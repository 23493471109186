import { useSuspenseQuery } from '@tanstack/react-query';
import { AuthLevel } from 'src/components/PersonalAccount/VFIDProfile';
import api from 'src/utils/api/api';

export const SHOW_PASSWORD_ENDPOINT = '/muj/profile/password';
export const SHOW_PASSWORD_KEY = 'showPassword';

type ShowPasswordResponse = {
  password: string;
};

type ShowPasswordQuery = {
  crmId: string;
  crmType: AuthLevel;
};

const showPasswordFn = async ({
  crmType,
  crmId,
}: ShowPasswordQuery): Promise<ShowPasswordResponse> => {
  const response = await api.get({
    url: SHOW_PASSWORD_ENDPOINT,
    query: { crmType, crmId },
  });

  return response.data;
};

export const useShowPassword = ({ crmType, crmId }: ShowPasswordQuery) => {
  return useSuspenseQuery({
    queryFn: () => showPasswordFn({ crmType, crmId }),
    queryKey: [SHOW_PASSWORD_KEY, crmType, crmId],
    staleTime: 0,
  });
};
