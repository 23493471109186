import { Button, Text, Title } from '@vodafone/red-white';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'src/hooks/useAppDispatch';
import {
  getRefreshSubscriptionTitle,
  setRefreshSubscriptionTitle,
} from 'src/utils/translation/getRefreshSubscriptionTitle';
import styled from 'styled-components';
import { refreshCasSubscription } from '../../../../store/cas/casActions';

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  margin: 20px 0;
  @media ${(props) => props.theme.breakpoints.sm} {
    margin: 1em 9em 0 7em;
    text-align: center;
  }
`;

const StyledSteps = styled.li`
  list-style: decimal;
  margin-top: 20px;
`;

const StyledList = styled.ol`
  @media ${(props) => props.theme.breakpoints.smOnly} {
    padding-left: 0px;
  }
`;

const StyledText = styled(Text)`
  @media ${(props) => props.theme.breakpoints.smOnly} {
    text-align: left;
  }
`;

const VtvCasRefreshSubscriptionOverview: FC = () => {
  const { t } = useTranslation(['services', 'error', 'cas']);
  const [title, setTitle] = useState<string>(t(getRefreshSubscriptionTitle()));
  const [resized, setResized] = React.useState<boolean>(false);
  const dispatch = useAppDispatch();
  const timer: string = window.sessionStorage.getItem(
    'timerForCasRefreshCasSubscription',
  );
  const isLimitSetUp = timer === 'set';

  useEffect(() => {
    setRefreshSubscriptionTitle(t, setTitle, setResized);
  }, [t, title, resized]);

  return (
    <>
      <Title level={2} style={{ margin: '0 0 30px 20px', lineHeight: 1.4 }}>
        {t('cas:refresh-subscription.title')}
      </Title>
      <StyledList>
        <StyledSteps
          dangerouslySetInnerHTML={{
            __html: t('cas:refresh-subscription.step-1'),
          }}
        />
        <StyledSteps
          dangerouslySetInnerHTML={{
            __html: t('cas:refresh-subscription.step-2'),
          }}
        />
        <ButtonWrapper>
          <Button
            htmlElement="button"
            disabled={isLimitSetUp}
            onClick={() => {
              dispatch(refreshCasSubscription());
            }}
            variant="primary"
          >
            {title}
          </Button>
          {isLimitSetUp && (
            <StyledText fontSize={14} color="primaryColor">
              {t('services:cas.limit-setup')}
            </StyledText>
          )}
        </ButtonWrapper>
        <StyledSteps
          dangerouslySetInnerHTML={{
            __html: t('cas:refresh-subscription.step-3'),
          }}
        />
      </StyledList>
    </>
  );
};

export default VtvCasRefreshSubscriptionOverview;
