import UserRoleEnum from 'src/store/user/types/UserRoleEnum';
import { IEligible } from '../types/eligibleInterface';
import { RoutesEnum } from './routes';

const config: IEligible = {
  [RoutesEnum.WSC_ESHOP_SPECIAL_OFFERS]: {
    hasRoles: [UserRoleEnum.ROLE_ESHOP_ACCESS],
  },
};

export default config;
