interface ISetValue {
  [x: string]: string | boolean;
}

interface IValueObject {
  set: ISetValue;
}

const implementsISetValue = (object: any): boolean => {
  const wrongAttribute = Object.keys(object).find(function isWrong(
    key: any,
  ): boolean {
    return typeof object[key] !== 'string' && typeof object[key] !== 'boolean';
  });

  return wrongAttribute === undefined;
};

const implementsIValueObject = (object: any): boolean => {
  return (
    object !== undefined &&
    'set' in object &&
    Object.keys(object).length === 1 &&
    implementsISetValue(object.set)
  );
};

const parseString = (text: string): IValueObject | undefined => {
  try {
    const re = /'/g;
    const fixedText = text.replace(re, '"');

    return JSON.parse(fixedText);
  } catch {
    return undefined;
  }
};

const parseActionValue = (value: string | null): IValueObject | undefined => {
  if (value === null) {
    return undefined;
  }

  const parsedValueObject = parseString(value);

  if (!implementsIValueObject(parsedValueObject)) {
    return undefined;
  }

  return parsedValueObject;
};

export default parseActionValue;
