import { ILoadableState } from '../../../types/loadableStateInterface';

export enum Device {
  mobile = 'mobile',
  tablet = 'tablet',
  desktop = 'desktop',
}

export interface IApiState extends ILoadableState {
  name: string;
}

export interface IApiItemState {
  [name: string]: IApiState;
}

export default interface IApplicationState {
  device: Device;
  previousPage: string;
  currentPage: string;
  apiLoading: IApiItemState[];
}
