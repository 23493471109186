import { createSelector, Selector } from 'reselect';
import { IApplicationState } from '../appStateInterface';
import { ISelectVtvActiveDeviceToRemove } from './types/vtvServicesSelectorInterfaces';
import IVtvServicesState from './types/vtvServicesStateInterface';

export const getVtvServices: Selector<IApplicationState, IVtvServicesState> = (
  state,
) => state.VtvServices;

export const selectVtvActiveDeviceToRemove: Selector<
  IApplicationState,
  ISelectVtvActiveDeviceToRemove
> = createSelector([getVtvServices], ({ activeDevices, removeDevice }) => ({
  ...removeDevice,
  device: activeDevices.find(
    (activeDevice) => activeDevice.udid === removeDevice.udid,
  ),
}));
