import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'src/hooks/useAppDispatch';
import IPaymentInfo from 'src/store/billing/paymentInfo/types/paymentInfoInterface';
import { downloadInvoice } from 'src/store/invoices/invoicesActions';
import InvoiceFormatEnum from 'src/store/invoices/types/InvoiceFormatEnum';
import selectLanguage from 'src/store/language/languageSelector';
import IBillingAccount from 'src/store/user/types/billingAccountInterface';
import UserAuthorizationRoleEnum from 'src/store/user/types/UserAuthorizationRoleEnum';
import userSelector, {
  selectBillingAccount,
} from 'src/store/user/userSelector';
import CardBillingPaymentSipo from 'src/ui/components/Billing/CardBillingPaymentSipo';
import { toLongMonthDateString } from 'src/utils/date';
import formatter from 'src/utils/formatter';
import capitalize from 'src/utils/formatter/text/capitalize/capitalize';
import { LanguagesEnum } from '../../../../types/translation/languagesEnum';
import Price from '../../../Price';

const SipoPaymentInfo: React.FC<IPaymentInfo> = ({
  billingMonth,
  invoiceId,
  sipoDueAmount,
  sipoTotalAmount,
  dueDate,
  dueAmount,
  pdfDocumentCreated,
}) => {
  const { t } = useTranslation(['billing', 'dashboard']);
  const language: LanguagesEnum = useSelector(selectLanguage);
  const dispatch = useAppDispatch();
  const billingAccount: IBillingAccount = useSelector(selectBillingAccount);
  const mustPay = sipoDueAmount > 120;
  const grayInfo = mustPay ? t('billing:sipo.subheading') : null;
  const heading = mustPay
    ? t('billing:sipo.heading')
    : t('billing:sipo.do-not-pay');
  const withPreviousDue = dueAmount > 120;
  const user = useSelector(userSelector);
  const isEndUser =
    user.authorizationRoleName === UserAuthorizationRoleEnum.User;
  const resolveLinkText = () => {
    if (isEndUser) {
      return null;
    }
    if (!pdfDocumentCreated) {
      return t('billing:paymentInfo.pdf-not-ready');
    }

    return t('billing:paymentInfo.link-text');
  };

  const textResolver = () => {
    if (dueAmount <= 0 && sipoDueAmount > 120) {
      return null;
    }

    if (sipoDueAmount < 120) {
      return t('billing:sipo.info-text-under-120');
    }

    if (withPreviousDue) {
      return `${t('billing:sipo.info-text', {
        totalDue: sipoTotalAmount - dueAmount,
      })} ${t('billing:sipo.with-previous-due')}`;
    }

    return t('billing:sipo.info-text', {
      totalDue: withPreviousDue ? sipoTotalAmount : sipoDueAmount,
    });
  };

  return (
    <>
      <CardBillingPaymentSipo
        title={t('billing:paymentInfo.title-UNPAID')}
        subtitle={t('billing:paymentInfo.subtitle', {
          month: capitalize(
            formatter.number.toMonthName(billingMonth, language),
          ),
        })}
        amount={<Price price={sipoDueAmount} />}
        heading={heading}
        dueDate={t('billing:paymentInfo.billing-subtitle', {
          date: toLongMonthDateString(dueDate, language),
        })}
        grayInfo={grayInfo}
        text={textResolver()}
        linkText={resolveLinkText()}
        onLinkClick={
          !isEndUser
            ? () => {
                dispatch(
                  downloadInvoice(
                    billingAccount.billingAccountNumber,
                    invoiceId,
                    InvoiceFormatEnum.PDF,
                  ),
                );
              }
            : null
        }
      />
    </>
  );
};

export default SipoPaymentInfo;
