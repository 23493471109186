import { Reducer } from 'redux';
import initialState from '../initialState';
import {
  IPasswordForgotConfirmationBeginAction,
  IPasswordForgotConfirmationFailureAction,
  IPasswordForgotConfirmationSuccessAction,
} from './types/passwordForgotConfirmationActionInterfaces';
import PasswordForgotConfirmationActionTypes from './types/passwordForgotConfirmationActionTypes';
import IPasswordForgotConfirmationState from './types/passwordForgotConfirmationStateInterface';

type IPasswordForgotConfirmationAction =
  | IPasswordForgotConfirmationBeginAction
  | IPasswordForgotConfirmationSuccessAction
  | IPasswordForgotConfirmationFailureAction;

const PasswordForgotConfirmationReducer: Reducer<
  IPasswordForgotConfirmationState
> = (
  state: IPasswordForgotConfirmationState = initialState.PasswordForgotConfirmation,
  action: IPasswordForgotConfirmationAction,
) => {
  switch (action.type) {
    case PasswordForgotConfirmationActionTypes.PASSWORD_FORGOT_CONFIRMATION_BEGIN:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      };
    case PasswordForgotConfirmationActionTypes.PASSWORD_FORGOT_CONFIRMATION_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        loaded: true,
        error: false,
      };
    case PasswordForgotConfirmationActionTypes.PASSWORD_FORGOT_CONFIRMATION_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    default:
      return state;
  }
};

export default PasswordForgotConfirmationReducer;
