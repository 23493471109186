import { AxiosResponse } from 'axios';
import { UpdateStolenLostActionEnum } from 'src/api/serviceManagement/request/types/UpdateStolenLostActionEnum';
import { IRestResponseFault } from 'src/api/types/IRestResponseFault';
import { IRestResponseSuccess } from 'src/api/types/IRestResponseSuccess';
import { API_ENDPOINT_SERVICE_STOLEN_LOST_UPDATE } from '../..';
import api from '../../../utils/api/api';

export default (
  serviceNumber: string,
  action: UpdateStolenLostActionEnum,
  channel: string,
): Promise<AxiosResponse<IRestResponseSuccess | IRestResponseFault>> =>
  api.post({
    url: API_ENDPOINT_SERVICE_STOLEN_LOST_UPDATE.replace(
      '{serviceNumber}',
      serviceNumber,
    ),
    data: { action, channel },
  });
