import {
  FooterRedesign,
  IconFacebook,
  IconGreenNetwork,
  IconInstagram,
  IconLinkedIn,
  IconP3,
  IconTop2020,
  IconTvLayout,
  IconTwitter,
  IconWebTop100,
  IconYoutube,
  IMainMenuItem,
} from '@vodafone/red-white';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { useGetLayout } from 'src/features/layout';
import { useAppDispatch } from 'src/hooks/useAppDispatch';
import { IApplicationState } from 'src/store/appStateInterface';
import { toggleLanguage } from 'src/store/language/languageActions';
import selectLanguage from 'src/store/language/languageSelector';
import UserRoleEnum from 'src/store/user/types/UserRoleEnum';
import userSelector, {
  selectIsUserWithRole,
} from 'src/store/user/userSelector';
import { redirect } from 'src/utils/redirect';
import { LanguagesEnum } from '../../types/translation/languagesEnum';
import { getRouteIdFromMenuItem } from '../Navigation/utils/helpers';

const Footer: React.FC<any> = ({
  history,
  match,
  showFooterNavigation = true,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation('layout');
  const { data } = useGetLayout();
  const user = useSelector(userSelector);
  const selectedLanguage: LanguagesEnum = useSelector(
    (state: IApplicationState) => selectLanguage(state),
  );

  const hasEmptyPARole = useSelector((state: IApplicationState) =>
    selectIsUserWithRole(state, [
      UserRoleEnum.ROLE_PA_NO_ROLES,
      UserRoleEnum.ROLE_PA_EMPTY,
    ]),
  );

  const baLoginMode = useSelector((state: IApplicationState) =>
    selectIsUserWithRole(state, [UserRoleEnum.ROLE_LOGIN_MODE_BILLING_ACCOUNT]),
  );

  const socials = [
    {
      id: 'facebook',
      label: 'facebook',
      href: '//www.facebook.com/vodafonecz',
      icon: <IconFacebook inverted shape="circle" />,
    },
    {
      id: 'twitter',
      label: 'twitter',
      href: '//www.twitter.com/vodafone_cz',
      icon: <IconTwitter inverted shape="circle" />,
    },
    {
      id: 'instagram',
      label: 'instagram',
      href: '//www.instagram.com/vodafonecz',
      icon: <IconInstagram inverted shape="circle" />,
    },
    {
      id: 'youtube',
      label: 'youtube',
      href: '//www.youtube.com/vodafoneczech',
      icon: <IconYoutube inverted shape="circle" />,
    },
    {
      id: 'vodafoneTv',
      label: 'vodafoneTv',
      href: '//www.facebook.com/vodafonetvcz',
      icon: <IconTvLayout size={25} />,
    },
    {
      id: 'linkedin',
      label: 'linkedin',
      href: '//www.linkedin.com/company/1217',
      icon: <IconLinkedIn inverted shape="circle" />,
    },
  ];

  const badges = [
    {
      id: 'top2021',
      description: t('footer.badge-top-2021-label'),
      href: 'https://byznysprospolecnost.cz/nejodpovednejsi-firmy-roku-2021-vyhlaseny/',
      icon: <IconTop2020 />,
    },
    {
      id: 'telecommunications',
      description: t('footer.badge-telecommunications-label'),
      href: '//www.vodafone.cz/o-vodafonu/o-spolecnosti/fakta-milniky-oceneni/#oceneni',
      icon: <IconWebTop100 />,
    },
    {
      id: 'test',
      description: t('footer.badge-test-label'),
      href: '//https://www.umlaut.com/en/benchmarking/czech-republic',
      icon: <IconP3 />,
    },
    {
      id: 'zelena-sit',
      description: t('footer.badge-green-label'),
      href: '//www.vodafone.cz/odpovednost/zelenou-cestou/prvni-zelena-sit/',
      icon: <IconGreenNetwork />,
    },
  ];

  const links = [
    {
      id: 'webmap',
      label: t('footer.webmap.label'),
      href: t('footer.webmap.href'),
    },
    {
      id: 'terms',
      label: t('footer.terms.label'),
      href: t('footer.terms.href'),
    },
    {
      id: 'privacy',
      label: t('footer.privacy.label'),
      href: t('footer.privacy.href'),
    },
    {
      id: 'responsibility',
      label: t('footer.responsibility.label'),
      href: t('footer.responsibility.href'),
    },
    {
      id: 'cookies',
      label: t('footer.cookies.label'),
      href: t('footer.cookies.href'),
    },
  ];

  const language = {
    label: t('footer.switch-language'),
    onClickCallback: () => {
      dispatch(toggleLanguage(history, match));
    },
  };

  return (
    data?.showNavigation && (
      <div data-testid="footer-component">
        <FooterRedesign
          topBar={{
            title: t('footer.connect-with-us'),
            socialLinks: {
              data: socials.map((social) => {
                return {
                  key: social.id,
                  label: social.label,
                  href: social.href,
                  icon: social.icon,
                };
              }),
            },
            languageText: language.label,
            onLanguageChange: language.onClickCallback,
          }}
          navLinks={
            showFooterNavigation &&
            selectedLanguage === LanguagesEnum.CS &&
            user.loaded
              ? {
                  data: data?.footerMenuItems
                    .filter(
                      (footerItem: IMainMenuItem) =>
                        ((baLoginMode || hasEmptyPARole) &&
                          footerItem.id === 'footer.useful_links') ||
                        (!baLoginMode && !hasEmptyPARole),
                    )
                    .map((footerItem: IMainMenuItem) => ({
                      key: footerItem.id,
                      title: footerItem.label,
                      onClick: null,
                      menuItems: footerItem.menuItems.map((menuItem) => {
                        return {
                          key: menuItem.id,
                          title: menuItem.label,
                          link: menuItem.uri,
                          onClick: () =>
                            redirect({
                              internalRouteId: getRouteIdFromMenuItem(menuItem),
                              url: menuItem.uri,
                              language: selectedLanguage,
                              history,
                            }),
                        };
                      }),
                    })),
                }
              : null
          }
          bottomBar={{
            copyrightText: t('footer.copy-text'),
            navData: links.map((link) => {
              return {
                key: link.id,
                label: link.label,
                href: link.href,
              };
            }),
            badgeData: badges.map((badge) => {
              return {
                key: badge.id,
                label: badge.description,
                href: badge.href,
                icon: badge.icon,
              };
            }),
          }}
        />
      </div>
    )
  );
};

export default withRouter(Footer);
