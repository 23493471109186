import { useMutation, useQueryClient } from '@tanstack/react-query';
import api from 'src/utils/api/api';

export const RENAME_IDENTITY_ENDPOINT = '/muj/vymysli-si-EP';
export const RENAME_IDENTITY_MUTATION_KEY = 'renameIdentityMutation';
export const RENAME_IDENTITY_KEY = 'renameIdentity';

type RenameIdentityRequest = {
  identityId: string;
  nickname: string;
};

type RenameIdentityError = {
  message: {
    code: string;
    description: string;
  };
};

type RenameIdentityResponse = {
  status: string;
};

const renameIdentityFn = async (
  request: RenameIdentityRequest,
): Promise<RenameIdentityResponse> => {
  const response = await api
    .post<RenameIdentityResponse>({
      url: RENAME_IDENTITY_ENDPOINT,
      data: request,
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });

  return response.data;
};

export const useRenameIdentity = () => {
  const queryClient = useQueryClient();

  return useMutation<
    RenameIdentityResponse,
    RenameIdentityError,
    RenameIdentityRequest
  >({
    mutationFn: renameIdentityFn,
    mutationKey: [RENAME_IDENTITY_MUTATION_KEY],
    onSuccess: (data, variables) =>
      queryClient.setQueryData([RENAME_IDENTITY_KEY, { variables }], data),
  });
};
