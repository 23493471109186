import { DocumentSectionEnum } from 'src/api/documents/response/DocumentInterface';
import initialState from '../initialState';
import { DocumentsActionTypes } from './types/documentsActionTypes';

const documentReducer = (state = initialState.Documents, action) => {
  switch (action.type) {
    // *************************** Invoices ************************* //
    case DocumentsActionTypes.FETCH_DOC_INVOICES_BEGIN:
      return {
        ...state,
        invoices: {
          loading: true,
          loaded: false,
          invoices: state.invoices.invoices,
          error: false,
        },
      };
    case DocumentsActionTypes.FETCH_DOC_INVOICES_SUCCESS:
      return {
        ...state,
        invoices: {
          loading: false,
          loaded: true,
          invoices: action.payload.data.documents.map((item) => ({
            ...item,
            section: DocumentSectionEnum.INVOICE,
          })),
          error: false,
        },
      };
    case DocumentsActionTypes.FETCH_DOC_INVOICES_FAILURE:
      return {
        ...state,
        invoices: {
          loading: false,
          loaded: true,
          invoices: state.invoices.invoices,
          error: true,
        },
      };

    // ***************************** Forms *********************** //

    case DocumentsActionTypes.FETCH_DOC_FORMS_BEGIN:
      return {
        ...state,
        forms: {
          loading: true,
          loaded: false,
          forms: state.forms.forms,
          error: false,
        },
      };
    case DocumentsActionTypes.FETCH_DOC_FORMS_SUCCESS:
      return {
        ...state,
        forms: {
          loading: false,
          loaded: true,
          forms: action.payload.data.documents.map((doc) => {
            return {
              ...doc,
              serviceNumber: action.serviceNumber,
              section: DocumentSectionEnum.FORM,
            };
          }),
          error: false,
        },
      };
    case DocumentsActionTypes.FETCH_DOC_FORMS_FAILURE:
      return {
        ...state,
        forms: {
          loading: false,
          loaded: true,
          forms: state.forms.forms,
          error: true,
        },
      };

    // ***************************** Agreements *********************** //

    case DocumentsActionTypes.FETCH_DOC_AGREEMENTS_BEGIN:
      return {
        ...state,
        agreements: {
          loading: true,
          loaded: false,
          agreements: state.agreements.agreements,
          error: false,
        },
      };
    case DocumentsActionTypes.FETCH_DOC_AGREEMENTS_SUCCESS:
      return {
        ...state,
        agreements: {
          loading: false,
          loaded: true,
          agreements: action.payload.data.documents.map((doc) => {
            return {
              ...doc,
              serviceNumber: action.serviceNumber,
              section: DocumentSectionEnum.AGREEMENT,
            };
          }),
          error: false,
        },
      };
    case DocumentsActionTypes.FETCH_DOC_AGREEMENTS_FAILURE:
      return {
        ...state,
        agreements: {
          loading: false,
          loaded: true,
          agreements: state.agreements.agreements,
          error: true,
        },
      };

    // ***************************** Agreements by type *********************** //
    case DocumentsActionTypes.FETCH_DOC_AGREEMENTS_BY_TYPE_BEGIN:
      return {
        ...state,
        agreementsByType: {
          loading: true,
          loaded: false,
          agreements: [],
          error: false,
        },
      };
    case DocumentsActionTypes.FETCH_DOC_AGREEMENTS_BY_TYPE_SUCCESS:
      return {
        ...state,
        agreementsByType: {
          loading: false,
          loaded: true,
          agreements: action.payload.data.documents,
          error: false,
        },
      };
    case DocumentsActionTypes.FETCH_DOC_AGREEMENTS_BY_TYPE_FAILURE:
      return {
        ...state,
        agreementsByType: {
          loading: false,
          loaded: false,
          agreements: [],
          error: true,
        },
      };

    // ***************************** Related content *********************** //

    case DocumentsActionTypes.FETCH_RELATED_CONTENT_BEGIN:
      return {
        ...state,
        relatedContent: {
          loading: true,
          loaded: false,
          data: state.relatedContent.data,
          error: false,
        },
      };
    case DocumentsActionTypes.FETCH_RELATED_CONTENT_SUCCESS:
      return {
        ...state,
        relatedContent: {
          loading: false,
          loaded: true,
          data: action.payload.data.documents.map((doc) => {
            return {
              ...doc,
              serviceNumber: action.serviceNumber,
              section: DocumentSectionEnum.RELATED_CONTENT,
            };
          }),
          error: false,
        },
      };
    case DocumentsActionTypes.FETCH_RELATED_CONTENT_FAILURE:
      return {
        ...state,
        relatedContent: {
          loading: false,
          loaded: true,
          data: state.relatedContent.data,
          error: true,
        },
      };

    // ***************************** Order preview *********************** //

    case DocumentsActionTypes.FETCH_ORDER_PREVIEW_BEGIN:
      return {
        ...state,
        orderPreview: {
          loading: true,
          loaded: false,
          data: state.agreements.agreements,
          error: false,
        },
      };
    case DocumentsActionTypes.FETCH_ORDER_PREVIEW_SUCCESS:
      return {
        ...state,
        orderPreview: {
          loading: false,
          loaded: true,
          data: action.payload.data.documents.map((doc) => {
            return {
              ...doc,
              serviceNumber: action.serviceNumber,
              section: DocumentSectionEnum.ORDER_PREVIEW,
            };
          }),
          error: false,
        },
      };
    case DocumentsActionTypes.FETCH_ORDER_PREVIEW_FAILURE:
      return {
        ...state,
        orderPreview: {
          loading: false,
          loaded: true,
          data: state.orderPreview.data,
          error: true,
        },
      };

    default:
      return state;
  }
};

export default documentReducer;
