import styled from 'styled-components';

export const Base = styled.div`
  text-align: center;
  background-color: #fff;
  padding: 30px;
`;

export const TitleTextSpacing = styled.span`
  margin-left: 16px;
`;

export const SubtitleSpacing = styled.div`
  margin-top: 12px;
`;
