import { AxiosResponse } from 'axios';
import { API_ENDPOINT_ESIM_CHANGE_SMS_FORKING_SETTINGS } from 'src/api';
import { IRestResponseFault } from 'src/api/types/IRestResponseFault';
import { IRestResponseSuccess } from 'src/api/types/IRestResponseSuccess';
import api from 'src/utils/api/api';

export default (
  serviceNumber: string,
  fork: boolean,
  productOfferingId: string,
): Promise<AxiosResponse<IRestResponseSuccess | IRestResponseFault>> =>
  api.post({
    url: API_ENDPOINT_ESIM_CHANGE_SMS_FORKING_SETTINGS.replace(
      '{serviceNumber}',
      serviceNumber,
    ),
    data: {
      fork,
      productOfferingId,
    },
  });
