import { Row, Text } from '@vodafone/red-white';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const PaymentInfoInCreation: React.FCC = () => {
  const { t } = useTranslation('billing');

  const Base = styled.div`
    text-align: center;
    background-color: #fff;
    padding: 30px;
  `;

  const TitleTextSpacing = styled.span`
    margin-left: 16px;
  `;

  const SubtitleSpacing = styled.div`
    margin-top: 12px;
  `;

  return (
    <Base>
      <Text block fontFamily="VodafoneLt" fontSize={26} color="darkGrey">
        <Row align="center" justify="center">
          <TitleTextSpacing>
            {t(`billing:paymentInfo.in-creation-title`)}
          </TitleTextSpacing>
        </Row>
      </Text>
      <SubtitleSpacing>
        <Text block fontSize={16} color="darkGrey">
          {t(`billing:paymentInfo.in-creation-subtitle`)}
        </Text>
      </SubtitleSpacing>
    </Base>
  );
};

export default PaymentInfoInCreation;
