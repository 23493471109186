import {
  Col,
  IconBlockRedesign,
  IconGiftRedesign,
  IconHelpCircleRedesign,
  IconInvoice,
  IconPayNumber,
  IconVodafoneStore,
  Row,
  Title,
} from '@vodafone/red-white';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import selectLanguage from 'src/store/language/languageSelector';
import CardList from 'src/ui/components/CardList';
import { getPathByRouteId, RoutesEnum } from '../../../config/routes';
import { LanguagesEnum } from '../../../types/translation/languagesEnum';
import generateExternalRoute from '../../../utils/router/generateExternalRoute';
import CardListLink from '../../CardListLink';

const LinksBoxes: React.FC = () => {
  const { t } = useTranslation('dashboard');
  const language: LanguagesEnum = useSelector(selectLanguage);

  return (
    <Row gutter={16} justify="center">
      <Col
        xs={24}
        lg={8}
        offsetBottom={{ xs: '16px', lg: '0px' }}
        dataAutomationId="other-functions-box"
      >
        <CardList
          gridArea="cardOtherFunctions"
          title={
            <>
              <Title
                color="darkGrey"
                fontFamily="VodafoneRg"
                fontWeight="bold"
                level={3}
                style={{ marginLeft: 8 }}
              >
                {t('dashboard:otherFunctions.title')}
              </Title>
            </>
          }
          data={[
            {
              name: t('dashboard:otherFunctions.link-eshop'),
              icon: <IconGiftRedesign size={20} />,
              href: generateExternalRoute('wsc_eshop_special_offers', language),
            },
            {
              name: t('dashboard:otherFunctions.link-topup'),
              icon: <IconPayNumber size={20} />,
              href: generateExternalRoute('wsc_charging', language),
            },
            {
              name: t('dashboard:otherFunctions.link-block-sim'),
              icon: <IconBlockRedesign size={20} />,
              linkDom: CardListLink,
              linkProps: {
                to: `${getPathByRouteId(
                  RoutesEnum.ACTIVE_SERVICES_ROUTE_ID,
                  language,
                )}?dl=lost_stolen`,
              },
            },
          ]}
        />
      </Col>
      <Col xs={24} lg={8} dataAutomationId="need-help-box">
        <CardList
          gridArea="cardNeedHelp"
          title={
            <>
              <Title
                color="darkGrey"
                fontFamily="VodafoneRg"
                fontWeight="bold"
                level={3}
                style={{ marginLeft: 8 }}
              >
                {t('dashboard:needHelp.title')}
              </Title>
            </>
          }
          data={[
            {
              name: t('dashboard:needHelp.link-billing'),
              icon: <IconInvoice size={20} />,
              href: t('dashboard:needHelp.link-billing-url'),
            },
            {
              name: t('dashboard:needHelp.link-stores'),
              icon: <IconVodafoneStore size={20} />,
              href: t('dashboard:needHelp.link-stores-url'),
            },
            {
              name: t('dashboard:needHelp.link-support'),
              icon: <IconHelpCircleRedesign size={20} />,
              href: t('dashboard:needHelp.link-support-url'),
            },
          ]}
        />
      </Col>
    </Row>
  );
};

export default LinksBoxes;
