export enum ScrollToBehaviorEnum {
  AUTO = 'auto',
  SMOOTH = 'smooth',
}

export const scrollTo =
  (reactId: string, behavior?: ScrollToBehaviorEnum) => async () => {
    const element = document.querySelector(`[data-react-id="${reactId}"]`);

    if (element) {
      element.scrollIntoView({
        block: 'start',
        behavior: behavior || ScrollToBehaviorEnum.SMOOTH,
      });
    }
  };
