import { Reducer } from 'redux';
import initialState from '../initialState';
import {
  IFetchEetReceiptsBeginAction,
  IFetchEetReceiptsFailureAction,
  IFetchEetReceiptsSuccessAction,
} from './types/eetReceiptsActionInterfaces';
import { EetReceiptsActionTypes } from './types/eetReceiptsActionTypes';
import { IEetReceiptsState } from './types/eetReceiptsStateInterface';

type EetReceiptsAction =
  | IFetchEetReceiptsBeginAction
  | IFetchEetReceiptsSuccessAction
  | IFetchEetReceiptsFailureAction;

const eetReceiptsReducer: Reducer<IEetReceiptsState> = (
  state: IEetReceiptsState = initialState.EETReceipts,
  action: EetReceiptsAction,
) => {
  switch (action.type) {
    case EetReceiptsActionTypes.FETCH_EET_RECEIPTS_BEGIN:
      return {
        ...state,
        loading: true,
        loaded: false,
      };

    case EetReceiptsActionTypes.FETCH_EET_RECEIPTS_SUCCESS:
      return {
        ...state,
        data: action.payload.receipts,
        loading: false,
        loaded: true,
        error: false,
      };

    case EetReceiptsActionTypes.FETCH_EET_RECEIPTS_FAILURE:
      return {
        ...state,
        data: [],
        loading: false,
        loaded: true,
        error: true,
      };

    default:
      return state;
  }
};

export default eetReceiptsReducer;
