export enum MicrositeFlow {
  /**
   * Default flow -  click on order will directly send request to BE
   */
  DIRECT_OFFER_CLICK = 'directOfferClick',
  /**
   *
   */
  SUMMARY_FLOW = 'syummaryFlow',
}

export enum MicrositeDirectOfferFlow {
  INITIAL = 'initial',
}

export enum MicrositeSummaryFlow {
  INITIAL = 'initial',
  SUMMARY = 'summary',
  THANK_YOU_PAGE = 'thankYouPage',
}

export type MicrositeFlowState =
  | MicrositeDirectOfferFlow
  | MicrositeSummaryFlow;
