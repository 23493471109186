import { createSelector, Selector } from 'reselect';
import { IApplicationState } from '../appStateInterface';
import {
  ICasState,
  IRefreshSubscriptionState,
} from './types/casStateInterface';

const getCas: Selector<IApplicationState, ICasState> = (
  state: IApplicationState,
) => state.Cas;

export const selectRefreshSubscription: Selector<
  IApplicationState,
  IRefreshSubscriptionState
> = createSelector([getCas], (cas) => cas.refreshSubscription.data);
export const selectRefreshSubscriptionLoading: Selector<
  IApplicationState,
  boolean
> = createSelector([getCas], (cas) => cas.refreshSubscription.loading);
export const selectRefreshSubscriptionIsLoaded: Selector<
  IApplicationState,
  boolean
> = createSelector([getCas], (cas) => cas.refreshSubscription.loaded);
export const selectRefreshSubscriptionHasError: Selector<
  IApplicationState,
  boolean
> = createSelector([getCas], (cas) => cas.refreshSubscription.error);

export const selectCasResetPin: Selector<
  IApplicationState,
  IRefreshSubscriptionState
> = createSelector([getCas], (cas) => cas.resetPin.data);
