import { Reducer } from 'redux';
import { ISubscriber } from 'src/api/productsOverview/response/productsOverviewResponseInterface';
import SubscriptionTypeEnum from 'src/store/user/types/SubscriptionTypeEnum';
import { addPrefix } from 'src/utils/serviceNumberFormatter';
import initialState from '../initialState';
import {
  IFetchProductsOverviewBeginAction,
  IFetchProductsOverviewFailureAction,
  IFetchProductsOverviewSuccessAction,
} from './types/productsOverviewActionInterfaces';
import { ProductsOverviewActionTypes } from './types/productsOverviewActionTypes';
import { IProductsOverviewState } from './types/productsOverviewStateInterface';

type ProductsOverviewAction =
  | IFetchProductsOverviewBeginAction
  | IFetchProductsOverviewSuccessAction
  | IFetchProductsOverviewFailureAction;

const productsOverviewReducer: Reducer<IProductsOverviewState> = (
  state: IProductsOverviewState = initialState.ProductsOverview,
  action: ProductsOverviewAction,
) => {
  switch (action.type) {
    case ProductsOverviewActionTypes.FETCH_PRODUCTS_OVERVIEW_BEGIN:
      return {
        ...state,
        loading: true,
        loaded: false,
      };

    case ProductsOverviewActionTypes.FETCH_PRODUCTS_OVERVIEW_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        data: {
          ...state.data,
          [action.caNumber]: {
            ...action.payload,
            subscribers: action.payload.subscribers.map(
              (subscriber: ISubscriber) => {
                return {
                  ...subscriber,
                  serviceNumber:
                    subscriber.subscriptionType === SubscriptionTypeEnum.GSM
                      ? addPrefix(subscriber.serviceNumber)
                      : subscriber.serviceNumber,
                };
              },
            ),
          },
        },
      };

    case ProductsOverviewActionTypes.FETCH_PRODUCTS_OVERVIEW_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };

    default:
      return state;
  }
};

export default productsOverviewReducer;
