import { useSelector } from 'react-redux';
import { Route, useHistory } from 'react-router-dom';
import { getRoute } from 'src/config/routes';
import NotEligiblePage from 'src/pages/_errors/NotEligiblePage';
import languageSelector from 'src/store/language/languageSelector';
import IUserState from 'src/store/user/types/userStateInterface';
import userSelector from 'src/store/user/userSelector';
import { LanguagesEnum } from '../../types/translation/languagesEnum';
import isEligibleRoute from '../eligible';
import { redirectToSpaLoginPage } from '../redirect';

export default ({ component, exact, path }) => {
  const user: IUserState = useSelector(userSelector);
  const language: LanguagesEnum = useSelector(languageSelector);
  const history = useHistory();

  if ((user.loaded && user.signedIn === null) || !user.loaded) {
    return null;
  }

  if (!user.signedIn) {
    redirectToSpaLoginPage(history, language);
  }

  const route = getRoute(path);

  if (!isEligibleRoute(route, user)) {
    return <NotEligiblePage path={path} />;
  }

  return (
    <>
      {/* @ts-ignore */}
      <Route exact={exact} path={path} component={component} />
    </>
  );
};
