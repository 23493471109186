import { Button, Modal } from '@vodafone/red-white';
import { useTranslation } from 'react-i18next';
import IdleTimer from 'react-idle-timer';
import { useSelector } from 'react-redux';
import {
  IDLE_DEBOUNCE,
  IDLE_LOGOUT_TIMEOUT,
  IDLE_WARNING_TIMEOUT,
} from 'src/config/config';
import { useAppDispatch } from 'src/hooks/useAppDispatch';
import { IApplicationState } from 'src/store/appStateInterface';
import {
  hideWarningModal,
  showWarningModal,
} from 'src/store/inactiveUserModal/inactiveUserModalActions';
import { isWarningModalVisible } from 'src/store/inactiveUserModal/inactiveUserModalSelectors';
import selectLanguage from 'src/store/language/languageSelector';
import { redirect } from 'src/utils/redirect';
import { LanguagesEnum } from '../../types/translation/languagesEnum';

const InactiveUserModal = () => {
  const { t } = useTranslation('layout');
  const language: LanguagesEnum = useSelector(selectLanguage);
  const dispatch = useAppDispatch();
  const displayWarningModal: boolean = useSelector((state: IApplicationState) =>
    isWarningModalVisible(state),
  );

  const showWarning = () => {
    dispatch(showWarningModal());
  };

  const hideWarning = () => dispatch(hideWarningModal());

  return (
    <>
      <IdleTimer
        element={document}
        onIdle={showWarning}
        debounce={IDLE_DEBOUNCE}
        timeout={IDLE_WARNING_TIMEOUT}
      />

      {displayWarningModal && (
        <IdleTimer
          element={document}
          onIdle={() => redirect({ url: 'wsc_logout', language })} // redirect from SPA to FE
          debounce={IDLE_DEBOUNCE}
          timeout={IDLE_LOGOUT_TIMEOUT}
        />
      )}

      <Modal
        title={t('inactiveUserModal.title')}
        isOpen={displayWarningModal}
        onClose={hideWarning}
        style={{ zIndex: 13002 }}
        button={
          <Button variant="primary" onClick={hideWarning}>
            {t('inactiveUserModal.button')}
          </Button>
        }
      >
        <p>{t('inactiveUserModal.message')}</p>
      </Modal>
    </>
  );
};

export default InactiveUserModal;
