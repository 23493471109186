import { IInteractionPointJson } from '@vodafone/red-white';
import {
  IMicrositeServiceActionResponseParam,
  IMicrositeServiceData,
} from 'src/api/genericMicrosites/response/getMicrositeDataInterface';

/**
 * Return truem, if data contains all properties and values from nested data
 * @param dataA
 */
export const hasNestedData = (
  data: IInteractionPointJson,
  nestedData: IInteractionPointJson,
): boolean => {
  let result = true;

  if (nestedData && Object.keys(nestedData).length > 0) {
    if (!data || Object.keys(nestedData).length === 0) {
      return false;
    }
    Object.keys(nestedData).forEach((key) => {
      const nestedValue = `${nestedData[key]}`;

      if (nestedValue.localeCompare(`${data[key]}`) !== 0) {
        result = false;
      }
    });
  }

  return result;
};

/**
 * Merge data, into new instance.
 *
 * @param dataA
 * @param dataB
 */
export const mergeJsonData = (
  dataA: IInteractionPointJson,
  dataB: IInteractionPointJson,
): IInteractionPointJson => {
  return Object.assign({}, dataA, dataB);
};

/**
 * Merge addData to data recursively throught childComponents param.
 * If filterObject is set, merge only thosse data, which contains filterObject.
 * eg. if filterObject is {id:"123"}, data will be added only to those object, which has id=123.
 *
 * @param addData
 * @param data
 * @param filterObject
 */
export const mergeMSData = (
  addData: IInteractionPointJson,
  data: IMicrositeServiceData,
  filterObject?: IInteractionPointJson,
): IMicrositeServiceData => {
  const response = Object.assign({}, data);
  const dataToSet: IInteractionPointJson =
    response.jsonData as IInteractionPointJson;

  if (hasNestedData(dataToSet, filterObject)) {
    response.jsonData = mergeJsonData(
      response.jsonData as IInteractionPointJson,
      addData,
    );
  }

  if (response.childComponents) {
    response.childComponents = response.childComponents.map((childData) =>
      mergeMSData(addData, childData, filterObject),
    );
  }
  return response;
};

/**
 * Merge serverAdditionalParam, which are defined in key-velue entrires to data.
 * @param data
 * @param serverAdditionalParam
 */
export const mergeObjectWithResponseParams = (
  data: IInteractionPointJson,
  serverAdditionalParam: IMicrositeServiceActionResponseParam[],
): IInteractionPointJson => {
  if (!serverAdditionalParam || serverAdditionalParam.length === 0) return data;
  const response: IInteractionPointJson = Object.assign({}, data);

  serverAdditionalParam.forEach((paramEntry) => {
    if (paramEntry.key) {
      response[paramEntry.key] = paramEntry.value;
    }
  });

  return response;
};
