export const REQUEST_FBB_MODEM_RESTART_SUCCESS =
  'REQUEST_FBB_MODEM_RESTART_LOADING';
export const REQUEST_FBB_MODEM_RESTART_LOADING =
  'REQUEST_FBB_MODEM_RESTART_SUCCESS';

export interface IRequestFbbModemRestartAction {
  type: typeof REQUEST_FBB_MODEM_RESTART_SUCCESS;
}

export interface IRequestFbbModemRestartLoadingAction {
  type: typeof REQUEST_FBB_MODEM_RESTART_LOADING;
}

export type FbbModemRestartTypes =
  | IRequestFbbModemRestartAction
  | IRequestFbbModemRestartLoadingAction;
