import { combineReducers } from 'redux';
import api from 'src/api/api';
import genericMicrositeAfterLoginReducer from 'src/store/genericMicrositeAfterLogin';
import application from './application';
import billing from './billing';
import cas from './cas';
import currentSpending from './currentSpending';
import documentReducer from './documents/documentsReducer';
import eetReceipts from './eetReceipts/eetReceiptsReducer';
import esim from './esim/esimReducer';
import { faqsReducer } from './faqs';
import fbbExitServicePenaltyReducer from './fbb/reducers/fbbExitServicePenaltyReducer';
import fbbModemMacAddress from './fbb/reducers/fbbModemMacAddressReducer';
import fbbModemRestart from './fbb/reducers/fbbModemRestartReducer';
import ipManagement from './fbb/reducers/ipManagementReducer';
import { genericMicrositesReducer } from './genericMicrosite';
import { inactiveUserModalReducer } from './inactiveUserModal';
import invoices from './invoices';
import language from './language';
import largeAccounts from './largeAccounts/largeAccountsReducer';
import { loadingReducer } from './loading';
import mfa from './mfa/mfaReducer';
import mnp from './mnp';
import { modalReducer } from './modal';
import mteChange from './mteChange';
import { newsReducer } from './news';
import notice from './notice/noticeReducer';
import passwordForgotConfirmation from './passwordForgotConfirmation';
import pegaOffers from './pegaOffers';
import pinPuk from './pinPuk';
import productsOverview from './productsOverview/productsOverviewReducer';
import recoveryEligibility from './recoveryEligibility';
import recoveryEligibilityCaptcha from './recoveryEligibilityCaptcha';
import recoveryRequest from './recoveryRequest';
import recoveryRequestCaptcha from './recoveryRequestCaptcha';
import recoverySetting from './recoverySetting';
import {
  activateRecurringDataOfferReducer,
  recurringDataOfferReducer,
} from './recurringDataOffer';
import requestValidationSms from './requestValidationSms';
import services from './services';
import managementEligibility from './services/reducers/managementEligibilityReducer';
import moreServices from './services/reducers/moreServiceReducer';
import roamingCalculator from './services/reducers/roamingCalculatorReducer';
import sections from './services/reducers/sectionReducer';
import simActivation from './services/reducers/simActivationReducer';
import tariffOverview from './services/reducers/tariffOverviewReducer';
import virtualBundle from './services/reducers/virtualBundleReducer';
import { smartOverviewReducer } from './smartOverview';
import usageConsumption from './usageConsumption';
import user from './user';
import verifySmsCode from './verifySmsCode';
import vtvServices from './vtvServices';

export default combineReducers({
  Application: application,
  Billing: billing,
  Cas: cas,
  CurrentSpending: currentSpending,
  Documents: documentReducer,
  EETReceipts: eetReceipts,
  ESim: esim,
  Faqs: faqsReducer,
  FbbServices: combineReducers({
    fbbModemMacAddress,
    fbbModemRestart,
    ipManagement,
    fbbExitServicePenalty: fbbExitServicePenaltyReducer,
  }),
  GenericMicrosites: genericMicrositesReducer,
  GenericMicrositeAfterLogin: genericMicrositeAfterLoginReducer,
  InactiveUserModal: inactiveUserModalReducer,
  Invoices: invoices,
  Language: language,
  LargeAccounts: largeAccounts,
  Loading: loadingReducer,
  Mfa: mfa,
  Mnp: mnp,
  Modal: modalReducer,
  MteChange: mteChange,
  News: newsReducer,
  Notice: notice,
  PasswordForgotConfirmation: passwordForgotConfirmation,
  PegaOffers: pegaOffers,
  PinPuk: pinPuk,
  ProductsOverview: productsOverview,
  RecoveryEligibility: recoveryEligibility,
  RecoveryEligibilityCaptcha: recoveryEligibilityCaptcha,
  RecoveryRequest: recoveryRequest,
  RecoveryRequestCaptcha: recoveryRequestCaptcha,
  RecoverySetting: recoverySetting,
  RequestValidationSms: requestValidationSms,
  RecurringDataOffer: combineReducers({
    Offer: recurringDataOfferReducer,
    Activation: activateRecurringDataOfferReducer,
  }),
  Services: combineReducers({
    managementEligibility,
    moreServices,
    roamingCalculator,
    sections,
    services,
    tariffOverview,
    virtualBundle,
    simActivation,
  }),
  SmartOverview: smartOverviewReducer,
  UsageConsumption: usageConsumption,
  User: user,
  VerifiedSmsCode: verifySmsCode,
  VtvServices: vtvServices,
  [api.reducerPath]: api.reducer,
});
