import { useQuery } from '@tanstack/react-query';
import { useCoreOutage } from 'src/features/core';
import api from 'src/utils/api/api';

export const CHAT_RESOLVER_PARAMETERS_ENDPOINT =
  '/muj/rapi/chat-resolver/parameters';
const CHAT_RESOLVER_PARAMETERS_KEY = 'chatResolverParameters';

type ChatResolverResponse = {
  channel: string;
  chatApiDomain: string;
  chatApiProtocol: string;
  xbotOWDataUrl: string;
  xbotOWDataPrefix: string;
  xbotOWEnabled: string;
  phpSessionId?: string;
};

const getChatResolverParameters = async (): Promise<ChatResolverResponse> => {
  const { data } = await api.get({ url: CHAT_RESOLVER_PARAMETERS_ENDPOINT });

  return data;
};

export const useChatResolverParameters = () => {
  const outage = useCoreOutage();

  return useQuery({
    queryFn: getChatResolverParameters,
    queryKey: [CHAT_RESOLVER_PARAMETERS_KEY],
    enabled: outage.isSuccess && !outage.data.isReadMode,
  });
};
