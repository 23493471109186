import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { PAYMENT_TYPE } from 'src/api/serviceManagement/response/getServicesResponseInterface';
import { REDIRECT_TO_PERSONAL_ACCOUNT_DISABLED_ROUTES } from 'src/config/config';
import {
  isCurrentRouteBlacklistedByConfig,
  RoutesEnum,
} from 'src/config/routes';
import { IApplicationState } from 'src/store/appStateInterface';
import selectLanguage from 'src/store/language/languageSelector';
import UserRoleEnum from 'src/store/user/types/UserRoleEnum';
import userSelector, {
  selectIsUserWithRole,
} from 'src/store/user/userSelector';
import { redirect } from 'src/utils/redirect';

export const useAfterLoginRedirect = () => {
  const user = useSelector(userSelector);
  const language = useSelector(selectLanguage);
  const hasEmptyPARole = useSelector((state: IApplicationState) =>
    selectIsUserWithRole(state, [
      UserRoleEnum.ROLE_PA_NO_ROLES,
      UserRoleEnum.ROLE_PA_EMPTY,
    ]),
  );
  const isDigiOpcoUnsupported = useSelector((state: IApplicationState) =>
    selectIsUserWithRole(state, [UserRoleEnum.ROLE_DIGI_OPCO_UNSUPPORTED]),
  );
  const isForcedCiMigrationUser = useSelector((state: IApplicationState) =>
    selectIsUserWithRole(state, [UserRoleEnum.ROLE_FORCED_MIGRATION]),
  );

  const isLoginModeBARole = useSelector((state: IApplicationState) =>
    selectIsUserWithRole(state, [UserRoleEnum.ROLE_LOGIN_MODE_BILLING_ACCOUNT]),
  );

  useEffect(() => {
    if (
      user.signedIn &&
      !isForcedCiMigrationUser &&
      !isDigiOpcoUnsupported &&
      !isCurrentRouteBlacklistedByConfig(
        REDIRECT_TO_PERSONAL_ACCOUNT_DISABLED_ROUTES,
      ) &&
      (hasEmptyPARole ||
        (isLoginModeBARole &&
          user.billingAccount?.paymentType === PAYMENT_TYPE.PREPAID))
    ) {
      redirect({
        internalRouteId: RoutesEnum.PERSONAL_ACCOUNT_PROFILE_ROUTE_ID,
        language,
      });
    }
  }, [
    user.signedIn,
    user.billingAccount,
    isDigiOpcoUnsupported,
    hasEmptyPARole,
    isLoginModeBARole,
    isForcedCiMigrationUser,
  ]);
};
