import toCurrency from './toCurrency';
import toDataUnit from './toDataUnit';
import toMonthName from './toMonthName';
import toPercentage from './toPercentage';

export default {
  toPercentage,
  toCurrency,
  toDataUnit,
  toMonthName,
};
