import { Col, Row, Text } from '@vodafone/red-white';
import * as React from 'react';
import * as Styled from './CardBillingHistory.styled';

export type CardBillingHistoryStatus = 'success' | 'danger' | 'default';

export interface ICardBillingLink {
  key: string;
  title: string;
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
  isHidden?: boolean;
}

export interface ICardBillingHistoryProps {
  style?: React.CSSProperties;
  billing: {
    title?: string;
    subtitle?: string;
    amount?: React.ReactNode;
    status?: CardBillingHistoryStatus;
    statusText?: string;
    statusIcon?: React.ReactNode;
    dueDate?: string;
  };
  linksData?: ICardBillingLink[];
  onLinkDataClick?: (key: string) => void;
  compact?: boolean;
  preperationText?: string;
}

const CardBillingHistory: React.FC<ICardBillingHistoryProps> = ({
  billing,
  linksData,
  style,
  onLinkDataClick,
  compact,
  preperationText,
}) => {
  const getTextColorByStatus = (status: CardBillingHistoryStatus) => {
    if (status === 'success') return 'green';
    if (status === 'danger') return 'primaryColor';

    return 'darkGrey';
  };
  const statusTextColor = getTextColorByStatus(billing.status);

  return (
    <Styled.CardWrapper
      padding={Styled.CardPadding}
      style={style}
      compact={compact}
    >
      <Row justify="space-between" align="center" gutter={0}>
        <Col xs={24} lg={preperationText ? 24 : 12}>
          <Row justify="space-between" gutter={0}>
            <Col xs={24} lg={9}>
              <Styled.HistoryHeader>
                <Text fontFamily="VodafoneRgBd" fontSize={24} color="darkGrey">
                  {billing.title}
                </Text>
                <Text fontSize={16} color="lightGrey2">
                  {billing.subtitle}
                  {billing.dueDate && (
                    <Styled.PaymentStatusText color="red" fontSize={16}>
                      <br />
                      {billing.dueDate}
                    </Styled.PaymentStatusText>
                  )}
                </Text>
              </Styled.HistoryHeader>
            </Col>

            {preperationText && (
              <Col xs={24} lg={15} justify="center">
                <Styled.PreperationText fontSize={18} color="darkGrey">
                  {preperationText}
                </Styled.PreperationText>
              </Col>
            )}
            {!preperationText && (
              <Styled.BillingCol xs={24} lg={15}>
                <Styled.CenteredContent>
                  <Text fontSize={32} fontFamily="VodafoneLt" color="darkGrey">
                    {billing.amount}
                  </Text>
                  <Styled.StatusWrapper>
                    <Styled.IconWrapper>
                      {billing.statusIcon}
                    </Styled.IconWrapper>
                    <Styled.PaymentStatusWrapper>
                      <Styled.PaymentStatusText
                        color={statusTextColor}
                        fontSize={18}
                      >
                        {billing.statusText}
                      </Styled.PaymentStatusText>
                    </Styled.PaymentStatusWrapper>
                  </Styled.StatusWrapper>
                </Styled.CenteredContent>
              </Styled.BillingCol>
            )}
          </Row>
        </Col>

        {!preperationText && (
          <Col xs={24} lg={12} align={{ xs: 'space-between', lg: 'flex-end' }}>
            <Styled.LinksRow>
              {linksData.map((link) => (
                <Styled.LinkTextWrapper
                  key={link.key}
                  isHidden={!!link.isHidden}
                >
                  <Styled.LinkText
                    key={link.key}
                    onActivation={() => onLinkDataClick(link.key)}
                  >
                    <Styled.CenteredContent>
                      {link.prefix && (
                        <Styled.PrefixWrapper>
                          {link.prefix}
                        </Styled.PrefixWrapper>
                      )}
                      <Styled.BillingLink>{link.title}</Styled.BillingLink>
                      {link.suffix && (
                        <Styled.SuffixWrapper>
                          {link.suffix}
                        </Styled.SuffixWrapper>
                      )}
                    </Styled.CenteredContent>
                  </Styled.LinkText>
                </Styled.LinkTextWrapper>
              ))}
            </Styled.LinksRow>
          </Col>
        )}
      </Row>
    </Styled.CardWrapper>
  );
};

export default CardBillingHistory;
