import {
  Col,
  IconBilling,
  IconChevronDownFa,
  IconChevronUpFa,
  Row,
  Text,
} from '@vodafone/red-white';
import * as React from 'react';
import { useOutsideComponentClick } from 'src/hooks/useOutsideComponentClick';
import { ThemeContext } from 'styled-components';
import * as Styled from './PayerSelect.styled';
import PayerSelectItem from './PayerSelectItem';

export type PayerSelectRow = {
  value: string;
  label: string;
  labelDesc: string;
};

export interface IPayerSelectProps {
  data: PayerSelectRow[];
  activeItem: PayerSelectRow;
  onChange?: (item: PayerSelectRow) => void;
  footerDom?: React.ReactNode;
  addon?: React.ReactNode | any;
  onClose?: () => void;
  showBullet?: boolean;
  icon?: React.ReactNode;
  disableExpand?: boolean;
}

const PayerSelect: React.FC<IPayerSelectProps> = ({
  data,
  onChange,
  activeItem,
  footerDom,
  addon,
  onClose,
  showBullet = true,
  icon = <IconBilling size={40} />,
  disableExpand = false,
}) => {
  const [open, setOpen] = React.useState(false);
  const themeContext = React.useContext(ThemeContext);
  const IconRW = open ? IconChevronUpFa : IconChevronDownFa;
  const ref = useOutsideComponentClick(() => {
    setOpen(false);
  });

  const escapeListener = React.useCallback((e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      setOpen(false);
      if (onClose) {
        onClose();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = React.useCallback(
    (item: PayerSelectRow) => {
      onChange(item);
      setOpen(false);
    },
    [onChange],
  );

  React.useEffect(() => {
    document.addEventListener('keyup', escapeListener);

    return () => {
      document.removeEventListener('keyup', escapeListener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Styled.SelectWrapper ref={ref}>
      <Styled.SelectBase
        disableExpand={disableExpand}
        onClick={() => {
          if (disableExpand) return;

          setOpen(!open);

          if (open && onClose) {
            onClose();
          }
        }}
      >
        <Row justify="space-around">
          <Col>
            <Row align="center" style={{ flexWrap: 'nowrap' }}>
              <div>{icon}</div>
              <Styled.LabelWrapper>
                <Text
                  block
                  fontSize={18}
                  color="darkGrey"
                  fontFamily="VodafoneRgBd"
                >
                  {activeItem.label}
                </Text>
                <Text block color="darkGrey">
                  {activeItem.labelDesc}
                </Text>
              </Styled.LabelWrapper>
              {!disableExpand && (
                <div>
                  <IconRW size={20} color={themeContext.colors.primaryColor} />
                </div>
              )}
            </Row>
          </Col>
        </Row>
      </Styled.SelectBase>
      {open && (
        <Styled.SelectContentWrapper>
          <Styled.SelectContent>
            {addon}
            <Styled.SelectContentScroller>
              {data.map((item) => (
                <PayerSelectItem
                  showBullet={showBullet}
                  onClick={() => handleChange(item)}
                  key={item.value}
                  active={activeItem && activeItem.value === item.value}
                  {...item}
                />
              ))}
            </Styled.SelectContentScroller>
            {footerDom}
          </Styled.SelectContent>
        </Styled.SelectContentWrapper>
      )}
    </Styled.SelectWrapper>
  );
};

export default PayerSelect;
