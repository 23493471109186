import { Reducer } from 'redux';
import initialState from '../initialState';
import {
  IFetchPinPukBeginAction,
  IFetchPinPukFailureAction,
  IFetchPinPukSuccessAction,
} from './types/pinPukActionInterfaces';
import PinPukActionTypes from './types/pinPukActionTypes';
import PinPukStateInterface from './types/pinPukStateInterface';

type IPinPukActions =
  | IFetchPinPukBeginAction
  | IFetchPinPukSuccessAction
  | IFetchPinPukFailureAction;

const pinPukReducer: Reducer<PinPukStateInterface, IPinPukActions> = (
  state = initialState.PinPuk,
  action,
) => {
  switch (action.type) {
    case PinPukActionTypes.FETCH_PIN_PUK_BEGIN:
      return {
        ...state,
        [action.serviceNumber]: {
          loading: true,
          loaded: false,
          error: false,
        },
      };
    case PinPukActionTypes.FETCH_PIN_PUK_SUCCESS:
      return {
        ...state,
        [action.serviceNumber]: {
          data: action.payload,
          loading: false,
          loaded: true,
          error: false,
        },
      };
    case PinPukActionTypes.FETCH_PIN_PUK_FAILURE:
      return {
        ...state,
        [action.serviceNumber]: {
          loading: false,
          loaded: false,
          error: true,
        },
      };
    default:
      return state;
  }
};

export default pinPukReducer;
