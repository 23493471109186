enum SubscriptionTypeEnum {
  GSM = 'GsmSubscription',
  VTV = 'VtvSubscription',
  FBB = 'FbbSubscription',
  FIX = 'FixSubscription',
  SEC = 'SecSubscription',
  SAS = 'SasSubscription',
  MOS = 'MosSubscription',
  FMS = 'FmsSubscription',
}

export const allowedSubscriptionTypeForPrefix = [
  SubscriptionTypeEnum.GSM,
  SubscriptionTypeEnum.FMS,
  SubscriptionTypeEnum.FIX,
];

export default SubscriptionTypeEnum;
