import {
  Button,
  CircleLoader,
  Col,
  COLORS,
  Container,
  IconDocumentSimple,
  IconPdfText,
  Message,
  Messages,
  Row,
  Text,
  Title,
} from '@vodafone/red-white';
import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useCoreOutage } from 'src/features/core';
import { useAppDispatch } from 'src/hooks/useAppDispatch';
import { IApplicationState } from 'src/store/appStateInterface';
import {
  downloadEetReceipt,
  fetchEetReceipts,
} from 'src/store/eetReceipts/eetReceiptsActions';
import {
  selectEetReceipts,
  selectHasEetReceiptsError,
  selectIsEetReceiptsLoaded,
  selectIsEetReceiptsLoading,
} from 'src/store/eetReceipts/eetReceiptsSelectors';
import { IEetReceipt } from 'src/store/eetReceipts/types/eetReceiptsInterface';
import IBillingAccount from 'src/store/user/types/billingAccountInterface';
import { selectBillingAccount } from 'src/store/user/userSelector';
import styled, { ThemeContext } from 'styled-components';
import { toNumericDateString } from '../../../utils/date';
import { ModalContentProps } from '../types/modalContentInterfaces';

const HeadingWrapper = styled.div`
  padding: 12px;
  padding-top: 16px;
  display: flex;

  @media ${(props) => props.theme.breakpoints.md} {
    padding: 32px;
    padding-bottom: 0;
  }
`;

const PaymentOverviewItem = styled.div`
  padding: 15px 0;

  &:not(:last-child) {
    border-bottom: 1px solid ${(props) => props.theme.colors.lightGrey5};
  }
`;

const ListWrapper = styled.div`
  padding: 32px;
  background: ${(props) => props.theme.colors.white};
`;

const List = styled.ul`
  list-style: none;
  padding: 0px;
  padding-right: 16px;
  max-height: 500px;
  overflow-y: auto;
`;

const DateTextSpacing = styled(Text)`
  margin-left: 16px;
`;

const MethodRow = styled(Row)`
  padding-left: 48px;

  @media ${(props) => props.theme.breakpoints.md} {
    padding-left: 0px;
    justify-content: flex-end;
    align-items: flex-end;
  }
`;

const IconWrapper = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) => props.theme.colors.lightGrey12};
  border-radius: 5px;
  width: 32px;
  height: 32px;
`;

const DownloadLink = styled.a`
  text-decoration: underline;
`;

const ButtonWrapper = styled.div`
  margin: 30px 0 0 0;
  text-align: center;
`;

const NoReceiptsWrapper = styled.div<{ paddingBottomMd: number }>`
  padding: 12px;
  padding-top: 16px;

  @media ${(props) => props.theme.breakpoints.md} {
    padding: 32px;
    padding-bottom: ${(props) => props.paddingBottomMd};
  }
`;

const EETReceiptsModalContent: FC<ModalContentProps> = ({
  setCompact,
  setCompactContent,
}) => {
  const themeContext = React.useContext(ThemeContext);
  const dispatch = useAppDispatch();
  const billingAccount: IBillingAccount = useSelector(
    (state: IApplicationState) => selectBillingAccount(state),
  );
  const outage = useCoreOutage();

  const { t } = useTranslation(['billing', 'component']);

  setCompact(false);
  setCompactContent(true);

  useEffect(() => {
    if (billingAccount && billingAccount.billingAccountNumber) {
      dispatch(fetchEetReceipts(billingAccount?.billingAccountNumber));
    }
  }, [dispatch, billingAccount]);

  const defaultLimit = 10;
  const [limit, setLimit] = React.useState(defaultLimit);
  const onLoadMoreClick = () => setLimit(limit + defaultLimit);

  const onDownloadCLick = (item: IEetReceipt) =>
    dispatch(
      downloadEetReceipt(billingAccount?.billingAccountNumber, item.documentId),
    );

  const hasLoading: boolean = useSelector((state: IApplicationState) =>
    selectIsEetReceiptsLoading(state),
  );
  const hasError: boolean = useSelector((state: IApplicationState) =>
    selectHasEetReceiptsError(state),
  );
  const isLoaded: boolean = useSelector((state: IApplicationState) =>
    selectIsEetReceiptsLoaded(state),
  );
  const eetReceipts: IEetReceipt[] = useSelector((state: IApplicationState) =>
    selectEetReceipts(state),
  );

  return (
    <>
      <HeadingWrapper>
        <IconDocumentSimple size={20} color={themeContext.colors.darkGrey} />
        <Title
          color="darkGrey"
          fontFamily="VodafoneRg"
          fontWeight="bold"
          level={3}
          style={{ marginLeft: 8 }}
        >
          {t('billing:eet.modal-title')}
        </Title>
      </HeadingWrapper>

      {outage.data.isReadMode && (
        <Container fluid>
          <Row justify="center" gutter={16}>
            <Col dataAutomationId="read-mode-message">
              <Message
                type="info"
                message={
                  <div>
                    <b>{t('component:readModeInfoMessage.title')}</b>
                    <p>{t('component:readModeInfoMessage.message')}</p>
                  </div>
                }
              />
            </Col>
          </Row>
        </Container>
      )}

      {!outage.data.isReadMode && hasLoading && (
        <div style={{ padding: 40 }}>
          <CircleLoader />
        </div>
      )}

      {!outage.data.isReadMode && hasError && (
        <div style={{ padding: 40 }}>
          <Messages
            color={COLORS.red}
            iconColor={COLORS.red}
            messageTitle={t(
              'component:errorBoundary.error500-message-headline',
            )}
            messageText=""
          />
        </div>
      )}

      {!outage.data.isReadMode && isLoaded && eetReceipts.length > 1 && (
        <>
          <NoReceiptsWrapper paddingBottomMd={0}>
            {t('billing:eet.new-legislation')}
          </NoReceiptsWrapper>
          <ListWrapper>
            <List>
              {eetReceipts.slice(0, limit).map((item: IEetReceipt) => {
                return (
                  <PaymentOverviewItem key={item.documentId}>
                    <Row align="center" justify="space-between">
                      <Col xs={13} md={9}>
                        <Row align="center">
                          <IconWrapper>
                            <IconDocumentSimple size={14} />
                          </IconWrapper>
                          <DateTextSpacing>
                            <Text fontSize={18} color="darkGrey">
                              {toNumericDateString(item.issueDate)}
                            </Text>
                          </DateTextSpacing>
                        </Row>
                      </Col>
                      <Col xs={24} md={9}>
                        <MethodRow>
                          <>
                            <IconPdfText size={20} />
                            <>&nbsp;</>
                            <DownloadLink onClick={() => onDownloadCLick(item)}>
                              {t('billing:eet.download')}
                            </DownloadLink>
                          </>
                        </MethodRow>
                      </Col>
                    </Row>
                  </PaymentOverviewItem>
                );
              })}
            </List>
            <ButtonWrapper>
              <Button
                variant="secondary"
                disabled={
                  eetReceipts.length === eetReceipts.slice(0, limit).length
                }
                onClick={() => onLoadMoreClick()}
              >
                {t('billing:eet.load-more', { number: limit })}
              </Button>
            </ButtonWrapper>
          </ListWrapper>
        </>
      )}

      {!outage.data.isReadMode && isLoaded && eetReceipts.length === 0 && (
        <NoReceiptsWrapper paddingBottomMd={20}>
          {t('billing:eet.no-receipts')}
          <br />
          {t('billing:eet.new-legislation')}
        </NoReceiptsWrapper>
      )}
    </>
  );
};

export default EETReceiptsModalContent;
