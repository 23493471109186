import {
  Card,
  ContentList,
  ICardProps,
  IconChevronRight,
  Text,
} from '@vodafone/red-white';
import * as React from 'react';
import { ThemeContext } from 'styled-components';
import * as Styled from './CardList.styled';

interface Data {
  name: string;
  icon?: React.ReactNode;
  onClick?: () => void;
  href?: string;
  linkDom?: React.ReactNode;
  linkProps?: any;
  analyticsAction?: () => void;
}

export interface ICardOtherFunctionsProps extends ICardProps {
  gridArea?: string;
  data: Data[];
}

const CardList: React.FC<ICardOtherFunctionsProps> = ({
  gridArea,
  data = [],
  ...cardProps
}) => {
  const themeContext = React.useContext(ThemeContext);

  return (
    <Card style={{ width: '100%', height: 235, gridArea }} {...cardProps}>
      <ContentList
        noBorder
        dataSource={data}
        renderItem={(item: Data) => {
          const renderListItem = () => {
            return (
              <Styled.ListItem>
                <Styled.ListItemContent>
                  <Styled.IconWrapper>{item.icon}</Styled.IconWrapper>
                  <Text
                    fontSize={18}
                    fontFamily="VodafoneLt"
                    color="darkGrey"
                    style={{ marginLeft: 12 }}
                  >
                    {item.name}
                  </Text>
                </Styled.ListItemContent>
                <IconChevronRight
                  size={18}
                  color={themeContext.colors.primaryColor}
                />
              </Styled.ListItem>
            );
          };

          if (item.analyticsAction) {
            return (
              <Styled.ContentListItem>
                <Styled.LinkButton
                  onActivation={item.analyticsAction}
                  onClick={item.onClick}
                >
                  {renderListItem()}
                </Styled.LinkButton>
              </Styled.ContentListItem>
            );
          }
          if (item.href) {
            return (
              <Styled.ContentListItem>
                <Styled.ListItemLink href={item.href}>
                  {renderListItem()}
                </Styled.ListItemLink>
              </Styled.ContentListItem>
            );
          }

          if (item.linkDom) {
            const LinkDom: any = item.linkDom;

            return (
              <Styled.ContentListItem>
                <LinkDom {...item.linkProps}>{renderListItem()}</LinkDom>
              </Styled.ContentListItem>
            );
          }

          return (
            <Styled.ContentListItem>
              <Styled.LinkButton onActivation={item.onClick}>
                {renderListItem()}
              </Styled.LinkButton>
            </Styled.ContentListItem>
          );
        }}
      />
    </Card>
  );
};

export default CardList;
