import { Reducer } from 'redux';
import initialState from '../../initialState';
import {
  IFetchTariffOverviewBeginAction,
  IFetchTariffOverviewFailureAction,
  IFetchTariffOverviewSuccessAction,
  IToggleTariffOverviewAction,
} from '../types/tariffOverview/tariffOverviewActionInterfaces';
import { TariffOverviewActionTypes } from '../types/tariffOverview/tariffOverviewActionTypes';
import { ITariffOverviewState } from '../types/tariffOverview/tariffOverviewStateInterface';

type TariffOverviewAction =
  | IFetchTariffOverviewBeginAction
  | IFetchTariffOverviewSuccessAction
  | IFetchTariffOverviewFailureAction
  | IToggleTariffOverviewAction;

export const tariffOverviewReducer: Reducer<ITariffOverviewState> = (
  state: ITariffOverviewState = initialState.Services.tariffOverview,
  action: TariffOverviewAction,
) => {
  switch (action.type) {
    case TariffOverviewActionTypes.FETCH_TARIFF_OVERVIEW_BEGIN:
      return {
        ...state,
        loading: true,
        loaded: false,
      };

    case TariffOverviewActionTypes.FETCH_TARIFF_OVERVIEW_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        tariff: action.payload,
      };

    case TariffOverviewActionTypes.FETCH_TARIFF_OVERVIEW_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };

    case TariffOverviewActionTypes.TOGGLE_TARIFF_OVERVIEW:
      return {
        ...state,
        isOpen: action.isOpen,
      };

    default:
      return state;
  }
};

export default tariffOverviewReducer;
