export enum BssServicesEnums {
  ABSENCEREASON = 'ABSNCRSN',
  BARRING_BY_CUSTOMER = 'BARRINGBYCUSTOMER',
  PASSBAR = 'PASSBAR',
  CALL_FORWARDING = 'CALLFORWARD',
  CALL_WAITING = 'CALLWAITING',
  CLIR = 'CLIR',
  EBILLING = 'EBILLING',
  EM01 = 'EM01',
  EM02 = 'EM02',
  EM03 = 'EM03',
  EM04 = 'EM04',
  EM05 = 'EM05',
  EM06 = 'EM06',
  VOLTE = 'VOLTE',
  SMARTINFO = 'SMARTINFO',
  DASCOUNT06 = 'DASCOUNT06',
  DASCOUNT08 = 'DASCOUNT08',
  INTVOICE08 = 'INTVOICE08',
  INTSMS12 = 'INTSMS12',
  FIX_CALL_FORWARD = 'FIX_CALL_FORWARD',
  SUPERCHARGER = 'SUPERCHARGER',
  SUPERCHARGERP01 = 'SUPERCHARGERP01',
  OVERCHARGE = 'OVERCHARGE',
  DAS_OVERCHARGE = 'DAS_OVERCHARGE',
  DATA_LIMIT = 'DATA_LIMIT',
  DAS_DATA_LIMIT = 'DAS_DATA_LIMIT',
  DATA_LIMIT_DIRECT = 'DATA_LIMIT_DIRECT',
  ENJOY_MORE_COMBO = 'ENJOY_MORE_COMBO',
  ROAMING_DATA_NEW_BUSINESS_ZONE_OVERCHARGE = 'ROAMING_DATA_NEW_BUSINESS_ZONE_OVERCHARGE',
  ROAMING_DATA_NEW_BUSINESS_ZONE_DATA_LIMIT = 'ROAMING_DATA_NEW_BUSINESS_ZONE_DATA_LIMIT',
  ROAMING_DATA_NEW_BUSINESS_ZONE = 'ROAMING_DATA_NEW_BUSINESS_ZONE',
  RVOICE_NEW_BUSINESS_ZONE = 'RVOICE_NEW_BUSINESS_ZONE',
  INTVOICE_BUSINESSZONE = 'INTVOICE_BUSINESSZONE',
  PRONWEL = 'PRONWEL',
  ONWELMEMBER = 'ONWELMEMBER',
  ONWELGCLI = 'ONWELGCLI',
}

export const bssOnwServices: BssServicesEnums[] = [
  BssServicesEnums.PRONWEL,
  BssServicesEnums.ONWELMEMBER,
  BssServicesEnums.ONWELGCLI,
];
