import { ActionCreator } from 'redux';
import {
  calculateRoamingCalculator,
  getRoamingCalculatorData,
} from 'src/api/serviceManagement';
import { ICalculateRoamingCalculatorResponse } from 'src/api/serviceManagement/response/calculateRoamingCalculatorResponseInterface';
import {
  IRoamingCalculatorCalculateBeginAction,
  IRoamingCalculatorCalculateFailureAction,
  IRoamingCalculatorCalculateSuccessAction,
  IRoamingCalculatorLoadDataBeginAction,
  IRoamingCalculatorLoadDataFailureAction,
  IRoamingCalculatorLoadDataSuccessAction,
} from '../types/roamingCalculator/roamingCalculatorActionInterfaces';
import { RoamingCalculatorActionTypes } from '../types/roamingCalculator/roamingCalculatorActionTypes';
import { IRoamingCalculator } from '../types/roamingCalculator/roamingCalculatorStateInterface';

export const fetchRoamingCalculatorDataBegin: ActionCreator<
  IRoamingCalculatorLoadDataBeginAction
> = () => ({
  type: RoamingCalculatorActionTypes.ROAMING_CALCULATOR_LOAD_DATA_BEGIN,
});

export const fetchRoamingCalculatorDataSuccess: ActionCreator<
  IRoamingCalculatorLoadDataSuccessAction
> = (payload: IRoamingCalculator) => ({
  type: RoamingCalculatorActionTypes.ROAMING_CALCULATOR_LOAD_DATA_SUCCESS,
  payload,
});

export const fetchRoamingCalculatorDataFailure: ActionCreator<
  IRoamingCalculatorLoadDataFailureAction
> = () => ({
  type: RoamingCalculatorActionTypes.ROAMING_CALCULATOR_LOAD_DATA_FAILURE,
});

export const calculateRoamingCalculatorBegin: ActionCreator<
  IRoamingCalculatorCalculateBeginAction
> = () => ({
  type: RoamingCalculatorActionTypes.ROAMING_CALCULATOR_CALCULATE_BEGIN,
});

export const calculateRoamingCalculatorSuccess: ActionCreator<
  IRoamingCalculatorCalculateSuccessAction
> = (payload: { calculatorData: ICalculateRoamingCalculatorResponse }) => ({
  type: RoamingCalculatorActionTypes.ROAMING_CALCULATOR_CALCULATE_SUCCESS,
  payload,
});

export const calculateRoamingCalculatorFailure: ActionCreator<
  IRoamingCalculatorCalculateFailureAction
> = () => ({
  type: RoamingCalculatorActionTypes.ROAMING_CALCULATOR_CALCULATE_FAILURE,
});

export const fetchRoamingCalculatorData =
  (currentSubscriber?: string) => async (dispatch) => {
    dispatch(fetchRoamingCalculatorDataBegin());
    return getRoamingCalculatorData()
      .then((response) => {
        dispatch(
          fetchRoamingCalculatorDataSuccess({
            countries: response.data.countries,
            subscribers: response.data.subscribers,
            currentCountry:
              response.data.countries[
                Object.keys(response.data.countries).find((country) => country)
              ],
            currentSubscriber:
              currentSubscriber ||
              response.data.subscribers[
                Object.keys(response.data.subscribers).find(
                  (subscriber) => subscriber,
                )
              ],
          }),
        );
      })
      .catch(() => {
        dispatch(fetchRoamingCalculatorDataFailure());
      });
  };

export const roamingCalculatorCalculate =
  (country: string, subscriber: string) => async (dispatch) => {
    dispatch(calculateRoamingCalculatorBegin());
    return calculateRoamingCalculator(country, subscriber)
      .then((response) => {
        dispatch(
          calculateRoamingCalculatorSuccess({
            calculatorData: response.data,
          }),
        );
      })
      .catch(() => {
        dispatch(calculateRoamingCalculatorFailure());
      });
  };
