import { Button, Card, ICardProps, Radio, Text } from '@vodafone/red-white';
import * as React from 'react';
import styled from 'styled-components';

const TitleWrapper = styled.div`
  margin-top: 32px;
`;

const RadioWrapper = styled.div`
  margin-top: 18px;
  margin-bottom: 18px;
`;

const ButtonWrapper = styled.div`
  margin-top: 24px;
`;

export interface ICardDownloadInvoiceProps extends ICardProps {
  buttonText?: string;
  data: string[];
  defaultValue: string;
  onButtonClick?: (year: string) => void;
  radioLabel?: string;
}

const CardDownloadInvoice: React.FC<ICardDownloadInvoiceProps> = ({
  buttonText,
  data = [],
  defaultValue,
  onButtonClick,
  radioLabel,
  ...cardProps
}) => {
  const [selected, setSelected] = React.useState<string | null>(
    defaultValue || null,
  );

  return (
    <Card style={{ width: '100%', boxShadow: 'none' }} {...cardProps}>
      <TitleWrapper>
        <Text fontSize={16} color="darkGrey">
          {radioLabel}
        </Text>
      </TitleWrapper>
      <RadioWrapper>
        {data.map((item) => (
          <Radio
            key={item}
            checked={item === selected}
            id={`download-invoice-checkbox-${item}`}
            label={item}
            onActivation={() => setSelected(item)}
            inline={false}
          />
        ))}
      </RadioWrapper>
      <ButtonWrapper>
        <Button variant="primary" onClick={() => onButtonClick(selected)}>
          {buttonText}
        </Button>
      </ButtonWrapper>
    </Card>
  );
};

export default CardDownloadInvoice;
