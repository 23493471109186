import { ILoadableState } from '../../../types/loadableStateInterface';

export enum CasResultEnum {
  OK = 'OK',
  ERROR = 'ERROR',
}

export interface IRefreshSubscriptionState {
  result?: CasResultEnum;
  errorCode?: string | null;
  errorMessage?: string | null;
}

export interface IRefreshSubscriptionDataState extends ILoadableState {
  data: IRefreshSubscriptionState;
}

export interface ICasState {
  refreshSubscription: IRefreshSubscriptionDataState;
  resetPin: IRefreshSubscriptionDataState;
}
