import { Card, Loader } from '@vodafone/red-white';
import React, { FC, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import MfaContentMap from 'src/components/Modal/components/Mfa/MfaContentMap';
import { SuccessContent } from 'src/components/Modal/components/Mfa/SuccessContent';
import { ModalContentProps } from 'src/components/Modal/types/modalContentInterfaces';
import { useAppDispatch } from 'src/hooks/useAppDispatch';
import { getApplication } from 'src/store/application/applicationSelector';
import { IApplicationState } from 'src/store/appStateInterface';
import { resetMfaState } from 'src/store/mfa/mfaActions';
import {
  selectMfaRequestStatesForTypeAndServiceNumber,
  selectMfaValidationStatesForTypeAndServiceNumber,
} from 'src/store/mfa/mfaSelectors';
import {
  closeModal,
  updateModalAnalyticsOptions,
} from 'src/store/modal/modalActions';
import ModalType from 'src/store/modal/types/ModalType';
import {
  selectBillingOrCustomerAccountContactPhone,
  selectServiceNumber,
} from 'src/store/user/userSelector';
import { setPageAnalytics } from 'src/utils/analytics/actions/analyticsActions';
import styled from 'styled-components';

const CardPadding = styled.div`
  padding: 15px;
  padding-bottom: 26px;

  @media ${(props) => props.theme.breakpoints.md} {
    padding-left: 38px;
    padding-right: 38px;
    padding-top: 30px;
    padding-bottom: 20px;
  }
`;

const MfaModalContent: FC<ModalContentProps> = ({
  onClose,
  setCompact,
  setCompactContent,
  options,
}) => {
  const dispatch = useAppDispatch();
  const contactNumber = useSelector(selectBillingOrCustomerAccountContactPhone);
  const serviceNumber = useSelector(selectServiceNumber);

  const mfa = useSelector((state: IApplicationState) =>
    selectMfaRequestStatesForTypeAndServiceNumber(
      state,
      options.messageType,
      serviceNumber,
    ),
  );

  const validationStates = useSelector((state: IApplicationState) =>
    selectMfaValidationStatesForTypeAndServiceNumber(
      state,
      options.messageType,
      serviceNumber,
    ),
  );
  const { currentPage } = useSelector(getApplication);
  const isError = !contactNumber || (mfa.loaded && mfa.error);
  const isSuccess = contactNumber && mfa.loaded && !mfa.error;

  useEffect(() => {
    setCompact(true);
    setCompactContent(true);
  }, [setCompactContent, setCompact, dispatch, contactNumber, serviceNumber]);

  const updateModalAnalyticsData = useCallback(() => {
    if (isError) {
      dispatch(
        updateModalAnalyticsOptions({
          prop49AdditionalInfo: ':error',
        }),
      );
      dispatch(setPageAnalytics(currentPage));
    }

    if (isSuccess) {
      dispatch(
        updateModalAnalyticsOptions({
          prop49AdditionalInfo: '',
        }),
      );
      dispatch(setPageAnalytics(currentPage));
    }
  }, [dispatch, isError, isSuccess, currentPage]);

  useEffect(() => {
    updateModalAnalyticsData();
  }, [updateModalAnalyticsData]);

  useEffect(() => {
    if (isSuccess && mfa.verified) {
      dispatch(closeModal({ type: ModalType.Mfa }));
    }
  }, [dispatch, isSuccess, mfa.verified]);

  const ErrorComponent: React.FC<any> = MfaContentMap[options.messageType];

  return (
    <Card
      style={{ maxWidth: 700, width: '100%', boxShadow: 'none' }}
      waiting={mfa.loading}
      padding={CardPadding}
    >
      {isError && (
        <ErrorComponent
          messageType={options.messageType}
          isRateLimited={validationStates.isRateLimited}
          onClose={(e) => {
            onClose(e, undefined, {
              afterCloseFn: () => {
                dispatch(resetMfaState(options.messageType, serviceNumber));
              },
            });
          }}
        />
      )}
      {isSuccess && !mfa.verified && (
        <SuccessContent
          messageType={options.messageType}
          onSuccessCallback={options.onSuccessCallback}
          onClose={onClose}
        />
      )}
      {contactNumber && (!mfa.loaded || mfa.loading) && (
        <div style={{ height: 80 }}>
          <Loader show />
        </div>
      )}
    </Card>
  );
};

export default MfaModalContent;
