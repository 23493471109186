import { Reducer } from 'redux';
import initialState from 'src/store/initialState';
import IMfaState from 'src/store/mfa/types/mfaStateInterface';
import MfaActionTypes from 'src/store/mfa/types/mfaTypes';
import {
  IFetchVerificationSecureBeginAction,
  IFetchVerificationSecureFailureAction,
  IFetchVerificationSecureSuccessAction,
  IFetchVerificationSecureVerifyCodeBeginAction,
  IFetchVerificationSecureVerifyCodeFailureAction,
  IFetchVerificationSecureVerifyCodeSuccessAction,
  IResetMfaState,
} from 'src/store/mfa/types/mfaTypesActionInterfaces';

type IMfaActions =
  | IResetMfaState
  | IFetchVerificationSecureBeginAction
  | IFetchVerificationSecureSuccessAction
  | IFetchVerificationSecureFailureAction
  | IFetchVerificationSecureVerifyCodeBeginAction
  | IFetchVerificationSecureVerifyCodeSuccessAction
  | IFetchVerificationSecureVerifyCodeFailureAction;

const mfaReducer: Reducer<IMfaState, IMfaActions> = (
  state = initialState.Mfa,
  action,
) => {
  switch (action.type) {
    case MfaActionTypes.RESET_MFA_STATE:
      return {
        ...state,
        [action.messageType]: {
          ...state[action.messageType],
          [action.serviceNumber]: {
            ...(state[action.messageType] &&
            state[action.messageType][action.serviceNumber]
              ? state[action.messageType][action.serviceNumber]
              : {}),
            request: {
              ...(state[action.messageType] &&
              state[action.messageType][action.serviceNumber]
                ? state[action.messageType][action.serviceNumber].request
                : {}),
              loading: false,
              loaded: false,
              error: false,
              expireAfterSeconds: null,
              verified: false,
            },
          },
        },
      };

    case MfaActionTypes.FETCH_VERIFICATION_SECURE_BEGIN:
      return {
        ...state,
        [action.messageType]: {
          ...state[action.messageType],
          [action.serviceNumber]: {
            ...(state[action.messageType] &&
            state[action.messageType][action.serviceNumber]
              ? state[action.messageType][action.serviceNumber]
              : {}),
            request: {
              ...(state[action.messageType] &&
              state[action.messageType][action.serviceNumber]
                ? state[action.messageType][action.serviceNumber].request
                : {}),
              loading: true,
              loaded: false,
              error: false,
              expireAfterSeconds: null,
              verified: false,
            },
          },
        },
      };
    case MfaActionTypes.FETCH_VERIFICATION_SECURE_SUCCESS:
      return {
        ...state,
        [action.messageType]: {
          ...state[action.messageType],
          [action.serviceNumber]: {
            ...(state[action.messageType] &&
            state[action.messageType][action.serviceNumber]
              ? state[action.messageType][action.serviceNumber]
              : {}),
            request: {
              ...(state[action.messageType] &&
              state[action.messageType][action.serviceNumber]
                ? state[action.messageType][action.serviceNumber].request
                : {}),
              loading: false,
              loaded: true,
              error: false,
              expireAfterSeconds: action.payload.expireAfterSeconds,
              verified: action.payload.verified,
            },
          },
        },
      };
    case MfaActionTypes.FETCH_VERIFICATION_SECURE_FAILURE:
      return {
        ...state,
        [action.messageType]: {
          ...state[action.messageType],
          [action.serviceNumber]: {
            ...(state[action.messageType] &&
            state[action.messageType][action.serviceNumber]
              ? state[action.messageType][action.serviceNumber]
              : {}),
            request: {
              ...(state[action.messageType] &&
              state[action.messageType][action.serviceNumber]
                ? state[action.messageType][action.serviceNumber].request
                : {}),
              loading: false,
              loaded: true,
              error: true,
              expireAfterSeconds: null,
              verified: false,
            },
          },
        },
      };

    case MfaActionTypes.FETCH_VERIFICATION_SECURE_VERIFY_CODE_BEGIN:
      return {
        ...state,
        [action.messageType]: {
          ...state[action.messageType],
          [action.serviceNumber]: {
            ...(state[action.messageType] &&
            state[action.messageType][action.serviceNumber]
              ? state[action.messageType][action.serviceNumber]
              : {}),
            validation: {
              ...(state[action.messageType] &&
              state[action.messageType][action.serviceNumber]
                ? state[action.messageType][action.serviceNumber].validation
                : {}),
              loading: true,
              loaded: false,
              error: false,
              isValid: false,
            },
          },
        },
      };
    case MfaActionTypes.FETCH_VERIFICATION_SECURE_VERIFY_CODE_SUCCESS:
      return {
        ...state,
        [action.messageType]: {
          ...state[action.messageType],
          [action.serviceNumber]: {
            ...(state[action.messageType] &&
            state[action.messageType][action.serviceNumber]
              ? state[action.messageType][action.serviceNumber]
              : {}),
            validation: {
              ...(state[action.messageType] &&
              state[action.messageType][action.serviceNumber]
                ? state[action.messageType][action.serviceNumber].validation
                : {}),
              loading: false,
              loaded: true,
              error: false,
              isValid: true,
            },
          },
        },
      };
    case MfaActionTypes.FETCH_VERIFICATION_SECURE_VERIFY_CODE_FAILURE:
      return {
        ...state,
        [action.messageType]: {
          ...state[action.messageType],
          [action.serviceNumber]: {
            ...(state[action.messageType] &&
            state[action.messageType][action.serviceNumber]
              ? state[action.messageType][action.serviceNumber]
              : {}),
            validation: {
              ...(state[action.messageType] &&
              state[action.messageType][action.serviceNumber]
                ? state[action.messageType][action.serviceNumber].validation
                : {}),
              loading: false,
              loaded: true,
              error: true,
              isValid: false,
              isRateLimited: action.isRateLimited,
            },
          },
        },
      };
    default:
      return state;
  }
};

export default mfaReducer;
