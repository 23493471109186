import { Reducer } from 'redux';
import initialState from '../initialState';
import {
  IFetchCurrentSpendingBeginAction,
  IFetchCurrentSpendingFailureAction,
  IFetchCurrentSpendingSuccessAction,
} from './types/currentSpendingActionInterfaces';
import { CurrentSpendingActionTypes } from './types/currentSpendingActionTypes';
import { ICurrentSpendingState } from './types/currentSpendingStateInterface';

type SmartOverviewAction =
  | IFetchCurrentSpendingBeginAction
  | IFetchCurrentSpendingSuccessAction
  | IFetchCurrentSpendingFailureAction;

const currentSpendingReducer: Reducer<ICurrentSpendingState> = (
  state: ICurrentSpendingState = initialState.CurrentSpending,
  action: SmartOverviewAction,
) => {
  switch (action.type) {
    case CurrentSpendingActionTypes.FETCH_CURRENT_SPENDING_BEGIN:
      return {
        ...state,
        loading: true,
        loaded: false,
      };

    case CurrentSpendingActionTypes.FETCH_CURRENT_SPENDING_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        data: {
          ...state.data,
          [action.serviceNumber]: action.payload,
        },
      };

    case CurrentSpendingActionTypes.FETCH_CURRENT_SPENDING_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };

    default:
      return state;
  }
};

export default currentSpendingReducer;
