import { Link, Text } from '@vodafone/red-white';
import styled from 'styled-components';

export const Content = styled.div<{ redBg?: boolean }>`
  width: 100%;
  background: ${(props) =>
    props.redBg
      ? props.theme.colors.cardRedBackground
      : props.theme.colors.white};
  border-radius: 6px;
  padding: 12px;
  box-shadow: 0px 6px 12px ${(props) => props.theme.colors.cardBoxShadow};

  @media ${(props) => props.theme.breakpoints.lg} {
    padding: 12px 32px;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
`;

export const HeadingText = styled(Text)`
  margin-left: 8px;
`;

export const SubtitleText = styled(Text)`
  margin-top: 8px;
  font-size: 16px;
  color: ${(props) => props.theme.colors.darkGrey};

  @media ${(props) => props.theme.breakpoints.lg} {
    font-size: 24px;
    color: ${(props) => props.theme.colors.lightGrey6};
  }
`;

export const BillingWrapper = styled.div`
  display: flex;
  padding-top: 32px;
  padding-bottom: 0px;
  padding-right: 0px;
  flex-direction: column;
  gap: 20px;

  @media ${(props) => props.theme.breakpoints.lg} {
    padding-bottom: 32px;
    border-bottom: 1px solid ${(props) => props.theme.colors.lightGrey5};
    flex-direction: row;
    align-items: center;
    margin-right: 32px;
  }
`;

export const BillingFooter = styled.div`
  display: flex;
  align-items: center;
  padding: 32px 0 12px 0;
  justify-content: space-between;

  @media ${(props) => props.theme.breakpoints.lg} {
    justify-content: flex-start;
  }
`;

export const BillingWrapperDetail = styled.div`
  @media ${(props) => props.theme.breakpoints.lg} {
    margin-left: 32px;
  }
`;

export const PaymentLink = styled(Link)`
  font-size: 18px;
  color: ${(props) => props.theme.colors.darkGrey};
  font-family: ${(props) => props.theme.fonts.VodafoneRg};
`;

export const BillingText = styled(Text)`
  white-space: pre;
`;
