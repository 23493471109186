enum GenericMicrositesActionTypes {
  STORE_MS_DATA = 'STORE_MS_DATA',
  STORE_MS_DATA_ERROR = 'STORE_MS_DATA_ERROR',
  SET_PARAMS_TO_OFFERS = 'SET_PARAMS_TO_OFFERS',
  SET_FLOW_STATE = 'SET_FLOW_STATE',
  START_LOADING = 'START_LOADING',
  LEAD_SEND = 'LEAD_SEND',
  LEAD_START_SENDING = 'LEAD_START_SENDING',
  LEAD_STORE_RESULT = 'LEAD_STORE_RESULT',
  SET_FORCE_PAGE_RELOAD = 'SET_FORCE_PAGE_RELOAD',
}

export default GenericMicrositesActionTypes;
