import { IMainMenuItem } from '@vodafone/red-white';
import { NavigationItem } from 'src/features/layout';
import { RoutesEnum } from '../../../config/routes';
import { getEnumKeyByEnumValue } from '../../../utils/enums';

export const getRouteIdFromMenuItem = (
  item: IMainMenuItem | NavigationItem,
): RoutesEnum | null => {
  const spaRoutes: string[] = Object.values(RoutesEnum);
  const foundedKey = getEnumKeyByEnumValue(RoutesEnum, item.routeId);

  if (item.routeId && spaRoutes.includes(item.routeId) && foundedKey) {
    return RoutesEnum[foundedKey];
  }

  return null;
};
