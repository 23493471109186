import {
  Col,
  Container,
  Layout,
  Message,
  Row,
  Text,
  Title,
} from '@vodafone/red-white';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { logComponentError } from 'src/components/ErrorBoundary/actions/logActions';
import ErrorBoundary from 'src/components/ErrorBoundary/components/ErrorBoundary';
import ErrorMessage from 'src/components/ErrorBoundary/components/ErrorMessage';
import Footer from 'src/components/Footer';
import InactiveUserModal from 'src/components/InactiveUserModal';
import Modal from 'src/components/Modal/components/Modal';
import { Navigation } from 'src/components/Navigation';
import { useCoreOutage } from 'src/features/core';
import { useAppDispatch } from 'src/hooks/useAppDispatch';
import UserRoleEnum from 'src/store/user/types/UserRoleEnum';
import userSelector, {
  selectIsUserWithRole,
} from 'src/store/user/userSelector';
import styled from 'styled-components';
import { IApplicationState } from '../store/appStateInterface';

interface IBasicLayoutProps extends RouteComponentProps {
  children?: React.ReactNode;
  status?: React.ReactNode;
  title?: string;
  titleAlign?: 'center' | 'left';
  header?: {
    title: string;
    subtitle: string;
  };
  narrowerWidth?: boolean;
  hideVatFooter?: boolean;
}

export const StyledMainWrapper = styled.div``;

const FooterBanner = styled.div`
  width: 100%;
  line-height: 50px;
  height: 50px;
  text-align: center;
  background-color: ${(props) => props.theme.colors.white};
`;

const BasicLayout: React.FC<IBasicLayoutProps> = ({
  children,
  match,
  status,
  title,
  titleAlign,
  header,
  narrowerWidth,
  hideVatFooter = false,
}) => {
  const dispatch = useAppDispatch();
  const user = useSelector(userSelector);
  const outage = useCoreOutage();
  const isForcedCiMigrationUser: boolean = useSelector(
    (state: IApplicationState) =>
      selectIsUserWithRole(state, [UserRoleEnum.ROLE_FORCED_MIGRATION]),
  );

  const { t } = useTranslation(['layout', 'component']);

  const content = (
    // @ts-ignore
    <ErrorBoundary
      onError={(error: Error, componentStack: string) =>
        dispatch(logComponentError(error, componentStack))
      }
      FallbackComponent={ErrorMessage}
    >
      {outage.data.isFullOutage && (
        <Container fluid>
          <Row justify="center" gutter={16}>
            <Col dataAutomationId="read-mode-message">
              <Message
                type="info"
                message={
                  <div>
                    <b>{t('component:readModeInfoMessage.title')}</b>
                    <p>
                      {t('component:readModeInfoMessage.fullOutageMessage')}
                    </p>
                  </div>
                }
              />
            </Col>
          </Row>
        </Container>
      )}

      {outage.data.isReadMode && (
        <Container fluid>
          <Row justify="center" gutter={16}>
            <Col dataAutomationId="read-mode-message">
              <Message
                type="info"
                message={
                  <div>
                    <b>{t('component:readModeInfoMessage.title')}</b>
                    <p>{t('component:readModeInfoMessage.message')}</p>
                  </div>
                }
              />
            </Col>
          </Row>
        </Container>
      )}
      {children}
    </ErrorBoundary>
  );

  const footer = (
    <Footer match={match} showFooterNavigation={!isForcedCiMigrationUser} />
  );

  const showTitle = !user || (user && !user.isWebView);

  return (
    <>
      <InactiveUserModal />
      <StyledMainWrapper>
        <Navigation />
        {(title || (!title && narrowerWidth)) && (
          <>
            <Layout>
              <Container maxWidth="960px">
                {showTitle && title && (
                  <Title
                    level={2}
                    color="darkGrey"
                    style={{
                      textAlign: titleAlign || 'center',
                      paddingBottom: 25,
                    }}
                  >
                    {title}
                  </Title>
                )}
                {content}
              </Container>
            </Layout>

            {!hideVatFooter && (
              <FooterBanner>
                <Text color="black" fontSize={16}>
                  {t('layout:footer.include-vat')}
                </Text>
              </FooterBanner>
            )}
            {footer}
          </>
        )}
        {status && (
          <>
            {showTitle && <Layout type="primary">{status}</Layout>}
            <Layout overlapContent>{content}</Layout>
            {footer}
          </>
        )}
        {header && (
          <>
            <Layout header={showTitle ? header : {}}>{content}</Layout>
            {footer}
          </>
        )}
        {!title && !status && !header && !narrowerWidth && (
          <>
            <Layout>{content}</Layout>
            {footer}
          </>
        )}
        {/* @ts-ignore */}
        <Modal match={match} />
      </StyledMainWrapper>
    </>
  );
};

export default withRouter(BasicLayout);
