import {
  Button,
  IconInfo,
  IconSolutionFinder,
  IconWarning,
  ModalMessage,
  ThemeColorTypes,
} from '@vodafone/red-white';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import ModalMessageTypeEnum from 'src/components/Modal/components/ModalMessage/types/ModalMessageTypeEnum';
import styled from 'styled-components';

const Types = {
  [ModalMessageTypeEnum.SUCCESS]: {
    icon: <IconSolutionFinder color="green" size={60} />,
    heading: 'component:modalMessage.success-message-title',
    color: 'green' as ThemeColorTypes,
  },
  [ModalMessageTypeEnum.ERROR]: {
    icon: <IconWarning color="red" size={60} />,
    heading: 'component:modalMessage.error-message-title',
    color: 'primaryColor' as ThemeColorTypes,
  },
  [ModalMessageTypeEnum.FAILURE]: {
    icon: <IconWarning color="red" size={60} />,
    heading: 'component:modalMessage.failure-message-title',
    color: 'primaryColor' as ThemeColorTypes,
  },
  [ModalMessageTypeEnum.INFO]: {
    icon: <IconInfo color="orange" size={60} />,
    heading: 'component:modalMessage.info-message-title',
    color: 'orange' as ThemeColorTypes,
  },
};

const ModalWrappper = styled.div`
  max-width: 600px;
`;

const DescriptionWrapper = styled.div`
  padding: 0 20px;
`;

const ButtonWrapper = styled.div`
  text-align: center;
  margin: 20px 0;
`;

interface IModalMessageOverviewProps {
  heading?: string;
  icon?: React.ReactNode;
  description?: React.ReactNode;
  color?: ThemeColorTypes;
  button?: {
    label: string;
    onClick: () => void;
  };
  type: ModalMessageTypeEnum;
  fullSize?: boolean;
}

const ModalMessageTemplate: FC<IModalMessageOverviewProps> = ({
  heading,
  icon,
  description,
  color,
  button,
  type = ModalMessageTypeEnum.SUCCESS,
  fullSize = false,
}) => {
  const { t } = useTranslation('component');

  return (
    <ModalWrappper>
      <ModalMessage
        heading={heading || t(Types[type].heading)}
        icon={icon || Types[type].icon}
        color={color || Types[type].color}
        fullSize={fullSize}
      >
        <DescriptionWrapper>{description}</DescriptionWrapper>
        {button && (
          <ButtonWrapper>
            <Button
              htmlElement="button"
              onClick={button.onClick}
              variant="primary"
            >
              {button.label}
            </Button>
          </ButtonWrapper>
        )}
      </ModalMessage>
    </ModalWrappper>
  );
};

export default ModalMessageTemplate;
