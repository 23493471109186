import { AxiosResponse } from 'axios';
import i18next from 'i18next';
import { API_ENDPOINT_ESIM_DOWNLOAD_QR_CODE } from 'src/api';
import { IRestResponseFault } from 'src/api/types/IRestResponseFault';
import api from 'src/utils/api/api';

export const eSimDownloadQRCodeUrl = (serviceNumber: string) => {
  const url = API_ENDPOINT_ESIM_DOWNLOAD_QR_CODE.replace(
    '{serviceNumber}',
    serviceNumber,
  );

  if (i18next.language === undefined) {
    return url;
  }

  return `${url}?language=${i18next.language}`;
};

export default (
  serviceNumber: string,
): Promise<AxiosResponse<string | IRestResponseFault>> =>
  api.get({
    url: eSimDownloadQRCodeUrl(serviceNumber),
  });
