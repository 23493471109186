export enum CookieNameEnum {
  LAST_OK_PAYMENT = 'lastOkPayment',
}

export enum CookiePaymentStatusEnum {
  OLD_REQUEST = 'oldRequest',
  LAST_REQUEST = 'lastRequest',
  PAID = 'paid',
}

export const getCookie = (cName: string): string => {
  const cookie = {};

  document.cookie.split(';').forEach((one) => {
    const [key, value] = one.split('=');

    cookie[key.trim()] = value;
  });
  return cookie[cName];
};

export const getCookieParsed = (cName: string) => {
  const cookieString = getCookie(cName);

  if (!cookieString) return null;
  try {
    return JSON.parse(cookieString);
  } catch (error) {
    return null;
  }
};

export const setCookie = (
  cName: string,
  cValue: string,
  cExpiredHours: number,
) => {
  const d = new Date();

  d.setTime(d.getTime() + cExpiredHours * 60 * 60 * 1000);
  const expires = `expires=${d.toUTCString()}`;

  document.cookie = `${cName}=${cValue};${expires};path=/`;
};

export const setCookieStringify = (
  cName: string,
  cValue: any,
  cExpireHours: number,
) => {
  const cString = JSON.stringify(cValue);

  setCookie(cName, cString, cExpireHours);
};

type OptinCookieType = {
  strictlyNecessary: boolean;
  functional: boolean;
  performance: boolean;
  marketing: boolean;
};

export const getOptinCookies = (): OptinCookieType => {
  const optInCookies: OptinCookieType = {
    strictlyNecessary: true,
    functional: false,
    performance: false,
    marketing: false,
  };

  const vfConsentsCookieValues = getCookie('vfconsents');

  const functionalAndPerformanceMatch =
    vfConsentsCookieValues?.match(/funa:([a-z])/);

  optInCookies.performance = optInCookies.functional =
    (functionalAndPerformanceMatch &&
      functionalAndPerformanceMatch !== null &&
      functionalAndPerformanceMatch[1] === 'i') ||
    false;

  const marketingMatch = vfConsentsCookieValues?.match(/mktg:([a-z])/);
  optInCookies.marketing =
    (marketingMatch && marketingMatch !== null && marketingMatch[1] === 'i') ||
    false;

  return optInCookies;
};

export const transformOptinCookiesToUrlParameter = (): string => {
  const mapOptinCookieToRouteParam = {
    strictlyNecessary: 'r',
    functional: 'f',
    performance: 'p',
    marketing: 'm',
  };

  return Object.entries(getOptinCookies())
    .map(
      ([key, value]) =>
        `${mapOptinCookieToRouteParam[key]}${value ? 'y' : 'n'}`,
    )
    .join('-');
};

export const getVisitorIdCookie = (): string | undefined => {
  const match = getCookie('utag_main')?.match(/v_id:(.*?)(?=\$)/);
  return match && match[1] ? match[1] : undefined;
};

export const getAcrCookie = (): string | undefined => {
  const match = getCookie('s_sv_p46')?.match(/pacr3:(.*?)(?=\|)/);
  return match && match[1] ? match[1] : undefined;
};
