import { AxiosResponse } from 'axios';
import { API_ENDPOINT_ESIM_DETAIL } from 'src/api';
import IESimFetchDetailSuccessResponse from 'src/api/esim/response/eSimFetchDetailSuccessResponse';
import { IRestResponseFault } from 'src/api/types/IRestResponseFault';
import api from 'src/utils/api/api';

export default (
  serviceNumber: string,
): Promise<
  AxiosResponse<IESimFetchDetailSuccessResponse | IRestResponseFault>
> =>
  api.get({
    url: API_ENDPOINT_ESIM_DETAIL.replace('{serviceNumber}', serviceNumber),
  });
