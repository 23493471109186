import * as React from 'react';
import { useSelector } from 'react-redux';
import Currency from 'src/components/Currency';
import CurrencyEnum from 'src/components/Price/enums/CurrencyEnum';
import selectLanguage from 'src/store/language/languageSelector';
import { LanguagesEnum } from 'src/types/translation/languagesEnum';
interface IPrice {
  price: string | number;
  currency?: string;
  period?: string;
}

const Price: React.FC<IPrice> = ({ price, currency, period }) => {
  const language: LanguagesEnum = useSelector(selectLanguage);
  const p = typeof price === 'string' ? parseFloat(price) : price;
  let c = currency;
  let pattern = '#,##0.00 !';

  if (!currency) {
    c = language === LanguagesEnum.CS ? 'Kč' : CurrencyEnum.CZK;
  }

  if (p % 1 === 0) {
    pattern = '#,##0 !';
  }

  return (
    <>
      <Currency quantity={p} currency={c} pattern={pattern} />
      {period ? ` / ${period}` : ''}
    </>
  );
};

export default Price;
