import { createSelector, Selector } from 'reselect';
import { ICurrentSpendingResponse } from 'src/api/currentSpending/response/currentSpendingResponseInterface';
import { IApplicationState } from '../appStateInterface';
import { ICurrentSpendingState } from './types/currentSpendingStateInterface';

export const getCurrentSpending: Selector<
  IApplicationState,
  ICurrentSpendingState
> = (state: IApplicationState) => state.CurrentSpending;

export const selectIsCurrentSpendingLoadedForServiceNumber = (
  state: IApplicationState,
  serviceNumber: string,
): boolean =>
  createSelector(
    [getCurrentSpending],
    (currentSpending) =>
      !!(
        serviceNumber &&
        Object.keys(currentSpending.data).length &&
        currentSpending.data[serviceNumber]
      ),
  )(state);

export const selectCurrentSpending = (
  state: IApplicationState,
  serviceNumber: string,
): ICurrentSpendingResponse | null =>
  createSelector([getCurrentSpending], (currentSpending) =>
    serviceNumber &&
    Object.keys(currentSpending.data).length &&
    currentSpending.data[serviceNumber]
      ? currentSpending.data[serviceNumber]
      : null,
  )(state);

export const selectCurrentSpendingLoading: Selector<
  IApplicationState,
  boolean
> = createSelector(
  [getCurrentSpending],
  (currentSpending: ICurrentSpendingState) => currentSpending.loading,
);

export const selectCurrentSpendingLoaded: Selector<IApplicationState, boolean> =
  createSelector(
    [getCurrentSpending],
    (currentSpending: ICurrentSpendingState) => currentSpending.loaded,
  );

export const selectCurrentSpendingError: Selector<IApplicationState, boolean> =
  createSelector(
    [getCurrentSpending],
    (currentSpending: ICurrentSpendingState) => currentSpending.error,
  );
