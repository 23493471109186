import { ActionCreator } from 'redux';
import modalOptionsMap from 'src/components/Modal/config/modalOptionsMap';
import { IModalOption } from 'src/components/Modal/types/modalOptionInterfaces';
import { getRoute, loginRoutes, RoutesEnum } from 'src/config/routes';
import { setCurrentPage } from 'src/store/application/applicationActions';
import { getApplication } from 'src/store/application/applicationSelector';
import {
  selectPaymentInfo,
  selectPaymentInfoForBaNumber,
} from 'src/store/billing/paymentInfo/paymentInfoSelectors';
import PaymentStatusEnum from 'src/store/billing/paymentStatus/types/paymentStatusEnum';
import selectLanguage from 'src/store/language/languageSelector';
import { resetaModalStore } from 'src/store/modal/modalActions';
import { getModal } from 'src/store/modal/modalSelectors';
import ModalType from 'src/store/modal/types/ModalType';
import IBillingAccount from 'src/store/user/types/billingAccountInterface';
import UserRoleEnum from 'src/store/user/types/UserRoleEnum';
import selectUser, { selectBillingAccount } from 'src/store/user/userSelector';
import BillingStatusEnum from 'src/types/billing/billingStatusEnum';
import { CustomAnalyticsProps } from 'src/utils/analytics/interface/customPropsInterface';
import IAction from '../../../types/actionInterface';
import { LanguagesEnum } from '../../../types/translation/languagesEnum';
import * as AnalyticsEnum from '../constats/analyticsConstants';
import { ISetPageAnalytics } from '../interface/setPageAnalyticsInterface';
import { mapRouteToAnalytics } from '../utils/mapRouteToAnalytics';

const doesAnalyticsExists = () =>
  window && typeof (window as any).s !== 'undefined';

const sendAnalytics = () => {
  if (typeof (window as any).s.t === 'function') {
    (window as any).s.t();
  }
};

const prop20creator = (path: string, language: string): string => {
  const reducedPageName = path
    .substring(AnalyticsEnum.PREFIX.length)
    .replace(`${LanguagesEnum.EN}/`, '');
  const languageConst =
    language === LanguagesEnum.CS
      ? AnalyticsEnum.PAGE_LANGUAGE_CZ
      : AnalyticsEnum.PAGE_LANGUAGE_EN;

  let finallPageName = `${reducedPageName
    .charAt(0)
    .toUpperCase()}${reducedPageName.substr(1)}`;

  if (
    getRoute(path)?.id === RoutesEnum.SUCCESSFUL_PAYMENT ||
    getRoute(path)?.id === RoutesEnum.UNSUCCESSFUL_PAYMENT
  ) {
    const [pageNameFixPart, pageNameVariants] = finallPageName.split(/[()]+/);

    finallPageName =
      pageNameFixPart +
      pageNameVariants
        .split('|')
        .find((variant) => window.location.pathname.includes(variant));
  }

  return `${languageConst}:${finallPageName}`;
};

const prop7creator = (signedIn: boolean): string => {
  return signedIn
    ? AnalyticsEnum.ANALYTICS_LOGGED_IN
    : AnalyticsEnum.ANALYTICS_LOGGED_OUT;
};

const pageAnalyticsConfig = (
  path: string,
  state,
  dispatch: any,
  formName?: string,
  pageNameSuffix?: string,
  additionalEvents?: string[],
  customProps?: CustomAnalyticsProps,
) => {
  const language = selectLanguage(state);
  const pageName = mapRouteToAnalytics(path, language, pageNameSuffix);
  const prop20 = prop20creator(path, language);
  const user = selectUser(state);
  const applicationState = getApplication(state);
  const prop7 = prop7creator(user.signedIn);
  const modal = getModal(state);
  const paymentInfo = selectPaymentInfo(state);
  const billingAccount: IBillingAccount = selectBillingAccount(state);
  const paymentInfoForBaNumber = selectPaymentInfoForBaNumber(
    state,
    billingAccount ? billingAccount.billingAccountNumber : null,
  );
  const events = [];
  let eVar107 = '';

  const data: any = {
    ...customProps,
    prop7,
    channel: 'WSC',
    pageName,
    pageNameHelper: pageName,
    prop20,
  };
  const payBillTransaction = () => {
    events.push('event7');
    data.eVar19 = 'transaction';
    data.eVar18 = 'pay bill (transaction)';
  };

  const afterSuccessfulLogin = () => {
    if (
      user.signedIn &&
      applicationState.previousPage &&
      applicationState.currentPage &&
      applicationState.previousPage !== applicationState.currentPage &&
      loginRoutes.includes(getRoute(applicationState.previousPage)?.id)
    ) {
      const hasCIActivated = user.roles.includes(UserRoleEnum.ROLE_TYPE_PA);
      const isExUPC = user.roles.includes(
        UserRoleEnum.ROLE_IS_UPC_MIGRATION_FLAG_TRUE,
      );

      if (user.signedIn) {
        if (data.events && !data.events.includes('event6')) {
          events.push('event6');
        } else if (!data.events) {
          events.push('event6');
        }

        if (user.globalPageData['s.eVar107']) {
          if (
            data.eVar107 &&
            data.eVar107 !== user.globalPageData['s.eVar107']
          ) {
            eVar107 = user.globalPageData['s.eVar107'];
          } else if (!data.eVar107) {
            eVar107 = user.globalPageData['s.eVar107'];
          }
        }
      }
      if (hasCIActivated) {
        events.push('event206');
      }
      if (isExUPC) {
        events.push('event183');
      }
      if (
        getRoute(applicationState.previousPage)?.id ===
        RoutesEnum.PERSONAL_ACCOUNT_REGISTRATION_CONFIRMATION
      ) {
        events.push('event5');
      }
    }
  };

  if (user && user.globalPageData) {
    ['s.events', 's.prop8', 's.prop9', 's.eVar107'].forEach((key) => {
      if (user.globalPageData[key]) {
        const values = user.globalPageData[key];
        let valuesArray = values.split(',');

        valuesArray = valuesArray.filter(
          (_, index) =>
            ![
              'event6',
              'otp',
              'msisdn',
              'email',
              'payer',
              'fbb',
              'vtv',
            ].includes(valuesArray[index]),
        );

        data[key.replace('s.', '')] = valuesArray.join(',');
      }
    });
  }

  afterSuccessfulLogin();

  if (
    (modal.isOpen && modal.type) ||
    modal?.analyticsOptions?.sendAnalyticsAfterModalClosed
  ) {
    const modalOptions: IModalOption = modalOptionsMap[modal.type];

    const prop49AdditionalInfo =
      (modal?.analyticsOptions?.analyticsText || '') +
      (modal?.analyticsOptions?.prop49AdditionalInfo || '');

    data.prop49 = `modal:${
      modalOptions && modalOptions.analyticsProp49Name
        ? modalOptions.analyticsProp49Name
        : (modal.type || '') + prop49AdditionalInfo
    }`;

    const prop16AdditionalInfo =
      modal?.analyticsOptions?.prop16AdditionalInfo || '';

    if (prop16AdditionalInfo) {
      data.prop16 = prop16AdditionalInfo;
    }

    if (modal.type === ModalType.PaymentInfo) {
      payBillTransaction();
    }

    if (modal.type === ModalType.PaymentStatus) {
      if (modal.options && modal.options.paymentStatus) {
        const targetMsisdn =
          modal.options.sourceMsisdn === modal.options.targetMsisdn
            ? 'OWN'
            : 'OTHER';

        const paymentType =
          window.location.pathname &&
          ['dobiti', 'recharge', 'atu'].some((topUp) =>
            window.location.pathname.includes(topUp),
          )
            ? 'TOPUP'
            : 'INVOICE';

        if (modal.options.paymentStatus === PaymentStatusEnum.SUCCESS) {
          // OK payment
          const timestamp = Date.now();

          data.products = `WSC;${targetMsisdn}-${paymentType};1;${modal.options.totalAmount}`;
          data.prop41 = 'standard:debit card';
          data.eVar19 = 'transaction';
          data.eVar18 =
            paymentType === 'TOPUP'
              ? 'topup (transaction)'
              : 'pay bill (transaction)';
          events.push('event29,event8,event41,purchase');
          data.purchaseID = `WSC${timestamp}${modal.options.paymentId}`;
          data.pageName = 'WSC:Platba:OK';
        } else {
          // NOK payment
          data.products = `WSC;${targetMsisdn}-${paymentType};1;${modal.options.totalAmount}`;

          data.prop41 = 'standard:debit card';
          events.push('event28');
          data.pageName = 'WSC:Platba:NOK';
        }
      }
    }
  } else if (!modal.isOpen && modal.type) {
    const modalOptions: IModalOption = modalOptionsMap[modal.type];

    data.prop49 = `modal:${
      modalOptions && modalOptions.analyticsProp49Name
        ? modalOptions.analyticsProp49Name
        : modal.type
    }-close`;

    if (modal.options && modal.options.closedBy) {
      data.ActivityMap = {
        Link:
          modal.options.closedBy +
          (modal.options.closedOptions && modal.options.closedOptions.buttonText
            ? `:${modal.options.closedOptions.buttonText}`
            : ''),
      };
    }

    dispatch(resetaModalStore());
  }

  if (pageName.includes('Billing')) {
    data.eVar18 = 'View Bill';
    data.eVar19 = 'transaction';
    if (
      paymentInfo.loaded &&
      paymentInfoForBaNumber &&
      (paymentInfoForBaNumber.status ===
        BillingStatusEnum.UNPAID_INCLUDE_OVERDUE ||
        paymentInfoForBaNumber.status === BillingStatusEnum.UNPAID_OVERDUE) &&
      paymentInfoForBaNumber.dueAmount > 120
    ) {
      payBillTransaction();
    }
  }

  if (formName) {
    data.prop49 = `form:${formName}`;
    data.prop73 = `form:${formName}`;
  }

  if (additionalEvents?.length) {
    additionalEvents.forEach((event) => event && events.push(event));
  }

  if (data.events) {
    data.events = `${data.events}${
      data.events.length !== 0 && events.length !== 0 ? ',' : ''
    }${events.join()}`;
  } else if (!data.events) {
    data.events = events.join();
  }

  if (data.eVar107) {
    data.eVar107 = `${data.eVar107}${
      data.eVar107.length !== 0 && eVar107.length !== 0 ? ',' : ''
    }${eVar107}`;
  } else if (!data.eVar107) {
    data.eVar107 = eVar107;
  }

  return data;
};

export const setPageAnalyticsAction: ActionCreator<ISetPageAnalytics> = (
  payload,
): IAction => ({
  type: AnalyticsEnum.PAGE_ANALYTICS_SET,
  payload: {
    payload,
  },
});

export function setPageAnalytics(
  path: string,
  formName?: string,
  pageNameSuffix?: string,
  additionalEvents?: string[],
  customProps?: CustomAnalyticsProps,
): any {
  return (dispatch, getState) => {
    dispatch(setCurrentPage(path));
    const state = getState();

    const pageAnalyticsParams = pageAnalyticsConfig(
      path,
      state,
      dispatch,
      formName,
      pageNameSuffix,
      additionalEvents,
      customProps,
    );
    const s = Object.keys(pageAnalyticsParams).reduce(
      (accumulator, currentValue) => {
        accumulator[currentValue] = pageAnalyticsParams[currentValue];

        return accumulator;
      },
      typeof (window as any).s === 'object' ? (window as any).s : {},
    );

    if (typeof s.t === 'function') {
      s.t();
    }

    dispatch(setPageAnalyticsAction());
  };
}

export const registrationByEmail = (code: string, errorCode?: string) => {
  try {
    if (window && typeof (window as any).s !== 'undefined') {
      (window as any).s.events = 'event11';
      (window as any).s.eVar20 = 'form:registrationByEmail';
      (
        window as any
      ).s.list2 = `Overovaci kod: ${code} resulted in ${errorCode}`;
      if (typeof (window as any).s.t === 'function') {
        (window as any).s.t();
      }
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Analytics action registrationByEmail failed');
  }
};

export const internetPayBill = () => {
  try {
    if (window && typeof (window as any).s !== 'undefined') {
      (window as any).s.events = 'event7';
      (window as any).s.eVar19 = 'transaction';
      (window as any).s.eVar18 = 'pay bill (transaction)';
      if (typeof (window as any).s.t === 'function') {
        (window as any).s.t();
      }
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Analytics action InternetPay failed');
  }
};

export const notOkDashboardAnalytics = (status: string) => {
  try {
    if (doesAnalyticsExists()) {
      (window as any).s.pageName = `WSC:Dashboard:NOK:${status}`;
      sendAnalytics();
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Analytics action notOkDashboardAnalytics failed');
  }
};

export const onRecurringDataOfferActivation = (productOfferingId?: string) => {
  try {
    if (doesAnalyticsExists() && productOfferingId) {
      window.s.products = `WSC;${productOfferingId};1;1;0`;
      window.s.events = `purchase`;
      sendAnalytics();
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Analytics action onRecurringDataOfferActivation failed');
  }
};

const shouldSelectRole = () => {
  if (doesAnalyticsExists()) {
    (window as any).s.events = 'event5, event6';
    sendAnalytics();
  }
};

const hasWeakPassword = () => {
  if (doesAnalyticsExists()) {
    (window as any).s.pageName = 'WSC:Prihlaseni:Slabe heslo';
    sendAnalytics();
  }
};

const setErrorMessage = (errorMessage: string, statusCode?: string) => {
  if (doesAnalyticsExists()) {
    (window as any).s.prop16 = `${
      statusCode ? `${statusCode}:` : ''
    }${errorMessage}`;
    sendAnalytics();
  }
};

const onSelectTab = (tabName: string) => {
  if (doesAnalyticsExists()) {
    (window as any).s.prop49 = `tab:${tabName}`;
    sendAnalytics();
  }
};

const onFormSubmit = (formName: string) => {
  if (doesAnalyticsExists()) {
    (window as any).s.prop49 = `form:${formName}`;
    sendAnalytics();
  }
};

interface loginAnalyticsIProps {
  shouldSelectRole: () => void;
  hasWeakPassword: () => void;
  setErrorMessage: (errorMessage: string) => void;
  onSelectTab: (tabName: string) => void;
  onFormSubmit: (formName: string) => void;
}

export const loginAnalytics: loginAnalyticsIProps = {
  shouldSelectRole,
  hasWeakPassword,
  setErrorMessage,
  onSelectTab,
  onFormSubmit,
};
