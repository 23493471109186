import i18next from 'i18next';
import { API_DEFAULT_ENDPOINT } from 'src/api/endpoints/endpoints';
import { LanguagesEnum } from '../../types/translation/languagesEnum';

export const translateApiEndpoint = (route: string): string => {
  const { language } = i18next;

  if (language === LanguagesEnum.EN) {
    return route.replace(
      `${API_DEFAULT_ENDPOINT}/`,
      `${API_DEFAULT_ENDPOINT}/en/`,
    );
  }

  return route;
};
