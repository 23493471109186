import { ActionCreator } from 'redux';
import { ITariffOverview } from 'src/api/serviceManagement/response/getServicesResponseInterface';
import {
  IFetchTariffOverviewBeginAction,
  IFetchTariffOverviewFailureAction,
  IFetchTariffOverviewSuccessAction,
  IToggleTariffOverviewAction,
} from '../types/tariffOverview/tariffOverviewActionInterfaces';
import { TariffOverviewActionTypes } from '../types/tariffOverview/tariffOverviewActionTypes';

export const fetchTariffOverviewBegin: ActionCreator<
  IFetchTariffOverviewBeginAction
> = () => ({
  type: TariffOverviewActionTypes.FETCH_TARIFF_OVERVIEW_BEGIN,
});

export const fetchTariffOverviewSuccess: ActionCreator<
  IFetchTariffOverviewSuccessAction
> = (payload: ITariffOverview) => ({
  type: TariffOverviewActionTypes.FETCH_TARIFF_OVERVIEW_SUCCESS,
  payload,
});

export const fetchTariffOverviewFailure: ActionCreator<
  IFetchTariffOverviewFailureAction
> = () => ({
  type: TariffOverviewActionTypes.FETCH_TARIFF_OVERVIEW_FAILURE,
});

export const toggleTariffOverview: ActionCreator<
  IToggleTariffOverviewAction
> = (isOpen: boolean) => ({
  type: TariffOverviewActionTypes.TOGGLE_TARIFF_OVERVIEW,
  isOpen,
});

export const onToggleTariffOverview =
  (currentState: boolean) => async (dispatch) => {
    dispatch(toggleTariffOverview(!currentState));
  };
