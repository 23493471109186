import { createSelector, Selector } from 'reselect';
import { IApplicationState } from '../appStateInterface';
import IUsageConsumptionState from './types/usageConsumptionStateInterface';

export const getUsageConsumption: Selector<
  IApplicationState,
  IUsageConsumptionState
> = (state: IApplicationState) => state.UsageConsumption;

export const selectUsageConsumptionForBaNumber = (
  state: IApplicationState,
  baNumber: string = null,
) =>
  createSelector(
    [getUsageConsumption],
    (usageConsomption) =>
      usageConsomption.data[baNumber || usageConsomption.selectedBaNumber],
  )(state);
