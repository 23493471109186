import { Reducer } from 'redux';
import initialState from '../../initialState';
import { IMoreServiceState } from '../types/moreServiceStateInterface';
import {
  IExpandGroupAfterMoreServicesLoadedBeginAction,
  IExpandGroupAfterMoreServicesLoadedSuccessAction,
  IFetchMoreServicesBeginAction,
  IFetchMoreServicesFailureAction,
  IFetchMoreServicesSuccessAction,
} from '../types/service/serviceActionInterfaces';
import { ServiceActionTypes } from '../types/service/serviceActionTypes';

type MoreServiceAction =
  | IFetchMoreServicesBeginAction
  | IFetchMoreServicesSuccessAction
  | IFetchMoreServicesFailureAction
  | IExpandGroupAfterMoreServicesLoadedBeginAction
  | IExpandGroupAfterMoreServicesLoadedSuccessAction;

const moreServicesReducer: Reducer<IMoreServiceState> = (
  state: IMoreServiceState = initialState.Services.moreServices,
  action: MoreServiceAction,
) => {
  switch (action.type) {
    case ServiceActionTypes.FETCH_MORE_SERVICES_BEGIN: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      };
    }

    case ServiceActionTypes.FETCH_MORE_SERVICES_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }

    case ServiceActionTypes.FETCH_MORE_SERVICES_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }

    case ServiceActionTypes.EXPAND_GROUP_AFTER_MORE_SERVICES_LOADED_BEGIN: {
      return {
        ...state,
        groupToExpand: action.section,
      };
    }

    case ServiceActionTypes.EXPAND_GROUP_AFTER_MORE_SERVICES_LOADED_SUCCESS: {
      return {
        ...state,
        groupToExpand: null,
      };
    }

    default: {
      return state;
    }
  }
};

export default moreServicesReducer;
