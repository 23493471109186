import { AxiosResponse } from 'axios';
import { API_ENDPOINT_VTV_GENERATE_PASSWORD_DEVICE } from '../..';
import api from '../../../utils/api/api';

export default (
  serviceNumber: string,
  contactPhoneNumber: string,
  customerEmail: string = null,
): Promise<AxiosResponse> =>
  api.post({
    url: API_ENDPOINT_VTV_GENERATE_PASSWORD_DEVICE.replace(
      '{serviceNumber}',
      serviceNumber,
    ),
    data: {
      contactPhoneNumber,
      customerEmail,
    },
  });
