import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { VerificationSecureMessageType } from 'src/api/mfa/request/verificationSecureRequest';
import { SILVER_TOKEN } from 'src/features/auth/hooks/useGoldTokenGuard';
import { useAppDispatch } from 'src/hooks/useAppDispatch';
import { IApplicationState } from 'src/store/appStateInterface';
import { fetchVerificationSecure } from 'src/store/mfa/mfaActions';
import { selectMfaRequestStatesForTypeAndServiceNumber } from 'src/store/mfa/mfaSelectors';
import { openModal } from 'src/store/modal/modalActions';
import ModalType from 'src/store/modal/types/ModalType';
import userSelector, { selectServiceNumber } from 'src/store/user/userSelector';

interface IProps {
  messageType: VerificationSecureMessageType;
  onSuccessCallback: () => void;
  contactNumber?: string;
}

export const useMfa = ({
  messageType,
  onSuccessCallback,
  contactNumber,
}: IProps) => {
  const dispatch = useAppDispatch();
  const cn = useSelector(selectServiceNumber);
  const phoneNumber = contactNumber || cn;
  const [verifying, setVerifying] = useState(false);
  const { isWebView, tokenLevel } = useSelector(userSelector);

  const mfa = useSelector((state: IApplicationState) =>
    selectMfaRequestStatesForTypeAndServiceNumber(
      state,
      messageType,
      phoneNumber,
    ),
  );

  const handleVerifying = useCallback(() => {
    if (
      mfa.verified ||
      !isWebView ||
      (isWebView && tokenLevel === SILVER_TOKEN)
    ) {
      onSuccessCallback();
    } else {
      dispatch(
        openModal({
          type: ModalType.Mfa,
          options: {
            messageType,
            contactNumber: phoneNumber,
            onSuccessCallback,
          },
        }),
      );
    }
  }, [dispatch, messageType, mfa, onSuccessCallback, phoneNumber]);

  useEffect(() => {
    if (verifying) {
      setVerifying(false);
      handleVerifying();
    }
  }, [handleVerifying, mfa.loaded, verifying]);

  const verify = async () => {
    if (!isWebView || (isWebView && tokenLevel === SILVER_TOKEN)) {
      onSuccessCallback();
      return false;
    }

    dispatch(
      openModal({
        type: ModalType.Mfa,
        options: {
          messageType,
          contactNumber: phoneNumber,
          onSuccessCallback,
        },
      }),
    );

    // @ts-ignore
    dispatch(fetchVerificationSecure(messageType, phoneNumber)).then(
      (response) => {
        if (response && response.payload.verified) {
          onSuccessCallback();
        }
      },
    );
  };

  return { verify };
};
