import { Button, IconCircleLoader, Text } from '@vodafone/red-white';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { VStack } from 'src/components/Layout';
import { ModalContentProps } from 'src/components/Modal/types/modalContentInterfaces';
import {
  AuthLevel,
  GrantAccessStatusEnum,
} from 'src/components/PersonalAccount/VFIDProfile';
import { RoutesEnum } from 'src/config/routes';
import { ReloadFlowEnum } from 'src/features/auth/enum';
import { useReloadUser } from 'src/features/auth/hooks';
import { useCreateInvitation } from 'src/features/profile';
import { IApplicationState } from 'src/store/appStateInterface';
import selectLanguage from 'src/store/language/languageSelector';
import UserAuthorizationRoleEnum from 'src/store/user/types/UserAuthorizationRoleEnum';
import { LanguagesEnum } from 'src/types/translation/languagesEnum';
import { redirect, resolveRoutePath } from 'src/utils/redirect';
import styled, { css } from 'styled-components';

const GrantAccessModalContent = ({ options }: ModalContentProps) => {
  const { t } = useTranslation('personalAccount');
  const [isChecked, setIsChecked] = useState(false);
  const language: LanguagesEnum = useSelector((state: IApplicationState) =>
    selectLanguage(state),
  );
  const { mutate: reloadUser, isPending: isReloadingUser } = useReloadUser();
  const { mutate, isPending } = useCreateInvitation();

  const handleGrantAccessSubmit = () => {
    mutate(
      {
        email: options.email,
        roleId: options.roleId,
        type: options.authLevel,
      },
      {
        onSuccess: () => {
          reloadUser({
            redirectUri:
              window.location.origin +
              resolveRoutePath({
                internalRouteId: RoutesEnum.PERSONAL_ACCOUNT_PROFILE_ROUTE_ID,
                language,
                params: {
                  status: GrantAccessStatusEnum.INVITATION_SENT,
                },
              }),
            flow: ReloadFlowEnum.ACCESS_UPDATE,
            language,
          });
        },
        onError: () => {
          redirect({
            internalRouteId: RoutesEnum.PERSONAL_ACCOUNT_PROFILE_ROUTE_ID,
            language,
            params: {
              status: 'invitation-not-sent',
            },
          });
        },
      },
    );
  };

  return (
    <VStack
      align="flex-start"
      style={{
        padding: '32px',
        width: '100%',
        height: '100%',
        justifyContent: 'space-between',
      }}
      spacing="24px"
    >
      <VStack spacing="16px" align="flex-start" style={{ width: '100%' }}>
        <Text fontSize={26} fontFamily="VodafoneRgBd">
          {t('profile.modal.grant-access.title')}
        </Text>
        <Text fontSize={18} fontFamily="VodafoneRg">
          {t('profile.modal.grant-access.subtitle')}
        </Text>
        <InnerTextField>
          <DynamicBulletPoints authLevel={options.authLevel} />
        </InnerTextField>
      </VStack>
      <Container>
        <CheckboxContainer>
          <Mark checked={isChecked} />
          <input
            type="checkbox"
            checked={isChecked}
            hidden
            onClick={() => setIsChecked((prev) => !prev)}
          />

          <Text fontSize={16} fontFamily="VodafoneRg">
            {t('profile.modal.grant-access.checkbox')}
          </Text>
        </CheckboxContainer>
        <StyledButton
          variant="primary"
          onClick={handleGrantAccessSubmit}
          disabled={!isChecked || isPending || isReloadingUser}
        >
          {isPending || isReloadingUser ? (
            <IconCircleLoader color="white" />
          ) : (
            t('profile.modal.grant-access.button')
          )}
        </StyledButton>
      </Container>
    </VStack>
  );
};

export default GrantAccessModalContent;

type DynamicBulletPointsProps = {
  authLevel: AuthLevel;
};

type ExtendedAuthLevel = AuthLevel | 'Billing-Business';

const DynamicBulletPoints = ({ authLevel }: DynamicBulletPointsProps) => {
  const { t } = useTranslation('personalAccount');

  const getListItems = (authLevel: ExtendedAuthLevel) => {
    const listItems = [];
    let i = 0;
    const basePath = `profile.modal.grant-access.${authLevel}`;

    while (t(`${basePath}.point-${i}`) !== `${basePath}.point-${i}`) {
      listItems.push(
        <li key={i} style={{ listStyle: 'disc', marginLeft: '32px' }}>
          <Text fontSize={16} fontFamily="VodafoneRg">
            {t(`${basePath}.point-${i}`)}
          </Text>
        </li>,
      );
      i++;
    }

    return listItems;
  };

  const getAfterList = (authLevel: ExtendedAuthLevel) => {
    const listItems = [];
    let i = 0;
    const basePath = `profile.modal.grant-access.${authLevel}`;

    while (t(`${basePath}.after-${i}`) !== `${basePath}.after-${i}`) {
      listItems.push(
        <Text fontSize={16} fontFamily="VodafoneRg">
          {t(`${basePath}.after-${i}`)}
        </Text>,
      );
      i++;
    }

    return listItems;
  };

  return (
    <>
      <VStack>
        <Text fontSize={18} fontFamily="VodafoneRgBd">
          {t(`profile.modal.grant-access.${authLevel}.title`)}
        </Text>
        <Text fontSize={16} fontFamily="VodafoneRg">
          {t(`profile.modal.grant-access.${authLevel}.beforeList`)}
        </Text>
      </VStack>
      <ul>{getListItems(authLevel)}</ul>
      {getAfterList(authLevel)}
      {authLevel === UserAuthorizationRoleEnum.User && (
        <div style={{ marginTop: '24px' }}>
          <VStack>
            <Text fontSize={18} fontFamily="VodafoneRgBd">
              {t(`profile.modal.grant-access.User-Business.title`)}
            </Text>
            <Text fontSize={16} fontFamily="VodafoneRg">
              {t(`profile.modal.grant-access.User-Business.beforeList`)}
            </Text>
          </VStack>
          <ul>{getListItems('User-Business' as ExtendedAuthLevel)}</ul>
          {getAfterList('User-Business' as ExtendedAuthLevel)}
        </div>
      )}
    </>
  );
};

const InnerTextField = styled.div`
  height: 100%;
  width: 100%;
  max-height: 330px;
  max-width: 896px;
  overflow-y: scroll;
  background-color: #f6f6f6;
  padding: 24px;
`;

const Mark = styled.div<{ checked: boolean }>`
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  margin-right: 10px;
  background: ${(props) => props.theme.colors.white};
  border-radius: ${(props) => props.theme.borderRadius.input};
  border: 1px solid ${(props) => props.theme.colors.gray};

  :hover {
    cursor: pointer;
  }

  ${(props) =>
    props.checked &&
    css`
      background: ${props.theme.colors.turquoiseDark};
      border-color: ${props.theme.colors.turquoiseDark};

      &::after,
      &::before {
        height: 2px;
        content: '';
        background: ${props.theme.colors.white};
      }

      &::after {
        width: 11px;
        transform: translateX(-1px) rotate(-50deg);
      }

      &::before {
        width: 5px;
        transform: translate(2px, 2px) rotate(45deg);
      }
    `}
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  @media (min-width: 600px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

const CheckboxContainer = styled.label`
  flex: 1;
  display: flex;
  align-items: center;
  margin-right: 10px;
  max-width: 650px;

  @media (max-width: 599px) {
    flex-basis: 100%;
    margin-bottom: 10px;
  }
`;

const StyledButton = styled(Button)`
  min-width: 124px;

  @media (max-width: 450px) {
    width: 100%;
  }
`;
