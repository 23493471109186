import i18next from 'i18next';
import { FormValues } from 'src/components/PersonalAccount/RegistrationForm/RegistrationForm';
import api from 'src/utils/api/api';
import {
  API_ENDPOINT_DELETE_PROFILE_ACCOUNT,
  API_ENDPOINT_DELETE_PROFILE_ACCOUNT_EN,
  API_ENDPOINT_REGISTRATION,
  API_ENDPOINT_REGISTRATION_CONFIRMATION,
  API_ENDPOINT_REGISTRATION_CONFIRMATION_EN,
  API_ENDPOINT_REGISTRATION_EN,
  API_ENDPOINT_SAVE_DEFAULT_ROLE,
  API_ENDPOINT_SAVE_DEFAULT_ROLE_EN,
  API_ENDPOINT_SEND_INITIAL_CODE,
  API_ENDPOINT_SEND_INITIAL_CODE_EN,
  API_ENDPOINT_SET_CONTACT_NUMBER,
  API_ENDPOINT_VERIFY_CONTACT_NUMBER,
  API_ENDPOINT_VERIFY_CONTACT_NUMBER_EN,
} from '..';
import { IPersonalAccountRegistrationConfirmation } from './request/personalAccountRegistrationConfirmation';

export const API_ENDPOINT_SEND_INITIAL_CODE_APIS = {
  cs: API_ENDPOINT_SEND_INITIAL_CODE,
  en: API_ENDPOINT_SEND_INITIAL_CODE_EN,
};

export const API_ENDPOINT_REGISTRATION_APIS = {
  cs: API_ENDPOINT_REGISTRATION,
  en: API_ENDPOINT_REGISTRATION_EN,
};

export const API_ENDPOINT_REGISTRATION_CONFIRMATION_APIS = {
  cs: API_ENDPOINT_REGISTRATION_CONFIRMATION,
  en: API_ENDPOINT_REGISTRATION_CONFIRMATION_EN,
};

export const API_ENDPOINT_VERIFY_CONTACT_NUMBER_APIS = {
  cs: API_ENDPOINT_VERIFY_CONTACT_NUMBER,
  en: API_ENDPOINT_VERIFY_CONTACT_NUMBER_EN,
};

export const API_ENDPOINT_SAVE_DEFAULT_ROLE_APIS = {
  cs: API_ENDPOINT_SAVE_DEFAULT_ROLE,
  en: API_ENDPOINT_SAVE_DEFAULT_ROLE_EN,
};

export const API_ENDPOINT_DELETE_PROFILE_ACCOUNT_APIS = {
  cs: API_ENDPOINT_DELETE_PROFILE_ACCOUNT,
  en: API_ENDPOINT_DELETE_PROFILE_ACCOUNT_EN,
};

export const saveDefaultRole = (defaultRoleId: string) =>
  api.post({
    url: API_ENDPOINT_SAVE_DEFAULT_ROLE_APIS[i18next.language],
    data: {
      defaultRoleId: defaultRoleId === 'null' ? null : defaultRoleId,
    },
  });

export const deleteProfileAccount = () =>
  api.delete({
    url: API_ENDPOINT_DELETE_PROFILE_ACCOUNT_APIS[i18next.language],
  });

export const sendInitialCode = () =>
  api.post({
    url: API_ENDPOINT_SEND_INITIAL_CODE_APIS[i18next.language],
  });

export const personalAccountRegistration = (
  formData: FormValues,
  isUpc: boolean,
) => {
  const data = { ...formData, isUpc };

  return api.post({
    url: API_ENDPOINT_REGISTRATION_APIS[i18next.language],
    data,
  });
};

export const personalAccountRegistrationConfirmation = (
  data: IPersonalAccountRegistrationConfirmation,
) =>
  api.post({
    url: API_ENDPOINT_REGISTRATION_CONFIRMATION_APIS[i18next.language],
    data,
  });

export const setContactNumber = (contactNumber: string) =>
  api.post({
    url: API_ENDPOINT_SET_CONTACT_NUMBER,
    data: {
      contactNumber,
    },
  });

export const verifyContactNumber = (contactNumber: string, code: string) =>
  api.post({
    url: API_ENDPOINT_VERIFY_CONTACT_NUMBER_APIS[i18next.language]
      .replace('{verificationTarget}', contactNumber)
      .replace('{code}', code),
  });
