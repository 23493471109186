import { Card, Col, Link, Row, Text } from '@vodafone/red-white';
import styled from 'styled-components';

export const CardWrapper = styled(Card)`
  cursor: default;
`;

export const LinkText = styled(Link)`
  font-size: 16px;
  color: ${(props) => props.theme.colors.darkGrey};
  margin-left: 0px;

  @media ${(props) => props.theme.breakpoints.lg} {
    margin-left: 16px;
  }
`;

export const HistoryHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media ${(props) => props.theme.breakpoints.lg} {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
`;

export const CenteredContent = styled.div`
  display: flex;
  align-items: center;
  height: 100%;

  @media ${(props) => props.theme.breakpoints.lg} {
    justify-content: flex-end;
  }
`;

export const PaymentStatusWrapper = styled.div`
  margin-left: 8px;
  display: flex;
  align-items: center;
`;

export const PaymentStatusText = styled(Text)``;

export const IconWrapper = styled.span`
  align-items: center;
  display: flex;
`;

export const StatusWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 12px;

  @media ${(props) => props.theme.breakpoints.lg} {
    width: 100px;
  }
`;

export const BillingCol = styled(Col)`
  margin: 15px 0;

  @media ${(props) => props.theme.breakpoints.lg} {
    margin: 0;
  }
`;

export const BillingLink = styled(Link)`
  font-size: 16px;
  font-family: ${(props) => props.theme.fonts.VodafoneRg};
  color: ${(props) => props.theme.colors.darkGrey};
`;

export const PrefixWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 4px;
`;

export const SuffixWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 4px;
`;

export const LinkTextWrapper = styled.span<{
  isHidden?: boolean;
}>`
  visibility: ${(props) => (props.isHidden ? 'hidden' : 'visible')};
  margin-bottom: 8px;

  @media ${(props) => props.theme.breakpoints.lg} {
    margin-bottom: 0px;
  }
`;

export const CardPadding = styled.div`
  padding: 12px;

  @media ${(props) => props.theme.breakpoints.lg} {
    padding: 16px 32px;
  }
`;

export const LinksRow = styled(Row)`
  align-items: flex-start;
  flex-direction: column;

  @media ${(props) => props.theme.breakpoints.lg} {
    align-items: center;
    flex-direction: row;
  }
`;

export const PreperationText = styled(Text)`
  margin-top: 8px;

  @media ${(props) => props.theme.breakpoints.lg} {
    margin-top: 0px;
  }
`;
