import { ActionCreator } from 'redux';
import fetchVtvActiveDevicesRequest from 'src/api/vtv/request/fetchVtvActiveDevicesRequest';
import generateVtvPasswordRequest from 'src/api/vtv/request/generateVtvPasswordRequest';
import removeVtvDeviceRequest from 'src/api/vtv/request/removeVtvDeviceRequest';
import setVtvPasswordRequest from 'src/api/vtv/request/setVtvPasswordRequest';
import setVtvPurchaseRequest from 'src/api/vtv/request/setVtvPurchaseRequest';
import IGetVtvActiveDevicesResponse, {
  IVtvDevice,
} from 'src/api/vtv/response/getVtvActiveDevicesResponseInterface';
import VVtvActiveDeviceTypeEnum from './types/vtvActiveDeviceTypeEnum';
import {
  IFetchVtvActiveDevicesBeginAction,
  IFetchVtvActiveDevicesFailureAction,
  IFetchVtvActiveDevicesSuccessAction,
  IGenerateVtvPasswordBeginAction,
  IGenerateVtvPasswordFailureAction,
  IGenerateVtvPasswordSuccessAction,
  IRemoveVtvActiveDeviceBeginAction,
  IRemoveVtvActiveDeviceFailureAction,
  IRemoveVtvActiveDeviceSuccessAction,
  ISetVtvActiveDeviceToRemoveAction,
  ISetVtvPasswordBeginAction,
  ISetVtvPasswordFailureAction,
  ISetVtvPasswordResetStoreAction,
  ISetVtvPasswordSuccessAction,
  ISetVtvPurchaseBeginAction,
  ISetVtvPurchaseFailureAction,
  ISetVtvPurchaseSuccessAction,
} from './types/vtvServicesActionInterfaces';
import VtvServicesActionTypes from './types/vtvServicesActionTypes';
import { IVtvActiveDeviceStore } from './types/vtvServicesStateInterface';

const mapToStoreDevices = (
  devices: IVtvDevice[],
  type: VVtvActiveDeviceTypeEnum,
): IVtvActiveDeviceStore[] => devices.map((device) => ({ ...device, type }));

const fetchVtvActiveDevicesBegin: ActionCreator<
  IFetchVtvActiveDevicesBeginAction
> = () => ({
  type: VtvServicesActionTypes.FETCH_VTV_ACTIVE_DEVICES_BEGIN,
});

const fetchVtvActiveDevicesSuccess: ActionCreator<
  IFetchVtvActiveDevicesSuccessAction
> = (response: IGetVtvActiveDevicesResponse) => ({
  type: VtvServicesActionTypes.FETCH_VTV_ACTIVE_DEVICES_SUCCESS,
  payload: {
    activeDevices: mapToStoreDevices(
      response.smartPhoneDevices?.devices || [],
      VVtvActiveDeviceTypeEnum.SMART_PHONE,
    ).concat(
      mapToStoreDevices(
        response.tabletDevices?.devices || [],
        VVtvActiveDeviceTypeEnum.TABLET,
      ),
    ),
    activeDevicesLimit: response.smartPhoneDevices?.deviceLimit || 0,
    activeSetTopBoxDevices: mapToStoreDevices(
      response.setTopBoxDevices?.devices || [],
      VVtvActiveDeviceTypeEnum.SET_TOP_BOX,
    ),
    casSetTopBoxDevices: mapToStoreDevices(
      response.casSetTopBoxDevices?.devices || [],
      VVtvActiveDeviceTypeEnum.CAS_MODULE,
    ),
  },
});

const fetchVtvActiveDevicesFailure: ActionCreator<
  IFetchVtvActiveDevicesFailureAction
> = () => ({
  type: VtvServicesActionTypes.FETCH_VTV_ACTIVE_DEVICES_FAILURE,
});

export const setVtvActiveDeviceToRemove: ActionCreator<
  ISetVtvActiveDeviceToRemoveAction
> = (udid: string) => ({
  type: VtvServicesActionTypes.SET_VTV_ACTIVE_DEVICE_TO_REMOVE,
  udid,
});

export const removeVtvActiveDeviceBegin: ActionCreator<
  IRemoveVtvActiveDeviceBeginAction
> = () => ({
  type: VtvServicesActionTypes.REMOVE_VTV_ACTIVE_DEVICE_BEGIN,
});

export const removeVtvActiveDeviceSuccess: ActionCreator<
  IRemoveVtvActiveDeviceSuccessAction
> = () => ({
  type: VtvServicesActionTypes.REMOVE_VTV_ACTIVE_DEVICE_SUCCESS,
});

export const removeVtvActiveDeviceFailure: ActionCreator<
  IRemoveVtvActiveDeviceFailureAction
> = () => ({
  type: VtvServicesActionTypes.REMOVE_VTV_ACTIVE_DEVICE_FAILURE,
});

const generateVtvPasswordBegin: ActionCreator<
  IGenerateVtvPasswordBeginAction
> = () => ({
  type: VtvServicesActionTypes.GENERATE_VTV_PASSWORD_BEGIN,
});

const generateVtvPasswordSuccess: ActionCreator<
  IGenerateVtvPasswordSuccessAction
> = () => ({
  type: VtvServicesActionTypes.GENERATE_VTV_PASSWORD_SUCCESS,
});

const generateVtvPasswordFailure: ActionCreator<
  IGenerateVtvPasswordFailureAction
> = () => ({
  type: VtvServicesActionTypes.GENERATE_VTV_PASSWORD_FAILURE,
});

const setVtvPasswordBegin: ActionCreator<ISetVtvPasswordBeginAction> = () => ({
  type: VtvServicesActionTypes.SET_VTV_PASSWORD_BEGIN,
});

const setVtvPasswordSuccess: ActionCreator<
  ISetVtvPasswordSuccessAction
> = () => ({
  type: VtvServicesActionTypes.SET_VTV_PASSWORD_SUCCESS,
});

const setVtvPasswordFailure: ActionCreator<
  ISetVtvPasswordFailureAction
> = () => ({
  type: VtvServicesActionTypes.SET_VTV_PASSWORD_FAILURE,
});

export const setVtvPasswordResetStore: ActionCreator<
  ISetVtvPasswordResetStoreAction
> = () => ({
  type: VtvServicesActionTypes.SET_VTV_PASSWORD_RESET_STORE,
});

const setVtvPurchasePinBegin: ActionCreator<
  ISetVtvPurchaseBeginAction
> = () => ({
  type: VtvServicesActionTypes.SET_VTV_PURCHASE_PIN_BEGIN,
});

const setVtvPurchasePinSuccess: ActionCreator<
  ISetVtvPurchaseSuccessAction
> = () => ({
  type: VtvServicesActionTypes.SET_VTV_PURCHASE_PIN_SUCCESS,
});

const setVtvPurchasePinFailure: ActionCreator<
  ISetVtvPurchaseFailureAction
> = () => ({
  type: VtvServicesActionTypes.SET_VTV_PURCHASE_PIN_FAILURE,
});

export const fetchVtvActiveDevices = (serviceNumber: string) => (dispatch) => {
  dispatch(fetchVtvActiveDevicesBegin());

  return fetchVtvActiveDevicesRequest(serviceNumber)
    .then((response) => {
      dispatch(fetchVtvActiveDevicesSuccess(response.data));
    })
    .catch(() => {
      dispatch(fetchVtvActiveDevicesFailure());
    });
};

export const removeVtvActiveDeviceInModal =
  (serviceNumber: string, udid: string) => (dispatch) => {
    dispatch(removeVtvActiveDeviceBegin());
    removeVtvDeviceRequest(serviceNumber, [udid])
      .then(() => {
        dispatch(removeVtvActiveDeviceSuccess());
        dispatch(fetchVtvActiveDevices(serviceNumber));
      })
      .catch(() => {
        dispatch(removeVtvActiveDeviceFailure());
      });
  };

export const setVtvPassword =
  (serviceNumber: string, password: string) => (dispatch) => {
    dispatch(setVtvPasswordBegin());
    setVtvPasswordRequest(serviceNumber, password)
      .then(() => {
        dispatch(setVtvPasswordSuccess());
      })
      .catch(() => {
        dispatch(setVtvPasswordFailure());
      });
  };

export const generateVtvPasswordInModal =
  (
    serviceNumber: string,
    contactPhoneNumber: string,
    customerEmail: string = null,
  ) =>
  (dispatch) => {
    dispatch(generateVtvPasswordBegin());
    generateVtvPasswordRequest(serviceNumber, contactPhoneNumber, customerEmail)
      .then(() => {
        dispatch(generateVtvPasswordSuccess());
      })
      .catch(() => {
        dispatch(generateVtvPasswordFailure());
      });
  };

export const setVtvPurchase =
  (serviceNumber: string, pin: string) => (dispatch) => {
    dispatch(setVtvPurchasePinBegin());

    return setVtvPurchaseRequest(serviceNumber, pin)
      .then(() => dispatch(setVtvPurchasePinSuccess()))
      .catch((error) => {
        dispatch(setVtvPurchasePinFailure());

        throw error;
      });
  };
