import { CircleLoader, IconDocument, Title } from '@vodafone/red-white';
import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'src/hooks/useAppDispatch';
import { IApplicationState } from 'src/store/appStateInterface';
import IUpcInvoceState from 'src/store/billing/upcInvoice/types/upcInvoiceStateInterface';
import {
  downloadUpcInvoice,
  fetchUpcInvoces,
} from 'src/store/billing/upcInvoice/upcInvoiceActions';
import { selectUpcInvoice } from 'src/store/billing/upcInvoice/upcInvoiceSelectors';
import selectLanguage from 'src/store/language/languageSelector';
import IBillingAccount from 'src/store/user/types/billingAccountInterface';
import { selectBillingAccount } from 'src/store/user/userSelector';
import CardBillingHistory from 'src/ui/components/Billing/CardBillingHistory';
import styled, { ThemeContext } from 'styled-components';
import { LanguagesEnum } from '../../../types/translation/languagesEnum';
import { toMonthName, toNumericDateString } from '../../../utils/date';
import Price from '../../Price';
import { ModalContentProps } from '../types/modalContentInterfaces';

const ModalHeading = styled.div`
  position: relative;
  text-align: center;
  padding: 20px 0;

  @media ${(props) => props.theme.breakpoints.lg} {
    padding: 0;
    padding-bottom: 80px;
  }
`;

const ContentWrapper = styled.div`
  overflow-y: auto;
  height: calc(100vh - 65px);

  @media ${(props) => props.theme.breakpoints.lg} {
    height: 530px;
  }
`;

const CardSpacing = styled.div`
  margin-bottom: 8px;
`;

const ResponsiveTitle = styled(Title)`
  font-size: 20px;

  @media ${(props) => props.theme.breakpoints.xs} {
    font-size: 28px;
  }

  @media ${(props) => props.theme.breakpoints.lg} {
    font-size: 36px;
  }
`;

const UPCBillingHistoryContent: FC<ModalContentProps> = () => {
  const themeContext = React.useContext(ThemeContext);
  const upcInvoice: IUpcInvoceState = useSelector(selectUpcInvoice);
  const language: LanguagesEnum = useSelector(selectLanguage);
  const dispatch = useAppDispatch();
  const billingAccount: IBillingAccount = useSelector(
    (state: IApplicationState) => selectBillingAccount(state),
  );
  const { t } = useTranslation('billing');

  useEffect(() => {
    dispatch(fetchUpcInvoces(billingAccount.upcBillingAccountNumber));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ModalHeading>
        <ResponsiveTitle color="white">
          {t('billing:upc:title')}
        </ResponsiveTitle>
      </ModalHeading>

      {upcInvoice.loading && <CircleLoader />}

      {!upcInvoice.loading && upcInvoice.data && upcInvoice.data.invoices && (
        <ContentWrapper>
          {upcInvoice.data.invoices.splice(0, 13).map((invoice, i) => {
            return (
              <CardSpacing key={i.toString()}>
                <CardBillingHistory
                  compact
                  key={i.toString()}
                  style={{ boxShadow: 'none' }}
                  billing={{
                    title: toMonthName(invoice.invoiceDate, language, true),
                    subtitle: t('billing:upc:due-date', {
                      date: toNumericDateString(invoice.dueDate),
                    }),
                    amount: <Price price={Math.ceil(Number(invoice.amount))} />,
                  }}
                  linksData={[
                    {
                      key: 'download-invoice',
                      title: t('billing:upc:download-invoice'),
                      prefix: (
                        <IconDocument color={themeContext.colors.darkGrey} />
                      ),
                    },
                  ]}
                  onLinkDataClick={() => {
                    dispatch(
                      downloadUpcInvoice(
                        billingAccount.upcBillingAccountNumber,
                        invoice.invoiceId.toString(),
                      ),
                    );
                  }}
                />
              </CardSpacing>
            );
          })}
        </ContentWrapper>
      )}
    </>
  );
};

export default UPCBillingHistoryContent;
