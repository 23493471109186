import { Reducer } from 'redux';
import initialState from '../initialState';
import {
  IFetchVtvActiveDevicesBeginAction,
  IFetchVtvActiveDevicesFailureAction,
  IFetchVtvActiveDevicesSuccessAction,
  IGenerateVtvPasswordBeginAction,
  IGenerateVtvPasswordFailureAction,
  IGenerateVtvPasswordSuccessAction,
  IRemoveVtvActiveDeviceBeginAction,
  IRemoveVtvActiveDeviceFailureAction,
  IRemoveVtvActiveDeviceSuccessAction,
  ISetVtvActiveDeviceToRemoveAction,
  ISetVtvPasswordBeginAction,
  ISetVtvPasswordFailureAction,
  ISetVtvPasswordResetStoreAction,
  ISetVtvPasswordSuccessAction,
  ISetVtvPurchaseBeginAction,
  ISetVtvPurchaseFailureAction,
  ISetVtvPurchaseSuccessAction,
} from './types/vtvServicesActionInterfaces';
import VtvServicesActionTypes from './types/vtvServicesActionTypes';
import IVtvServicesState from './types/vtvServicesStateInterface';

type VtvServicesAction =
  | IFetchVtvActiveDevicesBeginAction
  | IFetchVtvActiveDevicesSuccessAction
  | IFetchVtvActiveDevicesFailureAction
  | IRemoveVtvActiveDeviceBeginAction
  | IRemoveVtvActiveDeviceSuccessAction
  | IRemoveVtvActiveDeviceFailureAction
  | IGenerateVtvPasswordBeginAction
  | IGenerateVtvPasswordSuccessAction
  | IGenerateVtvPasswordFailureAction
  | ISetVtvPasswordBeginAction
  | ISetVtvPasswordSuccessAction
  | ISetVtvPasswordFailureAction
  | ISetVtvPasswordResetStoreAction
  | ISetVtvActiveDeviceToRemoveAction
  | ISetVtvPurchaseBeginAction
  | ISetVtvPurchaseSuccessAction
  | ISetVtvPurchaseFailureAction;

const vtvServicesReducer: Reducer<IVtvServicesState> = (
  state: IVtvServicesState = initialState.VtvServices,
  action: VtvServicesAction,
) => {
  switch (action.type) {
    case VtvServicesActionTypes.FETCH_VTV_ACTIVE_DEVICES_BEGIN:
      return {
        ...state,
        loading: true,
        loaded: false,
      };

    case VtvServicesActionTypes.FETCH_VTV_ACTIVE_DEVICES_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        ...action.payload,
      };

    case VtvServicesActionTypes.FETCH_VTV_ACTIVE_DEVICES_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };

    case VtvServicesActionTypes.SET_VTV_ACTIVE_DEVICE_TO_REMOVE:
      return {
        ...state,
        removeDevice: {
          ...state.removeDevice,
          udid: action.udid,
          loading: false,
          loaded: false,
          error: false,
        },
      };

    case VtvServicesActionTypes.REMOVE_VTV_ACTIVE_DEVICE_BEGIN:
      return {
        ...state,
        removeDevice: {
          ...state.removeDevice,
          loading: true,
          loaded: false,
          error: false,
        },
      };

    case VtvServicesActionTypes.REMOVE_VTV_ACTIVE_DEVICE_SUCCESS:
      return {
        ...state,
        removeDevice: {
          ...state.removeDevice,
          loading: false,
          loaded: true,
          error: false,
        },
      };

    case VtvServicesActionTypes.REMOVE_VTV_ACTIVE_DEVICE_FAILURE:
      return {
        ...state,
        removeDevice: {
          ...state.removeDevice,
          loading: false,
          loaded: false,
          error: true,
        },
      };

    case VtvServicesActionTypes.GENERATE_VTV_PASSWORD_BEGIN:
      return {
        ...state,
        generatePassword: {
          ...state.generatePassword,
          loading: true,
          loaded: false,
          error: false,
        },
      };

    case VtvServicesActionTypes.GENERATE_VTV_PASSWORD_SUCCESS:
      return {
        ...state,
        generatePassword: {
          ...state.generatePassword,
          loading: false,
          loaded: true,
          error: false,
        },
      };

    case VtvServicesActionTypes.GENERATE_VTV_PASSWORD_FAILURE:
      return {
        ...state,
        generatePassword: {
          ...state.generatePassword,
          loading: false,
          loaded: false,
          error: true,
        },
      };

    case VtvServicesActionTypes.SET_VTV_PASSWORD_BEGIN:
      return {
        ...state,
        setPassword: {
          ...state.setPassword,
          loading: true,
          loaded: false,
          error: false,
        },
      };

    case VtvServicesActionTypes.SET_VTV_PASSWORD_SUCCESS:
      return {
        ...state,
        setPassword: {
          ...state.setPassword,
          loading: false,
          loaded: true,
          error: false,
        },
      };

    case VtvServicesActionTypes.SET_VTV_PASSWORD_FAILURE:
      return {
        ...state,
        setPassword: {
          ...state.setPassword,
          loading: false,
          loaded: false,
          error: true,
        },
      };

    case VtvServicesActionTypes.SET_VTV_PASSWORD_RESET_STORE:
      return {
        ...state,
        setPassword: initialState.VtvServices.setPassword,
      };

    case VtvServicesActionTypes.SET_VTV_PURCHASE_PIN_BEGIN:
      return {
        ...state,
        setPurchasePin: {
          ...state.setPurchasePin,
          loading: true,
          loaded: false,
          error: false,
        },
      };

    case VtvServicesActionTypes.SET_VTV_PURCHASE_PIN_SUCCESS:
      return {
        ...state,
        setPurchasePin: {
          ...state.setPurchasePin,
          loading: false,
          loaded: true,
          error: false,
        },
      };

    case VtvServicesActionTypes.SET_VTV_PURCHASE_PIN_FAILURE:
      return {
        ...state,
        setPurchasePin: {
          ...state.setPurchasePin,
          loading: false,
          loaded: false,
          error: true,
        },
      };

    default:
      return state;
  }
};

export default vtvServicesReducer;
