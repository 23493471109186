import { Reducer } from 'redux';
import initialState from '../../initialState';
import {
  IRoamingCalculatorCalculateBeginAction,
  IRoamingCalculatorCalculateFailureAction,
  IRoamingCalculatorCalculateSuccessAction,
  IRoamingCalculatorLoadDataBeginAction,
  IRoamingCalculatorLoadDataFailureAction,
  IRoamingCalculatorLoadDataSuccessAction,
} from '../types/roamingCalculator/roamingCalculatorActionInterfaces';
import { RoamingCalculatorActionTypes } from '../types/roamingCalculator/roamingCalculatorActionTypes';
import { IRoamingCalculator } from '../types/roamingCalculator/roamingCalculatorStateInterface';

type RoamingCalculatorAction =
  | IRoamingCalculatorLoadDataBeginAction
  | IRoamingCalculatorLoadDataSuccessAction
  | IRoamingCalculatorLoadDataFailureAction
  | IRoamingCalculatorCalculateBeginAction
  | IRoamingCalculatorCalculateSuccessAction
  | IRoamingCalculatorCalculateFailureAction;

export const roamingCalculatorReducer: Reducer<IRoamingCalculator> = (
  state: IRoamingCalculator = initialState.Services.roamingCalculator,
  action: RoamingCalculatorAction,
) => {
  switch (action.type) {
    case RoamingCalculatorActionTypes.ROAMING_CALCULATOR_LOAD_DATA_BEGIN:
      return {
        ...state,
        loading: true,
        loaded: false,
      };

    case RoamingCalculatorActionTypes.ROAMING_CALCULATOR_LOAD_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        countries: action.payload.countries,
        subscribers: action.payload.subscribers,
      };

    case RoamingCalculatorActionTypes.ROAMING_CALCULATOR_LOAD_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };

    case RoamingCalculatorActionTypes.ROAMING_CALCULATOR_CALCULATE_BEGIN:
      return {
        ...state,
        loading: true,
        loaded: false,
        calculatorData: null,
      };

    case RoamingCalculatorActionTypes.ROAMING_CALCULATOR_CALCULATE_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        calculatorData: action.payload.calculatorData,
      };

    case RoamingCalculatorActionTypes.ROAMING_CALCULATOR_CALCULATE_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };

    default:
      return state;
  }
};

export default roamingCalculatorReducer;
