import apiDelete from './delete';
import apiGet from './get';
import apiPost from './post';
import apiPut from './put';

export default {
  get: apiGet,
  put: apiPut,
  post: apiPost,
  delete: apiDelete,
};
