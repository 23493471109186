import { ActionCreator } from 'redux';
import { IManagementEligibility } from 'src/api/serviceManagement/response/getServicesResponseInterface';
import {
  IManagementEligibilityBeginAction,
  IManagementEligibilityFailureAction,
  IManagementEligibilitySuccessAction,
} from '../types/managementEligibility/managementEligibilityActionInterfaces';
import { ManagementEligibilityActionTypes } from '../types/managementEligibility/managementEligibilityActionTypes';

export const fetchManagementEligibilityBegin: ActionCreator<
  IManagementEligibilityBeginAction
> = () => ({
  type: ManagementEligibilityActionTypes.MANAGEMENT_ELIGIBILITY_BEGIN,
});

export const fetchManagementEligibilitySuccess: ActionCreator<
  IManagementEligibilitySuccessAction
> = (payload: IManagementEligibility) => ({
  type: ManagementEligibilityActionTypes.MANAGEMENT_ELIGIBILITY_SUCCESS,
  payload,
});

export const fetchManagementEligibilityFailure: ActionCreator<
  IManagementEligibilityFailureAction
> = () => ({
  type: ManagementEligibilityActionTypes.MANAGEMENT_ELIGIBILITY_FAILURE,
});
