import { createSelector, Selector } from 'reselect';
import { IApplicationState } from '../appStateInterface';
import IBillingAccount from './types/billingAccountInterface';
import PaymentTypeEnum from './types/PubscriptionTypeEnum';
import SegmentTypeEnum from './types/segmentTypeEnum';
import ISubscription from './types/subscriptionInterface';
import SubscriptionTypeEnum from './types/SubscriptionTypeEnum';
import UserAuthorizationRoleEnum from './types/UserAuthorizationRoleEnum';
import UserRoleEnum from './types/UserRoleEnum';
import IUserState from './types/userStateInterface';

export const getUser: Selector<IApplicationState, IUserState> = (state) =>
  state.User;

export const selectIsMegaLargeAccount: Selector<IApplicationState, boolean> = (
  state: IApplicationState,
) => createSelector([getUser], (user) => user.isMegaLargeAccount)(state);

export const selectIsLoadedCorrectly: Selector<IApplicationState, boolean> = (
  state: IApplicationState,
) => createSelector([getUser], (user) => user.loaded && !user.error)(state);

export default createSelector([getUser], (user) => user);

export const selectIsPostpaid = createSelector(
  [getUser],
  (user) =>
    user.subscription &&
    user.subscription.paymentType === PaymentTypeEnum.POSTPAID,
);

export const selectServiceNumber: Selector<IApplicationState, string> =
  createSelector([getUser], (user) => user.subscription.serviceNumber);

export const selectCustomerAccountNumber = (state: IApplicationState): string =>
  createSelector([getUser], (user) => user.caNumber)(state);

export const selectCurrentSubscription: Selector<
  IApplicationState,
  ISubscription
> = createSelector([getUser], (user) => user.subscription);

export const selectSubscriptions = (
  state: IApplicationState,
  allowedSubscriptions: SubscriptionTypeEnum[] = [],
): ISubscription[] =>
  createSelector([getUser], (user) =>
    allowedSubscriptions.length === 0
      ? user.subscriptions
      : user.subscriptions.filter((subsription) =>
          allowedSubscriptions.includes(subsription.type),
        ),
  )(state);

export const selectIsPrepaid = createSelector(
  [getUser],
  (user) =>
    user.subscription &&
    user.subscription.paymentType === PaymentTypeEnum.PREPRAID,
);

export const selectIsBmsl = createSelector(
  [getUser],
  (user) => user.segment === SegmentTypeEnum.BMSL,
);

export const selectIsEndUser = createSelector(
  [getUser],
  (user) =>
    user.authorizationRoleName === UserAuthorizationRoleEnum.User ||
    (typeof user.roles !== 'undefined' &&
      user.roles.length > 0 &&
      user.roles.includes(UserRoleEnum.ROLE_PASSWORD_TYPE_ENDUSER)),
);

export const selectIsBAUser = createSelector(
  [getUser],
  (user) =>
    user.authorizationRoleName === UserAuthorizationRoleEnum.BillingAdmin ||
    (typeof user.roles !== 'undefined' &&
      user.roles &&
      user.roles.length > 0 &&
      user.roles.includes(UserRoleEnum.ROLE_PASSWORD_TYPE_BA_ADMIN)),
);

export const selectIsUserWithRole = (
  state: IApplicationState,
  roles: UserRoleEnum[],
  allRolesRequired = false,
): boolean =>
  createSelector([getUser], (user) => {
    if (!roles.length || !user.roles.length) {
      return false;
    }

    return allRolesRequired
      ? roles.every((role) => user.roles.includes(role))
      : roles.some((role) => user.roles.includes(role));
  })(state);

export const selectLargeSubscribers = (
  state: IApplicationState,
  caNumber: string,
) =>
  createSelector([getUser], (user) =>
    user.largeSubscribers.data[caNumber] !== undefined
      ? user.largeSubscribers.data[caNumber]
      : [],
  )(state);

export const selectLargeSubscribersIsLoaded = (
  state: IApplicationState,
  caNumber: string,
): boolean =>
  createSelector([getUser], (user) => {
    return (
      user.largeSubscribers.data[caNumber] !== undefined &&
      user.largeSubscribers.data[caNumber].length > 0
    );
  })(state);

export const selectLargeBillingAccountsIsLoaded = (
  state: IApplicationState,
  caNumber: string,
): boolean =>
  createSelector(
    [getUser],
    (user) =>
      user.largeBillingAccounts[caNumber] &&
      user.largeBillingAccounts[caNumber].length > 0,
  )(state);

export const selectSearchedSubscriptions = (
  state: IApplicationState,
  search: string,
) =>
  createSelector([getUser], (user) =>
    user.subscriptions.filter(
      (subscriber) => !search || subscriber.serviceNumber.includes(search),
    ),
  )(state);

export const selectSearchedLargeSubscribers = (
  state: IApplicationState,
  caNumber: string,
  search: string,
) =>
  createSelector([getUser], (user) =>
    caNumber && Object.keys(user.largeSubscribers.data).length
      ? user.largeSubscribers.data[caNumber].filter(
          (subscriber) => !search || subscriber.serviceNumber.includes(search),
        )
      : [],
  )(state);

export const selectCountOfSearchedLargeSubscribers = (
  state: IApplicationState,
  caNumber: string,
  search: string,
) =>
  createSelector([getUser], (user) => {
    return caNumber && Object.keys(user.largeSubscribers.data).length
      ? user.largeSubscribers.data[caNumber].filter(
          (subscriber) => !search || subscriber.serviceNumber.includes(search),
        ).length
      : 0;
  })(state);

export const selectIsErrorAndEligibleForAdminsOnly: Selector<
  IApplicationState,
  boolean
> = createSelector(
  [getUser],
  (user) =>
    user.largeSubscribers.error &&
    user.largeSubscribers.errorStatusCode === '403' &&
    user.largeSubscribers.errorMessage === 'Eligible for Admin only',
);

export const selectSearchedLargeBillingAccounts = (
  state: IApplicationState,
  caNumber: string,
  search: string,
) =>
  createSelector([getUser], (user) =>
    caNumber && Object.keys(user.largeBillingAccounts).length
      ? user.largeBillingAccounts[caNumber].filter(
          (account) => !search || account.billingAccountNumber.includes(search),
        )
      : [],
  )(state);

export const selectNumberOfBillingAccounts = (
  state: IApplicationState,
  caNumber: string,
): number =>
  createSelector([getUser], (user) =>
    caNumber && Object.keys(user.largeBillingAccounts).length
      ? user.largeBillingAccounts[caNumber].length
      : 0,
  )(state);

export const selectLargeBillingAccounts = (
  state: IApplicationState,
  caNumber: string,
) =>
  createSelector([getUser], (user) =>
    caNumber && Object.keys(user.largeBillingAccounts).length
      ? user.largeBillingAccounts[caNumber]
      : [],
  )(state);

export const selectBillingAccount: Selector<
  IApplicationState,
  IBillingAccount
> = createSelector([getUser], (user) => user.billingAccount);

export const selectIsBillingAccountPrepaid: Selector<
  IApplicationState,
  boolean
> = createSelector([getUser], (user) => {
  if (user.loaded && user.billingAccount && user.billingAccount.paymentType) {
    return (
      user.billingAccount.paymentType.toLowerCase() ===
      PaymentTypeEnum.PREPRAID.toLowerCase()
    );
  }

  return false;
});

export const selectIsSubscriptionFbbOrFms: Selector<
  IApplicationState,
  boolean
> = createSelector([getUser], (user) =>
  [SubscriptionTypeEnum.FMS, SubscriptionTypeEnum.FBB].includes(
    user.subscription.type,
  ),
);

export const selectCustomerAccountContactPhone: Selector<
  IApplicationState,
  string
> = createSelector([getUser], (user) => user.customerAccountContactPhone);

export const selectCustomerUsername: Selector<IApplicationState, string> =
  createSelector([getUser], (user) => user.username);

export const selectBillingOrCustomerAccountContactPhone: Selector<
  IApplicationState,
  string
> = createSelector(
  [getUser, selectIsBAUser],
  (user, isBaUser): string | undefined =>
    isBaUser
      ? user.billingAccount.billingAccountContactPhone ||
        user.customerAccountContactPhone
      : user.customerAccountContactPhone,
);

export const selectHasFbbSubscriber: Selector<IApplicationState, boolean> =
  createSelector([getUser], (user) =>
    user.subscriptions.some((sub) => sub.type === SubscriptionTypeEnum.FBB),
  );
