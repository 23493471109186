import { Card, IconGroupSaving, IconMobile } from '@vodafone/red-white';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMarketplaceCodes } from 'src/features/marketplace/hooks/useMarketplaceCodes';
import SanitizedHtml from 'src/utils/sanitizeHtml';
import { formatServiceNumber } from 'src/utils/serviceNumberFormatter';
import { ModalContentProps } from '../../types/modalContentInterfaces';
import * as Styled from './DiscountCodesModalContent.styled';

const DiscountCodesModalContent: FC<ModalContentProps> = ({ onClose }) => {
  const { t } = useTranslation('eshop');
  const { isLoading, isError, isSuccess, data } = useMarketplaceCodes();

  const [lastCopiedCode, setLastCopiedCode] = useState<string | null>(null);

  const copyToClipboard = async (code: string) => {
    try {
      await navigator.clipboard.writeText(code);
      setLastCopiedCode(code);
      setTimeout(() => {
        setLastCopiedCode(null);
      }, 2000);
    } catch (error) {
      setLastCopiedCode(null);
    }
  };

  if (isLoading) {
    return null;
  }

  return (
    <Card
      style={{ maxWidth: 700, width: '100%', boxShadow: 'none' }}
      loading={isLoading}
      title={
        <Styled.CardHeader>
          <Styled.IconHeader>
            <IconGroupSaving size={20} />
          </Styled.IconHeader>
          {t('marketplace.discount-codes-modal-header')}
        </Styled.CardHeader>
      }
    >
      <Styled.CardPadding>
        {isSuccess &&
          data.discounts.length > 0 &&
          data.discounts.map((discount) => (
            <Styled.Line key={discount.serviceNumber.toString()}>
              <Styled.Service>
                <Styled.Icon>
                  <IconMobile color="black" />
                </Styled.Icon>
                <Styled.ServiceNumber>
                  {formatServiceNumber(discount.serviceNumber, true)}
                </Styled.ServiceNumber>
              </Styled.Service>

              <Styled.CodeWrapper>
                <Styled.Code>{discount.code}</Styled.Code>

                {discount.code ? (
                  <Styled.StyledLink
                    onClick={() => copyToClipboard(discount.code)}
                  >
                    {lastCopiedCode === discount.code
                      ? t('marketplace.code-copied')
                      : t('marketplace.copy-discount-code')}
                  </Styled.StyledLink>
                ) : (
                  <Styled.NoCode>
                    {t('marketplace.discount-code-not-available')}
                  </Styled.NoCode>
                )}
              </Styled.CodeWrapper>
            </Styled.Line>
          ))}

        {isError && (
          <Styled.StyledError>
            <SanitizedHtml
              htmlTag="p"
              rawHtmlData={t('marketplace.code-error')}
            />
          </Styled.StyledError>
        )}

        <Styled.CodeButtonWrapper
          htmlElement="button"
          variant="secondary"
          onClick={() => onClose()}
        >
          {t('component:button.close')}
        </Styled.CodeButtonWrapper>
      </Styled.CardPadding>
    </Card>
  );
};

export default DiscountCodesModalContent;
