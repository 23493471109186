import {
  CircleLoader,
  COLORS,
  IconDocumentSimple,
  Messages,
  Title,
} from '@vodafone/red-white';
import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'src/hooks/useAppDispatch';
import { IApplicationState } from 'src/store/appStateInterface';
import {
  downloadInvoicesInXls,
  fetchInvoicesSummary,
} from 'src/store/invoices/invoicesActions';
import {
  selectInvoicesSummaryForBaNumber,
  selectSelectedInvoicesSummaryBaNumber,
} from 'src/store/invoices/invoicesSelectors';
import { IInvoiceSummaryBaItem } from 'src/store/invoices/types/invoicesInterface';
import IBillingAccount from 'src/store/user/types/billingAccountInterface';
import { selectBillingAccount } from 'src/store/user/userSelector';
import CardDownloadInvoice from 'src/ui/components/CardDownloadInvoice';
import { ThemeContext } from 'styled-components';
import { ModalContentProps } from '../types/modalContentInterfaces';

const DownloadInvoicesInXlsModalContent: FC<ModalContentProps> = ({
  setCompact,
  setCompactContent,
}) => {
  const themeContext = React.useContext(ThemeContext);
  const dispatch = useAppDispatch();
  const billingAccount: IBillingAccount = useSelector(
    (state: IApplicationState) => selectBillingAccount(state),
  );
  const summary: IInvoiceSummaryBaItem = useSelector(
    (state: IApplicationState) => selectInvoicesSummaryForBaNumber(state),
  );
  const selectedSummaryBaNumber: string = useSelector(
    (state: IApplicationState) => selectSelectedInvoicesSummaryBaNumber(state),
  );
  const { t } = useTranslation(['billing']);

  setCompact(true);
  setCompactContent(true);

  useEffect(() => {
    dispatch(fetchInvoicesSummary(billingAccount.billingAccountNumber));
  }, [dispatch, billingAccount.billingAccountNumber]);

  let data = [];

  if (summary && summary.loaded) {
    data = Object.keys(summary.years);
  }

  return (
    <>
      {!summary ||
        (summary && !summary.loaded && !summary.error && (
          <div style={{ padding: 40 }}>
            <CircleLoader />
          </div>
        ))}
      {summary && summary.error && (
        <div style={{ padding: 40 }}>
          <Messages
            color={COLORS.red}
            iconColor={COLORS.red}
            messageTitle={t(
              'component:errorBoundary.error500-message-headline',
            )}
            messageText=""
          />
        </div>
      )}
      {summary && summary.loaded && !summary.error && (
        <CardDownloadInvoice
          defaultValue={data[0]}
          title={
            <>
              <IconDocumentSimple
                size={20}
                color={themeContext.colors.darkGrey}
              />
              <Title
                color="darkGrey"
                fontFamily="VodafoneRg"
                fontWeight="bold"
                level={3}
                style={{ marginLeft: 8 }}
              >
                {t('billing:history.download-all-invoices-for-year-title')}
              </Title>
            </>
          }
          data={data}
          radioLabel={t('billing:history.download-all-invoices-for-year')}
          buttonText={t(
            'billing:history.download-all-invoices-for-year-button',
          )}
          onButtonClick={(year) =>
            dispatch(downloadInvoicesInXls(selectedSummaryBaNumber, year))
          }
        />
      )}
    </>
  );
};

export default DownloadInvoicesInXlsModalContent;
