import { Reducer } from 'redux';
import initialState from '../initialState';
import {
  IRecoveryRequestCaptchaBeginAction,
  IRecoveryRequestCaptchaFailureAction,
  IRecoveryRequestCaptchaSuccessAction,
} from './types/recoveryRequestCaptchaActionInterfaces';
import RecoveryRequestCaptchaActionTypes from './types/recoveryRequestCaptchaActionTypes';
import IRecoveryRequestCaptchaState from './types/recoveryRequestCaptchaStateInterface';

type IRecoveryRequestCaptchaAction =
  | IRecoveryRequestCaptchaBeginAction
  | IRecoveryRequestCaptchaSuccessAction
  | IRecoveryRequestCaptchaFailureAction;

const recoveryRequestCaptchaReducer: Reducer<IRecoveryRequestCaptchaState> = (
  state: IRecoveryRequestCaptchaState = initialState.RecoveryRequestCaptcha,
  action: IRecoveryRequestCaptchaAction,
) => {
  switch (action.type) {
    case RecoveryRequestCaptchaActionTypes.RECOVERY_REQUEST_CAPTCHA_BEGIN:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      };
    case RecoveryRequestCaptchaActionTypes.RECOVERY_REQUEST_CAPTCHA_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        loaded: true,
        error: false,
      };
    case RecoveryRequestCaptchaActionTypes.RECOVERY_REQUEST_CAPTCHA_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    default:
      return state;
  }
};

export default recoveryRequestCaptchaReducer;
