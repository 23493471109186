import { Card, IconEsim2, Title } from '@vodafone/red-white';
import { FC, useCallback, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import OrderNewESimFormContent from 'src/components/Modal/components/ESim/OrderNewESim/OrderNewESimFormContent';
import ModalMessageTemplate from 'src/components/Modal/components/ModalMessage/ModalMessageTemplate';
import ModalMessageTypeEnum from 'src/components/Modal/components/ModalMessage/types/ModalMessageTypeEnum';
import { ModalContentProps } from 'src/components/Modal/types/modalContentInterfaces';
import { useAppDispatch } from 'src/hooks/useAppDispatch';
import { getApplication } from 'src/store/application/applicationSelector';
import { IApplicationState } from 'src/store/appStateInterface';
import {
  eSimDeliveryResetStore,
  eSimSimSwapResetStore,
} from 'src/store/esim/esimActions';
import {
  selectESimDelivery,
  selectSimSwap,
} from 'src/store/esim/esimSelectors';
import {
  IESimDeliveryState,
  ISimSwapState,
} from 'src/store/esim/types/esimStateInterface';
import { updateModalAnalyticsOptions } from 'src/store/modal/modalActions';
import { getModal } from 'src/store/modal/modalSelectors';
import { setPageAnalytics } from 'src/utils/analytics/actions/analyticsActions';
import SanitizedHtml from 'src/utils/sanitizeHtml';
import { formatServiceNumber } from 'src/utils/serviceNumberFormatter';
import styled, { ThemeContext } from 'styled-components';

export type RequestFormValues = {
  contactNumber: string;
};

const CustomCardPadding = styled.div`
  padding: 40px 0 0 0;
  @media ${(props) => props.theme.breakpoints.smOnly} {
    padding-top: 20px;
  }
`;

const OrderNewESimContent: FC<ModalContentProps> = ({
  onClose,
  setCompact,
  setCompactContent,
  options,
}) => {
  const themeContext = useContext(ThemeContext);
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['esim']);

  useEffect(() => {
    setCompact(true);
    setCompactContent(true);
    dispatch(eSimSimSwapResetStore());
    dispatch(eSimDeliveryResetStore());
  }, [setCompactContent, setCompact, dispatch]);

  const onCloseButtonClick = () => {
    onClose();
    dispatch(eSimSimSwapResetStore());
    dispatch(eSimDeliveryResetStore());
  };

  const simSwap: ISimSwapState = useSelector(selectSimSwap);
  const simDelivery: IESimDeliveryState = useSelector(selectESimDelivery);
  const { currentPage } = useSelector(getApplication);
  const modal = useSelector((state: IApplicationState) => getModal(state));

  const hasLoading = simSwap.loading || simDelivery.loading;
  const hasError = simSwap.error || simDelivery.error;
  const isLoaded = simSwap.loaded || simDelivery.loaded;
  const isEIdMethod = simSwap.eId || simDelivery.eId;
  const isQRCodeMethod = simSwap.qrCode || simDelivery.qrCode;

  const isQRCodeSuccess =
    !hasLoading && isLoaded && !hasError && isQRCodeMethod;
  const isEIdMethodSuccess =
    !hasLoading && isLoaded && !hasError && isEIdMethod;
  const isError = !hasLoading && !isLoaded && hasError;
  const isOK = !hasLoading && !isLoaded && !hasError;
  const prop49Info = `${(isEIdMethod && ':SMS') || (isQRCodeMethod && ':QR')}`;

  const isProp49AdditionalInfoSet =
    modal?.analyticsOptions?.prop49AdditionalInfo !== undefined;

  const updateModalAnalyticsData = useCallback(() => {
    if (isOK && isProp49AdditionalInfoSet) {
      dispatch(setPageAnalytics(currentPage));
    }

    if (isQRCodeSuccess || isEIdMethodSuccess) {
      dispatch(
        updateModalAnalyticsOptions({
          prop49AdditionalInfo: `:success${prop49Info}`,
        }),
      );
      dispatch(setPageAnalytics(currentPage));
    }

    if (isError) {
      dispatch(
        updateModalAnalyticsOptions({
          prop49AdditionalInfo: `:error${prop49Info}`,
        }),
      );
      dispatch(setPageAnalytics(currentPage));
    }
  }, [
    dispatch,
    isError,
    isQRCodeSuccess,
    isEIdMethodSuccess,
    isOK,
    prop49Info,
    currentPage,
    isProp49AdditionalInfoSet,
  ]);

  useEffect(() => {
    updateModalAnalyticsData();
  }, [updateModalAnalyticsData]);

  return (
    <Card
      style={{ maxWidth: 700, width: '100%', boxShadow: 'none' }}
      waiting={simSwap.loading}
      title={
        <>
          <IconEsim2 size={20} color={themeContext.colors.darkGrey} />
          <Title
            color="darkGrey"
            fontFamily="VodafoneRg"
            fontWeight="bold"
            level={3}
            style={{ marginLeft: 8 }}
          >
            {t('esim:orderNewESim.title', {
              serviceNumber: formatServiceNumber(options.serviceNumber),
            })}
          </Title>
        </>
      }
    >
      <CustomCardPadding>
        {isOK && (
          <OrderNewESimFormContent
            defaultMethod={options.defaultMethod}
            changeCurrentActivationMethod={
              options.changeCurrentActivationMethod
            }
            serviceNumberForESim={options.serviceNumber}
          />
        )}

        {/* QR Code SUCCESS */}
        {isQRCodeSuccess && (
          <ModalMessageTemplate
            type={ModalMessageTypeEnum.SUCCESS}
            heading={t('esim:orderNewESim.after-submit.qr.success.title')}
            description={
              <SanitizedHtml
                htmlTag="span"
                rawHtmlData={t(
                  'esim:orderNewESim.after-submit.qr.success.description',
                )}
              />
            }
            button={{
              label: t('component:button.finish'),
              onClick: onCloseButtonClick,
            }}
          />
        )}

        {/* EID SUCCESS */}
        {isEIdMethodSuccess && (
          <ModalMessageTemplate
            type={ModalMessageTypeEnum.SUCCESS}
            heading={t('esim:orderNewESim.after-submit.eid.success.title')}
            description={
              <SanitizedHtml
                htmlTag="span"
                rawHtmlData={t(
                  'esim:orderNewESim.after-submit.eid.success.description',
                )}
              />
            }
            button={{
              label: t('component:button.finish'),
              onClick: onCloseButtonClick,
            }}
          />
        )}

        {/* ERROR MESSAGE */}
        {isError && (
          <ModalMessageTemplate
            type={ModalMessageTypeEnum.ERROR}
            button={{
              label: t('component:button.close'),
              onClick: onCloseButtonClick,
            }}
          />
        )}
      </CustomCardPadding>
    </Card>
  );
};

export default OrderNewESimContent;
