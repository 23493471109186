export enum SessionActionTypes {
  FETCH_SESSION_BEGIN = 'FETCH_SESSION_BEGIN',
  FETCH_SESSION_SUCCESS = 'FETCH_SESSION_SUCCESS',
  FETCH_SESSION_FAILURE = 'FETCH_SESSION_FAILURE',
  USER_LOGIN = 'USER_LOGIN',
  USER_LOGOUT = 'USER_LOGOUT',
  CHANGE_DEFAULT_ROLE_BEGIN = 'CHANGE_DEFAULT_ROLE_BEGIN',
  CHANGE_DEFAULT_ROLE_SUCCESS = 'CHANGE_DEFAULT_ROLE_SUCCESS',
  CHANGE_DEFAULT_ROLE_FAILURE = 'CHANGE_DEFAULT_ROLE_FAILURE',
  DELETE_PROFILE_BEGIN = 'DELETE_PROFILE_BEGIN',
  DELETE_PROFILE_SUCCESS = 'DELETE_PROFILE_SUCCESS',
  DELETE_PROFILE_FAILURE = 'DELETE_PROFILE_FAILURE',
  FETCH_LARGE_SUBSCRIBERS_BEGIN = 'FETCH_LARGE_SUBSCRIBERS_BEGIN',
  FETCH_LARGE_SUBSCRIBERS_SUCCESS = 'FETCH_LARGE_SUBSCRIBERS_SUCCESS',
  FETCH_LARGE_SUBSCRIBERS_FAILURE = 'FETCH_LARGE_SUBSCRIBERS_FAILURE',
  FETCH_LARGE_BILLING_ACCOUNTS_BEGIN = 'FETCH_LARGE_BILLING_ACCOUNTS_BEGIN',
  FETCH_LARGE_BILLING_ACCOUNTS_SUCCESS = 'FETCH_LARGE_BILLING_ACCOUNTS_SUCCESS',
  FETCH_LARGE_BILLING_ACCOUNTS_FAILURE = 'FETCH_LARGE_BILLING_ACCOUNTS_FAILURE',
  SWITCH_BILLING_ACCOUNT = 'SWITCH_BILLING_ACCOUNT',
  UPDATE_CONTACT_NUMBER = 'UPDATE_CONTACT_NUMBER',
}
