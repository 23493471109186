import { LanguagesEnum } from '../types/translation/languagesEnum';

export const toValidDateFormat = (dateString: string) =>
  new Date(
    dateString.replace('GMT', '').replace('CEST', '').replace('CET', ''),
  );

const toNumericDateStringAcc = {};

export const toNumericDateString = (dateString: string) => {
  if (toNumericDateStringAcc[dateString]) {
    return toNumericDateStringAcc[dateString];
  }

  const date = toValidDateFormat(dateString);
  const dateTimeFormat = new Intl.DateTimeFormat('en', {
    year: 'numeric',
    month: 'numeric',
    day: '2-digit',
  });
  try {
    const [{ value: month }, , { value: day }, , { value: year }] =
      dateTimeFormat.formatToParts(date);

    toNumericDateStringAcc[dateString] = `${parseInt(day, 10)}. ${parseInt(
      month,
      10,
    )}. ${year}`;
    return `${parseInt(day, 10)}. ${parseInt(month, 10)}. ${year}`;
  } catch (e) {
    return '';
  }
};

export const toLongMonthDateString = (dateString: string, language) => {
  const date = new Date(dateString);
  const locale = language === LanguagesEnum.CS ? 'cs-CZ' : 'en-CZ';
  const dateTimeFormat = new Intl.DateTimeFormat(locale, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
  const [{ value: day }, , { value: month }, , { value: year }] =
    dateTimeFormat.formatToParts(date);

  switch (locale) {
    case 'en-CZ':
      return `${day} ${month}. ${year}`;
    default:
      return `${day}. ${month} ${year}`;
  }
};

const toMonthNameAcc = {};
const hashKey = (s) => {
  const range = Array(s.length);

  for (let i = 0; i < s.length; i += 1) {
    range[i] = i;
  }

  return Array.prototype.map
    .call(range, (i) => s.charCodeAt(i).toString(16))
    .join('');
};

export const toMonthName = (
  dateString: string,
  language,
  firstLetterUppercase = false,
) => {
  const key = hashKey(
    `${dateString}${language}${firstLetterUppercase ? 1 : 0}`,
  );

  if (toMonthNameAcc[key]) {
    return toMonthNameAcc[key];
  }

  const date = new Date(dateString);
  const locale = language === LanguagesEnum.CS ? 'cs-CZ' : 'en-CZ';
  const dateTimeFormat = new Intl.DateTimeFormat(locale, { month: 'long' });
  const [{ value: month }] = dateTimeFormat.formatToParts(date);

  if (firstLetterUppercase) {
    return month.charAt(0).toUpperCase() + month.slice(1);
  }

  toMonthNameAcc[key] = month;

  return month;
};

export const getDateDaysNumberFromToday = (dateString: string) => {
  return Math.ceil(
    (new Date(dateString).getTime() - new Date().getTime()) /
      (1000 * 3600 * 24),
  ).toString();
};

export const DATE_FORMAT_DD_MM_YYYY = 'DD.MM.YYYY';

export const formatDateToString = (date: Date, format: string) => {
  // return moment(date).format(format); // I Hope we will soon use this
  if (format !== DATE_FORMAT_DD_MM_YYYY) {
    throw new Error('Not implemented format');
  } else {
    return toNumericDateString(date.toString());
  }
};

export const formatTimeToString = (dateTime: string) => {
  const dateTimezone = toValidDateFormat(dateTime);

  return dateTimezone.toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  });
};
