import { ActionCreator } from 'redux';
import validateSmsCodeRequestCall from 'src/api/VerifySmsCode';
import {
  IVerifySmsCodeBeginAction,
  IVerifySmsCodeFailureAction,
  IVerifySmsCodeInvalidateAction,
  IVerifySmsCodeSuccess,
  IVerifySmsCodeSuccessAction,
} from './types/verifySmsCodeActionInterfaces';
import VerifySmsCodeActionTypes from './types/verifySmsCodeActionTypes';

export const validateSmsCodeBegin: ActionCreator<
  IVerifySmsCodeBeginAction
> = () => ({
  type: VerifySmsCodeActionTypes.VALIDATE_SMS_CODE_BEGIN,
});

export const validateSmsCodeSuccess: ActionCreator<
  IVerifySmsCodeSuccessAction
> = (payload: IVerifySmsCodeSuccess) => ({
  type: VerifySmsCodeActionTypes.VALIDATE_SMS_CODE_SUCCESS,
  payload,
});

export const validateSmsCodeFailure: ActionCreator<
  IVerifySmsCodeFailureAction
> = () => ({
  type: VerifySmsCodeActionTypes.VALIDATE_SMS_CODE_FAILURE,
});

export const validateSmsCodeInvalidate: ActionCreator<
  IVerifySmsCodeInvalidateAction
> = () => ({
  type: VerifySmsCodeActionTypes.VALIDATE_SMS_CODE_INVALIDATE,
});

export const validateSmsCode =
  (verificationTarget: string, code: string) => (dispatch) => {
    dispatch(validateSmsCodeBegin());

    return validateSmsCodeRequestCall({ verificationTarget, code })
      .then((response) => {
        dispatch(
          validateSmsCodeSuccess({ isVerified: response.status === 200 }),
        );
      })
      .catch((error) => {
        if (error.response.status === 400) {
          dispatch(
            validateSmsCodeSuccess({
              isVerified: error.response.status === 200,
            }),
          );
        } else {
          dispatch(validateSmsCodeFailure());
        }
      });
  };
