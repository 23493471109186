import { Reducer } from 'redux';
import initialState from 'src/store/initialState';
import {
  ActivateRecurringDataOfferTypes,
  GetRecurringDataOfferTypes,
} from 'src/store/recurringDataOffer/types/recurringDataOfferActionTypes';
import {
  IActivateDataOfferBeginAction,
  IActivateDataOfferErrorAction,
  IActivateDataOfferSuccessAction,
  IFetchGetDataOfferBeginAction,
  IFetchGetDataOfferErrorAction,
  IFetchGetDataOfferSuccessAction,
} from 'src/store/recurringDataOffer/types/recurringDataOfferInterface';
import {
  IActivateRecurringDataOfferState,
  IGetRecurringDataOfferState,
} from 'src/store/recurringDataOffer/types/recurringDataOfferStateInterface';

type FetchRecurringDataOfferAction =
  | IFetchGetDataOfferBeginAction
  | IFetchGetDataOfferSuccessAction
  | IFetchGetDataOfferErrorAction;

export const recurringDataOfferReducer: Reducer<IGetRecurringDataOfferState> = (
  state: IGetRecurringDataOfferState = initialState.RecurringDataOffer.Offer,
  action: FetchRecurringDataOfferAction,
) => {
  switch (action.type) {
    case GetRecurringDataOfferTypes.FETCH_GET_DATA_OFFER_BEGIN:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      };

    case GetRecurringDataOfferTypes.FETCH_GET_DATA_OFFER_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        loaded: true,
        error: false,
      };

    case GetRecurringDataOfferTypes.FETCH_GET_DATA_OFFER_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };

    default:
      return state;
  }
};

type ActivateRecurringDataOfferAction =
  | IActivateDataOfferBeginAction
  | IActivateDataOfferSuccessAction
  | IActivateDataOfferErrorAction;

export const activateRecurringDataOfferReducer: Reducer<
  IActivateRecurringDataOfferState
> = (
  state: IActivateRecurringDataOfferState = initialState.RecurringDataOffer
    .Activation,
  action: ActivateRecurringDataOfferAction,
) => {
  switch (action.type) {
    case ActivateRecurringDataOfferTypes.ACTIVATE_DATA_OFFER_BEGIN:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      };

    case ActivateRecurringDataOfferTypes.ACTIVATE_DATA_OFFER_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };

    case ActivateRecurringDataOfferTypes.ACTIVATE_DATA_OFFER_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };

    default:
      return state;
  }
};
