import i18next from 'i18next';
import { ServiceCodeNameEnum } from 'src/components';
import { IService } from 'src/store/services/types/service/serviceInterface';
import { searchParamInUrl } from 'src/utils/url';
import {
  API_ENDPOINT_SERVICES_BSS_DETAIL,
  API_ENDPOINT_SERVICES_DETAIL,
} from '../..';
import {
  bssOnwServices,
  BssServicesEnums,
} from '../../../types/services/bssServicesEnums';
import VirtualBssServicesEnum from '../../../types/services/virtualBssServicesEnum';
import { LanguagesEnum } from '../../../types/translation/languagesEnum';
import { API_DEFAULT_ENDPOINT } from '../../endpoints/endpoints';

const bssServices = Object.values(BssServicesEnums);

const resolveWscEndpoint = (): string =>
  i18next.language === LanguagesEnum.EN
    ? `${API_DEFAULT_ENDPOINT}/en`
    : API_DEFAULT_ENDPOINT;

const resolveServicesBssDetailUrl = (service: IService): string => {
  let serviceName: string = service.productOfferingId;
  let withSettingsSuffix = true;

  if (bssOnwServices.indexOf(serviceName as BssServicesEnums) !== -1) {
    serviceName = VirtualBssServicesEnum.ONW;
    withSettingsSuffix = false;
  }

  return `${resolveWscEndpoint()}${API_ENDPOINT_SERVICES_BSS_DETAIL}/${serviceName}${
    withSettingsSuffix ? '/1' : ''
  }`;
};

type ResolveServiceQueryType = {
  offeringId: string;
  codename: string;
  iccid?: string;
};

const resolveServiceDetailQuery = (
  service: IService,
): ResolveServiceQueryType => {
  const query: ResolveServiceQueryType = {
    offeringId: service.productOfferingId,
    codename: service.codeName,
  };

  if (service.codeName === ServiceCodeNameEnum.SIM_ACTIVATION) {
    query.iccid = searchParamInUrl('iccid');
  }

  return query;
};

const resolveServiceDetailEndpoint = (service: IService) => {
  if (bssServices.indexOf(service.productOfferingId as any) > -1) {
    return {
      url: resolveServicesBssDetailUrl(service),
    };
  }

  return {
    url: `${resolveWscEndpoint()}${API_ENDPOINT_SERVICES_DETAIL}`,
    query: resolveServiceDetailQuery(service),
  };
};

export default resolveServiceDetailEndpoint;
