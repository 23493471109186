import {
  Button,
  Col,
  Container,
  IconMoveMoney,
  Input,
  Row,
  Text,
} from '@vodafone/red-white';
import { isNaN } from 'formik';
import * as React from 'react';
import { Dispatch, useEffect } from 'react';
import { ThemeContext } from 'styled-components';
import Price from '../../../../components/Price';
import { numberOfDecimals } from '../../../../utils/numberOfDecimals';
import * as Styled from './PayDifferentAmount.styled';

export type PayDifferentAmountStatus = 'default' | 'warning' | 'danger';

export interface IPayDifferentAmountProps {
  title: string;
  billing: {
    status: PayDifferentAmountStatus;
    title: string;
    amount: number;
    subtitle: string;
    due: string;
    buttonText: string;
  };
  input: {
    label: string;
    message: string;
  };
  value: string;
  setValue: Dispatch<string>;
  onClick: () => void;
}

const getColor = (status?: PayDifferentAmountStatus) => {
  if (status === 'danger') return 'primaryColor';
  if (status === 'warning') return 'orange';

  return 'darkGrey';
};

const PayDifferentAmount: React.FC<IPayDifferentAmountProps> = ({
  title,
  billing,
  input,
  onClick,
  value,
  setValue,
}) => {
  const themeContext = React.useContext(ThemeContext);
  const billingTextColor = getColor(billing.status);
  const [error, setError] = React.useState(false);

  useEffect(() => {
    if (
      isNaN(+value) ||
      value.trim() === '' ||
      (!isNaN(+value) && +value < 120)
    ) {
      setError(true);
    } else {
      setError(false);
    }
  }, [value]);

  return (
    <Container>
      <Row align="center">
        <Styled.BillingCol xs={24} lg={12}>
          <Styled.BillingBase>
            <Styled.Header>
              <span>
                <IconMoveMoney color={themeContext.colors.darkGrey} />
              </span>
              <Styled.HeadingText
                color="darkGrey"
                fontSize={18}
                fontFamily="VodafoneRgBd"
              >
                {title}
              </Styled.HeadingText>
            </Styled.Header>

            <Styled.BillingWrapper>
              <Input
                autoFocus
                autoComplete={false}
                id="differentPaymentAmount"
                name="differentPaymentAmount"
                labelAsPlaceholder
                label={input.label}
                message={input.message}
                type="text"
                value={value}
                errored={error}
                disabled={false}
                onChange={(e) => {
                  if (!isNaN(+e.target.value)) {
                    const number = e.target.value.trim();

                    if (numberOfDecimals(parseFloat(number)) > 2) {
                      setValue(parseFloat(number).toFixed(2));
                    } else {
                      setValue(number);
                    }
                  }
                }}
                icon={
                  <span
                    style={{
                      color: error
                        ? themeContext.colors.red
                        : themeContext.colors.fontColor,
                    }}
                  >
                    Kč
                  </span>
                }
              />
            </Styled.BillingWrapper>

            <Styled.BillingFooter>
              <Button
                variant="primary"
                block={false}
                disabled={error}
                onClick={onClick}
              >
                {billing.buttonText}
              </Button>
            </Styled.BillingFooter>
          </Styled.BillingBase>
        </Styled.BillingCol>
        <Col xs={24} lg={12}>
          <Styled.ContentWrapper>
            <Styled.SubtitleText block>{billing.title}</Styled.SubtitleText>

            <Styled.BillingWrapper>
              <Styled.BillingText
                color="darkGrey"
                fontSize={56}
                fontFamily="VodafoneLt"
              >
                <Price price={billing.amount} />
              </Styled.BillingText>
              <Styled.BillingWrapperDetail>
                <Text
                  block
                  color={billingTextColor}
                  fontSize={18}
                  fontFamily="VodafoneRgBd"
                >
                  {billing.subtitle}
                </Text>
                <Text block color="darkGrey" fontSize={16}>
                  <span
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{ __html: billing.due }}
                  />
                </Text>
              </Styled.BillingWrapperDetail>
            </Styled.BillingWrapper>
          </Styled.ContentWrapper>
        </Col>
      </Row>
    </Container>
  );
};

export default PayDifferentAmount;
