import { ActionCreator } from 'redux';
import fetchFbbModemMacAddress from 'src/api/fbb/request/fetchFbbModemMacAddress';
import { IGetFbbModemMacAddressInterface } from 'src/api/fbb/response/getFbbModemMacAddressInterface';
import { API_ENDPOINT_FBB_GET_MODEM_MAC_ADDRESS } from '../../../api';
import {
  apiBegin,
  apiFailure,
  apiSuccess,
} from '../../application/applicationActions';
import {
  FbbModemMacAddressTypes,
  FETCH_FBB_MODEM_MAC_ADDRESS_REFRESHING,
  FETCH_FBB_MODEM_MAC_ADDRESS_SUCCESS,
} from '../types/fbbModemMacAddress/fbbModemMacAddressActionTypes';

export const fetchModemMacAddressSuccess: ActionCreator<
  FbbModemMacAddressTypes
> = (payload: IGetFbbModemMacAddressInterface) => ({
  type: FETCH_FBB_MODEM_MAC_ADDRESS_SUCCESS,
  payload,
});

export const fetchModemMacAddressRefresh: ActionCreator<
  FbbModemMacAddressTypes
> = () => ({
  type: FETCH_FBB_MODEM_MAC_ADDRESS_REFRESHING,
});

export const getFbbModemMacAddress =
  (subscribtionId: string, modemIdentifier: string) => (dispatch) => {
    dispatch(apiBegin(API_ENDPOINT_FBB_GET_MODEM_MAC_ADDRESS));

    return fetchFbbModemMacAddress(subscribtionId, modemIdentifier)
      .then((response) => {
        if (response.status === 202 || response.data.status === null) {
          // waiting for response
        } else {
          dispatch(fetchModemMacAddressSuccess(response.data));
          dispatch(apiSuccess(API_ENDPOINT_FBB_GET_MODEM_MAC_ADDRESS));
        }
      })
      .catch(() => {
        dispatch(apiFailure(API_ENDPOINT_FBB_GET_MODEM_MAC_ADDRESS));
      });
  };
