import { Reducer } from 'redux';
import initialState from '../../initialState';
import {
  IFetchBillingCustomerBeginAction,
  IFetchBillingCustomerFailureAction,
  IFetchBillingCustomerSuccessAction,
} from './types/billingCustomerActionInterfaces';
import BillingCustomerActionTypes from './types/billingCustomerActionTypes';
import IBillingCustomerState from './types/billingCustomerStateInterface';

type IBillingCustomerActions =
  | IFetchBillingCustomerBeginAction
  | IFetchBillingCustomerSuccessAction
  | IFetchBillingCustomerFailureAction;

const billingCustomer: Reducer<
  IBillingCustomerState,
  IBillingCustomerActions
> = (state = initialState.Billing.BillingCustomer, action) => {
  switch (action.type) {
    case BillingCustomerActionTypes.FETCH_BILLING_CUSTOMER_BEGIN:
      return {
        ...state,
        selectedCaNumber: action.caNumber,
        data: {
          [action.caNumber]: {
            ...state.data[action.caNumber],
            loading: true,
            loaded: false,
            error: false,
          },
        },
      };
    case BillingCustomerActionTypes.FETCH_BILLING_CUSTOMER_SUCCESS:
      return {
        ...state,
        data: {
          [action.caNumber]: {
            ...action.payload,
            loading: false,
            loaded: true,
            error: false,
          },
        },
      };
    case BillingCustomerActionTypes.FETCH_BILLING_CUSTOMER_FAILURE:
      return {
        ...state,
        data: {
          [action.caNumber]: {
            ...state.data[action.caNumber],
            loading: false,
            loaded: false,
            error: true,
          },
        },
      };
    default:
      return state;
  }
};

export default billingCustomer;
