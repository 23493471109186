import { AxiosRequestConfig, AxiosResponse } from 'axios';
import * as ErrorStackParser from 'error-stack-parser';
import beautify from 'json-beautify';
import { ActionCreator } from 'redux';
import { APP_LOG_ERRORS_IS_ENABLED } from 'src/env';
import { API_ENDPOINT_ERROR_LOG } from '../../../api';
import api from '../../../utils/api/api';
import { ErrorSeverityEnum } from '../types/errorSeverityEnum';

enum errorType {
  COMPONENT = 'COMPONENT',
  REQUEST = 'REQUEST',
}

interface ILogMessage {
  severity: ErrorSeverityEnum;
  logger: string;
  message: string;
}

const createLogMessage = (
  type: errorType,
  severity: ErrorSeverityEnum,
  error: Error,
  componentStack?: string,
  ajaxRequest?: IAjaxRequest,
): ILogMessage => {
  switch (type) {
    case errorType.COMPONENT: {
      let logger = 'Javascript component error';
      const errorStack = ErrorStackParser.parse(error);

      if (errorStack && errorStack.length && errorStack[0].getFunctionName()) {
        logger = errorStack[0].getFunctionName();
      }

      if (error.message) {
        logger += ` ${error.message};`;
      }

      return {
        severity,
        logger,
        message: `
        ${error.message}
        ${error.stack}
--------- COMPONENT STACK: ---------
      ${componentStack}`,
      };
    }

    case errorType.REQUEST: {
      let logger = `[${ajaxRequest.config.method.toUpperCase()}] ${
        ajaxRequest.config.url
      }`;

      if (error.message) {
        logger += ` ${error.message};`;
      }

      return {
        severity,
        logger,
        message: `
        ${error.message}
        ${error.stack}
--------- REQUEST :---------
        ${beautify(ajaxRequest, null, 2)}`,
      };
    }

    default:
      return null;
  }
};

export const logComponentError: ActionCreator<any> =
  (error: Error, componentStack: string, severity = ErrorSeverityEnum.FATAL) =>
  () => {
    if (APP_LOG_ERRORS_IS_ENABLED === 'false') return null;

    return api.post({
      url: API_ENDPOINT_ERROR_LOG,
      data: createLogMessage(
        errorType.COMPONENT,
        severity,
        error,
        componentStack,
      ),
    });
  };

interface IAjaxRequest {
  config: AxiosRequestConfig;
  response: AxiosResponse;
}

export const logAjaxError: ActionCreator<any> =
  (
    error: Error,
    ajaxRequest: IAjaxRequest,
    severity = ErrorSeverityEnum.ERROR,
  ) =>
  () => {
    if (
      APP_LOG_ERRORS_IS_ENABLED === 'false' ||
      ajaxRequest.config.url === API_ENDPOINT_ERROR_LOG
    )
      return null;

    return api.post({
      url: API_ENDPOINT_ERROR_LOG,
      data: createLogMessage(
        errorType.REQUEST,
        severity,
        error,
        null,
        ajaxRequest,
      ),
    });
  };
