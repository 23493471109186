import * as React from 'react';
import { useEffect, useRef } from 'react';
import QRCodeLib, { QRCodeProps } from 'react-qr-code';

export interface IQRCodeProps extends QRCodeProps {}

const QRCode: React.FC<IQRCodeProps> = ({ value }) => {
  const [size, setSize] = React.useState<number>(100);

  const ref = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      if (ref.current) {
        setSize(ref.current.offsetWidth);
      }
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  window.onresize = () => {
    if (ref.current) {
      setSize(ref.current.offsetWidth);
    }
  };

  return (
    <div ref={ref}>
      <QRCodeLib value={value} size={size} />
    </div>
  );
};

export default QRCode;
