import initialState from '../initialState';
import { NoticeActionTypesEnum } from './noticeEnums';
import {
  INoticeActionInterface,
  INoticeStateInterface,
} from './noticeInterfaces';

const notice = (
  state = initialState.Notice,
  action: INoticeActionInterface,
): INoticeStateInterface => {
  switch (action.type) {
    case NoticeActionTypesEnum.SHOW_NOTICE:
      return {
        ...state,
        settings: action.settings || initialState.Notice.settings,
        isActive: true,
      };

    case NoticeActionTypesEnum.HIDE_NOTICE:
      return {
        ...state,
        settings: initialState.Notice.settings,
        isActive: false,
      };

    default:
      return state;
  }
};

export default notice;
