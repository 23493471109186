import { Reducer } from 'redux';
import {
  ISubmitSimActivationBeginAction,
  ISubmitSimActivationFailureAction,
  ISubmitSimActivationSuccessAction,
} from 'src/store/services/types/simActivation/simActivationActionInterfaces';
import { SimActivationActionTypes } from 'src/store/services/types/simActivation/simActivationActionTypes';
import { ISimActivationState } from 'src/store/services/types/simActivation/simActivationStateInterface';
import initialState from '../../initialState';

type SimActivationActions =
  | ISubmitSimActivationBeginAction
  | ISubmitSimActivationSuccessAction
  | ISubmitSimActivationFailureAction;

export const simActivationReducer: Reducer<ISimActivationState> = (
  state: ISimActivationState = initialState.Services.simActivation,
  action: SimActivationActions,
) => {
  switch (action.type) {
    case SimActivationActionTypes.SUBMIT_SIM_ACTIVATION_BEGIN:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      };

    case SimActivationActionTypes.SUBMIT_SIM_ACTIVATION_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        data: action.payload,
      };

    case SimActivationActionTypes.SUBMIT_SIM_ACTIVATION_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        data: action.payload,
      };

    default:
      return state;
  }
};

export default simActivationReducer;
