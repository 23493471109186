import { AxiosResponse } from 'axios';
import i18next from 'i18next';
import {
  API_ENDPOINT_REQUEST_VALIDATION_SMS_ENDPOINT,
  API_ENDPOINT_REQUEST_VALIDATION_SMS_ENDPOINT_EN,
} from 'src/api';
import api from 'src/utils/api/api';
import RequestValidationSmsInterface from './request/requestValidationSmsInterface';
import ResponseValidationSmsInterface from './response/responseValidationSmsInterface';

const API_ENDPOINT_REQUEST_VALIDATION_SMS_APIS = {
  cs: API_ENDPOINT_REQUEST_VALIDATION_SMS_ENDPOINT,
  en: API_ENDPOINT_REQUEST_VALIDATION_SMS_ENDPOINT_EN,
};

const requestValidationSmsCall = (
  request: RequestValidationSmsInterface,
): Promise<AxiosResponse<ResponseValidationSmsInterface>> => {
  const data = request.messageType
    ? {
        messageType: request.messageType,
      }
    : {};

  return api.post({
    url: API_ENDPOINT_REQUEST_VALIDATION_SMS_APIS[i18next.language].replace(
      '{verificationTarget}',
      request.verificationTarget,
    ),
    data,
  });
};

export default requestValidationSmsCall;
