import { useEffect, useRef } from 'react';

export const useOutsideComponentClick = (callback: () => void) => {
  const callbackRef = useRef<Function>();
  const innerRef = useRef<HTMLInputElement>();

  useEffect(() => {
    callbackRef.current = callback;
  });

  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      if (
        innerRef.current &&
        callbackRef.current &&
        !innerRef.current.contains(e.target as Node)
      )
        callbackRef.current(e);
    };

    document.addEventListener('click', handleClick);
    return () => document.removeEventListener('click', handleClick);
  }, []);

  return innerRef;
};
