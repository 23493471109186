import { combineReducers } from 'redux';
import billingAccount from './billingAccount';
import billingCustomer from './billingCustomer';
import payerPayment from './payerPayment';
import paymentInfo from './paymentInfo';
import paymentStatus from './paymentStatus';
import paymentTransaction from './paymentTransaction';
import upcInvoice from './upcInvoice';

export default combineReducers({
  PaymentInfo: paymentInfo,
  PaymentTransaction: paymentTransaction,
  PaymentStatus: paymentStatus,
  BillingCustomer: billingCustomer,
  PayerPayment: payerPayment,
  UpcInvoice: upcInvoice,
  BillingAccount: billingAccount,
});
