import { createContext, useContext } from 'react';

export type RefreshTokenContextProps = {
  tokenExpiresIn: string;
};

export const RefreshTokenContext =
  createContext<RefreshTokenContextProps>(undefined);

export const useRefreshTokenContext = () => {
  const context = useContext(RefreshTokenContext);

  if (context === undefined) {
    throw new Error(
      'useRefreshTokenContext must be used within a RefreshTokenProvider',
    );
  }

  return context;
};
