import {
  CircleLoader,
  ReceivedPaymentsOverview,
  Text,
} from '@vodafone/red-white';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'src/hooks/useAppDispatch';
import { IApplicationState } from 'src/store/appStateInterface';
import { fetchPayerPayment } from 'src/store/billing/payerPayment/payerPaymentActions';
import { selectPayerPayment } from 'src/store/billing/payerPayment/payerPaymentSelectors';
import { IPayerPaymentItem } from 'src/store/billing/payerPayment/types/payerPaymentInterface';
import IPayerPaymentState from 'src/store/billing/payerPayment/types/payerPaymentStateInterface';
import IBillingAccount from 'src/store/user/types/billingAccountInterface';
import { selectBillingAccount } from 'src/store/user/userSelector';
import styled from 'styled-components';
import { toNumericDateString, toValidDateFormat } from '../../../utils/date';
import Price from '../../Price';
import { ModalContentProps } from '../types/modalContentInterfaces';

const HeadingWrapper = styled.div`
  padding: 14px;

  @media ${(props) => props.theme.breakpoints.md} {
    padding: 32px 32px 0;
  }
`;

const NoPaymentWrapper = styled.div`
  padding: 0 14px 14px;

  @media ${(props) => props.theme.breakpoints.md} {
    padding: 32px;
  }
`;

const LoaderWrapper = styled.div`
  display: flex;
  min-height: 200px;
`;

const ReceivedPaymentsContent: FC<ModalContentProps> = () => {
  const payments: IPayerPaymentState = useSelector(selectPayerPayment);
  const { t } = useTranslation('billing');
  const dispatch = useAppDispatch();
  const billingAccount: IBillingAccount = useSelector(
    (state: IApplicationState) => selectBillingAccount(state),
  );

  const dataObject = {
    key: `vodafone-payments`,
    name: t(`billing:payerPayments:vodafone`),
    data: [],
  };

  if (payments.loaded && payments.data) {
    const sortPayments = payments.data.vodafone.sort(
      (a, b) =>
        new Date(toValidDateFormat(b.paymentDate)).getTime() -
        new Date(toValidDateFormat(a.paymentDate)).getTime(),
    );

    sortPayments.forEach((item: IPayerPaymentItem, i) => {
      dataObject.data.push({
        key: i.toString(),
        date: toNumericDateString(item.paymentDate),
        amount: <Price price={item.totalAmount} currency={item.currencyCode} />,
        method: t(`billing:payerPayments:state-${item.paymentType}`),
      });
    });
  }

  useEffect(() => {
    dispatch(fetchPayerPayment(billingAccount.billingAccountNumber));
  }, [dispatch, billingAccount.billingAccountNumber]);

  return (
    <>
      <HeadingWrapper>
        <Text fontFamily="VodafoneRgBd" fontSize={18} color="darkGrey">
          {t('billing:payerPayments:title')}
        </Text>
      </HeadingWrapper>

      {payments.loading && (
        <LoaderWrapper>
          <CircleLoader />
        </LoaderWrapper>
      )}

      {!payments.loading &&
        (dataObject.data.length ? (
          <ReceivedPaymentsOverview
            tabs={[dataObject]}
            onClick={() => {}}
            isTabBarHidden
          />
        ) : (
          <NoPaymentWrapper>
            <Text fontFamily="VodafoneRg" fontSize={18} color="darkGrey">
              {t('billing:payerPayments:no-payment')}
            </Text>
          </NoPaymentWrapper>
        ))}
    </>
  );
};

export default ReceivedPaymentsContent;
