import {
  Button,
  Col,
  DescriptionList,
  DescriptionListItem,
  IconEsim2,
  IconPdfText,
  IconPrint,
  Link,
  Row,
  Text,
  Title,
} from '@vodafone/red-white';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { eSimDownloadQRCodeUrl } from 'src/api/esim/request/eSimDownloadQRCodeRequest';
import { eSimPrintQRCodeUrl } from 'src/api/esim/request/eSimPrintQRCodeRequest';
import { ESimActivationMethods } from 'src/components/Modal/components/ESim/enums/ESimModalEnum';
import { useAppDispatch } from 'src/hooks/useAppDispatch';
import { eSimDeliveryResetStore } from 'src/store/esim/esimActions';
import {
  selectESimDetail,
  selectESimQRCodePayload,
} from 'src/store/esim/esimSelectors';
import { IESimDetailState } from 'src/store/esim/types/esimStateInterface';
import { openModal } from 'src/store/modal/modalActions';
import ModalType from 'src/store/modal/types/ModalType';
import QRCode from 'src/ui/components/QRCode';
import SanitizedHtml from 'src/utils/sanitizeHtml';
import styled from 'styled-components';

const IconWrapper = styled.span`
  margin: 3px 15px 0 0;
  float: left;
`;

const QRCodeWrapper = styled.div`
  @media ${(props) => props.theme.breakpoints.maxMd} {
    padding: 0 30%;
  }
`;

const LinkText = styled(Link)`
  font-size: 16px;
  color: ${(props) => props.theme.colors.darkGrey};
  display: inline-block;
  padding: 0 15px;
`;

const DescriptionListWrapper = styled(`div`)`
  margin: 20px 0;
`;

const LinkInlineText = styled(Link)`
  font-size: 18px;
  display: inline-block;
`;

interface IProps {
  serviceNumber: string;
  onClose: () => void;
}

const ActivateESimByQRCodeContent: FC<IProps> = ({
  serviceNumber,
  onClose,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['esim']);

  const eSimDetail: IESimDetailState = useSelector(selectESimDetail);
  const qrCodePayload: string | null = useSelector(selectESimQRCodePayload);

  const reopenOrder = (type: ESimActivationMethods) => {
    onClose();
    dispatch(eSimDeliveryResetStore());

    dispatch(
      openModal({
        type: ModalType.OrderNewESim,
        options: {
          serviceNumber,
          defaultMethod: type,
          changeCurrentActivationMethod: true,
        },
      }),
    );
  };

  return (
    <>
      <Title
        color="darkGrey"
        fontFamily="VodafoneRg"
        fontWeight="bold"
        fontSize={26}
      >
        {t('esim:activateESim:by-qr-code.sub-title')}
      </Title>

      <DescriptionListWrapper>
        <DescriptionList>
          <DescriptionListItem
            title={t('esim:activateESim:by-qr-code.step-1')}
          />
          <DescriptionListItem
            title={t('esim:activateESim:by-qr-code.step-2')}
          />
          <DescriptionListItem
            title={t('esim:activateESim:by-qr-code.step-3')}
          />
          <DescriptionListItem
            title={
              <SanitizedHtml
                htmlTag="span"
                rawHtmlData={t('esim:activateESim:by-qr-code.step-4')}
              />
            }
          />
        </DescriptionList>
      </DescriptionListWrapper>

      <Row align="center">
        <Col xs={24} md={6}>
          {qrCodePayload && (
            <QRCodeWrapper>
              <QRCode value={qrCodePayload} />
            </QRCodeWrapper>
          )}
        </Col>
        <Col xs={0} md={1} />
        <Col xs={24} md={17}>
          <a
            href={eSimDownloadQRCodeUrl(serviceNumber)}
            target="_blank"
            rel="noopener noreferrer"
            download
          >
            <IconWrapper>
              <IconPdfText size={20} />
            </IconWrapper>
            <Text fontSize={16}>
              {t('esim:activateESim:by-qr-code:download-qr-code')}
            </Text>
          </a>

          <a
            href={eSimPrintQRCodeUrl(serviceNumber)}
            target="_blank"
            rel="noopener noreferrer"
          >
            <IconWrapper>
              <IconPrint size={20} />
            </IconWrapper>
            <Text fontSize={16}>
              {t('esim:activateESim:by-qr-code:print-qr-code')}
            </Text>
          </a>

          <div>
            <IconWrapper>
              <IconEsim2 size={20} />
            </IconWrapper>
            <Text fontSize={16}>
              {t('esim:activateESim:by-qr-code.iccid-info', {
                iccid: eSimDetail.data.iccid,
              })}
            </Text>
          </div>
        </Col>
      </Row>

      <p>
        <SanitizedHtml
          htmlTag="div"
          style={{ marginTop: 15, marginBottom: 15 }}
          rawHtmlData={t('esim:activateESim:by-qr-code:bottom-help-text')}
        />
      </p>

      <p>
        <SanitizedHtml
          htmlTag="span"
          style={{ marginTop: 15, marginBottom: 15 }}
          rawHtmlData={t('esim:activateESim:by-qr-code:bottom-help-text-2')}
        />
        <LinkInlineText
          onClick={() => {
            dispatch(
              openModal({
                type: ModalType.CompleteSimSwapContent,
                options: {
                  serviceNumber,
                  iccid: eSimDetail.data.iccid,
                },
              }),
            );
          }}
          style={{ display: 'inline' }}
        >
          <strong>
            {t('esim:activateESim:by-qr-code:bottom-help-text-2-button')}
          </strong>
        </LinkInlineText>
        {t('esim:activateESim:by-qr-code:bottom-help-text-2-dot')}
      </p>

      <div>
        <Button
          htmlElement="button"
          variant="primary"
          onClick={() => onClose()}
        >
          {t('component:button.close')}
        </Button>
        <LinkText onClick={() => reopenOrder(ESimActivationMethods.EID)}>
          {t('esim:activateESim:by-qr-code.activate-by-eeid')}
        </LinkText>
      </div>
    </>
  );
};

export default ActivateESimByQRCodeContent;
