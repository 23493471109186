import { ActionCreator } from 'redux';
import { ExitServicesAuthTypeEnum } from 'src/api/exitServices/response/exitServicesAuthTypeResponseInterface';
import { getMnpServiceDetail } from 'src/api/serviceManagement';
import MnpServicePenaltyInteractionPointEnum from 'src/store/mnp/serviceDetail/types/MnpServicePenaltyInteractionPointEnum';
import { openModal } from '../../modal/modalActions';
import ModalType from '../../modal/types/ModalType';
import { requestValidationSmsRequest } from '../../requestValidationSms/requestValidationSmsAction';
import {
  loadServiceBegin,
  loadServiceFailure,
  loadServiceSuccess,
} from '../../services/actions/serviceActions';
import { IService } from '../../services/types/service/serviceInterface';
import { invokeMnpCvop } from '../cvop/mnpCvopActions';
import { fetchAuthType, fetchMnpServiceOku } from '../oku/mnpOkuActions';
import { fetchMnpServicePenalty } from '../penalty/mnpPenaltyActions';
import {
  IFetchMnpServiceDetailBeginAction,
  IFetchMnpServiceDetailFailureAction,
  IFetchMnpServiceDetailSuccessAction,
  IFetchMnpServiceDetailSuccessActionPayload,
  ISelectMnpServiceDetailTypeAction,
  ISelectMnpServiceDetailTypeActionPayload,
} from './types/mnpServiceDetailActionInterfaces';
import MnpServiceDetailActionTypes from './types/mnpServiceDetailActionTypes';
import MnpServiceDetailTypeEnum from './types/MnpServiceDetailTypeEnum';

export const selectMnpServiceDetailType: ActionCreator<
  ISelectMnpServiceDetailTypeAction
> = (payload: ISelectMnpServiceDetailTypeActionPayload) => ({
  type: MnpServiceDetailActionTypes.SELECT_MNP_SERVICE_DETAIL_TYPE,
  payload,
});

export const fetchMnpServiceDetailBeginAction: ActionCreator<
  IFetchMnpServiceDetailBeginAction
> = () => ({
  type: MnpServiceDetailActionTypes.FETCH_MNP_SERVICE_DETAIL_BEGIN,
});

export const fetchMnpServiceDetailSuccessAction: ActionCreator<
  IFetchMnpServiceDetailSuccessAction
> = (payload: IFetchMnpServiceDetailSuccessActionPayload) => ({
  type: MnpServiceDetailActionTypes.FETCH_MNP_SERVICE_DETAIL_SUCCESS,
  payload,
});

export const fetchMnpServiceDetailFailureAction: ActionCreator<
  IFetchMnpServiceDetailFailureAction
> = () => ({
  type: MnpServiceDetailActionTypes.FETCH_MNP_SERVICE_DETAIL_FAILURE,
});

export const fetchMnpServiceDetail = (service: IService) => (dispatch) => {
  dispatch(loadServiceBegin(service));
  dispatch(fetchMnpServiceDetailBeginAction());
  getMnpServiceDetail()
    .then((response) => {
      dispatch(fetchMnpServiceDetailSuccessAction(response.data));
      dispatch(loadServiceSuccess(service));
    })
    .catch(() => {
      dispatch(fetchMnpServiceDetailFailureAction());
      dispatch(loadServiceFailure(service));
    });
};

export const openMnpServiceDetailCvopModal =
  (service: IService, serviceNumber: string) => (dispatch) => {
    dispatch(loadServiceBegin(service));
    dispatch(invokeMnpCvop(serviceNumber))
      .then(() => {
        dispatch(openModal({ type: ModalType.MnpCvop }));
        dispatch(loadServiceSuccess(service));
      })
      .catch(() => {
        dispatch(loadServiceFailure(service));
      });
  };

export const openMnpServiceDetailOkuModal =
  (service: IService, serviceNumber: string) => (dispatch) => {
    dispatch(loadServiceBegin(service));
    dispatch(fetchMnpServiceOku(serviceNumber))
      .then(() => {
        dispatch(openModal({ type: ModalType.MnpOku }));
        dispatch(loadServiceSuccess(service));
      })
      .catch(() => {
        dispatch(loadServiceFailure(service));
      });
  };

export const openMnpInternetServiceDetailOkuModal =
  (service: IService, serviceNumber: string) => (dispatch) => {
    dispatch(loadServiceBegin(service));
    dispatch(fetchMnpServiceOku(serviceNumber))
      .then(() => {
        dispatch(openModal({ type: ModalType.MnpInternetOku }));
        dispatch(loadServiceSuccess(service));
      })
      .catch(() => {
        dispatch(loadServiceFailure(service));
      });
  };

export const openMnpServiceDetailModal =
  (
    service: IService,
    serviceMnpType: MnpServiceDetailTypeEnum,
    serviceNumber: string,
  ) =>
  (dispatch) => {
    switch (serviceMnpType) {
      case MnpServiceDetailTypeEnum.OKU:
        return dispatch(openMnpServiceDetailOkuModal(service, serviceNumber));
      case MnpServiceDetailTypeEnum.CVOP:
        return dispatch(openMnpServiceDetailCvopModal(service, serviceNumber));
      default:
        throw new Error(`Modal type: ${serviceMnpType} is not implemented.`);
    }
  };

export const openSmsVerificationModal =
  (service: IService, serviceNumber: string) => (dispatch) => {
    dispatch(loadServiceBegin(service));
    dispatch(requestValidationSmsRequest(serviceNumber))
      .then(() => {
        dispatch(openModal({ type: ModalType.SmsVerification }));
        dispatch(loadServiceSuccess(service));
      })
      .catch(() => {
        dispatch(loadServiceFailure(service));
      });
  };

export const openMnpServiceDetailPenaltyModal =
  (
    service: IService,
    serviceMnpType: MnpServiceDetailTypeEnum,
    serviceNumber: string,
    skipVerificationSms: boolean,
  ) =>
  (dispatch) => {
    let skipVerification = false;

    const handleFinishMnpServicePenalty = () => {
      if (skipVerification) {
        dispatch(
          openMnpServiceDetailModal(service, serviceMnpType, serviceNumber),
        );
      } else {
        dispatch(openSmsVerificationModal(service, serviceNumber));
      }
      dispatch(loadServiceSuccess(service));
    };

    dispatch(loadServiceBegin(service));
    dispatch(fetchAuthType(serviceNumber))
      .then((response) => {
        skipVerification =
          skipVerificationSms ||
          response.authType === ExitServicesAuthTypeEnum.NONE;
      })
      .then(() => {
        dispatch(
          fetchMnpServicePenalty(
            serviceNumber,
            MnpServicePenaltyInteractionPointEnum.MOBILE,
          ),
        )
          .then((response) => {
            if (!response.showPenalty) {
              handleFinishMnpServicePenalty();
            } else {
              dispatch(openModal({ type: ModalType.MnpPenalty }));
              dispatch(loadServiceSuccess(service));
            }
          })
          .catch(() => {
            handleFinishMnpServicePenalty();
          });
      })
      .catch(() => {
        dispatch(loadServiceFailure(service));
      });
  };

export const openMnpInternetServiceDetailPenaltyModal =
  (service: IService, serviceNumber: string) => (dispatch) => {
    const handleFinishMnpServicePenalty = () => {
      dispatch(openMnpInternetServiceDetailOkuModal(service, serviceNumber));

      dispatch(loadServiceSuccess(service));
    };

    dispatch(loadServiceBegin(service));
    dispatch(
      fetchMnpServicePenalty(
        serviceNumber,
        MnpServicePenaltyInteractionPointEnum.FIXED_INTERNET,
      ),
    )
      .then((response) => {
        if (!response.showPenalty) {
          handleFinishMnpServicePenalty();
        } else {
          dispatch(openModal({ type: ModalType.MnpInternetPenalty }));
          dispatch(loadServiceSuccess(service));
        }
      })
      .catch(() => {
        handleFinishMnpServicePenalty();
      });
  };
