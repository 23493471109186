import React, { FunctionComponent, LazyExoticComponent } from 'react';
import { matchPath } from 'react-router';
import { IDAAS_URL_PARAMETERS } from 'src/utils/router/router.consts';
import { LanguagesEnum } from '../types/translation/languagesEnum';
import { BreadcrumbsGroupServices } from './breadcrumbs';

export enum MenuIdEnum {
  Dashboard = 'menu.homepage',
  Billing = 'menu.billing',
  Usage = 'menu.smart_overview',
  Services = 'menu.service_settings',
  EshopSpecialOffers = 'menu.eshop.title',
}

export interface IRoute {
  id: RoutesEnum;
  faqParameter?: string;
  analytics: {
    cs: string;
    en: string;
  };
  path: {
    cs: string;
    en: string;
  };
  protected: boolean;
  breadcrumbs?: string[];
  component: LazyExoticComponent<FunctionComponent<any>>;
  menuId?: MenuIdEnum;
}

export const ROUTE_APP_PREFIX = '/muj';
export const ROUTE_EN_PREFIX = '/en';

export enum RoutesEnum {
  ACTIVE_SERVICES_ROUTE_ID = 'wsc_service_active',
  BILLING_ROUTE_ID = 'wsc_billing',
  DASHBOARD_ROUTE_ID = 'wsc_dashboard',
  EMAIL_RESUBSCRIBE_ID = 'wsc_email_resubscribe',
  EMAIL_UNSUBSCRIBE_ID = 'wsc_email_unsubscribe',
  EMAIL_UNSUBSCRIBE_ID_NPS = 'wsc_email_unsubscribe_nps',
  FORGOT_PASSWORD_ID = 'wsc_forgot_password',
  FORGOT_PASSWORD_ID_NEW = 'wsc_forgot_password_new',
  GENERIC_MICROSITES = 'generic_microsites',
  GENERIC_MICROSITES_AFTER_LOGIN = 'generic_microsites_after_login',
  LOGIN_ROUTE_ID = 'wsc_login',
  LOGOUT_SUCCESS_ROUTE_ID = 'wsc_logout_success',
  ONE_TIME_PASSWORD = 'wsc_one_time_password',
  PASSWORD_ID = 'wsc_password',
  PAYER_LOGIN = 'wsc_payer_login',
  PERSONAL_ACCOUNT_PROFILE_ROUTE_ID = 'wsc_personal_account_profil',
  PERSONAL_ACCOUNT_REGISTRATION = 'wsc_personal_account_registration',
  PERSONAL_ACCOUNT_REGISTRATION_CONFIRMATION = 'wsc_personal_account_registration_confirmation',
  PERSONAL_ACCOUNT_REQUEST_INVITE = 'wsc_personal_account_request_invite_code',
  PERSONAL_ACCOUNT_MERGE = 'wsc_personal_account_merge',
  PERSONAL_ACCOUNT_ADD_ACCOUNT = 'wsc_personal_account_add_account',
  PERSONAL_ACCOUNT_GRANT_ACCESS = 'wsc_personal_account_grant_access',
  PREPAID_VAT_BILLS_ROUTE_ID = 'wsc_prepaid_vat_bills',
  RENEW_PASSWORD_ID = 'wsc_renew_password',
  SERVICES_SUBSCRIPTIONS_OVERVIEW = 'wsc_service_active_overview',
  SUCCESSFUL_PAYMENT = 'wsc_successful_payment',
  UNSUCCESSFUL_PAYMENT = 'wsc_unsuccessful_payment',
  USAGE_ROUTE_ID = 'wsc_usage',
  WSC_CUSTOMER_DOCUMENT_LIST = 'wsc_customer_request_document_list',
  WSC_ESHOP_SPECIAL_OFFERS = 'wsc_eshop_special_offers',
  WSC_DIGI_OPCO = 'wsc_digi_opco_info',
  VODAFONE_ID_LOGIN = 'vodafone_id_login',
  VODAFONE_ID_PASSWORD = 'vodafone_id_password',
  VODAFONE_ID_EXPLANATION = 'vodafone_id_explanation',
  VODAFONE_ID_CONFIRM = 'vodafone_id_confirm',
  PERSONAL_ACCOUNT_ACCEPT_INVITATION = 'wsc_personal_account_accept_invitation',
  PERSONAL_ACCOUNT_ACCEPT_INVITATION_OLD = 'wsc_personal_account_accept_invitation_old',
  PERSONAL_ACCOUNT_ACCEPT_INVITATION_MSISDN = 'wsc_personal_account_accept_invitation_msisdn',
  MODEM_CHECK = 'modem_check',
  SIEBEL_LOGIN = 'siebel_login',
}

const routes: IRoute[] = [
  {
    id: RoutesEnum.LOGIN_ROUTE_ID,
    analytics: {
      cs: 'WSC:Prihlaseni',
      en: 'WSC:Login',
    },
    path: {
      cs: `${ROUTE_APP_PREFIX}/prihlaseni`,
      en: `${ROUTE_APP_PREFIX}${ROUTE_EN_PREFIX}/login`,
    },
    protected: false,
    breadcrumbs: [],
    component: React.lazy(() => import('../pages/LoginPage')),
  },
  {
    id: RoutesEnum.SIEBEL_LOGIN,
    analytics: {
      cs: 'WSC:Siebel login',
      en: 'WSC:Siebel login',
    },
    path: {
      cs: `${ROUTE_APP_PREFIX}/siebel/login`,
      en: `${ROUTE_APP_PREFIX}${ROUTE_EN_PREFIX}/siebel/login`,
    },
    protected: false,
    breadcrumbs: [],
    component: React.lazy(() => import('../pages/SiebelLoginPage')),
  },
  {
    id: RoutesEnum.FORGOT_PASSWORD_ID,
    analytics: {
      cs: 'WSC:Zapomenuté heslo',
      en: 'WSC:Forgot password',
    },
    path: {
      cs: `${ROUTE_APP_PREFIX}/prihlaseni/zapomenute-heslo`,
      en: `${ROUTE_APP_PREFIX}${ROUTE_EN_PREFIX}/login/forgotten-password`,
    },
    protected: false,
    breadcrumbs: [],
    component: React.lazy(() => import('../pages/ForgotPasswordSwitcher')),
  },
  {
    id: RoutesEnum.ONE_TIME_PASSWORD,
    analytics: {
      cs: 'WSC:Jednorázové přihlášení',
      en: 'WSC:One time login',
    },
    path: {
      cs: `${ROUTE_APP_PREFIX}/prihlaseni/jednorazove-prihlaseni`,
      en: `${ROUTE_APP_PREFIX}${ROUTE_EN_PREFIX}/login/onetime-login`,
    },
    protected: false,
    breadcrumbs: [],
    component: React.lazy(() => import('../pages/OneTimePassword')),
  },
  {
    id: RoutesEnum.PERSONAL_ACCOUNT_REQUEST_INVITE,
    analytics: {
      cs: 'WSC:Osobní účet požádat o pozvání',
      en: 'WSC:Personal account Request Invite',
    },
    path: {
      cs: `${ROUTE_APP_PREFIX}/profil/registrace`,
      en: `${ROUTE_APP_PREFIX}${ROUTE_EN_PREFIX}/profile/registration`,
    },
    protected: false,
    breadcrumbs: [],
    component: React.lazy(
      () => import('../pages/PersonalAccount/Registration'),
    ),
  },
  {
    id: RoutesEnum.PERSONAL_ACCOUNT_ADD_ACCOUNT,
    analytics: {
      cs: 'WSC:Připojení účtu/služby',
      en: 'WSC:Connection of account/service',
    },
    path: {
      cs: `${ROUTE_APP_PREFIX}/profil/pripojeni-uctu`,
      en: `${ROUTE_APP_PREFIX}${ROUTE_EN_PREFIX}/profile/add-account`,
    },
    protected: true,
    breadcrumbs: [],
    component: React.lazy(() => import('../pages/PersonalAccount/AddAccount')),
  },
  {
    id: RoutesEnum.PERSONAL_ACCOUNT_GRANT_ACCESS,
    analytics: {
      cs: 'WSC:Sdílení přístupu/služby',
      en: 'WSC:Connection of account/service',
    },
    path: {
      cs: `${ROUTE_APP_PREFIX}/profil/sdileni-pristupu`,
      en: `${ROUTE_APP_PREFIX}${ROUTE_EN_PREFIX}/profile/share-access`,
    },
    protected: true,
    breadcrumbs: [],
    component: React.lazy(() => import('../pages/VodafoneID/GrantAccess')),
  },
  {
    id: RoutesEnum.PERSONAL_ACCOUNT_REGISTRATION,
    analytics: {
      cs: 'WSC:Osobní účet Registrace',
      en: 'WSC:Personal account Registeration',
    },
    path: {
      cs: `${ROUTE_APP_PREFIX}/registrace/:source?/:code?/:email?`,
      en: `${ROUTE_APP_PREFIX}${ROUTE_EN_PREFIX}/registration/:source?/:code?/:email?`,
    },
    protected: false,
    breadcrumbs: [],
    component: React.lazy(
      () => import('../pages/PersonalAccount/Registration'),
    ),
  },
  {
    id: RoutesEnum.PERSONAL_ACCOUNT_REGISTRATION_CONFIRMATION,
    analytics: {
      cs: 'WSC:Osobní účet Registrace Potvrzení',
      en: 'WSC:Personal account Registeration Confirmation',
    },
    path: {
      cs: `${ROUTE_APP_PREFIX}/registrace-potvrzeni/:token?`,
      en: `${ROUTE_APP_PREFIX}${ROUTE_EN_PREFIX}/registration-confirmation/:token?`,
    },
    protected: false,
    breadcrumbs: [],
    component: React.lazy(
      () => import('../pages/PersonalAccount/Registration'),
    ),
  },
  {
    id: RoutesEnum.PERSONAL_ACCOUNT_MERGE,
    analytics: {
      cs: 'WSC:Osobní účet Registrace:Propojení účtů',
      en: 'WSC:Osobní účet Registrace:Propojení účtů',
    },
    path: {
      cs: `${ROUTE_APP_PREFIX}/registrace-propojeni-uctu`,
      en: `${ROUTE_APP_PREFIX}${ROUTE_EN_PREFIX}/registration-merge-accounts`,
    },
    protected: false,
    breadcrumbs: [],
    component: React.lazy(() => import('../pages/AccountsMerge/AccountsMerge')),
  },
  {
    id: RoutesEnum.RENEW_PASSWORD_ID,
    analytics: {
      cs: 'WSC:Zapomenuté heslo',
      en: 'WSC:Forgot password',
    },
    path: {
      cs: `${ROUTE_APP_PREFIX}/obnova-hesla/:token?`,
      en: `${ROUTE_APP_PREFIX}${ROUTE_EN_PREFIX}/password-forgot/:token?`,
    },
    protected: false,
    breadcrumbs: [],
    component: React.lazy(() => import('../pages/ForgotPassword')),
  },
  {
    id: RoutesEnum.PAYER_LOGIN,
    analytics: {
      cs: 'WSC:Přihlášení plátcem',
      en: 'WSC:Payer login',
    },
    path: {
      cs: `${ROUTE_APP_PREFIX}/prihlaseni/platci`,
      en: `${ROUTE_APP_PREFIX}${ROUTE_EN_PREFIX}/prihlaseni/platci`,
    },
    protected: false,
    breadcrumbs: [],
    component: React.lazy(() => import('../pages/PayerLogin')),
  },
  {
    id: RoutesEnum.PASSWORD_ID,
    analytics: {
      cs: 'WSC:Zapomenuté heslo',
      en: 'WSC:Forgot password',
    },
    path: {
      cs: `${ROUTE_APP_PREFIX}/obnova/:serviceNumber?/:question?`,
      en: `${ROUTE_APP_PREFIX}${ROUTE_EN_PREFIX}/password/:serviceNumber?/:question?`,
    },
    protected: false,
    breadcrumbs: [],
    component: React.lazy(() => import('../pages/ForgotPasswordSwitcher')),
  },
  {
    id: RoutesEnum.ACTIVE_SERVICES_ROUTE_ID,
    analytics: {
      cs: 'WSC:Services:Subscriptions:Detail',
      en: 'WSC:Services:Subscriptions:Detail',
    },
    path: {
      cs: `${ROUTE_APP_PREFIX}/sluzby`,
      en: `${ROUTE_APP_PREFIX}${ROUTE_EN_PREFIX}/services`,
    },
    breadcrumbs: BreadcrumbsGroupServices,
    protected: true,
    component: React.lazy(() => import('../pages/ServicesPage')),
    menuId: MenuIdEnum.Services,
  },
  {
    id: RoutesEnum.LOGOUT_SUCCESS_ROUTE_ID,
    analytics: {
      cs: 'WSC:Uspesne odhlaseni',
      en: 'WSC:Logout successful',
    },
    path: {
      cs: `${ROUTE_APP_PREFIX}/odhlaseni-uspesne`,
      en: `${ROUTE_APP_PREFIX}${ROUTE_EN_PREFIX}/logout-success`,
    },
    protected: false,
    component: React.lazy(() => import('../pages/LogoutPage')),
  },
  {
    id: RoutesEnum.PERSONAL_ACCOUNT_PROFILE_ROUTE_ID,
    analytics: {
      cs: 'WSC:PA:Profil',
      en: 'WSC:PA:Profile',
    },
    path: {
      cs: `${ROUTE_APP_PREFIX}/profil`,
      en: `${ROUTE_APP_PREFIX}${ROUTE_EN_PREFIX}/profile`,
    },
    protected: true,
    breadcrumbs: [],
    component: React.lazy(() => import('../pages/PersonalAccount/ProfilePage')),
  },
  {
    id: RoutesEnum.DASHBOARD_ROUTE_ID,
    analytics: {
      cs: 'WSC:Dashboard',
      en: 'WSC:Dashboard',
    },
    path: {
      cs: `${ROUTE_APP_PREFIX}`,
      en: `${ROUTE_APP_PREFIX}${ROUTE_EN_PREFIX}`,
    },
    protected: true,
    breadcrumbs: [],
    component: React.lazy(() => import('../pages/Dashboard/DashboardPage')),
    menuId: MenuIdEnum.Dashboard,
  },
  {
    id: RoutesEnum.SUCCESSFUL_PAYMENT,
    analytics: {
      cs: 'WSC:Dashboard',
      en: 'WSC:Dashboard',
    },
    path: {
      cs: `${ROUTE_APP_PREFIX}/online-platba/(uspesna-platba-faktury|uspesne-dobiti|uspesne-chytre-dobiti)/:paymentStatus/:paymentId/:sourceMsisdn/:targetMsisdn`,
      en: `${ROUTE_APP_PREFIX}${ROUTE_EN_PREFIX}/online-payment/(invoice-payment-successful|recharge-successful|atu-successful)/:paymentStatus/:paymentId/:sourceMsisdn/:targetMsisdn`,
    },
    protected: true,
    breadcrumbs: [],
    component: React.lazy(() => import('../pages/Dashboard/DashboardPage')),
    menuId: MenuIdEnum.Dashboard,
  },
  {
    id: RoutesEnum.UNSUCCESSFUL_PAYMENT,
    analytics: {
      cs: 'WSC:Dashboard',
      en: 'WSC:Dashboard',
    },
    path: {
      cs: `${ROUTE_APP_PREFIX}/online-platba/(neuspesna-platba-faktury|neuspesne-dobiti|neuspesne-chytre-dobiti)/:paymentStatus/:paymentId/:sourceMsisdn/:targetMsisdn`,
      en: `${ROUTE_APP_PREFIX}${ROUTE_EN_PREFIX}/online-payment/(invoice-payment-unsuccessful|recharge-unsuccessful|atu-unsuccessful)/:paymentStatus/:paymentId/:sourceMsisdn/:targetMsisdn`,
    },
    protected: true,
    breadcrumbs: [],
    component: React.lazy(() => import('../pages/Dashboard/DashboardPage')),
    menuId: MenuIdEnum.Dashboard,
  },
  {
    id: RoutesEnum.EMAIL_RESUBSCRIBE_ID,
    analytics: {
      cs: 'WSC:EmailZnovuPrihlaseni',
      en: 'WSC:EmailResubscribe',
    },
    path: {
      cs: `${ROUTE_APP_PREFIX}/email/prihlaseni`,
      en: `${ROUTE_APP_PREFIX}${ROUTE_EN_PREFIX}/email/resubscribe`,
    },
    protected: false,
    breadcrumbs: [],
    component: React.lazy(() => import('../pages/Email/ResubscribePage')),
  },
  {
    id: RoutesEnum.EMAIL_UNSUBSCRIBE_ID,
    analytics: {
      cs: 'WSC:EmailOdhlaseni',
      en: 'WSC:EmailUnsubscribe',
    },
    path: {
      cs: `${ROUTE_APP_PREFIX}/email/odhlaseni`,
      en: `${ROUTE_APP_PREFIX}${ROUTE_EN_PREFIX}/email/unsubscribe`,
    },
    protected: false,
    breadcrumbs: [],
    component: React.lazy(() => import('../pages/Email/UnsubscribePage')),
  },
  {
    id: RoutesEnum.EMAIL_UNSUBSCRIBE_ID_NPS,
    analytics: {
      cs: 'WSC:EmailOdhlaseniNps',
      en: 'WSC:EmailUnsubscribeNps',
    },
    path: {
      cs: `${ROUTE_APP_PREFIX}/email/odhlaseni-nps`,
      en: `${ROUTE_APP_PREFIX}${ROUTE_EN_PREFIX}/email/unsubscribe-nps`,
    },
    protected: false,
    breadcrumbs: [],
    component: React.lazy(() => import('../pages/Email/UnsubscribePageNps')),
  },
  {
    id: RoutesEnum.GENERIC_MICROSITES,
    analytics: {
      cs: 'GenericMicrositePage',
      en: 'GenericMicrositePage',
    },
    path: {
      cs: `${ROUTE_APP_PREFIX}/gm/:module/:submodule?`,
      en: `${ROUTE_APP_PREFIX}${ROUTE_EN_PREFIX}/gm/:module/:submodule?`,
    },
    protected: false,
    breadcrumbs: [],
    component: React.lazy(
      () => import('../pages/GenericMicrosite/GenericMicrositePage'),
    ),
  },
  {
    id: RoutesEnum.GENERIC_MICROSITES_AFTER_LOGIN,
    analytics: {
      cs: 'GenericMicrositeAfterLoginPage',
      en: 'GenericMicrositeAfterLoginPage',
    },
    path: {
      cs: `${ROUTE_APP_PREFIX}/gm-login/:module/:submodule?`,
      en: `${ROUTE_APP_PREFIX}${ROUTE_EN_PREFIX}/gm-login/:module/:submodule?`,
    },
    protected: true,
    breadcrumbs: [],
    component: React.lazy(
      () => import('../pages/GenericMicrosite/AfterLoginPage'),
    ),
  },
  {
    id: RoutesEnum.BILLING_ROUTE_ID,
    analytics: {
      cs: 'WSC:Billing',
      en: 'WSC:Billing',
    },
    path: {
      cs: `${ROUTE_APP_PREFIX}/vyuctovani/:number?`,
      en: `${ROUTE_APP_PREFIX}${ROUTE_EN_PREFIX}/billing/:number?`,
    },
    protected: true,
    breadcrumbs: [],
    component: React.lazy(() => import('../pages/BillingPage')),
    menuId: MenuIdEnum.Billing,
  },
  {
    id: RoutesEnum.USAGE_ROUTE_ID,
    faqParameter: 'wsc_smart_overview',
    analytics: {
      cs: 'WSC:Usage', // TODO rename
      en: 'WSC:Usage',
    },
    path: {
      cs: `${ROUTE_APP_PREFIX}/cerpani/:serviceNumber?`,
      en: `${ROUTE_APP_PREFIX}${ROUTE_EN_PREFIX}/usage-overview/:serviceNumber?`,
    },
    protected: true,
    breadcrumbs: [],
    component: React.lazy(() => import('../pages/UsagePage')),
    menuId: MenuIdEnum.Usage,
  },
  {
    id: RoutesEnum.SERVICES_SUBSCRIPTIONS_OVERVIEW,
    analytics: {
      cs: 'WSC:Services:Subscriptions:Overview', // TODO rename
      en: 'WSC:Services:Subscriptions:Overview',
    },
    path: {
      cs: `${ROUTE_APP_PREFIX}/sluzby/prehled`,
      en: `${ROUTE_APP_PREFIX}${ROUTE_EN_PREFIX}/services/overview`,
    },
    protected: true,
    breadcrumbs: [],
    menuId: MenuIdEnum.Services,
    component: React.lazy(
      () => import('../pages/ServicesSubscriptionsOverviewPage'),
    ),
  },
  {
    id: RoutesEnum.WSC_ESHOP_SPECIAL_OFFERS,
    analytics: {
      cs: 'WSC:eShop:Specialni nabidky',
      en: 'WSC:Eshop:Special Offers',
    },
    path: {
      cs: `${ROUTE_APP_PREFIX}/eshop/specialni-nabidky`,
      en: `${ROUTE_APP_PREFIX}${ROUTE_EN_PREFIX}/special-offers`,
    },
    protected: true,
    menuId: MenuIdEnum.EshopSpecialOffers,
    component: React.lazy(
      () => import('../pages/Eshop/EshopSpecialOffersPage'),
    ),
  },
  {
    id: RoutesEnum.PREPAID_VAT_BILLS_ROUTE_ID,
    analytics: {
      cs: 'WSC:Utrata a Vyuctovani:Vyuctovani',
      en: 'WSC:Utrata a Vyuctovani:Vyuctovani',
    },
    path: {
      cs: `${ROUTE_APP_PREFIX}/vyuctovani-prepaid/:number?`,
      en: `${ROUTE_APP_PREFIX}${ROUTE_EN_PREFIX}/prepaid_vat_bills/:number?`,
    },
    protected: true,
    component: null,
  },
  {
    id: RoutesEnum.WSC_CUSTOMER_DOCUMENT_LIST,
    analytics: {
      cs: 'WSC:Ucet:Vase dokumenty',
      en: 'WSC:Ucet:Vase dokumenty',
    },
    path: {
      cs: `${ROUTE_APP_PREFIX}/dokumenty/seznam-dokumentu`,
      en: `${ROUTE_APP_PREFIX}${ROUTE_EN_PREFIX}/customer-request/document-list`,
    },
    protected: true,
    component: React.lazy(() => import('../pages/Userdoc/UserdocPage')),
  },
  {
    id: RoutesEnum.WSC_DIGI_OPCO,
    analytics: {
      cs: 'WSC:Vodafone One',
      en: 'WSC:Vodafone One',
    },
    path: {
      cs: `${ROUTE_APP_PREFIX}/vodafone-one`,
      en: `${ROUTE_APP_PREFIX}${ROUTE_EN_PREFIX}/vodafone-one`,
    },
    protected: false,
    component: React.lazy(() => import('../pages/VodafoneOneInfoPage')),
  },
  {
    id: RoutesEnum.VODAFONE_ID_LOGIN,
    analytics: {
      cs: 'WSC:Prihlaseni',
      en: 'WSC:Login',
    },
    path: {
      cs: `${ROUTE_APP_PREFIX}/prihlaseni/info`,
      en: `${ROUTE_APP_PREFIX}${ROUTE_EN_PREFIX}/login/info`,
    },
    protected: false,
    breadcrumbs: [],
    component: React.lazy(() =>
      import('../pages/VodafoneID').then((module) => ({
        default: module.LoginInfo,
      })),
    ),
  },
  {
    id: RoutesEnum.VODAFONE_ID_PASSWORD,
    analytics: {
      cs: 'WSC:Prihlaseni',
      en: 'WSC:Login',
    },
    path: {
      cs: `${ROUTE_APP_PREFIX}/prihlaseni/heslo`,
      en: `${ROUTE_APP_PREFIX}${ROUTE_EN_PREFIX}/login/password`,
    },
    protected: false,
    component: React.lazy(() =>
      import('../pages/VodafoneID').then((module) => ({
        default: module.LoginPassword,
      })),
    ),
  },
  {
    id: RoutesEnum.VODAFONE_ID_EXPLANATION,
    analytics: {
      cs: 'WSC:Prihlaseni',
      en: 'WSC:Login',
    },
    path: {
      cs: `${ROUTE_APP_PREFIX}/prihlaseni/vysvetleni`,
      en: `${ROUTE_APP_PREFIX}${ROUTE_EN_PREFIX}/login/explanation`,
    },
    protected: false,
    component: React.lazy(() =>
      import('../pages/VodafoneID').then((module) => ({
        default: module.LoginExplanation,
      })),
    ),
  },
  {
    id: RoutesEnum.VODAFONE_ID_CONFIRM,
    analytics: {
      cs: 'WSC:Prihlaseni',
      en: 'WSC:Login',
    },
    path: {
      cs: `${ROUTE_APP_PREFIX}/prihlaseni/potvrzeni`,
      en: `${ROUTE_APP_PREFIX}${ROUTE_EN_PREFIX}/login/confirmation`,
    },
    protected: false,
    component: React.lazy(() =>
      import('../pages/VodafoneID').then((module) => ({
        default: module.LoginConfirmation,
      })),
    ),
  },
  {
    id: RoutesEnum.PERSONAL_ACCOUNT_ACCEPT_INVITATION,
    analytics: {
      cs: 'WSC:Prijmuti pozvanky',
      en: 'WSC:Accept invitation',
    },
    path: {
      cs: `${ROUTE_APP_PREFIX}/prijmuti-pozvanky/:code/email/:email`,
      en: `${ROUTE_APP_PREFIX}${ROUTE_EN_PREFIX}/accept-invitation/:code/email/:email`,
    },
    protected: false,
    component: React.lazy(() =>
      import('../pages/PersonalAccount/InvitationPage').then((module) => ({
        default: module.AcceptInvitationPage,
      })),
    ),
  },
  {
    id: RoutesEnum.PERSONAL_ACCOUNT_ACCEPT_INVITATION_OLD,
    analytics: {
      cs: 'WSC:Prijmuti pozvanky',
      en: 'WSC:Accept invitation',
    },
    path: {
      cs: `${ROUTE_APP_PREFIX}/prijmuti-pozvanky/:code`,
      en: `${ROUTE_APP_PREFIX}${ROUTE_EN_PREFIX}/accept-invitation/:code`,
    },
    protected: false,
    component: React.lazy(() =>
      import('../pages/PersonalAccount/InvitationPage').then((module) => ({
        default: module.AcceptInvitationPage,
      })),
    ),
  },
  {
    id: RoutesEnum.PERSONAL_ACCOUNT_ACCEPT_INVITATION_MSISDN,
    analytics: {
      cs: 'WSC:Prijmuti pozvanky',
      en: 'WSC:Accept invitation',
    },
    path: {
      cs: `${ROUTE_APP_PREFIX}/prijmuti-pozvanky/:code/msisdn/:msisdn`,
      en: `${ROUTE_APP_PREFIX}${ROUTE_EN_PREFIX}/accept-invitation/:code/msisdn/:msisdn`,
    },
    protected: false,
    component: React.lazy(() =>
      import('../pages/PersonalAccount/InvitationPage').then((module) => ({
        default: module.AcceptInvitationPage,
      })),
    ),
  },
  {
    id: RoutesEnum.MODEM_CHECK,
    analytics: {
      cs: 'WSC:Kontrola modemu',
      en: 'WSC:Modem check',
    },
    path: {
      cs: `${ROUTE_APP_PREFIX}/sluzby/kontrola-modemu/:serviceNumber?`,
      en: `${ROUTE_APP_PREFIX}${ROUTE_EN_PREFIX}/services/modem-check/:serviceNumber?`,
    },
    protected: true,
    component: React.lazy(() =>
      import('../pages/Services/ModemCheck').then((module) => ({
        default: module.ModemCheck,
      })),
    ),
  },
];

export const loginRoutes: string[] = [
  RoutesEnum.LOGIN_ROUTE_ID,
  RoutesEnum.PERSONAL_ACCOUNT_REGISTRATION_CONFIRMATION,
  RoutesEnum.ONE_TIME_PASSWORD,
  RoutesEnum.PASSWORD_ID,
  RoutesEnum.PAYER_LOGIN,
  RoutesEnum.ONE_TIME_PASSWORD,
];

export const PERSIST_URL_PARAMETERS = [IDAAS_URL_PARAMETERS.REDIRECT_URI];

export const getRoute = (path: string): IRoute | undefined => {
  const fixedPath = path.slice(-1) === '/' ? path.slice(0, -1) : path;
  const { pathname } = new URL(fixedPath, window.location.origin);

  return routes.find(
    (route) =>
      matchPath(path, { path: route.path.cs, exact: true, strict: false }) ||
      matchPath(path, { path: route.path.en, exact: true, strict: false }) ||
      route.path.cs === pathname ||
      route.path.en === pathname ||
      route.path.cs === path ||
      route.path.en === path,
  );
};

interface IParam {
  [name: string]: string;
}

export const buildURLQuery = (params: object) =>
  Object.entries(params)
    .map((pair) => pair.map(encodeURIComponent).join('='))
    .join('&');

export const preparePersistUrlParameters = (
  persistUrlParams = [],
): AnyObject => {
  const parameters: AnyObject = {};
  persistUrlParams.map((persistUrlParameter) => {
    const urlParams = new URLSearchParams(window.location.search);
    const urlParamValue = urlParams.get(persistUrlParameter);
    if (urlParamValue) {
      parameters[persistUrlParameter] = urlParamValue;
    }
  });

  return parameters;
};

export const getPathByRouteId = (
  routeId: RoutesEnum,
  language: LanguagesEnum,
  params: IParam = null,
  hash: string = null,
  persistUrlParams = PERSIST_URL_PARAMETERS,
) => {
  const foundRoute = routes.find((route) => route.id === routeId);
  let translatedRoute =
    language === LanguagesEnum.CS ? foundRoute.path.cs : foundRoute.path.en;

  const paramsIsNotInRoute = {};

  if (params) {
    Object.keys(params).forEach((key: string) => {
      let value = params[key];

      if (
        translatedRoute.indexOf(`:${key}`) === -1 &&
        typeof value === 'undefined'
      ) {
        return;
      }

      if (translatedRoute.indexOf(`:${key}`) === -1) {
        paramsIsNotInRoute[key] = value;
      }

      if (typeof value === 'undefined' || value === '' || value === null) {
        value = '';
      }
      translatedRoute = translatedRoute
        .replace(`:${key}?`, value)
        .replace(`:${key}`, value);
    });
  }

  const re = /\/:(.*?)\?/gi;

  translatedRoute = translatedRoute.replace(re, '');
  if (translatedRoute.substr(translatedRoute.length - 1) === '/') {
    translatedRoute = translatedRoute.slice(0, -1);
  }

  const paramsWithPersists = {
    ...paramsIsNotInRoute,
    ...preparePersistUrlParameters(persistUrlParams),
  };

  if (Object.keys(paramsWithPersists).length) {
    return `${hash ? `${translatedRoute}#${hash}` : translatedRoute}${
      translatedRoute.includes('?') ? '&' : '?'
    }${buildURLQuery(paramsWithPersists)}`;
  }
  return `${hash ? `${translatedRoute}#${hash}` : translatedRoute}`;
};

export const isLoginRoute = () =>
  [
    RoutesEnum.LOGIN_ROUTE_ID,
    RoutesEnum.FORGOT_PASSWORD_ID,
    RoutesEnum.VODAFONE_ID_CONFIRM,
    RoutesEnum.ONE_TIME_PASSWORD,
    RoutesEnum.VODAFONE_ID_LOGIN,
    RoutesEnum.VODAFONE_ID_EXPLANATION,
    RoutesEnum.VODAFONE_ID_PASSWORD,
    RoutesEnum.PASSWORD_ID,
  ].includes(getRoute(window.location.pathname)?.id);

export const isCurrentRouteBlacklistedByConfig = (
  blacklist: RoutesEnum[],
): boolean =>
  !!blacklist.filter(
    (disabledRoute) => getRoute(window.location.pathname)?.id === disabledRoute,
  ).length;

export default routes;
