import { Button, Loader, StatusBox, STATUS_COLORS } from '@vodafone/red-white';
import dateFormat from 'dateformat';
import { FC, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'src/hooks/useAppDispatch';
import selectLanguage from 'src/store/language/languageSelector';
import ISubscription from 'src/store/user/types/subscriptionInterface';
import { selectCurrentSubscription } from 'src/store/user/userSelector';
import { ISelectVtvActiveDeviceToRemove } from 'src/store/vtvServices/types/vtvServicesSelectorInterfaces';
import { removeVtvActiveDeviceInModal } from 'src/store/vtvServices/vtvServicesActions';
import { selectVtvActiveDeviceToRemove } from 'src/store/vtvServices/vtvServicesSelectors';
import { LanguagesEnum } from 'src/types/translation/languagesEnum';
import getTranslation from 'src/utils/translation/getTranslation';
import { DATE_FORMAT } from '../../../config/config';
import { ModalContentProps } from '../types/modalContentInterfaces';

const VtvRemoveActiveDeviceModalContent: FC<ModalContentProps> = ({
  setTitle,
  setButton,
  onClose,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['services', 'component', 'error']);
  const language: LanguagesEnum = useSelector(selectLanguage);

  const {
    udid,
    loading,
    loaded,
    error,
    device,
  }: ISelectVtvActiveDeviceToRemove = useSelector(
    selectVtvActiveDeviceToRemove,
  );
  const { serviceNumber }: ISubscription = useSelector(
    selectCurrentSubscription,
  );

  useEffect(() => {
    setTitle(t('services:vtv.remove-device-title'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    const onSubmit = () =>
      dispatch(removeVtvActiveDeviceInModal(serviceNumber, udid));

    const button = loaded ? (
      <Button onClick={onClose} variant="primary">
        {t('component:button.close')}
      </Button>
    ) : (
      <Button onClick={onSubmit} variant="primary">
        {t('component:button.remove')}
      </Button>
    );

    setButton(button);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceNumber, udid, loaded]);

  return (
    <>
      <Loader show={loading} />
      {loaded && (
        <StatusBox color={STATUS_COLORS.green}>
          {t('services:vtv.remove-device-success-message')}
        </StatusBox>
      )}
      {error && (
        <StatusBox color={STATUS_COLORS.red}>
          {t('error:api-request')}
        </StatusBox>
      )}
      {!loaded && (
        <p>
          <Trans i18nKey="services:vtv.remove-device-description">
            {{ name: getTranslation(device.name, language) }}
            {{ date: dateFormat(device.activatedOn, DATE_FORMAT) }}
          </Trans>
        </p>
      )}
    </>
  );
};

export default VtvRemoveActiveDeviceModalContent;
