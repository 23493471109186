import BillingDetailActionTypes from 'src/store/billing/billingDetail/types/billingDetailActionTypes';
import { ServiceActionTypes } from 'src/store/services/types/service/serviceActionTypes';
import { AnalyticsEnum } from './enums/analyticsEnum';
import { resolveServiceName, setNextAction } from './utils/services';

export const analyticsEvents = {
  [ServiceActionTypes.FETCH_SERVICES_DETAIL_SUCCESS]: (action) => {
    const nextAction = setNextAction(action.service);
    const serviceName = resolveServiceName(action.service);

    return {
      products: AnalyticsEnum.CHANNEL_WSC + nextAction + serviceName,
      events: AnalyticsEnum.PRODUCT_VIEW,
    };
  },
  [ServiceActionTypes.GENERIC_ANALYTICS_ACTION]: (action) => {
    return {
      products: action.products,
      events: action.events,
      pageName: action.pageName,
      list1: action.list1,
    };
  },
  [BillingDetailActionTypes.COLLAPSE_NEXT_BILLING_BOX]: () => {
    return {
      prop49: `popup:bill-breakdown:nextBilling`,
    };
  },
  [BillingDetailActionTypes.COLLAPSE_BILLING_HISTORY_BOX]: (action) => {
    return {
      prop49: `popup:bill-breakdown:-${action.index}`,
    };
  },
  [BillingDetailActionTypes.DOWNLOAD_INVOICE]: (action) => {
    return {
      prop49: `popup:bill-download:-${action.index}:${action.invoiceFormat}`,
    };
  },
};
