import {
  Button,
  DescriptionList,
  DescriptionListItem,
  Link,
  Title,
} from '@vodafone/red-white';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ESimActivationMethods } from 'src/components/Modal/components/ESim/enums/ESimModalEnum';
import { useAppDispatch } from 'src/hooks/useAppDispatch';
import { eSimDeliveryResetStore } from 'src/store/esim/esimActions';
import { selectESimDetail } from 'src/store/esim/esimSelectors';
import { IESimDetailState } from 'src/store/esim/types/esimStateInterface';
import { openModal } from 'src/store/modal/modalActions';
import ModalType from 'src/store/modal/types/ModalType';
import SanitizedHtml from 'src/utils/sanitizeHtml';
import styled from 'styled-components';

const LinkText = styled(Link)`
  font-size: 16px;
  color: ${(props) => props.theme.colors.darkGrey};
  display: inline-block;
  padding: 0 15px;
`;

const DescriptionListWrapper = styled(`div`)`
  margin-top: 20px;
`;

const EidWrapper = styled(`div`)`
  margin: 0;
  border-left: 5px solid ${(props) => props.theme.colors.red};
  background: #ededed;
  padding: 10px 10px 10px 20px;
  border-radius: 5px;
`;

interface IProps {
  serviceNumber: string;
  onClose: () => void;
}

const PreactivatedESimByEIdContent: FC<IProps> = ({
  serviceNumber,
  onClose,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['esim']);

  const eSimDetail: IESimDetailState = useSelector(selectESimDetail);

  const reopenOrder = (type: ESimActivationMethods) => {
    onClose();
    dispatch(eSimDeliveryResetStore());

    dispatch(
      openModal({
        type: ModalType.OrderNewESim,
        options: {
          serviceNumber,
          defaultMethod: type,
          changeCurrentActivationMethod: true,
        },
      }),
    );
  };

  return (
    <>
      <Title
        color="darkGrey"
        fontFamily="VodafoneRg"
        fontWeight="bold"
        fontSize={26}
      >
        {t('esim:preactivatedESim:by-eid.sub-title')}
      </Title>

      <DescriptionListWrapper>
        <DescriptionList>
          <DescriptionListItem title={t('esim:preactivatedESim:by-eid:step-1')}>
            <p>&nbsp;</p>
          </DescriptionListItem>
          <DescriptionListItem title={t('esim:preactivatedESim:by-eid:step-2')}>
            <p>&nbsp;</p>
          </DescriptionListItem>
        </DescriptionList>
      </DescriptionListWrapper>

      <EidWrapper>
        <strong>
          {t('esim:preactivatedESim:by-eid.eid', {
            eid: eSimDetail.data.eId,
          })}
        </strong>
        <br />
        {t('esim:preactivatedESim:by-eid.iccid', {
          iccid: eSimDetail.data.iccid,
        })}
      </EidWrapper>

      <p>
        <SanitizedHtml
          htmlTag="div"
          style={{ marginTop: 15, marginBottom: 15 }}
          rawHtmlData={t('esim:preactivatedESim:by-eid:help-text')}
        />
      </p>

      <div>
        <Button
          htmlElement="button"
          variant="primary"
          onClick={() => onClose()}
        >
          {t('component:button.close')}
        </Button>
        <LinkText onClick={() => reopenOrder(ESimActivationMethods.QR_CODE)}>
          {t('esim:preactivatedESim:by-eid.activate-by-qr-code')}
        </LinkText>
      </div>
    </>
  );
};

export default PreactivatedESimByEIdContent;
