import { Button, Col, IconMoveMoney, Row, Text } from '@vodafone/red-white';
import * as React from 'react';
import { ThemeContext } from 'styled-components';
import * as Styled from './CardBillingPayment.styled';

interface IPaymentListItem {
  key?: string;
  title?: string;
  value?: string;
}

type CardBillingStatus = 'default' | 'warning' | 'danger';

export interface ICardCardBillingPaymentProps {
  redBg?: boolean;
  title: string;
  subtitle?: string;
  billing: {
    status?: CardBillingStatus;
    amount?: React.ReactNode;
    title?: string;
    subtitle?: string;
    displayButton?: boolean;
    buttonText?: string;
    qrCodeText?: string;
    linkText?: string;
    onButtonClick?: () => void;
    onLinkClick?: () => void;
  };
  payment: {
    title?: string;
    listData?: IPaymentListItem[];
    qrCode?: string;
  };
}

const getCardBillingStatusColor = (status?: CardBillingStatus) => {
  if (status === 'danger') return 'primaryColor';
  if (status === 'warning') return 'orange';

  return 'darkGrey';
};

const CardBillingPayment: React.FC<ICardCardBillingPaymentProps> = ({
  title,
  subtitle,
  billing,
  payment,
  redBg = false,
}) => {
  const themeContext = React.useContext(ThemeContext);
  const { listData = [] } = payment;
  const billingStatusTextColor = getCardBillingStatusColor(billing.status);
  const billingTextColor = redBg ? 'white' : billingStatusTextColor;
  const textColor = redBg ? 'white' : 'darkGrey';
  const iconColor = redBg
    ? themeContext.colors.white
    : themeContext.colors.darkGrey;
  const billingButtonVariant = redBg ? 'secondary' : 'primary';
  const displayButton = billing.displayButton ? true : null;

  return (
    <Styled.Content redBg={redBg}>
      <Row align="center">
        <Styled.BillingCol xs={24} lg={12} redBg={redBg}>
          <Styled.BillingBase>
            <Styled.Header>
              <span>
                <IconMoveMoney color={iconColor} />
              </span>
              <Styled.HeadingText
                color={textColor}
                fontSize={18}
                fontFamily="VodafoneRgBd"
              >
                {title}
              </Styled.HeadingText>
            </Styled.Header>
            <Styled.SubtitleText block redBg={redBg}>
              {subtitle}
            </Styled.SubtitleText>
            <Styled.BillingWrapper redBg={redBg}>
              <Styled.BillingText
                color={textColor}
                fontSize={56}
                fontFamily="VodafoneLt"
              >
                {billing.amount}
              </Styled.BillingText>
              <Styled.BillingWrapperDetail>
                <Text block color={textColor} fontSize={16}>
                  <span
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{ __html: billing.subtitle }}
                  />
                </Text>
                <Text
                  block
                  color={billingTextColor}
                  fontSize={18}
                  fontFamily="VodafoneRgBd"
                >
                  <span
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{ __html: billing.title }}
                  />
                </Text>
              </Styled.BillingWrapperDetail>
            </Styled.BillingWrapper>
            <Styled.BillingFooter redBg={redBg}>
              {displayButton && (
                <Button
                  variant={billingButtonVariant}
                  onActivation={billing.onButtonClick}
                >
                  {billing.buttonText}
                </Button>
              )}
              {billing.onLinkClick ? (
                <Styled.PaymentLink
                  displayButton={displayButton}
                  onActivation={billing.onLinkClick}
                  redBg={redBg}
                >
                  {billing.linkText}
                </Styled.PaymentLink>
              ) : (
                <Styled.PaymentText displayButton={displayButton} redBg={redBg}>
                  {billing.linkText}
                </Styled.PaymentText>
              )}
            </Styled.BillingFooter>
          </Styled.BillingBase>
        </Styled.BillingCol>
        <Col xs={24} lg={12}>
          <Styled.QrContentWrapper>
            {payment.qrCode && (
              <Styled.QrContent>
                <Styled.MobileQrCodeText
                  block
                  color={textColor}
                  fontSize={18}
                  fontFamily="VodafoneRgBd"
                >
                  {billing.qrCodeText}
                </Styled.MobileQrCodeText>

                <Styled.QrImage src={payment.qrCode} />
              </Styled.QrContent>
            )}
            <Styled.PaymentListWrapper>
              {payment.title && (
                <Text
                  block
                  color={textColor}
                  fontSize={18}
                  fontFamily="VodafoneRgBd"
                >
                  {payment.title}
                </Text>
              )}
              <Styled.PaymentList>
                {listData &&
                  listData.map((item) => (
                    <Styled.PaymentListItem key={item.key}>
                      <Styled.TitleWrapper>
                        <Text color={textColor} fontSize={16}>
                          {item.title}
                        </Text>
                      </Styled.TitleWrapper>
                      <Text
                        color={textColor}
                        fontSize={16}
                        fontFamily="VodafoneRgBd"
                      >
                        {item.value}
                      </Text>
                    </Styled.PaymentListItem>
                  ))}
              </Styled.PaymentList>
            </Styled.PaymentListWrapper>
          </Styled.QrContentWrapper>
        </Col>
      </Row>
    </Styled.Content>
  );
};

export default CardBillingPayment;
