import { AxiosResponse } from 'axios';
import { ISendLeadAction } from 'src/api/genericMicrosites/request/sendLeadActionInterface';
import { ISendLeadResponse } from 'src/api/genericMicrosites/response/sendLeadResponseInterface';
import api from 'src/utils/api/api';
import {
  API_ENDPOINT_GENERIC_MICROSITES_GET_OFFER_DATA,
  API_ENDPOINT_GENERIC_MICROSITES_GET_OFFER_DATA_WITH_SUBMODULE,
  API_ENDPOINT_GENERIC_MICROSITES_POST_OFFER_DATA,
  API_ENDPOINT_GENERIC_MICROSITES_POST_OFFER_DATA_WITH_SUBMODULE,
  API_ENDPOINT_GENERIC_MICROSITES_SEND_LEAD,
  API_ENDPOINT_GENERIC_MICROSITES_SEND_LEAD_WITH_SUBMODULE,
} from '..';
import { LanguagesEnum } from '../../types/translation/languagesEnum';
import { IMicrositeAction } from './request/confirmOffer';
import {
  IMicrositeServiceActionResponse,
  IMicrositeServiceData,
} from './response/getMicrositeDataInterface';

export const getMicrositeData = (
  module: string,
  submodule?: string,
  language?: LanguagesEnum,
  visitGmHash?: string,
): Promise<AxiosResponse<IMicrositeServiceData>> => {
  const urlTemplate = submodule
    ? API_ENDPOINT_GENERIC_MICROSITES_GET_OFFER_DATA_WITH_SUBMODULE
    : API_ENDPOINT_GENERIC_MICROSITES_GET_OFFER_DATA;

  const url = urlTemplate
    .replace('{module}', module)
    .replace('{submodule}', submodule);
  const query: any = {};

  if (language) {
    query.lang = language;
  }

  if (visitGmHash) {
    query.visitGmHash = visitGmHash;
  }

  return api.get({
    url,
    query,
  });
};

export const sendMicrositeData = (
  actionData: IMicrositeAction,
  module: string,
  submodule?: string,
): Promise<AxiosResponse<IMicrositeServiceActionResponse>> => {
  const urlTemplate = submodule
    ? API_ENDPOINT_GENERIC_MICROSITES_POST_OFFER_DATA_WITH_SUBMODULE
    : API_ENDPOINT_GENERIC_MICROSITES_POST_OFFER_DATA;

  const url = urlTemplate
    .replace('{module}', module)
    .replace('{submodule}', submodule);

  return api.post({
    url,
    data: actionData,
  });
};

export const sendMicrositeLead = (
  actionData: ISendLeadAction,
  module: string,
  submodule?: string,
): Promise<AxiosResponse<ISendLeadResponse>> => {
  const urlTemplate = submodule
    ? API_ENDPOINT_GENERIC_MICROSITES_SEND_LEAD_WITH_SUBMODULE
    : API_ENDPOINT_GENERIC_MICROSITES_SEND_LEAD;

  const url = urlTemplate
    .replace('{module}', module)
    .replace('{submodule}', submodule);

  return api.post({
    url,
    data: actionData,
  });
};
