import axios, { AxiosResponse } from 'axios';

interface Props {
  url: string;
  data?: any;
  type?: 'json' | 'form';
  signal?: AbortSignal;
  headers?: { [key: string]: string };
}

const apiPost = <IResponse = any>({
  url,
  data = {},
  type,
  signal,
  headers = {},
}: Props): Promise<AxiosResponse<IResponse>> =>
  new Promise((resolve, reject) => {
    const defaultHeaders: { [key: string]: string } = {
      'X-Requested-With': 'XMLHttpRequest',
    };

    if (type === 'form') {
      defaultHeaders['Content-Type'] = 'multipart/form-data';
    }

    const mergedHeaders = { ...defaultHeaders, ...headers };

    axios
      .post(url, data, {
        signal,
        headers: mergedHeaders,
        withCredentials: true,
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });

export default apiPost;
