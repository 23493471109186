import { LanguagesEnum } from '../../types/translation/languagesEnum';
import { default as routes } from './routes.json';

const generateExternalRoute = (id: string, locale: LanguagesEnum): string => {
  if (id in routes.translations[locale].routes) {
    return `/muj${locale === LanguagesEnum.EN ? '/en' : ''}${
      // @ts-ignore
      routes.translations[locale].routes[id]
    }`;
  }

  throw new Error('invalid route');
};

export default generateExternalRoute;
