import { createContext, useContext } from 'react';
import { AxiosContextProps } from './axios.types';

export const AxiosContext = createContext<AxiosContextProps>(undefined);

export const useAxiosContext = () => {
  const context = useContext(AxiosContext);

  if (context === undefined) {
    throw new Error('useAxiosContext must be used within a AxiosProvider');
  }

  return context;
};
