import { Route } from 'react-router-dom';

export default ({ component, exact, path }) => {
  return (
    <>
      {/* @ts-ignore */}
      <Route exact={exact} path={path} component={component} />
    </>
  );
};
