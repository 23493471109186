import { useMutation } from '@tanstack/react-query';
import api from 'src/utils/api/api';

export type CpeDeviceRestartResponse = {
  message: string;
};

type CpeDeviceRestartRequest = {
  serviceNumber: string;
  deviceIdentifier: string;
};

export const POST_CPE_DEVICE_RESTART = '/muj/rapi/cpe/device/restart';
const POST_CPE_DEVICE_RESTART_KEY = 'postCpeDeviceRestart';

const postCpeDeviceRestart = async (
  request: CpeDeviceRestartRequest,
): Promise<CpeDeviceRestartResponse> => {
  const { serviceNumber, deviceIdentifier } = request;

  const { data } = await api.post<CpeDeviceRestartResponse>({
    url: POST_CPE_DEVICE_RESTART,
    data: {
      serviceNumber,
      deviceIdentifier,
    },
  });

  return data;
};

export const useCpeDeviceRestart = () => {
  return useMutation<CpeDeviceRestartResponse, void, CpeDeviceRestartRequest>({
    mutationFn: postCpeDeviceRestart,
    mutationKey: [POST_CPE_DEVICE_RESTART_KEY],
  });
};
