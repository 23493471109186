import { createSelector, Selector } from 'reselect';
import {
  ITariffOverview,
  PAYMENT_TYPE,
} from 'src/api/serviceManagement/response/getServicesResponseInterface';
import { IApplicationState } from '../../appStateInterface';
import { ITariffOverviewState } from '../types/tariffOverview/tariffOverviewStateInterface';

export const getTariffOverview: Selector<
  IApplicationState,
  ITariffOverviewState
> = (state: IApplicationState) => state.Services.tariffOverview;

export const selectTariffOverview: Selector<
  IApplicationState,
  ITariffOverview
> = createSelector(
  [getTariffOverview],
  (tariffOverview: ITariffOverviewState) => tariffOverview.tariff,
);

export const selectTariffOverviewIsOpen: Selector<IApplicationState, boolean> =
  createSelector(
    [getTariffOverview],
    (tariffOverview: ITariffOverviewState) => tariffOverview.isOpen,
  );

export const selectPaymentType: Selector<IApplicationState, PAYMENT_TYPE> =
  createSelector([getTariffOverview], (tariffOverview: ITariffOverviewState) =>
    tariffOverview.tariff ? tariffOverview.tariff.paymentType : null,
  );

export const selectIsPrepaid: Selector<IApplicationState, boolean> =
  createSelector(
    [getTariffOverview],
    (tariffOverview: ITariffOverviewState) =>
      tariffOverview.tariff.paymentType === PAYMENT_TYPE.PREPAID,
  );

export const selectIsPostpaid: Selector<IApplicationState, boolean> =
  createSelector(
    [getTariffOverview],
    (tariffOverview: ITariffOverviewState) =>
      tariffOverview.tariff.paymentType === PAYMENT_TYPE.POSTPAID,
  );
