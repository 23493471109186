import { Reducer } from 'redux';
import initialState from '../initialState';
import {
  ILoadingClearAction,
  ILoadingStartAction,
  ILoadingStopAction,
} from './types/loadingActionInterfaces';
import { LoadingActionTypes } from './types/loadingActionTypes';
import { ILoadingState } from './types/loadingStateInterface';

type LoadingAction =
  | ILoadingStartAction
  | ILoadingStopAction
  | ILoadingClearAction;

export const loadingReducer: Reducer<ILoadingState> = (
  state: ILoadingState = initialState.Loading,
  action: LoadingAction,
) => {
  switch (action.type) {
    case LoadingActionTypes.LOADING_START:
      return {
        ...state,
        number: state.initialLoading ? state.number : state.number + 1,
        initialLoading: false,
      };

    case LoadingActionTypes.LOADING_STOP:
      return {
        ...state,
        number: state.number - 1 > 0 ? state.number - 1 : 0,
        initialLoading: false,
      };

    case LoadingActionTypes.LOADING_CLEAR:
      return {
        ...state,
        number: 0,
        initialLoading: false,
      };

    default:
      return state;
  }
};
