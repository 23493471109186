import { Loader } from '@vodafone/red-white';
import * as React from 'react';
import ReactDOMServer from 'react-dom/server';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import CurrencyEnum from 'src/components/Price/enums/CurrencyEnum';
import PriceForRenderToString from 'src/components/Price/PriceForRenderToString';
import { useAppDispatch } from 'src/hooks/useAppDispatch';
import IPaymentInfo from 'src/store/billing/paymentInfo/types/paymentInfoInterface';
import { createPaymentTransaction } from 'src/store/billing/paymentTransaction/paymentTransactionActions';
import { selectPaymentTransaction } from 'src/store/billing/paymentTransaction/paymentTransactionSelectors';
import { downloadInvoice } from 'src/store/invoices/invoicesActions';
import InvoiceFormatEnum from 'src/store/invoices/types/InvoiceFormatEnum';
import selectLanguage from 'src/store/language/languageSelector';
import IBillingAccount from 'src/store/user/types/billingAccountInterface';
import UserAuthorizationRoleEnum from 'src/store/user/types/UserAuthorizationRoleEnum';
import userSelector, {
  selectBillingAccount,
} from 'src/store/user/userSelector';
import CardBillingPayment from 'src/ui/components/Billing/CardBillingPayment';
import CardBillingPaymentDifferentAmount from 'src/ui/components/Billing/CardBillingPaymentDifferentAmount';
import { CookieNameEnum, CookiePaymentStatusEnum } from 'src/utils/cookie';
import { toLongMonthDateString } from 'src/utils/date';
import formatter from 'src/utils/formatter';
import capitalize from 'src/utils/formatter/text/capitalize/capitalize';
import { updateOkPaymenCookie } from 'src/utils/payment';
import styled from 'styled-components';
import BillingStatusEnum from '../../../../types/billing/billingStatusEnum';
import PaymentMethodEnum from '../../../../types/billing/paymentMethodEnum';
import SipoStatusEnum from '../../../../types/billing/sipoStatusEnum';
import { LanguagesEnum } from '../../../../types/translation/languagesEnum';
import Price from '../../../Price';

const PriceWrapper = styled.span`
  display: inline-block;
`;

const resolveStatus = (status: BillingStatusEnum) => {
  switch (status) {
    // case BillingStatusEnum.OVERPAID: // paid more
    // case BillingStatusEnum.PAID: // amounts are the same
    // case BillingStatusEnum.SUFFICIENTLY_PAID: // not fully paid, but enough
    // case BillingStatusEnum.DO_NOT_PAY: // you don't have to pay
    // case BillingStatusEnum.UNPAID_OVERDUE: // the whole price is unpaid and overdue
    // case BillingStatusEnum.UNPAID_INCLUDE_OVERDUE: // part of the price is unpaid and overdue
    case BillingStatusEnum.UNDERPAID: // paid but little - still need to pay
      return 'warning';
    case BillingStatusEnum.UNPAID: // nothing paid
      return 'danger';
    default:
      return 'default';
  }
};

const resolveRedBg = (status: BillingStatusEnum) => {
  switch (status) {
    // case BillingStatusEnum.OVERPAID: // paid more
    // case BillingStatusEnum.PAID: // amounts are the same
    // case BillingStatusEnum.SUFFICIENTLY_PAID: // not fully paid, but enough
    // case BillingStatusEnum.DO_NOT_PAY: // you don't have to pay
    // case BillingStatusEnum.UNDERPAID: // paid but little - still need to pay
    // case BillingStatusEnum.UNPAID: // nothing paid
    case BillingStatusEnum.UNPAID_OVERDUE: // the whole price is unpaid and overdue
    case BillingStatusEnum.UNPAID_INCLUDE_OVERDUE: // part of the price is unpaid and overdue
      return true;
    default:
      return false;
  }
};

interface IProps extends IPaymentInfo {
  billingAccountNumber?: string;
}

const PaymentInfoUnpaid: React.FC<IProps> = ({
  billingAccountNumber,
  status,
  qrCode,
  dueDate,
  billingMonth,
  dueAmount,
  billingAmount,
  bankAccount,
  variableSymbol,
  specificSymbol,
  invoiceId,
  paymentAvailable,
  pdfDocumentCreated,
  isSipoActive,
  sipoDueAmount,
  sipoStatus,
}) => {
  const user = useSelector(userSelector);

  const { t } = useTranslation('billing');
  const dispatch = useAppDispatch();
  const language: LanguagesEnum = useSelector(selectLanguage);
  const title = t(
    status === BillingStatusEnum.UNDERPAID
      ? 'billing:paymentInfo.title-UNDERPAID'
      : 'billing:paymentInfo.title-UNPAID',
  );
  const paymentTransaction = useSelector(selectPaymentTransaction);
  const { billingAccounts } = user;

  const resolveAmount = () => {
    if (isSipoActive) {
      if (sipoStatus === SipoStatusEnum.REJECTED && dueAmount === 0) {
        return sipoDueAmount;
      }
      return billingAmount;
    }

    return billingAmount;
  };

  const sessionBillingAccount = useSelector(selectBillingAccount);
  const month = formatter.number.toMonthName(billingMonth, language);
  const amount = Math.floor(resolveAmount());
  const dueDateLong = toLongMonthDateString(dueDate, language);

  const billingAccount: IBillingAccount = billingAccountNumber
    ? billingAccounts.find(
        (account) => account.billingAccountNumber === billingAccountNumber,
      )
    : sessionBillingAccount;

  const subtitle =
    isSipoActive && dueAmount > 120
      ? t('billing:sipo.info-text-above-120')
      : t('billing:paymentInfo.billing-subtitle', {
          date: dueDateLong,
        });

  const createTransaction = () => {
    updateOkPaymenCookie(
      CookieNameEnum.LAST_OK_PAYMENT,
      billingAccount.billingAccountNumber,
      24,
      CookiePaymentStatusEnum.LAST_REQUEST,
    );
    dispatch(
      createPaymentTransaction(
        PaymentMethodEnum.ONLINE,
        billingAccount.billingAccountNumber,
      ),
    );
  };

  const isEndUser =
    user.authorizationRoleName === UserAuthorizationRoleEnum.User;

  const resolveLinkText = () => {
    if (isEndUser) {
      return null;
    }
    if (!pdfDocumentCreated) {
      return t('billing:paymentInfo.pdf-not-ready');
    }

    return t('billing:paymentInfo.link-text');
  };

  return (
    <>
      <Loader show={paymentTransaction.loading} />
      <CardBillingPayment
        redBg={resolveRedBg(status)}
        title={title}
        subtitle={t('billing:paymentInfo.subtitle', {
          month: capitalize(month),
        })}
        billing={{
          status: resolveStatus(status),
          amount: <Price price={amount} />,
          title: t(`billing:paymentInfo.subtitle-${status.toUpperCase()}`, {
            amount: ReactDOMServer.renderToString(
              <PriceWrapper>
                <PriceForRenderToString
                  price={Math.ceil(Number(dueAmount))}
                  currency={
                    language === LanguagesEnum.CS ? 'Kč' : CurrencyEnum.CZK
                  }
                />
              </PriceWrapper>,
            ),
          }),
          subtitle,
          qrCodeText: t('billing:paymentInfo.qr-payment'),
          displayButton: paymentAvailable,
          buttonText: t('billing:paymentInfo.button-text'),
          onButtonClick: () => {
            createTransaction();
          },
          linkText: resolveLinkText(),
          onLinkClick:
            !isEndUser && pdfDocumentCreated
              ? () => {
                  dispatch(
                    downloadInvoice(
                      billingAccount.billingAccountNumber,
                      invoiceId,
                      InvoiceFormatEnum.PDF,
                    ),
                  );
                }
              : null,
        }}
        payment={{
          title: t('billing:paymentInfo.payment-detail-title'),
          listData: [
            {
              key: 'account-number',
              title: t('billing:paymentInfo.payment-account-number'),
              value: bankAccount,
            },
            {
              key: 'variable-symbol',
              title: t('billing:paymentInfo.payment-variable-symbol'),
              value: `${variableSymbol}`,
            },
            {
              key: 'specific-symbol',
              title: t('billing:paymentInfo.payment-specific-symbol'),
              value: `${specificSymbol}`,
            },
          ],
          qrCode,
        }}
      />

      <CardBillingPaymentDifferentAmount
        amount={amount}
        dueAmount={Math.ceil(Number(dueAmount))}
        month={month}
        dueDate={dueDateLong}
        status={status}
        billingAccount={billingAccount}
      />
    </>
  );
};

export default PaymentInfoUnpaid;
