enum PegaOffersOfferIdEnum {
  MVADASHBOARD1 = 'WSCMVA10Dashboard1',
  MVADASHBOARD2 = 'WSCMVA10Dashboard2',
  MVADASHBOARD3 = 'WSCMVA10Dashboard3',
  YOUMAYALSOLIKE = 'YouMayAlsoLike',
  MVATOBI = 'MVA10Tobi',
  WSCSpecialOffer = 'WSCSpecialOffers',
}

export default PegaOffersOfferIdEnum;
