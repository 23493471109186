export enum GetRecurringDataOfferTypes {
  FETCH_GET_DATA_OFFER_BEGIN = 'FETCH_GET_DATA_OFFER_BEGIN',
  FETCH_GET_DATA_OFFER_SUCCESS = 'FETCH_GET_DATA_OFFER_SUCCESS',
  FETCH_GET_DATA_OFFER_FAILURE = 'FETCH_GET_DATA_OFFER_FAILURE',
}

export enum ActivateRecurringDataOfferTypes {
  ACTIVATE_DATA_OFFER_BEGIN = 'ACTIVATE_DATA_OFFER_BEGIN',
  ACTIVATE_DATA_OFFER_SUCCESS = 'ACTIVATE_DATA_OFFER_SUCCESS',
  ACTIVATE_DATA_OFFER_FAILURE = 'ACTIVATE_DATA_OFFER_FAILURE',
}
