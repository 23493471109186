import { Button, InputRedesign, Loader, Text } from '@vodafone/red-white';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HStack, VStack } from 'src/components/Layout';
import { ModalContentProps } from 'src/components/Modal/types/modalContentInterfaces';
import { useRenameIdentity } from 'src/features/profile';
import { useAppDispatch } from 'src/hooks/useAppDispatch';
import { closeModal } from 'src/store/modal/modalActions';
import ModalType from 'src/store/modal/types/ModalType';

const ChangeNicknameModalContent = ({ options }: ModalContentProps) => {
  const { t } = useTranslation('personalAccount');
  const dispatch = useAppDispatch();
  const [newNickname, setNewNickname] = useState<string>(
    options.accountNickname ?? '',
  );
  const { mutate, isPending } = useRenameIdentity();

  const handleNicknameChange = () => {
    mutate(
      { identityId: options.identityId, nickname: newNickname },
      { onSuccess: () => window.location.reload() },
    );
  };

  const cancelButtonClick = () => {
    dispatch(closeModal({ type: ModalType.ChangeNickname }));
  };
  return (
    <>
      <Loader show={isPending} />
      <VStack
        align="center"
        style={{
          padding: '40px',
          width: '100%',
          height: '100%',
          justifyContent: 'space-between',
        }}
        spacing="32px"
      >
        <Text color="red" fontSize={26} fontFamily="VodafoneRgBd">
          {t('profile.modal.change-nickname.title')}
        </Text>
        <InputRedesign
          id="vtv_security_pin"
          name="pin"
          onChange={(e) => setNewNickname(e.target.value)}
          value={newNickname}
          label={t('profile.modal.change-nickname.label')}
          labelAsPlaceholder={false}
        />
        <HStack justify="space-between" spacing="24px" align="center">
          <Button onClick={cancelButtonClick} variant="secondary">
            {t('profile.modal.change-nickname.cancel')}
          </Button>
          <Button onClick={handleNicknameChange} variant="primary">
            {t('profile.modal.change-nickname.confirm')}
          </Button>
        </HStack>
      </VStack>
    </>
  );
};

export default ChangeNicknameModalContent;
