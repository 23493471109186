import React from 'react';
import styled from 'styled-components';

interface VStackProps extends React.HTMLAttributes<HTMLDivElement> {
  spacing?: string;
  align?: 'flex-start' | 'flex-end' | 'center' | 'baseline' | 'stretch';
  children: React.ReactNode;
}

const StyledVStack = styled.div<VStackProps>`
  display: flex;
  flex-direction: column;
  align-items: ${({ align }) => align || 'stretch'};
  & > :not(:first-child) {
    margin-top: ${({ spacing }) => spacing || '0px'};
  }
`;

export const VStack = ({ children, spacing, align, ...props }: VStackProps) => {
  return (
    <StyledVStack spacing={spacing} align={align} {...props}>
      {children}
    </StyledVStack>
  );
};
