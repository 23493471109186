import { Card, IconEsim2, Title } from '@vodafone/red-white';
import { FC, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import PreactivatedESimByEIdContent from 'src/components/Modal/components/ESim/PreactivatedESim/PreactivatedESimByEIdContent';
import PreactivatedESimByQRCodeContent from 'src/components/Modal/components/ESim/PreactivatedESim/PreactivatedESimByQRCodeContent';
import ModalMessageTemplate from 'src/components/Modal/components/ModalMessage/ModalMessageTemplate';
import ModalMessageTypeEnum from 'src/components/Modal/components/ModalMessage/types/ModalMessageTypeEnum';
import { useAppDispatch } from 'src/hooks/useAppDispatch';
import {
  eSimActivateSimOrESimResetStore,
  eSimDeliveryResetStore,
  eSimDetailResetStore,
  eSimFetchDetail,
  eSimSimSwapResetStore,
} from 'src/store/esim/esimActions';
import {
  selectESimActivateSimOrESim,
  selectESimDetail,
} from 'src/store/esim/esimSelectors';
import {
  IESimActivateSimOrESimState,
  IESimDetailState,
} from 'src/store/esim/types/esimStateInterface';
import styled, { ThemeContext } from 'styled-components';
import { formatServiceNumber } from '../../../../utils/serviceNumberFormatter';
import { ModalContentProps } from '../../types/modalContentInterfaces';

const CustomCardPadding = styled.div`
  padding: 40px 0 0 0;
`;

const PreactivatedESimContent: FC<ModalContentProps> = ({
  onClose,
  setCompact,
  setCompactContent,
  options,
}) => {
  const themeContext = useContext(ThemeContext);
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['esim']);

  useEffect(() => {
    dispatch(eSimDetailResetStore());

    dispatch(eSimFetchDetail(options.serviceNumber));

    setCompact(true);
    setCompactContent(true);
    dispatch(eSimDeliveryResetStore());
    dispatch(eSimActivateSimOrESimResetStore());
  }, [setCompactContent, setCompact, dispatch, options.serviceNumber]);

  const eSimDetail: IESimDetailState = useSelector(selectESimDetail);
  const eSimActivation: IESimActivateSimOrESimState = useSelector(
    selectESimActivateSimOrESim,
  );

  const onCloseButtonClick = () => {
    onClose();
    dispatch(eSimSimSwapResetStore());
    dispatch(eSimDeliveryResetStore());
  };

  return (
    <Card
      style={{ maxWidth: 700, width: '100%', boxShadow: 'none' }}
      loading={eSimDetail.loading}
      waiting={eSimActivation.loading}
      title={
        <>
          <IconEsim2 size={20} color={themeContext.colors.darkGrey} />
          <Title
            color="darkGrey"
            fontFamily="VodafoneRg"
            fontWeight="bold"
            level={3}
            style={{ marginLeft: 8 }}
          >
            {t('esim:preactivatedESim.title', {
              serviceNumber: formatServiceNumber(options.serviceNumber),
            })}
          </Title>
        </>
      }
    >
      <CustomCardPadding>
        {eSimActivation.loaded && (
          <ModalMessageTemplate
            type={ModalMessageTypeEnum.SUCCESS}
            heading={t('esim:preactivatedESim:after-submit.success.headline')}
            button={{
              label: t('component:button.finish'),
              onClick: onCloseButtonClick,
            }}
          />
        )}
        {eSimActivation.error && (
          <ModalMessageTemplate type={ModalMessageTypeEnum.ERROR} />
        )}

        {eSimDetail.loaded &&
          !eSimDetail.data.eId &&
          !eSimActivation.loaded &&
          !eSimActivation.error && (
            <>
              <PreactivatedESimByQRCodeContent
                serviceNumber={options.serviceNumber}
                onClose={onClose}
              />
            </>
          )}

        {eSimDetail.loaded &&
          eSimDetail.data.eId &&
          !eSimActivation.loaded &&
          !eSimActivation.error && (
            <>
              <PreactivatedESimByEIdContent
                serviceNumber={options.serviceNumber}
                onClose={onClose}
              />
            </>
          )}
      </CustomCardPadding>
    </Card>
  );
};

export default PreactivatedESimContent;
