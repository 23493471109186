import { OnlineChatResolver, XbotOneWindow } from '@vodafone/xbot-ow-fe';
import i18next from 'i18next';
import { FC, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useChatResolverParameters } from 'src/features/chat/hooks/useChatResolverParameters';
import { IApplicationState } from 'src/store/appStateInterface';
import SegmentTypeEnum from 'src/store/user/types/segmentTypeEnum';
import IUserState from 'src/store/user/types/userStateInterface';
import userSelector from 'src/store/user/userSelector';
import { selectPegaOffer } from '../../store/pegaOffers/pegaOffersSelectors';
import IPegaOffer from '../../store/pegaOffers/types/pegaOfferInterface';
import PegaOffersOfferIdEnum from '../../types/pegaOffers/pegaOffersOfferIdEnum';
import { IS_EMPLOYEE, OPTIN_NOT_CHECKED } from './constants';

const ChatResolver: FC<RouteComponentProps> = ({ location }) => {
  const { data: parameters } = useChatResolverParameters();
  const { segment, loaded }: IUserState = useSelector(userSelector);
  const user: IUserState = useSelector(userSelector);
  const tobiPegaOffer: IPegaOffer[] | null = useSelector(
    (state: IApplicationState) =>
      selectPegaOffer(
        state,
        PegaOffersOfferIdEnum.MVATOBI,
        user.subscription?.serviceNumber,
      ),
  );

  const getSpecificConditions = useCallback((): string[] => {
    const specificConditions: string[] = [];

    if (segment === SegmentTypeEnum.Employee) {
      specificConditions.push(IS_EMPLOYEE);
    }

    if (window.vfconsents && window.vfconsents.get('loc4') === 'i') {
      specificConditions.push(OPTIN_NOT_CHECKED);
    }

    return specificConditions;
  }, [segment]);

  return (
    parameters &&
    loaded && (
      <>
        <OnlineChatResolver
          channel={parameters.channel}
          chatApiDomain={parameters.chatApiDomain}
          chatApiProtocol={parameters.chatApiProtocol}
          phpSessionId={parameters.phpSessionId}
          currentLocation={`${window.location.origin}${location.pathname}`}
        />
        {parameters.xbotOWEnabled && (
          <XbotOneWindow
            dataUrl={parameters.xbotOWDataUrl}
            dataSourcePrefix={parameters.xbotOWDataPrefix}
            language={i18next.language}
            dataChannel="WSC"
            externalConditions={getSpecificConditions()}
            pegaContext={
              tobiPegaOffer?.length > 0 && tobiPegaOffer[0].offerName
            }
            pegaId={tobiPegaOffer?.length > 0 && tobiPegaOffer[0].offerId}
          />
        )}
      </>
    )
  );
};

export default withRouter(ChatResolver);
