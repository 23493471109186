import {
  IconPasswordLock,
  Loader,
  SingleInputContent,
  Text,
} from '@vodafone/red-white';
import {
  ErrorMessage as FormikErrorMessage,
  Formik,
  FormikActions,
  FormikProps,
} from 'formik';
import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { VerificationSecureMessageType } from 'src/api/mfa/request/verificationSecureRequest';
import { MfaGenericErrorContent } from 'src/components/Modal/components/Mfa/ErrorContents/MfaGenericErrorContent';
import { useAppDispatch } from 'src/hooks/useAppDispatch';
import { IApplicationState } from 'src/store/appStateInterface';
import {
  fetchVerificationSecure,
  fetchVerificationSecureVerifyCode,
  resetMfaState,
} from 'src/store/mfa/mfaActions';
import {
  selectMfaRequestSecondsToExpirationForTypeAndServiceNumber,
  selectMfaValidationStatesForTypeAndServiceNumber,
} from 'src/store/mfa/mfaSelectors';
import UserAuthorizationRoleEnum from 'src/store/user/types/UserAuthorizationRoleEnum';
import userSelector, {
  selectBillingOrCustomerAccountContactPhone,
  selectServiceNumber,
} from 'src/store/user/userSelector';
import seconds2Time from 'src/utils/formatter/date/seconds2Time';
import SanitizedHtml from 'src/utils/sanitizeHtml';
import { formatServiceNumber } from 'src/utils/serviceNumberFormatter';

interface IFormValues {
  userInput: string;
}

interface IProps {
  messageType: VerificationSecureMessageType;
  onSuccessCallback: () => void;
  onClose: () => void;
}

export const SuccessContent: FC<IProps> = ({
  messageType,
  onSuccessCallback,
  onClose,
}) => {
  const dispatch = useAppDispatch();

  const { t } = useTranslation(['component', 'form']);
  const contactNumber = useSelector(selectBillingOrCustomerAccountContactPhone);
  const serviceNumber = useSelector(selectServiceNumber);
  const user = useSelector(userSelector);
  const isEndUser =
    user.authorizationRoleName === UserAuthorizationRoleEnum.User;

  const targetNumberForSMS =
    messageType === VerificationSecureMessageType.ONE_NUMBER && isEndUser
      ? serviceNumber
      : contactNumber;

  const validationStates = useSelector((state: IApplicationState) =>
    selectMfaValidationStatesForTypeAndServiceNumber(
      state,
      messageType,
      serviceNumber,
    ),
  );

  const [timeLeft, setTimeLeft] = React.useState(
    useSelector((state: IApplicationState) =>
      selectMfaRequestSecondsToExpirationForTypeAndServiceNumber(
        state,
        messageType,
        serviceNumber,
      ),
    ),
  );

  useEffect(() => {
    if (validationStates.isValid === true) {
      dispatch(resetMfaState(messageType, serviceNumber));
      onClose();
      onSuccessCallback();
    }
  }, [
    validationStates.isValid,
    onSuccessCallback,
    dispatch,
    messageType,
    serviceNumber,
    onClose,
  ]);

  useEffect(() => {
    if (timeLeft === 0) {
      setTimeLeft(null);
    }

    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [timeLeft]);

  const processSubmit = (
    values: IFormValues,
    { setErrors }: FormikActions<IFormValues>,
  ) => {
    dispatch(
      fetchVerificationSecureVerifyCode(
        messageType,
        serviceNumber,
        values.userInput,
      ),
      // @ts-ignore
    ).catch(() => {
      setErrors({
        userInput: t(`form:mfa.${messageType}.validation.code-is-not-valid`),
      });
    });
  };

  const resendSmsButtonText = (): string => {
    return timeLeft > 0
      ? t(`form:mfa.${messageType}.resend-button.disabled`, {
          time: seconds2Time(timeLeft),
        })
      : t(`form:mfa.${messageType}.resend-button.allowed`);
  };

  return (
    <div
      style={{
        maxWidth: 600,
        textAlign: 'center',
        margin: '0 auto',
      }}
    >
      {validationStates.isRateLimited ? (
        <MfaGenericErrorContent
          messageType={messageType}
          isRateLimited
          onClose={onClose}
        />
      ) : (
        <>
          <IconPasswordLock size={60} />
          <Text
            block
            fontSize={27}
            fontFamily="VodafoneRgBd"
            color="green"
            style={{ marginTop: 16 }}
          >
            {t(`form:mfa.${messageType}.title`)}
          </Text>
          <Text block fontSize={16} style={{ marginTop: 32 }}>
            <SanitizedHtml
              htmlTag="span"
              rawHtmlData={t(`form:mfa.${messageType}.description`, {
                contactNumber: formatServiceNumber(targetNumberForSMS),
              })}
            />
          </Text>

          <Loader show={validationStates.loading} />

          <Formik initialValues={{ userInput: '' }} onSubmit={processSubmit}>
            {({
              values,
              errors,
              touched,
              handleChange,
              handleSubmit,
            }: FormikProps<IFormValues>) => (
              <>
                <SingleInputContent
                  singleInputProps={{
                    label: t(`form:mfa.${messageType}.verification-code`),
                    labelUnderline: t(
                      `form:mfa.${messageType}.code-expiration-time`,
                    ),
                    value: values.userInput,
                    onChange: handleChange,
                    errored: errors.userInput && touched.userInput,
                    errorMessageComponent: (
                      <FormikErrorMessage name="userInput" />
                    ),
                  }}
                  submitButtonProps={{
                    label: t(`component:button.continue`),
                    disabled: !values.userInput || validationStates.loading,
                  }}
                  linkButtonProps={{
                    label: resendSmsButtonText(),
                    disabled: timeLeft > 0,
                  }}
                  onSubmit={handleSubmit}
                  onLinkClick={() =>
                    dispatch(
                      fetchVerificationSecure(messageType, serviceNumber),
                    )
                  }
                />
              </>
            )}
          </Formik>
        </>
      )}
    </div>
  );
};
