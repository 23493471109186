import { LocationState } from 'history';
import { RouteComponentProps } from 'react-router-dom';
import routes, {
  buildURLQuery,
  getPathByRouteId,
  getRoute,
  PERSIST_URL_PARAMETERS,
  preparePersistUrlParameters,
  RoutesEnum,
} from 'src/config/routes';
import { LanguagesEnum } from '../types/translation/languagesEnum';
import { ITranslation } from '../types/translationInterface';
import generateExternalRoute from './router/generateExternalRoute';
import getTranslation from './translation/getTranslation';

export interface IResolveRoute {
  internalRouteId?: RoutesEnum;
  language?: LanguagesEnum;
  params?: AnyObject;
  url?: ITranslation | string;
  hash?: string;
  persistUrlParams?: string[];
  forcedReload?: boolean;
}

export interface IGenerateRoute extends IResolveRoute {
  history?: RouteComponentProps['history'];
  state?: LocationState;
}

export const resolveRoutePath = ({
  internalRouteId = null,
  language = LanguagesEnum.CS,
  params = {},
  url = null,
  hash = null,
  persistUrlParams = PERSIST_URL_PARAMETERS,
}: IResolveRoute): string | null => {
  params = { ...params, ...preparePersistUrlParameters(persistUrlParams) };

  // internal (SPA) route
  if (internalRouteId) {
    return getPathByRouteId(internalRouteId, language, params, hash);
  }

  // another (fixed) route
  if (!internalRouteId && url) {
    let link = '';

    if (typeof url === 'string') {
      if (language) {
        try {
          link = generateExternalRoute(url, language);
          if (params) {
            Object.keys(params).forEach((key) => {
              if (link.includes(`{${key}}`)) {
                link = link.replace(`{${key}}`, params[key]);
                delete params[key];
              }
            });
          }
        } catch (e) {
          link = url;
        }
      }
    }
    if (typeof url !== 'string') {
      link = getTranslation(url, language);
    }

    if (hash) {
      link = `${link}#${hash}`;
    }

    // has url parameters?
    if (Object.keys(params).length) {
      return `${link}${link.includes('?') ? '&' : '?'}${buildURLQuery(params)}`;
    }
    return link;
  }

  return null;
};

const hasInternalRoutePageComponent = (
  internalRouteId: RoutesEnum,
): boolean => {
  const foundRoute = routes.find((route) => route.id === internalRouteId);

  return !!(foundRoute && foundRoute.component);
};

const redirectInternal = (
  path: string,
  history: RouteComponentProps['history'],
  state: LocationState = {},
): void => {
  history.push(path, state);
};

const redirectExternal = (path: string): void => {
  window.location.assign(path);
};

export const redirect = ({
  internalRouteId = null,
  language = LanguagesEnum.CS,
  params = {},
  history = null,
  url = null,
  hash = null,
  state = {},
  persistUrlParams = PERSIST_URL_PARAMETERS,
  forcedReload = false,
}: IGenerateRoute): void => {
  if (typeof url === 'string') {
    internalRouteId = getRoute(url)?.id;
    const urlObject = new URL(url, window.location.origin);
    const urlObjectParams = Object.fromEntries(
      urlObject.searchParams.entries(),
    );
    params =
      (internalRouteId &&
        Object.keys(urlObjectParams).length > 0 &&
        urlObjectParams) ||
      params;
  }

  const path = resolveRoutePath({
    internalRouteId,
    url,
    language,
    params,
    hash,
    persistUrlParams,
  });

  if (
    internalRouteId &&
    hasInternalRoutePageComponent(internalRouteId) &&
    history &&
    !forcedReload
  ) {
    redirectInternal(path, history, state);
  } else {
    redirectExternal(path);
  }
};

export const redirectToSpaLoginPage = (history, language: LanguagesEnum) => {
  const redirectParam =
    new URLSearchParams(window.location.search).get('redirect') ||
    new URLSearchParams(window.location.search).get('redirect_uri');

  redirect({
    internalRouteId: RoutesEnum.LOGIN_ROUTE_ID,
    language,
    history,
    state: { from: window.location.href },
    params: {
      ...(redirectParam && { redirect: redirectParam }),
    },
  });
};
