import { ISection } from 'src/store/services/types/section/sectionInterface';
import {
  IService,
  ITsBmslDiscount,
} from 'src/store/services/types/service/serviceInterface';
import { IServicePrice } from 'src/store/services/types/service/servicePriceInterface';
import { ITranslation } from '../../../types/translationInterface';

export enum PAYMENT_TYPE {
  PREPAID = 'prepaid',
  POSTPAID = 'postpaid',
  POSTPAID_WITH_CONTRACT = 'postpaid with contract',
}

export interface IManagementEligibility {
  hasOpenOrder: boolean;
  hasTariffMigrationInCart: boolean;
  isTariffMigrationEligible: boolean;
  isZame: boolean;
}

export enum AdditionalBenefitCodeName {
  DATA_SIM = 'DATA_SIM',
  IOT_SIM = 'IOT_SIM',
  MARKETPLACE = 'MARKETPLACE',
  NETFLIX = 'NETFLIX',
  CYBERWALL = 'CYBERWALL',
  ONENUMBER1 = 'ONENUMBER1',
  ONENUMBER2 = 'ONENUMBER2',

  // merged benefits:
  MERGED_IOT_SIM_AND_DATA_SIM = 'MERGED_IOT_SIM_AND_DATA_SIM',
}

export type AdditionalBenefit = {
  codename: AdditionalBenefitCodeName;
  name: ITranslation;
  icon: string;
  link: string | null;
  count?: number;
};
export interface ITariffOverview {
  connectionTypeDisplayName?: ITranslation;
  tariffDisplayName?: ITranslation;
  tariffHint?: ITranslation;
  tariffHintMessage?: ITranslation;
  tariffFee: IServicePrice;
  handsetInstallmentFee: IServicePrice;
  activeServiceTotal: IServicePrice;
  discountTotal: IServicePrice;
  total: IServicePrice;
  discountNames?: ITranslation[];
  totalRentalPrice?: IServicePrice;
  paymentType?: PAYMENT_TYPE;
  tsBMSLDiscount?: ITsBmslDiscount;
  additionalBenefits?: AdditionalBenefit[];
  choosableBenefits?: AdditionalBenefit[];
}

export interface IGetServicesResponse {
  serviceSections?: ISection[];
  services: IService[];
  tariffOverview?: ITariffOverview;
  managementEligibility?: IManagementEligibility;
}
