import { TFunction } from 'i18next';

export const getRefreshSubscriptionTitle = () => {
  return `services:cas.refresh-subscription${
    window.innerWidth > 440 ? '-title' : '-shortTitle'
  }`;
};

export const setRefreshSubscriptionTitle = (
  t: TFunction,
  setTitle: (t) => void,
  setResized: (string) => void,
) => {
  const handleResize = () => {
    setTitle(t(getRefreshSubscriptionTitle()));
    setResized((value) => !value);
  };

  window.addEventListener('resize', handleResize);

  return () => window.removeEventListener('resize', handleResize);
};
