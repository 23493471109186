import { AxiosError, AxiosRequestConfig } from 'axios';
import { nanoid } from 'nanoid';
import { standaloneRequests } from 'src/api/endpoints/standaloneRequests';
import { logAjaxError } from 'src/components/ErrorBoundary/actions/logActions';
import { HttpStatusCode } from 'src/enums';
import { logoutUserAction } from 'src/store/user/userActions';
import { store } from '../store';
import {
  AxiosRequest,
  AxiosWithQueuesServiceContract,
  ExtendedAxiosRequestConfig,
  ExtendedAxiosResponse,
} from './AxiosService.types';

export class AxiosService implements AxiosWithQueuesServiceContract {
  private _isPaused = false;
  private _pendingQueue: AxiosRequest[] = [];
  private _historyQueue: AxiosRequest[] = [];
  private _runningQueue: AxiosRequest[] = [];

  constructor(
    protected setIsRequestProcessingPaused: (isPaused: boolean) => void,
    protected setContextPendingQueue: (queue: AxiosRequest[]) => void,
    protected setContextHistoryQueue: (queue: AxiosRequest[]) => void,
    protected setContextRunningQueue: (queue: AxiosRequest[]) => void,
  ) {}

  get isPaused(): boolean {
    return this._isPaused;
  }

  set isPaused(isPaused: boolean) {
    this._isPaused = isPaused;
    this.setIsRequestProcessingPaused(isPaused);
  }

  get pendingQueue(): AxiosRequest[] {
    return this._pendingQueue;
  }

  set pendingQueue(requests: AxiosRequest[]) {
    this._pendingQueue = requests;
    this.setContextPendingQueue(this.pendingQueue);
  }

  get runningQueue(): AxiosRequest[] {
    return this._runningQueue;
  }

  set runningQueue(requests: AxiosRequest[]) {
    this._runningQueue = requests;
    this.setContextRunningQueue(this.runningQueue);
  }

  get historyQueue(): AxiosRequest[] {
    return this._historyQueue;
  }

  public setPause(newPauseState: boolean): void {
    this.isPaused = newPauseState;
    this.processPendingQueue();
  }

  private addResponseToRequestObject(
    response: ExtendedAxiosResponse,
  ): AxiosRequest | undefined {
    const request = this._runningQueue.find(
      (request) => request.id === response.config.id,
    );
    if (request) {
      request.response = response;
      request.state = response.status < 400 ? 'resolved' : 'rejected';
    }

    return request;
  }

  private addToPendingQueue(request: AxiosRequest): void {
    this.pendingQueue.push(request);
    this.setContextPendingQueue([...this.pendingQueue, request]);
  }

  private addToRunningQueue(request: AxiosRequest): void {
    request.state = 'resolving';
    this.runningQueue.push(request);
    this.setContextRunningQueue(this.runningQueue);
  }

  private addToHistoryQueue(response: ExtendedAxiosResponse): void {
    const request = this.addResponseToRequestObject(response);

    if (request) {
      this.historyQueue.unshift(request);
    }
    this.setContextHistoryQueue(this.historyQueue);
  }

  private removeFromPendingQueue(request: AxiosRequest) {
    this.pendingQueue = this.pendingQueue.filter((r) => r.id !== request.id);
  }

  private removeFromRunningQueue(id: string): void {
    this.runningQueue = this.runningQueue.filter((r) => r.id !== id);
  }

  private getFirstRequestFromPendingQueue(): AxiosRequest | undefined {
    return this.pendingQueue[0];
  }

  public clearHistoryQueue(): void {
    this._historyQueue = [];
    this.setContextHistoryQueue([]);
  }

  private isNotRunningAnyRequest(): Promise<boolean> {
    return new Promise((resolve) => {
      setInterval(() => {
        if (this.runningQueue.length === 1) {
          return resolve(true);
        }
      }, 1000);
    });
  }

  private processPendingQueue(): void {
    while (this.pendingQueue.length > 0 && !this.isPaused) {
      const nextRequest = this.getFirstRequestFromPendingQueue();
      if (nextRequest) {
        this.addToRunningQueue(nextRequest);
        this.removeFromPendingQueue(nextRequest);
        nextRequest.callback();
      }
    }
  }

  public async requestOnFulfilledCallback(
    axiosRequestConfig: AxiosRequestConfig,
  ): Promise<ExtendedAxiosRequestConfig> {
    const id: string = nanoid();
    const config: ExtendedAxiosRequestConfig = { ...axiosRequestConfig, id };

    const promise = new Promise<ExtendedAxiosRequestConfig>((resolve) => {
      const axiosRequest: AxiosRequest = {
        id,
        state: 'queued',
        config,
        callback: () => {
          return resolve(config);
        },
      };

      this.addToPendingQueue(axiosRequest);
      this.processPendingQueue();
    });

    if (standaloneRequests.includes(axiosRequestConfig.url!)) {
      this.setPause(true);
      await this.isNotRunningAnyRequest();
    }

    return promise;
  }

  public requestOnRejectedCallback(error) {
    return Promise.reject(error);
  }

  public async responseOnFulfilledCallback(
    response: ExtendedAxiosResponse,
  ): Promise<ExtendedAxiosResponse> {
    if (standaloneRequests.includes(response.config.url!)) {
      this.setPause(false);
    }

    this.addToHistoryQueue(response);
    this.removeFromRunningQueue(response.config.id);

    return response;
  }

  public responseOnRejectedCallback(error: AxiosError) {
    const response = error.response as ExtendedAxiosResponse;
    const { status } = response || {};

    if (status === 401) {
      store.dispatch(logoutUserAction());
    }

    if (status && status >= HttpStatusCode.INTERNAL_SERVER_ERROR_500) {
      store.dispatch(
        logAjaxError(error, { config: error.config, response: error.response }),
      );
    }

    this.addToHistoryQueue(response);
    this.removeFromRunningQueue(response.config.id);

    return Promise.reject(error);
  }
}
