import { Reducer } from 'redux';
import initialState from '../../initialState';
import {
  IFetchMnpPenaltyBeginAction,
  IFetchMnpPenaltyFailureAction,
  IFetchMnpPenaltySuccessAction,
} from './types/mnpPenaltyActionInterfaces';
import MnpPenaltyActionTypes from './types/mnpPenaltyActionTypes';
import MnpPenaltyStateInterface from './types/mnpPenaltyStateInterface';

type IMnpOkuActions =
  | IFetchMnpPenaltyBeginAction
  | IFetchMnpPenaltySuccessAction
  | IFetchMnpPenaltyFailureAction;

const mnpPenaltyReducer: Reducer<MnpPenaltyStateInterface, IMnpOkuActions> = (
  state = initialState.Mnp.penalty,
  action,
) => {
  switch (action.type) {
    case MnpPenaltyActionTypes.FETCH_MNP_PENALTY_BEGIN:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      };
    case MnpPenaltyActionTypes.FETCH_MNP_PENALTY_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        loaded: true,
        error: false,
      };
    case MnpPenaltyActionTypes.FETCH_MNP_PENALTY_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    default:
      return state;
  }
};

export default mnpPenaltyReducer;
