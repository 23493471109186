import { generatePath, matchPath } from 'react-router';
import { ActionCreator } from 'redux';
import {
  discardBasketChanges,
  getUnconfirmedChanges,
  switchSubscriber as apiSwitchSubscriber,
} from 'src/api/subscribers';
import routes, { RoutesEnum } from 'src/config/routes';
import { fetchSession } from 'src/store/user/userActions';
import { redirect } from 'src/utils/redirect';
import { searchParamInUrl } from 'src/utils/url';
import { LanguagesEnum } from '../../types/translation/languagesEnum';
import { loadingStart, loadingStop } from '../loading/loadingActions';
import {
  ISwitchSubscriber,
  ISwitchSubscriberBeginAction,
  ISwitchSubscriberFailureAction,
  ISwitchSubscriberSuccessAction,
} from './types/serviceNumberSwitcherActionInterfaces';
import { ServiceNumberSwitcherActionTypes } from './types/serviceNumberSwitcherActionTypes';

export const switchSubscriberBegin: ActionCreator<
  ISwitchSubscriberBeginAction
> = () => ({
  type: ServiceNumberSwitcherActionTypes.SWITCH_SUBSCRIBER_BEGIN,
});

export const switchSubscriberSuccess: ActionCreator<
  ISwitchSubscriberSuccessAction
> = () => ({
  type: ServiceNumberSwitcherActionTypes.SWITCH_SUBSCRIBER_SUCCESS,
});

export const switchSubscriberFailure: ActionCreator<
  ISwitchSubscriberFailureAction
> = () => ({
  type: ServiceNumberSwitcherActionTypes.SWITCH_SUBSCRIBER_FAILURE,
});

export const switchSubscriberCall =
  ({ msisdn, useParam = false, refresh = false, history }: ISwitchSubscriber) =>
  async (dispatch) => {
    const data = new FormData();

    data.append('msisdn', msisdn);

    return apiSwitchSubscriber(data)
      .then((response) => {
        if (response.data === 'ok') {
          dispatch(switchSubscriberSuccess());

          if (refresh) {
            window.location.reload();
            return;
          }

          const availableLanguages = Object.values(LanguagesEnum);
          let redirectToroute = null;

          routes.forEach((route) => {
            availableLanguages.forEach((lang) => {
              const match = matchPath(
                window.location.pathname,
                route.path[lang],
              );

              if (match && !redirectToroute) {
                redirectToroute = generatePath(match.path, {
                  serviceNumber: msisdn,
                });
              }
            });
          });

          if (useParam) {
            redirect({ url: redirectToroute });
          } else {
            dispatch(fetchSession()).then(() => {
              redirect({
                language: window.location.pathname.includes('/en/')
                  ? LanguagesEnum.EN
                  : LanguagesEnum.CS,
                internalRouteId: RoutesEnum.ACTIVE_SERVICES_ROUTE_ID,
                params: { msisdn, dl: searchParamInUrl('dl') },
                history,
              });
              dispatch(loadingStop());
            });
          }
        } else {
          dispatch(loadingStop());
          dispatch(switchSubscriberFailure());
        }
      })
      .catch(() => {
        dispatch(loadingStop());
        dispatch(switchSubscriberFailure());
      });
  };

const discardUnconfirmedBasketChanges =
  ({ msisdn, useParam, refresh, history }: ISwitchSubscriber) =>
  async (dispatch) => {
    return discardBasketChanges()
      .then(() => {
        dispatch(switchSubscriberCall({ msisdn, useParam, refresh, history }));
      })
      .catch(() => {
        dispatch(loadingStop());
        dispatch(switchSubscriberFailure());
      });
  };

const checkUnconfirmedBasketChanges =
  ({ msisdn, useParam, refresh, history }: ISwitchSubscriber) =>
  async (dispatch) => {
    return getUnconfirmedChanges(msisdn)
      .then((response) => {
        if (response.data && response.data.openOrder > 0) {
          dispatch(
            discardUnconfirmedBasketChanges({
              msisdn,
              useParam,
              refresh,
              history,
            }),
          );
        } else {
          dispatch(
            switchSubscriberCall({ msisdn, useParam, refresh, history }),
          );
        }
      })
      .catch(() => {
        dispatch(loadingStop());
        dispatch(switchSubscriberFailure());
      });
  };

export const switchSubscriber =
  ({ msisdn, useParam, refresh, history }: ISwitchSubscriber) =>
  async (dispatch) => {
    dispatch(loadingStart());
    dispatch(switchSubscriberBegin());
    dispatch(
      checkUnconfirmedBasketChanges({ msisdn, useParam, refresh, history }),
    );
  };

export const switchByServiceNumber = (
  msisdn: string,
  selectedSubscriber: string,
  useParam?: boolean,
) => {
  return (dispatch) => {
    if (msisdn !== selectedSubscriber) {
      dispatch(switchSubscriber({ msisdn, useParam }));
    }
  };
};
