export enum InvoicesActionTypes {
  FETCH_INVOICES_BEGIN = 'FETCH_INVOICES_BEGIN',
  FETCH_INVOICES_SUCCESS = 'FETCH_INVOICES_SUCCESS',
  FETCH_INVOICES_FAILURE = 'FETCH_INVOICES_FAILURE',

  FETCH_INVOICE_BREAKDOWN_BEGIN = 'FETCH_INVOICE_BREAKDOWN_BEGIN',
  FETCH_INVOICE_BREAKDOWN_SUCCESS = 'FETCH_INVOICE_BREAKDOWN_SUCCESS',
  FETCH_INVOICE_BREAKDOWN_FAILURE = 'FETCH_INVOICE_BREAKDOWN_FAILURE',

  INVOICE_DOWNLOAD = 'INVOICE_DOWNLOAD',

  FETCH_INVOICES_SUMMARY_BEGIN = 'FETCH_INVOICES_SUMMARY_BEGIN',
  FETCH_INVOICES_SUMMARY_SUCCESS = 'FETCH_INVOICES_SUMMARY_SUCCESS',
  FETCH_INVOICES_SUMMARY_FAILURE = 'FETCH_INVOICES_SUMMARY_FAILURE',
}
