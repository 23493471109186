import { Card, IconEsim2, Title } from '@vodafone/red-white';
import { FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ModalMessageTemplate from 'src/components/Modal/components/ModalMessage/ModalMessageTemplate';
import ModalMessageTypeEnum from 'src/components/Modal/components/ModalMessage/types/ModalMessageTypeEnum';
import { ModalContentProps } from 'src/components/Modal/types/modalContentInterfaces';
import { useAppDispatch } from 'src/hooks/useAppDispatch';
import {
  activateSimORESim,
  eSimActivateSimOrESimResetStore,
  eSimDetailResetStore,
  eSimFetchDetail,
} from 'src/store/esim/esimActions';
import {
  selectESimActivateSimOrESim,
  selectESimDetail,
} from 'src/store/esim/esimSelectors';
import {
  IESimActivateSimOrESimState,
  IESimDetailState,
} from 'src/store/esim/types/esimStateInterface';
import { formatServiceNumber } from 'src/utils/serviceNumberFormatter';
import styled, { ThemeContext } from 'styled-components';

const CustomCardPadding = styled.div`
  padding: 40px 0 0 0;
`;

const CompleteSimSwapContent: FC<ModalContentProps> = ({
  onClose,
  setCompact,
  setCompactContent,
  options,
}) => {
  const themeContext = useContext(ThemeContext);
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['esim']);
  const eSimDetail: IESimDetailState = useSelector(selectESimDetail);
  const eSimActivation: IESimActivateSimOrESimState = useSelector(
    selectESimActivateSimOrESim,
  );
  const iccid = options.iccid || eSimDetail.data?.iccid;
  const [resetDetail, setResetDetail] = useState<boolean>(false);

  useEffect(() => {
    dispatch(eSimActivateSimOrESimResetStore());
    dispatch(eSimDetailResetStore());
    setResetDetail(true);

    setCompact(true);
    setCompactContent(true);
  }, [dispatch, setCompact, setCompactContent]);

  useEffect(() => {
    if (!iccid && resetDetail) {
      dispatch(eSimFetchDetail(options.serviceNumber));
    }
  }, [dispatch, iccid, options.serviceNumber, resetDetail]);

  useEffect(() => {
    if (iccid && resetDetail) {
      dispatch(activateSimORESim(options.serviceNumber, iccid));
    }
  }, [dispatch, iccid, options.serviceNumber, resetDetail]);

  const onCloseButtonClick = () => {
    onClose();
    dispatch(eSimDetailResetStore());
    dispatch(eSimActivateSimOrESimResetStore());
  };

  return (
    <Card
      style={{ maxWidth: 700, width: '100%', boxShadow: 'none' }}
      loading={eSimDetail.loading || eSimActivation.loading}
      title={
        <>
          <IconEsim2 size={20} color={themeContext.colors.darkGrey} />
          <Title
            color="darkGrey"
            fontFamily="VodafoneRg"
            fontWeight="bold"
            level={3}
            style={{ marginLeft: 8 }}
          >
            {t('esim:activateESim.title', {
              serviceNumber: formatServiceNumber(options.serviceNumber),
            })}
          </Title>
        </>
      }
    >
      <CustomCardPadding>
        {eSimActivation.loaded && (
          <ModalMessageTemplate
            type={ModalMessageTypeEnum.SUCCESS}
            heading={t('esim:activateESim:after-submit.success.headline')}
            button={{
              label: t('component:button.finish'),
              onClick: onCloseButtonClick,
            }}
          />
        )}
        {eSimActivation.error && (
          <ModalMessageTemplate type={ModalMessageTypeEnum.ERROR} />
        )}
      </CustomCardPadding>
    </Card>
  );
};

export default CompleteSimSwapContent;
