export default (s: number) => {
  const hours = Math.floor(s / 3600);
  let minutes: string | number = Math.floor((s - hours * 3600) / 60);
  const seconds = s - hours * 3600 - minutes * 60;
  let time = '';

  if (hours !== 0) {
    time = `${hours}:`;
  }
  if (minutes !== 0 || time !== '') {
    minutes = minutes < 10 && time !== '' ? `0${minutes}` : String(minutes);
    time += `${minutes}:`;
  }
  if (time === '') {
    time = `${seconds}s`;
  } else {
    time += seconds < 10 ? `0${seconds}` : String(seconds);
  }
  return time;
};
