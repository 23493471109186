import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import 'core-js/stable';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import 'regenerator-runtime/runtime';
import App from './App';
import { store } from './config/store';
import { APP_MOCKS } from './env';
import { LOCAL_STORAGE_MOCK_KEY } from './features/mocks';

// fix for IE11 (DEV-8997). Polyfill library is too big nad this is a shorter fix
// @ts-ignore
if (!window.crypto && window.msCrypto) {
  // @ts-ignore
  window.crypto = window.msCrypto;
}

let worker;

const enableMocking = async () => {
  if (APP_MOCKS !== 'true') {
    return;
  }

  const { setupWorker } = await import('msw/browser');
  const { resolveHandlers } = await import('./features/mocks/handlers');
  worker = setupWorker(...resolveHandlers());

  await worker.start({
    onUnhandledRequest: 'bypass',
    serviceWorker: { url: '/muj/mockServiceWorker.js' },
  });

  return worker;
};

const reinitializeMocking = async () => {
  if (worker) {
    worker.stop();
    worker = null;
  }
  await enableMocking();
};

window.addEventListener('localStorageChange', async () => {
  if (localStorage.getItem(LOCAL_STORAGE_MOCK_KEY)) {
    await reinitializeMocking();
  }
});

const container = document.getElementById('root');
const root = createRoot(container);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      retry: false,
    },
  },
});

enableMocking().then(() => {
  root.render(
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <App />
      </QueryClientProvider>
    </Provider>,
  );
});
