import { SANITIZE_STATE_DRIVEN_HTML } from '@vodafone/red-white';
import * as React from 'react';
import sanitizeHtml from 'sanitize-html';

// @ts-ignore
type AllowedHtmlTag = (typeof SANITIZE_STATE_DRIVEN_HTML.allowedTags)[number];

interface IProps {
  htmlTag: AllowedHtmlTag;
  rawHtmlData: string;
  style?: React.CSSProperties;
}

const SanitizedHtml: React.FC<IProps> = ({
  htmlTag = 'span',
  rawHtmlData = '',
  style,
}) => {
  const htmlData: string = React.useMemo(
    () => sanitizeHtml(rawHtmlData, SANITIZE_STATE_DRIVEN_HTML),
    [rawHtmlData],
  );

  return React.createElement(htmlTag, {
    dangerouslySetInnerHTML: { __html: htmlData },
    style,
  });
};

export default SanitizedHtml;
