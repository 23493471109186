import { Reducer } from 'redux';
import initialState from '../initialState';
import {
  IFetchSmartOverviewBeginAction,
  IFetchSmartOverviewFailureAction,
  IFetchSmartOverviewSuccessAction,
} from './types/smartOverviewActionInterfaces';
import { SmartOverviewActionTypes } from './types/smartOverviewActionTypes';
import { ISmartOverviewState } from './types/smartOverviewStateInterface';

type SmartOverviewAction =
  | IFetchSmartOverviewBeginAction
  | IFetchSmartOverviewSuccessAction
  | IFetchSmartOverviewFailureAction;

export const smartOverviewReducer: Reducer<ISmartOverviewState> = (
  state: ISmartOverviewState = initialState.SmartOverview,
  action: SmartOverviewAction,
) => {
  switch (action.type) {
    case SmartOverviewActionTypes.FETCH_SMART_OVERVIEW_BEGIN:
      return {
        ...state,
        loading: true,
        loaded: false,
      };

    case SmartOverviewActionTypes.FETCH_SMART_OVERVIEW_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        data: {
          ...state.data,
          [action.serviceNumber]: action.payload,
        },
      };

    case SmartOverviewActionTypes.FETCH_SMART_OVERVIEW_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };

    default:
      return state;
  }
};
