import { ActionCreator } from 'redux';
import { ModalActionTypes } from './modalActionTypes';
import {
  ICloseModalAction,
  ICloseModalActionPayload,
  IOpenModalAction,
  IOpenModalActionPayload,
  IOpenModalWithIdAction,
  IResetModalActionPayload,
  IResetModalAnalyticsOptionsStore,
  IResetModalStore,
  IUpdateModalAnalyticsOptionsStore,
} from './types/modalActionInterfaces';

export const openModal: ActionCreator<IOpenModalAction> = (
  payload: IOpenModalActionPayload,
) => ({
  type: ModalActionTypes.MODAL_OPEN,
  payload,
});

export const updateModalAnalyticsOptions: ActionCreator<
  IUpdateModalAnalyticsOptionsStore
> = (payload: IOpenModalActionPayload) => ({
  type: ModalActionTypes.MODAL_UPDATE_ANALYTICS_OPTIONS,
  payload,
});

export const resetModalAnalyticsOptions: ActionCreator<
  IResetModalAnalyticsOptionsStore
> = (payload: IOpenModalActionPayload) => ({
  type: ModalActionTypes.MODAL_RESET_ANALYTICS_OPTIONS,
  payload,
});

export const openModalWithId: ActionCreator<IOpenModalWithIdAction> = (
  payload: IOpenModalActionPayload,
) => ({
  type: ModalActionTypes.MODAL_OPEN_WITH_ID,
  payload,
});

export const closeModal: ActionCreator<ICloseModalAction> = (
  payload: ICloseModalActionPayload,
) => ({
  type: ModalActionTypes.MODAL_CLOSE,
  payload,
});

export const resetaModalStore: ActionCreator<IResetModalStore> = (
  payload: IResetModalActionPayload,
) => ({
  type: ModalActionTypes.MODAL_RESET_STORE,
  payload,
});
