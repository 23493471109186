import { ActionCreator } from 'redux';
import { getPaymentInfo } from 'src/api/billing';
import {
  IFetchPaymentInfoBeginAction,
  IFetchPaymentInfoFailureAction,
  IFetchPaymentInfoSuccessAction,
} from './types/paymentInfoActionInterfaces';
import PaymentInfoActionTypes from './types/paymentInfoActionTypes';

export const fetchPaymentInfoBeginAction: ActionCreator<
  IFetchPaymentInfoBeginAction
> = (baNumber: string) => ({
  type: PaymentInfoActionTypes.FETCH_PAYMENT_INFO_BEGIN,
  baNumber,
});

export const fetchPaymentInfoSuccessAction: ActionCreator<
  IFetchPaymentInfoSuccessAction
> = (baNumber, payload) => ({
  type: PaymentInfoActionTypes.FETCH_PAYMENT_INFO_SUCCESS,
  baNumber,
  payload,
});

export const fetchPaymentInfoFailureAction: ActionCreator<
  IFetchPaymentInfoFailureAction
> = () => ({
  type: PaymentInfoActionTypes.FETCH_PAYMENT_INFO_FAILURE,
});

export const fetchPaymentInfo = (baNumber: string) => (dispatch) => {
  dispatch(fetchPaymentInfoBeginAction(baNumber));
  getPaymentInfo(baNumber)
    .then((response) => {
      dispatch(fetchPaymentInfoSuccessAction(baNumber, response.data));
    })
    .catch(() => {
      dispatch(fetchPaymentInfoFailureAction());
    });
};
