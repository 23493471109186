/* eslint no-restricted-globals:0 */
import { Modal as ModalComponent } from '@vodafone/red-white';
import { ReactNode, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { IModalOption } from 'src/components/Modal/types/modalOptionInterfaces';
import { useAppDispatch } from 'src/hooks/useAppDispatch';
import { closeModal } from 'src/store/modal/modalActions';
import { getModal } from 'src/store/modal/modalSelectors';
import IModalState from 'src/store/modal/types/modalStateInterface';
import { AUTO_OPEN_MODALS_PREFIX_IN_HASH } from '../../../config/config';
import { setPageAnalytics } from '../../../utils/analytics/actions/analyticsActions';
import modalContentMap from '../config/modalContentMap';
import modalOptionsMap from '../config/modalOptionsMap';

export const getModalNameFromHash = () => {
  return location.hash &&
    location.hash.indexOf(AUTO_OPEN_MODALS_PREFIX_IN_HASH) === 0
    ? location.hash.replace(AUTO_OPEN_MODALS_PREFIX_IN_HASH, '')
    : null;
};

const Modal: React.FC<any> = ({ match }) => {
  const dispatch = useAppDispatch();

  const { isOpen, type, options }: IModalState = useSelector(getModal);

  const [button, setButton] = useState<ReactNode>();
  const [declineButton, setDeclineButton] = useState<ReactNode>();
  const [title, setTitle] = useState<string | null>();
  const [isCompact, setCompact] = useState<boolean>(false);
  const [isCompactContent, setCompactContent] = useState<boolean>(false);
  const [isRedBg, setRedBg] = useState<boolean>(false);
  const [isTransparentBg, setTransparentBg] = useState<boolean>(false);

  const ModalContent: React.FC<any> = modalContentMap[type];
  const modalOptions: IModalOption = modalOptionsMap[type];

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('modal-open');
      if (!modalOptions?.disableDefaultAnalytics) {
        dispatch(setPageAnalytics(match.path));
      }
    } else {
      document.body.classList.remove('modal-open');
    }
  }, [dispatch, match.path, isOpen, modalOptions]);

  const onClose = (_event, closedBy, closedOptions) => {
    dispatch(closeModal({ type, closedBy, closedOptions }));
    setTitle(null);
    setButton(null);
    if (getModalNameFromHash() === type) {
      // remove modal hash from url
      history.replaceState(null, null, ' ');
    }
    setDeclineButton(null);
    setCompact(false);
    setCompactContent(false);
    setRedBg(false);
    setTransparentBg(false);

    dispatch(setPageAnalytics(match.path));

    if (closedOptions && closedOptions.afterCloseFn) {
      closedOptions.afterCloseFn();
    }
  };

  if (ModalContent) {
    return createPortal(
      <ModalComponent
        compactContent={
          (modalOptions && modalOptions.compactContent) || isCompactContent
        }
        dark
        redBg={isRedBg}
        transparentBg={isTransparentBg}
        compact={(modalOptions && modalOptions.compact) || isCompact}
        isOpen={isOpen}
        title={title}
        showCloseIcon={modalOptions ? modalOptions.showCloseIcon : true}
        onClose={onClose}
        button={button}
        declineButton={declineButton}
        style={modalOptions && modalOptions.style}
        closeIconColor={modalOptions && modalOptions.closeIconColor}
        size={modalOptions && modalOptions.size}
        preventCloseWhenClickOnOverlay={
          modalOptions && modalOptions.preventCloseWhenClickOnOverlay
        }
        preventCloseWhenKeypressEscape={
          modalOptions && modalOptions.preventCloseWhenKeypressEscape
        }
      >
        <ModalContent
          onClose={onClose}
          setTitle={setTitle}
          setButton={setButton}
          setDeclineButton={setDeclineButton}
          setCompact={setCompact}
          setCompactContent={setCompactContent}
          setRedBg={setRedBg}
          setTransparentBg={setTransparentBg}
          options={options}
        />
      </ModalComponent>,
      document.getElementById('root'),
    );
  }

  return null;
};

export default withRouter(Modal);
