import { createSelector, Selector } from 'reselect';
import { ICalculateRoamingCalculatorResponse } from 'src/api/serviceManagement/response/calculateRoamingCalculatorResponseInterface';
import { IApplicationState } from '../../appStateInterface';
import { IRoamingCalculator } from '../types/roamingCalculator/roamingCalculatorStateInterface';

const getRoamingCalculator: Selector<IApplicationState, IRoamingCalculator> = (
  state: IApplicationState,
) => state.Services.roamingCalculator;

export const selectCountries: Selector<IApplicationState, {}> = createSelector(
  [getRoamingCalculator],
  (roamingCalculator: IRoamingCalculator) => roamingCalculator.countries,
);

export const selectSubscribers: Selector<IApplicationState, {}> =
  createSelector(
    [getRoamingCalculator],
    (roamingCalculator: IRoamingCalculator) => roamingCalculator.subscribers,
  );

export const selectCalculatorData: Selector<
  IApplicationState,
  ICalculateRoamingCalculatorResponse
> = createSelector(
  [getRoamingCalculator],
  (roamingCalculator: IRoamingCalculator) => roamingCalculator.calculatorData,
);

export const selectIsLoading: Selector<IApplicationState, boolean> =
  createSelector(
    [getRoamingCalculator],
    (roamingCalculator: IRoamingCalculator) => roamingCalculator.loading,
  );

export const selectIsLoaded: Selector<IApplicationState, boolean> =
  createSelector(
    [getRoamingCalculator],
    (roamingCalculator: IRoamingCalculator) => roamingCalculator.loaded,
  );

export const selectIsError: Selector<IApplicationState, boolean> =
  createSelector(
    [getRoamingCalculator],
    (roamingCalculator: IRoamingCalculator) => roamingCalculator.error,
  );
