import { Reducer } from 'redux';
import {
  IFetchLargeAccountsPreactivatedCAEsimsBeginAction,
  IFetchLargeAccountsPreactivatedCAEsimsFailureAction,
  IFetchLargeAccountsPreactivatedCAEsimsSuccessAction,
} from 'src/store/largeAccounts/types/largeAccountsActionInterface';
import { ILargeAccountsState } from 'src/store/largeAccounts/types/largeAccountsStateInterface';
import LargeAccountsTypes from 'src/store/largeAccounts/types/largeAccountsTypes';
import initialState from '../initialState';

type Actions =
  | IFetchLargeAccountsPreactivatedCAEsimsBeginAction
  | IFetchLargeAccountsPreactivatedCAEsimsSuccessAction
  | IFetchLargeAccountsPreactivatedCAEsimsFailureAction;

const largeAccountsReducer: Reducer<ILargeAccountsState, Actions> = (
  state: ILargeAccountsState = initialState.LargeAccounts,
  action: Actions,
) => {
  switch (action.type) {
    case LargeAccountsTypes.LARGE_ACCOUNTS_FETCH_PREACTIVATED_CA_ESIMS_BEGIN:
      return {
        ...state,
        preactivatedESims: {
          ...state.preactivatedESims,
          data: {
            ...state.preactivatedESims.data,
            [action.caNumber]: {
              ...state.preactivatedESims.data[action.caNumber],
              loading: true,
              loaded: false,
              error: false,
            },
          },
        },
      };

    case LargeAccountsTypes.LARGE_ACCOUNTS_FETCH_PREACTIVATED_CA_ESIMS_SUCCESS:
      return {
        ...state,
        preactivatedESims: {
          ...state.preactivatedESims,
          data: {
            ...state.preactivatedESims.data,
            [action.caNumber]: {
              ...state.preactivatedESims.data[action.caNumber],
              loading: false,
              loaded: true,
              error: false,
              data: action.payload.subscribers,
            },
          },
        },
      };

    case LargeAccountsTypes.LARGE_ACCOUNTS_FETCH_PREACTIVATED_CA_ESIMS_FAILURE:
      return {
        ...state,
        preactivatedESims: {
          ...state.preactivatedESims,
          data: {
            ...state.preactivatedESims.data,
            [action.caNumber]: {
              ...state.preactivatedESims.data[action.caNumber],
              loading: false,
              loaded: false,
              error: true,
              data: [],
            },
          },
        },
      };

    default:
      return state;
  }
};

export default largeAccountsReducer;
