import { Reducer } from 'redux';
import initialState from '../../initialState';
import {
  IFetchVirtualBundleBeginAction,
  IFetchVirtualBundleFailureAction,
  IFetchVirtualBundleSuccessAction,
} from '../types/virtualBundle/virtualBundleActionInterfaces';
import { VirtualBundleActionTypes } from '../types/virtualBundle/virtualBundleActionTypes';
import { IVirtualBundleState } from '../types/virtualBundle/virtualBundleStateInterface';

type VirtualBundleAction =
  | IFetchVirtualBundleBeginAction
  | IFetchVirtualBundleSuccessAction
  | IFetchVirtualBundleFailureAction;

export const virtualBundleReducer: Reducer<IVirtualBundleState> = (
  state: IVirtualBundleState = initialState.Services.virtualBundle,
  action: VirtualBundleAction,
) => {
  switch (action.type) {
    case VirtualBundleActionTypes.FETCH_VIRTUAL_BUNDLE_BEGIN:
      return {
        ...state,
        loading: true,
        loaded: false,
      };

    case VirtualBundleActionTypes.FETCH_VIRTUAL_BUNDLE_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        data: action.payload,
      };

    case VirtualBundleActionTypes.FETCH_VIRTUAL_BUNDLE_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };

    default:
      return state;
  }
};

export default virtualBundleReducer;
