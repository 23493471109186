import { LoaderFullPage } from '@vodafone/red-white';
import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FadeInWrapper } from 'src/components/Styled/Styled';
import { IApplicationState } from 'src/store/appStateInterface';
import { selectShowLoading } from 'src/store/loading/loadingSelectors';

interface IProps {
  displayLoading?: boolean;
}

const Loading: FC<IProps> = ({ displayLoading }) => {
  const showLoading: boolean = useSelector((state: IApplicationState) =>
    selectShowLoading(state),
  );
  const [loading, setLoading] = useState(false);
  const [timer, setTimer] = useState(undefined);

  useEffect(() => {
    if (displayLoading || showLoading) {
      setLoading(true);
      if (timer) {
        clearTimeout(timer);
      }
    } else {
      setTimer(
        setTimeout(() => {
          setLoading(false);
        }, 500),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayLoading, showLoading]);

  // return <LoaderFullPage show={showLoading || displayLoading} opacity={0.7} />;
  return (
    <div>
      <FadeInWrapper
        visible={displayLoading || showLoading}
        displayProp={loading}
      >
        <LoaderFullPage show opacity={0.7} />
      </FadeInWrapper>
    </div>
  );
};

export default Loading;
