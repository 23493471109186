import { ActionCreator } from 'redux';
import { getPayerPayment } from 'src/api/billing';
import {
  IFetchPayerPaymentBeginAction,
  IFetchPayerPaymentFailureAction,
  IFetchPayerPaymentSuccessAction,
} from './types/payerPaymentActionInterfaces';
import PayerPaymentActionTypes from './types/payerPaymentActionTypes';

export const fetchPayerPaymentBeginAction: ActionCreator<
  IFetchPayerPaymentBeginAction
> = (baNumber: string) => ({
  type: PayerPaymentActionTypes.FETCH_PAYER_PAYMENT_BEGIN,
  baNumber,
});

export const fetchPayerPaymentSuccessAction: ActionCreator<
  IFetchPayerPaymentSuccessAction
> = (payload) => ({
  type: PayerPaymentActionTypes.FETCH_PAYER_PAYMENT_SUCCESS,
  payload,
});

export const fetchPayerPaymentFailureAction: ActionCreator<
  IFetchPayerPaymentFailureAction
> = () => ({
  type: PayerPaymentActionTypes.FETCH_PAYER_PAYMENT_FAILURE,
});

export const fetchPayerPayment = (baNumber: string) => (dispatch) => {
  dispatch(fetchPayerPaymentBeginAction());
  getPayerPayment(baNumber)
    .then((response) => {
      dispatch(fetchPayerPaymentSuccessAction(response.data));
    })
    .catch(() => {
      dispatch(fetchPayerPaymentFailureAction());
    });
};
