import { IGetFbbModemMacAddressInterface } from 'src/api/fbb/response/getFbbModemMacAddressInterface';

export const FETCH_FBB_MODEM_MAC_ADDRESS_SUCCESS =
  'FETCH_FBB_MODEM_MAC_ADDRESS_SUCCESS';
export const FETCH_FBB_MODEM_MAC_ADDRESS_REFRESHING =
  'FETCH_FBB_MODEM_MAC_ADDRESS_RESTARTING';

export interface IFetchFbbModemMacAddressSuccessAction {
  type: typeof FETCH_FBB_MODEM_MAC_ADDRESS_SUCCESS;
  payload: IGetFbbModemMacAddressInterface;
}

export interface IFetchFbbModemMacAddressRestartingAction {
  type: typeof FETCH_FBB_MODEM_MAC_ADDRESS_REFRESHING;
}

export type FbbModemMacAddressTypes =
  | IFetchFbbModemMacAddressSuccessAction
  | IFetchFbbModemMacAddressRestartingAction;
