import { Reducer } from 'redux';
import initialState from '../../initialState';
import {
  IInvokeMnpCvopBeginAction,
  IInvokeMnpCvopFailureAction,
  IInvokeMnpCvopSuccessAction,
} from './types/mnpCvopActionInterfaces';
import MnpCvopActionTypes from './types/mnpCvopActionTypes';
import IMnpCvopState from './types/mnpCvopStateInterface';

type IMnpCvopActions =
  | IInvokeMnpCvopBeginAction
  | IInvokeMnpCvopSuccessAction
  | IInvokeMnpCvopFailureAction;

const mnpCvopReducer: Reducer<IMnpCvopState, IMnpCvopActions> = (
  state = initialState.Mnp.cvop,
  action,
) => {
  switch (action.type) {
    case MnpCvopActionTypes.INVOKE_MNP_CVOP_BEGIN:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      };
    case MnpCvopActionTypes.INVOKE_MNP_CVOP_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    case MnpCvopActionTypes.INVOKE_MNP_CVOP_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    default:
      return state;
  }
};

export default mnpCvopReducer;
