import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import userSelector from 'src/store/user/userSelector';
import api from 'src/utils/api/api';

export const REFRESH_TOKEN_ENDPOINT = '/muj/auth/refresh';
const REFRESH_TOKEN_KEY = 'authRefreshToken';

type RefreshTokenResponse = {
  tokenExpiresIn: string;
};

const getRefreshToken = async (): Promise<RefreshTokenResponse> => {
  const { data } = await api.post({ url: REFRESH_TOKEN_ENDPOINT });

  return data;
};

export const useRefreshToken = (
  refetchInterval: number | undefined,
  isEnabled: boolean,
) => {
  const { signedIn } = useSelector(userSelector);
  const adjustedRefetchInterval = Math.max(
    1,
    (refetchInterval || 0) - 2 * 60 * 1000,
  );

  return useQuery({
    queryFn: getRefreshToken,
    queryKey: [REFRESH_TOKEN_KEY],
    refetchInterval: adjustedRefetchInterval,
    refetchIntervalInBackground: true,
    enabled: !!signedIn && isEnabled,
  });
};
