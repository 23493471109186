import { AxiosError } from 'axios';

const isAxiosError = (error: AxiosError): error is AxiosError => {
  return (
    'config' in error &&
    'isAxiosError' in error &&
    error.isAxiosError === true &&
    'toJSON' in error
  );
};

export default isAxiosError;
