import { useQuery } from '@tanstack/react-query';
import api from 'src/utils/api/api';

export const GET_CORE_OUTAGE_ENDPOINT = '/muj/rapi/core/outage';
const CORE_OUTAGE_KEY = 'getCoreOutage';

type CoreOutageResponse = {
  isFullOutage: boolean;
  isReadMode: boolean;
  isXbotEnabled: boolean;
};

const getOutage = async (): Promise<CoreOutageResponse> => {
  const { data } = await api.get({ url: GET_CORE_OUTAGE_ENDPOINT });

  return data;
};

export const useCoreOutage = () => {
  return useQuery({
    queryFn: () => getOutage(),
    queryKey: [CORE_OUTAGE_KEY],
    staleTime: 60 * 1000, // Keep outage 30 sec in memory
  });
};
