import {
  IconErrorMid,
  IconSuccess,
  IconWarningMid,
  Text,
  Title,
} from '@vodafone/red-white';
import React from 'react';
import * as Styled from './CardReceivedPaymentsResult.styled';

export type PaymentResultStatus = 'success' | 'warning' | 'error';

export interface ICardReceivedPaymentsResultProps {
  status?: PaymentResultStatus;
  title?: React.ReactNode;
  subTitle?: React.ReactNode;
  extra?: React.ReactNode[];
}

const statusIcon = {
  success: <IconSuccess size={76} />,
  warning: <IconWarningMid size={76} />,
  error: <IconErrorMid size={76} />,
};

const CardReceivedPaymentsResult: React.FC<
  ICardReceivedPaymentsResultProps
> = ({ status, title, subTitle, extra }) => {
  const getExtraItem = () => {
    return extra.map((item, i) => {
      return (
        <Styled.ExtraItemWrapper key={i.toString()}>
          {item}
        </Styled.ExtraItemWrapper>
      );
    });
  };

  return (
    <Styled.ResultBase>
      <Styled.IconSpacing>{statusIcon[status]}</Styled.IconSpacing>
      <Styled.TitleSpacing>
        <Title fontSize={26} color="darkGrey">
          {title}
        </Title>
      </Styled.TitleSpacing>
      <Styled.SubtitleSpacing>
        <Text fontSize={16} color="darkGrey">
          {subTitle}
        </Text>
      </Styled.SubtitleSpacing>
      {extra && getExtraItem()}
    </Styled.ResultBase>
  );
};

export default CardReceivedPaymentsResult;
