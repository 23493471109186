import styled from 'styled-components';

export const IconWrapper = styled.span`
  display: inline-block;
  margin-right: 6px;
`;

export const TitleWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  margin: 10px 0;
  position: relative;
  justify-content: start;
  padding: 7px 0;
  @media only screen and (max-width: 520px) {
    margin-top: 0px;
  }
`;
