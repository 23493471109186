import { Col, Row, Text } from '@vodafone/red-white';
import * as React from 'react';
import * as Styled from './PayerSelectItem.styled';

export interface IPayerSelectItemProps {
  active?: boolean;
  label?: string;
  labelDesc?: string;
  onClick?: () => void;
  showBullet?: boolean;
}

const PayerSelectItem: React.FC<IPayerSelectItemProps> = ({
  active,
  label,
  labelDesc,
  onClick,
  showBullet = true,
}) => {
  return (
    <Styled.SelectItemBase onClick={onClick}>
      <Row>
        <Col>
          <Text block fontSize={18} color="darkGrey" fontFamily="VodafoneRgBd">
            {label}
          </Text>
          <Text block color="darkGrey">
            {labelDesc}
          </Text>
        </Col>
        {showBullet && (
          <Col justify="center" align="flex-end">
            <Styled.BulletBorder active={active}>
              {active && <Styled.Bullet />}
            </Styled.BulletBorder>
          </Col>
        )}
      </Row>
    </Styled.SelectItemBase>
  );
};

export default PayerSelectItem;
