import {
  Button,
  Card,
  Col,
  IconLockRedesign,
  IconMinutesSMS,
  InputRedesign as Input,
  Row,
  Text,
} from '@vodafone/red-white';
import { FC, useContext, useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { verifyContactNumber } from 'src/api/personalAccount';
import requestValidationSmsCall from 'src/api/requestValidationSms';
import { useAppDispatch } from 'src/hooks/useAppDispatch';
import { closeModal } from 'src/store/modal/modalActions';
import ModalType from 'src/store/modal/types/ModalType';
import { updateContactNumber } from 'src/store/user/userActions';
import styled, { ThemeContext } from 'styled-components';
import SanitizedHtml from '../../../utils/sanitizeHtml';
import {
  addPrefix,
  formatServiceNumber,
} from '../../../utils/serviceNumberFormatter';
import { ModalContentProps } from '../types/modalContentInterfaces';

export type RequestFormValues = {
  contactNumber: string;
};

export type VerifyFormValues = {
  verificationCode: string;
};

const CardPadding = styled.div`
  padding: 15px;
  padding-bottom: 26px;

  @media ${(props) => props.theme.breakpoints.md} {
    padding-left: 38px;
    padding-right: 38px;
    padding-top: 30px;
    padding-bottom: 47px;
  }
`;

const FormField = styled(Row)`
  margin-top: 24px;
`;

enum ChangeContactNumberSteps {
  REQUEST = 'REQUEST',
  VERIFY = 'VERIFY',
  COMPLETE = 'COMPLETE',
}

const ChangeContactNumberContent: FC<ModalContentProps> = ({
  setCompact,
  setCompactContent,
  options,
}) => {
  const themeContext = useContext(ThemeContext);
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['component', 'form', 'error']);
  const [loading, setLoading] = useState<boolean>(false);
  const [step, setStep] = useState<ChangeContactNumberSteps>(
    ChangeContactNumberSteps.REQUEST,
  );
  const [serverError, setServerError] = useState<string>('');
  const [newContactNumber, setNewContactNumber] = useState<string>('');
  const { handleSubmit, control, errors } = useForm({
    shouldUnregister: false,
  });

  const contactNumber = options && options.contactNumber;

  const validateContactNumber = (serviceNumber: string) => {
    const isValid = /^\+?\d{3}?\d{3}?\d{3}$/.test(serviceNumber);

    if (!isValid) return t('form:error.invalid-contact-number');

    return null;
  };

  const onRequestVerification: SubmitHandler<RequestFormValues> = async (
    data,
  ) => {
    setLoading(true);
    setServerError('');
    setNewContactNumber('');

    try {
      setNewContactNumber(data.contactNumber);
      await requestValidationSmsCall({
        verificationTarget: contactNumber,
        messageType: 'contact_number_change',
      });
      setStep(ChangeContactNumberSteps.VERIFY);
    } catch (e) {
      if (e) {
        setServerError(t('error:api-request'));
        setNewContactNumber('');
      }
    }

    setLoading(false);
  };

  const onSubmitVerification: SubmitHandler<VerifyFormValues> = async () => {
    setLoading(true);
    setServerError('');

    try {
      const response = await verifyContactNumber(
        addPrefix(newContactNumber),
        '000000',
      );
      const result = response.data?.result;

      if (result === 'OK') {
        dispatch(updateContactNumber(newContactNumber));
        dispatch(closeModal({ type: ModalType.ChangeContactNumber }));
        setNewContactNumber('');
      } else {
        setServerError(t('error:api-request'));
      }
    } catch (e) {
      if (e) {
        setServerError(t('error:api-request'));
      }
    }

    setLoading(false);
  };

  const resendCode = async () => {
    try {
      await requestValidationSmsCall({
        verificationTarget: newContactNumber,
        messageType: 'contact_number_change',
      });
    } catch (e) {
      if (e) {
        setServerError(t('error:api-request'));
      }
    }
  };

  useEffect(() => {
    setCompact(true);
    setCompactContent(true);
  }, [setCompact, setCompactContent]);

  const heading = {
    [ChangeContactNumberSteps.REQUEST]: (
      <>
        <IconMinutesSMS size={100} color={themeContext.colors.green} />
        <Text block fontSize={27} fontFamily="VodafoneRgBd" color="green">
          {t('form:changeContactNumber.title')}
        </Text>
        <Text block fontSize={16} style={{ marginTop: 32 }}>
          <SanitizedHtml
            htmlTag="span"
            rawHtmlData={t('form:changeContactNumber.step1', {
              caNumber: formatServiceNumber(contactNumber),
            })}
          />
        </Text>
      </>
    ),
    [ChangeContactNumberSteps.VERIFY]: (
      <>
        <IconLockRedesign size={80} />
        <Text
          block
          fontSize={27}
          fontFamily="VodafoneRgBd"
          color="green"
          style={{ marginTop: 16 }}
        >
          {t('form:changeContactNumber.title2')}
        </Text>
        <Text block fontSize={16} style={{ marginTop: 32 }}>
          <SanitizedHtml
            htmlTag="span"
            rawHtmlData={t('form:changeContactNumber.step2', {
              caNumber: formatServiceNumber(contactNumber),
            })}
          />
        </Text>
      </>
    ),
  };

  const renderRequestVerificationForm = () => (
    <form
      onSubmit={handleSubmit(onRequestVerification)}
      name="requestVerificationForm"
    >
      <FormField>
        <Controller
          as={Input}
          control={control}
          labelAsPlaceholder={false}
          id="contactNumber"
          label={t('form:changeContactNumber.fields.contactNumber.label')}
          name="contactNumber"
          rules={{
            required: { value: true, message: t('form:error.required') },
            validate: validateContactNumber,
          }}
          type="contactNumber"
          message={
            (errors.contactNumber && errors.contactNumber.message) ||
            serverError
          }
          errored={!!errors.contactNumber || !!serverError}
          defaultValue=""
          disabled={loading}
        />
      </FormField>
      <FormField justify="center">
        <Col xs={12}>
          <Button
            variant="primary"
            block
            htmlElement="button"
            htmlElementProps={{ type: 'submit' }}
            disabled={loading}
          >
            {loading
              ? t('component:button.loading')
              : t('component:button.send-code')}
          </Button>
        </Col>
      </FormField>
    </form>
  );

  const renderVerificationForm = () => (
    <form
      onSubmit={handleSubmit(onSubmitVerification)}
      name="requestVerificationForm"
    >
      <FormField>
        <Controller
          as={Input}
          control={control}
          labelAsPlaceholder={false}
          id="verificationCode"
          label={t('form:changeContactNumber.fields.verificationCode.label')}
          name="verificationCode"
          rules={{
            required: { value: true, message: t('form:error.required') },
          }}
          type="verificationCode"
          message={
            (errors.verificationCode && errors.verificationCode.message) ||
            serverError
          }
          errored={!!errors.verificationCode || !!serverError}
          defaultValue=""
          disabled={loading}
        />
      </FormField>
      <Text
        block
        fontSize={14}
        color="grayMid6"
        style={{ textAlign: 'center', marginTop: 8 }}
      >
        {t('form:changeContactNumber.fields.verificationCode.description')}
      </Text>
      <FormField justify="center">
        <Col xs={12}>
          <Button
            variant="primary"
            block
            htmlElement="button"
            htmlElementProps={{ type: 'submit' }}
            disabled={loading}
          >
            {loading
              ? t('component:button.loading')
              : t('component:button.continue')}
          </Button>
        </Col>
      </FormField>
      <div onClick={resendCode}>
        <Text
          block
          fontSize={14}
          style={{
            textAlign: 'center',
            textDecoration: 'underline',
            cursor: 'pointer',
            marginTop: 16,
          }}
        >
          {t('form:changeContactNumber.resendCode')}
        </Text>
      </div>
    </form>
  );

  return (
    <Card padding={CardPadding}>
      <div
        style={{
          maxWidth: 400,
          textAlign: 'center',
          margin: '0 auto',
        }}
      >
        {heading[step]}
      </div>
      {step === ChangeContactNumberSteps.REQUEST &&
        renderRequestVerificationForm()}
      {step === ChangeContactNumberSteps.VERIFY && renderVerificationForm()}
    </Card>
  );
};

export default ChangeContactNumberContent;
