import { Reducer } from 'redux';
import initialState from '../../initialState';
import {
  IFetchPaymentStatusBeginAction,
  IFetchPaymentStatusFailureAction,
  IFetchPaymentStatusSuccessAction,
} from './types/paymentStatusActionInterfaces';
import PaymentStatusActionTypes from './types/paymentStatusActionTypes';
import IPaymentStatusState from './types/paymentStatusStateInterface';

type IPaymentStatusActions =
  | IFetchPaymentStatusBeginAction
  | IFetchPaymentStatusSuccessAction
  | IFetchPaymentStatusFailureAction;

const paymentStatusReducer: Reducer<
  IPaymentStatusState,
  IPaymentStatusActions
> = (state = initialState.Billing.PaymentStatus, action) => {
  switch (action.type) {
    case PaymentStatusActionTypes.FETCH_PAYMENT_STATUS_BEGIN:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      };
    case PaymentStatusActionTypes.FETCH_PAYMENT_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        data: action.payload,
      };
    case PaymentStatusActionTypes.FETCH_PAYMENT_STATUS_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    default:
      return state;
  }
};

export default paymentStatusReducer;
