import { Button, Card, IconSimHi, Title } from '@vodafone/red-white';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { ModalContentProps } from 'src/components/Modal/types/modalContentInterfaces';
import styled from 'styled-components';

const CustomCardPadding = styled.div`
  padding: 40px 0 0 0;
  text-align: center;
`;

const StyledButton = styled(Button)`
  background: transparent;
`;

const IotAndDataSimCardInfoModalContent: FC<ModalContentProps> = ({
  setCompact,
  setCompactContent,
  onClose,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['services', 'component']);

  useEffect(() => {
    setCompact(true);
    setCompactContent(true);
  }, [setCompactContent, setCompact, dispatch]);

  return (
    <Card
      style={{ maxWidth: 500, width: '100%', boxShadow: 'none' }}
      waiting={false}
    >
      <CustomCardPadding>
        <IconSimHi />
        <Title
          color="darkGrey"
          fontFamily="VodafoneRg"
          fontWeight="bold"
          level={3}
          style={{ marginLeft: 8, fontSize: 28, marginTop: 20 }}
        >
          {t(
            'services:additional-benefits.iot-and-data-sim-benefit.modal-title',
          )}
        </Title>

        <p style={{ paddingTop: 20, paddingBottom: 20 }}>
          {t(
            'services:additional-benefits.iot-and-data-sim-benefit.modal-content',
          )}
        </p>

        <StyledButton
          htmlElement="button"
          variant="secondaryDark"
          onClick={() => onClose()}
        >
          {t('component:button.close')}
        </StyledButton>
      </CustomCardPadding>
    </Card>
  );
};

export default IotAndDataSimCardInfoModalContent;
