import { AxiosResponse } from 'axios';
import { API_ENDPOINT_ESIM_ONENUMBER_PORTAL_LINK } from 'src/api';
import IESimOneNumberPortalLinkResponse from 'src/api/esim/response/eSimOneNumberPortalLinkResponse';
import { IRestResponseFault } from 'src/api/types/IRestResponseFault';
import api from 'src/utils/api/api';

export default (
  caNumber: string,
): Promise<
  AxiosResponse<IESimOneNumberPortalLinkResponse | IRestResponseFault>
> =>
  api.post({
    url: API_ENDPOINT_ESIM_ONENUMBER_PORTAL_LINK,
    data: {
      caNumber,
    },
  });
