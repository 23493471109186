export enum VerificationSecureMessageType {
  ESIM_SWAP = 'esim_swap',
  ONE_NUMBER = 'onenumber',
}

export default interface IVerificationSecureRequest {
  messageType: VerificationSecureMessageType;
  serviceNumber: string;
  language: string;
}
