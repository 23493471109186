import { Reducer } from 'redux';
import initialState from '../initialState';
import {
  IRecoveryEligibilityBeginAction,
  IRecoveryEligibilityFailureAction,
  IRecoveryEligibilitySuccessAction,
} from './types/recoveryEligibilityActionInterfaces';
import RecoveryEligibilityActionTypes from './types/recoveryEligibilityActionTypes';
import IRecoveryEligibilityState from './types/recoveryEligibilityStateInterface';

type IRecoveryEligibilityAction =
  | IRecoveryEligibilityBeginAction
  | IRecoveryEligibilitySuccessAction
  | IRecoveryEligibilityFailureAction;

const recoveryEligibilityReducer: Reducer<IRecoveryEligibilityState> = (
  state: IRecoveryEligibilityState = initialState.RecoveryEligibility,
  action: IRecoveryEligibilityAction,
) => {
  switch (action.type) {
    case RecoveryEligibilityActionTypes.RECOVERY_ELIGIBILITY_BEGIN:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      };
    case RecoveryEligibilityActionTypes.RECOVERY_ELIGIBILITY_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        loaded: true,
        error: false,
      };
    case RecoveryEligibilityActionTypes.RECOVERY_ELIGIBILITY_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    default:
      return state;
  }
};

export default recoveryEligibilityReducer;
