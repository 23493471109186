import { createSelector, Selector } from 'reselect';
import { IApplicationState } from '../appStateInterface';
import { IEetReceipt } from './types/eetReceiptsInterface';
import { IEetReceiptsState } from './types/eetReceiptsStateInterface';

const getEetReceipts: Selector<IApplicationState, IEetReceiptsState> = (
  state: IApplicationState,
) => state.EETReceipts;

export const selectIsEetReceiptsLoaded: Selector<IApplicationState, boolean> =
  createSelector([getEetReceipts], (eetReceipts) => eetReceipts.loaded);
export const selectIsEetReceiptsLoading: Selector<IApplicationState, boolean> =
  createSelector([getEetReceipts], (eetReceipts) => eetReceipts.loading);
export const selectHasEetReceiptsError: Selector<IApplicationState, boolean> =
  createSelector([getEetReceipts], (eetReceipts) => eetReceipts.error);
export const selectEetReceipts: Selector<IApplicationState, IEetReceipt[]> =
  createSelector([getEetReceipts], (eetReceipts) => eetReceipts.data);
