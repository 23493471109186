import { Loader } from '@vodafone/red-white';
import React, { FC, useEffect } from 'react';
import ReactDOMServer from 'react-dom/server';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ModalContentProps } from 'src/components/Modal/types/modalContentInterfaces';
import { useAppDispatch } from 'src/hooks/useAppDispatch';
import { createPaymentTransaction } from 'src/store/billing/paymentTransaction/paymentTransactionActions';
import { selectPaymentTransaction } from 'src/store/billing/paymentTransaction/paymentTransactionSelectors';
import IPaymentTransactionState from 'src/store/billing/paymentTransaction/types/paymentTransactionStateInterface';
import IBillingAccount from 'src/store/user/types/billingAccountInterface';
import BillingStatusEnum from 'src/types/billing/billingStatusEnum';
import PaymentMethodEnum from 'src/types/billing/paymentMethodEnum';
import PayDifferentAmount from 'src/ui/components/Billing/PayDifferentAmount';
import { PayDifferentAmountStatus } from 'src/ui/components/Billing/PayDifferentAmount/PayDifferentAmount';
import * as Styled from 'src/ui/components/Billing/PayDifferentAmount/PayDifferentAmount.styled';
import PriceForRenderToString from '../../Price/PriceForRenderToString';

interface IProps extends ModalContentProps {
  options: {
    status: BillingStatusEnum;
    amount: number;
    dueAmount: number;
    month: string;
    dueDate: string;
    billingAccount: IBillingAccount;
  };
}

const DifferentPaymentModalContent: FC<IProps> = ({
  setTransparentBg,
  setRedBg,
  options,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['billing']);
  const [value, setValue] = React.useState('');
  const paymentTransaction: IPaymentTransactionState = useSelector(
    selectPaymentTransaction,
  );

  useEffect(() => {
    setTransparentBg(false);
    setRedBg(false);
  }, [setTransparentBg, setRedBg]);

  const resolveStatus = (
    status: BillingStatusEnum,
  ): PayDifferentAmountStatus => {
    switch (status) {
      case BillingStatusEnum.UNDERPAID:
        return 'warning';
      case BillingStatusEnum.UNPAID_OVERDUE:
      case BillingStatusEnum.UNPAID_INCLUDE_OVERDUE:
      case BillingStatusEnum.UNPAID:
        return 'danger';
      default:
        return 'default';
    }
  };

  const args = {
    title: t('differentPaymentModal.modal-title'),
    input: {
      label: t('differentPaymentModal.input-label'),
      message: t('differentPaymentModal.input-message', { amount: 120 }),
    },
    billing: {
      title: t('differentPaymentModal.billing-title', { month: options.month }),
      amount: options.amount,
      status: resolveStatus(options.status),
      subtitle: t(`paymentInfo.subtitle-${options.status.toUpperCase()}`, {
        amount: ReactDOMServer.renderToString(
          <PriceForRenderToString
            price={
              options.status.toUpperCase() ===
              BillingStatusEnum.UNPAID_INCLUDE_OVERDUE
                ? options.dueAmount
                : options.amount
            }
          />,
        ),
      }),
      due: t('differentPaymentModal.due-date', { date: options.dueDate }),
      buttonText: t('differentPaymentModal.button-text'),
    },
    value,
    setValue,
    onClick: () => {
      dispatch(
        createPaymentTransaction(
          PaymentMethodEnum.ONLINE,
          options.billingAccount.billingAccountNumber,
          parseFloat(value),
        ),
      );
    },
  };

  return (
    <Styled.ModalWrapper>
      <Loader show={paymentTransaction.loading} />
      <PayDifferentAmount {...args} />
    </Styled.ModalWrapper>
  );
};

export default DifferentPaymentModalContent;
