import { createSelector, Selector } from 'reselect';
import { IApplicationState } from '../appStateInterface';
import { ILoadingState } from './types/loadingStateInterface';

const getLoading: Selector<IApplicationState, ILoadingState> = (
  state: IApplicationState,
) => state.Loading;

export const selectShowLoading: Selector<IApplicationState, boolean> =
  createSelector([getLoading], (loading: ILoadingState) => loading.number > 0);
