import { Reducer } from 'redux';
import {
  IESimActivateSimOrESimBeginAction,
  IESimActivateSimOrESimFailureAction,
  IESimActivateSimOrESimResetStoreAction,
  IESimActivateSimOrESimSuccessAction,
  IESimChangeSmsForkSettingsBeginAction,
  IESimChangeSmsForkSettingsFailureAction,
  IESimChangeSmsForkSettingsResetStoreAction,
  IESimChangeSmsForkSettingsSuccessAction,
  IESimDeliveryBeginAction,
  IESimDeliveryFailureAction,
  IESimDeliveryResetStoreAction,
  IESimDeliverySuccessAction,
  IESimDetailResetStoreAction,
  IESimDownloadQRCodeBeginAction,
  IESimDownloadQRCodeFailureAction,
  IESimDownloadQRCodeSuccessAction,
  IESimFetchDetailBeginAction,
  IESimFetchDetailFailureAction,
  IESimFetchDetailSuccessAction,
  IESimFetchOnNumberOneNumbersBeginAction,
  IESimFetchOnNumberOneNumbersFailureAction,
  IESimFetchOnNumberOneNumbersSuccessAction,
  IESimOnNumberPortalLinkFetchBeginAction,
  IESimOnNumberPortalLinkFetchFailureAction,
  IESimOnNumberPortalLinkFetchSuccessAction,
  IESimQRCodeResetStoreAction,
  IESimSimSwapBeginAction,
  IESimSimSwapFailureAction,
  IESimSimSwapResetStoreAction,
  IESimSimSwapSuccessAction,
} from 'src/store/esim/types/esimActionInterfaces';
import ESimActionTypes from 'src/store/esim/types/esimActionTypes';
import initialState from '../initialState';
import { IESimState } from './types/esimStateInterface';

type ESimAction =
  | IESimSimSwapBeginAction
  | IESimSimSwapSuccessAction
  | IESimSimSwapFailureAction
  | IESimSimSwapResetStoreAction
  | IESimDeliveryBeginAction
  | IESimDeliverySuccessAction
  | IESimDeliveryFailureAction
  | IESimDeliveryResetStoreAction
  | IESimFetchDetailBeginAction
  | IESimFetchDetailSuccessAction
  | IESimFetchDetailFailureAction
  | IESimDetailResetStoreAction
  | IESimDownloadQRCodeBeginAction
  | IESimDownloadQRCodeSuccessAction
  | IESimDownloadQRCodeFailureAction
  | IESimQRCodeResetStoreAction
  | IESimActivateSimOrESimBeginAction
  | IESimActivateSimOrESimSuccessAction
  | IESimActivateSimOrESimFailureAction
  | IESimActivateSimOrESimResetStoreAction
  | IESimOnNumberPortalLinkFetchBeginAction
  | IESimOnNumberPortalLinkFetchSuccessAction
  | IESimOnNumberPortalLinkFetchFailureAction
  | IESimFetchOnNumberOneNumbersBeginAction
  | IESimFetchOnNumberOneNumbersFailureAction
  | IESimFetchOnNumberOneNumbersSuccessAction
  | IESimChangeSmsForkSettingsBeginAction
  | IESimChangeSmsForkSettingsSuccessAction
  | IESimChangeSmsForkSettingsFailureAction
  | IESimChangeSmsForkSettingsResetStoreAction;

const esimReducer: Reducer<IESimState, ESimAction> = (
  state: IESimState = initialState.ESim,
  action: ESimAction,
) => {
  switch (action.type) {
    case ESimActionTypes.ESIM_SIM_SWAP_BEGIN:
      return {
        ...state,
        simSwap: {
          eId: action.eId,
          qrCode: !action.eId,
          loading: true,
          loaded: false,
          error: false,
        },
      };

    case ESimActionTypes.ESIM_SIM_SWAP_SUCCESS:
      return {
        ...state,
        simSwap: {
          ...state.simSwap,
          loading: false,
          loaded: true,
          error: false,
        },
      };

    case ESimActionTypes.ESIM_SIM_SWAP_FAILURE:
      return {
        ...state,
        simSwap: {
          ...state.simSwap,
          loading: false,
          loaded: false,
          error: true,
        },
      };

    case ESimActionTypes.ESIM_SIM_SWAP_RESET_STORE:
      return {
        ...state,
        simSwap: initialState.ESim.simSwap,
      };

    case ESimActionTypes.ESIM_DELIVERY_BEGIN:
      return {
        ...state,
        eSimDelivery: {
          eId: action.eId,
          qrCode: !action.eId,
          loading: true,
          loaded: false,
          error: false,
        },
      };

    case ESimActionTypes.ESIM_DELIVERY_SUCCESS:
      return {
        ...state,
        eSimDelivery: {
          ...state.eSimDelivery,
          loading: false,
          loaded: true,
          error: false,
        },
      };

    case ESimActionTypes.ESIM_DELIVERY_FAILURE:
      return {
        ...state,
        eSimDelivery: {
          ...state.eSimDelivery,
          loading: false,
          loaded: false,
          error: true,
        },
      };

    case ESimActionTypes.ESIM_DELIVERY_RESET_STORE:
      return {
        ...state,
        eSimDelivery: initialState.ESim.eSimDelivery,
      };

    case ESimActionTypes.ESIM_FETCH_DETAIL_BEGIN:
      return {
        ...state,
        eSimDetail: {
          ...state.eSimDetail,
          loading: true,
          loaded: false,
          error: false,
        },
      };

    case ESimActionTypes.ESIM_FETCH_DETAIL_SUCCESS:
      return {
        ...state,
        eSimDetail: {
          loading: false,
          loaded: true,
          error: false,
          data: action.payload,
        },
      };

    case ESimActionTypes.ESIM_FETCH_DETAIL_FAILURE:
      return {
        ...state,
        eSimDetail: {
          ...state.eSimDetail,
          loading: false,
          loaded: false,
          error: true,
          data: null,
        },
      };

    case ESimActionTypes.ESIM_DETAIL_RESET_STORE:
      return {
        ...state,
        eSimDetail: initialState.ESim.eSimDetail,
      };

    case ESimActionTypes.ESIM_DOWNLOAD_QR_CODE_BEGIN:
      return {
        ...state,
        qrCode: {
          loading: true,
          loaded: false,
          error: false,
        },
      };

    case ESimActionTypes.ESIM_DOWNLOAD_QR_CODE_SUCCESS:
      return {
        ...state,
        qrCode: {
          loading: false,
          loaded: true,
          error: false,
        },
      };

    case ESimActionTypes.ESIM_DOWNLOAD_QR_CODE_FAILURE:
      return {
        ...state,
        qrCode: {
          loading: false,
          loaded: false,
          error: true,
        },
      };

    case ESimActionTypes.ESIM_QR_CODE_RESET_STORE:
      return {
        ...state,
        qrCode: initialState.ESim.qrCode,
      };

    case ESimActionTypes.ESIM_ACTIVATE_SIM_OR_ESIM_BEGIN:
      return {
        ...state,
        activateSimOrESim: {
          loading: true,
          loaded: false,
          error: false,
        },
      };

    case ESimActionTypes.ESIM_ACTIVATE_SIM_OR_ESIM_SUCCESS:
      return {
        ...state,
        activateSimOrESim: {
          loading: false,
          loaded: true,
          error: false,
        },
      };

    case ESimActionTypes.ESIM_ACTIVATE_SIM_OR_ESIM_FAILURE:
      return {
        ...state,
        activateSimOrESim: {
          loading: false,
          loaded: false,
          error: true,
        },
      };

    case ESimActionTypes.ESIM_ACTIVATE_SIM_OR_ESIM_RESET_STORE:
      return {
        ...state,
        activateSimOrESim: initialState.ESim.activateSimOrESim,
      };

    case ESimActionTypes.ESIM_ONE_NUMBER_PORTAL_LINK_BEGIN:
      return {
        ...state,
        oneNumberPortal: {
          ...state.oneNumberPortal,
          data: {
            ...state.oneNumberPortal.data,
            [action.caNumber]: {
              loading: true,
              loaded: false,
              error: false,
              redirectUrl: null,
            },
          },
        },
      };

    case ESimActionTypes.ESIM_ONE_NUMBER_PORTAL_LINK_SUCCESS:
      return {
        ...state,
        oneNumberPortal: {
          ...state.oneNumberPortal,
          data: {
            ...state.oneNumberPortal.data,
            [action.caNumber]: {
              loading: false,
              loaded: true,
              error: false,
              redirectUrl: action.payload.redirectUrl,
            },
          },
        },
      };

    case ESimActionTypes.ESIM_ONE_NUMBER_PORTAL_LINK_FAILURE:
      return {
        ...state,
        oneNumberPortal: {
          ...state.oneNumberPortal,
          data: {
            ...state.oneNumberPortal.data,
            [action.caNumber]: {
              loading: false,
              loaded: true,
              error: true,
              redirectUrl: null,
            },
          },
        },
      };

    case ESimActionTypes.ESIM_FETCH_ONE_NUMBER_ONE_NUMBERS_BEGIN:
      return {
        ...state,
        oneNumberNumbers: {
          ...state.oneNumberNumbers,
          data: {
            ...state.oneNumberNumbers.data,
            [action.serviceNumber]: {
              loading: true,
              loaded: false,
              error: false,
              oneNumbers: [],
            },
          },
        },
      };

    case ESimActionTypes.ESIM_FETCH_ONE_NUMBER_ONE_NUMBERS_SUCCESS:
      return {
        ...state,
        oneNumberNumbers: {
          ...state.oneNumberNumbers,
          data: {
            ...state.oneNumberNumbers.data,
            [action.serviceNumber]: {
              loading: false,
              loaded: true,
              error: false,
              oneNumbers: action.payload.oneNumbers,
            },
          },
        },
      };

    case ESimActionTypes.ESIM_FETCH_ONE_NUMBER_ONE_NUMBERS_FAILURE:
      return {
        ...state,
        oneNumberNumbers: {
          ...state.oneNumberNumbers,
          data: {
            ...state.oneNumberNumbers.data,
            [action.serviceNumber]: {
              loading: false,
              loaded: true,
              error: true,
              oneNumbers: [],
            },
          },
        },
      };

    case ESimActionTypes.ESIM_CHANGE_SMS_FORK_SETTINGS_BEGIN:
      return {
        ...state,
        smsForkSettings: {
          loading: true,
          loaded: false,
          error: false,
        },
      };

    case ESimActionTypes.ESIM_CHANGE_SMS_FORK_SETTINGS_SUCCESS:
      return {
        ...state,
        smsForkSettings: {
          loading: false,
          loaded: true,
          error: false,
        },
        oneNumberNumbers: {
          ...state.oneNumberNumbers,
          data: {
            ...state.oneNumberNumbers.data,
            [action.serviceNumber]: {
              ...state.oneNumberNumbers.data[action.serviceNumber],
              oneNumbers: state.oneNumberNumbers.data[
                action.serviceNumber
              ].oneNumbers.map((oneNumber) => {
                if (oneNumber.productOfferingId === action.productOfferingId) {
                  // eslint-disable-next-line no-param-reassign
                  oneNumber.smsForking = action.fork;
                }

                return oneNumber;
              }),
            },
          },
        },
      };

    case ESimActionTypes.ESIM_CHANGE_SMS_FORK_SETTINGS_FAILURE:
      return {
        ...state,
        smsForkSettings: {
          loading: false,
          loaded: false,
          error: true,
        },
      };

    case ESimActionTypes.ESIM_CHANGE_SMS_FORK_SETTINGS_RESET_STORE:
      return {
        ...state,
        smsForkSettings: initialState.ESim.smsForkSettings,
      };

    default:
      return state;
  }
};

export default esimReducer;
