import { Title, TitleLevels } from '@vodafone/red-white';
import * as React from 'react';
import * as Styled from './ModalTitle.styled';

interface IProps {
  title: string;
  titleLevel?: TitleLevels;
  icon?: React.ReactNode;
  style?: object;
}

const ModalTitle: React.FC<IProps> = ({
  title,
  icon,
  titleLevel = 5,
  style = {},
}) => (
  <Styled.TitleWrapper>
    {icon && <Styled.IconWrapper>{icon}</Styled.IconWrapper>}
    <Title
      level={titleLevel}
      style={style}
      color="darkGrey"
      fontFamily="VodafoneRg"
      fontWeight="bold"
    >
      {title}
    </Title>
  </Styled.TitleWrapper>
);

export default ModalTitle;
