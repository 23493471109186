import initialState from '../initialState';
import {
  ApplicationActionTypes,
  FETCH_API_BEGIN,
  FETCH_API_FAILURE,
  FETCH_API_RELOAD,
  FETCH_API_SUCCESS,
  HIDE_LOADER,
  SET_CURRENT_PAGE,
  SET_MEDIA_DEVICE,
  SET_PREVIOUS_PAGE,
  SHOW_LOADER,
} from './applicationActionTypes';
import IApplicationState from './types/applicationStateInterface';

export default (
  state: IApplicationState = initialState.Application,
  action: ApplicationActionTypes,
) => {
  switch (action.type) {
    case FETCH_API_SUCCESS: {
      return {
        ...state,
        apiLoading: {
          ...state.apiLoading,
          [action.payload]: {
            name: action.payload,
            loaded: true,
            loading: false,
            error: false,
            needToBeReloaded: false,
          },
        },
      };
    }

    case FETCH_API_BEGIN: {
      return {
        ...state,
        apiLoading: {
          ...state.apiLoading,
          [action.payload]: {
            name: action.payload,
            loading: true,
            loaded: false,
            error: false,
            needToBeReloaded: false,
          },
        },
      };
    }

    case FETCH_API_FAILURE: {
      return {
        ...state,
        apiLoading: {
          ...state.apiLoading,
          [action.payload]: {
            name: action.payload,
            loading: false,
            loaded: true,
            error: true,
            needToBeReloaded: false,
          },
        },
      };
    }

    case FETCH_API_RELOAD: {
      return {
        ...state,
        apiLoading: {
          ...state.apiLoading,
          [action.payload]: {
            name: action.payload,
            loading: false,
            loaded: false,
            error: false,
            needToBeReloaded: true,
          },
        },
      };
    }

    case SHOW_LOADER: {
      return {
        ...state,
        displayLoader: true,
      };
    }

    case HIDE_LOADER: {
      return {
        ...state,
        displayLoader: false,
      };
    }

    case SET_MEDIA_DEVICE: {
      return {
        ...state,
        device: action.payload,
      };
    }

    case SET_PREVIOUS_PAGE: {
      return {
        ...state,
        previousPage: action.payload,
      };
    }

    case SET_CURRENT_PAGE: {
      return {
        ...state,
        currentPage: action.payload,
      };
    }

    default:
      return state;
  }
};
