import { createSelector, Selector } from 'reselect';
import { IApplicationState } from '../appStateInterface';
import { IInactiveUserModalState } from './types/inactiveUserModalStateInterface';

const getState: Selector<IApplicationState, IInactiveUserModalState> = (
  state: IApplicationState,
) => state.InactiveUserModal;

export const isWarningModalVisible: Selector<IApplicationState, boolean> =
  createSelector(
    [getState],
    (inactiveUserModal) => inactiveUserModal.warningModalIsVisible,
  );
