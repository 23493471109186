import {
  Button,
  Card,
  ClosedByEnum,
  IconSpinner,
  Title,
} from '@vodafone/red-white';
import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { ShowPasswordModalBodyContent } from 'src/components/Modal/components/ShowPassword/ShowPasswordModalBodyContent';
import { ModalContentProps } from 'src/components/Modal/types/modalContentInterfaces';
import styled from 'styled-components';

const CustomCardPadding = styled.div`
  padding: 40px 0 0 0;
`;

const ShowPasswordModalContent = ({ onClose, options }: ModalContentProps) => {
  const { t } = useTranslation(['component', 'customerIdentity']);

  return (
    <Card
      style={{
        maxWidth: 550,
        width: '100%',
        boxShadow: 'none',
        textAlign: 'center',
        minHeight: 250,
        marginTop: 0,
        marginBottom: 0,
      }}
    >
      <CustomCardPadding>
        <Title
          color="red"
          fontFamily="VodafoneRg"
          fontWeight="bold"
          level={3}
          style={{ textAlign: 'center', fontSize: 28, padding: '0 0 10px 0' }}
        >
          {t('customerIdentity:passwordModal.title')}
        </Title>
        <Suspense
          fallback={
            <div
              style={{
                display: 'flex',
                minHeight: '110px',
                justifyContent: 'center',
              }}
            >
              <IconSpinner />
            </div>
          }
        >
          <ShowPasswordModalBodyContent
            crmId={options.crmId}
            crmType={options.crmType}
          />
        </Suspense>
        <Button
          variant="primary"
          htmlElement="button"
          onClick={(event) => {
            onClose(event, ClosedByEnum.BUTTON);
          }}
        >
          {t('component:button.close')}
        </Button>
      </CustomCardPadding>
    </Card>
  );
};

export default ShowPasswordModalContent;
