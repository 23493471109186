import { Button, Loader } from '@vodafone/red-white';
import { FC, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { API_ENDPOINT_SERVICE_STOLEN_LOST_UPDATE } from 'src/api';
import { UpdateStolenLostActionEnum } from 'src/api/serviceManagement/request/types/UpdateStolenLostActionEnum';
import ModalMessageTemplate from 'src/components/Modal/components/ModalMessage/ModalMessageTemplate';
import ModalMessageTypeEnum from 'src/components/Modal/components/ModalMessage/types/ModalMessageTypeEnum';
import { ModalContentProps } from 'src/components/Modal/types/modalContentInterfaces';
import { ServiceCodeNameEnum } from 'src/components/Services';
import { useAppDispatch } from 'src/hooks/useAppDispatch';
import { selectApiStatus } from 'src/store/application/applicationSelector';
import { IApiState } from 'src/store/application/types/applicationStateInterface';
import { closeModal } from 'src/store/modal/modalActions';
import ModalType from 'src/store/modal/types/ModalType';
import { updateStolenLost } from 'src/store/services/actions/stolenLostActions';
import { selectServiceByCodename } from 'src/store/services/selectors/serviceSelectors';
import ISubscription from 'src/store/user/types/subscriptionInterface';
import userSelector, {
  selectCurrentSubscription,
} from 'src/store/user/userSelector';
import { ModalTitle } from 'src/ui/components/ModalTitle';
import SanitizedHtml from 'src/utils/sanitizeHtml';
import styled from 'styled-components';

const StyledActionButton = styled(Button)`
  margin-left: 5px;
  margin-right: 5px;
`;

const ModalTitleWrapper = styled.div`
  padding: 0 40px;
`;

const LostStolenConfirmModalContent: FC<ModalContentProps> = ({
  setTitle,
  setButton,
  setDeclineButton,
  options,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['services']);
  const user = useSelector(userSelector);
  const service = useSelector(
    selectServiceByCodename(ServiceCodeNameEnum.STOLEN_LOST),
  );

  // @ts-ignore
  const { loading, loaded, error }: IApiState = useSelector(
    selectApiStatus(API_ENDPOINT_SERVICE_STOLEN_LOST_UPDATE),
  );
  const { serviceNumber }: ISubscription = useSelector(
    selectCurrentSubscription,
  );
  const titleTranslate: string = options.service.active
    ? 'services:lostStolen.deactivate.modal-title'
    : 'services:lostStolen.activate.modal-title';
  const action: UpdateStolenLostActionEnum = options.service.active
    ? UpdateStolenLostActionEnum.DEACTIVATE
    : UpdateStolenLostActionEnum.ACTIVATE;

  const onCloseButtonClick = useCallback(() => {
    dispatch(closeModal({ type: ModalType.LostStolenConfirm }));
  }, [dispatch]);

  useEffect(() => {
    setTitle(
      <ModalTitleWrapper>
        <ModalTitle title={t(titleTranslate)} />
      </ModalTitleWrapper>,
    );

    setButton(
      <StyledActionButton
        variant="primary"
        onClick={() => {
          dispatch(
            updateStolenLost(
              serviceNumber,
              action,
              user.isWebView ? 'mCare' : 'WSC',
              service,
            ),
          );
        }}
      >
        {t('services:lostStolen.buttons.confirm')}
      </StyledActionButton>,
    );

    setDeclineButton(
      <StyledActionButton variant="secondary" onClick={onCloseButtonClick}>
        {t('services:lostStolen.buttons.cancel')}
      </StyledActionButton>,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    action,
    dispatch,
    error,
    loaded,
    onCloseButtonClick,
    options.service.active,
    serviceNumber,
    setButton,
    setDeclineButton,
    setTitle,
    t,
    titleTranslate,
    user,
  ]);

  useEffect(() => {
    if (loaded) {
      setTitle(null);
      setButton(null);
      setDeclineButton(null);
    }
  }, [error, loaded, setButton, setDeclineButton, setTitle]);

  return (
    <>
      {loaded && !error && (
        <ModalMessageTemplate
          type={ModalMessageTypeEnum.SUCCESS}
          heading={t('services:lostStolen.success-title')}
          description={
            <SanitizedHtml
              htmlTag="span"
              rawHtmlData={t('services:lostStolen.success-description')}
            />
          }
        />
      )}

      {loaded && error && (
        <ModalMessageTemplate type={ModalMessageTypeEnum.ERROR} />
      )}
      <Loader show={loading} />
    </>
  );
};

export default LostStolenConfirmModalContent;
