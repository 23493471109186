import { NoticeActionTypesEnum } from './noticeEnums';
import {
  INoticeActionInterface,
  INoticeSettingsInterface,
} from './noticeInterfaces';

export const actionShowNotice = (
  noticeSettings: INoticeSettingsInterface,
): INoticeActionInterface => {
  return {
    type: NoticeActionTypesEnum.SHOW_NOTICE,
    settings: noticeSettings,
  };
};

export const actionHideNotice = (): INoticeActionInterface => {
  return {
    type: NoticeActionTypesEnum.HIDE_NOTICE,
  };
};
