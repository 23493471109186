import { Reducer } from 'redux';
import initialState from '../../initialState';
import {
  IFetchSectionsBeginAction,
  IFetchSectionsFailureAction,
  IFetchSectionsSuccessAction,
  IResetSectionsDataAction,
  IToggleMoreServicesSuccessAction,
} from '../types/section/sectionActionInterfaces';
import { SectionActionTypes } from '../types/section/sectionActionTypes';
import { ISection } from '../types/section/sectionInterface';
import { ISectionState } from '../types/section/sectionStateInterface';
import { IFetchMoreServicesSuccessAction } from '../types/service/serviceActionInterfaces';
import { ServiceActionTypes } from '../types/service/serviceActionTypes';

type SectionAction =
  | IFetchSectionsBeginAction
  | IFetchSectionsSuccessAction
  | IFetchSectionsFailureAction
  | IToggleMoreServicesSuccessAction
  | IResetSectionsDataAction
  | IFetchMoreServicesSuccessAction;

export const sectionReducer: Reducer<ISectionState> = (
  state: ISectionState = initialState.Services.sections,
  action: SectionAction,
) => {
  switch (action.type) {
    case ServiceActionTypes.FETCH_MORE_SERVICES_SUCCESS: {
      return {
        ...state,
        items: state.items.concat(
          action.payload.serviceSections.filter((group: ISection) => {
            return state.items.find((g) => g.id === group.id) ? false : group;
          }),
        ),
      };
    }

    case SectionActionTypes.FETCH_SECTIONS_BEGIN:
      return {
        ...state,
        loading: true,
        loaded: false,
      };

    case SectionActionTypes.FETCH_SECTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        items: action.payload,
      };

    case SectionActionTypes.FETCH_SECTIONS_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        items: [],
      };

    case SectionActionTypes.TOGGLE_MORE_SERVICES_SUCCESS:
      return {
        ...state,
        items: state.items.map((item: ISection) => {
          if (item.id !== action.section.id) {
            return item;
          }

          return {
            ...item,
            isMoreItemsVisible: !action.section.isMoreItemsVisible,
          };
        }),
      };

    case SectionActionTypes.RESET_SECTIONS_DATA:
      return {
        ...state,
        ...initialState.Services.sections,
      };

    default:
      return state;
  }
};

export default sectionReducer;
