import { ICallbackHandler, IInteractionPointJson } from '@vodafone/red-white';
import { ActionCreator } from 'redux';
import {
  getMicrositeData,
  sendMicrositeData,
  sendMicrositeLead,
} from 'src/api/genericMicrosites';
import {
  IField,
  IMicrositeAction,
} from 'src/api/genericMicrosites/request/confirmOffer';
import { ISendLeadAction } from 'src/api/genericMicrosites/request/sendLeadActionInterface';
import { IMicrositeServiceData } from 'src/api/genericMicrosites/response/getMicrositeDataInterface';
import { switchSubscriber } from 'src/api/subscribers';
import { HttpStatusCode } from 'src/enums';
import parseActionValue from 'src/store/genericMicrosite/utils/parseActionValue';
import { genericAnalytics } from 'src/store/services/actions/serviceActions';
import IUserState from 'src/store/user/types/userStateInterface';
import {
  MicrositeFlow,
  MicrositeFlowState,
  MicrositeSummaryFlow,
} from 'src/types/genericMicrosite/genericMicoristeFlows';
import isAxiosError from 'src/utils/api/guards/isAxiosError';
import { mergeObjectWithResponseParams } from 'src/utils/interationPointJson';
import { genericMicrositesSelector } from './genericMicrositesSelectors';
import {
  IGenericMicrositeAction,
  ISetFlowState,
} from './types/genericMicrositesActionInterafces';
import GenericMicrositesActionTypes from './types/genericMicrositesActionTypes';
import {
  IGenericMicrositesState,
  IMicrositeParams,
} from './types/genericMicrositesStateInterface';

export const startLoading: ActionCreator<IGenericMicrositeAction> = () => ({
  type: GenericMicrositesActionTypes.START_LOADING,
});

export const storeMSData: ActionCreator<IGenericMicrositeAction> = (
  data: IMicrositeServiceData,
  micrositeParams: IMicrositeParams,
) => ({
  type: GenericMicrositesActionTypes.STORE_MS_DATA,
  data,
  micrositeParams,
});

export const setError: ActionCreator<IGenericMicrositeAction> = (
  errorMessage: string,
) => ({
  type: GenericMicrositesActionTypes.STORE_MS_DATA_ERROR,
  errorMessage,
});

export const setParamsToOffers: ActionCreator<IGenericMicrositeAction> = (
  addData: IInteractionPointJson,
  filterObject?: IInteractionPointJson,
) => ({
  type: GenericMicrositesActionTypes.SET_PARAMS_TO_OFFERS,
  addData,
  filterObject,
});

export const setFlowState: ActionCreator<ISetFlowState> = (
  flowState: MicrositeFlowState,
) => ({
  type: GenericMicrositesActionTypes.SET_FLOW_STATE,
  flowState,
});

export const fetchMSData =
  (micrositeParams: IMicrositeParams) => async (dispatch) => {
    const { module, submodule, lang, visitGmHash } = micrositeParams;

    dispatch(startLoading());

    return getMicrositeData(module, submodule, lang, visitGmHash)
      .then((response) => {
        dispatch(storeMSData(response.data, micrositeParams));
      })
      .catch((error: any) => {
        if (
          error !== undefined &&
          isAxiosError(error) &&
          error.response !== undefined &&
          error.response.status > 399 &&
          error.response.status < HttpStatusCode.INTERNAL_SERVER_ERROR_500
        ) {
          dispatch(setError('not-found'));
          return;
        }
        dispatch(setError('server-problem'));
      });
  };

export const toggleParam =
  ({ targetElement, jsonDataStack }: ICallbackHandler) =>
  async (dispatch) => {
    const senderButton: HTMLButtonElement = targetElement as HTMLButtonElement;
    const { name } = senderButton;

    if (!name || !jsonDataStack || !jsonDataStack[0]) return;

    const { dataIndex } = jsonDataStack[0];
    const actualChceckValue = jsonDataStack[0][name];

    let filter: IInteractionPointJson;

    if (dataIndex) {
      filter = { dataIndex };
    }

    dispatch(setParamsToOffers({ [name]: !actualChceckValue }, filter));
  };

export const goBackInFlow = () => async (dispatch, getState) => {
  const { micrositeFlowState, micrositeFlow }: IGenericMicrositesState =
    genericMicrositesSelector(getState());

  if (micrositeFlow === MicrositeFlow.SUMMARY_FLOW) {
    // eslint-disable-next-line default-case
    switch (micrositeFlowState) {
      case MicrositeSummaryFlow.THANK_YOU_PAGE:
        dispatch(setFlowState(MicrositeSummaryFlow.SUMMARY));
        break;
      case MicrositeSummaryFlow.SUMMARY:
        dispatch(setFlowState(MicrositeSummaryFlow.INITIAL));
        dispatch(setParamsToOffers({ activated: false }, { activated: true }));
        break;
    }
  }
};

/**
 * Prepare paramaters
 * @param callbackData
 */
const prepareMicrositeActionsData = (
  callbackData: ICallbackHandler,
): IMicrositeAction => {
  // first in stack -> deepes child data

  const fields: IField[] = [];
  // merge form data - the top on stack (deepest) fields should override upper fieds
  // so attributes "first" in array, should override "latter" attributes
  const formFields = callbackData.formDataStack.reduce(
    (accummulate, current) => Object.assign({}, accummulate, current),
    {},
  );

  if (formFields) {
    Object.keys(formFields).forEach((fieldParam) => {
      fields.push({
        name: fieldParam,
        value: `${formFields[fieldParam]}`,
      });
    });
  }

  return {
    identifier: callbackData.componentId,
    formFields: fields,
  };
};

const postMicrositesData = (
  actionData: IMicrositeAction,
  micrositeParams: IMicrositeParams,
  filter: IInteractionPointJson,
  dispatch,
) => {
  sendMicrositeData(
    actionData,
    micrositeParams.module,
    micrositeParams.submodule,
  )
    .then((response) => {
      if (response.data.activated) {
        dispatch(
          setParamsToOffers(
            mergeObjectWithResponseParams(
              {
                activated: true,
                loading: false,
                activationRequested: true,
              },
              response.data.parameters,
            ),
            filter,
          ),
        );
      } else {
        dispatch(
          setParamsToOffers(
            mergeObjectWithResponseParams(
              {
                actionError: true,
                loading: false,
                activationRequested: true,
              },
              response.data.parameters,
            ),
            filter,
          ),
        );
      }
    })
    .catch(() => {
      dispatch(
        setParamsToOffers(
          { actionError: true, loading: false, activationRequested: true },
          filter,
        ),
      );
    });
};

const handleDirectActionButton = (
  callbackData: ICallbackHandler,
  state: IGenericMicrositesState,
  dispatch,
) => {
  const { micrositeParams }: IGenericMicrositesState = state;

  // first in stack -> deepes child data
  const deepestChildParams = callbackData.jsonDataStack[0];
  // get index only from the deepest data (top of stack)
  const { dataIndex } = deepestChildParams;

  let filter: IInteractionPointJson;

  if (dataIndex) {
    filter = { dataIndex };
  }

  let setValues = { loading: true };
  const valueObject = parseActionValue(
    callbackData.targetElement.getAttribute('value'),
  );

  if (valueObject === undefined) {
    setValues = Object.assign(setValues, { firstDirectActionTriggered: true });
  } else {
    setValues = Object.assign(setValues, valueObject.set);
  }

  dispatch(setParamsToOffers(setValues, filter));

  sendPurchaseAnalyticsFromOfferConfig(deepestChildParams, dispatch);

  return postMicrositesData(
    prepareMicrositeActionsData(callbackData),
    micrositeParams,
    filter,
    dispatch,
  );
};

const sendPurchaseAnalyticsFromOfferConfig = (offerConfig: any, dispatch) => {
  const products = offerConfig['purchaseAnalytics.products'] ?? null;
  const events = offerConfig['purchaseAnalytics.events'] ?? null;
  const pagename = offerConfig['purchaseAnalytics.pagename'] ?? null;
  const list1 = offerConfig['purchaseAnalytics.list1'] ?? null;

  if (!products && !events && !pagename && !list1) {
    return;
  }

  dispatch(genericAnalytics(products, events, pagename, list1));
};

const handleSummaryFlowActionButton = (
  callbackData: ICallbackHandler,
  state: IGenericMicrositesState,
  dispatch,
) => {
  const { micrositeFlowState }: IGenericMicrositesState = state;

  const dataIndex: string =
    callbackData.jsonDataStack[0] && callbackData.jsonDataStack[0].dataIndex;

  let filter: IInteractionPointJson = {};

  if (dataIndex) {
    filter = { dataIndex };
  }

  /**
   * Set offer as picked (activated)
   */
  dispatch(
    setParamsToOffers(
      { activated: true, loading: false, activationRequested: true },
      filter,
    ),
  );

  /**
   * set flow state
   */
  switch (micrositeFlowState) {
    case MicrositeSummaryFlow.INITIAL:
      dispatch(setFlowState(MicrositeSummaryFlow.SUMMARY));
      break;
    case MicrositeSummaryFlow.SUMMARY:
      handleDirectActionButton(callbackData, state, dispatch);
      dispatch(setFlowState(MicrositeSummaryFlow.THANK_YOU_PAGE));
      break;
    default:
      break;
  }
};

export const handleDefaultButtonAction =
  (callbackData: ICallbackHandler) => async (dispatch, getState) => {
    const genericMicrositesState: IGenericMicrositesState =
      genericMicrositesSelector(getState());

    switch (genericMicrositesState.micrositeFlow) {
      case MicrositeFlow.SUMMARY_FLOW:
        handleSummaryFlowActionButton(
          callbackData,
          genericMicrositesState,
          dispatch,
        );
        break;
      default:
        handleDirectActionButton(
          callbackData,
          genericMicrositesState,
          dispatch,
        );
        break;
    }
  };

export const leadStartSending: ActionCreator<IGenericMicrositeAction> = () => ({
  type: GenericMicrositesActionTypes.LEAD_START_SENDING,
});

export const leadStoreResult: ActionCreator<IGenericMicrositeAction> = (
  result: boolean,
) => ({
  type: GenericMicrositesActionTypes.LEAD_STORE_RESULT,
  result,
});

export const sendLead =
  (phoneNumber: string, micrositeParams: IMicrositeParams) =>
  async (dispatch) => {
    const { module, submodule } = micrositeParams;

    dispatch(leadStartSending());

    const action: ISendLeadAction = {
      formFields: [
        {
          name: 'phoneNumber',
          value: phoneNumber,
        },
      ],
    };

    return sendMicrositeLead(action, module, submodule)
      .then((response) => {
        dispatch(leadStoreResult(response.data.success));
      })
      .catch((error: any) => {
        if (
          error !== undefined &&
          isAxiosError(error) &&
          error.response.status > 399 &&
          error.response.status < 500
        ) {
          dispatch(leadStoreResult(false));
        } else {
          dispatch(leadStoreResult(false));
        }
      });
  };

export const setForcePageReload: ActionCreator<
  IGenericMicrositeAction
> = () => ({
  type: GenericMicrositesActionTypes.SET_FORCE_PAGE_RELOAD,
});

export const switchSubscription =
  (requestedServiceIdent: string, user: IUserState) => async (dispatch) => {
    dispatch(startLoading());

    if (user.subscription.serviceNumber === requestedServiceIdent) {
      dispatch(setForcePageReload());
      return;
    }

    if (
      user.subscriptions.filter(
        (value) => value.serviceNumber === requestedServiceIdent,
      ).length !== 1
    ) {
      dispatch(setError('wrong-srvc-ident'));
      return;
    }

    const data = new FormData();

    data.append('msisdn', requestedServiceIdent);

    switchSubscriber(data)
      .then((response) => {
        if (response !== undefined && response.data === 'ok') {
          dispatch(setForcePageReload());
        } else {
          dispatch(setError('switching-failed'));
        }
      })
      .catch((error: any) => {
        if (
          error !== undefined &&
          isAxiosError(error) &&
          error.response !== undefined &&
          error.response.status > 399 &&
          error.response.status < 500
        ) {
          dispatch(setError('switching-failed'));
          return;
        }
        dispatch(setError('switching-failed-server'));
      });
  };
