import {
  Button,
  ClosedByEnum,
  IconAdminAtSign,
  IconTickNew,
} from '@vodafone/red-white';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalContentProps } from 'src/components/Modal/types/modalContentInterfaces';
import { RoutesEnum } from 'src/config/routes';
import { useAppDispatch } from 'src/hooks/useAppDispatch';
import { loadingStart } from 'src/store/loading/loadingActions';
import { redirect } from 'src/utils/redirect';
import SanitizedHtml from 'src/utils/sanitizeHtml';
import styled from 'styled-components';

const ContainerWrapper = styled.div`
  margin-top: -20px;

  @media ${(props) => props.theme.breakpoints.maxLg} {
    margin-top: -50px;
  }

  @media ${(props) => props.theme.breakpoints.smOnly} {
    margin-top: 0;
  }
`;

const IconWrapper = styled.div`
  text-align: center;
`;

const ButtonWrapper = styled.div`
  padding: 5px;
  display: inline-block;
`;

const Title = styled.div`
  text-align: center;
  font-size: 33px;
  font-family: ${(props) => props.theme.fonts.VodafoneLt};
  margin: 20px 0 0 0;

  @media ${(props) => props.theme.breakpoints.maxLg} {
    margin: 0;
    font-size: 25px;
  }
`;

const SubTitle = styled.div`
  text-align: center;
  font-size: 30px;
  font-family: ${(props) => props.theme.fonts.VodafoneLt};
  margin: 20px 0 30px 0;

  @media ${(props) => props.theme.breakpoints.maxLg} {
    margin: 0 0 20px 0;
    font-size: 22px;
  }
`;

const StyledUl = styled.ul`
  text-align: center;
  margin-bottom: 30px;

  @media ${(props) => props.theme.breakpoints.maxLg} {
    margin-bottom: -10px;
  }
`;

const StyledLi = styled.li`
  position: relative:
`;

const StyledLiWrapper = styled.span`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  padding: 0 15px;
  font-size: 21px;

  @media ${(props) => props.theme.breakpoints.maxLg} {
    font-size: 16px;
  }
`;

const CustomerIdentityLoginBannerModalContent: FC<ModalContentProps> = ({
  setButton,
  onClose,
}) => {
  const { t } = useTranslation('customerIdentity');
  const dispatch = useAppDispatch();

  useEffect(() => {
    setButton(
      <ButtonWrapper data-s-object-id="nastavit-prihlaseni">
        <Button
          onClick={(event) => {
            dispatch(loadingStart());
            onClose(event, ClosedByEnum.BUTTON, {
              buttonText: t('customerIdentity:modal-banner.buttons.success'),
            });

            setTimeout(() => {
              redirect({
                internalRouteId: RoutesEnum.PERSONAL_ACCOUNT_REQUEST_INVITE,
              });
            }, 500);
          }}
          variant="primary"
        >
          {t('customerIdentity:modal-banner.buttons.success')}
        </Button>
      </ButtonWrapper>,
    );

    // eslint-disable-next-line
  }, [t]);

  return (
    <ContainerWrapper>
      <IconWrapper>
        <IconAdminAtSign size={80} />
      </IconWrapper>

      <Title>
        <SanitizedHtml
          htmlTag="span"
          rawHtmlData={t('customerIdentity:modal-banner.title')}
        />
      </Title>
      <SubTitle>
        <SanitizedHtml
          htmlTag="span"
          rawHtmlData={t('customerIdentity:modal-banner.subtitle')}
        />
      </SubTitle>

      <StyledUl>
        <StyledLi>
          <StyledLiWrapper>
            <IconTickNew color="red" size={18} />
            {t('customerIdentity:modal-banner.list.item-1')}
          </StyledLiWrapper>
        </StyledLi>
        <StyledLi>
          <StyledLiWrapper>
            <IconTickNew color="red" size={18} />
            {t('customerIdentity:modal-banner.list.item-2')}
          </StyledLiWrapper>
        </StyledLi>
        <StyledLi>
          <StyledLiWrapper>
            <IconTickNew color="red" size={18} />
            {t('customerIdentity:modal-banner.list.item-3')}
          </StyledLiWrapper>
        </StyledLi>
      </StyledUl>
    </ContainerWrapper>
  );
};

export default CustomerIdentityLoginBannerModalContent;
