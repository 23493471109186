import { Container, Message, Text } from '@vodafone/red-white';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'src/hooks/useAppDispatch';
import { setPageAnalytics } from 'src/utils/analytics/actions/analyticsActions';
import SanitizedHtml from 'src/utils/sanitizeHtml';

interface IProps {
  path: string;
}

const EshopDisabledPage: React.FC<IProps> = ({ path }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation('eshop');

  React.useEffect(() => {
    dispatch(setPageAnalytics(path));
  }, [dispatch, path]);

  return (
    <Container style={{ width: 640 }}>
      <Message
        type="error-orange"
        message={
          <Text fontSize={16} color="darkGrey">
            <SanitizedHtml htmlTag="p" rawHtmlData={t('disabledPage')} />
          </Text>
        }
      />
    </Container>
  );
};

export default EshopDisabledPage;
