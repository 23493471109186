enum BillingStatusEnum {
  OVERPAID = 'OVERPAID',
  PAID = 'PAID',
  SUFFICIENTLY_PAID = 'SUFFICIENTLY_PAID',
  DO_NOT_PAY = 'DO_NOT_PAY',
  UNPAID_OVERDUE = 'UNPAID_OVERDUE',
  UNPAID_INCLUDE_OVERDUE = 'UNPAID_INCLUDE_OVERDUE',
  UNDERPAID = 'UNDERPAID',
  UNPAID = 'UNPAID',
  IN_CREATION = 'IN_CREATION',
}

export default BillingStatusEnum;
