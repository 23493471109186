import {
  Button,
  IconErrorMid,
  IconSuccess,
  Loader,
  Text,
  Title,
} from '@vodafone/red-white';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import IVtvServicesState from 'src/store/vtvServices/types/vtvServicesStateInterface';
import { getVtvServices } from 'src/store/vtvServices/vtvServicesSelectors';
import styled from 'styled-components';
import { ModalContentProps } from '../types/modalContentInterfaces';

const ModalContent = styled.div`
  margin-top: 0px;
`;

const ResultBase = styled.div`
  text-align: center;
  padding: 0 20px;
`;

const IconSpacing = styled.div`
  margin-bottom: 25px;
`;

const TitleSpacing = styled.div`
  margin-bottom: 16px;
`;

const SubtitleSpacing = styled.div`
  margin-bottom: 32px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

type VtvChangePasswordStatus = 'success' | 'error';

interface ICardReceivedPaymentsResultProps {
  status?: VtvChangePasswordStatus;
  title?: React.ReactNode;
  subTitle?: React.ReactNode;
  onClose?: () => void;
}

const statusIcon = {
  success: <IconSuccess size={76} />,
  error: <IconErrorMid size={76} />,
};

const VtvChangePasswordStatusResult: React.FC<
  ICardReceivedPaymentsResultProps
> = ({ status, title, subTitle, onClose }) => {
  const { t } = useTranslation();

  return (
    <ResultBase>
      <IconSpacing>{statusIcon[status]}</IconSpacing>
      <TitleSpacing>
        <Title fontSize={24} color="darkGrey">
          {title}
        </Title>
      </TitleSpacing>
      <SubtitleSpacing>
        <Text fontSize={16} color="darkGrey">
          {subTitle}
        </Text>
      </SubtitleSpacing>
      <ButtonWrapper>
        <Button onClick={onClose} variant="primary">
          {t('component:button.close')}
        </Button>
      </ButtonWrapper>
    </ResultBase>
  );
};

const VtvSetPasswordStatusModalContent: FC<ModalContentProps> = ({
  onClose,
  setTitle,
}) => {
  useEffect(() => {
    setTitle(null);
  }, [setTitle]);

  const { t } = useTranslation(['services', 'error']);
  const { setPassword }: IVtvServicesState = useSelector(getVtvServices);

  return (
    <ModalContent>
      <Loader show={setPassword.loading} />
      {setPassword.loaded && (
        <VtvChangePasswordStatusResult
          title={t('services:vtv.change-password-success-modal')}
          subTitle={t('services:vtv.change-password-success-other-modal')}
          status="success"
          onClose={onClose}
        />
      )}
      {setPassword.error && (
        <VtvChangePasswordStatusResult
          title={t('error:api-request')}
          status="error"
          onClose={onClose}
        />
      )}
    </ModalContent>
  );
};

export default VtvSetPasswordStatusModalContent;
