import { Reducer } from 'redux';
import {
  IMteChangeBeginAction,
  IMteChangeFailureAction,
  IMteChangeSuccessAction,
} from 'src/store/mteChange/types/mteChangeActionInterfaces';
import { MteChangeActionTypes } from 'src/store/mteChange/types/mteChangeActionTypes';
import { IMteChangeState } from 'src/store/mteChange/types/mteChangeStateInterface';
import initialState from '../initialState';

type MteChangeAction =
  | IMteChangeBeginAction
  | IMteChangeSuccessAction
  | IMteChangeFailureAction;

const mteChangeReducer: Reducer<IMteChangeState> = (
  state: IMteChangeState = initialState.MteChange,
  action: MteChangeAction,
) => {
  switch (action.type) {
    case MteChangeActionTypes.POST_MTE_CHANGE_BEGIN:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      };

    case MteChangeActionTypes.POST_MTE_CHANGE_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };

    case MteChangeActionTypes.POST_MTE_CHANGE_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };

    default:
      return state;
  }
};

export default mteChangeReducer;
