import { useQuery } from '@tanstack/react-query';
import api from 'src/utils/api/api';

export const GET_MARKETPLACE_CODES_ENDPOINT = '/muj/rapi/marketplace/codes';
export const MARKETPLACE_CODES_KEY = 'getMarketplaceCodes';

type GetMarketplaceCodesResponse = {
  discounts: { serviceNumber: string; code: null | string }[];
};

const getMarketplaceCodes = async (): Promise<GetMarketplaceCodesResponse> => {
  const response = await api.get({ url: GET_MARKETPLACE_CODES_ENDPOINT });

  return response.data;
};

export const useMarketplaceCodes = () => {
  return useQuery({
    queryFn: () => getMarketplaceCodes(),
    queryKey: [MARKETPLACE_CODES_KEY],
  });
};
