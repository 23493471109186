import { IInteractionPointJson } from '@vodafone/red-white';
import { IMicrositeServiceData } from 'src/api/genericMicrosites/response/getMicrositeDataInterface';
import {
  MicrositeFlow,
  MicrositeFlowState,
} from '../../../types/genericMicrosite/genericMicoristeFlows';
import { LanguagesEnum } from '../../../types/translation/languagesEnum';

export interface IMicrositeParams {
  module: string;
  submodule?: string;
  lang?: LanguagesEnum;
  visitGmHash?: string;
}

export interface IMicrositeLeadState {
  leadSent: boolean;
  leadSentLoading: boolean;
  leadSentSuccess: boolean;
}

export interface IGenericMicrositesState extends IMicrositeServiceData {
  errorMessage?: string;
  micrositeParams: IMicrositeParams;
  micrositeFlow: MicrositeFlow;
  micrositeFlowState: MicrositeFlowState;
  micrositeLeadState: IMicrositeLeadState;
  loading: boolean;
  forcePageReload: boolean;
}

export const INITIAL_MS_PROPS: IInteractionPointJson = {
  firstDirectActionTriggered: false,
  activated: false,
  actionError: false,
  serverError: false,
  activationRequested: false,
};
