import { FC } from 'react';
import currencyResolver from 'src/components/Currency/utils/resolvers';

interface ICurrency {
  quantity: string | number;
  currency?: string;
  pattern?: string;
}

const Currency: FC<ICurrency> = ({ quantity, currency = 'CZK', pattern }) => {
  const formatterFunction = currencyResolver({
    pattern,
    symbol: currency,
  });

  return formatterFunction(quantity);
};

export default Currency;
