import { ActionCreator } from 'redux';
import {
  IFetchSectionsBeginAction,
  IFetchSectionsFailureAction,
  IFetchSectionsSuccessAction,
  IResetSectionsDataAction,
  IToggleMoreServicesSuccessAction,
} from '../types/section/sectionActionInterfaces';
import { SectionActionTypes } from '../types/section/sectionActionTypes';
import { ISection } from '../types/section/sectionInterface';

export const fetchGroupsBegin: ActionCreator<
  IFetchSectionsBeginAction
> = () => ({
  type: SectionActionTypes.FETCH_SECTIONS_BEGIN,
});

export const fetchGroupsSuccess: ActionCreator<IFetchSectionsSuccessAction> = (
  payload: ISection[],
) => ({
  type: SectionActionTypes.FETCH_SECTIONS_SUCCESS,
  payload,
});

export const fetchGroupsFailure: ActionCreator<
  IFetchSectionsFailureAction
> = () => ({
  type: SectionActionTypes.FETCH_SECTIONS_FAILURE,
});

export const clearGroupsData: ActionCreator<IResetSectionsDataAction> = () => ({
  type: SectionActionTypes.RESET_SECTIONS_DATA,
});

export const onToggleMoreServicesSuccess: ActionCreator<
  IToggleMoreServicesSuccessAction
> = (section: ISection) => ({
  type: SectionActionTypes.TOGGLE_MORE_SERVICES_SUCCESS,
  section,
});
