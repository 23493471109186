import { VerificationSecureMessageType } from 'src/api/mfa/request/verificationSecureRequest';
import { MfaGenericErrorContent } from 'src/components/Modal/components/Mfa/ErrorContents/MfaGenericErrorContent';

/**
 **************** IMPORTANT ***************
 *
 * For more informations
 * see section How to add MFA modal
 * in README.md (root folder)
 *
 * *************** IMPORTANT ***************
 */
const MfaContentMap = {
  [VerificationSecureMessageType.ESIM_SWAP]: MfaGenericErrorContent,
  [VerificationSecureMessageType.ONE_NUMBER]: MfaGenericErrorContent,
};

export default MfaContentMap;
