import {
  Card,
  DescriptionList,
  DescriptionListItem,
  IconEsim2,
  Link,
  Message,
  Title,
} from '@vodafone/red-white';
import { FC, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { VerificationSecureMessageType } from 'src/api/mfa/request/verificationSecureRequest';
import { ESimActivationMethods } from 'src/components/Modal/components/ESim/enums/ESimModalEnum';
import { ModalContentProps } from 'src/components/Modal/types/modalContentInterfaces';
import { useCoreConfig } from 'src/features/core';
import { useAppDispatch } from 'src/hooks/useAppDispatch';
import { useMfa } from 'src/hooks/useMfa';
import {
  eSimDeliveryResetStore,
  eSimSimSwapResetStore,
} from 'src/store/esim/esimActions';
import { openModal } from 'src/store/modal/modalActions';
import ModalType from 'src/store/modal/types/ModalType';
import SanitizedHtml from 'src/utils/sanitizeHtml';
import { formatServiceNumber } from 'src/utils/serviceNumberFormatter';
import styled, { ThemeContext } from 'styled-components';

export type RequestFormValues = {
  contactNumber: string;
};

const CustomCardPadding = styled.div`
  padding: 40px 0 0 0;
`;

const DescriptionListWrapper = styled.div`
  margin-top: 30px;
`;

const Description = styled.div`
  font-size: 14px;
  color: ${(props) => props.theme.colors.grayMid3};
  margin-bottom: 20px;
  margin-top: 5px;
`;

const DescriptionFirst = styled.div`
  font-size: 14px;
  color: ${(props) => props.theme.colors.grayMid3};
  margin-top: 5px;
`;

const MessageWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const LinkText = styled(Link)`
  font-size: 16px;
  display: inline;
`;

const TransferESimContent: FC<ModalContentProps> = ({
  onClose,
  setCompact,
  setCompactContent,
  options,
}) => {
  const themeContext = useContext(ThemeContext);
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['esim']);
  const config = useCoreConfig();

  useEffect(() => {
    setCompact(true);
    setCompactContent(true);
    dispatch(eSimSimSwapResetStore());
    dispatch(eSimDeliveryResetStore());
  }, [setCompactContent, setCompact, dispatch]);

  const { verify } = useMfa({
    messageType: VerificationSecureMessageType.ESIM_SWAP,
    onSuccessCallback: () => openOrderModal(),
  });

  const openOrderModal = () => {
    onClose();
    dispatch(eSimDeliveryResetStore());

    dispatch(
      openModal({
        type: ModalType.OrderNewESim,
        options: {
          serviceNumber: options.serviceNumber,
          defaultMethod: ESimActivationMethods.QR_CODE,
        },
      }),
    );
  };

  return (
    <Card
      style={{ maxWidth: 700, width: '100%', boxShadow: 'none' }}
      title={
        <>
          <IconEsim2 size={20} color={themeContext.colors.darkGrey} />
          <Title
            color="darkGrey"
            fontFamily="VodafoneRg"
            fontWeight="bold"
            level={3}
            style={{ marginLeft: 8 }}
          >
            {t('esim:transferESim.title', {
              serviceNumber: formatServiceNumber(options.serviceNumber),
            })}
          </Title>
        </>
      }
    >
      <CustomCardPadding>
        <Title
          color="darkGrey"
          fontFamily="VodafoneRg"
          fontWeight="bold"
          fontSize={26}
        >
          {t('esim:transferESim.sub-title')}
        </Title>

        <DescriptionListWrapper>
          <DescriptionList>
            <DescriptionListItem
              title={
                <SanitizedHtml
                  htmlTag="span"
                  rawHtmlData={t('esim:transferESim.step-1.title')}
                />
              }
            >
              <DescriptionFirst>&nbsp;</DescriptionFirst>
            </DescriptionListItem>

            <DescriptionListItem
              title={
                <SanitizedHtml
                  htmlTag="span"
                  rawHtmlData={t('esim:transferESim.step-2.title')}
                />
              }
            >
              <Description>
                {t('esim:transferESim.step-2.description')}
              </Description>
            </DescriptionListItem>

            <DescriptionListItem
              title={
                <SanitizedHtml
                  htmlTag="span"
                  rawHtmlData={t('esim:transferESim.step-3.title')}
                />
              }
            >
              <Description>
                {t('esim:transferESim.step-3.description')}
              </Description>
            </DescriptionListItem>
          </DescriptionList>
        </DescriptionListWrapper>

        <MessageWrapper>
          <Message
            type="info"
            message={
              config.data.isEsimOrderServicesEnabled ? (
                <span>
                  {t('esim:transferESim.description')}
                  <LinkText onClick={() => verify()}>
                    {t('esim:transferESim.button')}
                  </LinkText>
                  {t('esim:transferESim.dot')}
                </span>
              ) : (
                <span>{t('esim:transferESim.descriptionDisabled')}</span>
              )
            }
          />
        </MessageWrapper>
      </CustomCardPadding>
    </Card>
  );
};

export default TransferESimContent;
