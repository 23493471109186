import { AxiosResponse } from 'axios';
import * as React from 'react';
import { ActionCreator } from 'redux';
import { eSimStateEnum } from 'src/api/esim/response/eSimStateEnum';
import {
  changeService,
  getEligibleServicesList,
  getPreloadAllServices,
  getServiceManagementContent,
  getServicesOverview,
  getVirtualBundle,
} from 'src/api/serviceManagement';
import { IGetServicesResponse } from 'src/api/serviceManagement/response/getServicesResponseInterface';
import { ServiceCodeNameEnum } from 'src/components';
import { initJqueryServiceDetail } from 'src/components/Services/utils/jqueryFunctions';
import { isEligibleForChange } from 'src/components/Services/utils/services';
import { openModal } from 'src/store/modal/modalActions';
import ModalType from 'src/store/modal/types/ModalType';
import ISubscription from 'src/store/user/types/subscriptionInterface';
import { selectCurrentSubscription } from 'src/store/user/userSelector';
import { BssServicesEnums } from 'src/types/services/bssServicesEnums';
import { searchParamInUrl } from 'src/utils/url';
import { redirect } from '../../../utils/redirect';
import { scrollTo } from '../../../utils/ui/scrollToActions';
import languageSelector from '../../language/languageSelector';
import { loadingStart, loadingStop } from '../../loading/loadingActions';
import { ISection } from '../types/section/sectionInterface';
import {
  IClearServicesDataAction,
  IExpandGroupAfterMoreServicesLoadedBeginAction,
  IExpandGroupAfterMoreServicesLoadedSuccessAction,
  IExpandServiceAfterBssAndWscLoadedBeginAction,
  IExpandServiceAfterBssAndWscLoadedSuccessAction,
  IFetchAllServicesBeginAction,
  IFetchAllServicesFailureAction,
  IFetchAllServicesSuccessAction,
  IFetchMoreServicesBeginAction,
  IFetchMoreServicesFailureAction,
  IFetchMoreServicesSuccessAction,
  IFetchServiceDetailBeginAction,
  IFetchServiceDetailFailureAction,
  IFetchServiceDetailProcessingAction,
  IFetchServiceDetailSuccessAction,
  IFetchServicesBeginAction,
  IFetchServicesFailureAction,
  IFetchServicesSuccessAction,
  IGenericAnalyticsAction,
  ILoadServiceBeginAction,
  ILoadServiceFailureAction,
  ILoadServiceSuccessAction,
  ILockServiceManagementAction,
  IReloadServiceAction,
  IServiceAjaxError,
  IStartServiceLoadingAction,
  IStopServiceLoadingAction,
  IToggleServiceDetailAction,
} from '../types/service/serviceActionInterfaces';
import { ServiceActionTypes } from '../types/service/serviceActionTypes';
import { IService } from '../types/service/serviceInterface';
import {
  fetchManagementEligibilityBegin,
  fetchManagementEligibilityFailure,
  fetchManagementEligibilitySuccess,
} from './managementEligibilityActions';
import {
  fetchGroupsBegin,
  fetchGroupsFailure,
  fetchGroupsSuccess,
  onToggleMoreServicesSuccess,
} from './sectionActions';
import {
  fetchTariffOverviewBegin,
  fetchTariffOverviewFailure,
  fetchTariffOverviewSuccess,
} from './tariffOverviewActions';
import {
  fetchVirtualBundleBegin,
  fetchVirtualBundleFailure,
  fetchVirtualBundleSuccess,
} from './virtualBundleActions';

// more services
export const fetchMoreServicesBegin: ActionCreator<
  IFetchMoreServicesBeginAction
> = () => ({
  type: ServiceActionTypes.FETCH_MORE_SERVICES_BEGIN,
});

export const fetchMoreServicesSuccess: ActionCreator<
  IFetchMoreServicesSuccessAction
> = (payload: IGetServicesResponse) => ({
  type: ServiceActionTypes.FETCH_MORE_SERVICES_SUCCESS,
  payload,
});

export const fetchMoreServicesFailure: ActionCreator<
  IFetchMoreServicesFailureAction
> = () => ({
  type: ServiceActionTypes.FETCH_MORE_SERVICES_FAILURE,
});

// services
export const fetchServiceDetailBegin: ActionCreator<
  IFetchServiceDetailBeginAction
> = (service: IService) => ({
  type: ServiceActionTypes.FETCH_SERVICES_DETAIL_BEGIN,
  service,
});

export const fetchServiceDetailSuccess: ActionCreator<
  IFetchServiceDetailSuccessAction
> = (service: IService, detail: string) => ({
  type: ServiceActionTypes.FETCH_SERVICES_DETAIL_SUCCESS,
  service,
  detail,
  analytics: true,
});

export const genericAnalytics: ActionCreator<IGenericAnalyticsAction> = (
  products: string,
  events: string,
  pageName: string,
  list1: string,
) => ({
  type: ServiceActionTypes.GENERIC_ANALYTICS_ACTION,
  products,
  events,
  pageName,
  list1,
  analytics: true,
});

export const fetchServiceDetailFailure: ActionCreator<
  IFetchServiceDetailFailureAction
> = (service: IService) => ({
  type: ServiceActionTypes.FETCH_SERVICES_DETAIL_FAILURE,
  service,
});

export const fetchServiceDetailProcessing: ActionCreator<
  IFetchServiceDetailProcessingAction
> = (service: IService) => ({
  type: ServiceActionTypes.FETCH_SERVICES_DETAIL_PROCESSING,
  service,
});

export const toggleServiceDetail: ActionCreator<IToggleServiceDetailAction> = (
  service: IService,
) => ({
  type: ServiceActionTypes.TOGGLE_SERVICE_DETAIL,
  service,
});

export const lockServiceManagement: ActionCreator<
  ILockServiceManagementAction
> = (codename: string) => ({
  type: ServiceActionTypes.LOCK_SERVICE_MANAGEMENT,
  codename,
});

export const loadServiceBegin: ActionCreator<ILoadServiceBeginAction> = (
  service: IService,
) => ({
  type: ServiceActionTypes.LOAD_SERVICE_BEGIN,
  payload: {
    service,
  },
});

export const loadServiceSuccess: ActionCreator<ILoadServiceSuccessAction> = (
  service: IService,
) => ({
  type: ServiceActionTypes.LOAD_SERVICE_SUCCESS,
  payload: {
    service,
  },
});

export const loadServiceFailure: ActionCreator<ILoadServiceFailureAction> = (
  service: IService,
) => ({
  type: ServiceActionTypes.LOAD_SERVICE_FAILURE,
  payload: {
    service,
  },
});

// services
export const fetchServicesBegin: ActionCreator<
  IFetchServicesBeginAction
> = () => ({
  type: ServiceActionTypes.FETCH_SERVICES_BEGIN,
});

export const fetchServicesSuccess: ActionCreator<
  IFetchServicesSuccessAction
> = (payload: IService[]) => ({
  type: ServiceActionTypes.FETCH_SERVICES_SUCCESS,
  payload,
});

export const clearServicesData: ActionCreator<
  IClearServicesDataAction
> = () => ({
  type: ServiceActionTypes.CLEAR_SERVICES_DATA,
});

export const serviceAjaxError: ActionCreator<IServiceAjaxError> = (
  service: IService,
) => ({
  type: ServiceActionTypes.SERVICE_AJAX_ERROR,
  service,
});

export const startServiceLoading: ActionCreator<IStartServiceLoadingAction> = (
  service: IService,
) => ({
  type: ServiceActionTypes.SERVICE_LOADING_START,
  service,
});

export const stopServiceLoading: ActionCreator<IStopServiceLoadingAction> = (
  service: IService,
) => ({
  type: ServiceActionTypes.SERVICE_LOADING_STOP,
  service,
});

export const reloadService: ActionCreator<IReloadServiceAction> = (
  service: IService,
) => ({
  type: ServiceActionTypes.SERVICE_LOADING_RELOAD,
  service,
});

export const fetchServicesFailure: ActionCreator<
  IFetchServicesFailureAction
> = () => ({
  type: ServiceActionTypes.FETCH_SERVICES_FAILURE,
});

// WSC services
export const fetchAllServicesBegin: ActionCreator<
  IFetchAllServicesBeginAction
> = () => ({
  type: ServiceActionTypes.FETCH_ALL_SERVICES_BEGIN,
});

export const fetchAllServicesSuccess: ActionCreator<
  IFetchAllServicesSuccessAction
> = () => ({
  type: ServiceActionTypes.FETCH_ALL_SERVICES_SUCCESS,
});

export const fetchAllServicesFailure: ActionCreator<
  IFetchAllServicesFailureAction
> = () => ({
  type: ServiceActionTypes.FETCH_ALL_SERVICES_FAILURE,
});

export const expandGroupAfterMoreServicesLoadedBegin: ActionCreator<
  IExpandGroupAfterMoreServicesLoadedBeginAction
> = (section: ISection) => ({
  type: ServiceActionTypes.EXPAND_GROUP_AFTER_MORE_SERVICES_LOADED_BEGIN,
  section,
});

export const expandGroupAfterMoreServicesLoadedSuccess: ActionCreator<
  IExpandGroupAfterMoreServicesLoadedSuccessAction
> = () => ({
  type: ServiceActionTypes.EXPAND_GROUP_AFTER_MORE_SERVICES_LOADED_SUCCESS,
});

export const expandServiceAfterBssAndWscLoadedBegin: ActionCreator<
  IExpandServiceAfterBssAndWscLoadedBeginAction
> = (service: IService) => ({
  type: ServiceActionTypes.EXPAND_SERVICE_AFTER_BSS_AND_WSC_LOADED_BEGIN,
  service,
});

export const expandServiceAfterBssAndWscLoadedSuccess: ActionCreator<
  IExpandServiceAfterBssAndWscLoadedSuccessAction
> = () => ({
  type: ServiceActionTypes.EXPAND_SERVICE_AFTER_BSS_AND_WSC_LOADED_SUCCESS,
});

export const changeServiceAction = (service: IService, action: string) => {
  return async (dispatch, getState) => {
    const language = languageSelector(getState());

    return changeService({
      changes: [
        {
          productOfferingId: service.productOfferingId,
          productInstanceId: service.productInstanceId,
          action,
        },
      ],
    })
      .then(dispatch(toggleServiceDetail(service)))
      .then(() => {
        redirect({
          url: 'wsc_basket',
          language,
        });
      });
  };
};

const loadMoreServicesBeforeLoaded =
  (group: ISection | null = null) =>
  async (dispatch) => {
    dispatch(loadingStart());
    dispatch(expandGroupAfterMoreServicesLoadedBegin(group));
  };

export const fetchMoreServices =
  (
    section: ISection | null = null,
    withoutLoading = false,
    abortSignal?: AbortSignal,
  ) =>
  async (dispatch, getState) => {
    const { loading } = getState().Services.moreServices;

    if (loading) {
      dispatch(loadMoreServicesBeforeLoaded(section));
      return false;
    }

    if (!withoutLoading) {
      dispatch(loadingStart());
    }

    dispatch(fetchMoreServicesBegin());

    return getEligibleServicesList(abortSignal)
      .then((response) => {
        dispatch(fetchMoreServicesSuccess(response.data));
        const { groupToExpand } = getState().Services.moreServices;

        if (groupToExpand) {
          // eslint-disable-next-line no-param-reassign
          section = groupToExpand;
          dispatch(loadingStop());
          dispatch(expandGroupAfterMoreServicesLoadedSuccess());
        }

        if (section) {
          dispatch(onToggleMoreServicesSuccess(section));
        }
        if (!withoutLoading) {
          dispatch(loadingStop());
        }
      })
      .catch(() => {
        dispatch(fetchMoreServicesFailure());
        if (!withoutLoading) {
          dispatch(loadingStop());
        }
      });
  };

export const onToggleMoreService =
  (section: ISection, moreServicesIsLoaded: boolean, isReadMode: boolean) =>
  async (dispatch) => {
    if (moreServicesIsLoaded) {
      dispatch(onToggleMoreServicesSuccess(section));
    } else if (!isReadMode) {
      dispatch(fetchMoreServices(section));
    }
  };

const loadServiceDetail = (service: IService, section) => async (dispatch) => {
  dispatch(fetchServiceDetailBegin(service));
  return getServiceManagementContent(service)
    .then((response: AxiosResponse<any>) => {
      if (
        typeof response.data === 'object' &&
        (response.data.eligible === false || response.data.openOrder === true)
      ) {
        dispatch(fetchServiceDetailProcessing(service));
      } else if (response.data === '') {
        dispatch(fetchServiceDetailFailure(service));
      } else {
        dispatch(fetchServiceDetailSuccess(service, response.data));
      }

      setTimeout(() => {
        initJqueryServiceDetail(section, service);
        dispatch(toggleServiceDetail(service));
      });
    })
    .catch(() => {
      dispatch(fetchServiceDetailFailure(service));
      dispatch(toggleServiceDetail(service));
    });
};

const fetchServiceDetail =
  (
    e: React.MouseEvent<HTMLElement> | CustomEvent | null,
    service: IService,
    querySelector?: string,
    htmlContent?: string | null,
    // eslint-disable-next-line consistent-return
  ) =>
  async (dispatch, getState) => {
    const allServicesIsLoaded = getState().Services.services.allServices.loaded;
    let section;

    if (e) {
      if (e.type && e.type === 'service:onSubmitSuccess') {
        section = $(`[data-react-id="${service.id}"]`).find('.serviceWrapper');
        section.removeClass('ready');
      } else {
        section = $(e.target).parents('.serviceWrapper');
      }
    } else if (querySelector) {
      section = $(querySelector).find('.serviceWrapper');
    }

    if (
      e &&
      e.type &&
      e.type === 'service:onSubmitSuccess' &&
      service.productOfferingId === BssServicesEnums.PRONWEL
    ) {
      dispatch(loadServiceDetail(service, section));
    } else if (
      e &&
      e.type &&
      e.type === 'service:onSubmitSuccess' &&
      htmlContent
    ) {
      dispatch(fetchServiceDetailSuccess(service, htmlContent));
      section.find('.toggleButton').remove();
      initJqueryServiceDetail(section, service);
      dispatch(toggleServiceDetail(service));
    } else if (!allServicesIsLoaded) {
      dispatch(fetchServiceDetailBegin(service));
      dispatch(expandServiceAfterBssAndWscLoadedBegin(service));
    } else if ((service.loaded && service.detail) || service.isOpen) {
      initJqueryServiceDetail(section, service);
      dispatch(toggleServiceDetail(service));
    } else if (
      section &&
      service &&
      !service.isSpaNativeService &&
      service.type !== 'showingModal'
    ) {
      dispatch(loadServiceDetail(service, section));
    } else if (service.isSpaNativeService) {
      dispatch(toggleServiceDetail(service));
    }
  };

export const onToggleService =
  (
    e: React.MouseEvent<HTMLElement> | CustomEvent | null,
    service: IService,
    querySelector?: string | null,
  ) =>
  async (dispatch) => {
    dispatch(fetchServiceDetail(e, service, querySelector));
  };

export const onReloadService =
  (
    e: React.MouseEvent<HTMLElement> | CustomEvent | null,
    service: IService,
    querySelector?: string | null,
    htmlContent?: string | null,
  ) =>
  async (dispatch) => {
    dispatch(reloadService(service));
    dispatch(scrollTo(service.id));
    // TO DO - no idea why the reducer does not work...
    // eslint-disable-next-line no-param-reassign
    service.isOpen = false;
    // eslint-disable-next-line no-param-reassign
    service.loaded = false;
    dispatch(fetchServiceDetail(e, service, querySelector, htmlContent));
  };

const openService =
  (service: IService, querySelector: string) => async (dispatch) => {
    if (!service.isOpen && isEligibleForChange(service)) {
      dispatch(onToggleService(null, service, querySelector));
    }
  };

export const expandServiceAfterBssAndWscLoaded =
  () => async (dispatch, getState) => {
    getState().Services.services.items.forEach((service) => {
      if (service.expandAfterBssAndWscLoaded) {
        dispatch(
          fetchServiceDetail(null, service, `[data-react-id="${service.id}"]`),
        );
      }
    });
    dispatch(expandServiceAfterBssAndWscLoadedSuccess());
  };

export const fetchServices =
  (abortSignal?: AbortSignal) => async (dispatch) => {
    dispatch(loadingStart());
    dispatch(fetchServicesBegin());
    dispatch(fetchGroupsBegin());
    dispatch(fetchTariffOverviewBegin());
    dispatch(fetchManagementEligibilityBegin());

    return getServicesOverview(abortSignal)
      .then((response) => {
        dispatch(fetchServicesSuccess(response.data.services));
        dispatch(fetchGroupsSuccess(response.data.serviceSections));
        dispatch(fetchTariffOverviewSuccess(response.data.tariffOverview));
        dispatch(
          fetchManagementEligibilitySuccess(
            response.data.managementEligibility,
          ),
        );
        dispatch(loadingStop());
      })
      .catch(() => {
        dispatch(fetchServicesFailure());
        dispatch(fetchGroupsFailure());
        dispatch(fetchTariffOverviewFailure());
        dispatch(fetchManagementEligibilityFailure());
        dispatch(loadingStop());
      });
  };

export const fetchAllServices =
  (abortSignal?: AbortSignal) => async (dispatch) => {
    dispatch(fetchAllServicesBegin());

    return getPreloadAllServices(abortSignal)
      .then(() => {
        dispatch(fetchAllServicesSuccess());
        dispatch(expandServiceAfterBssAndWscLoaded());
      })
      .catch(() => {
        dispatch(fetchAllServicesFailure());
      });
  };

export const fetchVirtualBundle = () => async (dispatch) => {
  dispatch(fetchVirtualBundleBegin());

  return getVirtualBundle()
    .then((response) => {
      dispatch(fetchVirtualBundleSuccess(response.data));
    })
    .catch(() => {
      dispatch(fetchVirtualBundleFailure());
    });
};

export const scrollToServiceAfterMoreServicesSuccess =
  () => async (dispatch, getState) => {
    const elementId = searchParamInUrl('dl');

    if (!elementId) {
      return;
    }

    const productOfferingId = elementId
      .replace('serviceChange', '')
      .replace('_service', '');

    dispatch(loadingStart());

    const i = setInterval(() => {
      const { loaded } = getState().Services.moreServices;
      const { error } = getState().Services.moreServices;
      const { items } = getState().Services.services;
      const allServicesLoaded = getState().Services.services.allServices.loaded;

      if (loaded && !error && allServicesLoaded) {
        const isServiceInStore =
          items.filter(
            (item) =>
              item.codeName === productOfferingId ||
              item.productOfferingId === productOfferingId ||
              (item.productOfferingId &&
                productOfferingId &&
                item.productOfferingId.toLowerCase() ===
                  productOfferingId.toLowerCase()) ||
              (item.codeName &&
                productOfferingId &&
                item.codeName.toLowerCase() ===
                  productOfferingId.toLowerCase()),
          ).length > 0;
        const service = items.find(
          (item) =>
            item.codeName === productOfferingId ||
            item.productOfferingId === productOfferingId ||
            (item.productOfferingId &&
              productOfferingId &&
              item.productOfferingId.toLowerCase() ===
                productOfferingId.toLowerCase()) ||
            (item.codeName &&
              productOfferingId &&
              item.codeName.toLowerCase() === productOfferingId.toLowerCase()),
        );
        const group = getState().Services.sections.items.find(
          (item) =>
            service && service.sectionId && item.id === service.sectionId,
        );

        if (isServiceInStore) {
          dispatch(onToggleMoreServicesSuccess(group));
          clearInterval(i);
          setTimeout(() => {
            dispatch(scrollTo(service.id));
            dispatch(loadingStop());
            dispatch(loadingStop());
            if (service) {
              dispatch(openService(service, `[data-react-id="${service.id}"]`));
            }
          }, 300);
        }
        dispatch(loadingStop());
        dispatch(loadingStop());
        clearInterval(i);
      }
    });
  };

export const scrollToService =
  (isReadMode: boolean, abortSignal?: AbortSignal) =>
  async (dispatch, getState) => {
    const urlParameter = searchParamInUrl('dl');

    if (!urlParameter) {
      return;
    }
    const productOfferingId = urlParameter
      .replace('serviceChange', '')
      .replace('_service', '');

    dispatch(loadingStart());

    const i = setInterval(() => {
      const { loaded } = getState().Services.services;
      const { error } = getState().Services.services;
      const { items } = getState().Services.services;
      const allServicesLoaded = getState().Services.services.allServices.loaded;

      const dataSharingCodeNames = ['data_master', 'data_member'];

      if (loaded && !error && allServicesLoaded) {
        const foundService = items.filter(
          (item) =>
            item.codeName === productOfferingId ||
            item.productOfferingId === productOfferingId ||
            (item.productOfferingId &&
              productOfferingId &&
              item.productOfferingId.toLowerCase() ===
                productOfferingId.toLowerCase()) ||
            (item.codeName &&
              productOfferingId &&
              item.codeName.toLowerCase() ===
                productOfferingId.toLowerCase()) ||
            (productOfferingId === 'data_sharing' &&
              dataSharingCodeNames.includes(item.codeName)),
        );

        const isServiceInStore = foundService.length > 0;

        const isDataSharingServiceInDOM =
          productOfferingId === 'data_sharing' &&
          dataSharingCodeNames.some((id) =>
            document.querySelector(`#serviceChange${id}`),
          );

        const isServiceInDOM =
          document.querySelector(`#serviceChange${productOfferingId}`) ||
          document.querySelector(
            `#serviceChange-${foundService[0]?.productOfferingId}`,
          ) ||
          document.querySelector(
            `#serviceChange-${foundService[0]?.codeName}`,
          ) ||
          isDataSharingServiceInDOM;

        const service = items.find(
          (item) =>
            item.codeName === productOfferingId ||
            item.productOfferingId === productOfferingId ||
            (item.productOfferingId &&
              productOfferingId &&
              item.productOfferingId.toLowerCase() ===
                productOfferingId.toLowerCase()) ||
            (item.codeName &&
              productOfferingId &&
              item.codeName.toLowerCase() ===
                productOfferingId.toLowerCase()) ||
            (productOfferingId === 'data_sharing' &&
              dataSharingCodeNames.includes(item.codeName)),
        );

        if (isServiceInStore && isServiceInDOM) {
          dispatch(loadingStop());
          clearInterval(i);
          setTimeout(() => {
            dispatch(scrollTo(service.id));
            dispatch(loadingStop());
            if (service) {
              dispatch(openService(service, `[data-react-id="${service.id}"]`));
            }
          }, 300);
        } else if (!isServiceInStore) {
          dispatch(loadingStop());
          dispatch(loadingStop());
          clearInterval(i);
          if (!isReadMode) {
            dispatch(fetchMoreServices(null, false, abortSignal));
          }
          dispatch(scrollToServiceAfterMoreServicesSuccess());
        }
      } else if (error) {
        dispatch(loadingStop());
        dispatch(loadingStop());
        clearInterval(i);
      }
    });
  };

export const openServiceDetailModal =
  (service: IService) => (dispatch, getState) => {
    const subscription: ISubscription = selectCurrentSubscription(getState());
    const { serviceNumber } = subscription;
    let modalType;

    if (service.codeName === ServiceCodeNameEnum.ESIM_PORT) {
      modalType = ModalType.TransferESim;
    } else if (service.codeName === ServiceCodeNameEnum.ESIM_ORDER) {
      modalType = ModalType.OrderNewESimMfa;
    } else if (
      (service.codeName === ServiceCodeNameEnum.ACTIVE_ESIM ||
        service.codeName === ServiceCodeNameEnum.ACTIVE_WITH_RELEASED_ESIM) &&
      service.data?.esim?.state === eSimStateEnum.ESIM_DELETED_FROM_DEVICE
    ) {
      modalType = ModalType.ActivateDeletedESim;
    } else if (
      (service.codeName === ServiceCodeNameEnum.ACTIVE_ESIM ||
        service.codeName === ServiceCodeNameEnum.ACTIVE_WITH_RELEASED_ESIM) &&
      service.data?.esim?.state === eSimStateEnum.ESIM_READY_FOR_DOWNLOAD
    ) {
      modalType = ModalType.ActivateESim;
    } else if (
      service.data?.esim?.state === eSimStateEnum.ESIM_READY_FOR_SWAP
    ) {
      modalType = ModalType.CompleteSimSwapContent;
    }

    dispatch(
      openModal({
        type: modalType,
        options: {
          service,
          serviceNumber,
        },
      }),
    );
  };
