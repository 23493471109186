import { AxiosResponse } from 'axios';
import { API_ENDPOINT_VTV_REMOVE_ACTIVE_DEVICE } from '../..';
import api from '../../../utils/api/api';

export default (
  serviceNumber: string,
  udids: string[],
): Promise<AxiosResponse> =>
  api.post({
    url: API_ENDPOINT_VTV_REMOVE_ACTIVE_DEVICE.replace(
      '{serviceNumber}',
      serviceNumber,
    ),
    data: { udids },
  });
