import { ICustomerBillingItem } from 'src/store/billing/billingCustomer/types/billingCustomerInterface';
import PayerInvoiceStatusEnum from 'src/store/invoices/types/PayerInvoiceStatusEnum';
import {
  CookieNameEnum,
  CookiePaymentStatusEnum,
  getCookieParsed,
  setCookieStringify,
} from 'src/utils/cookie';

export interface ILastOkPaid {
  lastOkPaidTime: number;
  lastOkPaidBaNumber: string;
  lastOkPaidStatus: CookiePaymentStatusEnum;
}

export const isPaidInLastDayForBa = (baNumber: string) => {
  let isPaidResult = false;
  const storedOkPaidArr: ILastOkPaid[] = getCookieParsed(
    CookieNameEnum.LAST_OK_PAYMENT,
  );

  if (!Array.isArray(storedOkPaidArr)) {
    return isPaidResult;
  }

  const storedOkPaidForBa = storedOkPaidArr.find(
    (oneBa) => oneBa.lastOkPaidBaNumber === baNumber,
  );

  if (
    storedOkPaidForBa?.lastOkPaidTime &&
    storedOkPaidForBa?.lastOkPaidBaNumber &&
    storedOkPaidForBa?.lastOkPaidStatus
  ) {
    const { lastOkPaidTime, lastOkPaidBaNumber } = storedOkPaidForBa;
    const now = new Date().getTime();
    const oneDay = 24 * 60 * 60 * 1000;
    const isPaidInLastDay = lastOkPaidTime + oneDay > now;
    const isPaidForThisBa = lastOkPaidBaNumber === baNumber;
    const isPaidStatusPaid =
      storedOkPaidForBa.lastOkPaidStatus === CookiePaymentStatusEnum.PAID;

    if (isPaidInLastDay && isPaidForThisBa && isPaidStatusPaid) {
      isPaidResult = true;
    }
  }
  return isPaidResult;
};

const updatePaidInLastDayForBa = (
  storedOkPaidArr: ILastOkPaid[],
  currentBa: string,
  status: CookiePaymentStatusEnum,
) => {
  const now = new Date().getTime();

  const newOkPaid = {
    lastOkPaidTime: now,
    lastOkPaidBaNumber: currentBa,
    lastOkPaidStatus: status,
  };

  if (!Array.isArray(storedOkPaidArr)) {
    return [newOkPaid];
  }

  const storedOkPaidBaIndex = storedOkPaidArr?.findIndex(
    (oneBa) => oneBa.lastOkPaidBaNumber === currentBa,
  );

  const updateCurrentStatus = (oneBa: ILastOkPaid) =>
    oneBa.lastOkPaidStatus === CookiePaymentStatusEnum.LAST_REQUEST
      ? {
          ...oneBa,
          lastOkPaidStatus: CookiePaymentStatusEnum.OLD_REQUEST,
        }
      : oneBa;

  if (storedOkPaidBaIndex === -1) {
    return [...storedOkPaidArr.map(updateCurrentStatus), newOkPaid];
  }

  let updatedOkPaidArr = storedOkPaidArr;

  if (storedOkPaidBaIndex !== -1) {
    if (status === CookiePaymentStatusEnum.LAST_REQUEST) {
      updatedOkPaidArr = updatedOkPaidArr.map(updateCurrentStatus);
      updatedOkPaidArr[storedOkPaidBaIndex].lastOkPaidStatus =
        CookiePaymentStatusEnum.LAST_REQUEST;
    }

    if (status === CookiePaymentStatusEnum.PAID) {
      updatedOkPaidArr[storedOkPaidBaIndex].lastOkPaidStatus =
        CookiePaymentStatusEnum.PAID;
    }

    updatedOkPaidArr[storedOkPaidBaIndex].lastOkPaidTime = now;
  }

  return updatedOkPaidArr;
};

export const updateOkPaymenCookie = (
  cName: string,
  currentBa: string,
  cExpireHours: number,
  cStatus: CookiePaymentStatusEnum,
) => {
  const storedOkPaidArr: ILastOkPaid[] = getCookieParsed(cName);

  setCookieStringify(
    cName,
    updatePaidInLastDayForBa(storedOkPaidArr, currentBa, cStatus),
    cExpireHours,
  );
};

export const isPaidInLastDaySomeBa = (baNumberArr: ICustomerBillingItem[]) => {
  const storedOkPaidArr: ILastOkPaid[] = getCookieParsed(
    CookieNameEnum.LAST_OK_PAYMENT,
  );

  if (!Array.isArray(storedOkPaidArr)) {
    return false;
  }

  if (!baNumberArr.length) {
    return false;
  }

  const paidInLastDaySomeBa = baNumberArr.some((oneBa) =>
    storedOkPaidArr.find(
      (oneBaNumberStored) =>
        oneBaNumberStored?.lastOkPaidBaNumber &&
        oneBaNumberStored.lastOkPaidBaNumber === oneBa.billingAccountNumber &&
        oneBaNumberStored?.lastOkPaidStatus &&
        oneBaNumberStored.lastOkPaidStatus === CookiePaymentStatusEnum.PAID &&
        (oneBa.status === PayerInvoiceStatusEnum.PARTIALLY_PAID ||
          oneBa.status === PayerInvoiceStatusEnum.UNPAID),
    ),
  );

  return paidInLastDaySomeBa;
};

export const getLastPaidBa = () => {
  const storedOkPaidArr: ILastOkPaid[] = getCookieParsed(
    CookieNameEnum.LAST_OK_PAYMENT,
  );

  if (!Array.isArray(storedOkPaidArr)) {
    return null;
  }

  const lastPaidBa = storedOkPaidArr.find(
    (oneBaNumberStored) =>
      oneBaNumberStored?.lastOkPaidStatus &&
      oneBaNumberStored.lastOkPaidStatus ===
        CookiePaymentStatusEnum.LAST_REQUEST,
  );

  if (!lastPaidBa) {
    return null;
  }

  return lastPaidBa.lastOkPaidBaNumber;
};
