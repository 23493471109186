import { Reducer } from 'redux';
import AuthTypeActionTypes from 'src/store/mnp/oku/types/authTypeActionTypes';
import initialState from '../../initialState';
import {
  IFetchAuthTypeBeginAction,
  IFetchAuthTypeFailureAction,
  IFetchAuthTypeSuccessAction,
} from '../oku/types/mnpOkuActionInterfaces';
import {
  IFetchMnpServiceDetailBeginAction,
  IFetchMnpServiceDetailFailureAction,
  IFetchMnpServiceDetailSuccessAction,
  ISelectMnpServiceDetailTypeAction,
} from './types/mnpServiceDetailActionInterfaces';
import MnpServiceDetailActionTypes from './types/mnpServiceDetailActionTypes';
import IMnpServiceDetailState from './types/mnpServiceDetailStateInterface';

type IMnpServiceDetailActions =
  | ISelectMnpServiceDetailTypeAction
  | IFetchMnpServiceDetailBeginAction
  | IFetchMnpServiceDetailSuccessAction
  | IFetchMnpServiceDetailFailureAction
  | IFetchAuthTypeBeginAction
  | IFetchAuthTypeSuccessAction
  | IFetchAuthTypeFailureAction;

const mnpReducer: Reducer<IMnpServiceDetailState, IMnpServiceDetailActions> = (
  state = initialState.Mnp.serviceDetail,
  action,
) => {
  switch (action.type) {
    case MnpServiceDetailActionTypes.SELECT_MNP_SERVICE_DETAIL_TYPE:
      return {
        ...state,
        mnpType: action.payload.mnpType,
      };
    case MnpServiceDetailActionTypes.FETCH_MNP_SERVICE_DETAIL_BEGIN:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      };
    case MnpServiceDetailActionTypes.FETCH_MNP_SERVICE_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        data: action.payload,
      };
    case AuthTypeActionTypes.FETCH_AUTH_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        authType: action.payload.authType,
      };
    case MnpServiceDetailActionTypes.FETCH_MNP_SERVICE_DETAIL_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    default:
      return state;
  }
};

export default mnpReducer;
