import { Text } from '@vodafone/red-white';
import React from 'react';
import styled from 'styled-components';
import SanitizedHtml from '../../../utils/sanitizeHtml';

const mapDot = {
  success: 'green',
  error: 'primaryColor',
  default: 'grayLight4',
};

const dotSize = {
  small: '7px',
  default: '9px',
};

const RuleText = styled(Text)`
  display: flex;
  align-items: center;
  margin-bottom: 13px;
`;

const Dot = styled.span<{
  status?: 'success' | 'error' | 'default';
  size?: 'small' | 'default';
}>`
  display: inline-block;
  width: ${(props) => dotSize[props.size || 'default']};
  height: ${(props) => dotSize[props.size || 'default']};
  background: ${(props) =>
    props.theme.colors[mapDot[props.status || 'default']]};
  border-radius: 50%;
  padding-right: ${(props) => dotSize[props.size || 'default']};
  margin-right: ${(props) => (props.size === 'small' ? '7px' : '14px')};
  margin-left: ${(props) => (props.size === 'small' ? '16px' : '0px')};
`;

interface IPasswordRuleProps {
  text: string;
  success: any;
  type?: any;
}

const PasswordRule: React.FC<IPasswordRuleProps> = ({
  text,
  success,
  type = 'default',
}) => {
  const isSmall = type === 'small';
  const fontSize = isSmall ? 14 : 16;
  const style = isSmall ? { color: '#6B6B6B' } : {};

  const status =
    typeof success === 'boolean' ? (success ? 'success' : 'error') : success;

  return (
    <RuleText block fontSize={fontSize} color="darkGrey" style={style}>
      {/* @ts-ignore */}
      <Dot size={type} status={status} />
      <SanitizedHtml htmlTag="span" rawHtmlData={text} />
    </RuleText>
  );
};

export default PasswordRule;
