import { Reducer } from 'redux';
import initialState from '../../initialState';
import {
  IFetchFbbExitServicePenaltyBeginAction,
  IFetchFbbExitServicePenaltyFailureAction,
  IFetchFbbExitServicePenaltySuccessAction,
} from '../types/fbbExitServicesPenalty/fbbExitServicePenaltyActionInterfaces';
import FbbExitServicePenaltyActionTypes from '../types/fbbExitServicesPenalty/fbbExitServicePenaltyActionTypes';
import IFbbExitServicePenaltyState from '../types/fbbExitServicesPenalty/fbbExitServicePenaltyStateInterface';

type IFbbExitServiceActions =
  | IFetchFbbExitServicePenaltyBeginAction
  | IFetchFbbExitServicePenaltySuccessAction
  | IFetchFbbExitServicePenaltyFailureAction;

const fbbExitServicePenaltyReducer: Reducer<
  IFbbExitServicePenaltyState,
  IFbbExitServiceActions
> = (state = initialState.FbbServices.fbbExitServicePenalty, action) => {
  switch (action.type) {
    case FbbExitServicePenaltyActionTypes.FETCH_FBB_EXIT_SERVICE_PENALTY_BEGIN:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      };
    case FbbExitServicePenaltyActionTypes.FETCH_FBB_EXIT_SERVICE_PENALTY_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        loaded: true,
        error: false,
      };
    case FbbExitServicePenaltyActionTypes.FETCH_FBB_EXIT_SERVICE_PENALTY_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    default:
      return state;
  }
};

export default fbbExitServicePenaltyReducer;
