import { createSelector, Selector } from 'reselect';
import { IApplicationState } from 'src/store/appStateInterface';
import IGenericMicrositeAfterLoginState from 'src/store/genericMicrositeAfterLogin/types/genericMicrositeAfterLoginStateInterface';
import ServiceHasOfferStatusEnum from 'src/store/genericMicrositeAfterLogin/types/serviceHasOfferStatusEnum';
import { IService } from 'src/store/genericMicrositeAfterLogin/types/serviceInterface';

const SWITCHER_BREAKDOWN_COUNT = 10;
const MAX_GET_HAS_OFFERS_PARAELL_CALLS = 2;

export const genericMicrositeAfterLoginSelector: Selector<
  IApplicationState,
  IGenericMicrositeAfterLoginState
> = (state: IApplicationState) => {
  return state.GenericMicrositeAfterLogin;
};

export const isLoadedSelector: Selector<IApplicationState, boolean> =
  createSelector([genericMicrositeAfterLoginSelector], (gm) => gm.loaded);

export const hasNoOtherServicesSelector: Selector<IApplicationState, boolean> =
  createSelector(
    [genericMicrositeAfterLoginSelector],
    (gm) => gm.loaded && gm.otherServices.length === 0,
  );

export const isSmallSwitcherSelector: Selector<IApplicationState, boolean> =
  createSelector(
    [genericMicrositeAfterLoginSelector],
    (gm) => gm.loaded && gm.otherServices.length <= SWITCHER_BREAKDOWN_COUNT,
  );

export const isLargeSwitcherCombiner = (gm: IGenericMicrositeAfterLoginState) =>
  gm.loaded && gm.otherServices.length > SWITCHER_BREAKDOWN_COUNT;

export const isLargeSwitcherSelector: Selector<IApplicationState, boolean> =
  createSelector([genericMicrositeAfterLoginSelector], isLargeSwitcherCombiner);

export const selectedServiceSelector: Selector<
  IApplicationState,
  IService | undefined
> = createSelector(
  [genericMicrositeAfterLoginSelector],
  (gm) => gm.selectedService,
);

export const otherServicesSelector: Selector<IApplicationState, IService[]> =
  createSelector(
    [genericMicrositeAfterLoginSelector],
    (gm) => gm.otherServices,
  );

export const isSignedInSelector: Selector<IApplicationState, boolean> =
  createSelector([genericMicrositeAfterLoginSelector], (gm) => gm.signedIn);

export const searchResultSelector: Selector<IApplicationState, IService[]> =
  createSelector([genericMicrositeAfterLoginSelector], (gm) => gm.searchResult);

export const toGetHasOffersSelector: Selector<IApplicationState, IService[]> =
  createSelector([genericMicrositeAfterLoginSelector], (gm) => {
    const selectedServices: IService[] = [];
    let loadingStateCount = 0;

    gm.otherServices.forEach((service) => {
      switch (service.hasOffers) {
        case ServiceHasOfferStatusEnum.INITIAL_STATE:
          if (selectedServices.length < MAX_GET_HAS_OFFERS_PARAELL_CALLS) {
            selectedServices.push(service);
          }
          break;
        case ServiceHasOfferStatusEnum.LOADING:
          loadingStateCount += 1;
          break;
        default:
      }
    });

    if (
      loadingStateCount >= MAX_GET_HAS_OFFERS_PARAELL_CALLS ||
      selectedServices.length === 0
    ) {
      return [];
    }

    if (loadingStateCount === 0) {
      return selectedServices;
    }

    return selectedServices.slice(
      0,
      MAX_GET_HAS_OFFERS_PARAELL_CALLS - loadingStateCount,
    );
  });

export const offersForSwitcherSelector: Selector<
  IApplicationState,
  IService[]
> = createSelector([genericMicrositeAfterLoginSelector], (gm) => {
  if (gm.otherServices.length === 0) {
    return [];
  }
  const servicesWithOffers: IService[] = [];
  let loadingService: IService | undefined;

  gm.otherServices.forEach((service) => {
    switch (service.hasOffers) {
      case ServiceHasOfferStatusEnum.HAS_OFFER:
        servicesWithOffers.push(service);
        break;
      case ServiceHasOfferStatusEnum.LOADING:
        if (loadingService === undefined) {
          loadingService = service;
        }
        break;
      default:
    }
  });

  if (servicesWithOffers.length === 0) {
    return [];
  }

  if (loadingService !== undefined) {
    servicesWithOffers.push(loadingService);
  }

  return servicesWithOffers;
});
