import { useQuery } from '@tanstack/react-query';
import { IMainMenuItem } from '@vodafone/red-white';
import { default as i18n } from 'i18next';
import { getRouteIdFromMenuItem } from 'src/components/Navigation/utils/helpers';
import { getPathByRouteId, RoutesEnum } from 'src/config/routes';
import ILoginBannerInfo from 'src/store/user/types/loginBannerInfo';
import { LanguagesEnum } from 'src/types/translation/languagesEnum';
import { ITranslation } from 'src/types/translationInterface';
import api from 'src/utils/api/api';
import getTranslation from 'src/utils/translation/getTranslation';

export const LAYOUT_ENPOINT = '/muj/rapi/layout/{lang}';
const QUERY_KEY = 'getLayout';

export type NavigationItem = {
  iconName: string | null;
  id: string;
  counter?: number;
  label: ITranslation;
  menuItems: NavigationItem[];
  uri: ITranslation;
  sticker?: ITranslation;
  enVersion?: boolean;
  routeId?: `${RoutesEnum}`;
  onClick?: () => void;
};

export type LayoutResponse<Item> = {
  footerMenuItems: Item[];
  menuItems: Item[];
  showNavigation: boolean;
  loginBannerInfoResponse?: ILoginBannerInfo;
  flashMessages?: any[];
};

const getLayout = async (): Promise<LayoutResponse<NavigationItem>> => {
  const { data } = await api.get<LayoutResponse<NavigationItem>>({
    url: LAYOUT_ENPOINT.replace('{lang}', i18n.language),
  });

  return data;
};

export const useGetLayout = () => {
  return useQuery({
    queryFn: getLayout,
    queryKey: [QUERY_KEY, i18n.language],
    select: (data): LayoutResponse<IMainMenuItem> => {
      const eshopRoute = {
        cze: getPathByRouteId(
          RoutesEnum.WSC_ESHOP_SPECIAL_OFFERS,
          LanguagesEnum.CS,
        ),
        eng: getPathByRouteId(
          RoutesEnum.WSC_ESHOP_SPECIAL_OFFERS,
          LanguagesEnum.EN,
        ),
      };

      return {
        ...data,
        menuItems: data.menuItems.map((item) => ({
          id: item.id,
          routeId: item.routeId,
          iconName: item.iconName,
          label: getTranslation(item.label, i18n.language),
          uri:
            item.id === 'menu.eshop.title'
              ? getTranslation(eshopRoute, i18n.language)
              : getTranslation(item.uri, i18n.language),
          onClick: null,
          menuItems: item.menuItems.map((subItem) => ({
            id: subItem.id,
            routeId: subItem.routeId,
            iconName: subItem.iconName,
            label: getTranslation(subItem.label, i18n.language),
            uri: getTranslation(subItem.uri, i18n.language),
            sticker:
              subItem.sticker && getTranslation(subItem.sticker, i18n.language),
            menuItems: [],
            onClick: null,
          })),
        })),
        footerMenuItems: data.footerMenuItems.map((item) => ({
          id: item.id,
          routeId: getRouteIdFromMenuItem(item),
          iconName: item.iconName,
          counter: item.counter,
          label: getTranslation(item.label, i18n.language),
          uri: getTranslation(item.uri, i18n.language),
          onClick: null,
          menuItems: item.menuItems.map((subItem) => ({
            id: subItem.id,
            routeId: subItem.routeId,
            iconName: subItem.iconName,
            counter: subItem.counter,
            label: getTranslation(subItem.label, i18n.language),
            uri: getTranslation(subItem.uri, i18n.language),
            onClick: null,
            menuItems: [],
          })),
        })),
      };
    },
  });
};
