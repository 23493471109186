import { Button, Loader } from '@vodafone/red-white';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'src/hooks/useAppDispatch';
import {
  fetchModemMacAddressRefresh,
  getFbbModemMacAddress,
} from 'src/store/fbb/actions/fbbModemMacAddressActions';
import { getFbbModemRestart } from 'src/store/fbb/actions/fbbModemRestartActions';
import { selectFbbModemMacAddress } from 'src/store/fbb/selectors/fbbModemMacAddressSelector';
import { selectFbbModemRestart } from 'src/store/fbb/selectors/fbbModemRestartSelector';
import { getModal } from 'src/store/modal/modalSelectors';
import userSelector from 'src/store/user/userSelector';
import styled from 'styled-components';
import SanitizedHtml from '../../../utils/sanitizeHtml';
import { ModalContentProps } from '../types/modalContentInterfaces';

const ContentWrapper = styled.div`
  overflow-y: auto;

  @media ${(props) => props.theme.breakpoints.lg} {
    height: 530px;
  }

  @media only screen and (max-height: 660px) {
    height: 100%;
  }
`;

const StyledListItem = styled.li`
  list-style-type: disc;
  margin-left: 20px;
  padding-bottom: 15px;
`;

const FbbModemSettingsContent: FC<ModalContentProps> = () => {
  const { t } = useTranslation('services');
  const dispatch = useAppDispatch();
  const { subscriptionId } = useSelector(userSelector).subscription;
  const fbbModemMacAddress = useSelector(selectFbbModemMacAddress);
  const fbbModemRestart = useSelector(selectFbbModemRestart);
  const modemIdentifier = useSelector(getModal).id;
  const modem = fbbModemMacAddress.data;

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (!fbbModemMacAddress.loaded) {
      const timer = setInterval(() => {
        dispatch(getFbbModemMacAddress(subscriptionId, modemIdentifier));
      }, 5000);

      return () => clearTimeout(timer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fbbModemMacAddress]);

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  useEffect(() => {}, [fbbModemRestart]);

  const handleModemRestart = () => {
    dispatch(getFbbModemRestart(subscriptionId, modemIdentifier));
  };

  const handleRefresh = () => {
    dispatch(fetchModemMacAddressRefresh());
  };

  return (
    <ContentWrapper>
      <Loader
        show={
          fbbModemMacAddress.loading ||
          !fbbModemMacAddress.loaded ||
          fbbModemRestart.loading
        }
      />
      <SanitizedHtml
        htmlTag="p"
        rawHtmlData={t('services:fbb.modem-state-description')}
      />
      <div>
        {fbbModemMacAddress.loaded && (
          <table>
            <tbody>
              <tr>
                <th>{t('services:fbb.modem-state')}</th>
                <td>
                  {fbbModemMacAddress.loaded && modem.status === 'Online'
                    ? t('services:fbb.state-active')
                    : t('services:fbb.state-active')}
                </td>
              </tr>
              <tr>
                <th>{t('services:fbb.modem-wifi-state')}</th>
                <td>
                  {modem.wifiStatus.band2400MHz === 'ON'
                    ? t('services:fbb.state-active')
                    : t('services:fbb.state-inactive')}{' '}
                  (2,4GHz)
                  <br />
                  {modem.wifiStatus.band5000MHz === 'ON'
                    ? t('services:fbb.state-active')
                    : t('services:fbb.state-inactive')}{' '}
                  (5,0GHz)
                </td>
              </tr>
              <tr>
                <th>{t('services:fbb.modem-ip-address')}</th>
                <td>
                  {modem.wanIp.map((address, i) => (
                    <span key={i.toString()}>
                      {address}
                      <br />
                    </span>
                  ))}
                </td>
              </tr>
              <tr>
                <th>{t('services:fbb.modem-active-from')}</th>
                <td>{modem.statusChanged}</td>
              </tr>
            </tbody>
          </table>
        )}
      </div>
      <br />
      <div style={{ textAlign: 'center' }}>
        <Button
          disabled={fbbModemMacAddress.loading}
          variant="primary"
          onClick={() => handleRefresh()}
        >
          {t('services:fbb.modem-refresh-button')}
        </Button>
      </div>
      <br />
      <SanitizedHtml
        htmlTag="strong"
        rawHtmlData={t('services:fbb.modem-restart-title')}
      />
      <SanitizedHtml
        htmlTag="p"
        rawHtmlData={t('services:fbb.modem-restart-description')}
      />
      <ul>
        <StyledListItem>
          <SanitizedHtml
            htmlTag="p"
            rawHtmlData={t('services:fbb.modem-restart-about-duration')}
          />
        </StyledListItem>
        <StyledListItem>
          <SanitizedHtml
            htmlTag="p"
            rawHtmlData={t('services:fbb.modem-restart-about-problems')}
          />
        </StyledListItem>
        <StyledListItem>
          <SanitizedHtml
            htmlTag="p"
            rawHtmlData={t('services:fbb.modem-restart-about-loading')}
          />
        </StyledListItem>
      </ul>
      <br />
      <div style={{ textAlign: 'center' }}>
        <Button
          variant="primary"
          onClick={() => handleModemRestart()}
          disabled={fbbModemRestart.loading || fbbModemRestart.loaded}
        >
          {t('services:fbb.modem-restart-button')}
        </Button>
      </div>
    </ContentWrapper>
  );
};

export default FbbModemSettingsContent;
