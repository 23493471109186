import { Button, Card, Text, Title } from '@vodafone/red-white';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'src/hooks/useAppDispatch';
import { deleteProfile } from 'src/store/user/userActions';
import userSelector from 'src/store/user/userSelector';
import styled from 'styled-components';
import { ModalContentProps } from '../types/modalContentInterfaces';

const ButtonsWrapper = styled.div`
  text-align: center;
  white-space: nowrap;
`;

const DeleteProfileModalContent: FC<ModalContentProps> = ({
  onClose,
  setCompact,
  setCompactContent,
}) => {
  const { t } = useTranslation('personalAccount');
  const user = useSelector(userSelector);
  const dispatch = useAppDispatch();

  const onClickDeleteProfile = () => {
    dispatch(deleteProfile());
  };

  setCompact(true);
  setCompactContent(true);

  return (
    <Card
      style={{
        maxWidth: 700,
        width: '100%',
        boxShadow: 'none',
        padding: '0 10px',
      }}
      loading={user.loading}
      title={
        <Title
          color="darkGrey"
          fontFamily="VodafoneRg"
          fontWeight="bold"
          level={3}
          style={{ marginTop: 10 }}
        >
          {t('profile.delete-profile-modal-title')}
        </Title>
      }
    >
      <Text fontSize={16} block style={{ margin: '15px 0' }}>
        {t('profile.delete-profile-modal-description')}
      </Text>
      <ButtonsWrapper>
        <Button variant="primary" onClick={onClose} style={{ margin: '0 5px' }}>
          {t('profile.delete-profile-modal-decline')}
        </Button>
        <Button
          variant="primary"
          onClick={onClickDeleteProfile}
          style={{ margin: '0 5px' }}
        >
          {t('profile.delete-profile-modal-accept')}
        </Button>
      </ButtonsWrapper>
    </Card>
  );
};

export default DeleteProfileModalContent;
