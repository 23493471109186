import { Button, CircleLoader, Loader, Message } from '@vodafone/red-white';
import i18n from 'i18next';
import * as React from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { VStack } from 'src/components/Layout';
import { FadeInWrapper } from 'src/components/Styled/Styled';
import { RoutesEnum } from 'src/config/routes';
import { useAppDispatch } from 'src/hooks/useAppDispatch';
import { IApplicationState } from 'src/store/appStateInterface';
import { fetchPaymentInfo } from 'src/store/billing/paymentInfo/paymentInfoActions';
import {
  selectPaymentInfo,
  selectPaymentInfoForBaNumber,
} from 'src/store/billing/paymentInfo/paymentInfoSelectors';
import IPaymentInfo from 'src/store/billing/paymentInfo/types/paymentInfoInterface';
import { selectPaymentTransaction } from 'src/store/billing/paymentTransaction/paymentTransactionSelectors';
import IBillingAccount from 'src/store/user/types/billingAccountInterface';
import { selectBillingAccount } from 'src/store/user/userSelector';
import { LanguagesEnum } from 'src/types/translation/languagesEnum';
import { isPaidInLastDayForBa } from 'src/utils/payment';
import { redirect } from 'src/utils/redirect';
import SanitizedHtml from 'src/utils/sanitizeHtml';
import styled from 'styled-components';
import BillingStatusEnum from '../../../../types/billing/billingStatusEnum';
import SipoStatusEnum from '../../../../types/billing/sipoStatusEnum';
import PaymentInfoInCreation from './PaymentInfoInCreation';
import PaymentInfoPaid from './PaymentInfoPaid';
import PaymentInfoUnpaid from './PaymentInfoUnpaid';
import SipoPaymentInfo from './SipoPaymentInfo';

const MessageWrapper = styled.div`
  padding: 20px 0 0 0;
`;

interface IPaymentInfoProps {
  paymentInfoData?: IPaymentInfo;
  showWithSipoBox: boolean;
  billingAccountNumber?: string;
}

const PaymentInfo: React.FC<IPaymentInfoProps> = ({
  paymentInfoData,
  showWithSipoBox,
  billingAccountNumber,
}) => {
  const PaymentInfoWrapper = styled.div`
    position: relative;
    z-index: 1;
  `;
  const { t } = useTranslation('billing');
  const billingAccount: IBillingAccount = useSelector(
    (state: IApplicationState) => selectBillingAccount(state),
  );
  const selectedBaNumber = billingAccount
    ? billingAccount.billingAccountNumber
    : null;
  const baNumber: string = billingAccountNumber || selectedBaNumber;
  const dispatch = useAppDispatch();
  const paymentTransaction = useSelector(selectPaymentTransaction);
  const paymentInfo = useSelector(selectPaymentInfo);
  const paymentInfoForBaNumber = useSelector((state: IApplicationState) =>
    selectPaymentInfoForBaNumber(state, baNumber),
  );

  useEffect(() => {
    if (paymentInfoData) {
      return;
    }
    if (paymentInfo.error) {
      return;
    }
    if (!paymentInfoForBaNumber) {
      dispatch(fetchPaymentInfo(baNumber));
    }
  }, [
    baNumber,
    dispatch,
    paymentInfo.error,
    paymentInfoData,
    paymentInfoForBaNumber,
  ]);

  const successStatuses: string[] = [
    BillingStatusEnum.PAID,
    BillingStatusEnum.OVERPAID,
    BillingStatusEnum.SUFFICIENTLY_PAID,
    BillingStatusEnum.DO_NOT_PAY,
  ];

  const overdueStatuses: string[] = [
    BillingStatusEnum.UNPAID_OVERDUE,
    BillingStatusEnum.UNPAID_INCLUDE_OVERDUE,
    BillingStatusEnum.UNDERPAID,
  ];

  const showSipoManualPayment =
    paymentInfo.loaded &&
    paymentInfoForBaNumber &&
    paymentInfoForBaNumber.isSipoActive &&
    paymentInfoForBaNumber.sipoDueAmount > 120 &&
    (paymentInfoForBaNumber.sipoStatus === SipoStatusEnum.REJECTED ||
      overdueStatuses.indexOf(paymentInfoForBaNumber.status) > -1);

  const showSipoBox =
    paymentInfo.loaded &&
    paymentInfoForBaNumber &&
    paymentInfoForBaNumber.isSipoActive &&
    showWithSipoBox &&
    (paymentInfoForBaNumber.billingAmount !==
      paymentInfoForBaNumber.dueAmount ||
      (paymentInfoForBaNumber.billingAmount < 120 &&
        paymentInfoForBaNumber.dueAmount < 120));

  const isUnpaid =
    paymentInfo.loaded &&
    (showSipoManualPayment ||
      (paymentInfoForBaNumber &&
        !paymentInfoForBaNumber.isSipoActive &&
        successStatuses.indexOf(paymentInfoForBaNumber.status) === -1)) &&
    paymentInfoForBaNumber.status !== BillingStatusEnum.IN_CREATION;

  return (
    <PaymentInfoWrapper>
      <Loader show={paymentTransaction.loading} />

      {paymentInfo.loading && <CircleLoader />}

      <FadeInWrapper visible={paymentInfo.loaded} animateHeight>
        {paymentInfo.loaded &&
        isUnpaid &&
        paymentInfoForBaNumber &&
        isPaidInLastDayForBa(baNumber) ? (
          <PaymentInfoPaid {...paymentInfoForBaNumber} isPaidInLastDayForBa />
        ) : (
          <>
            {!showSipoBox &&
              paymentInfo.loaded &&
              paymentInfoForBaNumber &&
              paymentInfoForBaNumber.status ===
                BillingStatusEnum.IN_CREATION && <PaymentInfoInCreation />}

            {!showSipoBox && isUnpaid && (
              <PaymentInfoUnpaid
                {...paymentInfoForBaNumber}
                billingAccountNumber={baNumber}
              />
            )}

            {!showSipoBox &&
              paymentInfo.loaded &&
              paymentInfoForBaNumber &&
              !paymentInfoForBaNumber.isSipoActive &&
              successStatuses.indexOf(paymentInfoForBaNumber.status) > -1 && (
                <PaymentInfoPaid {...paymentInfoForBaNumber} />
              )}

            {paymentInfo.loaded && showSipoBox && (
              <SipoPaymentInfo {...paymentInfoForBaNumber} />
            )}
            {paymentInfo.loaded &&
              paymentInfoForBaNumber.isDirectDebitEligible && (
                <MessageWrapper>
                  <Message
                    type="info-blue"
                    message={
                      <VStack spacing={'12px'} align={'flex-start'}>
                        <SanitizedHtml
                          htmlTag="span"
                          rawHtmlData={t('billing:directDebit.message')}
                        />
                        <Button
                          variant={'secondary'}
                          onClick={() =>
                            redirect({
                              internalRouteId:
                                RoutesEnum.ACTIVE_SERVICES_ROUTE_ID,
                              params: { dl: 'direct_debit' },
                              language:
                                i18n.language === LanguagesEnum.CS
                                  ? LanguagesEnum.CS
                                  : LanguagesEnum.EN,
                            })
                          }
                        >
                          {t('billing:directDebit.button')}
                        </Button>
                      </VStack>
                    }
                  />
                </MessageWrapper>
              )}
          </>
        )}
      </FadeInWrapper>
    </PaymentInfoWrapper>
  );
};

export default PaymentInfo;
