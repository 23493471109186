import SubscriptionTypeEnum, {
  allowedSubscriptionTypeForPrefix,
} from 'src/store/user/types/SubscriptionTypeEnum';

export const formatServiceNumber = (
  serviceNumber: string,
  nationalFormatSupport?: boolean,
): string => {
  const regExp = /\B(?=(\d{3})+(?!\d))/g;

  if (serviceNumber && serviceNumber.length === 12) {
    return serviceNumber.substring(3).replace(regExp, ' ');
  }

  if (serviceNumber && serviceNumber.length === 9 && nationalFormatSupport) {
    return serviceNumber.replace(regExp, ' ');
  }

  return serviceNumber;
};

export const addPrefix = (serviceNumber: string): string =>
  (serviceNumber.length === 12 && serviceNumber.startsWith('420')) ||
  serviceNumber.length !== 9
    ? serviceNumber
    : `420${serviceNumber}`;

export const hasSubscriptionTypePrefix = (
  subscriptionType: SubscriptionTypeEnum,
): boolean => {
  return allowedSubscriptionTypeForPrefix.indexOf(subscriptionType) > -1;
};

export const formatServiceNumberBySubscriptionType = (
  serviceNumber: string,
  subscriptionType: SubscriptionTypeEnum,
  withPrefix = false,
): string => {
  if (hasSubscriptionTypePrefix(subscriptionType)) {
    return formatServiceNumber(
      withPrefix ? addPrefix(serviceNumber) : serviceNumber,
    );
  }

  return serviceNumber;
};

export const removePrefix = (serviceNumber: string) => {
  return serviceNumber && serviceNumber.length === 12
    ? serviceNumber.substring(3)
    : serviceNumber;
};

export const addPrefixBySubscriptionType = (
  serviceNumber: string,
  subscriptionType: SubscriptionTypeEnum,
): string => {
  if (hasSubscriptionTypePrefix(subscriptionType)) {
    return addPrefix(serviceNumber);
  }

  return serviceNumber;
};

export const removeSpaces = (input: string): string => {
  return input.replaceAll(' ', '');
};
