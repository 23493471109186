import styled from 'styled-components';

export const ResultBase = styled.div`
  text-align: center;
  padding: 0 10px;
`;

export const IconSpacing = styled.div`
  margin-bottom: 25px;
`;

export const TitleSpacing = styled.div`
  margin-bottom: 16px;
`;

export const SubtitleSpacing = styled.div`
  margin-bottom: 32px;
`;

export const ExtraItemWrapper = styled.div`
  margin-bottom: 32px;
`;
