import { useTranslation } from 'react-i18next';
import { AuthLevel } from 'src/components/PersonalAccount/VFIDProfile';
import { useShowPassword } from 'src/features/profile';
import styled from 'styled-components';

const PasswordWrapper = styled.div`
  font-size: 33px;
  font-weight: bold;
  display: block;
  padding: 10px 0 25px 0;
`;

export const ShowPasswordModalBodyContent = ({
  crmType,
  crmId,
}: {
  crmType: AuthLevel;
  crmId: string;
}) => {
  const { t } = useTranslation(['component', 'customerIdentity']);
  const { data } = useShowPassword({
    crmType,
    crmId,
  });

  return data?.password ? (
    <>
      <p>{t('customerIdentity:passwordModal.description')}</p>
      <PasswordWrapper>{data.password}</PasswordWrapper>
    </>
  ) : (
    <p>
      {t(
        `${
          crmType === 'BillingAdmin'
            ? 'customerIdentity:passwordModal.not-exists-ba'
            : 'customerIdentity:passwordModal.not-exists'
        }`,
      )}
    </p>
  );
};
