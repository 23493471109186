import { http, HttpResponse } from 'msw';
import { HttpStatusCode } from 'src/enums';
import { GET_CORE_OUTAGE_ENDPOINT } from 'src/features/core';
import { resolveMock } from 'src/features/mocks';

const handlers = [
  http.get(GET_CORE_OUTAGE_ENDPOINT, async () => {
    return HttpResponse.json({
      isReadMode: false,
      isXbotEnabled: false,
      isFullOutage: false,
    });
  }),
];

export const getCoreOutageScenarios = {
  error_503: [
    http.get(GET_CORE_OUTAGE_ENDPOINT, async () => {
      return new HttpResponse(null, {
        status: HttpStatusCode.SERVICE_UNAVAILABLE_503,
      });
    }),
  ],
  readMode: [
    http.get(GET_CORE_OUTAGE_ENDPOINT, async () => {
      return HttpResponse.json({
        isReadMode: true,
        isXbotEnabled: false,
        isFullOutage: false,
      });
    }),
  ],
  fullOutage: [
    http.get(GET_CORE_OUTAGE_ENDPOINT, async () => {
      return HttpResponse.json({
        isReadMode: false,
        isXbotEnabled: false,
        isFullOutage: true,
      });
    }),
  ],
};

export const getCoreOutageMock = () =>
  resolveMock(handlers, getCoreOutageScenarios, GET_CORE_OUTAGE_ENDPOINT);
