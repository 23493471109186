export const PAGE_ANALYTICS_SET = 'PAGE_ANALYTICS_SET';
export const PREFIX = '/muj/';
export const PAGE_LANGUAGE_CZ = 'CZ';
export const PAGE_LANGUAGE_EN = 'EN';
export const ANALYTICS_LOGGED_IN = 'logged_in';
export const ANALYTICS_LOGGED_OUT = 'logged_out';

export const ANALYTICS_FORM_FORGOT_PASSWORD_STEP_1 = 'forgotPasswordStep1';
export const ANALYTICS_FORM_FORGOT_PASSWORD_STEP_2 = 'forgotPasswordStep2';
export const ANALYTICS_FORM_FORGOT_PASSWORD_STEP_3 = 'forgotPasswordStep3';
export const ANALYTICS_FORM_UNSUBSCRIBE = 'unsubscribe';
export const ANALYTICS_FORM_REGISTRATION_BY_EMAIL = 'registrationByEmail';

export const ANALYTICS_FORCED_CI_MIGRATION_SUFFIX = 'forcedCiMigration';
export const ANALYTICS_FORCED_CI_MIGRATION_WRONG_NUMBER_SUFFIX =
  'Chyba čísla:forcedCiMigration';
