import {
  Button,
  Card,
  IconClose,
  IconSuccessTick,
  RouterIcon,
  Text,
  Title,
} from '@vodafone/red-white';
import { FC, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { HStack } from 'src/components/Layout';
import { ModalContentProps } from 'src/components/Modal/types/modalContentInterfaces';
import { useActiveFbbServiceNumber } from 'src/components/Services/components/ServiceManagement/FbbActiveModemDevicesService/hooks/useActiveFbbServiceNumber';
import { Modem, useCpeDeviceRestart } from 'src/features/services';
import styled, { ThemeContext } from 'styled-components';

const CustomCardPadding = styled.div`
  padding: 40px 0 0 0;
  text-align: center;
`;

type OptionProps = {
  modem: Modem;
};

const ModemRestartModalContent: FC<ModalContentProps<OptionProps>> = ({
  setCompact,
  setCompactContent,
  onClose,
  options,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['services', 'component']);
  const { mutate, isSuccess, isPending, isError } = useCpeDeviceRestart();
  const themeContext = useContext(ThemeContext);
  const { fbbServiceNumber } = useActiveFbbServiceNumber();

  useEffect(() => {
    setCompact(true);
    setCompactContent(true);
  }, [setCompactContent, setCompact, dispatch]);

  const onClickHandler = () => {
    mutate({
      serviceNumber: fbbServiceNumber,
      deviceIdentifier: options.modem.cpeManagement.deviceIdentifier,
    });
  };

  return (
    <Card
      style={{ maxWidth: 590, width: '100%', boxShadow: 'none' }}
      waiting={isPending}
    >
      <CustomCardPadding>
        {isSuccess && (
          <>
            <IconSuccessTick size={100} />
            <Title
              color="darkGrey"
              fontFamily="VodafoneRg"
              fontWeight="bold"
              level={3}
              style={{ marginBottom: 15, marginTop: 15 }}
              fontSize={22}
            >
              {t('services:modem-restart-modal.success.title')}
            </Title>
            <Text fontFamily="VodafoneRg" fontSize={16}>
              {t('services:modem-restart-modal.success.description')}
            </Text>
            <div style={{ marginTop: 30 }}>
              <Button variant="secondary" onClick={() => onClose()}>
                {t('component:button.close')}
              </Button>
            </div>
          </>
        )}

        {isError && (
          <>
            <IconClose size={150} color={themeContext.colors.red} />
            <Title
              color="darkGrey"
              fontFamily="VodafoneRg"
              fontWeight="bold"
              level={3}
              style={{ marginBottom: 15 }}
              fontSize={22}
            >
              {t('services:modem-restart-modal.error.title')}
            </Title>
            <Text fontFamily="VodafoneRg" fontSize={16}>
              {t('services:modem-restart-modal.error.description')}
            </Text>
            <div style={{ marginTop: 30 }}>
              <Button variant="secondary" onClick={() => onClose()}>
                {t('component:button.close')}
              </Button>
            </div>
          </>
        )}

        {(isPending || (!isPending && !isSuccess && !isError)) && (
          <>
            <RouterIcon size={100} />
            <Title
              color="darkGrey"
              fontFamily="VodafoneRg"
              fontWeight="bold"
              level={3}
              style={{ marginBottom: 20 }}
              fontSize={22}
            >
              {t('services:modem-restart-modal.title')}
            </Title>

            <Text fontFamily="VodafoneRg" fontSize={16}>
              {t('services:modem-restart-modal.description')}
            </Text>

            <HStack
              justify="center"
              spacing="24px"
              align="center"
              style={{ marginTop: 30 }}
            >
              <Button variant="primary" onClick={onClickHandler}>
                {t('services:modem-restart-modal.confirm-restart')}
              </Button>
              <Button variant="secondary" onClick={() => onClose()}>
                {t('component:button.close')}
              </Button>
            </HStack>
          </>
        )}
      </CustomCardPadding>
    </Card>
  );
};

export default ModemRestartModalContent;
