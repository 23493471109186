import { AxiosResponse } from 'axios';
import {
  API_ENDPOINT_MFA_VERIFICATION_SECURE,
  API_ENDPOINT_MFA_VERIFICATION_SECURE_VERIFY_CODE,
} from 'src/api';
import IVerificationSecureRequest from 'src/api/mfa/request/verificationSecureRequest';
import IVerificationSecureVerifyCodeRequest from 'src/api/mfa/request/verificationSecureVerifyCodeRequest';
import IVerificationSecureResponse from 'src/api/mfa/response/verificationSecureResponse';
import IVerificationSecureVerifyCodeResponse from 'src/api/mfa/response/verificationSecureVerifyCodeResponse';
import { IRestResponseFault } from 'src/api/types/IRestResponseFault';
import api from 'src/utils/api/api';
import { translateApiEndpoint } from 'src/utils/router/translateApiEndpoint';

export const postVerificationSecure = (
  request: IVerificationSecureRequest,
): Promise<AxiosResponse<IVerificationSecureResponse | IRestResponseFault>> =>
  api.post({
    url: `${translateApiEndpoint(API_ENDPOINT_MFA_VERIFICATION_SECURE)}`,
    data: request,
  });

export const postVerificationSecureVerifyCode = (
  request: IVerificationSecureVerifyCodeRequest,
): Promise<
  AxiosResponse<IVerificationSecureVerifyCodeResponse | IRestResponseFault>
> => {
  return api.post({
    url: `${translateApiEndpoint(
      API_ENDPOINT_MFA_VERIFICATION_SECURE_VERIFY_CODE,
    )}`,
    data: request,
  });
};
