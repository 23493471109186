import { useMutation } from '@tanstack/react-query';
import { LanguagesEnum } from 'src/types/translation/languagesEnum';
import api from 'src/utils/api/api';
import { redirect } from 'src/utils/redirect';
import { IDAAS_URL_PARAMETERS } from 'src/utils/router/router.consts';

export const RELOAD_USER_ENDPOINT = '/muj/auth/wsc-reload';
export const RELOAD_USER_MUTATION_KEY = 'reloadUserMutation';

type ReloadUserRequest = {
  redirectUri: string;
  language: LanguagesEnum;
  flow: string;
};

const reloadUserFn = async (request: ReloadUserRequest): Promise<void> => {
  const reloadUrl = new URL(window.location.origin + RELOAD_USER_ENDPOINT);
  reloadUrl.searchParams.append(IDAAS_URL_PARAMETERS.FLOW, request.flow);
  const response = await api.post({ url: reloadUrl.toString() });

  if (response.status === 200) {
    redirect({ url: request.redirectUri, language: request.language });
  }
};

export const useReloadUser = () => {
  return useMutation({
    mutationFn: reloadUserFn,
    mutationKey: [RELOAD_USER_MUTATION_KEY],
  });
};
