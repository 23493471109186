import { Button, Card, Radio, Row, Text, Title } from '@vodafone/red-white';
import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IESimOneNumberOneNumberItem } from 'src/api/esim/response/eSimOneNumberOneNumbersResponse';
import ModalMessageTemplate from 'src/components/Modal/components/ModalMessage/ModalMessageTemplate';
import ModalMessageTypeEnum from 'src/components/Modal/components/ModalMessage/types/ModalMessageTypeEnum';
import { ModalContentProps } from 'src/components/Modal/types/modalContentInterfaces';
import { useAppDispatch } from 'src/hooks/useAppDispatch';
import {
  eSimChangeSmsForkSettings,
  eSimChangeSmsForkSettingsResetStore,
} from 'src/store/esim/esimActions';
import { selectESimSmsForkSettings } from 'src/store/esim/esimSelectors';
import { selectServiceNumber } from 'src/store/user/userSelector';
import styled from 'styled-components';

const CustomCardPadding = styled.div`
  padding: 10px 0 0 0;
`;

const DeviceName = styled.div`
  font-size: 14px;
  padding: 10px 0 20px 0;
`;

const RadioDescription = styled.div`
  margin: -10px 0 0 37px;
  font-size: 14px;
  color: ${(props) => props.theme.colors.lightGrey10};
  width: 100%;
  line-height: 1.1;
  font-weight: normal;
`;

const ButtonWrapper = styled.div`
  text-align: center;
  margin: 40px 0 0 0;
`;

interface IProps extends ModalContentProps {
  options: {
    eSimOneNumberOneNumberItem: IESimOneNumberOneNumberItem;
  };
}

const ESimDeviceNotificationSettingsModalContent: FC<IProps> = ({
  setCompact,
  options,
  setCompactContent,
  onClose,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['esim', 'component']);
  const [isActive, setIsActive] = React.useState(
    options.eSimOneNumberOneNumberItem.smsForking,
  );
  const serviceNumber = useSelector(selectServiceNumber);
  const { loaded, loading, error } = useSelector(selectESimSmsForkSettings);

  useEffect(() => {
    dispatch(eSimChangeSmsForkSettingsResetStore());
    setCompact(true);
    setCompactContent(true);
  }, [setCompactContent, setCompact, dispatch]);

  const onCloseButtonClick = () => {
    onClose();
    dispatch(eSimChangeSmsForkSettingsResetStore());
  };

  return (
    <Card
      style={{ maxWidth: 700, width: '100%', boxShadow: 'none' }}
      waiting={loading}
    >
      <CustomCardPadding>
        {error && (
          <ModalMessageTemplate
            type={ModalMessageTypeEnum.FAILURE}
            button={{
              label: t('component:button.close'),
              onClick: onCloseButtonClick,
            }}
          />
        )}
        {loaded && (
          <ModalMessageTemplate
            type={ModalMessageTypeEnum.SUCCESS}
            button={{
              label: t('component:button.close'),
              onClick: onCloseButtonClick,
            }}
          />
        )}

        {!error && !loaded && (
          <>
            <Title
              color="darkGrey"
              fontFamily="VodafoneRg"
              fontWeight="bold"
              level={3}
              fontSize={18}
              style={{ textAlign: 'center', fontSize: 28 }}
            >
              {t('esim:notificationModal.title')}
              <DeviceName>
                {options.eSimOneNumberOneNumberItem.nickname
                  ? `${options.eSimOneNumberOneNumberItem.nickname} - `
                  : ``}
                {t('esim:notificationModal.eid', {
                  eid: options.eSimOneNumberOneNumberItem.eId,
                })}
              </DeviceName>
            </Title>

            <Text
              color="darkGrey"
              fontFamily="VodafoneRg"
              fontSize={16}
              block
              style={{
                marginBottom: '15px',
                paddingBottom: '15px',
                borderBottom: '1px solid #f2f2f2',
              }}
            >
              {t('esim:notificationModal.description')}
            </Text>

            <Row
              style={{
                marginBottom: 10,
                fontWeight: !isActive ? 'bold' : 'normal',
              }}
            >
              <Radio
                id="esim-device-activate"
                checked={!isActive}
                label={t('esim:notificationModal.deactivate-label')}
                onActivation={() => {
                  setIsActive(false);
                }}
              />
              <RadioDescription>
                {t('esim:notificationModal.deactivate-description')}
              </RadioDescription>
            </Row>

            <Row style={{ fontWeight: isActive ? 'bold' : 'normal' }}>
              <Radio
                id="esim-device-deactivate"
                checked={isActive}
                label={t('esim:notificationModal.activate-label')}
                onActivation={() => {
                  setIsActive(true);
                }}
              />
              <RadioDescription>
                {t('esim:notificationModal.activate-description')}
              </RadioDescription>
            </Row>

            <ButtonWrapper>
              <Button
                variant="primary"
                onClick={() => {
                  dispatch(
                    eSimChangeSmsForkSettings(
                      serviceNumber,
                      isActive,
                      options.eSimOneNumberOneNumberItem.productOfferingId,
                    ),
                  );
                }}
              >
                {t('component:button.save')}
              </Button>
            </ButtonWrapper>
          </>
        )}
      </CustomCardPadding>
    </Card>
  );
};

export default ESimDeviceNotificationSettingsModalContent;
