import {
  Button,
  IconErrorCircle,
  IconLock,
  ModalMessage,
  SingleInputContent,
} from '@vodafone/red-white';
import { Formik, FormikActions, FormikProps } from 'formik';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'src/hooks/useAppDispatch';
import { openMnpServiceDetailModal } from 'src/store/mnp/serviceDetail/mnpServiceDetailActions';
import { getMnpServiceDetail } from 'src/store/mnp/serviceDetail/mnpServiceDetailSelectors';
import IMnpServiceDetailState from 'src/store/mnp/serviceDetail/types/mnpServiceDetailStateInterface';
import { requestValidationSmsRequest } from 'src/store/requestValidationSms/requestValidationSmsAction';
import { requestValidationSms } from 'src/store/requestValidationSms/requestValidationSmsSelector';
import IRequestValidationSmsState from 'src/store/requestValidationSms/types/requestValidationSmsStateInterface';
import { selectMnpService } from 'src/store/services/selectors/serviceSelectors';
import { IService } from 'src/store/services/types/service/serviceInterface';
import ISubscription from 'src/store/user/types/subscriptionInterface';
import { selectCurrentSubscription } from 'src/store/user/userSelector';
import IVerifySmsCodeState from 'src/store/verifySmsCode/types/verifySmsCodeStateInterface';
import {
  validateSmsCode,
  validateSmsCodeInvalidate,
} from 'src/store/verifySmsCode/verifySmsCodeAction';
import { verifySmsCodeSelector } from 'src/store/verifySmsCode/verifySmsCodeSelectors';
import SanitizedHtml from '../../../utils/sanitizeHtml';
import { formatServiceNumber } from '../../../utils/serviceNumberFormatter';
import { ModalContentProps } from '../types/modalContentInterfaces';

interface IFormValues {
  userInput: string;
}

const SmsValidationModalContent: FC<ModalContentProps> = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['services', 'component']);
  const { serviceNumber }: ISubscription = useSelector(
    selectCurrentSubscription,
  );
  const { mnpType }: IMnpServiceDetailState = useSelector(getMnpServiceDetail);
  const service: IService = useSelector(selectMnpService);
  const smsVerification: IVerifySmsCodeState = useSelector(
    verifySmsCodeSelector,
  );
  const requestSmsVerification: IRequestValidationSmsState =
    useSelector(requestValidationSms);
  const formattedServiceNumber: string = formatServiceNumber(serviceNumber);
  const [timeLeft, setTimeLeft] = useState(
    requestSmsVerification.data.secondsTillNextRequest,
  );

  useEffect(() => {
    if (smsVerification.loaded && smsVerification.data.isVerified === true) {
      dispatch(openMnpServiceDetailModal(service, mnpType, serviceNumber));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [smsVerification]);

  useEffect(() => {
    setTimeLeft(requestSmsVerification.data.secondsTillNextRequest);
  }, [requestSmsVerification]);

  useEffect(() => {
    if (timeLeft === 0) {
      setTimeLeft(null);
    }

    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [timeLeft]);

  const processSubmit = (
    values: IFormValues,
    { setSubmitting }: FormikActions<IFormValues>,
  ) => {
    dispatch(validateSmsCode(serviceNumber, values.userInput));
    setSubmitting(false);
  };

  const resendSmsButtonText = (): string => {
    return timeLeft > 0
      ? t('verification_code_modal.request_sms_time_cap', {
          seconds: timeLeft,
        })
      : t('verification_code_modal.send_again');
  };

  return (
    <>
      {!smsVerification.loaded && (
        <>
          <ModalMessage
            heading={t('verification_code_modal.title')}
            icon={<IconLock color="grey" size={100} />}
            color="green"
          >
            &nbsp;
          </ModalMessage>
          <SanitizedHtml
            htmlTag="p"
            rawHtmlData={t('verification_code_modal.send_to_number', {
              formattedServiceNumber,
            })}
            style={{ textAlign: 'center' }}
          />

          <Formik initialValues={{ userInput: '' }} onSubmit={processSubmit}>
            {({
              values,
              errors,
              touched,
              handleChange,
              isSubmitting,
              handleSubmit,
            }: FormikProps<IFormValues>) => (
              <SingleInputContent
                singleInputProps={{
                  label: t('verification_code_modal.verification_code'),
                  labelUnderline: t('verification_code_modal.code_validity'),
                  value: values.userInput,
                  onChange: handleChange,
                  errored: errors.userInput && touched.userInput,
                }}
                submitButtonProps={{
                  label: t('component:button.continue'),
                  disabled:
                    isSubmitting ||
                    !values.userInput ||
                    smsVerification.loading,
                }}
                linkButtonProps={{
                  label: resendSmsButtonText(),
                  disabled: timeLeft > 0,
                }}
                onSubmit={handleSubmit}
                onLinkClick={() =>
                  dispatch(requestValidationSmsRequest(serviceNumber))
                }
              />
            )}
          </Formik>
        </>
      )}

      {smsVerification.loaded && !smsVerification.data.isVerified && (
        <>
          <ModalMessage
            heading={t('verification_code_modal.wrong_code')}
            icon={<IconErrorCircle color="red" size={60} />}
            color="red"
          >
            &nbsp;
          </ModalMessage>
          <p style={{ textAlign: 'center' }}>
            <Button
              htmlElement="button"
              variant="primary"
              onClick={() => dispatch(validateSmsCodeInvalidate())}
            >
              {t('component:button:back')}
            </Button>
          </p>
        </>
      )}
    </>
  );
};

export default SmsValidationModalContent;
