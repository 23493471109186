export enum ServiceRequestType {
  ELECTRONIC_REQUEST = 'Electronic request',
  WSC_CUSTOMER_REQUEST = 'Požadavek zákazníka z WSC',
  FST_REQUEST_CONSUMER = 'Nastavení služeb - nefiremní',
  FST_REQUEST_BUSINESS = 'Nastavení služeb - firemní',
  FST_REQUEST = 'Nastavení služeb',
  TECH_HELPDESK = 'Technický Helpdesk',
}

export enum ServiceRequestArea {
  CUSTOMER_DATA_CHANGE = 'Změna zákaznických údajů',
  MSL_TERMINATION = 'Terminace MSL smlouvy ',
  NOT_SUBMITTED_MSL_TERMINATION = 'Neodeslaná terminace MSL sml.',
  HI_REPAYMENT = 'Předčasné splacení telefonu',
  NO_DEBT_CONFIRMATION = 'Potvrzení o bezdlužnosti',
  TRANSFER_OF_RESPONSIBILITY = 'Převod zodpovědnosti',
  SPECIAL_TARIFF_PLAN = 'Žádost o zvláštní cenový plán',
  BMSL_PENALTY_ESTIMATION = 'Žádost o odhad výše pokuty',
  FIX_LINE_RELOCATION = 'Změna inst. adresy pevné linky',
  DEPOSIT_RETURN_REQUEST = 'Žádost o vrácení dep. do zahr.',
  BILLING_ACCOUNT_CHANGE = 'WSC žádost o změnu plátce',
  GDPR = 'GDPR',
  COMBINED_REPAIR_OFFERS = 'Kombinovaná nabídka - Opravy',
  INTERNET_THD = 'Internet - THD',
}
