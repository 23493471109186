import {
  GlobalStyle,
  ThemeRedesign as themeRedesign,
} from '@vodafone/red-white';
import { ThemeProvider } from 'styled-components';
import ErrorMessage from './ErrorMessage';

const StyledErrorMessage = () => {
  return (
    <>
      <GlobalStyle />
      <ThemeProvider theme={themeRedesign}>
        <ErrorMessage />
      </ThemeProvider>
    </>
  );
};

export default StyledErrorMessage;
