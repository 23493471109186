enum ModalType {
  ConnectedDevicesToModem = 'ConnectedDevicesToModem',
  CreateServiceRequest = 'CreateServiceRequest',
  ModemRestart = 'ModemRestart',
  ModemFactoryReset = 'ModemFactoryReset',
  IotAndDataSimCardInfo = 'IotAndDataSimCardInfo',
  ESimDeviceNotificationSettings = 'ESimDeviceNotificationSettings',
  Mfa = 'Mfa',
  ErrorWhileRedirectToOnePortal = 'ErrorWhileRedirectToOnePortal',
  SimActivationConfirm = 'SimActivationConfirm',
  ShowPassword = 'ShowPassword',
  LostStolenConfirm = 'LostStolenConfirm',
  CustomerIdentityLoginBanner = 'CustomerIdentityLoginBanner',
  DifferentPayment = 'DifferentPayment',
  VtvRemoveActiveDevice = 'VtvRemoveActiveDevice',
  VtvGeneratePassword = 'VtvGeneratePassword',
  VtvSetPassword = 'VtvSetPassword',
  VtvSetPasswordStatus = 'VtvSetPasswordStatus',
  VtvCasPinReset = 'VtvCasPinReset',
  VtvCasRefreshSubscription = 'VtvCasRefreshSubscription',
  MnpOku = 'MnpOku',
  MnpInternetOku = 'MnpInternetOku',
  MnpCvop = 'MnpCvop',
  MnpPenalty = 'MnpPenalty',
  MnpInternetPenalty = 'MnpInternetPenalty',
  SmsVerification = 'SmsVerification',
  GrantAccess = 'GrantAccess',
  DeleteProfile = 'DeleteProfile',
  PaymentInfo = 'PaymentInfo',
  RoamingCalc = 'RoamingCalc',
  PaymentStatus = 'PaymentStatus',
  RemoveAccess = 'RemoveAccess',
  ChangeNickname = 'ChangeNickname',
  UPCBillingHistory = 'UPCBillingHistory',
  ReceivedPayments = 'ReceivedPayments',
  DownloadInvoicesInXls = 'DownloadInvoicesInXls',
  EETReceipts = 'EETReceipts',
  FbbModemSettings = 'FbbModemSettings',
  ChangeContactNumber = 'ChangeContactNumber',
  OrderNewESim = 'OrderNewESim',
  OrderNewESimMfa = 'OrderNewESimMfa',
  ActivateESim = 'ActivateESim',
  PreactivatedESim = 'PreactivatedESim',
  ActivateDeletedESim = 'ActivateDeletedESim',
  TransferESim = 'TransferESim',
  CompleteSimSwapContent = 'CompleteSimSwapContent',
  ESimInDelivery = 'ESimInDelivery',
  RecurringDataActivated = 'RecurringDataActivated',
  DiscountCodes = 'DiscountCodes',
  ModemDiagModal = 'ModemDiagModal',
}

export default ModalType;
