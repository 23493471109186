import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AuthLevel } from 'src/components/PersonalAccount/VFIDProfile';
import api from 'src/utils/api/api';

export const DELETE_IDENTITY_ENDPOINT =
  '/muj/rapi/account-management/identity/delete';

export const DELETE_IDENTITY_MUTATION_KEY = 'removeIdentityMutation';
export const DELETE_IDENTITY_KEY = 'removeIdentity';

type DeleteIdentityRequest = {
  id?: string;
  partyRoleId: string;
  type: AuthLevel;
};

type DeleteIdentityError = {
  message: {
    code: string;
    description: string;
  };
};

type DeleteIdentityResponse = {
  status: string;
};

const deleteIdentityFn = async (
  request: DeleteIdentityRequest,
): Promise<DeleteIdentityResponse> => {
  const response = await api
    .post<DeleteIdentityResponse>({
      url: DELETE_IDENTITY_ENDPOINT,
      data: request,
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });

  return response.data;
};

export const useDeleteIdentity = () => {
  const queryClient = useQueryClient();

  return useMutation<
    DeleteIdentityResponse,
    DeleteIdentityError,
    DeleteIdentityRequest
  >({
    mutationFn: deleteIdentityFn,
    mutationKey: [DELETE_IDENTITY_MUTATION_KEY],
    onSuccess: (data, variables) => {
      queryClient.setQueryData([DELETE_IDENTITY_KEY, { variables }], data);
    },
  });
};
