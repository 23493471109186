import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { VerificationSecureMessageType } from 'src/api/mfa/request/verificationSecureRequest';
import { ModalContentProps } from 'src/components/Modal/types/modalContentInterfaces';
import { useMfa } from 'src/hooks/useMfa';
import { openModal } from 'src/store/modal/modalActions';
import ModalType from 'src/store/modal/types/ModalType';
import ISubscription from 'src/store/user/types/subscriptionInterface';
import { selectCurrentSubscription } from 'src/store/user/userSelector';

const OrderNewESimMfaContent: FC<ModalContentProps> = ({
  setCompact,
  setCompactContent,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    setCompact(true);
    setCompactContent(true);
  }, [setCompactContent, setCompact, dispatch]);

  const subscription: ISubscription = useSelector(selectCurrentSubscription);
  const { serviceNumber } = subscription;

  const { verify } = useMfa({
    messageType: VerificationSecureMessageType.ESIM_SWAP,
    onSuccessCallback: () => {
      dispatch(
        openModal({
          type: ModalType.OrderNewESim,
          options: {
            serviceNumber,
          },
        }),
      );
    },
  });

  useEffect(() => {
    verify();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
};

export default OrderNewESimMfaContent;
