import { ActionCreator } from 'redux';
import { getEetReceiptDownloadUrl, getEetReceipts } from 'src/api/eetReceipts';
import {
  IFetchEetReceiptsBeginAction,
  IFetchEetReceiptsFailureAction,
  IFetchEetReceiptsSuccessAction,
} from './types/eetReceiptsActionInterfaces';
import { EetReceiptsActionTypes } from './types/eetReceiptsActionTypes';

export const fetchEetReceiptsBegin: ActionCreator<
  IFetchEetReceiptsBeginAction
> = () => ({
  type: EetReceiptsActionTypes.FETCH_EET_RECEIPTS_BEGIN,
});

export const fetchEetReceiptsSuccess: ActionCreator<
  IFetchEetReceiptsSuccessAction
> = (payload) => ({
  type: EetReceiptsActionTypes.FETCH_EET_RECEIPTS_SUCCESS,
  payload,
});

export const fetchEetReceiptsFailure: ActionCreator<
  IFetchEetReceiptsFailureAction
> = () => ({
  type: EetReceiptsActionTypes.FETCH_EET_RECEIPTS_FAILURE,
});

export const fetchEetReceipts = (baNumber: string) => async (dispatch) => {
  dispatch(fetchEetReceiptsBegin());

  return getEetReceipts(baNumber)
    .then((response) => {
      dispatch(fetchEetReceiptsSuccess(response.data));
    })
    .catch(() => {
      dispatch(fetchEetReceiptsFailure());
    });
};

export const downloadEetReceipt =
  (baNumber: string, documentId: string) => () => {
    window.open(getEetReceiptDownloadUrl(baNumber, documentId), '_blank');
  };
