import { Reducer } from 'redux';
import initialState from '../initialState';
import {
  ICasDeviceResetPinBeginAction,
  ICasDeviceResetPinFailureAction,
  ICasDeviceResetPinStateAction,
  ICasDeviceResetPinSuccessAction,
  IRefreshCasSubscriptionBeginAction,
  IRefreshCasSubscriptionFailureAction,
  IRefreshCasSubscriptionResetStateAction,
  IRefreshCasSubscriptionSuccessAction,
} from './types/casActionInterface';
import CasActionTypes from './types/casActionTypes';
import { ICasState } from './types/casStateInterface';

type CasAction =
  | IRefreshCasSubscriptionResetStateAction
  | IRefreshCasSubscriptionBeginAction
  | IRefreshCasSubscriptionSuccessAction
  | IRefreshCasSubscriptionFailureAction
  | ICasDeviceResetPinStateAction
  | ICasDeviceResetPinBeginAction
  | ICasDeviceResetPinSuccessAction
  | ICasDeviceResetPinFailureAction;

const casReducer: Reducer<ICasState, CasAction> = (
  state: ICasState = initialState.Cas,
  action: CasAction,
) => {
  switch (action.type) {
    case CasActionTypes.REFRESH_CAS_SUBSCRIPTION_RESET_STATE:
      return {
        ...state,
        refreshSubscription: {
          loading: false,
          loaded: false,
          error: false,
          data: {},
        },
      };

    case CasActionTypes.CAS_DEVICE_PIN_RESET_STATE:
      return {
        ...state,
        resetPin: {
          loading: false,
          loaded: false,
          error: false,
          data: {},
        },
      };

    case CasActionTypes.REFRESH_CAS_SUBSCRIPTION_BEGIN:
      return {
        ...state,
        refreshSubscription: {
          ...state.refreshSubscription,
          loading: true,
          loaded: false,
          error: false,
          data: {},
        },
      };

    case CasActionTypes.CAS_DEVICE_PIN_RESET_BEGIN:
      return {
        ...state,
        resetPin: {
          ...state.resetPin,
          loading: true,
          loaded: false,
          error: false,
          data: {},
        },
      };

    case CasActionTypes.REFRESH_CAS_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        refreshSubscription: {
          loading: false,
          loaded: true,
          error: false,
          data: {
            result: action.payload.result,
          },
        },
      };

    case CasActionTypes.CAS_DEVICE_PIN_RESET_SUCCESS:
      return {
        ...state,
        resetPin: {
          loading: false,
          loaded: true,
          error: false,
          data: {
            result: action.payload.result,
          },
        },
      };

    case CasActionTypes.REFRESH_CAS_SUBSCRIPTION_FAILURE:
      return {
        ...state,
        refreshSubscription: {
          loading: false,
          loaded: true,
          error: true,
          data: {
            result: action.payload.result,
            errorCode: action.payload.message.code,
            message: action.payload.message.description,
          },
        },
      };

    case CasActionTypes.CAS_DEVICE_PIN_RESET_FAILURE:
      return {
        ...state,
        resetPin: {
          loading: false,
          loaded: true,
          error: true,
          data: {
            result: action.payload.result,
            errorCode: action.payload.message.code,
            message: action.payload.message.description,
          },
        },
      };

    default:
      return state;
  }
};

export default casReducer;
