import { Reducer } from 'redux';
import initialState from '../initialState';
import {
  IVerifySmsCodeBeginAction,
  IVerifySmsCodeFailureAction,
  IVerifySmsCodeInvalidateAction,
  IVerifySmsCodeSuccessAction,
} from './types/verifySmsCodeActionInterfaces';
import VerifySmsCodeActionTypes from './types/verifySmsCodeActionTypes';
import IVerifySmsCodeState from './types/verifySmsCodeStateInterface';

type IVerifySmsCodeActions =
  | IVerifySmsCodeBeginAction
  | IVerifySmsCodeSuccessAction
  | IVerifySmsCodeFailureAction
  | IVerifySmsCodeInvalidateAction;

const verifySmsCodeReducer: Reducer<IVerifySmsCodeState> = (
  state: IVerifySmsCodeState = initialState.VerifiedSmsCode,
  action: IVerifySmsCodeActions,
) => {
  switch (action.type) {
    case VerifySmsCodeActionTypes.VALIDATE_SMS_CODE_BEGIN:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      };
    case VerifySmsCodeActionTypes.VALIDATE_SMS_CODE_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        loaded: true,
        error: false,
      };
    case VerifySmsCodeActionTypes.VALIDATE_SMS_CODE_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    case VerifySmsCodeActionTypes.VALIDATE_SMS_CODE_INVALIDATE:
      return {
        ...state,
        data: null,
        loading: false,
        loaded: false,
        error: false,
      };
    default:
      return state;
  }
};

export default verifySmsCodeReducer;
