/* eslint-disable */

/**
 * validates the password strength from email reset flow
 * @param password
 */
export const validateEmailPassword = (password: string) => {
  const validation = {
    passwordLength: false,
    symbols: {
      lower: false,
      upper: false,
      special: false,
      number: false,
    },
  };

  validation.passwordLength = password.length >= 8;
  validation.symbols.number = !!/\d/.test(password);
  validation.symbols.lower = !!/[a-z]/.test(password);
  validation.symbols.upper = !!/[A-Z]/.test(password);
  validation.symbols.special = !!/[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(
    password,
  );

  const symbolRulesCompletedCount = Object.keys(validation.symbols).reduce(
    (accum, symbolName) => {
      return validation.symbols[symbolName] ? ++accum : accum;
    },
    0,
  );

  const isSymbolsValid = symbolRulesCompletedCount >= 3;

  return {
    isValid: validation.passwordLength && isSymbolsValid,
    isSymbolsValid: symbolRulesCompletedCount >= 3,
    ...validation,
  };
};

export const validateVtvPassword = (password: string) => {
  const validation = {
    passwordLength: false,
    symbols: false,
  };

  validation.passwordLength = password.length >= 6 && password.length <= 10;
  validation.symbols = !!/^[a-zA-Z0-9]+$/.test(password);

  const allRulesCompletedCount = Object.keys(validation).reduce(
    (accum, symbolName) => {
      return validation[symbolName] ? ++accum : accum;
    },
    0,
  );

  const isPasswordValid = allRulesCompletedCount >= 2;

  return {
    isValid: isPasswordValid,
    ...validation,
  };
};

export const getSymbolStatus = (validation, symbolName) =>
  validation && validation.symbols[symbolName]
    ? 'success'
    : validation && !validation.isSymbolsValid
    ? 'default'
    : 'error';

/**
 * validates the password strength from number reset flow
 * @param password
 */
export const validateNumberPassword = (password: string) => {
  const passwordArr = password.split('');
  const uniquePasswordArr = new Set(passwordArr);

  const validation = {
    isNumeric: Number.isInteger(Number(password)),
    passwordLength: password.length >= 6 && password.length <= 15,
    threeUnique: Array.from(uniquePasswordArr).length >= 3,
    // to match circular sequence as well.
    notEasyPattern:
      '01234567890'.indexOf(password) === -1 &&
      '9876543210'.indexOf(password) === -1,
  };

  const isSymbolsValid = Object.keys(validation).filter((k) => validation[k]);

  return {
    isValid: isSymbolsValid.length >= 4,
    ...validation,
  };
};
