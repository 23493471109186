import { AxiosResponse } from 'axios';
import IPaymentStatus from 'src/store/billing/paymentStatus/types/paymentStatusInterface';
import IPaymentTransaction from 'src/store/billing/paymentTransaction/types/paymentTransactionInterface';
import api from 'src/utils/api/api';
import {
  API_ENDPOINT_BILLING_CUSTOMER,
  API_ENDPOINT_PAYMENT_INFO,
  API_ENDPOINT_PAYMENT_PAYER_PAYMENTS,
  API_ENDPOINT_PAYMENT_STATUS,
  API_ENDPOINT_PAYMENT_TRANSACTION,
  API_ENDPOINT_UPC_INVOICES,
  API_ENDPOINT_UPC_INVOICE_DOWNLOAD,
} from '..';
import { IBillingCustomerResponse } from './response/billingCustomerResponseInterface';
import { IPayerPaymentResponse } from './response/payerPaymentResponseInterface';
import { IPaymentInfoResponse } from './response/paymentInfoResponseInterface';
import { IUpcInvocesResponse } from './response/upcInvocesResponseInterface';

export const getPaymentInfo = (
  baNumber: string,
): Promise<AxiosResponse<IPaymentInfoResponse>> =>
  api.get({
    url: `${API_ENDPOINT_PAYMENT_INFO}`.replace('{baNumber}', baNumber),
  });

export const postPaymentTransaction = (
  paymentMethod: string,
  billingAccountNumber: string,
  amount?: number,
): Promise<AxiosResponse<IPaymentTransaction>> =>
  api.post({
    url: `${API_ENDPOINT_PAYMENT_TRANSACTION}`,
    data: {
      paymentMethod,
      billingAccountNumber,
      amount,
    },
  });

export const getPaymentStatus = (
  paymentId: string,
): Promise<AxiosResponse<IPaymentStatus>> =>
  api.get({
    url: `${API_ENDPOINT_PAYMENT_STATUS.replace('{paymentId}', paymentId)}`,
  });

export const getBillingCustomer = (
  caNumber: string,
): Promise<AxiosResponse<IBillingCustomerResponse>> =>
  api.get({
    url: `${API_ENDPOINT_BILLING_CUSTOMER.replace('{caNumber}', caNumber)}`,
  });

export const getPayerPayment = (
  baNumber: string,
): Promise<AxiosResponse<IPayerPaymentResponse>> =>
  api.get({
    url: `${API_ENDPOINT_PAYMENT_PAYER_PAYMENTS}`.replace(
      '{baNumber}',
      baNumber,
    ),
  });

export const getUpcInvoices = (
  variableSymbol: string,
): Promise<AxiosResponse<IUpcInvocesResponse>> =>
  api.get({
    url: `${API_ENDPOINT_UPC_INVOICES}`.replace(
      '{variableSymbol}',
      variableSymbol,
    ),
  });

export const getUpcInvoiceDownloadUrl = (
  variableSymbol: string,
  invoiceId: string,
): string => {
  return API_ENDPOINT_UPC_INVOICE_DOWNLOAD.replace(
    '{variableSymbol}',
    variableSymbol,
  ).replace('{invoiceId}', invoiceId);
};
