import { ActionCreator } from 'redux';
import { postPaymentTransaction } from 'src/api/billing';
import { redirect } from '../../../utils/redirect';
import {
  ICreatePaymentTransactionBeginAction,
  ICreatePaymentTransactionFailureAction,
  ICreatePaymentTransactionSuccessAction,
} from './types/paymentTransactionActionInterfaces';
import PaymentTransactionActionTypes from './types/paymentTransactionActionTypes';

export const createPaymentTransactionBeginAction: ActionCreator<
  ICreatePaymentTransactionBeginAction
> = () => ({
  type: PaymentTransactionActionTypes.CREATE_PAYMENT_TRANSACTION_BEGIN,
});

export const createPaymentTransactionSuccessAction: ActionCreator<
  ICreatePaymentTransactionSuccessAction
> = (payload) => ({
  type: PaymentTransactionActionTypes.CREATE_PAYMENT_TRANSACTION_SUCCESS,
  payload,
});

export const createPaymentTransactionFailureAction: ActionCreator<
  ICreatePaymentTransactionFailureAction
> = () => ({
  type: PaymentTransactionActionTypes.CREATE_PAYMENT_TRANSACTION_FAILURE,
});

export const createPaymentTransaction =
  (paymentMethod: string, billingAccountNumber: string, amount?: number) =>
  (dispatch) => {
    dispatch(createPaymentTransactionBeginAction());
    postPaymentTransaction(paymentMethod, billingAccountNumber, amount)
      .then((response) => {
        dispatch(createPaymentTransactionSuccessAction(response.data));
        redirect({ url: response.data.redirectUrl });
      })
      .catch(() => {
        dispatch(createPaymentTransactionFailureAction());
      });
  };
