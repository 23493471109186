import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import IPaymentInfo from 'src/store/billing/paymentInfo/types/paymentInfoInterface';
import selectLanguage from 'src/store/language/languageSelector';
import { LanguagesEnum } from 'src/types/translation/languagesEnum';
import CardBillingPaymentSuccess from 'src/ui/components/Billing/CardBillingPaymentSuccess';
import toCurrency from 'src/utils/formatter/number/toCurrency';
import SanitizedHtml from 'src/utils/sanitizeHtml';

const PaymentInfoPaid: React.FC<IPaymentInfo> = ({
  status,
  overpaidAmount,
  billingAmount,
  isPaidInLastDayForBa,
}) => {
  const { t } = useTranslation('billing');
  const language: LanguagesEnum = useSelector(selectLanguage);

  return (
    <CardBillingPaymentSuccess
      title={
        isPaidInLastDayForBa ? (
          t(`billing:paymentInfo.title-PAID_LAST_DAY`)
        ) : (
          <SanitizedHtml
            htmlTag="span"
            rawHtmlData={t(
              `billing:paymentInfo.title-${status.toUpperCase()}`,
              {
                sufficientlyPaidAmount: toCurrency(billingAmount, language),
              },
            )}
          />
        )
      }
      subtitle={
        isPaidInLastDayForBa ? (
          t(`billing:paymentInfo.subtitle-PAID_LAST_DAY`)
        ) : (
          <SanitizedHtml
            htmlTag="span"
            rawHtmlData={t(
              `billing:paymentInfo.subtitle-${status.toUpperCase()}`,
              {
                amount: toCurrency(overpaidAmount, language),
              },
            )}
          />
        )
      }
    />
  );
};

export default PaymentInfoPaid;
