import { LanguagesEnum } from '../../types/translation/languagesEnum';
import { ITranslation } from '../../types/translationInterface';

const getTranslationKey = (language: string): string => {
  if (language === LanguagesEnum.CS) {
    return 'cze';
  }

  return 'eng';
};

export default (
  languageString: ITranslation,
  language: string,
  replaceValues?: object,
): string => {
  let string = languageString[getTranslationKey(language)];

  if (replaceValues) {
    Object.keys(replaceValues).forEach((key) => {
      string = string.replace(new RegExp(`%${key}%`, 'g'), replaceValues[key]);
    });
  }

  return string;
};
