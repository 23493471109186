import { AxiosResponse } from 'axios';
import { API_ENDPOINT_ESIM_DELIVERY } from 'src/api';
import { IRestResponseFault } from 'src/api/types/IRestResponseFault';
import { IRestResponseSuccess } from 'src/api/types/IRestResponseSuccess';
import api from 'src/utils/api/api';

export default (
  serviceNumber: string,
  eId?: string,
): Promise<AxiosResponse<IRestResponseSuccess | IRestResponseFault>> =>
  api.post({
    url: API_ENDPOINT_ESIM_DELIVERY.replace('{serviceNumber}', serviceNumber),
    data: eId ? { eId } : {},
  });
