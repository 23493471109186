import {
  Button,
  IconQRCode,
  IconSms,
  InputRedesign,
  Row,
  Text,
  Title,
} from '@vodafone/red-white';
import { Formik, FormikErrors, FormikProps } from 'formik';
import { FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ESimSimSwapRequestTypeEnum } from 'src/api/esim/request/eSimSimSwapTypeEnum';
import { ESimActivationMethods } from 'src/components/Modal/components/ESim/enums/ESimModalEnum';
import { useAppDispatch } from 'src/hooks/useAppDispatch';
import { eSimDelivery, eSimSimSwap } from 'src/store/esim/esimActions';
import SanitizedHtml from 'src/utils/sanitizeHtml';
import styled, { ThemeContext } from 'styled-components';
import { isValidEid } from '../utils/validators';

export type RequestFormValues = {
  contactNumber: string;
};

const TitleWrapper = styled(Title)`
  font-size: 26px;
  @media ${(props) => props.theme.breakpoints.smOnly} {
    font-size: 18px;
  }
`;

const DescriptionWrapper = styled.div`
  margin-top: 20px;
  @media ${(props) => props.theme.breakpoints.smOnly} {
    margin-top: 5px;
  }
`;

const ButtonWrapper = styled.div`
  margin-top: 24px;
  @media ${(props) => props.theme.breakpoints.smOnly} {
    margin-top: 5px;
  }
`;

const SendToDeviceWrapper = styled.div`
  margin-top: 24px;
`;

interface IFormValues {
  eid: string;
  method: string;
}

interface IProps {
  defaultMethod?: ESimActivationMethods;
  changeCurrentActivationMethod?: boolean;
  serviceNumberForESim: string;
}

const OrderNewESimFormContent: FC<IProps> = ({
  defaultMethod,
  changeCurrentActivationMethod,
  serviceNumberForESim,
}) => {
  const themeContext = useContext(ThemeContext);
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['esim']);
  const [activationMethod, setActivationMethod] =
    useState<ESimActivationMethods | null>(null);

  const onSelectMethod = (
    method: ESimActivationMethods,
    setFieldValue?: (
      field: string,
      value: any,
      shouldValidate?: boolean,
    ) => void,
  ) => {
    setActivationMethod(method);
    if (setFieldValue) {
      setFieldValue('method', method);
    }
  };

  useEffect(() => {
    if (defaultMethod) {
      onSelectMethod(defaultMethod);
    }
  }, [defaultMethod]);

  const formValidate = (values: IFormValues) => {
    const errors: FormikErrors<IFormValues> = {};

    if (
      values.method === ESimActivationMethods.EID &&
      !isValidEid(values.eid)
    ) {
      errors.eid = t('esim:orderNewESim.errors.eid-wrong-format');
    }

    return errors;
  };

  const canSubmitForm = (values: IFormValues) =>
    Object.keys(formValidate(values)).length === 0 && values.method !== null;

  const onSubmit = (values: IFormValues) => {
    // zmena metody doruceni
    if (changeCurrentActivationMethod) {
      dispatch(eSimDelivery(serviceNumberForESim, values.eid));
    } else if (!changeCurrentActivationMethod) {
      dispatch(
        eSimSimSwap(
          serviceNumberForESim,
          ESimSimSwapRequestTypeEnum.E_SIM_VIRTUAL,
          values.eid,
        ),
      );
    }
  };

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={{ eid: null, method: defaultMethod || null }}
      validate={formValidate}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        setFieldValue,
        handleSubmit,
        handleBlur,
      }: FormikProps<IFormValues>) => (
        <>
          <TitleWrapper
            color="darkGrey"
            fontFamily="VodafoneRg"
            fontWeight="bold"
          >
            {t('esim:orderNewESim.sub-title')}
          </TitleWrapper>

          <>
            <DescriptionWrapper>
              <Text fontSize={16} color="darkGrey">
                {t('esim:orderNewESim.description')}
              </Text>
            </DescriptionWrapper>
            <ButtonWrapper>
              <Button
                variant={
                  activationMethod === ESimActivationMethods.QR_CODE
                    ? 'primary'
                    : 'secondary'
                }
                onClick={() =>
                  onSelectMethod(ESimActivationMethods.QR_CODE, setFieldValue)
                }
                style={{ marginRight: 10 }}
              >
                <Row align="center">
                  <IconQRCode
                    size={25}
                    color={
                      activationMethod === ESimActivationMethods.QR_CODE
                        ? themeContext.colors.white
                        : themeContext.colors.darkGrey
                    }
                  />
                  <Text
                    fontSize={18}
                    fontWeight="bold"
                    style={{
                      paddingLeft: 10,
                      color:
                        activationMethod === ESimActivationMethods.QR_CODE
                          ? themeContext.colors.white
                          : themeContext.colors.darkGrey,
                    }}
                  >
                    {t('esim:orderNewESim:buttons.qr-code')}
                  </Text>
                </Row>
              </Button>

              <Button
                variant={
                  activationMethod === ESimActivationMethods.EID
                    ? 'primary'
                    : 'secondary'
                }
                onClick={() =>
                  onSelectMethod(ESimActivationMethods.EID, setFieldValue)
                }
              >
                <Row align="center">
                  <IconSms
                    size={25}
                    color={
                      activationMethod === ESimActivationMethods.EID
                        ? themeContext.colors.white
                        : themeContext.colors.darkGrey
                    }
                  />
                  <Text
                    fontSize={18}
                    fontWeight="bold"
                    style={{
                      paddingLeft: 10,
                      color:
                        activationMethod === ESimActivationMethods.EID
                          ? themeContext.colors.white
                          : themeContext.colors.darkGrey,
                    }}
                  >
                    {t('esim:orderNewESim:buttons.send-to-device')}
                  </Text>
                </Row>
              </Button>
            </ButtonWrapper>

            <SendToDeviceWrapper
              style={{
                display:
                  values.method === ESimActivationMethods.EID
                    ? 'block'
                    : 'none',
              }}
            >
              <TitleWrapper
                color="darkGrey"
                fontFamily="VodafoneRg"
                fontWeight="bold"
              >
                {t('esim:orderNewESim:eid-title')}
              </TitleWrapper>

              <DescriptionWrapper>
                <Text fontSize={16} color="darkGrey">
                  <SanitizedHtml
                    htmlTag="span"
                    rawHtmlData={t('esim:orderNewESim:eid-help')}
                  />
                </Text>
              </DescriptionWrapper>

              <InputRedesign
                id="eidInput"
                name="eid"
                label={t('esim:orderNewESim:eid-label')}
                labelAsPlaceholder
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.eid}
                errored={!!errors.eid && touched.eid}
                message={touched.eid && errors.eid}
              />
            </SendToDeviceWrapper>

            <ButtonWrapper>
              <Button
                variant="primary"
                disabled={!canSubmitForm(values)}
                onClick={() => handleSubmit()}
              >
                {t('component:button.continue')}
              </Button>
            </ButtonWrapper>
          </>
        </>
      )}
    </Formik>
  );
};

export default OrderNewESimFormContent;
