import {
  BaseTable,
  Button,
  Card,
  IconEthernet,
  IconWifi,
  Title,
} from '@vodafone/red-white';
import { FC, ReactNode, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { CellProps, Column } from 'react-table';
import { ConnectedDeviceType } from 'src/features/services';
import styled from 'styled-components';
import { ModalContentProps } from '../types/modalContentInterfaces';

type OptionProps = {
  connectedDevices: ConnectedDeviceType[];
};

const StyledIcon = styled.span`
  float: left;
  padding-top: 2px;
  padding-right: 10px;
`;

const ConnectedDevicesToModemModalContent: FC<
  ModalContentProps<OptionProps>
> = ({ setCompact, setCompactContent, onClose, options }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['services', 'component']);

  useEffect(() => {
    setCompact(true);
    setCompactContent(true);
  }, [setCompactContent, setCompact, dispatch]);

  const resolveConnectionType = (
    connectionType: ConnectedDeviceType['connectionType'],
  ): ReactNode => {
    switch (connectionType) {
      case 'Ethernet':
        return (
          <>
            <StyledIcon>
              <IconEthernet size={20} />
            </StyledIcon>
            {t('services:modem-connected-devices-modal.ethernet')}
          </>
        );
      case 'Wifi':
        return (
          <>
            <StyledIcon>
              <IconWifi size={20} />
            </StyledIcon>
            {t('services:modem-connected-devices-modal.wifi')}
          </>
        );
      default:
        return <></>;
    }
  };

  const columns = useMemo<Column<ConnectedDeviceType>[]>(
    () => [
      {
        Header: t(
          'services:modem-connected-devices-modal.table-headlines.name',
        ),
        accessor: 'name',
        Cell: ({
          cell,
        }: CellProps<
          ConnectedDeviceType,
          PickOneAsType<ConnectedDeviceType, 'name'>
        >) => <strong>{cell.value}</strong>,
      },
      {
        Header: t(
          'services:modem-connected-devices-modal.table-headlines.type',
        ),
        accessor: 'connectionType',
        Cell: ({
          cell,
        }: CellProps<
          ConnectedDeviceType,
          PickOneAsType<ConnectedDeviceType, 'connectionType'>
        >) => <>{resolveConnectionType(cell.value)}</>,
      },
      {
        Header: t(
          'services:modem-connected-devices-modal.table-headlines.ip-address',
        ),
        accessor: 'ipAddr',
      },
      {
        Header: t(
          'services:modem-connected-devices-modal.table-headlines.mac-address',
        ),
        accessor: 'macAddr',
        Cell: ({
          cell,
        }: CellProps<
          ConnectedDeviceType,
          PickOneAsType<ConnectedDeviceType, 'macAddr'>
        >) => <>{cell.value.replaceAll(':', '-')}</>,
      },
    ],
    [],
  );

  return (
    <Card
      style={{ boxShadow: 'none' }}
      waiting={false}
      title={
        <>
          <Title
            color="darkGrey"
            fontFamily="VodafoneRg"
            fontWeight="bold"
            level={3}
            style={{ marginTop: 20, marginBottom: 10, fontSize: 22 }}
          >
            {t('services:modem-connected-devices-modal.title')}
          </Title>
        </>
      }
    >
      <BaseTable columns={columns} data={options.connectedDevices} />

      <div style={{ textAlign: 'center', marginTop: 40 }}>
        <Button variant="secondary" onClick={onClose}>
          {t('component:button.close')}
        </Button>
      </div>
    </Card>
  );
};

export default ConnectedDevicesToModemModalContent;
