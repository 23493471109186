import { ActionCreator } from 'redux';
import { getAuthType, getExitServiceOku } from 'src/api/exitServices';
import AuthTypeActionTypes from './types/authTypeActionTypes';
import {
  IFetchAuthTypeActionPayload,
  IFetchAuthTypeBeginAction,
  IFetchAuthTypeFailureAction,
  IFetchAuthTypeSuccessAction,
  IFetchMnpOkuBeginAction,
  IFetchMnpOkuFailureAction,
  IFetchMnpOkuSuccessAction,
  IFetchMnpOkuSuccessActionPayload,
} from './types/mnpOkuActionInterfaces';
import MnpOkuActionTypes from './types/mnpOkuActionTypes';

export const fetchMnpServiceOkuBegin: ActionCreator<
  IFetchMnpOkuBeginAction
> = () => ({
  type: MnpOkuActionTypes.FETCH_MNP_OKU_BEGIN,
});

export const fetchMnpServiceOkuSuccess: ActionCreator<
  IFetchMnpOkuSuccessAction
> = (payload: IFetchMnpOkuSuccessActionPayload) => ({
  type: MnpOkuActionTypes.FETCH_MNP_OKU_SUCCESS,
  payload,
});

export const fetchMnpServiceOkuFailure: ActionCreator<
  IFetchMnpOkuFailureAction
> = () => ({
  type: MnpOkuActionTypes.FETCH_MNP_OKU_FAILURE,
});

export const fetchAuthTypeBegin: ActionCreator<
  IFetchAuthTypeBeginAction
> = () => ({
  type: AuthTypeActionTypes.FETCH_AUTH_TYPE_BEGIN,
});

export const fetchAuthTypeSuccess: ActionCreator<
  IFetchAuthTypeSuccessAction
> = (payload: IFetchAuthTypeActionPayload) => ({
  type: AuthTypeActionTypes.FETCH_AUTH_TYPE_SUCCESS,
  payload,
});

export const fetchAuthTypeFailure: ActionCreator<
  IFetchAuthTypeFailureAction
> = () => ({
  type: AuthTypeActionTypes.FETCH_AUTH_TYPE_FAILURE,
});

export const fetchMnpServiceOku = (serviceNumber: string) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(fetchMnpServiceOkuBegin());

    return getExitServiceOku({
      serviceNumber,
    })
      .then((response) => {
        dispatch(fetchMnpServiceOkuSuccess(response.data));
        resolve(response.data);
      })
      .catch(() => {
        dispatch(fetchMnpServiceOkuFailure());
        reject();
      });
  });

export const fetchAuthType = (serviceNumber: string) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(fetchAuthTypeBegin());

    return getAuthType({ serviceNumber })
      .then((response) => {
        dispatch(fetchAuthTypeSuccess(response.data));
        resolve(response.data);
      })
      .catch(() => {
        dispatch(fetchAuthTypeFailure());
        reject();
      });
  });
