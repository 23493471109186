import { createSelector, Selector } from 'reselect';
import IPaymentInfo from 'src/store/billing/paymentInfo/types/paymentInfoInterface';
import { IApplicationState } from '../../appStateInterface';
import IPaymentInfoState from './types/paymentInfoStateInterface';

export const getPaymentInfo: Selector<IApplicationState, IPaymentInfoState> = (
  state: IApplicationState,
) => state.Billing.PaymentInfo;

export const selectPaymentInfo: Selector<
  IApplicationState,
  IPaymentInfoState
> = (state) => state.Billing.PaymentInfo;

export const selectPaymentInfoForBaNumber = (
  state: IApplicationState,
  baNumber?: string,
): IPaymentInfo | null =>
  createSelector([getPaymentInfo], (paymentInfo) =>
    paymentInfo.data && paymentInfo.data[baNumber]
      ? paymentInfo.data[baNumber]
      : null,
  )(state);
