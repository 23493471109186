import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { IApplicationState } from 'src/store/appStateInterface';
import SubscriptionTypeEnum from 'src/store/user/types/SubscriptionTypeEnum';
import {
  selectCurrentSubscription,
  selectSubscriptions,
} from 'src/store/user/userSelector';

export const useActiveFbbServiceNumber = () => {
  const fbbSubscriptions = useSelector((state: IApplicationState) =>
    selectSubscriptions(state, [SubscriptionTypeEnum.FBB]),
  );

  const { serviceNumber: urlServiceNumber } = useParams<{
    serviceNumber?: string;
  }>();

  const urlFbbSubscription = fbbSubscriptions.find(
    ({ serviceNumber }) => serviceNumber === urlServiceNumber,
  );

  if (urlServiceNumber && !urlFbbSubscription) {
    return { fbbServiceNumber: '' };
  }

  const currentSubscription = useSelector((state: IApplicationState) =>
    selectCurrentSubscription(state),
  );

  return {
    fbbServiceNumber:
      urlFbbSubscription?.serviceNumber ||
      (currentSubscription.type === SubscriptionTypeEnum.FBB
        ? currentSubscription.serviceNumber
        : fbbSubscriptions[0]?.serviceNumber || ''),
  };
};
