import styled from 'styled-components';

export const FadeInWrapper = styled.div<{
  visible: boolean;
  animateHeight?: boolean;
  maxHeight?: number;
  displayProp?: boolean;
}>`
  transition: all 1s ease;
  transition-property: max-height, opacity;
  opacity: 0;
  ${(props) => (props.visible ? 'opacity: 1' : '')};
  display: ${(props) =>
    props.displayProp !== undefined && props.displayProp === false
      ? 'none'
      : 'block'};
  ${(props) => (props.animateHeight ? 'max-height: 0' : '')};
  ${(props) => (props.animateHeight ? 'overflow: hidden' : '')};
  ${(props) =>
    props.animateHeight && props.visible
      ? `max-height: ${props.maxHeight ? props.maxHeight : 2000}px`
      : ''};
  width: 100%;

  position: relative;
  z-index: 999999999;
`;
