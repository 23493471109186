import axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import { AxiosService, ExtendedAxiosResponse } from 'src/config/axios';
import { ENV_MODE } from 'src/env';
import { AxiosContext } from './axios.context';
import { AxiosContextProps } from './axios.types';
import { AxiosDevTools } from './AxiosDevTools/AxiosDevTools';

export const AxiosProvider = ({ children }) => {
  const axiosServiceRef = useRef<AxiosService>();

  const [isRequestProcessingPaused, setIsRequestProcessingPaused] =
    useState<AxiosContextProps['isRequestProcessingPaused']>(false);

  const [pendingQueue, setPendingQueue] = useState<
    AxiosContextProps['pendingQueue']
  >([]);

  const [historyQueue, setHistoryQueue] = useState<
    AxiosContextProps['historyQueue']
  >([]);

  const [runningQueue, setRunningQueue] = useState<
    AxiosContextProps['runningQueue']
  >([]);

  if (!axiosServiceRef.current) {
    axiosServiceRef.current = new AxiosService(
      setIsRequestProcessingPaused,
      setPendingQueue,
      setHistoryQueue,
      setRunningQueue,
    );
  }

  const onHistoryClear = () => axiosServiceRef.current.clearHistoryQueue();
  const pauseReuqestProcessing = () => axiosServiceRef?.current.setPause(true);
  const unpauseReuqestProcessing = () =>
    axiosServiceRef?.current.setPause(false);

  useEffect(() => {
    axios.interceptors.request.use(
      (config) => axiosServiceRef?.current.requestOnFulfilledCallback(config),
      (error) => axiosServiceRef?.current.requestOnRejectedCallback(error),
    );

    axios.interceptors.response.use(
      (response) =>
        axiosServiceRef?.current.responseOnFulfilledCallback(
          response as ExtendedAxiosResponse,
        ),
      (error) => axiosServiceRef?.current.responseOnRejectedCallback(error),
    );
  }, []);

  return (
    <AxiosContext.Provider
      value={{
        isRequestProcessingPaused,
        pendingQueue: pendingQueue,
        historyQueue,
        runningQueue,
        pauseReuqestProcessing,
        unpauseReuqestProcessing,
        onHistoryClear,
      }}
    >
      {children}
      {ENV_MODE !== 'production' && <AxiosDevTools />}
    </AxiosContext.Provider>
  );
};
