enum ESimActionTypes {
  ESIM_SIM_SWAP_BEGIN = 'ESIM_SIM_SWAP_BEGIN',
  ESIM_SIM_SWAP_SUCCESS = 'ESIM_SIM_SWAP_SUCCESS',
  ESIM_SIM_SWAP_FAILURE = 'ESIM_SIM_SWAP_FAILURE',
  ESIM_SIM_SWAP_RESET_STORE = 'ESIM_SIM_SWAP_RESET_STORE',

  ESIM_DELIVERY_BEGIN = 'ESIM_DELIVERY_BEGIN',
  ESIM_DELIVERY_SUCCESS = 'ESIM_DELIVERY_SUCCESS',
  ESIM_DELIVERY_FAILURE = 'ESIM_DELIVERY_FAILURE',
  ESIM_DELIVERY_RESET_STORE = 'ESIM_DELIVERY_RESET_STORE',

  ESIM_FETCH_DETAIL_BEGIN = 'ESIM_FETCH_DETAIL_BEGIN',
  ESIM_FETCH_DETAIL_SUCCESS = 'ESIM_FETCH_DETAIL_SUCCESS',
  ESIM_FETCH_DETAIL_FAILURE = 'ESIM_FETCH_DETAIL_FAILURE',
  ESIM_DETAIL_RESET_STORE = 'ESIM_DETAIL_RESET_STORE',

  ESIM_DOWNLOAD_QR_CODE_BEGIN = 'ESIM_DOWNLOAD_QR_CODE_BEGIN',
  ESIM_DOWNLOAD_QR_CODE_SUCCESS = 'ESIM_DOWNLOAD_QR_CODE_SUCCESS',
  ESIM_DOWNLOAD_QR_CODE_FAILURE = 'ESIM_DOWNLOAD_QR_CODE_FAILURE',
  ESIM_QR_CODE_RESET_STORE = 'ESIM_QR_CODE_RESET_STORE',

  ESIM_ACTIVATE_SIM_OR_ESIM_BEGIN = 'ESIM_ACTIVATE_SIM_OR_ESIM_BEGIN',
  ESIM_ACTIVATE_SIM_OR_ESIM_SUCCESS = 'ESIM_ACTIVATE_SIM_OR_ESIM_SUCCESS',
  ESIM_ACTIVATE_SIM_OR_ESIM_FAILURE = 'ESIM_ACTIVATE_SIM_OR_ESIM_FAILURE',
  ESIM_ACTIVATE_SIM_OR_ESIM_RESET_STORE = 'ESIM_ACTIVATE_SIM_OR_ESIM_RESET_STORE',

  ESIM_ONE_NUMBER_PORTAL_LINK_BEGIN = 'ESIM_ONE_NUMBER_PORTAL_LINK_BEGIN',
  ESIM_ONE_NUMBER_PORTAL_LINK_SUCCESS = 'ESIM_ONE_NUMBER_PORTAL_LINK_SUCCESS',
  ESIM_ONE_NUMBER_PORTAL_LINK_FAILURE = 'ESIM_ONE_NUMBER_PORTAL_LINK_FAILURE',

  ESIM_FETCH_ONE_NUMBER_ONE_NUMBERS_BEGIN = 'ESIM_FETCH_ONE_NUMBER_ONE_NUMBERS_BEGIN',
  ESIM_FETCH_ONE_NUMBER_ONE_NUMBERS_SUCCESS = 'ESIM_FETCH_ONE_NUMBER_ONE_NUMBERS_SUCCESS',
  ESIM_FETCH_ONE_NUMBER_ONE_NUMBERS_FAILURE = 'ESIM_FETCH_ONE_NUMBER_ONE_NUMBERS_FAILURE',

  ESIM_CHANGE_SMS_FORK_SETTINGS_BEGIN = 'ESIM_CHANGE_SMS_FORK_SETTINGS_BEGIN',
  ESIM_CHANGE_SMS_FORK_SETTINGS_SUCCESS = 'ESIM_CHANGE_SMS_FORK_SETTINGS_SUCCESS',
  ESIM_CHANGE_SMS_FORK_SETTINGS_FAILURE = 'ESIM_CHANGE_SMS_FORK_SETTINGS_FAILURE',
  ESIM_CHANGE_SMS_FORK_SETTINGS_RESET_STORE = 'ESIM_CHANGE_SMS_FORK_SETTINGS_RESET_STORE',
}

export default ESimActionTypes;
