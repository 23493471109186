import { Reducer } from 'redux';
import initialState from '../../initialState';
import {
  IFetchMnpOkuBeginAction,
  IFetchMnpOkuFailureAction,
  IFetchMnpOkuSuccessAction,
} from './types/mnpOkuActionInterfaces';
import MnpOkuActionTypes from './types/mnpOkuActionTypes';
import MnpOkuStateInterface from './types/mnpOkuStateInterface';

type IMnpOkuActions =
  | IFetchMnpOkuBeginAction
  | IFetchMnpOkuSuccessAction
  | IFetchMnpOkuFailureAction;

const mnpOkuReducer: Reducer<MnpOkuStateInterface, IMnpOkuActions> = (
  state = initialState.Mnp.oku,
  action,
) => {
  switch (action.type) {
    case MnpOkuActionTypes.FETCH_MNP_OKU_BEGIN:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      };
    case MnpOkuActionTypes.FETCH_MNP_OKU_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        loaded: true,
        error: false,
      };
    case MnpOkuActionTypes.FETCH_MNP_OKU_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    default:
      return state;
  }
};

export default mnpOkuReducer;
