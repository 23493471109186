export enum DocumentSectionEnum {
  INVOICE = 'INVOICE',
  AGREEMENT = 'AGREEMENT',
  FORM = 'FORM',
  RELATED_CONTENT = 'RELATED_CONTENT',
  ORDER_PREVIEW = 'ORDER_PREVIEW',
}

export interface IDocument {
  documentId: string;
  documentTitle: string;
  dateReceived: Date;
  type: string;
  subtype: string;
  agreementNumber: string;
  addendumNumber: string;
  documentNumber?: string;
  fileExtension: string;
  serviceRequestId: string;
  section?: DocumentSectionEnum;
}

export interface IGetDocumentResponse {
  documents: IDocument[];
}
