import { Loader } from '@vodafone/red-white';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ModalMessageTypeEnum from 'src/components/Modal/components/ModalMessage/types/ModalMessageTypeEnum';
import { useAppDispatch } from 'src/hooks/useAppDispatch';
import { IApplicationState } from 'src/store/appStateInterface';
import {
  getRefreshSubscriptionTitle,
  setRefreshSubscriptionTitle,
} from 'src/utils/translation/getRefreshSubscriptionTitle';
import styled from 'styled-components';
import {
  refreshCasSubscription,
  refreshCasSubscriptionResetState,
} from '../../../../store/cas/casActions';
import {
  selectRefreshSubscription,
  selectRefreshSubscriptionHasError,
  selectRefreshSubscriptionIsLoaded,
  selectRefreshSubscriptionLoading,
} from '../../../../store/cas/casSelectors';
import {
  CasResultEnum,
  IRefreshSubscriptionState,
} from '../../../../store/cas/types/casStateInterface';
import { ModalContentProps } from '../../types/modalContentInterfaces';
import ModalMessageTemplate from '../ModalMessage/ModalMessageTemplate';
import VtvCasRefreshSubscriptionOverview from './VtvCasRefreshSubscriptionOverview';

const Content = styled.div`
  max-width: 700px;
`;

const VtvCasRefreshSubscriptionModalContent: FC<ModalContentProps> = () => {
  const { t } = useTranslation(['services', 'cas']);
  const [title, setTitle] = useState<string>(t(getRefreshSubscriptionTitle()));
  const [resized, setResized] = React.useState<boolean>(false);
  const dispatch = useAppDispatch();
  const data: IRefreshSubscriptionState = useSelector(
    (state: IApplicationState) => selectRefreshSubscription(state),
  );
  const loading: boolean = useSelector((state: IApplicationState) =>
    selectRefreshSubscriptionLoading(state),
  );
  const isLoaded: boolean = useSelector((state: IApplicationState) =>
    selectRefreshSubscriptionIsLoaded(state),
  );
  const hasError: boolean = useSelector((state: IApplicationState) =>
    selectRefreshSubscriptionHasError(state),
  );

  useEffect(() => {
    setRefreshSubscriptionTitle(t, setTitle, setResized);
  }, [t, title, resized]);

  useEffect(() => {
    dispatch(refreshCasSubscriptionResetState());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <Content>
      <Loader show={loading} />

      {!hasError && !isLoaded && <VtvCasRefreshSubscriptionOverview />}
      {!loading && isLoaded && data.result === CasResultEnum.OK && (
        <ModalMessageTemplate
          type={ModalMessageTypeEnum.SUCCESS}
          heading={t('cas:refresh-subscription.refreshed-success-title')}
          description={t(
            'cas:refresh-subscription.refreshed-success-description',
          )}
        />
      )}
      {((!loading && isLoaded && hasError) ||
        (!loading && isLoaded && data.result === CasResultEnum.ERROR)) && (
        <ModalMessageTemplate
          type={ModalMessageTypeEnum.ERROR}
          description={t(
            'cas:refresh-subscription.refreshed-error-description',
          )}
          button={{
            label: title,
            onClick: () => dispatch(refreshCasSubscription()),
          }}
        />
      )}
    </Content>
  );
};

export default VtvCasRefreshSubscriptionModalContent;
