interface IEncodePattern {
  decimalPlaces: number;
  frontPadding: string;
  backPadding: string;
  groupLengths: number[];
  zeroLength: number;
}

interface IFormat extends IEncodePattern {
  symbol?: string;
  decimal?: string;
  group?: string;
}

interface IProps {
  pattern?: string;
  decimal?: string;
  group?: string;
  symbol?: string;
}

const isUndefined = (o) => typeof o === 'undefined';

const encodePattern = (pattern): IEncodePattern => {
  let decimalPlaces = 0;
  let frontPadding = '';
  let backPadding = '';
  const groupLengths = [];

  let patternStarted = false;
  let decimalsStarted = false;
  let patternEnded = false;

  let currentGroupLength = 0;
  let zeroLength = 0;

  for (let i = 0; i < pattern.length; i += 1) {
    const c = pattern[i];

    if (!patternStarted && ['#', '0', ',', '.'].indexOf(c) > -1) {
      patternStarted = true;
    }

    if (!patternStarted) {
      frontPadding += c;
    }

    switch (c) {
      case '#':
        currentGroupLength += 1;
        break;

      case '0':
        if (decimalsStarted) {
          decimalPlaces += 1;
        } else {
          currentGroupLength += 1;
          zeroLength += 1;
        }
        break;

      case ',':
        groupLengths.push(currentGroupLength);
        currentGroupLength = 0;
        break;

      case '.':
        groupLengths.push(currentGroupLength);
        decimalsStarted = true;
        break;

      default:
        break;
    }

    if (patternStarted && !(['#', '0', ',', '.'].indexOf(c) > -1)) {
      patternEnded = true;

      if (!decimalsStarted) {
        groupLengths.push(currentGroupLength);
      }
    }

    if (patternEnded) {
      backPadding += c;
    }
  }

  return {
    decimalPlaces,
    frontPadding,
    backPadding,
    groupLengths,
    zeroLength,
  };
};

const toFixed = (n, precision) =>
  (+`${Math.round(+`${n.toString()}e${precision}`)}e${-precision}`).toFixed(
    precision,
  );

const pad = (n, width) => {
  const x = n.toString();

  return x.length >= width ? x : new Array(width - x.length + 1).join('0') + x;
};

const format = (n, f, symbol) => {
  let formattedNumberInFormat = toFixed(Math.abs(n), f.decimalPlaces);

  const splitNumber = formattedNumberInFormat.split('.');
  let segment = '';

  // i.e. we actually have some sort of grouping in the values
  if (f.groupLengths.length > 1) {
    let cursor = splitNumber[0].length;
    let groupIndex = f.groupLengths.length - 1;

    while (cursor > 0) {
      if (groupIndex <= 0) {
        groupIndex = 1;
      } // Always reset to the first group length if the number is big

      const currentGroupLength = f.groupLengths[groupIndex];

      const start = cursor - currentGroupLength;

      segment = splitNumber[0].substring(start, cursor) + f.group + segment;

      cursor -= currentGroupLength;

      groupIndex -= 1;
    }

    segment = segment.substring(0, segment.length - 1);
  }

  if (segment.length < f.zeroLength) {
    segment = pad(segment, f.zeroLength);
  }

  formattedNumberInFormat =
    f.frontPadding +
    segment +
    (isUndefined(splitNumber[1]) ? '' : f.decimal + splitNumber[1]) +
    f.backPadding;

  return formattedNumberInFormat.replace('!', symbol);
};

const currencyResolver = ({
  pattern = '#,##0.00 !',
  decimal = ',',
  group = ' ',
  symbol = 'Kč',
}: IProps) => {
  const patternArray = pattern.split(';');

  const positiveFormat: IFormat = {
    ...encodePattern(patternArray[0]),
    symbol,
    decimal,
    group,
  };

  const negativeFormat = {
    ...(isUndefined(patternArray[1])
      ? encodePattern(`-${patternArray[0]}`)
      : encodePattern(patternArray[1])),
    symbol,
    decimal,
    group,
  };

  const zero = isUndefined(patternArray[2])
    ? format(0, positiveFormat, symbol)
    : patternArray[2];

  return (n) => {
    let formattedNumber;

    const nn = Number(n);

    if (nn > 0) {
      formattedNumber = format(nn, positiveFormat, symbol);
    } else if (nn === 0) {
      formattedNumber = zero.replace('!', symbol);
    } else {
      formattedNumber = format(nn, negativeFormat, symbol);
    }
    return formattedNumber;
  };
};

export default currencyResolver;
