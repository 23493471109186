import { AxiosResponse } from 'axios';
import { API_ENDPOINT_SIM_SWAP } from 'src/api';
import { ESimSimSwapRequestTypeEnum } from 'src/api/esim/request/eSimSimSwapTypeEnum';
import { IRestResponseFault } from 'src/api/types/IRestResponseFault';
import { IRestResponseSuccess } from 'src/api/types/IRestResponseSuccess';
import api from 'src/utils/api/api';

export default (
  serviceNumber: string,
  type: ESimSimSwapRequestTypeEnum,
  eId?: string,
): Promise<AxiosResponse<IRestResponseSuccess | IRestResponseFault>> =>
  api.post({
    url: API_ENDPOINT_SIM_SWAP.replace('{serviceNumber}', serviceNumber),
    data: eId ? { type, eId } : { type },
  });
