import i18n from 'i18next';
import { LanguagesEnum } from 'src/types/translation/languagesEnum';
import {
  getAcrCookie,
  getCookie,
  getVisitorIdCookie,
  setCookie,
  transformOptinCookiesToUrlParameter,
} from './cookie';
import { icuLocaleFromLanguage } from './language';
import { resolveRoutePath } from './redirect';
import {
  IDAAS_LOGIN_NEXT_URL,
  IDAAS_LOGIN_REGISTER_URL,
  IDAAS_RESET_PASSWORD_URL,
  IDAAS_URL_PARAMETERS,
} from './router/router.consts';

type CreateIdaasUrlParams = {
  username?: string;
  redirectUrl?: string;
  queryParams?: AnyObject;
  msisdn?: string;
};

type ResetPasswordIdaasUrlParams = {
  redirectUrl?: string;
  queryParams?: AnyObject;
};

export const createIdaasLoginUrl = ({
  username,
  redirectUrl,
  queryParams,
  msisdn,
}: CreateIdaasUrlParams): URL => {
  const loginUrl = new URL(
    IDAAS_LOGIN_NEXT_URL,
    `${window.location.protocol}//${window.location.host}`,
  );

  // append cookie parameters
  const visitorIdCookie = getVisitorIdCookie();
  const acrCookie = getAcrCookie();
  loginUrl.searchParams.append('cc', transformOptinCookiesToUrlParameter());
  if (visitorIdCookie) {
    loginUrl.searchParams.append(IDAAS_URL_PARAMETERS.T_V_ID, visitorIdCookie);
  }
  if (acrCookie) {
    loginUrl.searchParams.append(IDAAS_URL_PARAMETERS.A_V_ID, acrCookie);
  }

  loginUrl.searchParams.append(
    'ui_locales',
    icuLocaleFromLanguage(i18n.language as LanguagesEnum),
  );

  if (username) {
    loginUrl.searchParams.append(
      IDAAS_URL_PARAMETERS.LOGIN_HINT,
      `USERNAME:${username}`,
    );
  }

  if (msisdn) {
    loginUrl.searchParams.append(
      IDAAS_URL_PARAMETERS.LOGIN_HINT,
      `MSISDN:${msisdn}`,
    );
  }

  if (queryParams) {
    Object.keys(queryParams).forEach((key) => {
      loginUrl.searchParams.append(key, queryParams[key].toString());
    });
  }

  if (redirectUrl) {
    loginUrl.searchParams.append(
      IDAAS_URL_PARAMETERS.REDIRECT_URI,
      redirectUrl,
    );
  }

  // use resolveRoutePath() to add persist params to the url if any exists
  return new URL(resolveRoutePath({ url: loginUrl.toString() }));
};

export const createIdaasResetPasswordUrl = ({
  redirectUrl,
  queryParams,
}: ResetPasswordIdaasUrlParams): URL => {
  const resetPasswordUrl = new URL(
    IDAAS_RESET_PASSWORD_URL,
    `${window.location.protocol}//${window.location.host}`,
  );

  resetPasswordUrl.searchParams.append(
    'ui_locales',
    icuLocaleFromLanguage(i18n.language as LanguagesEnum),
  );

  if (queryParams) {
    Object.keys(queryParams).forEach((key) => {
      resetPasswordUrl.searchParams.append(key, queryParams[key].toString());
    });
  }

  if (redirectUrl) {
    resetPasswordUrl.searchParams.append(
      IDAAS_URL_PARAMETERS.REDIRECT_URI,
      redirectUrl,
    );
  }

  // use resolveRoutePath() to add persist params to the url if any exists
  return new URL(resolveRoutePath({ url: resetPasswordUrl.toString() }));
};

export const createIdaasRegisterUrl = ({
  username,
  redirectUrl,
  queryParams,
  msisdn,
}: CreateIdaasUrlParams): URL => {
  const loginUrl = new URL(
    IDAAS_LOGIN_REGISTER_URL,
    `${window.location.protocol}//${window.location.host}`,
  );

  const visitorIdCookie = getVisitorIdCookie();
  const acrCookie = getAcrCookie();
  loginUrl.searchParams.append(
    IDAAS_URL_PARAMETERS.CC,
    transformOptinCookiesToUrlParameter(),
  );
  if (visitorIdCookie) {
    loginUrl.searchParams.append(IDAAS_URL_PARAMETERS.T_V_ID, visitorIdCookie);
  }
  if (acrCookie) {
    loginUrl.searchParams.append(IDAAS_URL_PARAMETERS.A_V_ID, acrCookie);
  }

  loginUrl.searchParams.append(
    'ui_locales',
    icuLocaleFromLanguage(i18n.language as LanguagesEnum),
  );

  if (username) {
    loginUrl.searchParams.append(
      IDAAS_URL_PARAMETERS.LOGIN_HINT,
      `USERNAME:${username}`,
    );
  }

  if (msisdn) {
    loginUrl.searchParams.append(
      IDAAS_URL_PARAMETERS.LOGIN_HINT,
      `MSISDN:${msisdn}`,
    );
  }

  if (queryParams) {
    Object.keys(queryParams).forEach((key) => {
      loginUrl.searchParams.append(key, queryParams[key].toString());
    });
  }

  if (redirectUrl) {
    loginUrl.searchParams.append(
      IDAAS_URL_PARAMETERS.REDIRECT_URI,
      redirectUrl,
    );
  }

  // use resolveRoutePath() to add persist params to the url if any exists
  return new URL(resolveRoutePath({ url: loginUrl.toString() }));
};

export const WSC_AUTHENTICATED_COOKIE_NAME = 'wscUserAuthenticated';

export const logout = (): void => {
  const hasAuthenticatedCookie =
    getCookie(WSC_AUTHENTICATED_COOKIE_NAME) === '1';

  if (hasAuthenticatedCookie) {
    setCookie(WSC_AUTHENTICATED_COOKIE_NAME, '0', 1);
    window.location.reload();
  }
};
