import { Reducer } from 'redux';
import initialState from '../initialState';
import {
  IFetchPegaOffersBeginAction,
  IFetchPegaOffersFailureAction,
  IFetchPegaOffersSuccessAction,
} from './types/pegaOffersActionInterfaces';
import { PegaOffersActionTypes } from './types/pegaOffersActionTypes';
import { IPegaOffersState } from './types/pegaOffersStateInterface';

type PegaOffersActions =
  | IFetchPegaOffersBeginAction
  | IFetchPegaOffersFailureAction
  | IFetchPegaOffersSuccessAction;

const pegaOffersReducer: Reducer<IPegaOffersState, PegaOffersActions> = (
  state: IPegaOffersState = initialState.PegaOffers,
  action: PegaOffersActions,
) => {
  switch (action.type) {
    case PegaOffersActionTypes.FETCH_PEGA_OFFERS_BEGIN:
      return {
        ...state,
        serviceNumber: {
          ...state?.serviceNumber,
          [action.serviceNumber]: {
            ...state?.serviceNumber?.[action.serviceNumber],
            offers: {
              ...state?.serviceNumber?.[action.serviceNumber]?.offers,
              [action.offerId]: {
                ...state?.serviceNumber?.[action.serviceNumber]?.offers[
                  action.offerId
                ],
                loading: true,
                loaded: false,
              },
            },
          },
        },
      };

    case PegaOffersActionTypes.FETCH_PEGA_OFFERS_SUCCESS:
      return {
        ...state,
        serviceNumber: {
          ...state.serviceNumber,
          [action.serviceNumber]: {
            ...state.serviceNumber[action.serviceNumber],
            offers: {
              ...state.serviceNumber[action.serviceNumber].offers,
              [action.offerId]: {
                ...state.serviceNumber[action.serviceNumber].offers[
                  action.offerId
                ],
                loading: false,
                loaded: true,
                error: false,
                data: action.payload.data.offers,
              },
            },
          },
        },
      };

    case PegaOffersActionTypes.FETCH_PEGA_OFFERS_FAILURE:
      return {
        ...state,
        serviceNumber: {
          ...state.serviceNumber,
          [action.serviceNumber]: {
            ...state.serviceNumber[action.serviceNumber],
            offers: {
              ...state.serviceNumber[action.serviceNumber].offers,
              [action.offerId]: {
                ...state.serviceNumber[action.serviceNumber].offers[
                  action.offerId
                ],
                loading: false,
                loaded: true,
                error: true,
                data: [],
              },
            },
          },
        },
      };

    default:
      return state;
  }
};

export default pegaOffersReducer;
