import { Reducer } from 'redux';
import initialState from '../../initialState';
import { ISetBillingAccountAction } from './types/billingAccountActionInterfaces';
import BillingAccountActionTypes from './types/billingAccountActionTypes';
import IBillingAccountState from './types/billingAccountStateInterface';

type IBillingAccountActions = ISetBillingAccountAction;

const billingAccountReducer: Reducer<
  IBillingAccountState,
  IBillingAccountActions
> = (state = initialState.Billing.BillingAccount, action) => {
  switch (action.type) {
    case BillingAccountActionTypes.SET_BILLING_ACCOUNT:
      return {
        ...state,
        isSet: true,
      };
    default:
      return state;
  }
};

export default billingAccountReducer;
