/* eslint no-param-reassign: 0, no-nested-ternary:0 */
import {
  Button,
  Col,
  IconEye,
  IconHide,
  InputRedesign as Input,
  Row,
  Text,
} from '@vodafone/red-white';
import * as React from 'react';
import {
  Controller,
  ErrorOption,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { PasswordStrength } from 'src/ui/components/PasswordRecovery/PasswordStrength/PasswordStrength';
import PasswordRule from 'src/ui/components/PasswordRule';
import { validateVtvPassword } from 'src/utils/password';
import SanitizedHtml from 'src/utils/sanitizeHtml';
import styled, { ThemeContext } from 'styled-components';

export type FormValues = {
  password: string;
  passwordConfirmation: string;
};

export type OnSubmitRegistrationForm = {
  data: FormValues;
  setLoading?: (value: boolean) => void;
  setError?: (
    fieldName: 'password' | 'passwordConfirmation',
    error: ErrorOption,
  ) => void;
};

const Footer = styled(Row)`
  margin-top: 28px;
`;

const FormField = styled(Row)<{ noMt?: boolean }>`
  margin-top: ${(props) => (props.noMt ? 0 : 24)}px;
`;

const InfoWrapper = styled.div`
  padding-top: 20px;
`;

const IconWrapper = styled.div`
  cursor: pointer;
`;

export interface IRegistrationFormProps {
  onSubmit?: (props: OnSubmitRegistrationForm) => void;
}

const VtvSetPasswordStrength: React.FC<IRegistrationFormProps> = ({
  ...props
}) => {
  const themeContext = React.useContext(ThemeContext);
  const { t } = useTranslation([
    'forgotPassword',
    'component',
    'form',
    'customerIdentity',
  ]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [showPassword, setShowPassword] = React.useState<boolean>(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] =
    React.useState<boolean>(false);
  const { handleSubmit, control, errors, watch, setError } = useForm();
  const password = watch('password');

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    setLoading(true);

    if (props.onSubmit) {
      props.onSubmit({
        data,
        setError,
        setLoading,
      });
    }
  };

  const validateRepeatPassword = (value: string) => {
    if (!value) {
      return t('form:error.required');
    }
    if (value !== password) {
      return t('form:error.password-different');
    }

    return null;
  };

  const passwordProgress = password && validateVtvPassword(password);

  const getPasswordStatus = (value?: string) => {
    if (!value) {
      return null;
    }

    const result = {
      1: 'medium',
      2: 'strong',
      3: 'strong',
    };

    let score = 0;

    if (passwordProgress && passwordProgress.passwordLength) {
      score += 1;
    }

    if (passwordProgress && passwordProgress.symbols) {
      score += 1;
    }

    if (score === 0) {
      return null;
    }

    return result[score];
  };

  const passwordStatus = getPasswordStatus(password);

  const validatePassword = (value: string) => {
    if (!value) {
      return t('form:error.required');
    }

    const validator = validateVtvPassword(value);

    return validator.isValid ? null : t('form:error.invalid-password');
  };

  const formKey = 'form:personalAccountRegistrationForm';

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <>
        <>
          <PasswordRule
            text={t('services:vtv.change-password-hint-length-modal')}
            success={
              (passwordProgress && passwordProgress.passwordLength) || false
            }
          />
          <PasswordRule
            text={t('services:vtv.change-password-hint-characters-modal')}
            success={(passwordProgress && passwordProgress.symbols) || false}
          />
        </>
        <FormField>
          <Controller
            as={Input}
            control={control}
            labelAsPlaceholder={false}
            id="password"
            label={t(`${formKey}.fields.password.label`)}
            placeholder={t(`${formKey}.fields.password.placeholder`)}
            name="password"
            rules={{
              validate: validatePassword,
            }}
            message={
              <Row justify="space-between">
                {(errors.password && errors.password.message) || <>&nbsp;</>}
                <PasswordStrength status={passwordStatus} size={2} />
              </Row>
            }
            type={showPassword ? 'text' : 'password'}
            icon={
              !errors.password && (
                <IconWrapper onClick={() => setShowPassword(!showPassword)}>
                  {!showPassword ? (
                    <IconEye size={24} color={themeContext.colors.darkGrey} />
                  ) : (
                    <IconHide size={24} color={themeContext.colors.darkGrey} />
                  )}
                </IconWrapper>
              )
            }
            errored={!!errors.password}
            defaultValue=""
            disabled={loading}
          />
        </FormField>
        <div style={{ marginTop: 12 }}>
          <Controller
            as={Input}
            control={control}
            labelAsPlaceholder={false}
            id="passwordConfirmation"
            label={t(`${formKey}.fields.passwordConfirmation.label`)}
            placeholder={t(
              `${formKey}.fields.passwordConfirmation.placeholder`,
            )}
            name="passwordConfirmation"
            rules={{
              validate: validateRepeatPassword,
            }}
            message={
              errors.passwordConfirmation && errors.passwordConfirmation.message
            }
            errored={!!errors.passwordConfirmation}
            defaultValue=""
            onPaste={(e: React.ClipboardEvent) => {
              // disable copy for password repeat
              e.preventDefault();
            }}
            type={showPasswordConfirmation ? 'text' : 'password'}
            icon={
              !errors.passwordConfirmation && (
                <IconWrapper
                  onClick={() =>
                    setShowPasswordConfirmation(!showPasswordConfirmation)
                  }
                >
                  {!showPasswordConfirmation ? (
                    <IconEye size={24} color={themeContext.colors.darkGrey} />
                  ) : (
                    <IconHide size={24} color={themeContext.colors.darkGrey} />
                  )}
                </IconWrapper>
              )
            }
            disabled={loading}
          />
        </div>
        <Footer justify="center">
          <Col xs={12}>
            <Button
              variant="primary"
              block
              htmlElement="button"
              htmlElementProps={{ type: 'submit' }}
              disabled={loading}
            >
              {loading
                ? t('component:button.loading')
                : t('services:vtv.change-password-title')}
            </Button>
          </Col>
        </Footer>
        <br />
        <InfoWrapper>
          <Text fontSize={14} color="darkGrey" className="center">
            <SanitizedHtml
              htmlTag="span"
              rawHtmlData={t('services:vtv.change-password-info-modal')}
            />
          </Text>
        </InfoWrapper>
      </>
    </form>
  );
};

export default VtvSetPasswordStrength;
