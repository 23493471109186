import * as React from 'react';
import Currency from 'src/components/Currency';
interface IPrice {
  price: string | number;
  currency?: string;
  period?: string;
}

const PriceForRenderToString: React.FC<IPrice> = ({
  price,
  currency = 'Kč',
  period,
}) => {
  const p = typeof price === 'string' ? parseFloat(price) : price;
  let pattern = '#,##0.00 !';

  if (p % 1 === 0) {
    pattern = '#,##0 !';
  }

  return (
    <>
      <Currency quantity={p} currency={currency} pattern={pattern} />
      {period ? ` / ${period}` : ''}
    </>
  );
};

export default PriceForRenderToString;
