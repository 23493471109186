import { Reducer } from 'redux';
import initialState from '../../initialState';
import {
  IFetchPayerPaymentBeginAction,
  IFetchPayerPaymentFailureAction,
  IFetchPayerPaymentSuccessAction,
} from './types/payerPaymentActionInterfaces';
import PayerPaymentActionTypes from './types/payerPaymentActionTypes';
import IPayerPaymentState from './types/payerPaymentStateInterface';

type IPayerPaymentActions =
  | IFetchPayerPaymentBeginAction
  | IFetchPayerPaymentSuccessAction
  | IFetchPayerPaymentFailureAction;

const payerPaymentReducer: Reducer<IPayerPaymentState, IPayerPaymentActions> = (
  state = initialState.Billing.PayerPayment,
  action,
) => {
  switch (action.type) {
    case PayerPaymentActionTypes.FETCH_PAYER_PAYMENT_BEGIN:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      };
    case PayerPaymentActionTypes.FETCH_PAYER_PAYMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        data: action.payload,
      };
    case PayerPaymentActionTypes.FETCH_PAYER_PAYMENT_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    default:
      return state;
  }
};

export default payerPaymentReducer;
