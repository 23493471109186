/* eslint-disable react/no-danger */
import { Button, Message } from '@vodafone/red-white';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IApplicationState } from 'src/store/appStateInterface';
import selectLanguage from 'src/store/language/languageSelector';
import styled from 'styled-components';
import { LanguagesEnum } from '../../../types/translation/languagesEnum';
import generateExternalRoute from '../../../utils/router/generateExternalRoute';
import SanitizedHtml from '../../../utils/sanitizeHtml';

const Base = styled.div`
  h2,
  p {
    display: block;
    text-align: left;

    &:last-child {
      margin-bottom: 1em;
    }
  }
`;

const Container = styled.div`
  width: 100%;
  max-width: 697px;
  padding: 30px 15px 30px 15px;
  text-align: center;
  margin: 0 auto;

  @media ${(props) => props.theme.breakpoints.md} {
    padding: 60px 20px 60px 20px;
  }

  @media ${(props) => props.theme.breakpoints.lg} {
    padding-left: 0;
    padding-right: 0;
  }
`;

const ButtonSpacing = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;

  a:not(:first-child) {
    margin-top: 24px;
  }

  @media ${(props) => props.theme.breakpoints.sm} {
    flex-direction: row;

    a {
      margin-top: 0px;
    }

    a:not(:first-child) {
      margin-top: 0px;
    }

    a + a {
      margin-left: 16px;
    }
  }
`;

const ErrorMessage = () => {
  const { t } = useTranslation('component');
  const language: LanguagesEnum = useSelector((state: IApplicationState) =>
    selectLanguage(state),
  );

  const messageText = (
    <Base>
      <h2>{t('errorBoundary.error500-message-headline')}</h2>
      <p>
        <SanitizedHtml
          htmlTag="span"
          rawHtmlData={t('errorBoundary.error500-message-paragraph-first', {
            link: generateExternalRoute('wsc_homepage', language),
          })}
        />
        <br />
        <SanitizedHtml
          htmlTag="span"
          rawHtmlData={t('errorBoundary.error500-message-paragraph-second')}
        />
      </p>
      <SanitizedHtml
        htmlTag="p"
        rawHtmlData={t('errorBoundary.error500-message-paragraph-third', {
          link: t('errorBoundary.centrum-pece-link'),
        })}
      />
    </Base>
  );

  return (
    <Container>
      <Message type="error" message={messageText} />
      <ButtonSpacing>
        <Button
          variant="primary"
          htmlElement="a"
          htmlElementProps={{ href: t('errorBoundary.recharge-link') }}
        >
          {t('errorBoundary.recharge-title')}
        </Button>
        <Button
          variant="secondary"
          htmlElement="a"
          htmlElementProps={{ href: t('errorBoundary.onenet-link') }}
        >
          {t('errorBoundary.onenet-title')}
        </Button>
      </ButtonSpacing>
    </Container>
  );
};

export default ErrorMessage;
