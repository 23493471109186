import { Reducer } from 'redux';
import initialState from '../../initialState';
import {
  IManagementEligibilityBeginAction,
  IManagementEligibilityFailureAction,
  IManagementEligibilitySuccessAction,
} from '../types/managementEligibility/managementEligibilityActionInterfaces';
import { ManagementEligibilityActionTypes } from '../types/managementEligibility/managementEligibilityActionTypes';
import { IManagementEligibility } from '../types/managementEligibility/managementEligibilityStateInterface';

type ManagementEligibilityAction =
  | IManagementEligibilityBeginAction
  | IManagementEligibilitySuccessAction
  | IManagementEligibilityFailureAction;

export const managementEligibilityReducer: Reducer<IManagementEligibility> = (
  state: IManagementEligibility = initialState.Services.managementEligibility,
  action: ManagementEligibilityAction,
) => {
  switch (action.type) {
    case ManagementEligibilityActionTypes.MANAGEMENT_ELIGIBILITY_BEGIN:
      return {
        ...state,
        loading: true,
        loaded: false,
      };

    case ManagementEligibilityActionTypes.MANAGEMENT_ELIGIBILITY_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        hasOpenOrder: action.payload.hasOpenOrder,
        hasTariffMigrationInCart: action.payload.hasTariffMigrationInCart,
        isTariffMigrationEligible: action.payload.isTariffMigrationEligible,
        isZame: action.payload.isZame,
      };

    case ManagementEligibilityActionTypes.MANAGEMENT_ELIGIBILITY_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        hasOpenOrder: false,
        hasTariffMigrationInCart: false,
        isTariffMigrationEligible: false,
        isZame: false,
      };

    default:
      return state;
  }
};

export default managementEligibilityReducer;
