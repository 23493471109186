import { Reducer } from 'redux';
import { isLargeSwitcherCombiner } from 'src/store/genericMicrositeAfterLogin/genericMicrositeAfterLoginSelectors';
import AfterLoginActionEnum from 'src/store/genericMicrositeAfterLogin/types/afterLoginActionEnum';
import { AfterLoginAction } from 'src/store/genericMicrositeAfterLogin/types/afterLoginActionInterfaces';
import IGenericMicrositeAfterLoginState from 'src/store/genericMicrositeAfterLogin/types/genericMicrositeAfterLoginStateInterface';
import { IService } from 'src/store/genericMicrositeAfterLogin/types/serviceInterface';
import initialState from '../initialState';

const complementDefaultSearchForLargeSwitcher = (
  state: IGenericMicrositeAfterLoginState,
): IGenericMicrositeAfterLoginState => {
  const isLargeSwitcher = isLargeSwitcherCombiner(state);

  if (!isLargeSwitcher) {
    return state;
  }
  return {
    ...state,
    searchQuery: '',
    searchResult: state.otherServices,
  };
};

const updateServices = (
  updatedService: IService,
  allServices: IService[],
): IService[] => {
  if (allServices.length === 0) {
    return allServices;
  }

  return allServices.map((service) => {
    return service.key === updatedService.key ? updatedService : service;
  });
};

const genericMicrositeAfterLoginReducer: Reducer<
  IGenericMicrositeAfterLoginState,
  AfterLoginAction
> = (
  state: IGenericMicrositeAfterLoginState = initialState.GenericMicrositeAfterLogin,
  action: AfterLoginAction,
) => {
  switch (action.type) {
    case AfterLoginActionEnum.GENERIC_MICROSTE_AFTER_LOGIN_SET_SERVICES:
      if (!action.allServices.loaded) {
        return state;
      }
      return complementDefaultSearchForLargeSwitcher({
        ...state,
        ...action.allServices,
      });

    case AfterLoginActionEnum.GENERIC_MICROSTE_AFTER_LOGIN_SET_SEARCH_RESULT:
      return {
        ...state,
        ...action.searchResult,
      };
    case AfterLoginActionEnum.GENERIC_MICROSTE_AFTER_LOGIN_UPDATE_SERVICE:
      return {
        ...state,
        selectedService:
          state.selectedService.key === action.updatedService.key
            ? action.updatedService
            : state.selectedService,
        otherServices: updateServices(
          action.updatedService,
          state.otherServices,
        ),
        searchResult: updateServices(action.updatedService, state.searchResult),
      };
    default:
      return state;
  }
};

export default genericMicrositeAfterLoginReducer;
