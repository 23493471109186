import { Reducer } from 'redux';
import { IMicrositeServiceData } from 'src/api/genericMicrosites/response/getMicrositeDataInterface';
import { sendLead } from 'src/store/genericMicrosite/genericMicrositesActions';
import { mergeMSData } from 'src/utils/interationPointJson';
import {
  MicrositeDirectOfferFlow,
  MicrositeFlow,
  MicrositeFlowState,
  MicrositeSummaryFlow,
} from '../../types/genericMicrosite/genericMicoristeFlows';
import initialState from '../initialState';
import {
  IGenericMicrositeAction,
  ISetFlowState,
  ISetParamsToOffer,
  IStoreMSData,
} from './types/genericMicrositesActionInterafces';
import GenericMicrositesActionTypes from './types/genericMicrositesActionTypes';
import {
  IGenericMicrositesState,
  INITIAL_MS_PROPS,
} from './types/genericMicrositesStateInterface';

const storeMSData = (
  state: IGenericMicrositesState,
  action: IStoreMSData,
): IGenericMicrositesState => {
  const msData: IMicrositeServiceData = action.data;
  const dataToSet = mergeMSData(INITIAL_MS_PROPS, msData);

  dataToSet.jsonData = Object.assign(
    { dataIndex: 'msIndex' },
    dataToSet.jsonData,
  );

  if (dataToSet.childComponents && dataToSet.childComponents.length > 0) {
    const newChildren = dataToSet.childComponents.map((childData, index) =>
      Object.assign({}, childData, {
        jsonData: Object.assign({}, childData.jsonData, {
          dataIndex: `child${index}`,
        }),
      }),
    );

    dataToSet.childComponents = newChildren;
  }
  let micrositeFlow: MicrositeFlow = MicrositeFlow.DIRECT_OFFER_CLICK;
  let micrositeFlowState: MicrositeFlowState = MicrositeDirectOfferFlow.INITIAL;

  if (typeof msData.jsonData === 'object') {
    if (msData.jsonData.useSummaryFlow) {
      micrositeFlow = MicrositeFlow.SUMMARY_FLOW;
      micrositeFlowState = MicrositeSummaryFlow.INITIAL;
    }
  }
  const sanitizedData = mergeMSData(
    { flowState: micrositeFlowState },
    dataToSet,
    {},
  );

  return {
    ...state,
    ...sanitizedData,
    errorMessage: '',
    micrositeParams: action.micrositeParams,
    loading: false,
    micrositeFlow,
    micrositeFlowState,
  };
};

const setParamsToOffers = (
  state: IGenericMicrositesState,
  { addData, filterObject }: ISetParamsToOffer,
): IGenericMicrositesState => {
  const result = mergeMSData(addData, state, filterObject);

  return { ...state, ...result };
};

const setFlowState = (
  state: IGenericMicrositesState,
  { flowState }: ISetFlowState,
): IGenericMicrositesState => {
  const result = mergeMSData({ flowState }, state, {});

  return { ...state, ...result, micrositeFlowState: flowState };
};

export const genericMicrositesReducer: Reducer<
  IGenericMicrositesState,
  IGenericMicrositeAction
> = (
  state: IGenericMicrositesState = initialState.GenericMicrosites,
  action: IGenericMicrositeAction,
) => {
  switch (action.type) {
    case GenericMicrositesActionTypes.STORE_MS_DATA:
      return storeMSData(state, action);
    case GenericMicrositesActionTypes.STORE_MS_DATA_ERROR:
      return {
        ...state,
        errorMessage: action.errorMessage,
        loading: false,
      };
    case GenericMicrositesActionTypes.SET_PARAMS_TO_OFFERS:
      return setParamsToOffers(state, action);
    case GenericMicrositesActionTypes.SET_FLOW_STATE:
      return setFlowState(state, action);
    case GenericMicrositesActionTypes.START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case GenericMicrositesActionTypes.LEAD_START_SENDING:
      return {
        ...state,
        micrositeLeadState: {
          leadSentLoading: true,
          leadSentSuccess: false,
          leadSent: false,
        },
      };
    case GenericMicrositesActionTypes.LEAD_STORE_RESULT:
      return {
        ...state,
        micrositeLeadState: {
          leadSentLoading: false,
          leadSentSuccess: action.result,
          leadSent: true,
        },
      };
    case GenericMicrositesActionTypes.SET_FORCE_PAGE_RELOAD:
      return {
        ...state,
        loading: false,
        forcePageReload: true,
      };
    case GenericMicrositesActionTypes.LEAD_SEND:
      sendLead(action.serviceNumber, action.micrositeParams);
      return state;
    default:
      return state;
  }
};
