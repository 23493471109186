import { AxiosResponse } from 'axios';
import { API_ENDPOINT_FBB_GET_MODEM_MAC_ADDRESS } from '../..';
import api from '../../../utils/api/api';
import { IGetFbbModemMacAddressInterface } from '../response/getFbbModemMacAddressInterface';

export default (
  subscriptionId: string,
  modemIdentifier: string,
): Promise<AxiosResponse<IGetFbbModemMacAddressInterface>> =>
  api.get({
    url: API_ENDPOINT_FBB_GET_MODEM_MAC_ADDRESS.replace(
      '{subscriptionId}',
      subscriptionId,
    ).replace('{modemIdentifier}', encodeURIComponent(modemIdentifier)),
  });
