import { useMutation, useQueryClient } from '@tanstack/react-query';
import api from 'src/utils/api/api';

export const DELETE_INVITATION_ENDPOINT =
  '/muj/rapi/account-management/invitation/delete';
export const DELETE_INVITATION_MUTATION_KEY = 'deleteInvitationMutation';
export const DELETE_INVITATION_KEY = 'deleteInvitation';

type DeleteInvitationRequest = {
  id: string;
};

type DeleteInvitationError = {
  message: {
    code: string;
    description: string;
  };
};

type DeleteInvitationResponse = {
  status: string;
};

const deleteInvitationFn = async (
  request: DeleteInvitationRequest,
): Promise<DeleteInvitationResponse> => {
  const response = await api
    .post<DeleteInvitationResponse>({
      url: DELETE_INVITATION_ENDPOINT,
      data: request,
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });

  return response.data;
};

export const useDeleteInvitation = () => {
  const queryClient = useQueryClient();

  return useMutation<
    DeleteInvitationResponse,
    DeleteInvitationError,
    DeleteInvitationRequest
  >({
    mutationFn: deleteInvitationFn,
    mutationKey: [DELETE_INVITATION_MUTATION_KEY],
    onSuccess: (data, variables) => {
      queryClient.setQueryData([DELETE_INVITATION_KEY, { variables }], data);
    },
  });
};
