import styled from 'styled-components';

export const BulletBorder = styled.span<{ active?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border: 1px solid
    ${(props) =>
      props.active ? props.theme.colors.turquoise : props.theme.colors.gray60};
  border-radius: 50%;
`;

export const Bullet = styled.div`
  width: 12px;
  height: 12px;
  background: ${(props) => props.theme.colors.turquoise};
  border-radius: 50%;
`;

export const SelectItemBase = styled.div`
  padding: 23px 0px;
  padding-right: 32px;
  cursor: pointer;

  &:not(:first-child) {
    border-top: 1px solid ${(props) => props.theme.colors.lightGrey5};
  }

  &:last-child {
    border-bottom: 1px solid ${(props) => props.theme.colors.lightGrey5};
  }
`;
