import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { useCoreOutage } from 'src/features/core';
import userSelector from 'src/store/user/userSelector';
import api from 'src/utils/api/api';

export const GET_CART_COUNT_ENPOINT = '/muj/rapi/cart/items/count';
const QUERY_KEY = 'getCartCount';

export type GetCartCountResponse = {
  count: number;
};

const getCartCount = async (): Promise<GetCartCountResponse> => {
  const { data } = await api.get<GetCartCountResponse>({
    url: GET_CART_COUNT_ENPOINT,
  });

  return data;
};

export const useGetCartCount = () => {
  const { data: outage } = useCoreOutage();
  const user = useSelector(userSelector);

  return useQuery({
    queryFn: () => getCartCount(),
    queryKey: [QUERY_KEY],
    enabled: !outage?.isReadMode && user.loaded && user.signedIn,
  });
};
