import { ActionCreator } from 'redux';
import { getExitServicePenalty } from 'src/api/exitServices';
import {
  IFetchMnpPenaltyBeginAction,
  IFetchMnpPenaltyFailureAction,
  IFetchMnpPenaltySuccessAction,
  IFetchMnpPenaltySuccessActionPayload,
} from './types/mnpPenaltyActionInterfaces';
import MnpPenaltyActionTypes from './types/mnpPenaltyActionTypes';

export const fetchMnpServicPenaltyBegin: ActionCreator<
  IFetchMnpPenaltyBeginAction
> = () => ({
  type: MnpPenaltyActionTypes.FETCH_MNP_PENALTY_BEGIN,
});

export const fetchMnpServicePenaltySuccess: ActionCreator<
  IFetchMnpPenaltySuccessAction
> = (payload: IFetchMnpPenaltySuccessActionPayload) => ({
  type: MnpPenaltyActionTypes.FETCH_MNP_PENALTY_SUCCESS,
  payload,
});

export const fetchMnpServicePenaltyFailure: ActionCreator<
  IFetchMnpPenaltyFailureAction
> = () => ({
  type: MnpPenaltyActionTypes.FETCH_MNP_PENALTY_FAILURE,
});

export const fetchMnpServicePenalty =
  (serviceNumber: string, interactionPoint: string) => (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch(fetchMnpServicPenaltyBegin());

      return getExitServicePenalty({ serviceNumber, interactionPoint })
        .then((response) => {
          dispatch(fetchMnpServicePenaltySuccess(response.data));
          resolve(response.data);
        })
        .catch(() => {
          dispatch(fetchMnpServicePenaltyFailure());
          reject();
        });
    });
