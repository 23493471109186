import { Reducer } from 'redux';
import initialState from '../../initialState';
import {
  IFetchPaymentInfoBeginAction,
  IFetchPaymentInfoFailureAction,
  IFetchPaymentInfoSuccessAction,
} from './types/paymentInfoActionInterfaces';
import PaymentInfoActionTypes from './types/paymentInfoActionTypes';
import IPaymentInfoState from './types/paymentInfoStateInterface';

type IPaymentInfoActions =
  | IFetchPaymentInfoBeginAction
  | IFetchPaymentInfoSuccessAction
  | IFetchPaymentInfoFailureAction;

const paymentInfoReducer: Reducer<IPaymentInfoState, IPaymentInfoActions> = (
  state = initialState.Billing.PaymentInfo,
  action,
) => {
  switch (action.type) {
    case PaymentInfoActionTypes.FETCH_PAYMENT_INFO_BEGIN:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
        baNumber: action.baNumber,
      };
    case PaymentInfoActionTypes.FETCH_PAYMENT_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        data: {
          ...state.data,
          [action.baNumber]: action.payload,
        },
      };
    case PaymentInfoActionTypes.FETCH_PAYMENT_INFO_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
        baNumber: null,
      };
    default:
      return state;
  }
};

export default paymentInfoReducer;
