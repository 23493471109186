enum UserRoleEnum {
  ROLE_ACCOUNT_TYPE_CONTRACT_BUSINESS_V20 = 'ROLE_ACCOUNT_TYPE_CONTRACT_BUSINESS_V20',
  ROLE_ESHOP_ACCESS = 'ROLE_ESHOP_ACCESS',
  ROLE_IS_UPC_MIGRATION_FLAG_TRUE = 'ROLE_IS_UPC_MIGRATION_FLAG_TRUE',
  ROLE_LOGIN_MODE_BILLING_ACCOUNT = 'ROLE_LOGIN_MODE_BILLING_ACCOUNT',
  ROLE_PASSWORD_TYPE_ADMIN = 'ROLE_PASSWORD_TYPE_ADMIN',
  ROLE_PASSWORD_TYPE_BA_ADMIN = 'ROLE_PASSWORD_TYPE_BA_ADMIN',
  ROLE_PASSWORD_TYPE_BILLING = 'ROLE_PASSWORD_TYPE_BILLING',
  ROLE_PASSWORD_TYPE_ENDUSER = 'ROLE_PASSWORD_TYPE_ENDUSER',
  ROLE_PA_EMPTY = 'ROLE_PA_EMPTY',
  ROLE_PA_NO_ROLES = 'ROLE_PA_NO_ROLES',
  ROLE_TYPE_PA = 'ROLE_TYPE_PA',
  ROLE_DIGI_OPCO_UNSUPPORTED = 'ROLE_DIGI_OPCO_UNSUPPORTED',
  ROLE_FORCED_MIGRATION = 'ROLE_FORCED_MIGRATION',
  ROLE_PROFILE_MANAGEMENT = 'ROLE_PROFILE_MANAGEMENT',
}

export default UserRoleEnum;
