import { IService } from 'src/store/services/types/service/serviceInterface';
import { LanguagesEnum } from '../../../types/translation/languagesEnum';
import generateExternalRoute from '../../../utils/router/generateExternalRoute';

export const getServicesFlat = (serviceGroup) => {
  let services = [];

  Object.keys(serviceGroup).map((group) => {
    services = services.concat(serviceGroup[group]);
    return group;
  });

  return services;
};

export const hasExternalLink = (service: IService): boolean => {
  return (
    service.attributes &&
    service.attributes.href &&
    service.attributes.href !== ''
  );
};

export const getExternalLink = (
  service: IService,
  locale: LanguagesEnum,
): string | null => {
  if (!hasExternalLink(service)) {
    return null;
  }

  try {
    return generateExternalRoute(service.attributes.href, locale);
  } catch (e) {
    return service.attributes.href;
  }
};

export const canModify = (service: IService) =>
  (service.eligibility && service.eligibility.canModify) || false;

export const canActivate = (service: IService) =>
  (service.eligibility && service.eligibility.canActivate) || false;

export const canTerminate = (service: IService) =>
  (service.eligibility && service.eligibility.canTerminate) || false;

export const isEligibleForChange = (service: IService): boolean => {
  return (
    !hasExternalLink(service) &&
    (canActivate(service) || canModify(service) || canTerminate(service))
  );
};

export const isServiceVisible = (service: IService) =>
  (service.eligibility &&
    service.eligibility.available &&
    service.eligibility.visible) ||
  false;

export const getUnitPrices = (service) => {
  let prices = [];

  if (
    Object.prototype.hasOwnProperty.call(service, 'unitPriceCollection') &&
    service.unitPriceCollection !== null &&
    Object.prototype.hasOwnProperty.call(
      service.unitPriceCollection,
      'unitPrices',
    )
  ) {
    const { unitPrices = [] } = service.unitPriceCollection;

    prices = unitPrices !== null ? unitPrices : [];
  }

  return prices;
};

export const isEnabledInReadMode = (productOfferingId: string): boolean => {
  const enabledInReadMode = ['pin_puk'];

  return enabledInReadMode.includes(productOfferingId);
};

export const resolveChangeButtonText = (service: IService): string => {
  if (hasExternalLink(service)) {
    return 'more';
  }
  const buttonTextMap: Record<string, 'show' | 'more'> = {
    femto: 'show',
    regular_cost_control_sms: 'more',
  };
  return buttonTextMap[service.productOfferingId] ?? 'change';
};
