import {
  Button,
  Card,
  IconClose,
  IconSuccessTick,
  IconUnifiedCommunications,
  InputRedesign,
  Text,
  Title,
} from '@vodafone/red-white';
import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { HStack } from 'src/components/Layout';
import { ModalContentProps } from 'src/components/Modal/types/modalContentInterfaces';
import { useActiveFbbServiceNumber } from 'src/components/Services/components/ServiceManagement/FbbActiveModemDevicesService/hooks/useActiveFbbServiceNumber';
import {
  ServiceRequestArea,
  ServiceRequestType,
  useCustomerServiceRequestCreate,
} from 'src/features/customer';
import { Modem } from 'src/features/services';
import SanitizedHtml from 'src/utils/sanitizeHtml';
import { removeSpaces } from 'src/utils/serviceNumberFormatter';
import { phoneNumberValidator } from 'src/utils/validators/phoneNumberValidator';
import styled, { ThemeContext } from 'styled-components';

const CustomCardPadding = styled.div`
  padding: 40px 0 0 0;
  text-align: center;
`;

type OptionProps = {
  modem: Modem;
};

const CreateServiceRequestModalContent: FC<ModalContentProps<OptionProps>> = ({
  setCompact,
  setCompactContent,
  onClose,
  options,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('component');
  const { mutate, isSuccess, isPending, isError } =
    useCustomerServiceRequestCreate();
  const themeContext = useContext(ThemeContext);
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const { fbbServiceNumber } = useActiveFbbServiceNumber();

  const isPhoneNumberValid = useMemo(() => {
    return phoneNumberValidator(removeSpaces(phoneNumber));
  }, [phoneNumber]);

  useEffect(() => {
    setCompact(true);
    setCompactContent(true);
  }, [setCompactContent, setCompact, dispatch]);

  // TODO: pokud bude potreba info o modemu pro SP
  const optionalModemInfo = `serviceNumber:${fbbServiceNumber}, deviceIdentifier:${
    options.modem?.cpeManagement.deviceIdentifier || ''
  }`;

  const onClickHandler = () => {
    mutate({
      description: `Mám problém s modemem. Zavolejte mi prosím ${phoneNumber}. ${optionalModemInfo}`,
      type: ServiceRequestType.TECH_HELPDESK,
      area: ServiceRequestArea.INTERNET_THD,
    });
  };

  return (
    <Card
      style={{ maxWidth: 590, width: '100%', boxShadow: 'none' }}
      waiting={isPending}
    >
      <CustomCardPadding>
        {isSuccess && (
          <>
            <IconSuccessTick size={100} />
            <Title
              color="darkGrey"
              fontFamily="VodafoneRg"
              fontWeight="bold"
              level={3}
              style={{ marginBottom: 15, marginTop: 15 }}
              fontSize={22}
            >
              {t('service-request-modal.success.title')}
            </Title>
            <Text fontFamily="VodafoneRg" fontSize={16}>
              <SanitizedHtml
                htmlTag="span"
                rawHtmlData={t('service-request-modal.success.description')}
              />
            </Text>
            <div style={{ marginTop: 30 }}>
              <Button variant="secondary" onClick={() => onClose()}>
                {t('button.close')}
              </Button>
            </div>
          </>
        )}

        {isError && (
          <>
            <IconClose size={150} color={themeContext.colors.red} />
            <Title
              color="darkGrey"
              fontFamily="VodafoneRg"
              fontWeight="bold"
              level={3}
              style={{ marginBottom: 15 }}
              fontSize={22}
            >
              {t('service-request-modal.error.title')}
            </Title>
            <Text fontFamily="VodafoneRg" fontSize={16}>
              {t('service-request-modal.error.description')}
            </Text>
            <div style={{ marginTop: 30 }}>
              <Button variant="secondary" onClick={() => onClose()}>
                {t('button.close')}
              </Button>
            </div>
          </>
        )}

        {(isPending || (!isPending && !isSuccess && !isError)) && (
          <>
            <IconUnifiedCommunications size={100} />
            <Title
              color="darkGrey"
              fontFamily="VodafoneRg"
              fontWeight="bold"
              level={3}
              style={{ marginBottom: 20 }}
              fontSize={22}
            >
              {t('service-request-modal.title')}
            </Title>

            <Text fontFamily="VodafoneRg" fontSize={16}>
              <SanitizedHtml
                htmlTag="span"
                rawHtmlData={t('service-request-modal.description')}
              />
            </Text>

            <HStack
              justify="center"
              spacing="24px"
              align="center"
              style={{ marginTop: 30 }}
            >
              <InputRedesign
                id="serviceRequestModal_label"
                name="pin"
                onChange={(e) => setPhoneNumber(e.target.value)}
                value={phoneNumber}
                label={t('service-request-modal.label')}
                labelAsPlaceholder={false}
              />

              <Button
                variant="primary"
                onClick={onClickHandler}
                disabled={!isPhoneNumberValid}
              >
                {t('service-request-modal.submit')}
              </Button>
            </HStack>
          </>
        )}
      </CustomCardPadding>
    </Card>
  );
};

export default CreateServiceRequestModalContent;
