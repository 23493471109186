import { IInteractionPointJson } from '@vodafone/red-white';
import { createSelector, Selector } from 'reselect';
import { IMicrositeConfigJsonData } from 'src/api/genericMicrosites/response/getMicrositeDataInterface';
import { IApplicationState } from '../appStateInterface';
import { IGenericMicrositesState } from './types/genericMicrositesStateInterface';

export const genericMicrositesSelector: Selector<
  IApplicationState,
  IGenericMicrositesState
> = (state: IApplicationState) => {
  return state.GenericMicrosites;
};

export const showLeadFormSelector: Selector<IApplicationState, boolean> =
  createSelector([genericMicrositesSelector], (gm) => {
    if (typeof gm.jsonData === 'string') {
      return false;
    }
    const jsonData = gm.jsonData as IInteractionPointJson;

    return typeof jsonData.showLeadForm === 'boolean' && jsonData.showLeadForm;
  });

export const showAccountSwitcherSelector: Selector<IApplicationState, boolean> =
  createSelector([genericMicrositesSelector], (gm) => {
    if (typeof gm.jsonData === 'string') {
      return false;
    }
    const jsonData = gm.jsonData as IInteractionPointJson;

    return (
      typeof jsonData.showAccountSwitcher === 'boolean' &&
      jsonData.showAccountSwitcher
    );
  });

export const serviceSwitcherDisabledSelector: Selector<
  IApplicationState,
  boolean
> = createSelector([genericMicrositesSelector], (gm) => {
  if (typeof gm.jsonData === 'string') {
    return false;
  }
  const jsonData = gm.jsonData as IInteractionPointJson;

  return (
    typeof jsonData.serviceSwitcherDisabled === 'boolean' &&
    jsonData.serviceSwitcherDisabled
  );
});

export const leadFormTitleSelector: Selector<IApplicationState, string> =
  createSelector([genericMicrositesSelector], (gm) => {
    if (typeof gm.jsonData === 'string') {
      return '';
    }
    const jsonData = gm.jsonData as IInteractionPointJson;

    return typeof jsonData.leadFormTitle === 'string'
      ? jsonData.leadFormTitle
      : '';
  });

export const leadFormSubtitleSelector: Selector<IApplicationState, string> =
  createSelector([genericMicrositesSelector], (gm) => {
    if (typeof gm.jsonData === 'string') {
      return '';
    }
    const jsonData = gm.jsonData as IInteractionPointJson;

    return typeof jsonData.leadFormSubtitle === 'string'
      ? jsonData.leadFormSubtitle
      : '';
  });

export const serviceSwitcherTitleSelector: Selector<IApplicationState, string> =
  createSelector([genericMicrositesSelector], (gm) => {
    if (typeof gm.jsonData === 'string') {
      return '';
    }
    const jsonData = gm.jsonData as IMicrositeConfigJsonData;

    return jsonData.serviceSwitcherTitle ?? '';
  });

export const serviceSwitcherSubtitleSelector: Selector<
  IApplicationState,
  string
> = createSelector([genericMicrositesSelector], (gm) => {
  if (typeof gm.jsonData === 'string') {
    return '';
  }
  const jsonData = gm.jsonData as IMicrositeConfigJsonData;

  return jsonData.serviceSwitcherSubtitle ?? '';
});

export const leadSentSelector: Selector<IApplicationState, boolean> =
  createSelector(
    [genericMicrositesSelector],
    (gm) => gm.micrositeLeadState.leadSent,
  );

export const leadSendingFailedSelector: Selector<IApplicationState, boolean> =
  createSelector(
    [genericMicrositesSelector],
    (gm) =>
      gm.micrositeLeadState.leadSent === true &&
      gm.micrositeLeadState.leadSentSuccess === false,
  );

export const isLoggedSelector: Selector<IApplicationState, boolean> =
  createSelector([genericMicrositesSelector], (gm) => {
    if (typeof gm.jsonData === 'string') {
      return false;
    }
    const jsonData = gm.jsonData as IInteractionPointJson;

    return typeof jsonData.isLogged === 'boolean' && jsonData.isLogged;
  });

export const hasForcePageReloadSelector: Selector<IApplicationState, boolean> =
  createSelector([genericMicrositesSelector], (gm) => {
    return gm.forcePageReload;
  });
