import { Button, Link } from '@vodafone/red-white';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'src/hooks/useAppDispatch';
import { openModal } from 'src/store/modal/modalActions';
import ModalType from 'src/store/modal/types/ModalType';
import CardReceivedPaymentsResult from 'src/ui/components/CardReceivedPaymentsResult';
import styled from 'styled-components';

const ExtraLink = styled(Link)`
  font-size: 16px;
  color: ${(props) => props.theme.colors.darkGrey};
`;

interface IProps {
  close: () => void;
}

const PaymentStatusError: React.FC<IProps> = ({ close }) => {
  const { t } = useTranslation('billing');
  const dispatch = useAppDispatch();

  const payAgain = () => {
    close();
    dispatch(openModal({ type: ModalType.PaymentInfo }));
  };

  return (
    <CardReceivedPaymentsResult
      status="warning"
      title={t('billing:paymentStatus.title-error')}
      subTitle={t('billing:paymentStatus.subtitle-error')}
      extra={[
        <Button variant="primary" onClick={payAgain}>
          {t('billing:paymentStatus.button-label-error')}
        </Button>,
        <ExtraLink onClick={close}>
          {t('billing:paymentStatus.extra-link-error')}
        </ExtraLink>,
      ]}
    />
  );
};

export default PaymentStatusError;
