export enum AnalyticsEnum {
  CHANNEL_WSC = 'WSC;',
  PRODUCT_VIEW = 'prodView',
  SERVICE_ACTIVATION = 'WA-',
  SERVICE_DEACTIVATION = 'WD-',
  SERVICE_CHANGE = 'WC-',

  SERVICE_FLEXI_CEILING = 'flexi_ceiling',
  SERVICE_DATA = 'data',
  SERVICE_FON = 'fon',

  SERVICE_BLOCKING = 'BARRINGBYCUSTOMER',
  SERVICE_BLOCKING_OMNITURE = 'barring_by_customer',
  SERVICE_VOLTE = 'VOLTE',
  SERVICE_VOLTE_OMNITURE = 'VoLTE',
}
