import { ActionCreator } from 'redux';
import requestFbbModemRestart from 'src/api/fbb/request/requestFbbModemRestart';
import { API_ENDPOINT_FBB_REQUEST_MODEM_RESET } from '../../../api';
import {
  apiBegin,
  apiFailure,
  apiSuccess,
} from '../../application/applicationActions';
import {
  FbbModemRestartTypes,
  REQUEST_FBB_MODEM_RESTART_LOADING,
  REQUEST_FBB_MODEM_RESTART_SUCCESS,
} from '../types/fbbModemRestart/fbbModemRestartActionTypes';
import {
  fetchModemMacAddressRefresh,
  getFbbModemMacAddress,
} from './fbbModemMacAddressActions';

export const requestModemRestartSuccess: ActionCreator<
  FbbModemRestartTypes
> = () => ({
  type: REQUEST_FBB_MODEM_RESTART_SUCCESS,
});

export const requestModemRestartLoading: ActionCreator<
  FbbModemRestartTypes
> = () => ({
  type: REQUEST_FBB_MODEM_RESTART_LOADING,
});

export const getFbbModemRestart =
  (subscriptionId: string, modemIdentifier: string) => (dispatch) => {
    dispatch(apiBegin(API_ENDPOINT_FBB_REQUEST_MODEM_RESET));
    dispatch(requestModemRestartLoading());

    return requestFbbModemRestart(subscriptionId, modemIdentifier)
      .then((response) => {
        if (response.status === 202) {
          dispatch(requestModemRestartSuccess());
          dispatch(apiSuccess(API_ENDPOINT_FBB_REQUEST_MODEM_RESET));
          dispatch(fetchModemMacAddressRefresh());
          dispatch(getFbbModemMacAddress(subscriptionId, modemIdentifier));
        } else {
          dispatch(apiFailure(API_ENDPOINT_FBB_REQUEST_MODEM_RESET));
        }
      })
      .catch(() => {
        dispatch(apiFailure(API_ENDPOINT_FBB_REQUEST_MODEM_RESET));
      });
  };
