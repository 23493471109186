import { Reducer } from 'redux';
import initialState from '../initialState';
import {
  IRecoveryRequestBeginAction,
  IRecoveryRequestFailureAction,
  IRecoveryRequestSuccessAction,
} from './types/recoveryRequestActionInterfaces';
import RecoveryRequestActionTypes from './types/recoveryRequestActionTypes';
import IRecoveryRequestState from './types/recoveryRequestStateInterface';

type IRecoveryRequestAction =
  | IRecoveryRequestBeginAction
  | IRecoveryRequestSuccessAction
  | IRecoveryRequestFailureAction;

const recoveryRequestReducer: Reducer<IRecoveryRequestState> = (
  state: IRecoveryRequestState = initialState.RecoveryRequest,
  action: IRecoveryRequestAction,
) => {
  switch (action.type) {
    case RecoveryRequestActionTypes.RECOVERY_REQUEST_BEGIN:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      };
    case RecoveryRequestActionTypes.RECOVERY_REQUEST_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        loaded: true,
        error: false,
      };
    case RecoveryRequestActionTypes.RECOVERY_REQUEST_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    default:
      return state;
  }
};

export default recoveryRequestReducer;
