import { ActionCreator } from 'redux';
import {
  ILoadingClearAction,
  ILoadingStartAction,
  ILoadingStopAction,
} from './types/loadingActionInterfaces';
import { LoadingActionTypes } from './types/loadingActionTypes';

export const loadingStart: ActionCreator<ILoadingStartAction> = () => ({
  type: LoadingActionTypes.LOADING_START,
});

export const loadingStop: ActionCreator<ILoadingStopAction> = () => ({
  type: LoadingActionTypes.LOADING_STOP,
});

export const loadingClear: ActionCreator<ILoadingClearAction> = () => ({
  type: LoadingActionTypes.LOADING_CLEAR,
});
