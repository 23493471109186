import { Button, Link } from '@vodafone/red-white';
import styled from 'styled-components';

export const CardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const IconHeader = styled.div`
  display: flex;
  margin-right: 15px;
`;

export const CardPadding = styled.div`
  padding: 40px 10px 0 10px;

  @media ${(props) => props.theme.breakpoints.smOnly} {
    padding-top: 20px;
  }
`;

export const Line = styled.div`
  width: 100%;
  display: flex;
  gap: 20px;
  font-size: 16px;
  justify-content: space-between;
  border-bottom: 1px ${(props) => props.theme.colors.grayLight} solid;
  padding: 10px 0;
  @media ${(props) => props.theme.breakpoints.smOnly} {
    flex-direction: column;
  }
`;

export const Service = styled.div`
  display: flex;
  width: 30%;

  @media ${(props) => props.theme.breakpoints.smOnly} {
    padding-top: 10px;
  }
`;

export const Icon = styled.div`
  display: flex;
  margin-right: 15px;
  border-radius: 3px;
  padding: 3px;
  background: ${(props) => props.theme.colors.grayLight6};
`;

export const ServiceNumber = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  @media ${(props) => props.theme.breakpoints.smOnly} {
    text-align: left;
  }
`;

export const CodeWrapper = styled.div`
  width: 70%;
  text-align: center;
  align-items: center;
  justify-content: space-between;
  display: flex;
  @media ${(props) => props.theme.breakpoints.smOnly} {
    width: 100%;
  }
`;

export const Code = styled.div`
  font-weight: bold;
`;

export const StyledLink = styled(Link)`
  width: 150px;
  font-size: 16px;
  text-decoration: none;
  color: ${(props) => props.theme.colors.primaryColor};

  &:hover {
    text-decoration: underline;
  }
`;

export const NoCode = styled.div`
  width: 150px;
`;

export const StyledError = styled.div`
  margin: 30px 0 50px 0;
  font-size: 20px;
  text-align: center;
  align-items: center;
`;

export const CodeButtonWrapper = styled(Button)`
  margin: 20px auto 0 auto;
  display: flex;
  align-items: center;
`;
