import { useTranslation } from 'react-i18next';
import ModalMessageTemplate from 'src/components/Modal/components/ModalMessage/ModalMessageTemplate';
import ModalMessageTypeEnum from 'src/components/Modal/components/ModalMessage/types/ModalMessageTypeEnum';
import { useAppDispatch } from 'src/hooks/useAppDispatch';
import { closeModal } from 'src/store/modal/modalActions';
import ModalType from 'src/store/modal/types/ModalType';
import SanitizedHtml from 'src/utils/sanitizeHtml';

const RecurringDataActivatedContent = () => {
  const { t } = useTranslation(['component', 'dashboard']);
  const dispatch = useAppDispatch();

  return (
    <ModalMessageTemplate
      type={ModalMessageTypeEnum.SUCCESS}
      heading={t('dashboard:dataBox.recurring-data-modal-title')}
      description={
        <SanitizedHtml
          htmlTag="span"
          rawHtmlData={t('dashboard:dataBox.recurring-data-modal-description')}
        />
      }
      button={{
        label: t('component:button.close'),
        onClick: () =>
          dispatch(closeModal({ type: ModalType.RecurringDataActivated })),
      }}
    />
  );
};

export default RecurringDataActivatedContent;
