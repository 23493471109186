import { createSelector, Selector } from 'reselect';
import {
  IESimActivateSimOrESimState,
  IESimDeliveryState,
  IESimDetailState,
  IESimQrCode,
  IEsimSmsForkSettings,
  IESimState,
  IOneNumberNumbersItemState,
  IOneNumberPortalItemState,
  ISimSwapState,
} from 'src/store/esim/types/esimStateInterface';
import { IApplicationState } from '../appStateInterface';

const getESim: Selector<IApplicationState, IESimState> = (
  state: IApplicationState,
) => state.ESim;

export const selectSimSwap: Selector<IApplicationState, ISimSwapState> =
  createSelector([getESim], (ESim) => {
    return ESim.simSwap;
  });

export const selectESimDelivery: Selector<
  IApplicationState,
  IESimDeliveryState
> = createSelector([getESim], (ESim) => {
  return ESim.eSimDelivery;
});

export const selectESimDetail: Selector<IApplicationState, IESimDetailState> =
  createSelector([getESim], (ESim) => {
    return ESim.eSimDetail;
  });

export const selectESimSmsForkSettings: Selector<
  IApplicationState,
  IEsimSmsForkSettings
> = createSelector([getESim], (ESim) => {
  return ESim.smsForkSettings;
});

export const selectESimQRCodePayload: Selector<
  IApplicationState,
  string | null
> = createSelector([selectESimDetail], (ESimDetail) => {
  if (
    ESimDetail.loaded &&
    ESimDetail.data?.smdpfqdn &&
    ESimDetail.data?.matchingId
  ) {
    return `LPA:1$${ESimDetail.data.smdpfqdn}$${ESimDetail.data.matchingId}`;
  }

  return null;
});

export const selectESimQRCode: Selector<IApplicationState, IESimQrCode> =
  createSelector([getESim], (ESim) => {
    return ESim.qrCode;
  });

export const selectESimActivateSimOrESim: Selector<
  IApplicationState,
  IESimActivateSimOrESimState
> = createSelector([getESim], (ESim) => {
  return ESim.activateSimOrESim;
});

export const selectESimOneNumberPortalForCaNumber = (
  state: IApplicationState,
  caNumber: string,
): IOneNumberPortalItemState =>
  createSelector(
    [getESim],
    (ESim) => ESim.oneNumberPortal.data[caNumber],
  )(state);

export const selectESimOneNumberOneNumbersForServiceNumber = (
  state: IApplicationState,
  serviceNumber: string,
): IOneNumberNumbersItemState =>
  createSelector(
    [getESim],
    (ESim) => ESim.oneNumberNumbers.data[serviceNumber],
  )(state);

export const selectHasESimOneNumberOneNumbersForServiceNumber = (
  state: IApplicationState,
  serviceNumber: string,
): boolean =>
  createSelector(
    [getESim],
    (ESim) =>
      ESim.oneNumberNumbers.data[serviceNumber] &&
      ESim.oneNumberNumbers.data[serviceNumber].oneNumbers.length > 0,
  )(state);
