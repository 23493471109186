import { Button, Card, Text } from '@vodafone/red-white';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ModalContentProps } from 'src/components/Modal/types/modalContentInterfaces';
import { useAppDispatch } from 'src/hooks/useAppDispatch';
import { closeModal } from 'src/store/modal/modalActions';
import ModalType from 'src/store/modal/types/ModalType';
import { IService } from 'src/store/services/types/service/serviceInterface';
import ISubscription from 'src/store/user/types/subscriptionInterface';
import { selectCurrentSubscription } from 'src/store/user/userSelector';
import { formatServiceNumber } from 'src/utils/serviceNumberFormatter';
import { scrollTo, ScrollToBehaviorEnum } from 'src/utils/ui/scrollToActions';
import styled from 'styled-components';

const CustomCardPadding = styled.div`
  padding: 40px 0 0 0;
  text-align: center;
`;

const StyledDescription = styled.p`
  padding: 20px 0;
  margin: 0;
`;

const StyledCustomerInfo = styled.div`
  margin-bottom: 30px;
`;

const StyledCustomerInfoName = styled(Text)`
  margin-top: 10px;
`;

const StyledButtonComponent = styled(Button)`
  margin: 0 10px;
`;

interface IOptions {
  service: IService;
  onConfirmClick: () => void;
}

interface ISimActivationConfirmModalContent extends ModalContentProps {
  options: IOptions;
}

const SimActivationConfirmModalContent: FC<
  ISimActivationConfirmModalContent
> = ({ setCompact, setCompactContent, options }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['services']);
  const { serviceNumber, nickname }: ISubscription = useSelector(
    selectCurrentSubscription,
  );

  useEffect(() => {
    setCompact(true);
    setCompactContent(true);
    dispatch(scrollTo(options.service.id, ScrollToBehaviorEnum.AUTO));
  }, [setCompactContent, setCompact, dispatch, options.service.id]);

  const onNoButtonClick = () => {
    dispatch(closeModal({ type: ModalType.SimActivationConfirm }));
  };

  const onYesButtonClick = () => {
    dispatch(closeModal({ type: ModalType.SimActivationConfirm }));
    dispatch(options.onConfirmClick);
  };

  return (
    <Card
      style={{ maxWidth: 700, width: '100%', boxShadow: 'none' }}
      waiting={false}
    >
      <CustomCardPadding>
        <Text block fontFamily="VodafoneRgBd" fontSize={28} color="darkGrey">
          {t('services:sim-activation.modal.title')}
        </Text>

        <StyledDescription>
          {t('services:sim-activation.modal.description')}
        </StyledDescription>

        <StyledCustomerInfo>
          <Text
            block
            fontFamily="VodafoneRgBd"
            fontSize={28}
            color="turquoiseDark"
          >
            {formatServiceNumber(serviceNumber)}
          </Text>
          <StyledCustomerInfoName
            block
            fontFamily="VodafoneRg"
            fontSize={18}
            color="turquoiseDark"
          >
            {nickname}
          </StyledCustomerInfoName>
        </StyledCustomerInfo>

        <StyledButtonComponent variant="secondary" onClick={onYesButtonClick}>
          <Text fontFamily="VodafoneRgBd" fontSize={18}>
            {t('services:sim-activation.modal.button-yes')}
          </Text>
        </StyledButtonComponent>
        <StyledButtonComponent variant="secondary" onClick={onNoButtonClick}>
          <Text fontFamily="VodafoneRgBd" fontSize={18}>
            {t('services:sim-activation.modal.button-no')}
          </Text>
        </StyledButtonComponent>
      </CustomCardPadding>
    </Card>
  );
};

export default SimActivationConfirmModalContent;
