import { Reducer } from 'redux';
import { InvoicesSummaryResponse } from 'src/api/invoices/response/getInvoicesSummaryResponseInterface';
import initialState from '../initialState';
import {
  IFetchInvoiceBreakdownBeginAction,
  IFetchInvoiceBreakdownFailureAction,
  IFetchInvoiceBreakdownSuccessAction,
  IFetchInvoicesBeginAction,
  IFetchInvoicesFailureAction,
  IFetchInvoicesSuccessAction,
  IFetchInvoicesSummaryBeginAction,
  IFetchInvoicesSummaryFailureAction,
  IFetchInvoicesSummarySuccessAction,
} from './types/invoicesActionInterfaces';
import { InvoicesActionTypes } from './types/invoicesActionTypes';
import { IInvoicesState } from './types/invoicesStateInterface';

type InvoicesAction =
  | IFetchInvoicesBeginAction
  | IFetchInvoicesSuccessAction
  | IFetchInvoicesFailureAction
  | IFetchInvoiceBreakdownBeginAction
  | IFetchInvoiceBreakdownSuccessAction
  | IFetchInvoiceBreakdownFailureAction
  | IFetchInvoicesSummaryBeginAction
  | IFetchInvoicesSummarySuccessAction
  | IFetchInvoicesSummaryFailureAction;

const invoicesReducer: Reducer<IInvoicesState> = (
  state: IInvoicesState = initialState.Invoices,
  action: InvoicesAction,
) => {
  switch (action.type) {
    case InvoicesActionTypes.FETCH_INVOICES_BEGIN:
      return {
        ...state,
        invoices: {
          ...state.invoices,
          loading: true,
          loaded: false,
        },
      };

    case InvoicesActionTypes.FETCH_INVOICES_SUCCESS:
      return {
        ...state,
        invoices: {
          ...state.invoices,
          loading: false,
          loaded: true,
          error: false,
          data: {
            ...state.invoices.data,
            [action.baNumber]: action.payload.invoices,
          },
        },
      };

    case InvoicesActionTypes.FETCH_INVOICES_FAILURE:
      return {
        ...state,
        invoices: {
          ...state.invoices,
          loading: false,
          loaded: true,
          error: true,
          data: {
            ...state.invoices.data,
          },
        },
      };

    case InvoicesActionTypes.FETCH_INVOICE_BREAKDOWN_BEGIN:
      return {
        ...state,
        breakdown: {
          ...state.breakdown,
          loading: true,
          loadingInvoiceId: action.invoiceId,
          loaded: false,

          data: {
            ...state.breakdown.data,
            [action.invoiceId]: {
              ...state.breakdown.data[action.invoiceId],
              loading: true,
              loaded: false,
              error: false,
            },
          },
        },
      };

    case InvoicesActionTypes.FETCH_INVOICE_BREAKDOWN_SUCCESS:
      return {
        ...state,
        breakdown: {
          ...state.breakdown,
          loading: false,
          loaded: true,
          error: false,
          loadingInvoiceId: null,
          // data: {...state.breakdown.data, ...{[action.invoiceId]: action.payload}},

          data: {
            ...state.breakdown.data,
            [action.invoiceId]: {
              ...action.payload,
              loading: false,
              loaded: true,
              error: false,
            },
          },
        },
      };

    case InvoicesActionTypes.FETCH_INVOICE_BREAKDOWN_FAILURE:
      return {
        ...state,
        breakdown: {
          ...state.breakdown,
          loading: false,
          loadingInvoiceId: null,
          loaded: true,
          error: true,

          data: {
            ...state.breakdown.data,
            [action.invoiceId]: {
              ...state.breakdown.data[action.invoiceId],
              loading: false,
              loaded: false,
              error: true,
            },
          },
        },
      };

    // summary
    case InvoicesActionTypes.FETCH_INVOICES_SUMMARY_BEGIN:
      return {
        ...state,
        summary: {
          ...state.summary,
          selectedBaNumber: action.baNumber,
          data: {
            [action.baNumber]: {
              ...state.summary.data[action.baNumber],
              loading: true,
              loaded: false,
              error: false,
            },
          },
        },
      };

    case InvoicesActionTypes.FETCH_INVOICES_SUMMARY_SUCCESS:
      // eslint-disable-next-line no-param-reassign
      state.summary.data[action.baNumber].years = {};
      action.payload.years.forEach((item: InvoicesSummaryResponse) => {
        // eslint-disable-next-line no-param-reassign
        state.summary.data[action.baNumber].years[item.year] = item.totalAmount;
      });

      return {
        ...state,
        summary: {
          ...state.summary,
          data: {
            [action.baNumber]: {
              ...state.summary.data[action.baNumber],
              loading: false,
              loaded: true,
              error: false,
            },
          },
        },
      };

    case InvoicesActionTypes.FETCH_INVOICES_SUMMARY_FAILURE:
      return {
        ...state,
        summary: {
          ...state.summary,
          data: {
            [action.baNumber]: {
              ...state.summary.data[action.baNumber],
              loading: false,
              loaded: false,
              error: true,
            },
          },
        },
      };

    default:
      return state;
  }
};

export default invoicesReducer;
