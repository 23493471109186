import { Device } from './types/applicationStateInterface';

export const SET_MEDIA_DEVICE = 'SET_MEDIA_DEVICE';
export const SET_PREVIOUS_PAGE = 'SET_PREVIOUS_PAGE';
export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';

export const FETCH_API_BEGIN = 'FETCH_API_BEGIN';
export const FETCH_API_SUCCESS = 'FETCH_API_SUCCESS';
export const FETCH_API_FAILURE = 'FETCH_API_FAILURE';
export const FETCH_API_RELOAD = 'FETCH_API_RELOAD';
export const SHOW_LOADER = 'SHOW_LOADER';
export const HIDE_LOADER = 'HIDE_LOADER';

export interface IFetchApiBeginAction {
  type: typeof FETCH_API_BEGIN;
  payload: string;
}

export interface IFetchApiSuccessAction {
  type: typeof FETCH_API_SUCCESS;
  payload: string;
}

export interface IFetchApiFailureAction {
  type: typeof FETCH_API_FAILURE;
  payload: string;
}

export interface IFetchApiReloadAction {
  type: typeof FETCH_API_RELOAD;
  payload: string;
}

export interface IShowLoaderAction {
  type: typeof SHOW_LOADER;
}

export interface ISetDeviceActionInterface {
  type: typeof SET_MEDIA_DEVICE;
  payload: Device;
}

export interface ISetPreviousPageInterface {
  type: typeof SET_PREVIOUS_PAGE;
  payload: string;
}

export interface ISetCurrentPageInterface {
  type: typeof SET_CURRENT_PAGE;
  payload: string;
}
export interface IHideLoaderAction {
  type: typeof HIDE_LOADER;
}

export type ApplicationActionTypes =
  | IFetchApiBeginAction
  | IFetchApiSuccessAction
  | IFetchApiFailureAction
  | IFetchApiReloadAction
  | IShowLoaderAction
  | IHideLoaderAction
  | ISetDeviceActionInterface
  | ISetPreviousPageInterface
  | ISetCurrentPageInterface;
