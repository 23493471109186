import { ISessionResponse } from 'src/api/core/response/sessionResponseInterface';

export const setAcrAndOptionSettings = (response: ISessionResponse) => {
  if (response.session.acrResult && response.session.optinSettings) {
    const { acrResult, optinSettings } = response.session;

    if (window.vfconsents && window.vfconsents.setAll && optinSettings) {
      window.vfconsents.setAll(optinSettings);
    }
    if (window.vfAcr && window.vfAcr.setAll && acrResult) {
      window.vfAcr.setAll(acrResult.status, acrResult.acr);
    }
  }
};
