import { APP_MOCKS } from 'src/env';
import { API_DEFAULT_ENDPOINT } from '../endpoints/endpoints';

export const resolveEndpoint = (route: string, mock?: string): string => {
  if (APP_MOCKS === 'true') {
    if (mock) {
      return `${API_DEFAULT_ENDPOINT}/static/api-mocks${mock}`;
    } else {
      return `${API_DEFAULT_ENDPOINT}/static/api-mocks/empty-response.json?route=${route}`;
    }
  } else {
    return route;
  }
};
