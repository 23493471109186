import { LanguagesEnum } from '../../../../types/translation/languagesEnum';

export default (number: number, language: LanguagesEnum) => {
  const formatter = new Intl.NumberFormat(
    language === LanguagesEnum.EN ? 'en-CZ' : 'cs-CZ',
    {
      style: 'currency',
      currency: 'CZK',
      minimumFractionDigits: number % 1 === 0 ? 0 : 2,
      maximumFractionDigits: number % 1 === 0 ? 0 : 2,
    },
  );

  return formatter.format(number);
};
