import { AxiosResponse } from 'axios';
import api from 'src/utils/api/api';
import {
  API_ENDPOINT_EET_RECEIPTS_DOWNLOAD,
  API_ENDPOINT_EET_RECEIPTS_SUMMARY,
} from '..';
import { IGetEetReceiptsResponse } from './response/getEetReceiptsResponseInterface';

export const getEetReceipts = (
  baNumber: string,
): Promise<AxiosResponse<IGetEetReceiptsResponse>> =>
  api.get({
    url: API_ENDPOINT_EET_RECEIPTS_SUMMARY.replace('{baNumber}', baNumber),
  });

export const getEetReceiptDownloadUrl = (
  baNumber: string,
  documentId: string,
): string => {
  return API_ENDPOINT_EET_RECEIPTS_DOWNLOAD.replace(
    '{baNumber}',
    baNumber,
  ).replace('{documentId}', documentId);
};
