import { LoaderFullPage } from '@vodafone/red-white';
import { Navigation as NavigationUser } from '@vodafone/user';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { getRoute, IRoute, MenuIdEnum } from 'src/config/routes';
import { useGetCartCount } from 'src/features/cart/hooks';
import { useCoreConfig, useCoreOutage } from 'src/features/core';
import { useGetLayout } from 'src/features/layout';
import {
  useUnreadContactUsCount,
  useUnreadMessagesCount,
  useUnreadNewsCount,
} from 'src/features/messages/hooks';
import { useAppDispatch } from 'src/hooks/useAppDispatch';
import { IApplicationState } from 'src/store/appStateInterface';
import selectLanguage from 'src/store/language/languageSelector';
import { openModal } from 'src/store/modal/modalActions';
import ModalType from 'src/store/modal/types/ModalType';
import UserAuthorizationRoleEnum from 'src/store/user/types/UserAuthorizationRoleEnum';
import UserRoleEnum from 'src/store/user/types/UserRoleEnum';
import userSelector, {
  selectIsUserWithRole,
} from 'src/store/user/userSelector';
import { redirect } from 'src/utils/redirect';
import generateExternalRoute from 'src/utils/router/generateExternalRoute';
import { isUsageRelevant } from 'src/utils/usage';
import { LanguagesEnum } from '../../types/translation/languagesEnum';
import { IMessage, IUserMessages } from './types/userMessagesInterface';
import { getRouteIdFromMenuItem } from './utils/helpers';

const Navigation: React.FC<RouteComponentProps> = ({ match }) => {
  const { t } = useTranslation('layout', { useSuspense: false });
  const [route, setRoute] = useState<IRoute | null | undefined>(null);
  const outage = useCoreOutage();
  const user = useSelector(userSelector);
  const { data: cartCount } = useGetCartCount();
  const { data: unreadMessages, isSuccess: isUnreadMessagesSuccess } =
    useUnreadMessagesCount(user.signedIn);
  const { data: unreadNews, isSuccess: isUnreadNewsSuccess } =
    useUnreadNewsCount(user.signedIn);
  const { data: unreadContactUs, isSuccess: isUnreadContactUsSuccess } =
    useUnreadContactUsCount(user.signedIn);
  const config = useCoreConfig();

  const hasEmptyPARole = useSelector((state: IApplicationState) =>
    selectIsUserWithRole(state, [
      UserRoleEnum.ROLE_PA_NO_ROLES,
      UserRoleEnum.ROLE_PA_EMPTY,
    ]),
  );

  useEffect(() => {
    setRoute(getRoute(match.path));
  }, [match]);

  const dispatch = useAppDispatch();
  const history = useHistory();
  const { data } = useGetLayout();

  const language: LanguagesEnum = useSelector((state: IApplicationState) =>
    selectLanguage(state),
  );

  const createUserMessagesSummary = (): IUserMessages => {
    const messagesList: IMessage[] = [];

    if (
      !isUnreadMessagesSuccess ||
      !isUnreadNewsSuccess ||
      !isUnreadContactUsSuccess
    ) {
      return {
        messagesList,
      };
    }

    const messages = unreadMessages.count + unreadNews.result;

    if (messages > 0) {
      messagesList.push({
        title:
          messages > 1
            ? t('layout:navigation.notifications-more-msgs')
            : t('layout:navigation.notifications-one-msg'),
        type: 'INFO_DOC',
        url: generateExternalRoute('wsc_account_messages', language),
        unreadCount: messages,
      });
    }

    if (unreadContactUs.result > 0) {
      messagesList.push({
        title: t('layout:navigation.notifications-contact-us'),
        type: 'INFO',
        url: generateExternalRoute('wsc_contact_us', language),
        unreadCount: unreadContactUs.result,
      });
    }

    return {
      messagesList,
    };
  };

  const isNotActiveBA = user?.roles?.includes(
    UserRoleEnum.ROLE_LOGIN_MODE_BILLING_ACCOUNT,
  );

  const showPasswordOnClick = (
    event: React.MouseEvent<HTMLElement>,
    crmId: string,
    crmType: string,
  ) => {
    dispatch(
      openModal({
        type: ModalType.ShowPassword,
        options: {
          crmId,
          crmType,
        },
      }),
    );

    event.stopPropagation();
  };

  const filteredItems = (data?.menuItems || []).filter(
    (item) =>
      !(
        item.id === MenuIdEnum.Usage &&
        !user.subscriptions.some((subscribtion) =>
          isUsageRelevant(subscribtion.type),
        )
      ),
  );

  if (config.isLoading) {
    return <LoaderFullPage show opacity={0.7} />;
  }

  return data?.showNavigation ? (
    <NavigationUser
      cartCount={cartCount?.count || 0}
      basketIconIsHidden={
        outage.data.isReadMode || isNotActiveBA || hasEmptyPARole
      }
      user={user}
      userMessages={createUserMessagesSummary()}
      outageInfo={{
        isReadMode: outage.data.isReadMode,
        isXbotEnabled: outage.data.isXbotEnabled,
        isFullOutage: outage.data.isFullOutage,
      }}
      showPasswordOnClick={
        user?.authorizationRoleName &&
        [
          UserAuthorizationRoleEnum.Admin,
          UserAuthorizationRoleEnum.BillingAdmin,
          UserAuthorizationRoleEnum.User,
        ].indexOf(user.authorizationRoleName) > -1
          ? showPasswordOnClick
          : undefined
      }
      items={filteredItems.map((item) => {
        const itemWithRedirect = item;

        itemWithRedirect.onClick = () => {
          redirect({
            internalRouteId: getRouteIdFromMenuItem(item),
            url: item.uri,
            language,
            history,
          });
        };

        itemWithRedirect.menuItems = item.menuItems.map((subItem) => {
          const subItemWithRedirect = subItem;

          subItemWithRedirect.onClick = () =>
            redirect({
              internalRouteId: getRouteIdFromMenuItem(item),
              url: subItem.uri,
              language,
              history,
            });

          return subItemWithRedirect;
        });

        return itemWithRedirect;
      })}
      route={route}
    />
  ) : (
    <></>
  );
};

export default withRouter(Navigation);
