import { LanguagesEnum } from '../../../../types/translation/languagesEnum';

export default (number: number, language: LanguagesEnum) => {
  const d = new Date();

  d.setMonth(number - 1);

  const formatter = new Intl.DateTimeFormat(
    language === LanguagesEnum.CS ? 'cs-CZ' : 'en-CZ',
    {
      month: 'long',
    },
  );

  return formatter.format(d);
};
