import { Button, Loader } from '@vodafone/red-white';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ModalMessageTypeEnum from 'src/components/Modal/components/ModalMessage/types/ModalMessageTypeEnum';
import { useAppDispatch } from 'src/hooks/useAppDispatch';
import { IApplicationState } from 'src/store/appStateInterface';
import SanitizedHtml from 'src/utils/sanitizeHtml';
import styled from 'styled-components';
import { API_ENDPOINT_CAS_RESET_PIN } from '../../../../api';
import { apiReload } from '../../../../store/application/applicationActions';
import { selectApiStatus } from '../../../../store/application/applicationSelector';
import { IApiState } from '../../../../store/application/types/applicationStateInterface';
import { casDeviceResetPin } from '../../../../store/cas/casActions';
import { selectCasResetPin } from '../../../../store/cas/casSelectors';
import {
  CasResultEnum,
  IRefreshSubscriptionState,
} from '../../../../store/cas/types/casStateInterface';
import { ModalContentProps } from '../../types/modalContentInterfaces';
import ModalMessageTemplate from '../ModalMessage/ModalMessageTemplate';

const ButtonWrapper = styled.div`
  text-align: center;
  margin-top: 30px;
`;

const Content = styled.div`
  max-width: 600px;
`;

const VtvCasPinResetModalContent: FC<ModalContentProps> = ({ options }) => {
  const { t } = useTranslation(['services', 'error', 'cas']);
  const dispatch = useAppDispatch();
  const instructions = [
    'cas:reset-pin.instruction1',
    'cas:reset-pin.instruction2',
    'cas:reset-pin.instruction3',
  ];
  // @ts-ignore
  const { loading, loaded, error }: IApiState = useSelector(
    selectApiStatus(API_ENDPOINT_CAS_RESET_PIN),
  );

  const data: IRefreshSubscriptionState = useSelector(
    (state: IApplicationState) => selectCasResetPin(state),
  );

  useEffect(() => {
    dispatch(apiReload(API_ENDPOINT_CAS_RESET_PIN));
  }, [dispatch]);

  const onSubmit = () => {
    dispatch(casDeviceResetPin(options.device.udid));
  };

  const Instruction = (
    <>
      {instructions.map((instruction, i) => {
        return (
          <SanitizedHtml
            key={i.toString()}
            style={{ marginBottom: 10 }}
            htmlTag="div"
            rawHtmlData={t(instruction)}
          />
        );
      })}
      <ButtonWrapper>
        <Button
          htmlElement="button"
          variant="primary"
          onClick={() => onSubmit()}
          disabled={loading}
        >
          {t('cas:reset-pin.button')}
        </Button>
      </ButtonWrapper>
    </>
  );

  return (
    <>
      <Content>
        <Loader show={loading} />

        {!error && !loaded && Instruction}
        {!loading && loaded && data.result === CasResultEnum.OK && (
          <ModalMessageTemplate
            type={ModalMessageTypeEnum.SUCCESS}
            description={t('cas:reset-pin.success-message-description')}
          />
        )}
        {((!loading && loaded && error) ||
          (!loading && loaded && data.result === CasResultEnum.ERROR)) && (
          <ModalMessageTemplate
            type={ModalMessageTypeEnum.ERROR}
            description={t('cas:reset-pin.error-message-description')}
            button={{
              label: t('cas:reset-pin.button'),
              onClick: () => onSubmit(),
            }}
          />
        )}
      </Content>
    </>
  );
};

export default VtvCasPinResetModalContent;
