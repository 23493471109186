import { IService } from 'src/store/services/types/service/serviceInterface';

export type ServiceManagementProps = {
  service: IService;
};

export enum ServiceCodeNameEnum {
  VTV_ACTIVE_DEVICES = 'vtv_devices',
  VTV_ACTIVE_SET_TOP_BOXES = 'vtv_stb',
  CAS_MODULE_PIN = 'cas_module_pin',
  FBB_ACTIVE_STB = 'fbb_stb',
  FBB_ACTIVE_MODEMS = 'fbb_modem',
  VTV_ADDON_01 = 'TV_ADDON_01',
  VTV_ADDON_02 = 'TV_ADDON_02',
  VTV_ADDON_03 = 'TV_ADDON_03',
  VTV_ADDON_04 = 'TV_ADDON_04',
  VTV_ADDON_05 = 'TV_ADDON_05',
  VTV_ADDON_06 = 'TV_ADDON_06',
  VTV_ADDON_07 = 'TV_ADDON_07',
  VTV_ADDON_08 = 'TV_ADDON_08',
  VTV_ADDON_09 = 'TV_ADDON_09',
  VTV_ADDON_10 = 'TV_ADDON_10',
  VTV_ADDON_11 = 'TV_ADDON_11',
  VTV_ADDON_12 = 'TV_ADDON_12',
  VTV_ADDON_13 = 'TV_ADDON_13',
  VTV_ADDON_14 = 'TV_ADDON_14',
  VTV_ADDON_15 = 'TV_ADDON_15',
  VTV_ADDON_34 = 'TV_ADDON_34',
  VTV_ADDON_35 = 'TV_ADDON_35',
  VTV_ADDON_36 = 'TV_ADDON_36',
  VTV_ADDON_37 = 'TV_ADDON_37',
  SPEEDCAPS_ONE_TIME = 'SPEEDCAPS_ONE_TIME',
  MNP = 'mnp',
  MNP_INTERNET = 'mnp_internet',
  SIM_ACTIVATION = 'sim_activation',
  HBO_GO = 'hbo_go',
  NETFLIX = 'netflix',
  PRIMAPLUS = 'prima_plus',
  VOYO = 'voyo',
  PIN_PUK = 'pin_puk',
  ROAMING_CALC = 'roaming_calculator',
  IPADDRESSES = 'ip_addresses',
  ACTIVE_ESIM = 'active_esim',
  ACTIVE_WITH_RELEASED_ESIM = 'active_with_released_esim',
  ESIM_ORDER = 'esim_order',
  ESIM_PORT = 'esim_port',
  STOLEN_LOST = 'lost_stolen',
  FBB_SUPER_WIFI = 'fbb_super_wifi',
}
