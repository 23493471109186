import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import api from 'src/api/api';
import analyticsMiddleware from 'src/utils/analytics/analyticsMiddleware';
import rootReducer from '../store';

export const store = configureStore({
  reducer: rootReducer,
  middleware: [api.middleware, thunk, analyticsMiddleware],
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
