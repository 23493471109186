import { IconTickNew, Row, Text } from '@vodafone/red-white';
import * as React from 'react';
import { ThemeContext } from 'styled-components';
import * as Styled from './CardBillingPaymentSuccess.styled';

export interface ICardBillingPaymentSuccessProps {
  title: React.ReactNode;
  subtitle: React.ReactNode;
}

const CardBillingPaymentSuccess: React.FC<ICardBillingPaymentSuccessProps> = ({
  title,
  subtitle,
}) => {
  const themeContext = React.useContext(ThemeContext);

  return (
    <Styled.Base>
      <Text block fontFamily="VodafoneLt" fontSize={26} color="darkGrey">
        <Row align="center" justify="center">
          <IconTickNew size={32} color={themeContext.colors.green} />
          <Styled.TitleTextSpacing>{title}</Styled.TitleTextSpacing>
        </Row>
      </Text>
      <Styled.SubtitleSpacing>
        <Text block fontSize={16} color="darkGrey">
          {subtitle}
        </Text>
      </Styled.SubtitleSpacing>
    </Styled.Base>
  );
};

export default CardBillingPaymentSuccess;
