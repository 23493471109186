import { Col, Text } from '@vodafone/red-white';
import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  align-items: center;
`;

export const HeadingText = styled(Text)`
  margin-left: 8px;
`;

export const BillingCol = styled(Col)`
  @media ${(props) => props.theme.breakpoints.lg} {
    margin-bottom: 32px;
    border-right: 1px solid ${(props) => props.theme.colors.lightGrey5};
  }
`;

export const SubtitleText = styled(Text)`
  font-weight: normal;
  margin-top: 8px;
  font-size: 24px;
  color: ${(props) => props.theme.colors.lightGrey6};
`;

export const BillingBase = styled.div`
  @media ${(props) => props.theme.breakpoints.lg} {
  }
`;

export const BillingWrapper = styled.div`
  display: flex;
  padding-top: 15px;
  padding-right: 0px;
  padding-bottom: 0px;
  flex-direction: column;

  @media ${(props) => props.theme.breakpoints.lg} {
    flex-direction: row;
    align-items: center;
    margin-right: 32px;
  }
`;

export const BillingFooter = styled.div`
  display: flex;
  align-items: center;
  margin-top: 25px;
  padding-bottom: 25px;
  justify-content: space-between;

  border-bottom: 1px solid ${(props) => props.theme.colors.lightGrey5};

  @media ${(props) => props.theme.breakpoints.lg} {
    padding-bottom: 0px;
    border-bottom: 0;
    justify-content: flex-start;
  }
`;

export const BillingWrapperDetail = styled.div`
  @media ${(props) => props.theme.breakpoints.lg} {
    margin-left: 32px;
  }
`;

export const ContentWrapper = styled.div`
  @media ${(props) => props.theme.breakpoints.lg} {
    padding-left: 32px;
  }
`;

export const BillingText = styled(Text)`
  white-space: pre;
  line-height: 1;
  margin-bottom: 15px;
`;

export const ModalWrapper = styled.div`
  padding: 30px 15px;
`;
