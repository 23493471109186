import { AnalyticsEnum } from '../enums/analyticsEnum';

export function setNextAction(service): string {
  switch (service.codeName) {
    case AnalyticsEnum.SERVICE_FLEXI_CEILING:
    case AnalyticsEnum.SERVICE_DATA:
    case AnalyticsEnum.SERVICE_FON:
      return AnalyticsEnum.SERVICE_CHANGE;
    default:
      return service.active
        ? AnalyticsEnum.SERVICE_DEACTIVATION
        : AnalyticsEnum.SERVICE_ACTIVATION;
  }
}

export const resolveServiceName = (service): string | null => {
  if (service.codeName !== null) {
    return service.codeName;
  }

  if (service.productOfferingId === AnalyticsEnum.SERVICE_BLOCKING) {
    return AnalyticsEnum.SERVICE_BLOCKING_OMNITURE;
  }

  if (service.productOfferingId === AnalyticsEnum.SERVICE_VOLTE) {
    return AnalyticsEnum.SERVICE_VOLTE_OMNITURE;
  }

  return null;
};
