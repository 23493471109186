import PayerInvoiceStatusEnum from 'src/store/invoices/types/PayerInvoiceStatusEnum';
import { CardBillingHistoryStatus } from 'src/ui/components/Billing/CardBillingHistory/CardBillingHistory';
import { InvoiceThresholdEnum } from './InvoiceEnums';

const InvoiceStatusEnumToStatusColorMap = (
  status: PayerInvoiceStatusEnum,
  invoiceBalanceDue: number,
): CardBillingHistoryStatus => {
  switch (status) {
    case PayerInvoiceStatusEnum.PAID:
    case PayerInvoiceStatusEnum.DO_NOT_PAY:
    case PayerInvoiceStatusEnum.CREDIT_NOTE:
      return 'success';
    case PayerInvoiceStatusEnum.PARTIALLY_PAID:
      return invoiceBalanceDue <= InvoiceThresholdEnum.NO_PAY_120
        ? 'success'
        : 'danger';
    case PayerInvoiceStatusEnum.UNPAID:
      return 'danger';
    case PayerInvoiceStatusEnum.IN_CREATION:
    case PayerInvoiceStatusEnum.OVERPAID:
      return 'default';
    default:
      return 'default';
  }
};

export default InvoiceStatusEnumToStatusColorMap;
