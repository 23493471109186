import { Button } from '@vodafone/red-white';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import CardReceivedPaymentsResult from 'src/ui/components/CardReceivedPaymentsResult';
import styled from 'styled-components';

const ErrorSubtitle = styled.span`
  display: inline-block;
  max-width: 471px;
`;

interface IProps {
  close: () => void;
}

const PaymentStatusUnavailable: React.FC<IProps> = ({ close }) => {
  const { t } = useTranslation('billing');

  return (
    <CardReceivedPaymentsResult
      status="error"
      title={t('billing:paymentStatus.title-unavailable')}
      subTitle={
        <ErrorSubtitle>
          {t('billing:paymentStatus.subtitle-unavailable')}
        </ErrorSubtitle>
      }
      extra={[
        <Button variant="primary" onClick={close}>
          {t('billing:paymentStatus.button-label-unavailable')}
        </Button>,
      ]}
    />
  );
};

export default PaymentStatusUnavailable;
