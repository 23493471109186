import {
  Button,
  Code,
  IconSolutionFinder,
  ModalMessage,
  Text,
  VariantEnum,
} from '@vodafone/red-white';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'src/hooks/useAppDispatch';
import { IApplicationState } from 'src/store/appStateInterface';
import { getMnpOku } from 'src/store/mnp/oku/mnpOkuSelectors';
import IMnpOkuState from 'src/store/mnp/oku/types/mnpOkuStateInterface';
import { openMnpServiceDetailCvopModal } from 'src/store/mnp/serviceDetail/mnpServiceDetailActions';
import { selectMnpService } from 'src/store/services/selectors/serviceSelectors';
import { selectIsPostpaid } from 'src/store/services/selectors/tariffOverviewSelectors';
import { IService } from 'src/store/services/types/service/serviceInterface';
import ISubscription from 'src/store/user/types/subscriptionInterface';
import { selectCurrentSubscription } from 'src/store/user/userSelector';
import { ModalContentProps } from '../types/modalContentInterfaces';

const MnpOkuModalContent: FC<ModalContentProps> = ({ onClose }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation('services');
  const { data }: IMnpOkuState = useSelector(getMnpOku);
  const service: IService = useSelector(selectMnpService);
  const { serviceNumber }: ISubscription = useSelector(
    selectCurrentSubscription,
  );
  const { oku } = data;
  const isPostpaid: boolean = useSelector((state: IApplicationState) =>
    selectIsPostpaid(state),
  );

  const onHelpButtonClick = () => {
    onClose();
    dispatch(openMnpServiceDetailCvopModal(service, serviceNumber));
  };

  const trackOku = () => {
    try {
      if (window && typeof (window as any).s !== 'undefined') {
        const purchaseID = oku;
        const product = 'mnp;1;0';

        (window as any).s.purchaseID = `oku${purchaseID}`;
        (window as any).s.products = `WSC;WA-${product}`;
        (window as any).s.events = 'purchase';
        if (typeof (window as any).s.t === 'function') {
          (window as any).s.t();
        }
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log("Calling function 'trackOku' not successful.");
    }
  };

  useEffect(() => {
    trackOku();
  });

  return (
    <>
      <ModalMessage
        heading={t('services:mnp.oku-modal-title')}
        icon={<IconSolutionFinder color="green" size={60} />}
        color="green"
      >
        {t('services:mnp.oku-modal-hint')}
      </ModalMessage>
      <Code
        label={t('services:mnp.oku')}
        value={oku}
        variant={VariantEnum.BIG}
      />
      {isPostpaid && (
        <>
          <p>
            <Text block fontSize={16} style={{ textAlign: 'center' }}>
              {t('services:mnp.oku-help-text')}
            </Text>
          </p>
          <p style={{ textAlign: 'center' }} data-s-object-id="button:mnp:oku">
            <Button
              htmlElement="button"
              variant="primary"
              onClick={onHelpButtonClick}
            >
              {t('services:mnp.oku-help-button')}
            </Button>
          </p>
        </>
      )}
    </>
  );
};

export default MnpOkuModalContent;
