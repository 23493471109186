import { useTranslation } from 'react-i18next';
import ModalMessageTemplate from 'src/components/Modal/components/ModalMessage/ModalMessageTemplate';
import ModalMessageTypeEnum from 'src/components/Modal/components/ModalMessage/types/ModalMessageTypeEnum';
import SanitizedHtml from 'src/utils/sanitizeHtml';

export const MfaGenericErrorContent = ({
  onClose,
  messageType,
  isRateLimited = false,
}) => {
  const { t } = useTranslation(['component', 'form']);
  const errorType = isRateLimited ? 'rateLimitError' : 'error';

  return (
    <ModalMessageTemplate
      type={ModalMessageTypeEnum.ERROR}
      heading=" "
      fullSize
      description={
        <SanitizedHtml
          htmlTag="span"
          rawHtmlData={t(`form:mfa.${messageType}.${errorType}`)}
        />
      }
      button={{
        label: t('component:button.close'),
        onClick: () => {
          onClose();
        },
      }}
    />
  );
};
