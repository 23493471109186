import { combineReducers } from 'redux';
import cvop from './cvop';
import oku from './oku';
import penalty from './penalty';
import serviceDetail from './serviceDetail';

export default combineReducers({
  oku,
  cvop,
  penalty,
  serviceDetail,
});
