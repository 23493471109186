import { IconSolutionFinder, ModalMessage } from '@vodafone/red-white';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalContentProps } from '../types/modalContentInterfaces';

const MnpCvopModalContent: FC<ModalContentProps> = () => {
  const { t } = useTranslation('services');

  const trackPEGA = () => {
    try {
      if (window && typeof (window as any).s !== 'undefined') {
        const timestamp = Date.now();
        const product = 'mnp;1;0';

        (window as any).s.purchaseID = `pega${timestamp}`;
        (window as any).s.products = `WSC;WA-${product}`;
        (window as any).s.events = 'purchase';
        if (typeof (window as any).s.t === 'function') {
          (window as any).s.t();
        }
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log("Calling function 'trackPega' not successful.");
    }
  };

  useEffect(() => {
    trackPEGA();
  });
  return (
    <ModalMessage
      heading={t('services:mnp.cvop-modal-title')}
      icon={<IconSolutionFinder color="green" size={60} />}
      color="green"
    >
      {t('services:mnp.cvop-modal-text')}
    </ModalMessage>
  );
};

export default MnpCvopModalContent;
