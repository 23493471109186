import { AxiosResponse } from 'axios';
import { IRestResponseFault } from 'src/api/types/IRestResponseFault';
import { IRestResponseSuccess } from 'src/api/types/IRestResponseSuccess';
import { IService } from 'src/store/services/types/service/serviceInterface';
import api from 'src/utils/api/api';
import { translateApiEndpoint } from 'src/utils/router/translateApiEndpoint';
import {
  API_ENDPOINT_ADD_MTE_TO_CART,
  API_ENDPOINT_SERVICES,
  API_ENDPOINT_SERVICES_ALL,
  API_ENDPOINT_SERVICES_CHANGE,
  API_ENDPOINT_SERVICES_DETAIL_MNP,
  API_ENDPOINT_SERVICES_DETAIL_PIN_PUK,
  API_ENDPOINT_SERVICES_DETAIL_ROAMING_CALCULATOR,
  API_ENDPOINT_SERVICES_DETAIL_ROAMING_CALCULATOR_CALCULATE,
  API_ENDPOINT_SERVICES_MORE,
  API_ENDPOINT_SIM_ACTIVATION,
  API_ENDPOINT_VIRTUAL_BUNDLE,
} from '..';
import { IChangeServiceRequest } from './request/changeServiceRequestInterface';
import { ICalculateRoamingCalculatorResponse } from './response/calculateRoamingCalculatorResponseInterface';
import IChangeServiceResponse from './response/changeServiceResponse';
import { IGetServicesResponse } from './response/getServicesResponseInterface';
import IGetMnpServiceDetailResponse from './response/mnpServiceDetailResponseInterface';
import IGetPinPukServiceDetailResponse from './response/pinPukServiceDetailResponseInterface';
import { IRoamingCalculatorDataResponse } from './response/roamingCalculatorDataResponseInterface';
import resolveServiceDetailEndpoint from './utils/resolveServiceDetailEndpoint';

export const addMteChange = (
  productId: string,
  packageId?: string,
  campaignId?: string,
) =>
  api.post<AxiosResponse>({
    url: API_ENDPOINT_ADD_MTE_TO_CART,
    data: { id: productId, packageId, campaignId },
  });
export const getVirtualBundle = () =>
  api.get({ url: API_ENDPOINT_VIRTUAL_BUNDLE });
export const getServicesOverview = (signal?: AbortSignal) =>
  api.get<IGetServicesResponse>({ url: API_ENDPOINT_SERVICES, signal });
export const getEligibleServicesList = (signal?: AbortSignal) =>
  api.get<IGetServicesResponse>({ url: API_ENDPOINT_SERVICES_MORE, signal });
export const getServiceManagementContent = (service: IService) =>
  api.get(resolveServiceDetailEndpoint(service));
export const getPreloadAllServices = (signal?: AbortSignal) =>
  api.get({ url: translateApiEndpoint(API_ENDPOINT_SERVICES_ALL), signal });
export const getMnpServiceDetail = (): Promise<
  AxiosResponse<IGetMnpServiceDetailResponse>
> => api.get({ url: API_ENDPOINT_SERVICES_DETAIL_MNP });
export const getPinPukServiceDetail = (): Promise<
  AxiosResponse<IGetPinPukServiceDetailResponse>
> => api.get({ url: API_ENDPOINT_SERVICES_DETAIL_PIN_PUK });

export const changeService = (
  request: IChangeServiceRequest,
): Promise<AxiosResponse<IChangeServiceResponse>> =>
  api.post({
    url: API_ENDPOINT_SERVICES_CHANGE,
    data: request,
  });

export const getRoamingCalculatorData = (): Promise<
  AxiosResponse<IRoamingCalculatorDataResponse>
> =>
  api.get({
    url: translateApiEndpoint(API_ENDPOINT_SERVICES_DETAIL_ROAMING_CALCULATOR),
  });
export const calculateRoamingCalculator = (
  country: string,
  subscriber: string,
): Promise<AxiosResponse<ICalculateRoamingCalculatorResponse>> =>
  api.post({
    url: API_ENDPOINT_SERVICES_DETAIL_ROAMING_CALCULATOR_CALCULATE,
    data: { subscriber, country },
  });

export const getSimActivation = (
  iccid: string,
): Promise<AxiosResponse<IRestResponseSuccess | IRestResponseFault>> =>
  api.post({
    url: API_ENDPOINT_SIM_ACTIVATION,
    data: { iccid },
  });
