import { Card, IconEsim2, Title } from '@vodafone/red-white';
import { FC, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ActivateESimByEIdContent from 'src/components/Modal/components/ESim/ActivateESim/ActivateESimByEIdContent';
import ActivateESimByQRCodeContent from 'src/components/Modal/components/ESim/ActivateESim/ActivateESimByQRCodeContent';
import { ModalContentProps } from 'src/components/Modal/types/modalContentInterfaces';
import { useAppDispatch } from 'src/hooks/useAppDispatch';
import {
  eSimDetailResetStore,
  eSimFetchDetail,
} from 'src/store/esim/esimActions';
import { selectESimDetail } from 'src/store/esim/esimSelectors';
import { IESimDetailState } from 'src/store/esim/types/esimStateInterface';
import { formatServiceNumber } from 'src/utils/serviceNumberFormatter';
import styled, { ThemeContext } from 'styled-components';

const CustomCardPadding = styled.div`
  padding: 40px 0 0 0;
`;

const ActivateESimContent: FC<ModalContentProps> = ({
  onClose,
  setCompact,
  setCompactContent,
  options,
}) => {
  const themeContext = useContext(ThemeContext);
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['esim']);

  useEffect(() => {
    dispatch(eSimDetailResetStore());
    dispatch(eSimFetchDetail(options.serviceNumber));
    setCompact(true);
    setCompactContent(true);
  }, [setCompactContent, setCompact, dispatch, options.serviceNumber]);

  const eSimDetail: IESimDetailState = useSelector(selectESimDetail);

  return (
    <Card
      style={{ maxWidth: 700, width: '100%', boxShadow: 'none' }}
      loading={eSimDetail.loading}
      title={
        <>
          <IconEsim2 size={20} color={themeContext.colors.darkGrey} />
          <Title
            color="darkGrey"
            fontFamily="VodafoneRg"
            fontWeight="bold"
            level={3}
            style={{ marginLeft: 8 }}
          >
            {t('esim:activateESim.title', {
              serviceNumber: formatServiceNumber(options.serviceNumber),
            })}
          </Title>
        </>
      }
    >
      <CustomCardPadding>
        {eSimDetail.loaded && !eSimDetail.data.eId && (
          <>
            <ActivateESimByQRCodeContent
              serviceNumber={options.serviceNumber}
              onClose={onClose}
            />
          </>
        )}

        {eSimDetail.loaded && eSimDetail.data.eId && (
          <>
            <ActivateESimByEIdContent
              serviceNumber={options.serviceNumber}
              onClose={onClose}
            />
          </>
        )}
      </CustomCardPadding>
    </Card>
  );
};

export default ActivateESimContent;
