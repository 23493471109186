import { Reducer } from 'redux';
import initialState from '../../initialState';
import {
  IFetchUpcInvoicesBeginAction,
  IFetchUpcInvoicesFailureAction,
  IFetchUpcInvoicesSuccessAction,
} from './types/upcInvoiceActionInterfaces';
import UpcInvoiceActionTypes from './types/upcInvoiceActionTypes';
import IUpcInvoceState from './types/upcInvoiceStateInterface';

type IUpcInvoiceActions =
  | IFetchUpcInvoicesBeginAction
  | IFetchUpcInvoicesSuccessAction
  | IFetchUpcInvoicesFailureAction;

const upcInvoiceReducer: Reducer<IUpcInvoceState, IUpcInvoiceActions> = (
  state = initialState.Billing.UpcInvoice,
  action,
) => {
  switch (action.type) {
    case UpcInvoiceActionTypes.FETCH_UPC_INVOICE_LIST_BEGIN:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      };
    case UpcInvoiceActionTypes.FETCH_UPC_INVOICE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        data: action.payload,
      };
    case UpcInvoiceActionTypes.FETCH_UPC_INVOICE_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    default:
      return state;
  }
};

export default upcInvoiceReducer;
