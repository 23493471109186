export enum NoticeTypesEnum {
  success = 'success',
  error = 'error',
  neutral = 'neutral',
}

export enum NoticeStyleModifiersEnum {
  success = '-positive',
  error = '-negative',
  neutral = '',
}

export enum NoticeActionTypesEnum {
  SHOW_NOTICE = 'SHOW_NOTICE',
  HIDE_NOTICE = 'HIDE_NOTICE',
}
