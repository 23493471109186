import { Reducer } from 'redux';
import initialState from '../../initialState';
import {
  ICreatePaymentTransactionBeginAction,
  ICreatePaymentTransactionFailureAction,
  ICreatePaymentTransactionSuccessAction,
} from './types/paymentTransactionActionInterfaces';
import PaymentTransactionActionTypes from './types/paymentTransactionActionTypes';
import IPaymentInfoState from './types/paymentTransactionStateInterface';

type IPaymentTransactionActions =
  | ICreatePaymentTransactionBeginAction
  | ICreatePaymentTransactionSuccessAction
  | ICreatePaymentTransactionFailureAction;

const paymentTransactionReducer: Reducer<
  IPaymentInfoState,
  IPaymentTransactionActions
> = (state = initialState.Billing.PaymentTransaction, action) => {
  switch (action.type) {
    case PaymentTransactionActionTypes.CREATE_PAYMENT_TRANSACTION_BEGIN:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      };
    case PaymentTransactionActionTypes.CREATE_PAYMENT_TRANSACTION_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        data: action.payload,
      };
    case PaymentTransactionActionTypes.CREATE_PAYMENT_TRANSACTION_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    default:
      return state;
  }
};

export default paymentTransactionReducer;
