enum ServiceHasOfferStatusEnum {
  INITIAL_STATE = 'INITIAL_STATE',
  LOADING = 'LOADING',
  HAS_OFFER = 'HAS_OFFER',
  HAS_NO_OFFER = 'HAS_NO_OFFER',
  ERROR_OCCURRED = 'ERROR_OCCURRED',
  SERVER_ERROR_OCCURRED = 'SERVER_ERROR_OCCURRED',
}

export default ServiceHasOfferStatusEnum;
