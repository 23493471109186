import axios, { AxiosResponse } from 'axios';

interface Props {
  url: string;
  data?: any;
  signal?: AbortSignal;
}

const apiPut = <IResponse = any>({
  url,
  data = {},
  signal,
}: Props): Promise<AxiosResponse<IResponse>> =>
  new Promise((resolve, reject) => {
    axios
      .put(url, data, {
        signal,
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
        },
        withCredentials: true,
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });

export default apiPut;
