import { IService } from '../types/service/serviceInterface';

type serviceUpdater = (originalItem: IService) => IService;

export const updateServiceItem = (
  items: IService[],
  serviceId: string,
  updater: serviceUpdater,
): IService[] =>
  items.map((item) => (item.id === serviceId ? updater(item) : item));

export const updateServiceByCodename = (
  items: IService[],
  codename: string,
  updater: serviceUpdater,
): IService[] =>
  items.map((item) => (item.codeName === codename ? updater(item) : item));
