import { createSelector, Selector } from 'reselect';
import InvoiceStatusEnumToStatusColorMap from 'src/store/invoices/types/InvoiceStatusEnumToStatusColorMap';
import { toMonthName, toNumericDateString } from 'src/utils/date';
import { CardBillingHistoryStatus } from '../../ui/components/Billing/CardBillingHistory/CardBillingHistory';
import { IApplicationState } from '../appStateInterface';
import {
  getCurrentSpending,
  selectCurrentSpending,
} from '../currentSpending/currentSpendingSelectors';
import selectLanguage from '../language/languageSelector';
import { getUsageConsumption } from '../usageConsumption/usageConsumptionSelectors';
import { selectBillingAccount } from '../user/userSelector';
import InvoiceKeyEnum, { InvoiceThresholdEnum } from './types/InvoiceEnums';
import {
  IBillingHistoryDataItem,
  IBillingHistoryTableItem,
  IInvoiceSummaryBaItem,
  IPayerInvoice,
} from './types/invoicesInterface';
import { IInvoicesState } from './types/invoicesStateInterface';
import PayerInvoiceStatusEnum from './types/PayerInvoiceStatusEnum';

export const getInvoices: Selector<IApplicationState, IInvoicesState> = (
  state: IApplicationState,
) => state.Invoices;

export const selectPayerInvoicesIsLoaded: Selector<IApplicationState, boolean> =
  createSelector([getInvoices], (invoices) => invoices.invoices.loaded);

export const selectPayerInvoicesIsLoading: Selector<
  IApplicationState,
  boolean
> = createSelector([getInvoices], (invoices) => invoices.invoices.loading);

export const selectInvoicesForBaNumber = (
  state: IApplicationState,
  baNumber?: string,
): IPayerInvoice[] =>
  createSelector([getInvoices], (invoices) =>
    invoices.invoices.data && invoices.invoices.data[baNumber]
      ? invoices.invoices.data[baNumber]
      : [],
  )(state);

export const selectHasInvoicesForBaNumber = (
  state: IApplicationState,
  baNumber?: string,
): boolean =>
  createSelector([getInvoices], (invoices) =>
    invoices.invoices.data && invoices.invoices.data[baNumber]
      ? invoices.invoices.data[baNumber].length > 0
      : false,
  )(state);

export const selectPayerInvoicesForChart = (
  state: IApplicationState,
  baNumber: string,
): IBillingHistoryDataItem[] =>
  createSelector([getInvoices, selectLanguage], (_invoices, language) => {
    const invoicesForBaNumber = selectInvoicesForBaNumber(state, baNumber);
    const filteredInvoices = invoicesForBaNumber.filter(
      (invoice) => invoice.status !== PayerInvoiceStatusEnum.CREDIT_NOTE,
    );

    const max = filteredInvoices.length
      ? Math.max(...filteredInvoices.slice(0, 5).map((i) => i.totalAmount))
      : 0;

    return filteredInvoices
      .map((invoice) => {
        const inCreation =
          invoice.status === PayerInvoiceStatusEnum.IN_CREATION;

        return {
          key: invoice.invoiceId.toString(),
          title: toMonthName(invoice.billStartDate, language),
          subtitle: invoice.totalAmount.toString(),
          paid: inCreation
            ? null
            : invoice.status === PayerInvoiceStatusEnum.PAID ||
              invoice.status === PayerInvoiceStatusEnum.DO_NOT_PAY ||
              (invoice.status === PayerInvoiceStatusEnum.PARTIALLY_PAID &&
                invoice.invoiceBalanceDue <= InvoiceThresholdEnum.NO_PAY_120),
          current: inCreation,
          percent: (invoice.totalAmount / max) * 100,
          inCreation,
        };
      })
      .slice(0, 5)
      .reverse();
  })(state);

export const selectPayerInvoicesForTable = (
  state: IApplicationState,
  serviceNumber: string,
  baNumber: string,
): IBillingHistoryTableItem[] =>
  createSelector(
    [
      getInvoices,
      getCurrentSpending,
      getUsageConsumption,
      selectBillingAccount,
      selectLanguage,
    ],
    (invoices, currentSpending, usageConsumption, billingAccount, language) => {
      // const invoicesForBaNumber = selectInvoicesForBaNumber(state, baNumber);
      const currentSpendingForServiceNumber = selectCurrentSpending(
        state,
        serviceNumber,
      );
      const data = (
        invoices.invoices.data && invoices.invoices.data[baNumber]
          ? invoices.invoices.data[baNumber]
          : []
      ).map((invoice: IPayerInvoice) => {
        return {
          key: invoice.invoiceId.toString(),
          title: toMonthName(invoice.billStartDate, language, true),
          period: `${toNumericDateString(
            invoice.billStartDate,
          )} - ${toNumericDateString(invoice.billEndDate)}`,
          amount: invoice.totalAmount,
          invoiceState: invoice.status,
          status: InvoiceStatusEnumToStatusColorMap(
            invoice.status,
            invoice.invoiceBalanceDue,
          ),
          zipIsAvailable: invoice.zipDocumentCreated,
          pdfIsAvailable: invoice.pdfDocumentCreated,
          isDetailAvailable: invoice.isDetailAvailable,
          isCurrent: false,
          dueDate: invoice.dueDate,
        };
      });

      const emptyUsageConsumptionData = {
        key: InvoiceKeyEnum.CURRENT_SPENDING,
        title: '',
        period: '',
        amount: 0,
        invoiceState: PayerInvoiceStatusEnum.UNPAID,
        status: 'default' as CardBillingHistoryStatus,
        zipIsAvailable: false,
        pdfIsAvailable: false,
        isDetailAvailable: false,
        isCurrent: true,
        dueDate: '',
      };

      if (
        usageConsumption.data[
          billingAccount ? billingAccount.billingAccountNumber : null
        ]
      ) {
        if (
          usageConsumption.data[
            billingAccount ? billingAccount.billingAccountNumber : null
          ].loaded &&
          currentSpending.loaded
        ) {
          data.unshift({
            key: InvoiceKeyEnum.CURRENT_SPENDING,
            title: toMonthName(
              currentSpendingForServiceNumber?.periodDateStart,
              language,
              true,
            ),
            period: `${toNumericDateString(
              usageConsumption.data[
                billingAccount ? billingAccount.billingAccountNumber : null
              ].summary.periodDateStart,
            )} - ${toNumericDateString(
              usageConsumption.data[
                billingAccount ? billingAccount.billingAccountNumber : null
              ].summary.periodDateEnd,
            )}`,
            amount:
              usageConsumption.data[
                billingAccount ? billingAccount.billingAccountNumber : null
              ].summary.totalAmount,
            invoiceState: PayerInvoiceStatusEnum.UNPAID,
            status: 'default',
            zipIsAvailable: false,
            pdfIsAvailable: false,
            isDetailAvailable: false,
            isCurrent: true,
            dueDate: '',
          });
        } else {
          data.unshift(emptyUsageConsumptionData);
        }
      }

      return data;
    },
  )(state);

export const selectInvoicesSummaryForBaNumber: Selector<
  IApplicationState,
  IInvoiceSummaryBaItem
> = createSelector(
  [getInvoices],
  (invoices) => invoices.summary.data[invoices.summary.selectedBaNumber],
);

export const selectSelectedInvoicesSummaryBaNumber: Selector<
  IApplicationState,
  string
> = createSelector(
  [getInvoices],
  (invoices) => invoices.summary.selectedBaNumber,
);

export const selectInvoiceBreakdown = (
  state: IApplicationState,
  invoice: IBillingHistoryTableItem,
) =>
  createSelector([getInvoices], (invoices) => {
    if (!invoice) return null;
    return invoices.breakdown.data[invoice.key];
  })(state);
