export default (number: number, unit?: string) => {
  const formatter = new Intl.NumberFormat('cs-CZ', {
    style: 'decimal',
    minimumFractionDigits: number % 1 === 0 ? 0 : 1,
    maximumFractionDigits: number % 1 === 0 ? 0 : 2,
  });

  if (unit) {
    return `${formatter.format(number)} ${unit}`;
  } else {
    return `${formatter.format(number)}`;
  }
};
