import { AxiosResponse } from 'axios';
import { API_ENDPOINT_CAS_REFRESH_SUBSCRIPTION } from '../..';
import api from '../../../utils/api/api';
import { IRefreshSubscriptionFailureResponse } from '../response/refreshSubscriptionFailureResponse';
import { IRefreshSubscriptionSuccessResponse } from '../response/refreshSubscriptionSuccessResponse';

const refreshSubscriptionRequest = (): Promise<
  AxiosResponse<
    IRefreshSubscriptionSuccessResponse | IRefreshSubscriptionFailureResponse
  >
> =>
  api.post({
    url: API_ENDPOINT_CAS_REFRESH_SUBSCRIPTION,
  });

export default refreshSubscriptionRequest;
