import {
  Button,
  IconComplaints,
  IconExtender,
  IconLandlineOrCall,
  IconMinutesSMS,
  Message,
} from '@vodafone/red-white';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'src/hooks/useAppDispatch';
import selectLanguage from 'src/store/language/languageSelector';
import {
  fetchRoamingCalculatorData,
  roamingCalculatorCalculate,
} from 'src/store/services/actions/roamingCalculatorActions';
import {
  selectCalculatorData,
  selectCountries,
  selectIsError,
  selectIsLoaded,
  selectIsLoading,
  selectSubscribers,
} from 'src/store/services/selectors/roamingCalculatorSelectors';
import ISubscription from 'src/store/user/types/subscriptionInterface';
import { selectCurrentSubscription } from 'src/store/user/userSelector';
import { ModalTitle } from 'src/ui/components/ModalTitle';
import { RoamingCalculatorTable } from 'src/ui/components/RoamingCalculator/components/RoamingCalculatorTable';
import RoamingCalculatorContent from 'src/ui/components/RoamingCalculator/components/RoamingCalculatorTable/RoamingCalculatorContent';
import {
  HorizontalLine,
  StyledLink,
  TextWrapper,
} from 'src/ui/components/RoamingCalculator/components/RoamingCalculatorTable/RoamingData';
import SanitizedHtml from 'src/utils/sanitizeHtml';
import styled from 'styled-components';
import { LanguagesEnum } from '../../../types/translation/languagesEnum';
import { ITranslation } from '../../../types/translationInterface';
import { redirect } from '../../../utils/redirect';
import { removePrefix } from '../../../utils/serviceNumberFormatter';
import getTranslation from '../../../utils/translation/getTranslation';
import { ModalContentProps } from '../types/modalContentInterfaces';

enum TableTheadEnum {
  VOICE_KEY = 1,
  SMS_KEY = 2,
  DATA_KEY = 3,
  MMS_KEY = 4,
}

const ContentWrapper = styled.div`
  padding-top: 10px;
`;

const MessageWrapper = styled.div`
  display: flex;
  padding: 20px 0 0 0;
  justify-content: center;
`;

interface ICalcDataProps {
  voice: ITranslation[];
  sms: ITranslation[];
  data: ITranslation[];
  mms: ITranslation[];
  specialInfo: ITranslation[];
  generalInfo: ITranslation[];
  priceList: {
    url: ITranslation;
    label: ITranslation;
  };
  partners: {
    url: ITranslation;
    label: ITranslation;
  };
  warning?: ITranslation;
}

interface IRoamingDataProps {
  calculatorData: ICalcDataProps;
  isOnlyVoLteDeviceSupported?: boolean;
  children: React.ReactNode;
}

const RoamingData: React.FC<IRoamingDataProps> = ({
  calculatorData,
  isOnlyVoLteDeviceSupported,
  children,
}) => {
  const language: LanguagesEnum = useSelector(selectLanguage);
  const { t } = useTranslation('services');

  return (
    <>
      <TextWrapper fontSize="14px">
        {calculatorData.generalInfo.map((generalInfo, i) => (
          <SanitizedHtml
            key={i.toString()}
            htmlTag="p"
            rawHtmlData={getTranslation(generalInfo, language)}
          />
        ))}
      </TextWrapper>

      {calculatorData.warning && (
        <TextWrapper fontSize="14px">
          <strong>
            <SanitizedHtml
              htmlTag="p"
              rawHtmlData={getTranslation(calculatorData.warning, language)}
            />
          </strong>
        </TextWrapper>
      )}

      {isOnlyVoLteDeviceSupported && (
        <MessageWrapper>
          <Message
            type="info"
            message={
              <SanitizedHtml
                htmlTag="p"
                rawHtmlData={t(
                  'services:roamingCalculator.only-volte-device-supported',
                )}
              />
            }
          />
        </MessageWrapper>
      )}

      {children}

      <TextWrapper fontSize="14px">
        {calculatorData.specialInfo.map((specialInfo, i) => (
          <SanitizedHtml
            key={i.toString()}
            htmlTag="p"
            rawHtmlData={getTranslation(specialInfo, language)}
          />
        ))}
      </TextWrapper>

      <TextWrapper fontSize="14px">
        <StyledLink
          onClick={() =>
            redirect({
              url: getTranslation(calculatorData.partners.url, language),
            })
          } // redirect from SPA to unknown location
        >
          {getTranslation(calculatorData.partners.label, language)}
          <br />
        </StyledLink>
        <StyledLink
          onClick={() =>
            redirect({
              url: getTranslation(calculatorData.priceList.url, language),
            })
          } // redirect from SPA to unknown location
        >
          {getTranslation(calculatorData.priceList.label, language)}
        </StyledLink>
      </TextWrapper>
    </>
  );
};

const RoamingInfoText: React.FC<{ infoText: string }> = ({ infoText }) => {
  return (
    <TextWrapper fontSize="14px">
      <p>{infoText}</p>
    </TextWrapper>
  );
};

const RoamingCalcModalContent: FC<ModalContentProps> = ({ setTitle }) => {
  const dispatch = useAppDispatch();

  const loading = useSelector(selectIsLoading);
  const loaded = useSelector(selectIsLoaded);
  const error = useSelector(selectIsError);
  const subscribers = useSelector(selectSubscribers);
  const countries = useSelector(selectCountries);
  const initSubscriber: ISubscription = useSelector(selectCurrentSubscription);
  const calculatorData = useSelector(selectCalculatorData);

  const language: LanguagesEnum = useSelector(selectLanguage);
  const { t } = useTranslation(['services', 'error']);
  const [clicked, setClicked] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedSubscriber, setSelectedSubscriber] = useState(null);

  useEffect(() => {
    dispatch(
      fetchRoamingCalculatorData(
        initSubscriber ? removePrefix(initSubscriber.serviceNumber) : null,
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCountryChange = (event) => {
    setSelectedCountry(event.value);
    setClicked(false);
  };

  const onSubscriberChange = (event) => {
    setSelectedSubscriber(event.value);
    setClicked(false);
  };

  const onCalculateClick = () => {
    dispatch(roamingCalculatorCalculate(selectedCountry, selectedSubscriber));
    setClicked(true);
  };

  const title = t('services:roamingCalculator.title');

  useEffect(() => {
    setTitle(<ModalTitle title={title} icon={<IconMinutesSMS size={16} />} />);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title]);

  const getCalculatorTable = () => {
    return {
      thead: [
        {
          icon: IconLandlineOrCall,
          text: t('services:roamingCalculator.table-head-voice'),
          key: TableTheadEnum.VOICE_KEY,
        },
        {
          icon: IconComplaints,
          text: t('services:roamingCalculator.table-head-sms'),
          key: TableTheadEnum.SMS_KEY,
        },
        {
          icon: IconExtender,
          text: t('services:roamingCalculator.table-head-data'),
          key: TableTheadEnum.DATA_KEY,
        },
        {
          icon: IconComplaints,
          text: t('services:roamingCalculator.table-head-mms'),
          key: TableTheadEnum.MMS_KEY,
        },
      ],
      tbody: [
        {
          columnItems: calculatorData.voice.map((voice, i) => {
            return {
              text: getTranslation(voice, language),
              key: i,
            };
          }),
          key: TableTheadEnum.VOICE_KEY,
        },
        {
          columnItems: calculatorData.sms.map((sms, i) => {
            return {
              text: getTranslation(sms, language),
              key: i,
            };
          }),
          key: TableTheadEnum.SMS_KEY,
        },
        {
          columnItems: calculatorData.data.map((data, i) => {
            return {
              text: getTranslation(data, language),
              key: i,
            };
          }),
          key: TableTheadEnum.DATA_KEY,
        },
        {
          columnItems: calculatorData.mms.map((mms, i) => {
            return {
              text: getTranslation(mms, language),
              key: i,
            };
          }),
          key: TableTheadEnum.MMS_KEY,
        },
      ],
    };
  };

  const description = t('services:roamingCalculator.description');
  const phone = {
    id: 'phone-number-select',
    label: t('services:roamingCalculator.input-msisdn-label'),
    options: subscribers,
    onChange: onSubscriberChange,
    value: selectedSubscriber,
  };
  const country = {
    id: 'country-select',
    label: t('services:roamingCalculator.input-country-label'),
    options: countries,
    onChange: onCountryChange,
    value: selectedCountry,
  };
  const button = (
    <Button variant="primary" onClick={onCalculateClick}>
      <span>{t('services:roamingCalculator.button-action')}</span>
    </Button>
  );

  const isOnlyVoLteDeviceSupported = ['USA', 'Svycar'].includes(
    selectedCountry,
  );

  return (
    <ContentWrapper>
      <RoamingCalculatorContent
        loading={loading}
        description={description}
        phone={phone}
        country={country}
        button={button}
      />
      <HorizontalLine />
      {error && loaded && clicked && (
        <RoamingInfoText infoText={t('error:api-loading')} />
      )}
      {!error &&
        loaded &&
        clicked &&
        (calculatorData && calculatorData.voice.length !== 0 ? (
          <RoamingData
            calculatorData={calculatorData}
            isOnlyVoLteDeviceSupported={isOnlyVoLteDeviceSupported}
          >
            <RoamingCalculatorTable {...getCalculatorTable()} />
          </RoamingData>
        ) : (
          <RoamingInfoText
            infoText={t('services:roamingCalculator.unlisted-countries')}
          />
        ))}
    </ContentWrapper>
  );
};

export default RoamingCalcModalContent;
