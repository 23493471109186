import { AxiosResponse } from 'axios';
import { ActionCreator } from 'redux';
import { CasResultEnum } from 'src/store/cas/types/casStateInterface';
import { API_ENDPOINT_CAS_RESET_PIN } from '../../api';
import casResetPinRequest from '../../api/cas/request/casResetPinRequest';
import refreshSubscriptionRequest from '../../api/cas/request/refreshSubscriptionRequest';
import ICasResetPinFailureResponse from '../../api/cas/response/casResetPinFailureResponse';
import ICasResetPinSuccessResponse from '../../api/cas/response/casResetPinSuccessResponse';
import { IRefreshSubscriptionFailureResponse } from '../../api/cas/response/refreshSubscriptionFailureResponse';
import { IRefreshSubscriptionSuccessResponse } from '../../api/cas/response/refreshSubscriptionSuccessResponse';
import {
  apiBegin,
  apiFailure,
  apiSuccess,
} from '../application/applicationActions';
import {
  ICasDeviceResetPinBeginAction,
  ICasDeviceResetPinFailureAction,
  ICasDeviceResetPinStateAction,
  ICasDeviceResetPinSuccessAction,
} from './types/casActionInterface';
import {
  IRefreshCasSubscriptionBeginAction,
  IRefreshCasSubscriptionFailureAction,
  IRefreshCasSubscriptionResetStateAction,
  IRefreshCasSubscriptionSuccessAction,
} from './types/casActionInterfaces';
import CasActionTypes from './types/casActionTypes';

export const casDeviceResetPinState: ActionCreator<
  ICasDeviceResetPinStateAction
> = () => ({
  type: CasActionTypes.CAS_DEVICE_PIN_RESET_STATE,
});

export const casDeviceResetPinBegin: ActionCreator<
  ICasDeviceResetPinBeginAction
> = () => ({
  type: CasActionTypes.CAS_DEVICE_PIN_RESET_BEGIN,
});

export const casDeviceResetPinSuccess: ActionCreator<
  ICasDeviceResetPinSuccessAction
> = (payload: ICasResetPinSuccessResponse) => ({
  type: CasActionTypes.CAS_DEVICE_PIN_RESET_SUCCESS,
  payload,
});

export const casDeviceResetPinFailure: ActionCreator<
  ICasDeviceResetPinFailureAction
> = (payload: ICasResetPinFailureResponse) => ({
  type: CasActionTypes.CAS_DEVICE_PIN_RESET_FAILURE,
  payload,
});

export const casDeviceResetPin = (deviceId: string) => (dispatch) => {
  dispatch(apiBegin(API_ENDPOINT_CAS_RESET_PIN));
  dispatch(casDeviceResetPinBegin());

  return casResetPinRequest(deviceId)
    .then(
      (
        response: AxiosResponse<
          ICasResetPinSuccessResponse | ICasResetPinFailureResponse
        >,
      ) => {
        dispatch(casDeviceResetPinSuccess(response.data));
        dispatch(apiSuccess(API_ENDPOINT_CAS_RESET_PIN));
      },
    )
    .catch((response: AxiosResponse<ICasResetPinFailureResponse>) => {
      dispatch(casDeviceResetPinFailure(response));
      dispatch(apiFailure(API_ENDPOINT_CAS_RESET_PIN));
    });
};

export const refreshCasSubscriptionResetState: ActionCreator<
  IRefreshCasSubscriptionResetStateAction
> = () => ({
  type: CasActionTypes.REFRESH_CAS_SUBSCRIPTION_RESET_STATE,
});

export const refreshCasSubscriptionBegin: ActionCreator<
  IRefreshCasSubscriptionBeginAction
> = () => ({
  type: CasActionTypes.REFRESH_CAS_SUBSCRIPTION_BEGIN,
});

export const refreshCasSubscriptionSuccess: ActionCreator<
  IRefreshCasSubscriptionSuccessAction
> = (payload: IRefreshSubscriptionSuccessResponse) => ({
  type: CasActionTypes.REFRESH_CAS_SUBSCRIPTION_SUCCESS,
  payload,
});

export const refreshCasSubscriptionFailure: ActionCreator<
  IRefreshCasSubscriptionFailureAction
> = (payload: IRefreshSubscriptionFailureResponse) => ({
  type: CasActionTypes.REFRESH_CAS_SUBSCRIPTION_FAILURE,
  payload,
});
export const setRefreshCasSubscriptionTimer = () => {
  const time = 900000;

  window.sessionStorage.setItem('timerForCasRefreshCasSubscription', 'set');
  setTimeout(() => {
    window.sessionStorage.setItem('timerForCasRefreshCasSubscription', 'unset');
  }, time);
};

export const refreshCasSubscription = () => (dispatch) => {
  dispatch(refreshCasSubscriptionBegin());

  return refreshSubscriptionRequest()
    .then(
      (
        response: AxiosResponse<
          | IRefreshSubscriptionSuccessResponse
          | IRefreshSubscriptionFailureResponse
        >,
      ) => {
        dispatch(refreshCasSubscriptionSuccess(response.data));
        if (response.data.result === CasResultEnum.OK) {
          setRefreshCasSubscriptionTimer();
        }
      },
    )
    .catch((response: AxiosResponse<IRefreshSubscriptionFailureResponse>) => {
      dispatch(refreshCasSubscriptionFailure(response));
    });
};
