import { ActionCreator } from 'redux';
import { invokeExitServiceCvop } from 'src/api/exitServices';
import {
  IInvokeMnpCvopBeginAction,
  IInvokeMnpCvopFailureAction,
  IInvokeMnpCvopSuccessAction,
} from './types/mnpCvopActionInterfaces';
import MnpCvopActionTypes from './types/mnpCvopActionTypes';

export const invokeMnpCvopBegin: ActionCreator<
  IInvokeMnpCvopBeginAction
> = () => ({
  type: MnpCvopActionTypes.INVOKE_MNP_CVOP_BEGIN,
});

export const invokeMnpCvopSuccess: ActionCreator<
  IInvokeMnpCvopSuccessAction
> = () => ({
  type: MnpCvopActionTypes.INVOKE_MNP_CVOP_SUCCESS,
});

export const invokeMnpCvopFailure: ActionCreator<
  IInvokeMnpCvopFailureAction
> = () => ({
  type: MnpCvopActionTypes.INVOKE_MNP_CVOP_FAILURE,
});

export const invokeMnpCvop = (serviceNumber: string) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(invokeMnpCvopBegin());

    invokeExitServiceCvop({
      serviceNumber,
    })
      .then(() => {
        dispatch(invokeMnpCvopSuccess());
        resolve(true);
      })
      .catch(() => {
        dispatch(invokeMnpCvopFailure());
        reject();
      });
  });
