import { useIsMutating } from '@tanstack/react-query';
import { Button, Loader, Message, Text } from '@vodafone/red-white';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { HStack, VStack } from 'src/components/Layout';
import { ModalContentProps } from 'src/components/Modal/types/modalContentInterfaces';
import {
  GrantAccessStatusEnum,
  JoinAccountStatusEnum,
} from 'src/components/PersonalAccount/VFIDProfile';
import { RoutesEnum } from 'src/config/routes';
import { ReloadFlowEnum } from 'src/features/auth/enum';
import {
  RELOAD_USER_MUTATION_KEY,
  useReloadUser,
} from 'src/features/auth/hooks';
import {
  DELETE_IDENTITY_MUTATION_KEY,
  DELETE_INVITATION_MUTATION_KEY,
  useDeleteIdentity,
  useDeleteInvitation,
} from 'src/features/profile';
import { useAppDispatch } from 'src/hooks/useAppDispatch';
import { IApplicationState } from 'src/store/appStateInterface';
import selectLanguage from 'src/store/language/languageSelector';
import { closeModal } from 'src/store/modal/modalActions';
import ModalType from 'src/store/modal/types/ModalType';
import { LanguagesEnum } from 'src/types/translation/languagesEnum';
import { resolveRoutePath } from 'src/utils/redirect';
import SanitizedHtml from 'src/utils/sanitizeHtml';

const RemoveAccessModalContent = ({ options }: ModalContentProps) => {
  const { t } = useTranslation('personalAccount');
  const dispatch = useAppDispatch();
  const isGrantedAccessType = options.isGrantedAccessType;
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const { mutate: deleteIdentity } = useDeleteIdentity();
  const { mutate: deleteInvitation } = useDeleteInvitation();
  const { mutate: reloadUser } = useReloadUser();
  const language: LanguagesEnum = useSelector((state: IApplicationState) =>
    selectLanguage(state),
  );
  const id = options.invitationId;
  const partyRoleId = options.accountNumber;
  const type = options.authLevel;

  const isMutating = useIsMutating({
    predicate: (mutation) =>
      mutation.options.mutationKey.find(Boolean) ===
        DELETE_IDENTITY_MUTATION_KEY ||
      mutation.options.mutationKey.find(Boolean) ===
        DELETE_INVITATION_MUTATION_KEY ||
      mutation.options.mutationKey.find(Boolean) === RELOAD_USER_MUTATION_KEY,
  });

  const handleDeleteSubmit = () => {
    if (
      options.isGrantedAccessType &&
      options.grandAccessStatus.toUpperCase() !== 'ACTIVE'
    ) {
      deleteInvitation(
        {
          id,
        },
        {
          onSuccess: () => {
            reloadUser({
              redirectUri:
                window.location.origin +
                resolveRoutePath({
                  internalRouteId: RoutesEnum.PERSONAL_ACCOUNT_PROFILE_ROUTE_ID,
                  language,
                  params: {
                    status: GrantAccessStatusEnum.INVITATION_DELETED,
                  },
                }),
              flow: ReloadFlowEnum.ACCESS_UPDATE,
              language,
            });
          },
          onError: () => {
            setErrorMessage(
              'profile.modal.remove-access.accessible.prerequisite-failed',
            );
          },
        },
      );
    } else {
      deleteIdentity(
        {
          id,
          partyRoleId,
          type,
        },
        {
          onSuccess: () => {
            reloadUser({
              redirectUri:
                window.location.origin +
                resolveRoutePath({
                  internalRouteId: RoutesEnum.PERSONAL_ACCOUNT_PROFILE_ROUTE_ID,
                  language,
                  params: {
                    status: JoinAccountStatusEnum.DELETED,
                  },
                }),
              flow: ReloadFlowEnum.ACCESS_UPDATE,
              language,
            });
          },
          onError: (error) => {
            if (error.message.code === '412') {
              setErrorMessage(
                'profile.modal.remove-access.accessible.prerequisite-failed',
              );
            } else {
              setErrorMessage(
                'profile.modal.remove-access.accessible.generic-error',
              );
            }
          },
        },
      );
    }
  };

  const cancelButtonClick = () => {
    dispatch(closeModal({ type: ModalType.RemoveAccess }));
  };
  return (
    <>
      <Loader show={isMutating > 0} />
      <VStack
        align="center"
        style={{
          padding: '40px',
          width: '100%',
          height: '100%',
          justifyContent: 'space-between',
        }}
        spacing={errorMessage ? '0px' : '32px'}
      >
        <VStack spacing="10px" align="center">
          <Text
            fontWeight={700}
            fontSize={26}
            fontFamily="VodafoneRgBd"
            color="red"
            style={{ marginBottom: '40px' }}
          >
            {t('profile.modal.remove-access.title')}
          </Text>
          <HStack style={{ marginBottom: errorMessage ? '20px' : '10px' }}>
            <Text fontWeight={400} fontSize={22} fontFamily="VodafoneRg">
              <SanitizedHtml
                htmlTag="span"
                rawHtmlData={t(
                  `profile.modal.remove-access.${
                    isGrantedAccessType ? 'access-granted' : 'accessible'
                  }.subtitle`,
                  {
                    acrId: options.accountName,
                    acrType: t(`profile.role-${options.authLevel}`),
                  },
                )}
              />
            </Text>
          </HStack>
          {errorMessage && (
            <Message
              type="error"
              message={
                <>
                  <Text fontSize={20} fontFamily="VodafoneRgBd">
                    {t(`${errorMessage}.title`)}
                  </Text>
                  <Text fontSize={18} fontFamily="VodafoneRg">
                    {t(`${errorMessage}.subtitle`)}
                  </Text>
                </>
              }
            />
          )}
        </VStack>
        <HStack justify="space-between" spacing="24px" align="center">
          <Button onClick={cancelButtonClick} variant="secondary">
            {t('profile.modal.remove-access.cancel')}
          </Button>
          <Button
            onClick={handleDeleteSubmit}
            variant="primary"
            disabled={!!errorMessage}
          >
            {t('profile.modal.remove-access.confirm')}
          </Button>
        </HStack>
      </VStack>
    </>
  );
};

export default RemoveAccessModalContent;
