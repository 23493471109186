import { Col, Link, Text } from '@vodafone/red-white';
import styled, { css } from 'styled-components';

export const Content = styled.div<{ redBg?: boolean }>`
  width: 100%;
  background: ${(props) =>
    props.redBg
      ? props.theme.colors.cardRedBackground
      : props.theme.colors.white};
  border-radius: 0px;
  padding: 12px;
  padding-bottom: 30px;
  box-shadow: 0px 6px 12px ${(props) => props.theme.colors.cardBoxShadow};

  @media ${(props) => props.theme.breakpoints.md} {
    border-radius: 6px;
  }

  @media ${(props) => props.theme.breakpoints.lg} {
    padding-top: 18px;
    padding-left: 32px;
    padding-right: 32px;
    padding-bottom: 0px;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
`;

export const HeadingText = styled(Text)`
  margin-left: 8px;
`;

export const BillingCol = styled(Col)<{ redBg?: boolean }>`
  @media ${(props) => props.theme.breakpoints.lg} {
    margin-bottom: 32px;
    border-right: 1px solid
      ${(props) =>
        props.redBg
          ? props.theme.colors.collapseBorderColor
          : props.theme.colors.lightGrey5};
  }
`;

export const SubtitleText = styled(Text)<{ redBg?: boolean }>`
  margin-top: 8px;
  font-size: 16px;
  color: ${(props) =>
    props.redBg ? props.theme.colors.white : props.theme.colors.darkGrey};

  @media ${(props) => props.theme.breakpoints.lg} {
    font-size: 24px;
    color: ${(props) =>
      props.redBg ? props.theme.colors.white : props.theme.colors.lightGrey6};
  }
`;

export const BillingBase = styled.div`
  @media ${(props) => props.theme.breakpoints.lg} {
  }
`;

export const BillingWrapper = styled.div<{ redBg?: boolean }>`
  display: flex;
  padding-top: 15px;
  padding-right: 0px;
  padding-bottom: 0px;
  flex-direction: column;

  @media ${(props) => props.theme.breakpoints.lg} {
    padding-bottom: 32px;
    border-bottom: 1px solid
      ${(props) =>
        props.redBg
          ? props.theme.colors.collapseBorderColor
          : props.theme.colors.lightGrey5};
    flex-direction: row;
    align-items: center;
    margin-right: 32px;
  }
`;

export const BillingFooter = styled.div<{ redBg?: boolean }>`
  display: flex;
  align-items: center;
  margin-top: 25px;
  padding-bottom: 25px;
  justify-content: space-between;

  border-bottom: 1px solid
    ${(props) =>
      props.redBg
        ? props.theme.colors.collapseBorderColor
        : props.theme.colors.lightGrey5};

  @media ${(props) => props.theme.breakpoints.lg} {
    padding-bottom: 0px;
    border-bottom: 0;
    justify-content: flex-start;
  }
`;

export const BillingWrapperDetail = styled.div`
  @media ${(props) => props.theme.breakpoints.lg} {
    margin-left: 32px;
  }
`;

export const QrContentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media ${(props) => props.theme.breakpoints.lg} {
    display: flex;
    align-items: center;
    flex-direction: row;
    padding-left: 32px;
  }
`;

export const PaymentListWrapper = styled.div`
  width: 100%;
  padding-top: 25px;

  @media ${(props) => props.theme.breakpoints.lg} {
    padding-top: 0px;
  }
`;

export const PaymentList = styled.ul`
  list-style: none;
  margin-top: 16px;
`;

export const PaymentListItem = styled.li`
  display: flex;
  margin-top: 8px;
`;

const paymentTextCss = css<{ redBg?: boolean; displayButton?: boolean }>`
  margin-left: ${(props) => (props.displayButton ? 16 : 0)}px;
  font-size: 18px;
  color: ${(props) =>
    props.redBg ? props.theme.colors.white : props.theme.colors.darkGrey};
  font-family: ${(props) => props.theme.fonts.VodafoneRg};

  @media ${(props) => props.theme.breakpoints.lg} {
    margin-left: ${(props) => (props.displayButton ? 32 : 0)}px;
  }
`;

export const PaymentText = styled.span`
  ${paymentTextCss};
`;

export const PaymentLink = styled(Link)`
  ${paymentTextCss};
`;

export const BillingText = styled(Text)`
  white-space: pre;
  line-height: 1;
  margin-bottom: 15px;
`;

export const QrContent = styled.div`
  order: 2;

  @media ${(props) => props.theme.breakpoints.lg} {
    order: 1;
  }
`;

export const MobileQrCodeText = styled(Text)`
  margin-bottom: 12px;
  margin-top: 24px;

  @media ${(props) => props.theme.breakpoints.lg} {
    display: none;
  }
`;

export const QrImage = styled.img`
  width: 96px;
  height: 96px;

  @media ${(props) => props.theme.breakpoints.lg} {
    width: 130px;
    height: 130px;
    margin-right: 32px;
    display: block;
    order: 1;
  }
`;

export const TitleWrapper = styled.span`
  width: 120px;
`;
