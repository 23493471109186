import { AxiosResponse } from 'axios';
import { API_ENDPOINT_VTV_SET_PURCHASE_PIN } from '../..';
import api from '../../../utils/api/api';

export default (serviceNumber: string, pin: string): Promise<AxiosResponse> =>
  api.post({
    url: API_ENDPOINT_VTV_SET_PURCHASE_PIN.replace(
      '{serviceNumber}',
      serviceNumber,
    ),
    data: { pin },
  });
