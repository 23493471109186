import { Reducer } from 'redux';
import initialState from '../initialState';
import {
  IRequestValidationSmsBeginAction,
  IRequestValidationSmsFailureAction,
  IRequestValidationSmsSuccessAction,
} from './types/requestValidationSmsActionInterfaces';
import RequestValidationSmsActionTypes from './types/requestValidationSmsActionTypes';
import IRequestValidationSmsState from './types/requestValidationSmsStateInterface';

type IRequestValidationSmsAction =
  | IRequestValidationSmsBeginAction
  | IRequestValidationSmsSuccessAction
  | IRequestValidationSmsFailureAction;

const requestValidationSmsReducer: Reducer<IRequestValidationSmsState> = (
  state: IRequestValidationSmsState = initialState.RequestValidationSms,
  action: IRequestValidationSmsAction,
) => {
  switch (action.type) {
    case RequestValidationSmsActionTypes.REQUEST_VALIDATION_SMS_BEGIN:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      };
    case RequestValidationSmsActionTypes.REQUEST_VALIDATION_SMS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        loaded: true,
        error: false,
      };
    case RequestValidationSmsActionTypes.REQUEST_VALIDATION_SMS_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    default:
      return state;
  }
};

export default requestValidationSmsReducer;
