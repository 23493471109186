import { Button, Card, IconEsim2, Title } from '@vodafone/red-white';
import { FC, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'src/hooks/useAppDispatch';
import {
  eSimActivateSimOrESimResetStore,
  eSimDeliveryResetStore,
  eSimDetailResetStore,
  eSimFetchDetail,
} from 'src/store/esim/esimActions';
import { selectESimDetail } from 'src/store/esim/esimSelectors';
import { IESimDetailState } from 'src/store/esim/types/esimStateInterface';
import SanitizedHtml from 'src/utils/sanitizeHtml';
import styled, { ThemeContext } from 'styled-components';
import { formatServiceNumber } from '../../../../utils/serviceNumberFormatter';
import { ModalContentProps } from '../../types/modalContentInterfaces';

const CustomCardPadding = styled.div`
  padding: 40px 0 0 0;
`;

const ESimInDeliveryContent: FC<ModalContentProps> = ({
  onClose,
  setCompact,
  setCompactContent,
  options,
}) => {
  const themeContext = useContext(ThemeContext);
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['esim']);

  useEffect(() => {
    dispatch(eSimDetailResetStore());
    setCompact(true);
    setCompactContent(true);
    dispatch(eSimDeliveryResetStore());
    dispatch(eSimActivateSimOrESimResetStore());
    if (options && options.serviceNumber) {
      dispatch(eSimFetchDetail(options.serviceNumber.toString()));
    }
  }, [setCompactContent, setCompact, dispatch, options]);

  const eSimDetail: IESimDetailState = useSelector(selectESimDetail);

  return (
    <Card
      style={{ maxWidth: 700, width: '100%', boxShadow: 'none' }}
      loading={eSimDetail.loading}
      title={
        <>
          <IconEsim2 size={20} color={themeContext.colors.darkGrey} />
          <Title
            color="darkGrey"
            fontFamily="VodafoneRg"
            fontWeight="bold"
            level={3}
            style={{ marginLeft: 8 }}
          >
            {t('esim:eSimInDelivery.title', {
              serviceNumber: formatServiceNumber(options.serviceNumber),
            })}
          </Title>
        </>
      }
    >
      <CustomCardPadding>
        {eSimDetail.loaded && eSimDetail.data.iccid && (
          <>
            <Title
              color="darkGrey"
              fontFamily="VodafoneRg"
              fontWeight="bold"
              fontSize={26}
            >
              {t('esim:eSimInDelivery.sub-title')}
            </Title>

            <p>
              <SanitizedHtml
                htmlTag="div"
                style={{ marginTop: 15, marginBottom: 15 }}
                rawHtmlData={t('esim:eSimInDelivery:help-text')}
              />
            </p>

            <p>
              {eSimDetail.data.eId && (
                <>
                  <strong>
                    {t('esim:preactivatedESim:by-eid.eid', {
                      eid: eSimDetail.data.eId,
                    })}
                  </strong>
                  <br />
                </>
              )}
              {t('esim:preactivatedESim:by-eid.iccid', {
                iccid: eSimDetail.data.iccid,
              })}
            </p>

            <div>
              <Button
                htmlElement="button"
                variant="primary"
                onClick={() => onClose()}
              >
                {t('component:button.close')}
              </Button>
            </div>
          </>
        )}
      </CustomCardPadding>
    </Card>
  );
};

export default ESimInDeliveryContent;
