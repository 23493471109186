import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import intervalPlural from 'i18next-intervalplural-postprocessor';
import { initReactI18next } from 'react-i18next';
import { ENV_MODE } from 'src/env';
import { setLanguageAction } from 'src/store/language/languageActions';
import { store } from './store';

// for all options read: https://www.i18next.com/overview/configuration-options
export const options = {
  fallbackLng: 'cs',
  supportedLngs: ['cs', 'en'],
  defaultNS: '',
  ns: [],
  debug: ENV_MODE === 'development',
  backend: {
    loadPath: '/muj/static/locales/{{lng}}/{{ns}}.json',
    queryStringParams: { v: APP_VERSION },
  },
  detection: {
    order: ['path'],
    lookupFromPathIndex: 1,
  },
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
  skipOnVariables: false,
};

export const configureI18next = () => {
  // eslint-disable-next-line import/no-named-as-default-member
  i18next
    // load translation using xhr -> see /public/locales
    // learn more: https://github.com/i18next/i18next-xhr-backend
    .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    .use(initReactI18next)
    .use(intervalPlural)
    .init(options, () => store.dispatch(setLanguageAction(i18next.language)));

  return i18next;
};
