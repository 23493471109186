import { useQuery } from '@tanstack/react-query';
import { CACHE_TIME_FOR_UNREAD_VF_NEWS } from 'src/config/config';
import { useCoreOutage } from 'src/features/core';
import api from 'src/utils/api/api';

export const GET_UNREAD_NEWS_COUNT_ENDPOINT = '/muj/novinky/ajax/unread';
export const UNREAD_NEWS_COUNT_KEY = 'getUnreadNewsCount';

type GetUnreadNewsCountResponse = {
  result: number;
  status: string;
};

const getUnreadNewsCount = async (): Promise<GetUnreadNewsCountResponse> => {
  const { data } = await api.get({ url: GET_UNREAD_NEWS_COUNT_ENDPOINT });

  return data;
};

export const useUnreadNewsCount = (isUserSignedIn: boolean) => {
  const outage = useCoreOutage();

  return useQuery({
    queryFn: () => getUnreadNewsCount(),
    queryKey: [UNREAD_NEWS_COUNT_KEY],
    gcTime: CACHE_TIME_FOR_UNREAD_VF_NEWS,
    enabled: isUserSignedIn && !outage.data.isReadMode,
  });
};
