import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useRefreshToken } from 'src/features/auth/hooks';
import userSelector from 'src/store/user/userSelector';
import { RefreshTokenContext } from './refreshToken.context';

export const RefreshTokenProvider: React.FCC = ({ children }) => {
  const [tokenExpiresIn, setTokenExpiresIn] = useState<string | undefined>();
  const [isEnabled, setIsEnabled] = useState<boolean>(false);

  const user = useSelector(userSelector);
  const { data } = useRefreshToken(
    tokenExpiresIn ? parseInt(tokenExpiresIn) * 1000 : undefined,
    isEnabled,
  );

  useEffect(() => {
    if (data?.tokenExpiresIn) {
      setTokenExpiresIn(data.tokenExpiresIn);
    }
  }, [data]);

  useEffect(() => {
    if (user?.tokenExpiresIn) {
      setTokenExpiresIn(user.tokenExpiresIn.toString());

      if (!isEnabled && !!user.signedIn) {
        setTimeout(() => setIsEnabled(true), user.tokenExpiresIn * 1000);
      }
    }
  }, [user]);

  return (
    <RefreshTokenContext.Provider
      value={{
        tokenExpiresIn,
      }}
    >
      {children}
    </RefreshTokenContext.Provider>
  );
};
