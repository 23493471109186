import {
  Button,
  IconOk2,
  Loader,
  StatusBox,
  STATUS_COLORS,
} from '@vodafone/red-white';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'src/hooks/useAppDispatch';
import ISubscription from 'src/store/user/types/subscriptionInterface';
import IUserState from 'src/store/user/types/userStateInterface';
import userSelector, {
  selectCurrentSubscription,
} from 'src/store/user/userSelector';
import IVtvServicesState from 'src/store/vtvServices/types/vtvServicesStateInterface';
import { generateVtvPasswordInModal } from 'src/store/vtvServices/vtvServicesActions';
import { getVtvServices } from 'src/store/vtvServices/vtvServicesSelectors';
import SanitizedHtml from '../../../utils/sanitizeHtml';
import { ModalContentProps } from '../types/modalContentInterfaces';

const VtvGeneratePasswordModalContent: FC<ModalContentProps> = ({
  setTitle,
  setButton,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['services', 'error']);

  const { customerAccountContactPhone }: IUserState = useSelector(userSelector);
  const { serviceNumber }: ISubscription = useSelector(
    selectCurrentSubscription,
  );

  const { generatePassword }: IVtvServicesState = useSelector(getVtvServices);
  const { loading, loaded, error } = generatePassword;

  useEffect(() => {
    setTitle(t('services:vtv.generate-password-title'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    const onSubmit = () =>
      dispatch(
        generateVtvPasswordInModal(serviceNumber, customerAccountContactPhone),
      );

    setButton(
      !loaded && (
        <Button onClick={onSubmit} variant="primary">
          {t('services:vtv.generate-password-title')}
        </Button>
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceNumber, customerAccountContactPhone, loaded]);

  return (
    <>
      <Loader show={loading} />
      {error && (
        <StatusBox color={STATUS_COLORS.red}>
          {t('error:api-request')}
        </StatusBox>
      )}
      {loaded && (
        <StatusBox
          color={STATUS_COLORS.green}
          icon={<IconOk2 color={STATUS_COLORS.green2} />}
        >
          {t('services:vtv.generate-password-success-message')}
        </StatusBox>
      )}
      {!loaded && (
        <SanitizedHtml
          htmlTag="p"
          rawHtmlData={t('services:vtv.generate-password-description')}
        />
      )}
    </>
  );
};

export default VtvGeneratePasswordModalContent;
