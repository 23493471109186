import styled from 'styled-components';

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  line-height: 1.4em;
  font-size: 16px;
`;

const StyledTh = styled.th`
  text-align: left;
  padding: 10px;
  width: 25%;
  text-transform: none;

  @media (max-width: 768px) {
    display: block;
    width: 100%;
  }
`;

const StyledTd = styled.td`
  padding: 10px;
  width: 25%;

  @media (max-width: 768px) {
    display: block;
    width: 100%;
  }
`;

const StrongTag = styled.strong`
  margin: 5px;
`;

export const RoamingCalculatorTable = ({ thead, tbody }) => {
  const renderTableHeaders = () => {
    return thead.map((header) => (
      <StyledTh key={header.key}>
        <header.icon />
        <StrongTag>{header.text}</StrongTag>
      </StyledTh>
    ));
  };

  const renderTableBody = () => {
    const maxItems = Math.max(
      ...tbody.map((column) => column.columnItems.length),
    );

    const rows = [];
    for (let rowIndex = 0; rowIndex < maxItems; rowIndex++) {
      const rowCells = tbody.map((column, columnIndex) => {
        const item = column.columnItems.find((item) => item.key === rowIndex);
        return (
          <StyledTd key={`${columnIndex}-${rowIndex}`}>
            {item ? (
              <span dangerouslySetInnerHTML={{ __html: item.text }} />
            ) : null}
          </StyledTd>
        );
      });

      rows.push(<tr key={rowIndex}>{rowCells}</tr>);
    }

    return rows;
  };

  return (
    <StyledTable>
      <thead>
        <tr>{renderTableHeaders()}</tr>
      </thead>
      <tbody>{renderTableBody()}</tbody>
    </StyledTable>
  );
};
