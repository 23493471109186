enum VtvServicesActionTypes {
  FETCH_VTV_ACTIVE_DEVICES_BEGIN = 'FETCH_VTV_ACTIVE_DEVICES_BEGIN',
  FETCH_VTV_ACTIVE_DEVICES_SUCCESS = 'FETCH_VTV_ACTIVE_DEVICES_SUCCESS',
  FETCH_VTV_ACTIVE_DEVICES_FAILURE = 'FETCH_VTV_ACTIVE_DEVICES_FAILURE',
  GENERATE_VTV_PASSWORD_BEGIN = 'GENERATE_VTV_PASSWORD_BEGIN',
  GENERATE_VTV_PASSWORD_SUCCESS = 'GENERATE_VTV_PASSWORD_SUCCESS',
  GENERATE_VTV_PASSWORD_FAILURE = 'GENERATE_VTV_PASSWORD_FAILURE',
  SET_VTV_PASSWORD_BEGIN = 'SET_VTV_PASSWORD_BEGIN',
  SET_VTV_PASSWORD_SUCCESS = 'SET_VTV_PASSWORD_SUCCESS',
  SET_VTV_PASSWORD_FAILURE = 'SET_VTV_PASSWORD_FAILURE',
  SET_VTV_ACTIVE_DEVICE_TO_REMOVE = 'SET_VTV_ACTIVE_DEVICE_TO_REMOVE',
  REMOVE_VTV_ACTIVE_DEVICE_BEGIN = 'REMOVE_VTV_ACTIVE_DEVICE_BEGIN',
  REMOVE_VTV_ACTIVE_DEVICE_SUCCESS = 'REMOVE_VTV_ACTIVE_DEVICE_SUCCESS',
  REMOVE_VTV_ACTIVE_DEVICE_FAILURE = 'REMOVE_VTV_ACTIVE_DEVICE_FAILURE',
  SET_VTV_PURCHASE_PIN_BEGIN = 'SET_VTV_PURCHASE_PIN_BEGIN',
  SET_VTV_PURCHASE_PIN_SUCCESS = 'SET_VTV_PURCHASE_PIN_SUCCESS',
  SET_VTV_PURCHASE_PIN_FAILURE = 'SET_VTV_PURCHASE_PIN_FAILURE',
  SET_VTV_PASSWORD_RESET_STORE = 'SET_VTV_PASSWORD_RESET_STORE',
}

export default VtvServicesActionTypes;
