import { useQuery } from '@tanstack/react-query';
import { CACHE_TIME_FOR_UNREAD_VF_NEWS } from 'src/config/config';
import { useCoreOutage } from 'src/features/core';
import api from 'src/utils/api/api';

export const GET_UNREAD_CONTACT_US_COUNT_ENDPOINT =
  '/muj/kontaktujte-nas/ajax/unread';
export const UNREAD_CONTACT_US_COUNT_KEY = 'getUnreadContactUsCount';

type GetUnreadContactUsCountResponse = {
  result: number;
};

const getUnreadContactUsCount =
  async (): Promise<GetUnreadContactUsCountResponse> => {
    const { data } = await api.get({
      url: GET_UNREAD_CONTACT_US_COUNT_ENDPOINT,
    });

    return data;
  };

export const useUnreadContactUsCount = (isUserSignedIn: boolean) => {
  const outage = useCoreOutage();

  return useQuery({
    queryFn: () => getUnreadContactUsCount(),
    queryKey: [UNREAD_CONTACT_US_COUNT_KEY],
    gcTime: CACHE_TIME_FOR_UNREAD_VF_NEWS,
    enabled: isUserSignedIn && !outage.data.isReadMode,
  });
};
