import { AxiosResponse } from 'axios';
import { API_ENDPOINT_CAS_RESET_PIN } from '../..';
import api from '../../../utils/api/api';
import ICasResetPinFailureResponse from '../response/casResetPinFailureResponse';
import CasResetPinSuccessResponse from '../response/casResetPinSuccessResponse';

export default (
  deviceId: string,
): Promise<
  AxiosResponse<CasResetPinSuccessResponse | ICasResetPinFailureResponse>
> =>
  api.post({
    url: API_ENDPOINT_CAS_RESET_PIN.replace('{udid}', deviceId),
  });
