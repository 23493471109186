import initialState from '../../initialState';
import {
  FETCH_IP_ADDRESSES_SUCCESS,
  IpActionTypes,
} from '../types/ipManagement/ipActionTypes';
import IIPManagementState from '../types/ipManagement/ipManagementStateInterface';

export default (
  state = initialState.FbbServices.ipManagement,
  action: IpActionTypes,
): IIPManagementState => {
  switch (action.type) {
    case FETCH_IP_ADDRESSES_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        ...action.payload,
      };
    }
    default:
      return state;
  }
};
