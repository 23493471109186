import { LanguagesEnum } from '../../../types/translation/languagesEnum';
import { mapLocationToRoute } from '../../mapLocationToRoute';

export const mapRouteToAnalytics = (
  location: string,
  language: LanguagesEnum,
  pageNameSuffix: string,
) => {
  const matchedRoute = mapLocationToRoute(location);
  const pageName = matchedRoute.analytics[language];

  return pageNameSuffix ? `${pageName}:${pageNameSuffix}` : pageName;
};
