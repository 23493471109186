import { AxiosResponse } from 'axios';
import { API_ENDPOINT_VALIDATE_SMS_ENDPOINT } from 'src/api';
import api from 'src/utils/api/api';
import SmsCodeValidationRequestInterface from './request/verifySmsCodeRequestInterface';
import SmsCodeValidationResponseInterface from './response/verifySmsCodeResponseInterface';

const validateSmsCodeRequestCall = (
  request: SmsCodeValidationRequestInterface,
): Promise<AxiosResponse<SmsCodeValidationResponseInterface>> =>
  api.post({
    url: API_ENDPOINT_VALIDATE_SMS_ENDPOINT.replace(
      '{verificationTarget}',
      request.verificationTarget,
    ).replace('{code}', request.code),
  });

export default validateSmsCodeRequestCall;
