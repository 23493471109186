import { AxiosResponse } from 'axios';
import api from 'src/utils/api/api';
import {
  API_ENDPOINT_EXIT_SERVICES_AUTH_TYPE,
  API_ENDPOINT_EXIT_SERVICES_FBB_PENALTY,
  API_ENDPOINT_EXIT_SERVICES_GET_OKU,
  API_ENDPOINT_EXIT_SERVICES_INVOKE_CVOP,
  API_ENDPOINT_EXIT_SERVICES_INVOKE_PENALTY,
} from '..';
import IExitServicesAuthTypeRequest from './request/exitServiceAuthTypeRequestInterface';
import IGetExitServiceOkuRequest from './request/getExitServiceOkuRequestInterface';
import IGetExitServicePenaltyRequest from './request/getExitServicePenaltyRequestInterface';
import IGetFbbExitServicePenaltyRequest from './request/getFbbExitServicePenaltyRequestInterface';
import IInvokeExitServiceCvopRequest from './request/invokeExitServiceCvopRequestInterface';
import IGetExitServiceOkuResponse from './response/getExitServiceOkuResponseInterface';

export const getExitServiceOku = (
  request: IGetExitServiceOkuRequest,
): Promise<AxiosResponse<IGetExitServiceOkuResponse>> =>
  api.get({
    url: API_ENDPOINT_EXIT_SERVICES_GET_OKU.replace(
      '{serviceNumber}',
      request.serviceNumber,
    ),
  });

export const invokeExitServiceCvop = (
  request: IInvokeExitServiceCvopRequest,
): Promise<AxiosResponse> =>
  api.post({
    url: API_ENDPOINT_EXIT_SERVICES_INVOKE_CVOP.replace(
      '{serviceNumber}',
      request.serviceNumber,
    ),
  });

export const getExitServicePenalty = (
  request: IGetExitServicePenaltyRequest,
): Promise<AxiosResponse> =>
  api.get({
    url: API_ENDPOINT_EXIT_SERVICES_INVOKE_PENALTY.replace(
      '{serviceNumber}',
      request.serviceNumber,
    ).replace('{interactionPoint}', request.interactionPoint),
  });

export const getAuthType = (
  request: IExitServicesAuthTypeRequest,
): Promise<AxiosResponse> =>
  api.get({
    url: API_ENDPOINT_EXIT_SERVICES_AUTH_TYPE.replace(
      '{serviceNumber}',
      request.serviceNumber,
    ),
  });

export const getExitServiceFbbPenalty = (
  request: IGetFbbExitServicePenaltyRequest,
): Promise<AxiosResponse> =>
  api.get({
    url: API_ENDPOINT_EXIT_SERVICES_FBB_PENALTY.replace(
      '{serviceNumber}',
      request.serviceNumber,
    ),
  });
