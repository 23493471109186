import { createSelector, Selector } from 'reselect';
import IPegaOffer from 'src/store/pegaOffers/types/pegaOfferInterface';
import PegaOffersOfferIdEnum from '../../types/pegaOffers/pegaOffersOfferIdEnum';
import { IApplicationState } from '../appStateInterface';
import { IPegaOffersState } from './types/pegaOffersStateInterface';

const getPegaOffers: Selector<IApplicationState, IPegaOffersState> = (
  state: IApplicationState,
) => state.PegaOffers;

export const selectPegaOffer = (
  state: IApplicationState,
  offerId: PegaOffersOfferIdEnum,
  serviceNumber?: string,
): IPegaOffer[] | null => {
  if (!serviceNumber) {
    return null;
  }

  return createSelector(
    [getPegaOffers],
    (pegaOffers) =>
      pegaOffers?.serviceNumber?.[serviceNumber]?.offers?.[offerId]?.data,
  )(state);
};

export const hasServiceNumberPeggaOffersLoaded = (
  state: IApplicationState,
  offerId: PegaOffersOfferIdEnum,
  serviceNumber: string,
): boolean =>
  createSelector([getPegaOffers], (pegaOffers) => {
    return (
      !!pegaOffers?.serviceNumber?.[serviceNumber]?.offers?.[offerId]?.loaded &&
      pegaOffers?.serviceNumber?.[serviceNumber]?.offers?.[offerId]?.error ===
        false
    );
  })(state);

export const selectIsPegaOfferLoadedForDashboard = (
  state: IApplicationState,
  serviceNumber?: string,
): boolean => {
  if (!serviceNumber) {
    return false;
  }

  return createSelector(
    [getPegaOffers],
    (pegaOffers) =>
      pegaOffers?.serviceNumber?.[serviceNumber]?.offers?.[
        PegaOffersOfferIdEnum.MVADASHBOARD1
      ]?.loaded &&
      pegaOffers?.serviceNumber?.[serviceNumber]?.offers?.[
        PegaOffersOfferIdEnum.MVADASHBOARD2
      ]?.loaded &&
      pegaOffers?.serviceNumber?.[serviceNumber]?.offers?.[
        PegaOffersOfferIdEnum.MVADASHBOARD3
      ]?.loaded &&
      pegaOffers?.serviceNumber?.[serviceNumber]?.offers?.[
        PegaOffersOfferIdEnum.MVATOBI
      ]?.loaded,
  )(state);
};
