import { Reducer } from 'redux';
import initialState from '../initialState';
import {
  IFetchNewsBeginAction,
  IFetchNewsFailureAction,
  IFetchNewsSuccessAction,
} from './types/newsActionInterfaces';
import { NewsActionTypes } from './types/newsActionTypes';
import { INewsState } from './types/newsStateInterface';

type NewsAction =
  | IFetchNewsBeginAction
  | IFetchNewsSuccessAction
  | IFetchNewsFailureAction;

export const newsReducer: Reducer<INewsState> = (
  state: INewsState = initialState.News,
  action: NewsAction,
) => {
  switch (action.type) {
    case NewsActionTypes.FETCH_NEWS_BEGIN:
      return {
        ...state,
        loading: true,
        loaded: false,
      };

    case NewsActionTypes.FETCH_NEWS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        data: action.payload,
      };

    case NewsActionTypes.FETCH_NEWS_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
        data: null,
      };

    default:
      return state;
  }
};
