interface QueryParams {
  [key: string]: string;
}

export const buildQuery = (params: QueryParams): string => {
  return Object.keys(params)
    .map((key) => {
      return `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`;
    })
    .join('&');
};

export const searchParamInUrl = (param: string) =>
  new URLSearchParams(window.location.search).get(param);

/**
 * examples:
 * url: http://example.com/category/article?p1=test1&p2=test2
 *      buildUrl(null, ['p1', 'p2']) => http://example.com?p1=test1&p2=test2
 *      buildUrl(null, ['p1', 'p2', 'p3']) => http://example.com?p1=test1&p2=test2
 *      buildUrl(null, ['p3']) => http://example.com
 *      buildUrl('http://example.com/new-folder?p1=test1&p2=test2', ['p1', 'p2']) => http://example.com/new-folder?p1=test1&p2=test2
 */
export const buildUrl = (base?: string, params?: string[]): string => {
  const url = `${
    base || `${window.location.protocol}//${window.location.host}`
  }`;

  const queryParams =
    params &&
    params.reduce((result, paramName) => {
      const paramValue = searchParamInUrl(paramName);

      if (paramValue) {
        return {
          ...result,
          [paramName]: paramValue,
        };
      }

      return result;
    }, {});

  const queryString = queryParams && buildQuery(queryParams);

  return `${url}${queryString ? `?${queryString}` : ''}`;
};

export const transformRedirectParamToUrl = (base?: string) => {
  if (
    searchParamInUrl('redirect') === null &&
    searchParamInUrl('redirect_uri') === null
  ) {
    return null;
  }

  const path = searchParamInUrl('redirect') || searchParamInUrl('redirect_uri');
  const baseUrl = `${
    base || `${window.location.protocol}//${window.location.host}`
  }`;

  if (path.startsWith('http://') || path.startsWith('https://')) {
    return path;
  }

  return `${baseUrl}${path.startsWith('/') ? '' : '/'}${path}`;
};

export const isValidHttpUrl = (string): boolean => {
  let url;

  try {
    url = new URL(string);
  } catch (e) {
    return false;
  }

  return url.protocol === 'http:' || url.protocol === 'https:';
};

export const getCurrentUrl = (withHost = false) => {
  const { href, origin } = window.location;

  return withHost ? href : href.substring(origin.length);
};
