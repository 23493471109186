import React from 'react';
import styled from 'styled-components';

interface HStackProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  spacing?: string;
  align?: 'flex-start' | 'flex-end' | 'center' | 'baseline' | 'stretch';
  justify?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'space-evenly';
}

const StyledHStack = styled.div<HStackProps>`
  display: flex;
  flex-direction: row;
  align-items: ${({ align }) => align || 'stretch'};
  justify-content: ${({ justify }) => justify || 'flex-start'};
  & > :not(:first-child) {
    margin-left: ${({ spacing }) => spacing || '0px'};
  }
`;

export const HStack = ({
  children,
  spacing,
  align,
  justify,
  ...props
}: HStackProps) => {
  return (
    <StyledHStack spacing={spacing} align={align} justify={justify} {...props}>
      {children}
    </StyledHStack>
  );
};
