import { createSelector, Selector } from 'reselect';
import { IApplicationState } from '../appStateInterface';
import IAppState, {
  Device,
  IApiState,
} from './types/applicationStateInterface';

export const getApplication: Selector<IApplicationState, IAppState> = (state) =>
  state.Application;

// eslint-disable-next-line import/prefer-default-export
export const selectMediaDevice: Selector<IApplicationState, Device> =
  createSelector(
    [getApplication],
    (application: IAppState) => application.device,
  );

export const selectApiStatus = (
  api: string,
): Selector<IApplicationState, IApiState> =>
  createSelector([getApplication], (application: IAppState) =>
    application.apiLoading[api] === undefined
      ? {
          name: api,
          loading: false,
          loaded: false,
          error: false,
          needToBeReloaded: false,
        }
      : application.apiLoading[api],
  );
