import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AuthLevel } from 'src/components/PersonalAccount/VFIDProfile';
import api from 'src/utils/api/api';

export const CREATE_INVITATION_ENDPOINT =
  '/muj/rapi/account-management/invitation/create';
export const CREATE_INVITATION_MUTATION_KEY = 'createInvitationMutation';
export const CREATE_INVITATION_KEY = 'createInvitation';

type CreateInvitationRequest = {
  email: string;
  type: AuthLevel;
  roleId: string;
};

type CreateInvitationError = {
  message: {
    code: string;
    description: string;
  };
};

type CreateInvitationResponse = {
  status: string;
};

const createIdentityFn = async (
  request: CreateInvitationRequest,
): Promise<CreateInvitationResponse> => {
  const response = await api
    .post<CreateInvitationResponse>({
      url: CREATE_INVITATION_ENDPOINT,
      data: request,
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });

  return response.data;
};

export const useCreateInvitation = () => {
  const queryClient = useQueryClient();

  return useMutation<
    CreateInvitationResponse,
    CreateInvitationError,
    CreateInvitationRequest
  >({
    mutationFn: createIdentityFn,
    mutationKey: [CREATE_INVITATION_MUTATION_KEY],
    onSuccess: (data, variables) => {
      queryClient.setQueryData([CREATE_INVITATION_KEY, { variables }], data);
    },
  });
};
