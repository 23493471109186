import { AxiosResponse } from 'axios';
import {
  API_ENDPOINT_LARGE_BILLING_ACCOUNTS,
  API_ENDPOINT_LARGE_PREACTIVATED_CA_ESIMS,
  API_ENDPOINT_LARGE_SUBSCRIBERS,
} from 'src/api';
import { ILargeAccountsPreactivatedESimsSuccessResponse } from 'src/api/largeAccounts/response/ILargeAccountsPreactivatedESimsSuccessResponse';
import { IRestResponseFault } from 'src/api/types/IRestResponseFault';
import api from 'src/utils/api/api';
import { ILargeBillingAccountsResponse } from './response/largeBillingAccountsResponseInterface';
import { ILargeSubscribersResponse } from './response/largeSubscribersResponseInterface';

export const getLargeSubscribers = (
  customerAccountNumber,
): Promise<AxiosResponse<ILargeSubscribersResponse | IRestResponseFault>> => {
  return api.get<ILargeSubscribersResponse>({
    url: `${API_ENDPOINT_LARGE_SUBSCRIBERS}`.replace(
      '{caNumber}',
      customerAccountNumber,
    ),
  });
};

export const getLargeBillingAccounts = (
  customerAccountNumber: string,
): Promise<
  AxiosResponse<ILargeBillingAccountsResponse | IRestResponseFault>
> => {
  return api.get<ILargeBillingAccountsResponse>({
    url: `${API_ENDPOINT_LARGE_BILLING_ACCOUNTS}`.replace(
      '{caNumber}',
      customerAccountNumber,
    ),
  });
};

export const getLargePreactivatedCAESims = (
  customerAccountNumber: string,
): Promise<
  AxiosResponse<
    ILargeAccountsPreactivatedESimsSuccessResponse | IRestResponseFault
  >
> => {
  return api.get<
    ILargeAccountsPreactivatedESimsSuccessResponse | IRestResponseFault
  >({
    url: `${API_ENDPOINT_LARGE_PREACTIVATED_CA_ESIMS}`.replace(
      '{caNumber}',
      customerAccountNumber,
    ),
  });
};
