import { Reducer } from 'redux';
import initialState from '../initialState';
import {
  IRecoverySettingBeginAction,
  IRecoverySettingFailureAction,
  IRecoverySettingSuccessAction,
} from './types/recoverySettingActionInterfaces';
import RecoverySettingActionTypes from './types/recoverySettingActionTypes';
import IRecoverySettingState from './types/recoverySettingStateInterface';

type IRecoverySettingAction =
  | IRecoverySettingBeginAction
  | IRecoverySettingSuccessAction
  | IRecoverySettingFailureAction;

const recoverySettingReducer: Reducer<IRecoverySettingState> = (
  state: IRecoverySettingState = initialState.RecoverySetting,
  action: IRecoverySettingAction,
) => {
  switch (action.type) {
    case RecoverySettingActionTypes.RECOVERY_SETTING_BEGIN:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      };
    case RecoverySettingActionTypes.RECOVERY_SETTING_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        loaded: true,
        error: false,
      };
    case RecoverySettingActionTypes.RECOVERY_SETTING_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    default:
      return state;
  }
};

export default recoverySettingReducer;
