import initialState from '../../initialState';
import {
  FbbModemMacAddressTypes,
  FETCH_FBB_MODEM_MAC_ADDRESS_REFRESHING,
  FETCH_FBB_MODEM_MAC_ADDRESS_SUCCESS,
} from '../types/fbbModemMacAddress/fbbModemMacAddressActionTypes';
import IFbbModemMacAddressState from '../types/fbbModemMacAddress/fbbModemMacAddressStateInterface';

export default (
  state = initialState.FbbServices.fbbModemMacAddress,
  action: FbbModemMacAddressTypes,
): IFbbModemMacAddressState => {
  switch (action.type) {
    case FETCH_FBB_MODEM_MAC_ADDRESS_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        data: action.payload,
      };
    }
    case FETCH_FBB_MODEM_MAC_ADDRESS_REFRESHING: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      };
    }
    default:
      return state;
  }
};
