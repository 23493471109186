import { Row } from '@vodafone/red-white';
import * as React from 'react';
import styled from 'styled-components';

const colors = {
  weak: 'primaryColor',
  medium: 'orange',
  strong: 'green',
};

export type StrengthType = 'weak' | 'medium' | 'strong';

const getType = (i: number, s: StrengthType) => {
  if (s === 'weak' && i < 1) return s;
  if (s === 'medium' && i < 2) return s;
  if (s === 'strong' && i < 3) return s;

  return null;
};

const Strength = styled.span<{ type?: StrengthType }>`
  width: 20px;
  height: 6px;
  border-radius: 3px;
  background: ${(props) =>
    props.theme.colors[colors[props.type] || 'lightGrey14']};
  transition: 0.3s background
    ${(props) => props.theme.transitions.easeInOutCubic};

  &:not(:last-child) {
    margin-right: 5px;
  }
`;

export interface IPasswordStrengthProps {
  status: StrengthType;
  size?: 2 | 3;
}

export const PasswordStrength: React.FC<IPasswordStrengthProps> = ({
  status,
  size = 3,
}) => {
  return (
    <Row align="center" justify="flex-end">
      {[...Array(size).fill(null)].map((_, i: number) => (
        <Strength key={i.toString()} type={getType(i, status)} />
      ))}
    </Row>
  );
};
