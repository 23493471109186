import { ThunkAction } from '@reduxjs/toolkit';
import { Action, ActionCreator } from 'redux';
import { IApplicationState } from '../appStateInterface';
import { actionShowNotice } from '../notice/noticeActions';
import {
  NoticeStyleModifiersEnum,
  NoticeTypesEnum,
} from '../notice/noticeEnums';
import {
  ApplicationActionTypes,
  FETCH_API_BEGIN,
  FETCH_API_FAILURE,
  FETCH_API_RELOAD,
  FETCH_API_SUCCESS,
  HIDE_LOADER,
  ISetCurrentPageInterface,
  ISetDeviceActionInterface,
  ISetPreviousPageInterface,
  SET_CURRENT_PAGE,
  SET_MEDIA_DEVICE,
  SET_PREVIOUS_PAGE,
  SHOW_LOADER,
} from './applicationActionTypes';
import { Device } from './types/applicationStateInterface';

export const setMediaDeviceAction: ActionCreator<ISetDeviceActionInterface> = (
  media: Device,
) => ({
  type: SET_MEDIA_DEVICE,
  payload: media,
});

export const setPreviousPageAction: ActionCreator<ISetPreviousPageInterface> = (
  previousPage: string,
) => ({
  type: SET_PREVIOUS_PAGE,
  payload: previousPage,
});

export const setCurrentPageAction: ActionCreator<ISetCurrentPageInterface> = (
  currentPage: string,
) => ({
  type: SET_CURRENT_PAGE,
  payload: currentPage,
});

export const setCurrentPage =
  (
    currentPage: string,
  ): ThunkAction<void, IApplicationState, unknown, Action<string>> =>
  async (dispatch) => {
    const previousPage = window.localStorage.getItem('currentPage');

    window.localStorage.setItem('previousPage', previousPage);
    window.localStorage.setItem('currentPage', currentPage);
    dispatch(setPreviousPageAction(previousPage));
    dispatch(setCurrentPageAction(currentPage));
  };

export const fetchApiBegin = (apiEndpoint: string): ApplicationActionTypes => ({
  type: FETCH_API_BEGIN,
  payload: apiEndpoint,
});
export const fetchApiSuccess = (
  apiEndpoint: string,
): ApplicationActionTypes => ({
  type: FETCH_API_SUCCESS,
  payload: apiEndpoint,
});
export const fetchApiFailure = (
  apiEndpoint: string,
): ApplicationActionTypes => ({
  type: FETCH_API_FAILURE,
  payload: apiEndpoint,
});

export const fetchApiReload = (
  apiEndpoint: string,
): ApplicationActionTypes => ({
  type: FETCH_API_RELOAD,
  payload: apiEndpoint,
});

export const showLoader = (): ApplicationActionTypes => ({
  type: SHOW_LOADER,
});

export const hideLoader = (): ApplicationActionTypes => ({
  type: HIDE_LOADER,
});

export const apiBegin =
  (
    apiEndpoint: string,
  ): ThunkAction<void, IApplicationState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(showLoader());
    dispatch(fetchApiBegin(apiEndpoint));
  };

export const apiSuccess =
  (
    apiEndpoint: string,
  ): ThunkAction<void, IApplicationState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(hideLoader());
    dispatch(fetchApiSuccess(apiEndpoint));
  };

export const apiFailure =
  (
    apiEndpoint: string,
  ): ThunkAction<void, IApplicationState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(hideLoader());
    dispatch(fetchApiFailure(apiEndpoint));
    dispatch(
      actionShowNotice({
        title: 'API Chyba',
        message: [
          {
            index: 0,
            text: apiEndpoint,
          },
        ],
        noticeType: NoticeTypesEnum.error,
        noticeStyleModifier: NoticeStyleModifiersEnum.error,
      }),
    );
  };

export const apiReload =
  (
    apiEndpoint: string,
  ): ThunkAction<void, IApplicationState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(showLoader());
    dispatch(fetchApiReload(apiEndpoint));
  };
