// https://github.com/bvaughn/react-error-boundary
import { Component } from 'react';
import ErrorMessage from './ErrorMessage';

type Props = {
  children?: any;
  FallbackComponent: React.ComponentType<any> | null;
  onError?: (error: Error, componentStack: string) => void;
};

type ErrorInfo = {
  componentStack: string;
};

type State = {
  error?: Error;
  info?: ErrorInfo;
};

class ErrorBoundary extends Component<Props, State> {
  static defaultProps = {
    // eslint-disable-next-line react/default-props-match-prop-types
    FallbackComponent: ErrorMessage,
  };

  state = {
    error: null,
    info: null,
  };

  componentDidCatch(error: Error, info: ErrorInfo): void {
    // @ts-ignore
    const { onError } = this.props;

    if (typeof onError === 'function') {
      try {
        /* istanbul ignore next: Ignoring ternary; can’t reproduce missing info in test environment. */
        onError.call(this, error, info ? info.componentStack : '');
        // eslint-disable-next-line no-empty
      } catch (ignoredError) {}
    }

    // @ts-ignore
    this.setState({ error, info });
  }

  render() {
    // @ts-ignore
    const { children, FallbackComponent } = this.props;
    const { error, info } = this.state;

    if (error !== null && FallbackComponent) {
      return (
        <FallbackComponent
          componentStack={info ? info.componentStack : ''}
          error={error}
        />
      );
    }

    return children || null;
  }
}

export default ErrorBoundary;
