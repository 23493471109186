import initialState from '../initialState';
import { CHANGE_LANGUAGE } from './languageActionTypes';
import ILanguageState from './types/LanguageStateInterface';
import ISetLanguageAction from './types/SetLanguageActionInterface';

export default (
  state: ILanguageState = initialState.Language,
  action: ISetLanguageAction,
) => {
  if (action.type === CHANGE_LANGUAGE) {
    return {
      ...state,
      language: action.payload.language,
    };
  }
  return state;
};
