import i18n, { changeLanguage } from 'i18next';
import { generatePath } from 'react-router';
import { ActionCreator } from 'redux';
import { getRoute, RoutesEnum } from 'src/config/routes';
import { redirect } from 'src/utils/redirect';
import { LanguagesEnum } from '../../types/translation/languagesEnum';
import { clearGroupsData } from '../services/actions/sectionActions';
import {
  clearServicesData,
  fetchAllServices,
  fetchServices,
} from '../services/actions/serviceActions';
import { CHANGE_LANGUAGE } from './languageActionTypes';
import ISetLanguageAction from './types/SetLanguageActionInterface';

export const setLanguageAction: ActionCreator<ISetLanguageAction> = (
  newLanguage: LanguagesEnum,
) => ({
  type: CHANGE_LANGUAGE,
  payload: {
    language: newLanguage,
  },
});

const getPathAfterSwitch = (match, newLanguage: LanguagesEnum) => {
  const route = getRoute(match.path);

  return generatePath(route?.path[newLanguage], match.params);
};

export const toggleLanguage = (history, match) => async (dispatch) => {
  const newLanguage =
    i18n.language === LanguagesEnum.CS ? LanguagesEnum.EN : LanguagesEnum.CS;
  await changeLanguage(newLanguage);
  dispatch(setLanguageAction(newLanguage));
  redirect({
    url: getPathAfterSwitch(match, newLanguage),
    language: newLanguage,
    history,
    params: match.params,
  });

  if (getRoute(match.path)?.id === RoutesEnum.ACTIVE_SERVICES_ROUTE_ID) {
    dispatch(clearGroupsData());
    dispatch(clearServicesData());
    dispatch(fetchServices());
    dispatch(fetchAllServices());
  }
};
