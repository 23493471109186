export enum BreadcrumbsEnum {
  HOME = 'home',
  MUJ = 'muj',
  SERVICES = 'services',
  SPENDING = 'spending',
}

export const BreadcrumbsGroupServices = [
  BreadcrumbsEnum.HOME,
  BreadcrumbsEnum.MUJ,
  BreadcrumbsEnum.SERVICES,
];
