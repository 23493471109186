import { Reducer } from 'redux';
import initialState from '../../initialState';
import { SectionActionTypes } from '../types/section/sectionActionTypes';
import {
  IClearServicesDataAction,
  IExpandServiceAfterBssAndWscLoadedBeginAction,
  IExpandServiceAfterBssAndWscLoadedSuccessAction,
  IFetchAllServicesBeginAction,
  IFetchAllServicesFailureAction,
  IFetchAllServicesSuccessAction,
  IFetchMoreServicesSuccessAction,
  IFetchServiceDetailBeginAction,
  IFetchServiceDetailFailureAction,
  IFetchServiceDetailProcessingAction,
  IFetchServiceDetailSuccessAction,
  IFetchServicesBeginAction,
  IFetchServicesFailureAction,
  IFetchServicesSuccessAction,
  ILoadServiceBeginAction,
  ILoadServiceFailureAction,
  ILoadServiceSuccessAction,
  ILockServiceManagementAction,
  IReloadServiceAction,
  IServiceAjaxError,
  IStartServiceLoadingAction,
  IStopServiceLoadingAction,
  IToggleMoreServicesSuccessAction,
  IToggleServiceDetailAction,
} from '../types/service/serviceActionInterfaces';
import { ServiceActionTypes } from '../types/service/serviceActionTypes';
import { IService } from '../types/service/serviceInterface';
import { IServiceState } from '../types/service/serviceStateInterface';
import { updateServiceByCodename, updateServiceItem } from '../utils/services';

type ServiceAction =
  | IFetchServicesBeginAction
  | IFetchServicesSuccessAction
  | IFetchServicesFailureAction
  | IFetchServiceDetailBeginAction
  | IFetchServiceDetailSuccessAction
  | IFetchServiceDetailFailureAction
  | IClearServicesDataAction
  | IToggleServiceDetailAction
  | ILoadServiceBeginAction
  | ILoadServiceSuccessAction
  | ILoadServiceFailureAction
  | IFetchAllServicesBeginAction
  | IFetchAllServicesSuccessAction
  | IFetchAllServicesFailureAction
  | IFetchMoreServicesSuccessAction
  | IToggleMoreServicesSuccessAction
  | IStartServiceLoadingAction
  | IStopServiceLoadingAction
  | IReloadServiceAction
  | IFetchServiceDetailProcessingAction
  | IServiceAjaxError
  | IExpandServiceAfterBssAndWscLoadedBeginAction
  | IExpandServiceAfterBssAndWscLoadedSuccessAction
  | ILockServiceManagementAction;

const servicesReducer: Reducer<IServiceState> = (
  state: IServiceState = initialState.Services.services,
  action: ServiceAction,
) => {
  switch (action.type) {
    case ServiceActionTypes.FETCH_MORE_SERVICES_SUCCESS: {
      return {
        ...state,
        items: state.items.concat(
          action.payload.services.map((service: IService) => {
            // eslint-disable-next-line no-param-reassign
            service.isMoreService = true;
            return service;
          }),
        ),
      };
    }

    case ServiceActionTypes.FETCH_SERVICES_BEGIN: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      };
    }

    case ServiceActionTypes.FETCH_SERVICES_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        items: state.items.concat(
          action.payload.map((service) => {
            // eslint-disable-next-line no-param-reassign
            service.isMoreService = false;
            // eslint-disable-next-line no-param-reassign
            service.detail = null;
            return service;
          }),
        ),
      };
    }

    case ServiceActionTypes.CLEAR_SERVICES_DATA: {
      return {
        ...state,
        loaded: false,
        items: initialState.Services.services.items,
      };
    }

    case ServiceActionTypes.FETCH_SERVICES_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
        items: [],
      };
    }

    case ServiceActionTypes.SERVICE_AJAX_ERROR: {
      return {
        ...state,
        items: updateServiceItem(state.items, action.service.id, (item) => ({
          ...item,
          ajaxError: true,
        })),
      };
    }

    case ServiceActionTypes.SERVICE_LOADING_START: {
      return {
        ...state,
        items: updateServiceItem(state.items, action.service.id, (item) => ({
          ...item,
          loading: true,
        })),
      };
    }

    case ServiceActionTypes.SERVICE_LOADING_RELOAD: {
      return {
        ...state,
        items: updateServiceItem(state.items, action.service.id, (item) => ({
          ...item,
          detail: null,
          isOpen: false,
          loaded: false,
          loading: false,
          error: false,
        })),
      };
    }

    case ServiceActionTypes.SERVICE_LOADING_STOP: {
      return {
        ...state,
        items: updateServiceItem(state.items, action.service.id, (item) => ({
          ...item,
          loading: false,
        })),
      };
    }

    case ServiceActionTypes.FETCH_SERVICES_DETAIL_BEGIN: {
      return {
        ...state,
        items: updateServiceItem(state.items, action.service.id, (item) => ({
          ...item,
          loading: true,
          loaded: true,
          error: false,
          processing: false,
          ajaxError: false,
        })),
      };
    }

    case SectionActionTypes.TOGGLE_MORE_SERVICES_SUCCESS: {
      const updatedItems = state.items.map((item) => {
        if (item.sectionId !== action.section.id) {
          return item;
        }

        return {
          ...item,
          isOpen: false,
        };
      });

      return {
        ...state,
        items: updatedItems,
      };
    }

    case ServiceActionTypes.FETCH_SERVICES_DETAIL_SUCCESS: {
      return {
        ...state,
        items: updateServiceItem(state.items, action.service.id, (item) => ({
          ...item,
          detail: action.detail,
          loading: false,
          loaded: true,
          error: false,
          processing: false,
          ajaxError: false,
        })),
      };
    }

    case ServiceActionTypes.FETCH_SERVICES_DETAIL_PROCESSING: {
      return {
        ...state,
        items: updateServiceItem(state.items, action.service.id, (item) => ({
          ...item,
          loading: false,
          loaded: true,
          error: false,
          processing: true,
          ajaxError: false,
        })),
      };
    }

    case ServiceActionTypes.FETCH_SERVICES_DETAIL_FAILURE: {
      return {
        ...state,
        items: updateServiceItem(state.items, action.service.id, (item) => ({
          ...item,
          loading: false,
          loaded: true,
          error: true,
          processing: false,
          ajaxError: false,
        })),
      };
    }

    case ServiceActionTypes.TOGGLE_SERVICE_DETAIL: {
      return {
        ...state,
        items: updateServiceItem(state.items, action.service.id, (item) => ({
          ...item,
          isOpen: !action.service.isOpen,
        })),
      };
    }

    case ServiceActionTypes.LOCK_SERVICE_MANAGEMENT: {
      return {
        ...state,
        items: updateServiceByCodename(
          state.items,
          action.codename,
          (item) => ({
            ...item,
            isLocked: true,
          }),
        ),
      };
    }

    case ServiceActionTypes.LOAD_SERVICE_BEGIN: {
      return {
        ...state,
        items: updateServiceItem(
          state.items,
          action.payload.service.id,
          (item) => ({
            ...item,
            loading: true,
            loaded: false,
            error: false,
          }),
        ),
      };
    }

    case ServiceActionTypes.LOAD_SERVICE_SUCCESS: {
      return {
        ...state,
        items: updateServiceItem(
          state.items,
          action.payload.service.id,
          (item) => ({
            ...item,
            loading: false,
            loaded: true,
            error: false,
          }),
        ),
      };
    }

    case ServiceActionTypes.LOAD_SERVICE_FAILURE: {
      return {
        ...state,
        items: updateServiceItem(
          state.items,
          action.payload.service.id,
          (item) => ({
            ...item,
            loading: false,
            loaded: false,
            error: true,
          }),
        ),
      };
    }

    case ServiceActionTypes.FETCH_ALL_SERVICES_BEGIN: {
      return {
        ...state,
        allServices: {
          ...state.allServices,
          loading: true,
          error: false,
        },
      };
    }

    case ServiceActionTypes.FETCH_ALL_SERVICES_SUCCESS: {
      return {
        ...state,
        allServices: {
          loading: false,
          loaded: true,
          error: false,
        },
      };
    }

    case ServiceActionTypes.FETCH_ALL_SERVICES_FAILURE: {
      return {
        ...state,
        allServices: {
          loading: false,
          loaded: false,
          error: true,
        },
      };
    }

    case ServiceActionTypes.EXPAND_SERVICE_AFTER_BSS_AND_WSC_LOADED_BEGIN: {
      const updatedItems = state.items.map((item) => {
        if (item.id !== action.service.id) {
          return item;
        }

        return {
          ...item,
          expandAfterBssAndWscLoaded: true,
        };
      });

      return {
        ...state,
        items: updatedItems,
      };
    }

    case ServiceActionTypes.EXPAND_SERVICE_AFTER_BSS_AND_WSC_LOADED_SUCCESS: {
      const updatedItems = state.items.map((item) => {
        if (item.expandAfterBssAndWscLoaded) {
          return {
            ...item,
            expandAfterBssAndWscLoaded: false,
          };
        }
        return item;
      });

      return {
        ...state,
        items: updatedItems,
      };
    }

    default:
      return state;
  }
};

export default servicesReducer;
