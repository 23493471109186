import { useMutation } from '@tanstack/react-query';
import api from 'src/utils/api/api';

export type CpeDeviceFactoryResetResponse = {
  message: string;
};

type CpeDeviceFactoryResetRequest = {
  serviceNumber: string;
  deviceIdentifier: string;
};

export const POST_CPE_DEVICE_FACTORY_RESET =
  '/muj/rapi/cpe/device/factory-reset';
const POST_CPE_DEVICE_FACTORY_RESET_KEY = 'postCpeDeviceRestart';

const postCpeDeviceFactoryReset = async (
  request: CpeDeviceFactoryResetRequest,
): Promise<CpeDeviceFactoryResetResponse> => {
  const { serviceNumber, deviceIdentifier } = request;

  const { data } = await api.post<CpeDeviceFactoryResetResponse>({
    url: POST_CPE_DEVICE_FACTORY_RESET,
    data: {
      serviceNumber,
      deviceIdentifier,
    },
  });

  return data;
};

export const useCpeDeviceFactoryReset = () => {
  return useMutation<
    CpeDeviceFactoryResetResponse,
    void,
    CpeDeviceFactoryResetRequest
  >({
    mutationFn: postCpeDeviceFactoryReset,
    mutationKey: [POST_CPE_DEVICE_FACTORY_RESET_KEY],
  });
};
