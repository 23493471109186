import styled from 'styled-components';

export const SelectWrapper = styled.div`
  position: relative;
`;

export const SelectBase = styled.div<{ disableExpand: boolean }>`
  padding: 20px;
  box-shadow: 0px 6px 12px ${(props) => props.theme.colors.cardBoxShadow};
  border-radius: 6px;
  cursor: ${(props) => (props.disableExpand ? 'default' : 'pointer')};
  background-color: ${(props) => props.theme.colors.white};
  width: 100%;
`;

export const LabelWrapper = styled.span`
  margin-left: 20px;
  text-align: left;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: clip;
  margin-right: 20px;
  flex: 1;
`;

export const SelectContentWrapper = styled.div`
  position: absolute;
  top: auto;
  left: 0;
  right: 0;
  z-index: 1001;
`;

export const SelectContent = styled.div`
  margin-top: 8px;
  padding: 20px 32px;
  padding-right: 16px;
  box-shadow: 0px 6px 12px ${(props) => props.theme.colors.cardBoxShadow};
  border-radius: 6px;
  background-color: ${(props) => props.theme.colors.white};
`;

export const SelectContentScroller = styled.div`
  overflow-y: auto;
  max-height: 500px;
`;
