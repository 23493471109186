import { ILinkProps, Link } from '@vodafone/red-white';
import * as React from 'react';
import styled from 'styled-components';

export const HorizontalLine = styled.hr`
  border-bottom: 0;
  border-top: 1px solid ${(props) => props.theme.colors.gray10};
  margin-top: 40px;
  margin-bottom: 20px;
`;

interface ITextWrapperProps {
  fontSize: string;
  linkColor: string;
}

export const Wrapper: React.FCC<ITextWrapperProps> = ({
  fontSize,
  linkColor,
}) => {
  return (
    <TextWrapper fontSize={fontSize}>
      <ChildLink linkColor={linkColor} />
    </TextWrapper>
  );
};

export const TextWrapper = styled.div<{ fontSize: string }>`
  font-size: ${(props) => props.fontSize};
  margin-bottom: 20px;
  @media only screen and (max-width: 560px) {
    margin-bottom: 0;
    margin-top: -5px;
  }
`;

export const StyledLink = styled(Link)<ILinkProps>`
  color: ${(props) => props.theme.colors.gray60};
`;

export const ChildLink = styled(Link)<{ linkColor?: string }>`
  color: ${(props) => props.linkColor};
  transition: color 250ms;
  &:hover {
    color: ${(props) => props.theme.colors.black};
  }
`;
