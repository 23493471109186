import { Button, IconWarning, ModalMessage } from '@vodafone/red-white';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'src/hooks/useAppDispatch';
import { getMnpPenalty } from 'src/store/mnp/penalty/npmPenaltySelector';
import IMnpPenaltyState from 'src/store/mnp/penalty/types/mnpPenaltyStateInterface';
import {
  openMnpServiceDetailModal,
  openSmsVerificationModal,
} from 'src/store/mnp/serviceDetail/mnpServiceDetailActions';
import { getMnpServiceDetail } from 'src/store/mnp/serviceDetail/mnpServiceDetailSelectors';
import IMnpServiceDetailState from 'src/store/mnp/serviceDetail/types/mnpServiceDetailStateInterface';
import MnpServiceDetailTypeEnum from 'src/store/mnp/serviceDetail/types/MnpServiceDetailTypeEnum';
import { selectMnpService } from 'src/store/services/selectors/serviceSelectors';
import { IService } from 'src/store/services/types/service/serviceInterface';
import ISubscription from 'src/store/user/types/subscriptionInterface';
import SubscriptionTypeEnum from 'src/store/user/types/SubscriptionTypeEnum';
import {
  selectCurrentSubscription,
  selectIsBmsl,
  selectSubscriptions,
} from 'src/store/user/userSelector';
import SanitizedHtml from '../../../utils/sanitizeHtml';
import { ModalContentProps } from '../types/modalContentInterfaces';

const MnpPenaltyModalContent: FC<ModalContentProps> = ({ onClose }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation('services');
  const service: IService = useSelector(selectMnpService);
  const { serviceNumber, type } = useSelector(selectCurrentSubscription);
  const isGsmSubscriber: boolean = type === SubscriptionTypeEnum.GSM;
  const penalty: IMnpPenaltyState = useSelector(getMnpPenalty);
  const isBmsl = useSelector(selectIsBmsl);
  const subscriptions: ISubscription[] = useSelector(selectSubscriptions);
  const { mnpType }: IMnpServiceDetailState = useSelector(getMnpServiceDetail);

  const skipSmsVerification = (): boolean =>
    mnpType === MnpServiceDetailTypeEnum.CVOP ||
    !isGsmSubscriber ||
    (isBmsl && subscriptions.length >= 20);

  const onLeaveButtonClick = () => {
    onClose();
    if (skipSmsVerification()) {
      dispatch(openMnpServiceDetailModal(service, mnpType, serviceNumber));
    } else {
      dispatch(openSmsVerificationModal(service, serviceNumber));
    }
  };

  const onDoNotLeaveButtonClick = () => {
    onClose();
  };

  const onAlternativeButtonClick = () => {
    window.location.href = penalty.data.action;
    onClose();
  };

  const actionButton = penalty.data.isAlternative ? (
    <Button
      style={{ marginRight: '20px' }}
      htmlElement="button"
      variant="primary"
      onClick={onAlternativeButtonClick}
    >
      {t('services:mnpPenalty.modal.interested-in-offer')}
    </Button>
  ) : (
    <Button
      style={{ marginRight: '20px' }}
      htmlElement="button"
      variant="primary"
      onClick={onDoNotLeaveButtonClick}
    >
      {t('services:mnpPenalty.modal.do-not-leave')}
    </Button>
  );

  return (
    <>
      <ModalMessage
        heading={t('services:mnpPenalty.modal.title')}
        icon={<IconWarning color="red" size={60} />}
        color="red"
      >
        &nbsp;
      </ModalMessage>
      <>
        <SanitizedHtml
          htmlTag="p"
          rawHtmlData={penalty.data.htmlContent}
          style={{ textAlign: 'center' }}
        />

        {penalty.data.hasInstallment && (
          <SanitizedHtml
            htmlTag="p"
            rawHtmlData={t('services:mnpPenalty.modal.has-installment')}
            style={{ textAlign: 'center' }}
          />
        )}
        <p style={{ textAlign: 'center' }}>
          {actionButton}
          <Button
            htmlElement="button"
            variant="secondary"
            onClick={onLeaveButtonClick}
          >
            {t('services:mnpPenalty.modal.leave')}
          </Button>
        </p>
      </>
    </>
  );
};

export default MnpPenaltyModalContent;
