import api from 'src/utils/api/api';
import {
  API_ENDPOINT_SERVICE_NUMBER_SWITCHER_CHECK_UNCONFIRMED_BASKET_CHANGES,
  API_ENDPOINT_SERVICE_NUMBER_SWITCHER_DISCARD_BASKET_CHANGES,
  API_ENDPOINT_SERVICE_NUMBER_SWITCHER_SWITCH_SUBSCRIPTION,
} from '../index';
import { IServiceNumberSwitcherCheckUnconfirmedBasketChangesResponse } from './response/serviceNumberSwitcherResponseInterface';

export const getUnconfirmedChanges = (serviceNumber: string) =>
  api.get<IServiceNumberSwitcherCheckUnconfirmedBasketChangesResponse>({
    url: API_ENDPOINT_SERVICE_NUMBER_SWITCHER_CHECK_UNCONFIRMED_BASKET_CHANGES,
    query: {
      type: 'switcher',
      serviceNumber2: serviceNumber,
    },
  });

export const discardBasketChanges = () =>
  api.get({ url: API_ENDPOINT_SERVICE_NUMBER_SWITCHER_DISCARD_BASKET_CHANGES });

export const switchSubscriber = (data: FormData) =>
  api.post({
    url: API_ENDPOINT_SERVICE_NUMBER_SWITCHER_SWITCH_SUBSCRIPTION,
    data,
  });
