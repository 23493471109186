import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getUser } from 'src/store/user/userSelector';
import { createIdaasLoginUrl } from 'src/utils/login';

export const GOLD_TOKEN = 'urn:vodafone:loa:gold';
export const SILVER_TOKEN = 'urn:vodafone:loa:silver';

export const useGoldTokenGuard = () => {
  const user = useSelector(getUser);
  const path = useLocation();
  const idaasLoginNextUrl = createIdaasLoginUrl({
    username: user.username,
    redirectUrl: `${window.location.origin}${path.pathname}`,
    queryParams: {
      upgrade_level: true,
    },
  });

  const hasGoldToken = user.tokenLevel === GOLD_TOKEN;

  const canRequestGoldToken = false;

  const verifyWithIdaas = () => {
    window.open(idaasLoginNextUrl.toString(), '_self');
  };

  return {
    hasGoldToken,
    verifyWithIdaas,
    canRequestGoldToken,
  };
};
