import { createSelector, Selector } from 'reselect';
import { ServiceCodeNameEnum } from 'src/components';
import { ESimServiceState } from 'src/types/services/esimServiceStateEnum';
import { IApplicationState } from '../../appStateInterface';
import { ISection } from '../types/section/sectionInterface';
import { IService } from '../types/service/serviceInterface';

export const getAllActiveServices: Selector<IApplicationState, IService[]> = (
  state: IApplicationState,
) => state.Services.services.items;

export const selectServicesBySection = (
  state: IApplicationState,
  section: ISection,
) =>
  createSelector([getAllActiveServices], (allServices) => {
    return allServices
      .filter((item) => {
        if (item.eligibility === null) {
          return false;
        }
        if (item.groupId) {
          return false;
        }
        return (
          item.sectionId === section.id &&
          item.eligibility.visible &&
          ((!section.isMoreItemsVisible && !item.isMoreService) ||
            section.isMoreItemsVisible)
        );
      })
      .sort((a, b) => a.order - b.order);
  })(state);

export const hasServicesInGroups = (
  state: IApplicationState,
  groups: string[],
): boolean =>
  createSelector([getAllActiveServices], (allServices) => {
    if (!groups) {
      return false;
    }

    const services = [];

    (groups || []).forEach((groupId: string) => {
      allServices.filter((item) => {
        if (item.eligibility === null) {
          return false;
        }
        if (item.groupId === groupId && item.eligibility.visible) {
          services.push(item);
        }
        return item.groupId === groupId && item.eligibility.visible;
      });
    });

    return services.length > 0;
  })(state);

export const selectServicesByGroupId = (
  state: IApplicationState,
  groupId: string,
) =>
  createSelector([getAllActiveServices], (allServices) => {
    return allServices
      .filter((item) => {
        if (item.eligibility === null) {
          return false;
        }
        return item.groupId === groupId && item.eligibility.visible;
      })
      .sort((a, b) => a.order - b.order);
  })(state);

export const selectMoreServicesInSection = (
  state: IApplicationState,
  group: ISection,
) =>
  createSelector([getAllActiveServices], (allServices) => {
    return allServices.filter((item) => {
      return (
        item.sectionId === group.id &&
        item.eligibility.visible &&
        item.isMoreService
      );
    });
  })(state);

export const selectMnpService: Selector<IApplicationState, IService> =
  createSelector([getAllActiveServices], (allServices) =>
    allServices.find((service) => service.codeName === ServiceCodeNameEnum.MNP),
  );

export const selectESimReadyToActivate: Selector<IApplicationState, IService> =
  createSelector([getAllActiveServices], (allServices) =>
    allServices.find(
      (service) =>
        service.data?.esim?.state === ESimServiceState.READY_FOR_DOWNLOAD,
    ),
  );

export const selectServiceByCodename = (codename: string) =>
  createSelector([getAllActiveServices], (allServices) =>
    allServices.find((service) => service.codeName === codename),
  );
