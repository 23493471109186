import initialState from '../initialState';
import {
  IChangeDefaultRoleBeginAction,
  IChangeDefaultRoleFailureAction,
  IChangeDefaultRoleSuccessAction,
} from './types/changeDefaultRoleActionInterface';
import {
  IDeleteProfileBeginAction,
  IDeleteProfileFailureAction,
  IDeleteProfileSuccessAction,
} from './types/deleteProfileActionInterface';
import {
  IFetchLargeBillingAccountsBeginAction,
  IFetchLargeBillingAccountsFailureAction,
  IFetchLargeBillingAccountsSuccessAction,
} from './types/fetchLargeBillingAccountsActionInterface';
import {
  IFetchLargeSubscribersBeginAction,
  IFetchLargeSubscribersFailureAction,
  IFetchLargeSubscribersSuccessAction,
} from './types/fetchLargeSubscribersActionInterface';
import { ILogoutUserAction } from './types/logoutUserActionInterface';
import {
  IFetchSessionBeginAction,
  IFetchSessionFailureAction,
  IFetchSessionSuccessAction,
  ISwitchBillingAccountAction,
  IUpdateContactNumberAction,
} from './types/sessionActionInterfaces';
import { SessionActionTypes } from './types/sessionActionTypes';
import IUserState from './types/userStateInterface';

type ActionType =
  | ILogoutUserAction
  | IFetchSessionBeginAction
  | IFetchSessionSuccessAction
  | IFetchSessionFailureAction
  | IFetchLargeSubscribersBeginAction
  | IFetchLargeSubscribersSuccessAction
  | IFetchLargeSubscribersFailureAction
  | IChangeDefaultRoleBeginAction
  | IChangeDefaultRoleSuccessAction
  | IChangeDefaultRoleFailureAction
  | IDeleteProfileBeginAction
  | IDeleteProfileSuccessAction
  | IDeleteProfileFailureAction
  | IFetchLargeBillingAccountsBeginAction
  | IFetchLargeBillingAccountsSuccessAction
  | IFetchLargeBillingAccountsFailureAction
  | ISwitchBillingAccountAction
  | IUpdateContactNumberAction;

export default (state: IUserState = initialState.User, action: ActionType) => {
  switch (action.type) {
    case SessionActionTypes.FETCH_SESSION_SUCCESS: {
      return {
        ...state,
        signedIn: true,
        username: action.payload.session.username,
        email: action.payload.session.email,
        authorizationRoleName: action.payload.session.authorizationRoleName,
        customerAccountContactPhone:
          action.payload.session.customerAccountContactPhone,
        paymentType: action.payload.session.paymentType,
        prepaidType: action.payload.session.prepaidType,
        isPersonalAccount: action.payload.session.isPersonalAccount,
        actualGrantedRole: action.payload.session.actualGrantedRole,
        grantedRoles: action.payload.session.grantedRoles,
        defaultRoleId: action.payload.session.defaultRoleId,
        subscription: action.payload.session.subscription,
        subscriptions: action.payload.session.subscriptions,
        caNumber: action.payload.session.caNumber,
        segment: action.payload.session.segment,
        billingAccount: action.payload.session.billingAccount,
        billingAccounts: action.payload.session.billingAccounts,
        isLargeAccount: action.payload.session.isLargeAccount,
        isMegaLargeAccount: action.payload.session.isMegaLargeAccount,
        isWebView: action.payload.session.isWebView,
        roles: action.payload.session.roles,
        globalPageData: action.payload.session.globalPageData,
        vfIdEmail: action.payload.session.vfIdEmail,
        vfIdMsisdn: action.payload.session.vfIdMsisdn,
        tokenExpiresIn: action.payload.session.tokenExpiresIn,
        tokenLevel: action.payload.session.tokenLevel,
        loaded: true,
        loading: false,
        error: false,
      };
    }

    case SessionActionTypes.FETCH_SESSION_BEGIN: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      };
    }

    case SessionActionTypes.FETCH_SESSION_FAILURE: {
      return {
        ...state,
        signedIn: false,
        loading: false,
        loaded: true,
        error: true,
      };
    }

    case SessionActionTypes.USER_LOGOUT: {
      return {
        ...state,
        signedIn: false,
      };
    }

    case SessionActionTypes.CHANGE_DEFAULT_ROLE_SUCCESS: {
      return {
        ...state,
        defaultRoleId: action.payload.defaultRoleId,
        loading: false,
      };
    }

    case SessionActionTypes.CHANGE_DEFAULT_ROLE_BEGIN: {
      return {
        ...state,
        loading: true,
      };
    }

    case SessionActionTypes.CHANGE_DEFAULT_ROLE_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    case SessionActionTypes.DELETE_PROFILE_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }

    case SessionActionTypes.DELETE_PROFILE_BEGIN: {
      return {
        ...state,
        loading: true,
      };
    }

    case SessionActionTypes.DELETE_PROFILE_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }

    case SessionActionTypes.FETCH_LARGE_SUBSCRIBERS_BEGIN: {
      return {
        ...state,
        largeSubscribers: {
          ...state.largeSubscribers,
          loaded: false,
          loading: true,
          error: false,
        },
      };
    }

    case SessionActionTypes.FETCH_LARGE_SUBSCRIBERS_SUCCESS: {
      return {
        ...state,
        loading: false,
        largeSubscribers: {
          ...state.largeSubscribers,
          loaded: true,
          loading: false,
          error: false,
          data: {
            ...state.largeSubscribers.data,
            [action.caNumber]: action.payload.subscribers,
          },
        },
      };
    }

    case SessionActionTypes.FETCH_LARGE_SUBSCRIBERS_FAILURE: {
      return {
        ...state,
        loading: false,
        largeSubscribers: {
          ...state.largeSubscribers,
          loaded: true,
          loading: false,
          error: true,
          errorMessage: action.payload.description,
          errorStatusCode: action.payload.code,
        },
      };
    }

    case SessionActionTypes.FETCH_LARGE_BILLING_ACCOUNTS_BEGIN: {
      return {
        ...state,
        largeBillingAccountsLoading: true,
      };
    }

    case SessionActionTypes.FETCH_LARGE_BILLING_ACCOUNTS_SUCCESS: {
      return {
        ...state,
        loading: false,
        largeBillingAccounts: {
          ...state.largeBillingAccounts,
          [action.caNumber]: action.payload.billingAccounts,
        },
        largeBillingAccountsLoading: false,
      };
    }

    case SessionActionTypes.FETCH_LARGE_BILLING_ACCOUNTS_FAILURE: {
      return {
        ...state,
        largeBillingAccountsLoading: false,
      };
    }

    case SessionActionTypes.SWITCH_BILLING_ACCOUNT: {
      return {
        ...state,
        billingAccount: action.payload,
      };
    }

    case SessionActionTypes.UPDATE_CONTACT_NUMBER: {
      return {
        ...state,
        customerAccountContactPhone: action.payload,
      };
    }

    default:
      return state;
  }
};
