import { Reducer } from 'redux';
import initialState from '../initialState';
import {
  IFetchFaqsBeginAction,
  IFetchFaqsFailureAction,
  IFetchFaqsSuccessAction,
} from './types/faqsActionInterfaces';
import { FaqsActionTypes } from './types/faqsActionTypes';
import { IFaqsState } from './types/faqsStateInterface';

type FaqsAction =
  | IFetchFaqsBeginAction
  | IFetchFaqsSuccessAction
  | IFetchFaqsFailureAction;

export const faqsReducer: Reducer<IFaqsState> = (
  state: IFaqsState = initialState.Faqs,
  action: FaqsAction,
) => {
  switch (action.type) {
    case FaqsActionTypes.FETCH_FAQS_BEGIN:
      return {
        ...state,
        loading: true,
        loaded: false,
      };

    case FaqsActionTypes.FETCH_FAQS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        data: {
          ...state.data,
          [action.key]: action.payload,
        },
      };

    case FaqsActionTypes.FETCH_FAQS_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };

    default:
      return state;
  }
};
