import {
  Col,
  IconGroupAdmin,
  IconSearch,
  Input,
  Row,
  Text,
} from '@vodafone/red-white';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'src/hooks/useAppDispatch';
import { switchSubscriber } from 'src/store/serviceNumberSwitcher/serviceNumberSwitcherActions';
import IUserState from 'src/store/user/types/userStateInterface';
import userSelector from 'src/store/user/userSelector';
import PayerSelect from 'src/ui/components/PayerSelect';
import styled from 'styled-components';
import { formatServiceNumberBySubscriptionType } from '../../../utils/serviceNumberFormatter';

const Wrapper = styled.div`
  max-width: 471px;
  margin: 0 auto;
`;

const SearchInputWrapper = styled.div`
  margin-bottom: 24px;
`;

const NoResults = styled.div`
  margin-top: 12px;
`;

const ServiceSwitcher: React.FC = () => {
  const [search, setSearch] = React.useState('');
  const dispatch = useAppDispatch();
  const { t } = useTranslation('billing');

  const user: IUserState = useSelector(userSelector);

  const findSubscriptionById = (subscriptionId: string) => {
    return user.subscriptions.find(
      (subscription) => subscription.subscriptionId === subscriptionId,
    );
  };

  const switcherData = user.subscriptions.map((item) => ({
    value: item.subscriptionId,
    label: formatServiceNumberBySubscriptionType(
      item.serviceNumber,
      findSubscriptionById(item.subscriptionId).type,
    ),
    labelDesc: item.nickname,
  }));

  const resolveActiveItem = () => {
    try {
      return {
        value: user.subscription.subscriptionId,
        label: formatServiceNumberBySubscriptionType(
          user.subscription.serviceNumber,
          findSubscriptionById(user.subscription.subscriptionId).type,
        ),
        labelDesc: user.subscription.nickname,
      };
    } catch (e) {
      return {
        value: '',
        label: '',
        labelDesc: '',
      };
    }
  };

  const switchSubscription = (item) => {
    dispatch(
      switchSubscriber({
        msisdn: findSubscriptionById(item.value).serviceNumber,
        useParam: false,
        refresh: true,
      }),
    );
  };

  const getData = () => {
    const filteredData =
      search &&
      switcherData.filter(
        (x) =>
          x.label.toLowerCase().includes(search) ||
          x.labelDesc.toLowerCase().includes(search),
      );

    if (search && filteredData.length === 0) {
      return [];
    }

    return search ? filteredData : switcherData;
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const data = React.useMemo(() => getData(), [search, switcherData]);

  const switcherAddon = () => {
    return (
      <SearchInputWrapper>
        <Input
          id="payer-select-search"
          autoFocus
          icon={<IconSearch />}
          label={t('billing:switcher:label')}
          labelAsPlaceholder
          onChange={(e) => setSearch(e.target.value.toLowerCase())}
          value={search}
        />
        {data.length === 0 && (
          <NoResults>
            <Text fontSize={16} color="darkGrey">
              {t('billing:switcher:no-results')}
            </Text>
          </NoResults>
        )}
      </SearchInputWrapper>
    );
  };

  return (
    <Row gutter={16} justify="center">
      <Col xs={24} dataAutomationId="other-functions-box">
        <Wrapper>
          <PayerSelect
            data={data}
            activeItem={resolveActiveItem()}
            onChange={switchSubscription}
            onClose={() => setSearch('')}
            icon={<IconGroupAdmin size={30} />}
            addon={switcherAddon}
          />
        </Wrapper>
      </Col>
    </Row>
  );
};

export default ServiceSwitcher;
