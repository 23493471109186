import { Link } from '@vodafone/red-white';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import CardReceivedPaymentsResult from 'src/ui/components/CardReceivedPaymentsResult';
import styled from 'styled-components';

const ExtraLink = styled(Link)`
  font-size: 16px;
  color: ${(props) => props.theme.colors.darkGrey};
`;

interface IProps {
  paymentId: string;
  close: () => void;
}

const PaymentStatusSuccess: React.FC<IProps> = ({ close }) => {
  const { t } = useTranslation('billing');

  return (
    <CardReceivedPaymentsResult
      status="success"
      title={t('billing:paymentStatus.title-success')}
      subTitle={t('billing:paymentStatus.subtitle-success')}
      extra={[
        <ExtraLink onClick={close}>
          {t('billing:paymentStatus.extra-link-success')}
        </ExtraLink>,
      ]}
    />
  );
};

export default PaymentStatusSuccess;
