import { ActionCreator } from 'redux';
import { getUpcInvoiceDownloadUrl, getUpcInvoices } from 'src/api/billing';
import {
  IFetchUpcInvoicesBeginAction,
  IFetchUpcInvoicesFailureAction,
  IFetchUpcInvoicesSuccessAction,
} from './types/upcInvoiceActionInterfaces';
import UpcInvoiceActionTypes from './types/upcInvoiceActionTypes';

export const fetchUpcInvocesBeginAction: ActionCreator<
  IFetchUpcInvoicesBeginAction
> = () => ({
  type: UpcInvoiceActionTypes.FETCH_UPC_INVOICE_LIST_BEGIN,
});

export const fetchUpcInvocesSuccessAction: ActionCreator<
  IFetchUpcInvoicesSuccessAction
> = (payload) => ({
  type: UpcInvoiceActionTypes.FETCH_UPC_INVOICE_LIST_SUCCESS,
  payload,
});

export const fetchUpcInvocesFailureAction: ActionCreator<
  IFetchUpcInvoicesFailureAction
> = () => ({
  type: UpcInvoiceActionTypes.FETCH_UPC_INVOICE_LIST_FAILURE,
});

export const fetchUpcInvoces = (variableSymbol: string) => (dispatch) => {
  dispatch(fetchUpcInvocesBeginAction());
  getUpcInvoices(variableSymbol)
    .then((response) => {
      dispatch(fetchUpcInvocesSuccessAction(response.data));
    })
    .catch(() => {
      dispatch(fetchUpcInvocesFailureAction());
    });
};

// download invoice
export const downloadUpcInvoice =
  (variableSymbol: string, invoiceId: string) => () => {
    window.open(getUpcInvoiceDownloadUrl(variableSymbol, invoiceId), '_blank');
  };
