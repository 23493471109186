import * as React from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getRoute, RoutesEnum } from 'src/config/routes';
import { ENV_MODE, PUBLIC_URL } from 'src/env';
import selectLanguage from 'src/store/language/languageSelector';
import { LanguagesEnum } from 'src/types/translation/languagesEnum';

const SERVICES_SCRIPTS = ['/static/services/minified-service-page-scripts.js'];
const SERVICES_STYLES = ['/static/services/services.css'];

const SERVICES_SCRIPTS_ROUTES = [
  RoutesEnum.ACTIVE_SERVICES_ROUTE_ID,
  RoutesEnum.BILLING_ROUTE_ID,
];

const SERVICES_STYLES_ROUTES = [
  RoutesEnum.ACTIVE_SERVICES_ROUTE_ID,
  RoutesEnum.SERVICES_SUBSCRIPTIONS_OVERVIEW,
];

const MetaDataDecorator: React.FCC = () => {
  const language: LanguagesEnum = useSelector(selectLanguage);
  const publicUrl = PUBLIC_URL as string;
  const location = useLocation();
  const route = getRoute(location.pathname);

  return (
    <>
      <Helmet htmlAttributes={{ lang: language }}>
        {ENV_MODE === 'production' && (
          <script
            async={true}
            type="text/javascript"
            src={'/public/omniture/s_code.js'}
          ></script>
        )}
        {route &&
          SERVICES_SCRIPTS_ROUTES.includes(route.id) &&
          SERVICES_SCRIPTS.map((path) => (
            <script
              key={path}
              type="text/javascript"
              src={`${publicUrl}${path}`}
            ></script>
          ))}
        {route &&
          SERVICES_STYLES_ROUTES.includes(route.id) &&
          SERVICES_STYLES.map((path) => (
            <link
              key={path}
              rel="stylesheet"
              href={`${publicUrl}${path}`}
              type="text/css"
            />
          ))}
      </Helmet>
    </>
  );
};

export default MetaDataDecorator;
