import { AxiosResponse } from 'axios';
import { ActionCreator } from 'redux';
import eSimActivateSimOrESimRequest from 'src/api/esim/request/eSimActivateSimOrESimRequest';
import eSimChangeSmsForkingSettingsRequest from 'src/api/esim/request/eSimChangeSmsForkingSettingsRequest';
import eSimDeliveryRequest from 'src/api/esim/request/eSimDeliveryRequest';
import eSimDownloadQRCodeRequest from 'src/api/esim/request/eSimDownloadQRCodeRequest';
import eSimFetchDetailRequest from 'src/api/esim/request/eSimFetchDetailRequest';
import eSimOneNumberOneNumbersRequest from 'src/api/esim/request/eSimOneNumberOneNumbersRequest';
import eSimOneNumberPortalLinkRequest from 'src/api/esim/request/eSimOneNumberPortalLinkRequest';
import eSimSimSwapRequest from 'src/api/esim/request/eSimSimSwapRequest';
import { ESimSimSwapRequestTypeEnum } from 'src/api/esim/request/eSimSimSwapTypeEnum';
import IESimFetchDetailSuccessResponse from 'src/api/esim/response/eSimFetchDetailSuccessResponse';
import IESimOneNumberOneNumbersResponse from 'src/api/esim/response/eSimOneNumberOneNumbersResponse';
import IESimOneNumberPortalLinkResponse from 'src/api/esim/response/eSimOneNumberPortalLinkResponse';
import { IRestResponseFault } from 'src/api/types/IRestResponseFault';
import { IRestResponseSuccess } from 'src/api/types/IRestResponseSuccess';
import { ServiceCodeNameEnum } from 'src/components';
import {
  IESimActivateSimOrESimBeginAction,
  IESimActivateSimOrESimFailureAction,
  IESimActivateSimOrESimResetStoreAction,
  IESimActivateSimOrESimSuccessAction,
  IESimChangeSmsForkSettingsBeginAction,
  IESimChangeSmsForkSettingsFailureAction,
  IESimChangeSmsForkSettingsResetStoreAction,
  IESimChangeSmsForkSettingsSuccessAction,
  IESimDeliveryBeginAction,
  IESimDeliveryFailureAction,
  IESimDeliveryResetStoreAction,
  IESimDeliverySuccessAction,
  IESimDetailResetStoreAction,
  IESimDownloadQRCodeBeginAction,
  IESimDownloadQRCodeFailureAction,
  IESimDownloadQRCodeSuccessAction,
  IESimFetchDetailBeginAction,
  IESimFetchDetailFailureAction,
  IESimFetchDetailSuccessAction,
  IESimFetchOnNumberOneNumbersBeginAction,
  IESimFetchOnNumberOneNumbersFailureAction,
  IESimFetchOnNumberOneNumbersSuccessAction,
  IESimOnNumberPortalLinkFetchBeginAction,
  IESimOnNumberPortalLinkFetchFailureAction,
  IESimOnNumberPortalLinkFetchSuccessAction,
  IESimQRCodeResetStoreAction,
  IESimSimSwapBeginAction,
  IESimSimSwapFailureAction,
  IESimSimSwapResetStoreAction,
  IESimSimSwapSuccessAction,
} from 'src/store/esim/types/esimActionInterfaces';
import ESimActionTypes from 'src/store/esim/types/esimActionTypes';
import { updateModalAnalyticsOptions } from 'src/store/modal/modalActions';
import { lockServiceManagement } from 'src/store/services/actions/serviceActions';

export const eSimSimSwapBegin: ActionCreator<IESimSimSwapBeginAction> = (
  eId: boolean,
) => ({
  type: ESimActionTypes.ESIM_SIM_SWAP_BEGIN,
  eId,
});

export const eSimSimSwapSuccess: ActionCreator<IESimSimSwapSuccessAction> = (
  payload: IRestResponseSuccess,
) => ({
  type: ESimActionTypes.ESIM_SIM_SWAP_SUCCESS,
  payload,
});

export const eSimSimSwapFailure: ActionCreator<IESimSimSwapFailureAction> = (
  payload: IRestResponseFault,
) => ({
  type: ESimActionTypes.ESIM_SIM_SWAP_FAILURE,
  payload,
});

export const eSimSimSwap =
  (serviceNumber: string, type: ESimSimSwapRequestTypeEnum, eId?: string) =>
  (dispatch) => {
    dispatch(eSimSimSwapBegin(!!eId));

    return eSimSimSwapRequest(serviceNumber, type, eId)
      .then(
        (
          response: AxiosResponse<IRestResponseSuccess | IRestResponseFault>,
        ) => {
          dispatch(lockServiceManagement(ServiceCodeNameEnum.ESIM_ORDER));
          dispatch(eSimSimSwapSuccess(response.data));
        },
      )
      .catch((response: AxiosResponse<IRestResponseFault>) => {
        dispatch(eSimSimSwapFailure(response.data));
        dispatch(
          updateModalAnalyticsOptions({
            prop16AdditionalInfo: `error ${
              (response as any)?.response?.status || ''
            }`,
          }),
        );
      });
  };

export const eSimSimSwapResetStore: ActionCreator<
  IESimSimSwapResetStoreAction
> = () => ({
  type: ESimActionTypes.ESIM_SIM_SWAP_RESET_STORE,
});

export const eSimDeliveryBegin: ActionCreator<IESimDeliveryBeginAction> = (
  eId: boolean,
) => ({
  type: ESimActionTypes.ESIM_DELIVERY_BEGIN,
  eId,
});

export const eSimDeliverySuccess: ActionCreator<IESimDeliverySuccessAction> = (
  payload: IRestResponseSuccess,
) => ({
  type: ESimActionTypes.ESIM_DELIVERY_SUCCESS,
  payload,
});

export const eSimDeliveryFailure: ActionCreator<IESimDeliveryFailureAction> = (
  payload: IRestResponseFault,
) => ({
  type: ESimActionTypes.ESIM_DELIVERY_FAILURE,
  payload,
});

export const eSimDelivery =
  (serviceNumber: string, eId?: string) => (dispatch) => {
    dispatch(eSimDeliveryBegin(!!eId));

    return eSimDeliveryRequest(serviceNumber, eId)
      .then(
        (
          response: AxiosResponse<IRestResponseSuccess | IRestResponseFault>,
        ) => {
          dispatch(lockServiceManagement(ServiceCodeNameEnum.ACTIVE_ESIM));
          dispatch(eSimDeliverySuccess(response.data));
        },
      )
      .catch((response: AxiosResponse<IRestResponseFault>) => {
        dispatch(eSimDeliveryFailure(response.data));
      });
  };

export const eSimDeliveryResetStore: ActionCreator<
  IESimDeliveryResetStoreAction
> = () => ({
  type: ESimActionTypes.ESIM_DELIVERY_RESET_STORE,
});

export const eSimFetchDetailBegin: ActionCreator<
  IESimFetchDetailBeginAction
> = () => ({
  type: ESimActionTypes.ESIM_FETCH_DETAIL_BEGIN,
});

export const eSimFetchDetailSuccess: ActionCreator<
  IESimFetchDetailSuccessAction
> = (payload: IESimFetchDetailSuccessResponse) => ({
  type: ESimActionTypes.ESIM_FETCH_DETAIL_SUCCESS,
  payload,
});

export const eSimFetchDetailFailure: ActionCreator<
  IESimFetchDetailFailureAction
> = (payload: IRestResponseFault) => ({
  type: ESimActionTypes.ESIM_FETCH_DETAIL_FAILURE,
  payload,
});

export const eSimFetchDetail = (serviceNumber: string) => async (dispatch) => {
  dispatch(eSimFetchDetailBegin());

  return eSimFetchDetailRequest(serviceNumber)
    .then(
      (
        response: AxiosResponse<
          IESimFetchDetailSuccessResponse | IRestResponseFault
        >,
      ) => {
        dispatch(eSimFetchDetailSuccess(response.data));
      },
    )
    .catch((response: AxiosResponse<IRestResponseFault>) => {
      dispatch(eSimFetchDetailFailure(response.data));
    });
};

export const eSimDetailResetStore: ActionCreator<
  IESimDetailResetStoreAction
> = () => ({
  type: ESimActionTypes.ESIM_DETAIL_RESET_STORE,
});

export const eSimDownloadQRCodeBegin: ActionCreator<
  IESimDownloadQRCodeBeginAction
> = () => ({
  type: ESimActionTypes.ESIM_DOWNLOAD_QR_CODE_BEGIN,
});

export const eSimDownloadQRCodeSuccess: ActionCreator<
  IESimDownloadQRCodeSuccessAction
> = (payload: string) => ({
  type: ESimActionTypes.ESIM_DOWNLOAD_QR_CODE_SUCCESS,
  payload,
});

export const eSimDownloadQRCodeFailure: ActionCreator<
  IESimDownloadQRCodeFailureAction
> = (payload: IRestResponseFault) => ({
  type: ESimActionTypes.ESIM_DOWNLOAD_QR_CODE_FAILURE,
  payload,
});

export const eSimDownloadQRCode = (serviceNumber: string) => (dispatch) => {
  dispatch(eSimDownloadQRCodeBegin());

  return eSimDownloadQRCodeRequest(serviceNumber)
    .then((response: AxiosResponse<string | IRestResponseFault>) => {
      dispatch(eSimDownloadQRCodeSuccess(response.data));
    })
    .catch((response: AxiosResponse<IRestResponseFault>) => {
      dispatch(eSimDownloadQRCodeFailure(response.data));
    });
};

export const eSimQRCodeResetStore: ActionCreator<
  IESimQRCodeResetStoreAction
> = () => ({
  type: ESimActionTypes.ESIM_QR_CODE_RESET_STORE,
});

export const eSimActivateSimOrESimBegin: ActionCreator<
  IESimActivateSimOrESimBeginAction
> = () => ({
  type: ESimActionTypes.ESIM_ACTIVATE_SIM_OR_ESIM_BEGIN,
});

export const eSimActivateSimOrESimSuccess: ActionCreator<
  IESimActivateSimOrESimSuccessAction
> = (payload: IRestResponseSuccess) => ({
  type: ESimActionTypes.ESIM_ACTIVATE_SIM_OR_ESIM_SUCCESS,
  payload,
});

export const eSimActivateSimOrESimFailure: ActionCreator<
  IESimActivateSimOrESimFailureAction
> = (payload: IRestResponseFault) => ({
  type: ESimActionTypes.ESIM_ACTIVATE_SIM_OR_ESIM_FAILURE,
  payload,
});

export const activateSimORESim =
  (serviceNumber: string, iccid: string) => (dispatch) => {
    dispatch(eSimActivateSimOrESimBegin());

    return eSimActivateSimOrESimRequest(serviceNumber, iccid)
      .then(
        (
          response: AxiosResponse<IRestResponseSuccess | IRestResponseFault>,
        ) => {
          dispatch(eSimActivateSimOrESimSuccess(response.data));
        },
      )
      .catch((response: AxiosResponse<IRestResponseFault>) => {
        dispatch(eSimActivateSimOrESimFailure(response.data));
      });
  };

export const eSimActivateSimOrESimResetStore: ActionCreator<
  IESimActivateSimOrESimResetStoreAction
> = () => ({
  type: ESimActionTypes.ESIM_ACTIVATE_SIM_OR_ESIM_RESET_STORE,
});

export const eSimOneNumberPortalLinkBegin: ActionCreator<
  IESimOnNumberPortalLinkFetchBeginAction
> = (caNumber: string) => ({
  type: ESimActionTypes.ESIM_ONE_NUMBER_PORTAL_LINK_BEGIN,
  caNumber,
});

export const eSimOneNumberPortalLinkSuccess: ActionCreator<
  IESimOnNumberPortalLinkFetchSuccessAction
> = (caNumber: string, payload: IESimOneNumberPortalLinkResponse) => ({
  type: ESimActionTypes.ESIM_ONE_NUMBER_PORTAL_LINK_SUCCESS,
  caNumber,
  payload,
});

export const eSimOneNumberPortalLinkFailure: ActionCreator<
  IESimOnNumberPortalLinkFetchFailureAction
> = (caNumber: string, payload: IRestResponseFault) => ({
  type: ESimActionTypes.ESIM_ONE_NUMBER_PORTAL_LINK_FAILURE,
  caNumber,
  payload,
});

export const eSimOneNumberPortalLinkFetch: any =
  (caNumber: string) => (dispatch) => {
    dispatch(eSimOneNumberPortalLinkBegin(caNumber));

    return eSimOneNumberPortalLinkRequest(caNumber)
      .then(
        (
          response: AxiosResponse<
            IESimOneNumberPortalLinkResponse | IRestResponseFault
          >,
        ) => {
          dispatch(eSimOneNumberPortalLinkSuccess(caNumber, response.data));
          return response.data;
        },
      )
      .catch((response: AxiosResponse<IRestResponseFault>) => {
        dispatch(
          updateModalAnalyticsOptions({
            prop16AdditionalInfo: `error ${
              (response as any)?.response?.status || ''
            }`,
          }),
        );
        dispatch(eSimOneNumberPortalLinkFailure(caNumber, response.data));
      });
  };

export const eSimFetchOneNumberOneNumbersBegin: ActionCreator<
  IESimFetchOnNumberOneNumbersBeginAction
> = (serviceNumber: string) => ({
  type: ESimActionTypes.ESIM_FETCH_ONE_NUMBER_ONE_NUMBERS_BEGIN,
  serviceNumber,
});

export const eSimFetchOneNumberOneNumbersSuccess: ActionCreator<
  IESimFetchOnNumberOneNumbersSuccessAction
> = (serviceNumber: string, payload: IESimOneNumberOneNumbersResponse) => ({
  type: ESimActionTypes.ESIM_FETCH_ONE_NUMBER_ONE_NUMBERS_SUCCESS,
  serviceNumber,
  payload,
});

export const eSimFetchOneNumberOneNumbersFailure: ActionCreator<
  IESimFetchOnNumberOneNumbersFailureAction
> = (serviceNumber: string, payload: IRestResponseFault) => ({
  type: ESimActionTypes.ESIM_FETCH_ONE_NUMBER_ONE_NUMBERS_FAILURE,
  serviceNumber,
  payload,
});

export const eSimFetchOneNumberOneNumbers =
  (serviceNumber: string) => (dispatch) => {
    dispatch(eSimFetchOneNumberOneNumbersBegin(serviceNumber));

    return eSimOneNumberOneNumbersRequest(serviceNumber)
      .then(
        (
          response: AxiosResponse<
            IESimOneNumberOneNumbersResponse | IRestResponseFault
          >,
        ) => {
          dispatch(
            eSimFetchOneNumberOneNumbersSuccess(serviceNumber, response.data),
          );
        },
      )
      .catch((response: AxiosResponse<IRestResponseFault>) => {
        dispatch(
          eSimFetchOneNumberOneNumbersFailure(serviceNumber, response.data),
        );
      });
  };

export const eSimChangeSmsForkSettingsBegin: ActionCreator<
  IESimChangeSmsForkSettingsBeginAction
> = () => ({
  type: ESimActionTypes.ESIM_CHANGE_SMS_FORK_SETTINGS_BEGIN,
});

export const eSimChangeSmsForkSettingsSuccess: ActionCreator<
  IESimChangeSmsForkSettingsSuccessAction
> = (
  serviceNumber: string,
  productOfferingId: string,
  fork: boolean,
  payload: IRestResponseSuccess,
) => ({
  type: ESimActionTypes.ESIM_CHANGE_SMS_FORK_SETTINGS_SUCCESS,
  serviceNumber,
  productOfferingId,
  fork,
  payload,
});

export const eSimChangeSmsForkSettingsFailure: ActionCreator<
  IESimChangeSmsForkSettingsFailureAction
> = (payload: IRestResponseFault) => ({
  type: ESimActionTypes.ESIM_CHANGE_SMS_FORK_SETTINGS_FAILURE,
  payload,
});

export const eSimChangeSmsForkSettingsResetStore: ActionCreator<
  IESimChangeSmsForkSettingsResetStoreAction
> = () => ({
  type: ESimActionTypes.ESIM_CHANGE_SMS_FORK_SETTINGS_RESET_STORE,
});

export const eSimChangeSmsForkSettings =
  (serviceNumber: string, fork: boolean, productOfferingId: string) =>
  (dispatch) => {
    dispatch(eSimChangeSmsForkSettingsBegin(serviceNumber, fork));

    return eSimChangeSmsForkingSettingsRequest(
      serviceNumber,
      fork,
      productOfferingId,
    )
      .then(
        (
          response: AxiosResponse<IRestResponseSuccess | IRestResponseFault>,
        ) => {
          dispatch(
            eSimChangeSmsForkSettingsSuccess(
              serviceNumber,
              fork,
              response.data,
            ),
          );
        },
      )
      .catch((response: AxiosResponse<IRestResponseFault>) => {
        dispatch(eSimChangeSmsForkSettingsFailure(response.data));
      });
  };
