import { AxiosResponse } from 'axios';
import { API_ENDPOINT_ACTIVATE_SIM_OR_ESIM } from 'src/api';
import { IRestResponseFault } from 'src/api/types/IRestResponseFault';
import { IRestResponseSuccess } from 'src/api/types/IRestResponseSuccess';
import api from 'src/utils/api/api';

export default (
  serviceNumber: string,
  iccid: string,
): Promise<AxiosResponse<IRestResponseSuccess | IRestResponseFault>> =>
  api.post({
    url: API_ENDPOINT_ACTIVATE_SIM_OR_ESIM.replace(
      '{serviceNumber}',
      serviceNumber,
    ),
    data: {
      iccid,
    },
  });
