import {
  IconErrorMid,
  Link,
  LINK_COLORS,
  Text,
  Title,
} from '@vodafone/red-white';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { LinksBoxes } from 'src/components/NotEligible';
import ServiceSwitcher from 'src/components/NotEligible/components/ServiceSwitcher';
import { RoutesEnum } from 'src/config/routes';
import { useCoreConfig } from 'src/features/core';
import { useAppDispatch } from 'src/hooks/useAppDispatch';
import BasicLayout from 'src/layouts/BasicLayout';
import EshopDisabledPage from 'src/pages/Eshop/EshopDisabledPage';
import selectLanguage from 'src/store/language/languageSelector';
import { setPageAnalytics } from 'src/utils/analytics/actions/analyticsActions';
import { redirect } from 'src/utils/redirect';
import styled from 'styled-components';
import { LanguagesEnum } from '../../types/translation/languagesEnum';

interface IProps {
  path: string;
}

const ResultBase = styled.div`
  text-align: center;
`;

const IconSpacing = styled.div`
  margin-bottom: 25px;
`;

const TitleSpacing = styled.div`
  margin-bottom: 16px;
`;

const SubtitleSpacing = styled.div`
  margin-bottom: 32px;
`;

const LinksWrapper = styled.div`
  margin-top: 100px;
  @media ${(props) => props.theme.breakpoints.maxLg} {
    padding-bottom: 50px;
  }
`;

const NotEligiblePage: React.FC<IProps> = ({ path }) => {
  const { t } = useTranslation(['dashboard', 'component']);
  const dispatch = useAppDispatch();
  const history = useHistory();

  const language: LanguagesEnum = useSelector(selectLanguage);
  const config = useCoreConfig();

  React.useEffect(() => {
    dispatch(setPageAnalytics(path));
  }, [dispatch, path]);

  return (
    <>
      {/* @ts-ignore */}
      <BasicLayout>
        {!config.data.eshopEnabled ? (
          <EshopDisabledPage path={path} />
        ) : (
          <ResultBase>
            <IconSpacing>
              <IconErrorMid size={76} />
            </IconSpacing>
            <TitleSpacing>
              <Title fontSize={26} color="darkGrey">
                {t('component:notEligible.title')}
              </Title>
            </TitleSpacing>
            <SubtitleSpacing>
              <Text fontSize={16} color="darkGrey">
                {t('component:notEligible.subtitle')}
                <Link
                  onClick={() => {
                    redirect({
                      internalRouteId: RoutesEnum.DASHBOARD_ROUTE_ID,
                      language,
                      history,
                    });
                  }}
                  style={{ color: LINK_COLORS.black }}
                >
                  {t('component:notEligible.link')}
                </Link>
              </Text>
            </SubtitleSpacing>

            <div style={{ marginTop: 60 }}>
              <ServiceSwitcher />
            </div>

            <LinksWrapper>
              <LinksBoxes />
            </LinksWrapper>
          </ResultBase>
        )}
      </BasicLayout>
    </>
  );
};

export default NotEligiblePage;
