export enum ServiceActionTypes {
  FETCH_SERVICES_BEGIN = 'FETCH_SERVICES_BEGIN',
  FETCH_SERVICES_SUCCESS = 'FETCH_SERVICES_SUCCESS',
  CLEAR_SERVICES_DATA = 'CLEAR_SERVICES_DATA',
  FETCH_SERVICES_FAILURE = 'FETCH_SERVICES_FAILURE',
  SERVICE_LOADING_START = 'SERVICE_LOADING_START',
  SERVICE_LOADING_RELOAD = 'SERVICE_LOADING_RELOAD',
  SERVICE_LOADING_STOP = 'SERVICE_LOADING_STOP',
  SERVICE_AJAX_ERROR = 'SERVICE_AJAX_ERROR',

  LOAD_SERVICE_BEGIN = 'LOAD_SERVICE_BEGIN',
  LOAD_SERVICE_SUCCESS = 'LOAD_SERVICE_SUCCESS',
  LOAD_SERVICE_FAILURE = 'LOAD_SERVICE_FAILURE',

  FETCH_ALL_SERVICES_BEGIN = 'FETCH_ALL_SERVICES_BEGIN',
  FETCH_ALL_SERVICES_SUCCESS = 'FETCH_ALL_SERVICES_SUCCESS',
  FETCH_ALL_SERVICES_FAILURE = 'FETCH_ALL_SERVICES_FAILURE',

  FETCH_SERVICES_DETAIL_BEGIN = 'FETCH_SERVICES_DETAIL_BEGIN',
  FETCH_SERVICES_DETAIL_SUCCESS = 'FETCH_SERVICES_DETAIL_SUCCESS',
  FETCH_SERVICES_DETAIL_FAILURE = 'FETCH_SERVICES_DETAIL_FAILURE',
  FETCH_SERVICES_DETAIL_PROCESSING = 'FETCH_SERVICES_DETAIL_PROCESSING',

  GENERIC_ANALYTICS_ACTION = 'GENERIC_ANALYTICS_ACTION',

  TOGGLE_SERVICE_DETAIL = 'TOGGLE_SERVICE_DETAIL',

  LOCK_SERVICE_MANAGEMENT = 'LOCK_SERVICE_MANAGEMENT',

  FETCH_MORE_SERVICES_BEGIN = 'FETCH_MORE_SERVICES_BEGIN',
  FETCH_MORE_SERVICES_SUCCESS = 'FETCH_MORE_SERVICES_SUCCESS',
  FETCH_MORE_SERVICES_FAILURE = 'FETCH_MORE_SERVICES_FAILURE',

  EXPAND_GROUP_AFTER_MORE_SERVICES_LOADED_BEGIN = 'EXPAND_GROUP_AFTER_MORE_SERVICES_LOADED_BEGIN',
  EXPAND_GROUP_AFTER_MORE_SERVICES_LOADED_SUCCESS = 'EXPAND_GROUP_AFTER_MORE_SERVICES_LOADED_SUCCESS',

  EXPAND_SERVICE_AFTER_BSS_AND_WSC_LOADED_BEGIN = 'EXPAND_SERVICE_AFTER_BSS_AND_WSC_LOADED_BEGIN',
  EXPAND_SERVICE_AFTER_BSS_AND_WSC_LOADED_SUCCESS = 'EXPAND_SERVICE_AFTER_BSS_AND_WSC_LOADED_SUCCESS',

  RESET_ALL_SERVICES = 'RESET_ALL_SERVICES',
}
