import { ActionCreator } from 'redux';
import {
  getInvoiceBreakdown,
  getInvoiceDownloadUrl,
  getInvoiceDownloadXlsUrl,
  getInvoices,
  getInvoicesSummary,
} from 'src/api/invoices';
import InvoiceFormatEnum from './types/InvoiceFormatEnum';
import {
  IFetchInvoiceBreakdownBeginAction,
  IFetchInvoiceBreakdownFailureAction,
  IFetchInvoiceBreakdownSuccessAction,
  IFetchInvoicesBeginAction,
  IFetchInvoicesFailureAction,
  IFetchInvoicesSuccessAction,
  IFetchInvoicesSummaryBeginAction,
  IFetchInvoicesSummaryFailureAction,
  IFetchInvoicesSummarySuccessAction,
} from './types/invoicesActionInterfaces';
import { InvoicesActionTypes } from './types/invoicesActionTypes';

export const fetchInvoicesBegin: ActionCreator<
  IFetchInvoicesBeginAction
> = () => ({
  type: InvoicesActionTypes.FETCH_INVOICES_BEGIN,
});

export const fetchInvoicesSuccess: ActionCreator<
  IFetchInvoicesSuccessAction
> = (baNumber, payload) => ({
  type: InvoicesActionTypes.FETCH_INVOICES_SUCCESS,
  baNumber,
  payload,
});

export const fetchInvoicesFailure: ActionCreator<
  IFetchInvoicesFailureAction
> = () => ({
  type: InvoicesActionTypes.FETCH_INVOICES_FAILURE,
});

export const fetchInvoices = (baNumber: string) => async (dispatch) => {
  dispatch(fetchInvoicesBegin());

  return getInvoices(baNumber)
    .then((response) => {
      dispatch(fetchInvoicesSuccess(baNumber, response.data));
    })
    .catch(() => {
      dispatch(fetchInvoicesFailure());
    });
};

export const fetchInvoiceBreakdownBegin: ActionCreator<
  IFetchInvoiceBreakdownBeginAction
> = (invoiceId) => ({
  type: InvoicesActionTypes.FETCH_INVOICE_BREAKDOWN_BEGIN,
  invoiceId,
});

export const fetchInvoiceBreakdownSuccess: ActionCreator<
  IFetchInvoiceBreakdownSuccessAction
> = (payload, invoiceId) => ({
  type: InvoicesActionTypes.FETCH_INVOICE_BREAKDOWN_SUCCESS,
  payload,
  invoiceId,
});

export const fetchInvoiceBreakdownFailure: ActionCreator<
  IFetchInvoiceBreakdownFailureAction
> = (invoiceId) => ({
  type: InvoicesActionTypes.FETCH_INVOICE_BREAKDOWN_FAILURE,
  invoiceId,
});

export const fetchInvoiceBreakdown =
  (baNumber: string, invoiceId: string) => async (dispatch) => {
    dispatch(fetchInvoiceBreakdownBegin(invoiceId));

    return getInvoiceBreakdown(baNumber, invoiceId)
      .then((response) => {
        dispatch(fetchInvoiceBreakdownSuccess(response.data, invoiceId));
      })
      .catch(() => {
        dispatch(fetchInvoiceBreakdownFailure(invoiceId));
      });
  };

// download invoice
export const downloadInvoice =
  (baNumber: string, invoiceId: string, format: InvoiceFormatEnum) => () => {
    window.open(getInvoiceDownloadUrl(baNumber, invoiceId, format), '_blank');
  };

// download invoices in xls
export const downloadInvoicesInXls = (baNumber: string, year: string) => () => {
  window.open(getInvoiceDownloadXlsUrl(baNumber, year), '_blank');
};

// invoice summary
export const fetchInvoicesSummaryBegin: ActionCreator<
  IFetchInvoicesSummaryBeginAction
> = (baNumber) => ({
  type: InvoicesActionTypes.FETCH_INVOICES_SUMMARY_BEGIN,
  baNumber,
});

export const fetchInvoicesSummarySuccess: ActionCreator<
  IFetchInvoicesSummarySuccessAction
> = (payload, baNumber) => ({
  type: InvoicesActionTypes.FETCH_INVOICES_SUMMARY_SUCCESS,
  payload,
  baNumber,
});

export const fetchInvoicesSummaryFailure: ActionCreator<
  IFetchInvoicesSummaryFailureAction
> = (baNumber) => ({
  type: InvoicesActionTypes.FETCH_INVOICES_SUMMARY_FAILURE,
  baNumber,
});

export const fetchInvoicesSummary = (baNumber: string) => async (dispatch) => {
  dispatch(fetchInvoicesSummaryBegin(baNumber));

  return getInvoicesSummary(baNumber)
    .then((response) => {
      dispatch(fetchInvoicesSummarySuccess(response.data, baNumber));
    })
    .catch(() => {
      dispatch(fetchInvoicesSummaryFailure(baNumber));
    });
};
