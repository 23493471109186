import { Text } from '@vodafone/red-white';
import * as React from 'react';
import { Trans } from 'react-i18next';
import { useAppDispatch } from 'src/hooks/useAppDispatch';
import { openModal } from 'src/store/modal/modalActions';
import ModalType from 'src/store/modal/types/ModalType';
import IBillingAccount from 'src/store/user/types/billingAccountInterface';
import BillingStatusEnum from 'src/types/billing/billingStatusEnum';
import * as Styled from './CardBillingPaymentDifferentAmount.styled';

interface IProps {
  status: BillingStatusEnum;
  amount: number;
  dueAmount: number;
  month: string;
  dueDate: string;
  billingAccount: IBillingAccount;
}

const CardBillingPaymentDifferentAmount: React.FC<IProps> = ({
  status,
  amount,
  dueAmount,
  month,
  dueDate,
  billingAccount,
}) => {
  const dispatch = useAppDispatch();

  return (
    <Styled.Content>
      <Styled.TitleWrapper>
        <Text fontSize={16}>
          <Trans
            i18nKey="billing:differentPaymentModal.info-message"
            components={[
              <Styled.LinkText
                onClick={() =>
                  dispatch(
                    openModal({
                      type: ModalType.DifferentPayment,
                      options: {
                        status,
                        amount,
                        dueAmount,
                        month,
                        dueDate,
                        billingAccount,
                      },
                    }),
                  )
                }
              />,
            ]}
          />
        </Text>
      </Styled.TitleWrapper>
    </Styled.Content>
  );
};

export default CardBillingPaymentDifferentAmount;
