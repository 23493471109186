import { Reducer } from 'redux';
import initialState from '../initialState';
import {
  IRecoveryEligibilityCaptchaBeginAction,
  IRecoveryEligibilityCaptchaFailureAction,
  IRecoveryEligibilityCaptchaSuccessAction,
} from './types/recoveryEligibilityCaptchaActionInterfaces';
import RecoveryEligibilityCaptchaActionTypes from './types/recoveryEligibilityCaptchaActionTypes';
import IRecoveryEligibilityCaptchaState from './types/recoveryEligibilityCaptchaStateInterface';

type IRecoveryEligibilityCaptchaAction =
  | IRecoveryEligibilityCaptchaBeginAction
  | IRecoveryEligibilityCaptchaSuccessAction
  | IRecoveryEligibilityCaptchaFailureAction;

const recoveryEligibilityCaptchaReducer: Reducer<
  IRecoveryEligibilityCaptchaState
> = (
  state: IRecoveryEligibilityCaptchaState = initialState.RecoveryEligibilityCaptcha,
  action: IRecoveryEligibilityCaptchaAction,
) => {
  switch (action.type) {
    case RecoveryEligibilityCaptchaActionTypes.RECOVERY_ELIGIBILITY_CAPTCHA_BEGIN:
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      };
    case RecoveryEligibilityCaptchaActionTypes.RECOVERY_ELIGIBILITY_CAPTCHA_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        loaded: true,
        error: false,
      };
    case RecoveryEligibilityCaptchaActionTypes.RECOVERY_ELIGIBILITY_CAPTCHA_FAILURE:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    default:
      return state;
  }
};

export default recoveryEligibilityCaptchaReducer;
