import {
  Code,
  IconSolutionFinder,
  ModalMessage,
  VariantEnum,
} from '@vodafone/red-white';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getMnpOku } from 'src/store/mnp/oku/mnpOkuSelectors';
import IMnpOkuState from 'src/store/mnp/oku/types/mnpOkuStateInterface';
import { ModalContentProps } from '../types/modalContentInterfaces';

const MnpInternetOkuModalContent: FC<ModalContentProps> = () => {
  const { t } = useTranslation('services');
  const { data }: IMnpOkuState = useSelector(getMnpOku);
  const { oku } = data;

  const trackOku = () => {
    try {
      if (window && typeof (window as any).s !== 'undefined') {
        const purchaseID = oku;
        const product = 'mnp;1;0';

        (window as any).s.purchaseID = `oku${purchaseID}`;
        (window as any).s.products = `WSC;WA-${product}`;
        (window as any).s.events = 'purchase';
        if (typeof (window as any).s.t === 'function') {
          (window as any).s.t();
        }
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log("Calling function 'trackOku' not successful.");
    }
  };

  useEffect(() => {
    trackOku();
  });

  return (
    <>
      <ModalMessage
        heading={t('services:mnp-internet.oku-modal-title')}
        icon={<IconSolutionFinder color="green" size={60} />}
        color="green"
      >
        {t('services:mnp-internet.oku-modal-hint')}
      </ModalMessage>
      <Code
        label={t('services:mnp-internet.oku')}
        value={oku}
        variant={VariantEnum.BIG}
      />
    </>
  );
};

export default MnpInternetOkuModalContent;
