/* eslint-disable react/no-danger */
import { IconMoveMoney, Row, Text } from '@vodafone/red-white';
import * as React from 'react';
import { ThemeContext } from 'styled-components';
import * as Styled from './CardBillingPaymentSipo.styled';

export interface ICardBillingPaymentSipoProps {
  title: string;
  subtitle?: string;
  heading?: string;
  amount?: React.ReactNode;
  grayInfo?: string;
  text?: string;
  linkText?: string;
  onLinkClick?: () => void;
  dueDate?: string;
}

const CardBillingPaymentSipo: React.FC<ICardBillingPaymentSipoProps> = ({
  title,
  subtitle,
  heading,
  amount,
  grayInfo,
  text,
  linkText,
  onLinkClick,
  dueDate,
}) => {
  const themeContext = React.useContext(ThemeContext);
  const textColor = 'darkGrey';
  const iconColor = themeContext.colors.darkGrey;

  return (
    <Styled.Content>
      <Row align="center">
        <div>
          <Styled.Header>
            <span>
              <IconMoveMoney color={iconColor} />
            </span>
            <Styled.HeadingText
              color={textColor}
              fontSize={18}
              fontFamily="VodafoneRgBd"
            >
              {title}
            </Styled.HeadingText>
          </Styled.Header>
          <Styled.SubtitleText block>{subtitle}</Styled.SubtitleText>
          <Styled.BillingWrapper>
            <Styled.BillingText
              color={textColor}
              fontSize={56}
              fontFamily="VodafoneLt"
            >
              {amount}
            </Styled.BillingText>
            <Styled.BillingWrapperDetail>
              <Text block color="green" fontSize={18} fontFamily="VodafoneRgBd">
                {heading}
              </Text>
              <Text block color="darkGrey" fontSize={16}>
                {dueDate}
              </Text>
              {grayInfo && (
                <>
                  <br />
                  <Text block color="grayDark" fontSize={14}>
                    {grayInfo}
                  </Text>
                </>
              )}

              {text && (
                <>
                  <br />
                  <Text block color={textColor} fontSize={16}>
                    <span dangerouslySetInnerHTML={{ __html: text }} />
                  </Text>
                </>
              )}
            </Styled.BillingWrapperDetail>
          </Styled.BillingWrapper>
          <Styled.BillingFooter>
            <Styled.PaymentLink onActivation={onLinkClick}>
              {linkText}
            </Styled.PaymentLink>
          </Styled.BillingFooter>
        </div>
      </Row>
    </Styled.Content>
  );
};

export default CardBillingPaymentSipo;
