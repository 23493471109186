import { useQuery } from '@tanstack/react-query';
import api from 'src/utils/api/api';

export const GET_CORE_CONFIG_ENDPOINT = '/muj/rapi/core/config';
const CORE_CONFIG_KEY = 'getCoreConfig';

type CoreConfigResponse = {
  recaptchaEnabled: boolean;
  recaptchaSiteKey: string;
  eshopEnabled: boolean;
  esimD2enabled: boolean;
  isXmasDeliveryDeadlineMessageEnabled: boolean;
  isEsimOrderServicesEnabled: boolean;
  isLoginInfoMessageEnabled: boolean;
};

const getConfig = async (): Promise<CoreConfigResponse> => {
  const { data } = await api.get({ url: GET_CORE_CONFIG_ENDPOINT });

  return data;
};

export const useCoreConfig = () => {
  // const outage = useCoreOutage();

  return useQuery({
    queryFn: () => getConfig(),
    queryKey: [CORE_CONFIG_KEY],
    // enabled: !outage.data.isFullOutage,
  });
};
