import { ActionCreator } from 'redux';
import requestValidationSmsCall from 'src/api/requestValidationSms';
import {
  IRequestValidationSmsBeginAction,
  IRequestValidationSmsFailureAction,
  IRequestValidationSmsSuccessAction,
  IRequestValidationSmsSuccessActionPayload,
} from './types/requestValidationSmsActionInterfaces';
import RequestValidationSmsActionTypes from './types/requestValidationSmsActionTypes';

export const requestValidationSmsBegin: ActionCreator<
  IRequestValidationSmsBeginAction
> = () => ({
  type: RequestValidationSmsActionTypes.REQUEST_VALIDATION_SMS_BEGIN,
});

export const requestValidationSmsSuccess: ActionCreator<
  IRequestValidationSmsSuccessAction
> = (payload: IRequestValidationSmsSuccessActionPayload) => ({
  type: RequestValidationSmsActionTypes.REQUEST_VALIDATION_SMS_SUCCESS,
  payload,
});

export const requestValidationSmsFailure: ActionCreator<
  IRequestValidationSmsFailureAction
> = () => ({
  type: RequestValidationSmsActionTypes.REQUEST_VALIDATION_SMS_FAILURE,
});

export const requestValidationSmsRequest =
  (verificationTarget: string) => (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch(requestValidationSmsBegin());

      return requestValidationSmsCall({ verificationTarget })
        .then((response) => {
          dispatch(requestValidationSmsSuccess(response.data));
          resolve(response.data);
        })
        .catch(() => {
          dispatch(requestValidationSmsFailure());
          reject();
        });
    });
