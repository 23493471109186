import {
  ICULocaleEnum,
  LanguagesEnum,
} from 'src/types/translation/languagesEnum';

export const icuLocaleFromLanguage = (language: LanguagesEnum) => {
  return language === LanguagesEnum.CS
    ? ICULocaleEnum.CS_CZ
    : ICULocaleEnum.EN_GB;
};
