import { AxiosResponse } from 'axios';
import { API_ENDPOINT_VTV_GET_ACTIVE_DEVICES } from '../..';
import api from '../../../utils/api/api';
import IGetVtvActiveDevicesResponse from '../response/getVtvActiveDevicesResponseInterface';

export default (
  serviceNumber: string,
): Promise<AxiosResponse<IGetVtvActiveDevicesResponse>> =>
  api.get({
    url: API_ENDPOINT_VTV_GET_ACTIVE_DEVICES.replace(
      '{serviceNumber}',
      serviceNumber,
    ),
  });
