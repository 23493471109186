import { UpdateStolenLostActionEnum } from 'src/api/serviceManagement/request/types/UpdateStolenLostActionEnum';
import updateStolenLostRequest from 'src/api/serviceManagement/request/updateStolenLostRequest';
import { ServiceCodeNameEnum } from 'src/components';
import {
  lockServiceManagement,
  toggleServiceDetail,
} from 'src/store/services/actions/serviceActions';
import { IService } from 'src/store/services/types/service/serviceInterface';
import { API_ENDPOINT_SERVICE_STOLEN_LOST_UPDATE } from '../../../api';
import {
  apiBegin,
  apiFailure,
  apiSuccess,
} from '../../application/applicationActions';

export const updateStolenLost =
  (
    serviceNumber: string,
    action: UpdateStolenLostActionEnum,
    channel: string,
    service: IService,
  ) =>
  (dispatch) => {
    dispatch(apiBegin(API_ENDPOINT_SERVICE_STOLEN_LOST_UPDATE));

    return updateStolenLostRequest(serviceNumber, action, channel)
      .then((response) => {
        if (response.status === 204) {
          dispatch(apiSuccess(API_ENDPOINT_SERVICE_STOLEN_LOST_UPDATE));
          dispatch(toggleServiceDetail(service));
          dispatch(lockServiceManagement(ServiceCodeNameEnum.STOLEN_LOST));
        } else {
          dispatch(apiFailure(API_ENDPOINT_SERVICE_STOLEN_LOST_UPDATE));
        }
      })
      .catch(() => {
        dispatch(apiFailure(API_ENDPOINT_SERVICE_STOLEN_LOST_UPDATE));
      });
  };
