import { AxiosResponse } from 'axios';
import InvoiceFormatEnum from 'src/store/invoices/types/InvoiceFormatEnum';
import api from 'src/utils/api/api';
import {
  API_ENDPOINT_INVOICES,
  API_ENDPOINT_INVOICES_BREAKDOWN,
  API_ENDPOINT_INVOICE_DOWNLOAD,
  API_ENDPOINT_INVOICE_SUMMARY,
  API_ENDPOINT_INVOICE_XLS_DOWNLOAD,
} from '..';
import { IGetInvoiceBreakdownResponse } from './response/getInvoiceBreakdownResponseInterface';
import { IGetInvoicesResponse } from './response/getInvoicesResponseInterface';
import { IGetInvoicesSummaryResponse } from './response/getInvoicesSummaryResponseInterface';

export const getInvoices = (
  baNumber: string,
): Promise<AxiosResponse<IGetInvoicesResponse>> =>
  api.get({
    url: API_ENDPOINT_INVOICES.replace('{baNumber}', baNumber),
  });

export const getInvoiceBreakdown = (
  baNumber: string,
  invoiceId: string,
): Promise<AxiosResponse<IGetInvoiceBreakdownResponse>> =>
  api.get({
    url: API_ENDPOINT_INVOICES_BREAKDOWN.replace(
      '{baNumber}',
      baNumber,
    ).replace('{invoiceId}', invoiceId),
  });

export const getInvoiceDownloadUrl = (
  baNumber: string,
  invoiceId: string,
  format: InvoiceFormatEnum,
): string => {
  return API_ENDPOINT_INVOICE_DOWNLOAD.replace('{baNumber}', baNumber)
    .replace('{invoiceId}', invoiceId)
    .replace('{format}', format)
    .replace(
      '{downloadFormat}',
      format === InvoiceFormatEnum.PDF ? 'pdf' : 'zip',
    );
};

export const getInvoiceDownloadXlsUrl = (
  baNumber: string,
  year: string,
): string => {
  return API_ENDPOINT_INVOICE_XLS_DOWNLOAD.replace(
    '{baNumber}',
    baNumber,
  ).replace('{year}', year);
};

export const getInvoicesSummary = (
  baNumber: string,
): Promise<AxiosResponse<IGetInvoicesSummaryResponse>> =>
  api.get({
    url: API_ENDPOINT_INVOICE_SUMMARY.replace('{baNumber}', baNumber),
  });
