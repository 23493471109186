import ISubscription from 'src/store/user/types/subscriptionInterface';
import SubscriptionTypeEnum from 'src/store/user/types/SubscriptionTypeEnum';
import ISubscriber from 'src/types/user/subscriberInterface';

export const isUsageRelevant = (subscriptionType: SubscriptionTypeEnum) =>
  [
    SubscriptionTypeEnum.FIX,
    SubscriptionTypeEnum.FMS,
    SubscriptionTypeEnum.GSM,
  ].includes(subscriptionType);

export const getFilteredSubscriptions = (subscriptions: ISubscription[]) =>
  subscriptions.filter((subscription) => isUsageRelevant(subscription.type));

export const getFilteredSubscribers = (subscribers: ISubscriber[]) =>
  subscribers.filter((subscriber) =>
    isUsageRelevant(subscriber.subscriptionType),
  );
