export enum DocumentsActionTypes {
  FETCH_DOC_INVOICES_BEGIN = 'FETCH_DOC_INVOICES_BEGIN',
  FETCH_DOC_INVOICES_SUCCESS = 'FETCH_DOC_INVOICES_SUCCESS',
  FETCH_DOC_INVOICES_FAILURE = 'FETCH_DOC_INVOICES_FAILURE',

  FETCH_DOC_AGREEMENTS_BEGIN = 'FETCH_DOC_AGREEMENTS_BEGIN',
  FETCH_DOC_AGREEMENTS_SUCCESS = 'FETCH_DOC_AGREEMENTS_SUCCESS',
  FETCH_DOC_AGREEMENTS_FAILURE = 'FETCH_DOC_AGREEMENTS_FAILURE',

  FETCH_DOC_AGREEMENTS_BY_TYPE_BEGIN = 'FETCH_DOC_AGREEMENTS_BY_TYPE_BEGIN',
  FETCH_DOC_AGREEMENTS_BY_TYPE_SUCCESS = 'FETCH_DOC_AGREEMENTS_BY_TYPE_SUCCESS',
  FETCH_DOC_AGREEMENTS_BY_TYPE_FAILURE = 'FETCH_DOC_AGREEMENTS_BY_TYPE_FAILURE',

  FETCH_DOC_FORMS_BEGIN = 'FETCH_DOC_FORMS_BEGIN',
  FETCH_DOC_FORMS_SUCCESS = 'FETCH_DOC_FORMS_SUCCESS',
  FETCH_DOC_FORMS_FAILURE = 'FETCH_DOC_FORMS_FAILURE',

  FETCH_RELATED_CONTENT_BEGIN = 'FETCH_RELATED_CONTENT_BEGIN',
  FETCH_RELATED_CONTENT_SUCCESS = 'FETCH_RELATED_CONTENT_SUCCESS',
  FETCH_RELATED_CONTENT_FAILURE = 'FETCH_RELATED_CONTENT_FAILURE',

  FETCH_ORDER_PREVIEW_BEGIN = 'FETCH_ORDER_PREVIEW_BEGIN',
  FETCH_ORDER_PREVIEW_SUCCESS = 'FETCH_ORDER_PREVIEW_SUCCESS',
  FETCH_ORDER_PREVIEW_FAILURE = 'FETCH_ORDER_PREVIEW_FAILURE',
}
