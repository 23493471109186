import axios, { AxiosError } from 'axios';
import i18next from 'i18next';
import { ActionCreator } from 'redux';
import {
  postVerificationSecure,
  postVerificationSecureVerifyCode,
} from 'src/api/mfa';
import { VerificationSecureMessageType } from 'src/api/mfa/request/verificationSecureRequest';
import { IRestResponseFault } from 'src/api/types/IRestResponseFault';
import MfaActionTypes from 'src/store/mfa/types/mfaTypes';
import {
  IFetchVerificationSecureBeginAction,
  IFetchVerificationSecureFailureAction,
  IFetchVerificationSecureSuccessAction,
  IFetchVerificationSecureVerifyCodeBeginAction,
  IFetchVerificationSecureVerifyCodeFailureAction,
  IFetchVerificationSecureVerifyCodeSuccessAction,
  IResetMfaState,
} from 'src/store/mfa/types/mfaTypesActionInterfaces';
import { updateModalAnalyticsOptions } from 'src/store/modal/modalActions';

export const resetMfaState: ActionCreator<IResetMfaState> = (
  messageType,
  serviceNumber,
) => ({
  type: MfaActionTypes.RESET_MFA_STATE,
  messageType,
  serviceNumber,
});

export const fetchVerificationSecureBeginAction: ActionCreator<
  IFetchVerificationSecureBeginAction
> = (messageType, serviceNumber) => ({
  type: MfaActionTypes.FETCH_VERIFICATION_SECURE_BEGIN,
  messageType,
  serviceNumber,
});

export const fetchVerificationSecureSuccessAction: ActionCreator<
  IFetchVerificationSecureSuccessAction
> = (messageType, serviceNumber, payload) => ({
  type: MfaActionTypes.FETCH_VERIFICATION_SECURE_SUCCESS,
  payload,
  messageType,
  serviceNumber,
});

export const fetchVerificationSecureFailureAction: ActionCreator<
  IFetchVerificationSecureFailureAction
> = (messageType, serviceNumber, isRateLimited = false) => ({
  type: MfaActionTypes.FETCH_VERIFICATION_SECURE_FAILURE,
  messageType,
  serviceNumber,
  isRateLimited,
});

export const fetchVerificationSecure =
  (messageType: VerificationSecureMessageType, serviceNumber: string) =>
  (dispatch) => {
    dispatch(fetchVerificationSecureBeginAction(messageType, serviceNumber));

    return postVerificationSecure({
      messageType,
      serviceNumber,
      language: i18next.language,
    })
      .then((response) => {
        return dispatch(
          fetchVerificationSecureSuccessAction(
            messageType,
            serviceNumber,
            response.data,
          ),
        );
      })
      .catch((error: AxiosError<IRestResponseFault> | Error) => {
        if (axios.isAxiosError(error)) {
          const e = error as AxiosError<IRestResponseFault>;

          dispatch(
            updateModalAnalyticsOptions({
              prop16AdditionalInfo: `error ${e?.response?.status || ''}`,
            }),
          );
        }

        dispatch(
          fetchVerificationSecureFailureAction(messageType, serviceNumber),
        );
      });
  };

export const fetchVerificationSecureVerifyCodeBeginAction: ActionCreator<
  IFetchVerificationSecureVerifyCodeBeginAction
> = (messageType, serviceNumber) => ({
  type: MfaActionTypes.FETCH_VERIFICATION_SECURE_VERIFY_CODE_BEGIN,
  messageType,
  serviceNumber,
});

export const fetchVerificationSecureVerifyCodeSuccessAction: ActionCreator<
  IFetchVerificationSecureVerifyCodeSuccessAction
> = (payload, messageType, serviceNumber) => ({
  type: MfaActionTypes.FETCH_VERIFICATION_SECURE_VERIFY_CODE_SUCCESS,
  payload,
  messageType,
  serviceNumber,
});

export const fetchVerificationSecureVerifyCodeFailureAction: ActionCreator<
  IFetchVerificationSecureVerifyCodeFailureAction
> = (messageType, serviceNumber, isRateLimited) => ({
  type: MfaActionTypes.FETCH_VERIFICATION_SECURE_VERIFY_CODE_FAILURE,
  messageType,
  serviceNumber,
  isRateLimited,
});

export const fetchVerificationSecureVerifyCode =
  (
    messageType: VerificationSecureMessageType,
    serviceNumber: string,
    code: string,
  ) =>
  (dispatch) => {
    dispatch(
      fetchVerificationSecureVerifyCodeBeginAction(messageType, serviceNumber),
    );
    return postVerificationSecureVerifyCode({
      messageType,
      serviceNumber,
      code,
    })
      .then((response) => {
        dispatch(
          fetchVerificationSecureVerifyCodeSuccessAction(
            response.data,
            messageType,
            serviceNumber,
          ),
        );
      })
      .catch((error: AxiosError<IRestResponseFault> | Error) => {
        if (axios.isAxiosError(error)) {
          const e = error as AxiosError<IRestResponseFault>;
          const isRateLimited = e.response.data.message.code === 'rateLimit';

          dispatch(
            fetchVerificationSecureVerifyCodeFailureAction(
              messageType,
              serviceNumber,
              isRateLimited,
            ),
          );
        }

        throw error;
      });
  };
