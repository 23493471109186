import { Link } from '@vodafone/red-white';
import styled from 'styled-components';

export const ContentListItem = styled.li`
  &:not(:first-child) {
    border-top: 1px solid ${(props) => props.theme.colors.lightGrey5};
  }
`;

export const ListItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 15px;
  padding-left: 0;
`;

export const LinkButton = styled(Link)`
  color: ${(props) => props.theme.colors.darkGrey};
  text-decoration: none;
`;

export const ListItemLink = styled.a`
  color: ${(props) => props.theme.colors.darkGrey};
  text-decoration: none;
`;

export const ListItemContent = styled.div`
  display: flex;
  align-items: center;
`;

export const IconWrapper = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 100%;
    height: 100%;
  }
`;
