enum PayerInvoiceStatusEnum {
  PAID = 'PAID',
  UNPAID = 'UNPAID',
  PARTIALLY_PAID = 'PARTIALLY_PAID',
  DO_NOT_PAY = 'DO_NOT_PAY',
  CREDIT_NOTE = 'CREDIT_NOTE',
  IN_CREATION = 'IN_CREATION',
  OVERPAID = 'OVERPAID',
}

export default PayerInvoiceStatusEnum;
