import { resolveEndpoint } from '../utils/apiResolver';

export const API_DEFAULT_ENDPOINT = '/muj';

export const API_ENDPOINT_USER_SESSION = resolveEndpoint(
  `/muj/rapi/core/session`,
  '/core/session.json',
);
export const API_ENDPOINT_LARGE_SUBSCRIBERS = resolveEndpoint(
  `/muj/rapi/large-accounts/customers/{caNumber}/subscribers`,
  '/large-accounts/subscribers/search.json',
);
export const API_ENDPOINT_LARGE_BILLING_ACCOUNTS = resolveEndpoint(
  `/muj/rapi/large-accounts/customers/{caNumber}/billing-accounts`,
  '/large-accounts/billing-accounts/search.json',
);
export const API_ENDPOINT_LARGE_PREACTIVATED_CA_ESIMS = resolveEndpoint(
  `/muj/rapi/large-accounts/customers/{caNumber}/preactivated-esims`,
  '/large-accounts/esim/preactivated-ca-esims.json',
);
export const API_ENDPOINT_SERVICES = resolveEndpoint(
  `/muj/rapi/service-management/services/overview`,
  '/services/overview/vtv.json',
);
export const API_ENDPOINT_SERVICES_MORE = resolveEndpoint(
  `/muj/rapi/service-management/services/list/eligible`,
  '/services/services-eligible.json',
);
export const API_ENDPOINT_SERVICES_ALL = resolveEndpoint(
  `/muj/rapi/all-services`,
  '/services/all-services.json',
);
export const API_ENDPOINT_SERVICES_DETAIL_MNP = resolveEndpoint(
  `/muj/rapi/service-management/services/mnp`,
  '/services/service-detail-mnp.json',
);
export const API_ENDPOINT_SERVICES_DETAIL_PIN_PUK = resolveEndpoint(
  `/muj/rapi/service-management/services/pin-puk`,
  '/services/service-detail-pin-puk.json',
);
export const API_ENDPOINT_SERVICES_CHANGE = resolveEndpoint(
  `/muj/rapi/service-management/services`,
  '/services/service-change.json',
);
export const API_ENDPOINT_SERVICES_DETAIL = resolveEndpoint(`/get-service`);
export const API_ENDPOINT_SERVICES_BSS_DETAIL = resolveEndpoint(
  `/service-settings/bss-services`,
);
export const API_ENDPOINT_SERVICES_BSS_ONW_DETAIL = resolveEndpoint(
  `/muj/service-settings/bss-services/ONW/detail/{headerNumber}`,
  '/services/details/PRONWEL_detail.html',
);
export const API_ENDPOINT_SERVICES_DETAIL_ROAMING_CALCULATOR = resolveEndpoint(
  `/muj/rapi/service-management/roaming-calculator`,
  '/services/service-detail-roaming-calculator.json',
);
export const API_ENDPOINT_SERVICES_DETAIL_ROAMING_CALCULATOR_CALCULATE =
  resolveEndpoint(
    `/muj/rapi/service-management/roaming-calculator/calculate`,
    '/services/service-detail-roaming-calculate.json',
  );
export const API_ENDPOINT_EXIT_SERVICES_GET_OKU = resolveEndpoint(
  `/muj/rapi/exit-services/subscribers/{serviceNumber}/cpu`,
  '/services/exit-services-oku.json',
);
export const API_ENDPOINT_EXIT_SERVICES_INVOKE_CVOP = resolveEndpoint(
  `/muj/rapi/exit-services/subscribers/{serviceNumber}/cvop/invoke`,
);
export const API_ENDPOINT_EXIT_SERVICES_INVOKE_PENALTY = resolveEndpoint(
  `/muj/rapi/exit-services/subscribers/{serviceNumber}/{interactionPoint}/hw-penalties`,
  '/services/exit-services-penalty.json',
);
export const API_ENDPOINT_EXIT_SERVICES_AUTH_TYPE = resolveEndpoint(
  `/muj/rapi/exit-services/subscribers/{serviceNumber}/auth-type`,
);
export const API_ENDPOINT_EXIT_SERVICES_FBB_PENALTY = resolveEndpoint(
  `/muj/rapi/exit-services/subscribers/{serviceNumber}/penalty`,
  '/services/exit-services-fbb-penalty.json?serviceNumber={serviceNumber}',
);
export const API_ENDPOINT_SERVICE_NUMBER_SWITCHER_CHECK_UNCONFIRMED_BASKET_CHANGES =
  resolveEndpoint(
    `/muj/existuji-nepotvrzene-zmeny`,
    '/empty-response.json?=check-unconfirmed-basket-changes',
  );
export const API_ENDPOINT_SERVICE_NUMBER_SWITCHER_DISCARD_BASKET_CHANGES =
  resolveEndpoint(`/muj/basket/discard-changes`);
export const API_ENDPOINT_SERVICE_NUMBER_SWITCHER_SWITCH_SUBSCRIPTION =
  resolveEndpoint(`/muj/switch`);
export const API_ENDPOINT_SERVICE_STOLEN_LOST_UPDATE = resolveEndpoint(
  `/muj/rapi/service-management/subscribers/{serviceNumber}/services/stolen-lost`,
);
export const API_ENDPOINT_ADD_MTE_TO_CART = resolveEndpoint(
  `/muj/rapi/speedboost-change-add-to-cart`,
);
export const API_ENDPOINT_VTV_GET_ACTIVE_DEVICES = resolveEndpoint(
  `/muj/rapi/vtv/devices/{serviceNumber}/active`,
  '/vtv/vtv-active-devices.json',
);
export const API_ENDPOINT_VTV_REMOVE_ACTIVE_DEVICE = resolveEndpoint(
  `/muj/rapi/vtv/devices/{serviceNumber}/remove`,
);
export const API_ENDPOINT_VTV_GENERATE_PASSWORD_DEVICE = resolveEndpoint(
  `/muj/rapi/vtv/services/{serviceNumber}/password`,
);
export const API_ENDPOINT_VTV_SET_PURCHASE_PIN = resolveEndpoint(
  `/muj/rapi/vtv/services/{serviceNumber}/purchase-pin`,
);
export const API_ENDPOINT_VTV_SET_PASSWORD = resolveEndpoint(
  `/muj/rapi/vtv/services/subscribers/{serviceNumber}/password`,
);
export const API_ENDPOINT_FBB_GET_IP_ADDRESSES = resolveEndpoint(
  `/muj/rapi/fbb/services/subscribers/{serviceNumber}/ipaddress`,
  '/fbb/service-detail-ip.json',
);
export const API_ENDPOINT_FBB_CHANGE_MAC_ADDRESSES = resolveEndpoint(
  `/muj/rapi/fbb/subscriber/{subscriptionId}/ipaddress/macchange`,
);
export const API_ENDPOINT_FBB_GET_MODEM_MAC_ADDRESS = resolveEndpoint(
  `/muj/rapi/fbb/subscriber/{subscriptionId}/modem/{modemIdentifier}`,
  '/fbb/modem-mac-address-response.json',
);
export const API_ENDPOINT_FBB_REQUEST_MODEM_RESET = resolveEndpoint(
  `/muj/rapi/fbb/subscriber/{subscriptionId}/modem/{modemIdentifier}/reset`,
);
export const API_ENDPOINT_CHAT_RESOLVER_CONTEXT = resolveEndpoint(
  `/muj/rapi/chat-resolver/context`,
);
export const API_ENDPOINT_VIRTUAL_BUNDLE = resolveEndpoint(
  `/muj/rapi/service-management/virtual-bundle`,
  '/virtual-bundle/virtual-bundle.json',
);
export const API_ENDPOINT_SIM_ACTIVATION = resolveEndpoint(
  `/muj/rapi/service-management/sim-activation`,
  '/sim-activation.json',
);
export const API_ENDPOINT_NEWS = resolveEndpoint(
  `/muj/rapi/layout/news`,
  '/layout/news.json',
);
export const API_ENDPOINT_FAQS = resolveEndpoint(
  `/muj/rapi/layout/faqs`,
  '/layout/faqs.json?=',
);
export const API_ENDPOINT_ERROR_LOG = resolveEndpoint(`/muj/rapi/core/log/js`);
export const API_ENDPOINT_CMT_MESSAGES = resolveEndpoint(
  `/muj/cmt-messages`,
  '/cmt/cmt-messages.json?=',
);
export const API_ENDPOINT_CMT_MESSAGE_STATUS = resolveEndpoint(
  `/muj/cmt-message-status`,
);
export const API_ENDPOINT_CURRENT_SPENDING = resolveEndpoint(
  `/muj/rapi/smart-overview/currentSpending/subscriber/{serviceNumber}`,
  '/smart-overview/current-spending.json?sn={serviceNumber}',
);
export const API_ENDPOINT_SMART_OVERVIEW = resolveEndpoint(
  `/muj/rapi/data/smart_overview/{serviceNumber}`,
  '/smart-overview/smart-overview.json',
);
export const API_ENDPOINT_REQUEST_VALIDATION_SMS_ENDPOINT = resolveEndpoint(
  `/muj/rapi/verification/send/{verificationTarget}`,
);
export const API_ENDPOINT_REQUEST_VALIDATION_SMS_ENDPOINT_EN = resolveEndpoint(
  `/muj/en/rapi/verification/send/{verificationTarget}`,
);
export const API_ENDPOINT_VALIDATE_SMS_ENDPOINT = resolveEndpoint(
  `/muj/rapi/verification/verify/{verificationTarget}/{code}`,
);
export const API_ENDPOINT_EMAIL_UNSUBSCRIBE = resolveEndpoint(
  `/muj/rapi/email/unsubscribe`,
);
export const API_ENDPOINT_EMAIL_UNSUBSCRIBE_NPS = resolveEndpoint(
  `/muj/rapi/email/unsubscribe-nps`,
);
export const API_ENDPOINT_EMAIL_RESUBSCRIBE = resolveEndpoint(
  `/muj/rapi/email-subscription-queue/subscribe`,
);
export const API_ENDPOINT_RECOVERY_ELIGIBILITY = resolveEndpoint(
  `/muj/rapi/form/recovery_eligibility`,
  '/form/recovery-eligibility.json',
);
export const API_ENDPOINT_RECOVERY_ELIGIBILITY_CAPTCHA = resolveEndpoint(
  `/muj/rapi/form/recovery_eligibility/captcha`,
  '/form/recovery-eligibility-captcha.json',
);
export const API_ENDPOINT_RECOVERY_SETTING = resolveEndpoint(
  `/muj/rapi/form/recovery_setting`,
  '/form/recovery-setting.json',
);
export const API_ENDPOINT_RECOVERY_REQUEST = resolveEndpoint(
  `/muj/rapi/form/recovery_request`,
  '/form/recovery-request.json',
);
export const API_ENDPOINT_PASSWORD_FORGOT_CONFIRMATION = resolveEndpoint(
  `/muj/rapi/form/password_forgot_confirmation`,
  '/form/password-forgot-confirmation.json',
);
export const API_ENDPOINT_RECOVERY_REQUEST_CAPTCHA = resolveEndpoint(
  `/muj/rapi/form/recovery_request/captcha`,
  '/form/recovery-eligibility-captcha.json',
);
export const API_ENDPOINT_PAYMENT_INFO = resolveEndpoint(
  `/muj/rapi/billing/payers/{baNumber}/payment-info`,
  '/billing/payment-info.json?ba={baNumber}',
);
export const API_ENDPOINT_PAYMENT_TRANSACTION = resolveEndpoint(
  `/muj/rapi/payments`,
  '/billing/payment.json',
);
export const API_ENDPOINT_PAYMENT_STATUS = resolveEndpoint(
  `/muj/rapi/payments/{paymentId}`,
  '/billing/payment-status.json',
);
export const API_ENDPOINT_PAYMENT_PAYER_PAYMENTS = resolveEndpoint(
  `/muj/rapi/payments/payers/{baNumber}`,
  '/payments/payer-payments.json',
);
export const API_ENDPOINT_UPC_INVOICES = resolveEndpoint(
  `/muj/rapi/upc/invoices/{variableSymbol}`,
  '/payments/upc-payments.json?va={variableSymbol}',
);
export const API_ENDPOINT_UPC_INVOICE_DOWNLOAD = resolveEndpoint(
  `/muj/download/upc/invoices/{variableSymbol}/{invoiceId}/download`,
  '/invoices/invoice.pdf?va={variableSymbol}&in={invoiceId}',
);
export const API_ENDPOINT_PEGA_OFFERS = resolveEndpoint(
  `/muj/rapi/offers/{msisdn}/{offerId}`,
  '/offers/offers.json?offerId={offerId}',
);
export const API_ENDPOINT_BILLING_CUSTOMER = resolveEndpoint(
  `/muj/rapi/billing/customers/{caNumber}`,
  '/billing/customer.json?ca={caNumber}',
);
export const API_ENDPOINT_INVOICES = resolveEndpoint(
  `/muj/rapi/invoices/payers/{baNumber}`,
  '/invoices/payers-invoices.json?ba={baNumber}',
);
export const API_ENDPOINT_INVOICES_BREAKDOWN = resolveEndpoint(
  `/muj/rapi/invoices/payers/{baNumber}/invoice/{invoiceId}`,
  '/invoices/invoice-breakdown.json',
);
export const API_ENDPOINT_INVOICE_DOWNLOAD = resolveEndpoint(
  `/muj/download/invoices/payers/{baNumber}/invoice/{invoiceId}/download?format={downloadFormat}`,
  '/invoices/invoice.pdf?ba={baNumber}&in={invoiceId}&format={downloadFormat}',
);
export const API_ENDPOINT_INVOICE_XLS_DOWNLOAD = resolveEndpoint(
  `/muj/download/invoices/payers/{baNumber}/summary/download?year={year}`,
  '/invoices/invoice.pdf?ba={baNumber}&year={year}',
);
export const API_ENDPOINT_INVOICE_SUMMARY = resolveEndpoint(
  `/muj/rapi/invoices/payers/{baNumber}/summary`,
  '/invoices/invoice-summary.json',
);
export const API_ENDPOINT_USAGE_CONSUMPTION = resolveEndpoint(
  `/muj/rapi/usage-consumption/payers/{baNumber}/monetary`,
  '/usage-consumption/usage-consumption.json?ba={baNumber}',
);
export const API_ENDPOINT_GENERIC_MICROSITES_GET_OFFER_DATA = resolveEndpoint(
  `/muj/rapi/special-offers/generic/get-offer/{module}`,
  '/special-offers/generic/get-offer/{module}.json',
);
export const API_ENDPOINT_GENERIC_MICROSITES_GET_OFFER_DATA_WITH_SUBMODULE =
  resolveEndpoint(
    `/muj/rapi/special-offers/generic/get-offer/{module}/{submodule}`,
    '/special-offers/generic/get-offer/{module}/{submodule}.json',
  );
export const API_ENDPOINT_GENERIC_MICROSITES_GET_CONFIG_DATA = resolveEndpoint(
  `/muj/rapi/special-offers/generic/get-config/{module}`,
  '/special-offers/generic/get-config/{module}.json',
);
export const API_ENDPOINT_GENERIC_MICROSITES_GET_CONFIG_DATA_WITH_SUBMODULE =
  resolveEndpoint(
    `/muj/rapi/special-offers/generic/get-config/{module}/{submodule}`,
    '/special-offers/generic/get-config/{module}/{submodule}.json',
  );
export const API_ENDPOINT_GENERIC_MICROSITES_SEND_LEAD = resolveEndpoint(
  `/muj/rapi/special-offers/generic/send-lead-form/{module}`,
);
export const API_ENDPOINT_GENERIC_MICROSITES_SEND_LEAD_WITH_SUBMODULE =
  resolveEndpoint(
    `/muj/rapi/special-offers/generic/send-lead-form/{module}/{submodule}`,
  );
export const API_ENDPOINT_GENERIC_MICROSITES_POST_OFFER_DATA = resolveEndpoint(
  `/muj/rapi/special-offers/generic/activate-offer/{module}`,
  '/special-offers/generic/activate-offer/{module}.json',
);
export const API_ENDPOINT_GENERIC_MICROSITES_POST_OFFER_DATA_WITH_SUBMODULE =
  resolveEndpoint(
    `/muj/rapi/special-offers/generic/activate-offer/{module}/{submodule}`,
    '/special-offers/generic/activate-offer/{module}/{submodule}.json',
  );
export const API_ENDPOINT_GENERIC_MICROSITES_AFTER_LOGIN_GET_HAS_OFFERS =
  resolveEndpoint(
    `/muj/rapi/special-offers/generic/has-offer/{srvc_ident}/{module}`,
  );
export const API_ENDPOINT_GENERIC_MICROSITES_AFTER_LOGIN_GET_HAS_OFFERS_WITH_SUBMODULE =
  resolveEndpoint(
    `/muj/rapi/special-offers/generic/has-offer/{srvc_ident}/{module}/{submodule}`,
  );
export const API_ENDPOINT_ONE_TIME_PASSWORD = resolveEndpoint(
  `/muj/prihlaseni/jednorazove-prihlaseni/poslat-heslo`,
  '/otp/poslat-heslo.json',
);
export const API_ENDPOINT_ONE_TIME_PASSWORD_EN = resolveEndpoint(
  `/muj/en/login/onetime-login/submit`,
  '/otp/onetime-login.json',
);
export const API_ENDPOINT_LOGIN_CHECK = resolveEndpoint(
  `/muj/login-check`,
  '/login-check.json',
);
export const API_ENDPOINT_LOGIN_CHECK_EN = resolveEndpoint(
  `/muj/en/login-check`,
  '/login-check.json',
);
export const API_ENDPOINT_EET_RECEIPTS_SUMMARY = resolveEndpoint(
  `/muj/rapi/receipts/payers/{baNumber}`,
  '/eet/receipts.json?ba={baNumber}',
);
export const API_ENDPOINT_EET_RECEIPTS_DOWNLOAD = resolveEndpoint(
  `/muj/download/receipts/payers/{baNumber}/receipt/{documentId}/download`,
  '/invoices/invoice.pdf?ba={baNumber}&in={invoiceId}&documentId={docuemntId}',
);
export const API_ENDPOINT_PRODUCTS_OVERVIEW = resolveEndpoint(
  `/muj/rapi/views/products-overview/customers/{caNumber}`,
  '/products-overview/products-overview.json?caNumber={caNumber}',
);
export const API_ENDPOINT_USERDOC_AGREEMENTS = resolveEndpoint(
  `/muj/rapi/documents/{caNumber}/agreements`,
  '/documents/1040931403/agreements.json',
);
export const API_ENDPOINT_USERDOC_AGREEMENTS_BY_TYPE = resolveEndpoint(
  `/muj/rapi/documents/{caNumber}/agreements/{agreementType}`,
  '/documents/1040931403/agreementsByType.json',
);
export const API_ENDPOINT_USERDOC_INVOICES = resolveEndpoint(
  `/muj/rapi/documents/{caNumber}/invoices`,
  '/documents/1040931403/invoices.json',
);
export const API_ENDPOINT_USERDOC_FORMS = resolveEndpoint(
  `/muj/rapi/documents/{caNumber}/forms`,
  '/documents/1040931403/forms.json',
);
export const API_ENDPOINT_USERDOC_RELATED_CONTENT = resolveEndpoint(
  `/muj/rapi/documents/{caNumber}/related-content`,
  '/documents/1040931403/relatedContent.json',
);
export const API_ENDPOINT_USERDOC_ORDER_PREVIEW = resolveEndpoint(
  `/muj/rapi/documents/customers/{caNumber}/order-preview`,
  '/documents/1040931403/orderPreview.json',
);
export const API_ENDPOINT_USERDOC_AGREEMENTS_DOWNLOAD = resolveEndpoint(
  `/muj/download/agreements/subscribers/{serviceNumber}/{documentId}`,
  '/download/agreements/subscribers/{serviceNumber}/{documentId}',
);
export const API_ENDPOINT_USERDOC_INVOICES_DOWNLOAD = resolveEndpoint(
  `/muj/customer-request/download-logistic-invoice/{documentId}`,
  '/customer-request/download-logistic-invoice/{documentId}',
);
export const API_ENDPOINT_USERDOC_FORMS_DOWNLOAD = resolveEndpoint(
  `/muj/download/agreements/subscribers/{serviceNumber}/{documentId}`,
  '/download/agreements/subscribers/{serviceNumber}/{documentId}',
);
export const API_ENDPOINT_USERDOC_DOCUMENT_DOWNLOAD = resolveEndpoint(
  `/muj/download/documents/customers/{customerAccountNumber}/document/{documentId}`,
  '/download/documents/subscribers/{serviceNumber}/{documentId}',
);
export const API_ENDPOINT_CAS_REFRESH_SUBSCRIPTION = resolveEndpoint(
  `/muj/rapi/cas/services/refresh-subscription`,
  '/cas/refresh-subscription/refresh-subscription-200.json',
);
export const API_ENDPOINT_CAS_RESET_PIN = resolveEndpoint(
  `/muj/rapi/cas/services/{udid}/reset-pin`,
  '/cas/casDeviceResetPin200.json',
);
export const API_ENDPOINT_SEND_INITIAL_CODE = resolveEndpoint(
  `/muj/rapi/personal-account/send-initial-code`,
  '/personal-account/send-initial-code.json',
);
export const API_ENDPOINT_SEND_INITIAL_CODE_EN = resolveEndpoint(
  `/muj/en/rapi/personal-account/send-initial-code`,
  '/personal-account/send-initial-code.json',
);
export const API_ENDPOINT_REGISTRATION = resolveEndpoint(
  `/muj/rapi/form/registration`,
  '/form/registration.json',
);
export const API_ENDPOINT_REGISTRATION_EN = resolveEndpoint(
  `/muj/en/rapi/form/registration`,
  '/form/registration.json',
);
export const API_ENDPOINT_SET_CONTACT_NUMBER = resolveEndpoint(
  `/muj/profile/set-contact-number`,
  '/set-contact-number.json',
);
export const API_ENDPOINT_REGISTRATION_CONFIRMATION = resolveEndpoint(
  `/muj/rapi/form/registration_confirmation`,
  '/form/registration-confirmation.json',
);
export const API_ENDPOINT_REGISTRATION_CONFIRMATION_EN = resolveEndpoint(
  `/muj/en/rapi/form/registration_confirmation`,
  '/form/registration-confirmation.json',
);
export const API_ENDPOINT_VERIFY_CONTACT_NUMBER = resolveEndpoint(
  `/muj/rapi/customer/contact-number/{verificationTarget}/{code}`,
);
export const API_ENDPOINT_VERIFY_CONTACT_NUMBER_EN = resolveEndpoint(
  `/muj/en/rapi/customer/contact-number/{verificationTarget}/{code}`,
);
export const API_ENDPOINT_SAVE_DEFAULT_ROLE = resolveEndpoint(
  `/muj/profile/save-default-role`,
  '/profile/save-default-role.json',
);
export const API_ENDPOINT_SAVE_DEFAULT_ROLE_EN = resolveEndpoint(
  `/muj/en/profile/save-default-role`,
  '/profile/save-default-role.json',
);
export const API_ENDPOINT_DELETE_PROFILE_ACCOUNT = resolveEndpoint(
  `/muj/profile/delete-account`,
  '/profile/delete-account.json',
);
export const API_ENDPOINT_DELETE_PROFILE_ACCOUNT_EN = resolveEndpoint(
  `/muj/en/profile/delete-account`,
  '/profile/delete-account.json',
);
export const API_ENDPOINT_SIM_SWAP = resolveEndpoint(
  `/muj/rapi/sim-swap/subscribers/{serviceNumber}`,
  '/sim-swap.json',
);
export const API_ENDPOINT_ESIM_DELIVERY = resolveEndpoint(
  `/muj/rapi/esim/subscribers/{serviceNumber}/delivery`,
  '/delivery.json',
);
export const API_ENDPOINT_ESIM_DOWNLOAD_QR_CODE = resolveEndpoint(
  `/muj/download/esim-qrcodes/subscribers/{serviceNumber}`,
  '/esim/qrcode.pdf?sn={serviceNumber}',
);
export const API_ENDPOINT_ESIM_PRINT_QR_CODE = resolveEndpoint(
  `/muj/download/esim-qrcodes/subscribers/{serviceNumber}?x-vf-print`,
  '/esim/qrcode.pdf?sn={serviceNumber}&x-vf-print',
);
export const API_ENDPOINT_ESIM_DETAIL = resolveEndpoint(
  `/muj/rapi/sim/subscribers/{serviceNumber}`,
  '/esim/detail.json',
);
export const API_ENDPOINT_ACTIVATE_SIM_OR_ESIM = resolveEndpoint(
  `/muj/rapi/esim/subscribers/{serviceNumber}/complete-sim-swap`,
  '/complete-sim-swap.json',
);
export const API_ENDPOINT_ESIM_ONENUMBER_PORTAL_LINK = resolveEndpoint(
  `/muj/rapi/auth/one-number-portal`,
  '/esim/one-number-portal.json?caNumber={caNumber}',
);
export const API_ENDPOINT_ESIM_ONENUMBER_ONE_NUMBERS = resolveEndpoint(
  `/muj/rapi/esim/subscribers/{serviceNumber}/one-numbers`,
  '/esim/one-numbers.json?serviceNumber={serviceNumber}',
);
export const API_ENDPOINT_ESIM_CHANGE_SMS_FORKING_SETTINGS = resolveEndpoint(
  `/muj/rapi/esim/subscribers/{serviceNumber}/change-sms-forking-settings`,
  '/empty-response.json?serviceNumber={serviceNumber}',
);
export const API_ENDPOINT_SHOW_ACCOUNT_PASSWORD = resolveEndpoint(
  `/muj/profile/password?crmId={crmId}&crmType={crmType}`,
  '/account/password.json?crmId={crmId}&crmType={crmType}',
);
export const API_ENDPOINT_ADD_ACCOUNT = resolveEndpoint(
  `/muj/rapi/form/add_account`,
);
export const API_ENDPOINT_GET_DATA_OFFER = resolveEndpoint(
  `/muj/rapi/recurring-data-offer/{serviceNumber}`,
  '/recurring-data-offer/data-offer.json',
);
export const API_ENDPOINT_ACTIVATE_DATA_OFFER = resolveEndpoint(
  `/muj/rapi/recurring-data-offer/{serviceNumber}/activate`,
);
export const API_ENDPOINT_MFA_VERIFICATION_SECURE = resolveEndpoint(
  `/muj/rapi/verification/secure/send`,
  '/mfa/verification-secure.json',
);
export const API_ENDPOINT_MFA_VERIFICATION_SECURE_VERIFY_CODE = resolveEndpoint(
  `/muj/rapi/verification/secure/verify`,
);
