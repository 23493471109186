import { Middleware } from 'redux';
import BillingDetailActionTypes from 'src/store/billing/billingDetail/types/billingDetailActionTypes';
import IAnalyticsAction from '../../types/actions/analyticsActionInterface';
import { analyticsEvents } from './analyticsEvents';

const setAnalytics = (action: IAnalyticsAction) => {
  const trackingConfig = analyticsEvents[action.type];
  const params = trackingConfig(action);

  const s = Object.keys(params).reduce(
    (accumulator, currentValue) => {
      accumulator[currentValue] = params[currentValue];

      return accumulator;
    },
    typeof (window as any).s === 'object' ? (window as any).s : {},
  );

  if (
    action.type !== BillingDetailActionTypes.DOWNLOAD_INVOICE &&
    typeof s.t === 'function'
  ) {
    s.t();
  }

  if (
    action.type === BillingDetailActionTypes.DOWNLOAD_INVOICE &&
    typeof s.tl === 'function'
  ) {
    s.linkTrackVars = 'prop73';
    s.tl(true, 'd', BillingDetailActionTypes.DOWNLOAD_INVOICE);
  }
};

const analyticsMiddleware: Middleware = () => (next) => (action) => {
  if (action.analytics) {
    setAnalytics(action);
  }
  return next(action);
};

export default analyticsMiddleware;
