import { Link } from '@vodafone/red-white';
import styled from 'styled-components';

export const Content = styled.div<{ redBg?: boolean }>`
  width: 100%;
  background: ${(props) =>
    props.redBg
      ? props.theme.colors.cardRedBackground
      : props.theme.colors.white};
  border-radius: 0px;
  padding: 12px;
  box-shadow: 0px 6px 12px ${(props) => props.theme.colors.cardBoxShadow};
  margin-top: 20px;

  @media ${(props) => props.theme.breakpoints.md} {
    border-radius: 6px;
  }
`;

export const TitleWrapper = styled.div`
  text-align: center;
`;

export const LinkText = styled(Link)`
  font-size: 16px;
  display: inline;
`;
