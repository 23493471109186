import { ActionCreator } from 'redux';
import {
  IFetchVirtualBundleBeginAction,
  IFetchVirtualBundleFailureAction,
  IFetchVirtualBundleSuccessAction,
} from '../types/virtualBundle/virtualBundleActionInterfaces';
import { VirtualBundleActionTypes } from '../types/virtualBundle/virtualBundleActionTypes';
import { IVirtualBundle } from '../types/virtualBundle/virtualBundleInterface';

export const fetchVirtualBundleBegin: ActionCreator<
  IFetchVirtualBundleBeginAction
> = () => ({
  type: VirtualBundleActionTypes.FETCH_VIRTUAL_BUNDLE_BEGIN,
});

export const fetchVirtualBundleSuccess: ActionCreator<
  IFetchVirtualBundleSuccessAction
> = (payload: IVirtualBundle) => ({
  type: VirtualBundleActionTypes.FETCH_VIRTUAL_BUNDLE_SUCCESS,
  payload,
});

export const fetchVirtualBundleFailure: ActionCreator<
  IFetchVirtualBundleFailureAction
> = () => ({
  type: VirtualBundleActionTypes.FETCH_VIRTUAL_BUNDLE_FAILURE,
});
