import { Loader } from '@vodafone/red-white';
import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'src/hooks/useAppDispatch';
import { IApplicationState } from 'src/store/appStateInterface';
import { fetchPaymentInfo } from 'src/store/billing/paymentInfo/paymentInfoActions';
import {
  selectPaymentInfo,
  selectPaymentInfoForBaNumber,
} from 'src/store/billing/paymentInfo/paymentInfoSelectors';
import IBillingAccount from 'src/store/user/types/billingAccountInterface';
import { selectBillingAccount } from 'src/store/user/userSelector';
import PaymentInfo from '../../Billing/PaymentInfo';
import { ModalContentProps } from '../types/modalContentInterfaces';

const PaymentInfoModalContent: FC<ModalContentProps> = ({
  setRedBg,
  options,
  setTransparentBg,
}) => {
  const paymentInfo = useSelector(selectPaymentInfo);
  const billingAccount: IBillingAccount = useSelector(
    (state: IApplicationState) => selectBillingAccount(state),
  );
  const billingAccountNumber =
    options && options.billingAccountNumber
      ? options.billingAccountNumber
      : billingAccount.billingAccountNumber;
  const paymentInfoForBaNumber = useSelector((state: IApplicationState) =>
    selectPaymentInfoForBaNumber(state, billingAccountNumber),
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!paymentInfoForBaNumber) {
      dispatch(fetchPaymentInfo(billingAccountNumber));
    }
  }, [dispatch, billingAccountNumber, paymentInfoForBaNumber]);

  useEffect(() => {
    if (paymentInfo.loaded && billingAccountNumber) {
      setRedBg(new Date(billingAccountNumber.dueDate) < new Date());
    }
    setTransparentBg(true);
  }, [billingAccountNumber, paymentInfo.loaded, setRedBg, setTransparentBg]);

  return (
    <>
      <Loader show={paymentInfo.loading} />
      {paymentInfo.loaded && paymentInfoForBaNumber && (
        <PaymentInfo
          paymentInfoData={paymentInfoForBaNumber}
          showWithSipoBox
          billingAccountNumber={billingAccountNumber}
        />
      )}
    </>
  );
};

export default PaymentInfoModalContent;
