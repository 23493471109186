import { Loader } from '@vodafone/red-white';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import VtvSetPasswordStrength, {
  OnSubmitRegistrationForm,
} from 'src/components/Modal/components/VtvSetPasswordModalContent/VtvSetPasswordStrength';
import { ModalContentProps } from 'src/components/Modal/types/modalContentInterfaces';
import { useAppDispatch } from 'src/hooks/useAppDispatch';
import { openModal } from 'src/store/modal/modalActions';
import ModalType from 'src/store/modal/types/ModalType';
import ISubscription from 'src/store/user/types/subscriptionInterface';
import { selectCurrentSubscription } from 'src/store/user/userSelector';
import IVtvServicesState from 'src/store/vtvServices/types/vtvServicesStateInterface';
import { setVtvPassword } from 'src/store/vtvServices/vtvServicesActions';
import { getVtvServices } from 'src/store/vtvServices/vtvServicesSelectors';
import styled from 'styled-components';

const ContentWrapper = styled.div`
  padding: 30px 10px 20px 10px;
`;

const TitleWrapper = styled.div`
  padding: 10px;
  font-size: 30px;
  font-weight: bold;
  line-height: 1.2;
`;

const VtvSetPasswordModalContent: FC<ModalContentProps> = ({ setTitle }) => {
  const { t } = useTranslation(['services']);

  const dispatch = useAppDispatch();
  const { serviceNumber }: ISubscription = useSelector(
    selectCurrentSubscription,
  );

  const { setPassword }: IVtvServicesState = useSelector(getVtvServices);
  const { loading, loaded, error } = setPassword;

  useEffect(() => {
    setTitle(
      <TitleWrapper>
        {t('services:vtv.change-password-title-modal')}
      </TitleWrapper>,
    );
  }, [setTitle, t]);

  const handleSetPassword = ({ data }: OnSubmitRegistrationForm) => {
    dispatch(setVtvPassword(serviceNumber, data.password));
    dispatch(openModal({ type: ModalType.VtvSetPasswordStatus }));
  };

  return (
    <>
      <Loader show={loading} />
      {!loaded && !error && (
        <ContentWrapper>
          <VtvSetPasswordStrength onSubmit={handleSetPassword} />
        </ContentWrapper>
      )}
    </>
  );
};

export default VtvSetPasswordModalContent;
