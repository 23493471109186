import { enquireScreen, unenquireScreen } from 'enquire-js';
import { useCallback, useEffect } from 'react';
import { useAppDispatch } from 'src/hooks/useAppDispatch';
import { setMediaDeviceAction } from 'src/store/application/applicationActions';
import { Device } from 'src/store/application/types/applicationStateInterface';

const mediaRule = {
  isMobile: 'screen and (max-width: 576px)',
  isTablet: 'screen and (min-width: 577px) and (max-width: 1299px)',
  isDesktop: 'screen and (min-width: 1300px)',
};

const MediaEnquire = () => {
  const dispatch = useAppDispatch();

  const setMedia = useCallback(
    (device: Device) => {
      dispatch(setMediaDeviceAction(device));
    },
    [dispatch],
  );

  useEffect(() => {
    const handleMobile = enquireScreen(
      (bool: boolean) => bool && setMedia(Device.mobile),
      mediaRule.isMobile,
    );
    const handleTablet = enquireScreen(
      (bool: boolean) => bool && setMedia(Device.tablet),
      mediaRule.isTablet,
    );
    const handleDesktop = enquireScreen(
      (bool: boolean) => bool && setMedia(Device.desktop),
      mediaRule.isDesktop,
    );

    return () => {
      unenquireScreen(handleMobile, mediaRule.isMobile);
      unenquireScreen(handleTablet, mediaRule.isTablet);
      unenquireScreen(handleDesktop, mediaRule.isDesktop);
    };
  }, [setMedia]);

  return null;
};

export default MediaEnquire;
