import { createSelector, Selector } from 'reselect';
import { VerificationSecureMessageType } from 'src/api/mfa/request/verificationSecureRequest';
import { IApplicationState } from 'src/store/appStateInterface';
import IMfaState from 'src/store/mfa/types/mfaStateInterface';

export const getMfa: Selector<IApplicationState, IMfaState> = (
  state: IApplicationState,
) => state.Mfa;

export const selectMfaRequestSecondsToExpirationForTypeAndServiceNumber = (
  state: IApplicationState,
  messageType: VerificationSecureMessageType,
  serviceNumber: string,
): number | null =>
  createSelector([getMfa], (mfa) => {
    if (
      !mfa[messageType] ||
      !mfa[messageType][serviceNumber] ||
      !mfa[messageType][serviceNumber].request
    ) {
      return null;
    }

    return mfa[messageType][serviceNumber].request.expireAfterSeconds;
  })(state);

export const selectMfaRequestVerifiedForTypeAndServiceNumber = (
  state: IApplicationState,
  messageType: VerificationSecureMessageType,
  serviceNumber: string,
): boolean | null =>
  createSelector([getMfa], (mfa) => {
    if (
      !mfa[messageType] ||
      !mfa[messageType][serviceNumber] ||
      !mfa[messageType][serviceNumber].request
    ) {
      return null;
    }

    return mfa[messageType][serviceNumber].request.verified;
  })(state);

export const selectMfaRequestStatesForTypeAndServiceNumber = (
  state: IApplicationState,
  messageType: VerificationSecureMessageType,
  serviceNumber: string,
) =>
  createSelector([getMfa], (mfa) => {
    if (
      !mfa[messageType] ||
      !mfa[messageType][serviceNumber] ||
      !mfa[messageType][serviceNumber].request
    ) {
      return {
        loading: false,
        loaded: false,
        error: false,
      };
    }

    const { request } = mfa[messageType][serviceNumber];

    return {
      loading: request.loading,
      loaded: request.loaded,
      error: request.error,
      verified: request.verified,
    };
  })(state);

export const selectMfaValidationStatesForTypeAndServiceNumber = (
  state: IApplicationState,
  messageType: VerificationSecureMessageType,
  serviceNumber: string,
) =>
  createSelector([getMfa], (mfa) => {
    if (
      !mfa[messageType] ||
      !mfa[messageType][serviceNumber] ||
      !mfa[messageType][serviceNumber].validation
    ) {
      return {
        loading: false,
        loaded: false,
        error: false,
        isValid: false,
        isRateLimited: false,
      };
    }

    const { validation } = mfa[messageType][serviceNumber];

    return {
      loading: validation.loading,
      loaded: validation.loaded,
      error: validation.error,
      isValid: validation.isValid,
      isRateLimited: validation.isRateLimited,
    };
  })(state);
