import { ICoreOptionProps, Loader, Select } from '@vodafone/red-white';
import * as React from 'react';
import styled from 'styled-components';
import { TextWrapper } from '../RoamingData';

const ButtonWrapper = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  flex-grow: 2;
  margin-top: 10px;
`;

const SelectWrapper = styled.div`
  flex-grow: 2;
  width: 100%;
  @media ${(props) => props.theme.breakpoints.sm} {
    margin-right: 20px;
    margin-bottom: 0;
  }
  @media only screen and (max-width: 560px) {
    margin-top: -5px;
  }
`;

const SelectField = styled(Select)`
  width: 100%;
  margin: 6px 0;
  @media only screen and (max-height: 430px), (max-width: 560px) {
    & > div:nth-of-type(2) {
      max-height: 180px;
    }
  }
  @media ${(props) => props.theme.breakpoints.sm} {
    width: unset;
    margin: unset;
  }
`;

const StyledFormWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  position: relative;
  justify-content: space-between;
  flex-direction: column;

  @media ${(props) => props.theme.breakpoints.sm} {
    flex-direction: row;
  }
`;

const mapSelectValues = (object: {}) => {
  return Object.keys(object).map((objectLabel) => {
    return {
      value: object[objectLabel],
      label: objectLabel,
    };
  });
};

export interface IRoamingCalculatorContentProps {
  loading: boolean;
  description: string;
  phone: IMySelectProps;
  country: IMySelectProps;
  button: React.ReactChild;
}

interface IMySelectProps {
  id?: string;
  className?: string;
  label?: React.ReactNode;
  value?: string;
  options: object;
  onChange?: (value: ICoreOptionProps) => void;
}

const ServiceManagementRoamingCalcContent: React.FC<
  IRoamingCalculatorContentProps
> = ({ loading, description, phone, country, button }) => {
  return (
    <>
      <Loader show={loading} />
      <TextWrapper fontSize="14px">
        <p>{description}</p>
      </TextWrapper>

      <StyledFormWrapper>
        <SelectWrapper>
          <SelectField
            id={phone.id}
            label={phone.label}
            options={mapSelectValues(phone.options)}
            onChange={phone.onChange}
            value={phone.value}
            maxHeight="230px"
          />
        </SelectWrapper>
        <SelectWrapper>
          <SelectField
            id={country.id}
            label={country.label}
            options={mapSelectValues(country.options)}
            onChange={country.onChange}
            value={country.value}
            maxHeight="230px"
          />
        </SelectWrapper>
        <ButtonWrapper>{button}</ButtonWrapper>
      </StyledFormWrapper>
    </>
  );
};

export default ServiceManagementRoamingCalcContent;
