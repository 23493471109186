import { Reducer } from 'redux';
import initialState from '../initialState';
import {
  IFetchUsageConsumptionBeginAction,
  IFetchUsageConsumptionFailureAction,
  IFetchUsageConsumptionSuccessAction,
} from './types/usageConsumptionActionInterfaces';
import UsageConsumptionActionTypes from './types/usageConsumptionActionTypes';
import IUsageConsumptionState from './types/usageConsumptionStateInterface';

type IUsageConsumptionActions =
  | IFetchUsageConsumptionBeginAction
  | IFetchUsageConsumptionSuccessAction
  | IFetchUsageConsumptionFailureAction;

const usageConsumptionReducer: Reducer<
  IUsageConsumptionState,
  IUsageConsumptionActions
> = (state = initialState.UsageConsumption, action) => {
  switch (action.type) {
    case UsageConsumptionActionTypes.FETCH_USAGE_CONSUMPTION_BEGIN:
      return {
        ...state,
        selectedBaNumber: action.baNumber,
        data: {
          ...state.data,
          [action.baNumber]: {
            ...state.data[action.baNumber],
            loading: true,
            loaded: false,
            error: false,
          },
        },
      };
    case UsageConsumptionActionTypes.FETCH_USAGE_CONSUMPTION_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.baNumber]: {
            ...action.payload,
            loading: false,
            loaded: true,
            error: false,
          },
        },
      };
    case UsageConsumptionActionTypes.FETCH_USAGE_CONSUMPTION_FAILURE:
      return {
        ...state,
        data: {
          ...state.data,
          [action.baNumber]: {
            ...state.data[action.baNumber],
            loading: false,
            loaded: false,
            error: true,
          },
        },
      };
    default:
      return state;
  }
};

export default usageConsumptionReducer;
