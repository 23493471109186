import { AxiosResponse } from 'axios';
import { API_ENDPOINT_ESIM_ONENUMBER_ONE_NUMBERS } from 'src/api';
import IESimOneNumberOneNumbersResponse from 'src/api/esim/response/eSimOneNumberOneNumbersResponse';
import { IRestResponseFault } from 'src/api/types/IRestResponseFault';
import api from 'src/utils/api/api';

export default (
  serviceNumber: string,
): Promise<
  AxiosResponse<IESimOneNumberOneNumbersResponse | IRestResponseFault>
> =>
  api.get({
    url: API_ENDPOINT_ESIM_ONENUMBER_ONE_NUMBERS.replace(
      '{serviceNumber}',
      serviceNumber,
    ),
  });
