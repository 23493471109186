import { useMutation } from '@tanstack/react-query';
import api from 'src/utils/api/api';
import {
  ServiceRequestArea,
  ServiceRequestType,
} from './useCustomerServiceRequestCreate.enums';

type CustomerServiceRequestCreateRequest = {
  type: ServiceRequestType;
  area: ServiceRequestArea;
  description: string;
};

export const POST_CUSTOMER_SERVICE_REQUEST_CREATE =
  '/muj/rapi/customer/service-request/create';
const POST_CUSTOMER_SERVICE_REQUEST_CREATE_KEY =
  'postCustomerServiceRequestCreate';

const postCustomerServiceRequestCreate = async (
  request: CustomerServiceRequestCreateRequest,
): Promise<void> => {
  const { type, area, description } = request;

  await api.post({
    url: POST_CUSTOMER_SERVICE_REQUEST_CREATE,
    data: {
      type,
      area,
      description,
    },
  });
};

export const useCustomerServiceRequestCreate = () => {
  return useMutation<void, void, CustomerServiceRequestCreateRequest>({
    mutationFn: postCustomerServiceRequestCreate,
    mutationKey: [POST_CUSTOMER_SERVICE_REQUEST_CREATE_KEY],
  });
};
