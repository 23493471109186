import { Loader } from '@vodafone/red-white';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { selectPaymentStatus } from 'src/store/billing/paymentStatus/paymentStatusSelectors';
import PaymentStatusEnum from 'src/store/billing/paymentStatus/types/paymentStatusEnum';
import styled from 'styled-components';
import {
  PaymentStatusError,
  PaymentStatusSuccess,
  PaymentStatusUnavailable,
} from '../../Billing/PaymentStatus';
import { ModalContentProps } from '../types/modalContentInterfaces';

const ModalContent = styled.div`
  margin-top: 64px;
`;

const PaymentStatusModalContent: FC<ModalContentProps> = ({ onClose }) => {
  const paymentStatus = useSelector(selectPaymentStatus);

  return (
    <ModalContent>
      <Loader show={paymentStatus.loading} />
      {paymentStatus.loaded &&
        paymentStatus.data.paymentStatus === PaymentStatusEnum.SUCCESS && (
          <PaymentStatusSuccess
            close={onClose}
            paymentId={paymentStatus.data.paymentId}
          />
        )}
      {paymentStatus.loaded &&
        paymentStatus.data.paymentStatus === PaymentStatusEnum.ERROR && (
          <PaymentStatusError close={onClose} />
        )}
      {((paymentStatus.loaded &&
        paymentStatus.data.paymentStatus === PaymentStatusEnum.UNAVAILABLE) ||
        (!paymentStatus.loading &&
          (paymentStatus.error || !paymentStatus.data))) && (
        <PaymentStatusUnavailable close={onClose} />
      )}
    </ModalContent>
  );
};

export default PaymentStatusModalContent;
