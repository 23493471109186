import initialState from '../../initialState';
import {
  FbbModemRestartTypes,
  REQUEST_FBB_MODEM_RESTART_LOADING,
  REQUEST_FBB_MODEM_RESTART_SUCCESS,
} from '../types/fbbModemRestart/fbbModemRestartActionTypes';
import IRequestFbbModemRestart from '../types/fbbModemRestart/fbbModemRestartStateInterface';

export default (
  state = initialState.FbbServices.fbbModemRestart,
  action: FbbModemRestartTypes,
): IRequestFbbModemRestart => {
  switch (action.type) {
    case REQUEST_FBB_MODEM_RESTART_LOADING: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
      };
    }
    case REQUEST_FBB_MODEM_RESTART_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    default:
      return state;
  }
};
