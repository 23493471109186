import { Button, Card, IconRepair2, Text, Title } from '@vodafone/red-white';
import { FC, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { HStack } from 'src/components/Layout';
import { ModalContentProps } from 'src/components/Modal/types/modalContentInterfaces';
import { CpeDeviceDiagnosticsErrorResponse } from 'src/features/services';
import styled from 'styled-components';

const CustomCardPadding = styled.div`
  padding: 40px 0 0 0;
  text-align: center;
`;

const ModemDiagModalContent: FC<
  ModalContentProps<CpeDeviceDiagnosticsErrorResponse>
> = ({ setCompact, setCompactContent, onClose, options }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['services', 'component']);

  useEffect(() => {
    setCompact(true);
    setCompactContent(true);
  }, [setCompactContent, setCompact, dispatch]);

  return (
    <Card style={{ maxWidth: 590, width: '100%', boxShadow: 'none' }}>
      <CustomCardPadding>
        <>
          <IconRepair2 size={96} />
          <Title
            color="darkGrey"
            fontFamily="VodafoneRg"
            fontWeight="bold"
            level={3}
            style={{ marginBottom: 20 }}
            fontSize={22}
          >
            {t('services:modem-diag-modal.error.title')}
          </Title>
          <Trans>
            <Text fontFamily="VodafoneRg" fontSize={16}>
              {options?.message?.description ||
                t('services:modem-diag-modal.error.description')}
            </Text>
          </Trans>
          <HStack
            justify="center"
            spacing="24px"
            align="center"
            style={{ marginTop: 30 }}
          >
            <Button variant="secondary" onClick={() => onClose()}>
              {t('component:button.close')}
            </Button>
          </HStack>
        </>
      </CustomCardPadding>
    </Card>
  );
};

export default ModemDiagModalContent;
