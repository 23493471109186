import { ActionCreator } from 'redux';
import {
  IHideWarningModalAction,
  IShowWarningModalAction,
  IUserIsActiveAction,
  IUserIsInactiveAction,
} from './types/inactiveUserModalActionInterfaces';
import { InactiveUserModalActionTypes } from './types/inactiveUserModalActionTypes';

export const userIsActive: ActionCreator<IUserIsActiveAction> = () => ({
  type: InactiveUserModalActionTypes.USER_IS_ACTIVE,
});

export const userIsInactive: ActionCreator<IUserIsInactiveAction> = () => ({
  type: InactiveUserModalActionTypes.USER_IS_INACTIVE,
});

export const showWarningModal: ActionCreator<IShowWarningModalAction> = () => ({
  type: InactiveUserModalActionTypes.SHOW_WARNING_MODAL,
});

export const hideWarningModal: ActionCreator<IHideWarningModalAction> = () => ({
  type: InactiveUserModalActionTypes.HIDE_WARNING_MODAL,
});
