import { Reducer } from 'redux';
import initialState from '../initialState';
import {
  IHideWarningModalAction,
  IShowWarningModalAction,
  IUserIsActiveAction,
  IUserIsInactiveAction,
} from './types/inactiveUserModalActionInterfaces';
import { InactiveUserModalActionTypes } from './types/inactiveUserModalActionTypes';
import { IInactiveUserModalState } from './types/inactiveUserModalStateInterface';

type InactiveUserModalActions =
  | IUserIsActiveAction
  | IUserIsInactiveAction
  | IShowWarningModalAction
  | IHideWarningModalAction;

export const inactiveUserModalReducer: Reducer<IInactiveUserModalState> = (
  state: IInactiveUserModalState = initialState.InactiveUserModal,
  action: InactiveUserModalActions,
) => {
  switch (action.type) {
    case InactiveUserModalActionTypes.USER_IS_ACTIVE:
      return {
        ...state,
        active: true,
      };

    case InactiveUserModalActionTypes.USER_IS_INACTIVE:
      return {
        ...state,
        active: false,
      };

    case InactiveUserModalActionTypes.SHOW_WARNING_MODAL:
      return {
        ...state,
        warningModalIsVisible: true,
      };

    case InactiveUserModalActionTypes.HIDE_WARNING_MODAL:
      return {
        ...state,
        warningModalIsVisible: false,
      };

    default:
      return state;
  }
};
