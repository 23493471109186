import eligible from 'src/config/eligible';
import { IRoute } from 'src/config/routes';
import IUserState from 'src/store/user/types/userStateInterface';
import { IEligibleConfig } from '../types/eligibleInterface';

const isEligibleByRoles = (
  eligibleConfig: IEligibleConfig,
  user: IUserState,
): boolean => {
  return eligibleConfig.hasRoles.every((role) => user.roles.includes(role));
};

const isEligibleRoute = (route: IRoute, user: IUserState): boolean => {
  const eligibleConfig = eligible[route?.id];

  if (!eligibleConfig) {
    return true;
  }

  if (eligibleConfig.hasRoles && !isEligibleByRoles(eligibleConfig, user)) {
    return false;
  }

  return true;
};

export default isEligibleRoute;
