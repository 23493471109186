import { Reducer } from 'redux';
import initialState from '../initialState';
import { ModalActionTypes } from './modalActionTypes';
import { IModalActions } from './types/modalActionInterfaces';
import IModalState from './types/modalStateInterface';

export const modalReducer: Reducer<IModalState, IModalActions> = (
  state = initialState.Modal,
  { type, payload },
) => {
  switch (type) {
    case ModalActionTypes.MODAL_OPEN:
      return {
        ...state,
        type: payload.type,
        options: payload.options,
        isOpen: true,
      };
    case ModalActionTypes.MODAL_UPDATE_ANALYTICS_OPTIONS:
      return {
        ...state,
        analyticsOptions: {
          ...state.analyticsOptions,
          prop49AdditionalInfo:
            payload?.prop49AdditionalInfo ??
            state?.analyticsOptions?.prop49AdditionalInfo,
          prop16AdditionalInfo:
            payload?.prop16AdditionalInfo ??
            state?.analyticsOptions?.prop16AdditionalInfo,
          analyticsText:
            payload?.analyticsText ?? state?.analyticsOptions?.analyticsText,
          sendAnalyticsAfterModalClosed:
            payload?.sendAnalyticsAfterModalClosed ??
            state?.analyticsOptions?.sendAnalyticsAfterModalClosed,
        },
      };
    case ModalActionTypes.MODAL_RESET_ANALYTICS_OPTIONS:
      return {
        ...state,
        analyticsOptions: {},
      };
    case ModalActionTypes.MODAL_OPEN_WITH_ID:
      return {
        ...state,
        type: payload.type,
        isOpen: true,
        id: payload.id,
        options: payload.options,
      };
    case ModalActionTypes.MODAL_CLOSE:
      if (state.type === payload.type) {
        return {
          ...state,
          type: payload.type,
          isOpen: false,
          options: {
            closedBy: payload.closedBy,
            closedOptions: payload.closedOptions,
          },
        };
      }
      return { ...state };

    case ModalActionTypes.MODAL_RESET_STORE:
      return initialState.Modal;
    default:
      return state;
  }
};
