enum CasActionTypes {
  REFRESH_CAS_SUBSCRIPTION_RESET_STATE = 'REFRESH_CAS_SUBSCRIPTION_RESET_STATE',
  REFRESH_CAS_SUBSCRIPTION_BEGIN = 'REFRESH_CAS_SUBSCRIPTION_BEGIN',
  REFRESH_CAS_SUBSCRIPTION_SUCCESS = 'REFRESH_CAS_SUBSCRIPTION_SUCCESS',
  REFRESH_CAS_SUBSCRIPTION_FAILURE = 'REFRESH_CAS_SUBSCRIPTION_FAILURE',
  CAS_DEVICE_PIN_RESET_STATE = 'CAS_DEVICE_PIN_RESET_STATE',
  CAS_DEVICE_PIN_RESET_BEGIN = 'CAS_DEVICE_PIN_RESET_BEGIN',
  CAS_DEVICE_PIN_RESET_SUCCESS = 'CAS_DEVICE_PIN_RESET_SUCCESS',
  CAS_DEVICE_PIN_RESET_FAILURE = 'CAS_DEVICE_PIN_RESET_FAILURE',
}

export default CasActionTypes;
