enum MfaActionTypes {
  RESET_MFA_STATE = 'RESET_MFA_STATE',

  FETCH_VERIFICATION_SECURE_BEGIN = 'FETCH_VERIFICATION_SECURE_BEGIN',
  FETCH_VERIFICATION_SECURE_SUCCESS = 'FETCH_VERIFICATION_SECURE_SUCCESS',
  FETCH_VERIFICATION_SECURE_FAILURE = 'FETCH_VERIFICATION_SECURE_FAILURE',

  FETCH_VERIFICATION_SECURE_VERIFY_CODE_BEGIN = 'FETCH_VERIFICATION_SECURE_VERIFY_CODE_BEGIN',
  FETCH_VERIFICATION_SECURE_VERIFY_CODE_SUCCESS = 'FETCH_VERIFICATION_SECURE_VERIFY_CODE_SUCCESS',
  FETCH_VERIFICATION_SECURE_VERIFY_CODE_FAILURE = 'FETCH_VERIFICATION_SECURE_VERIFY_CODE_FAILURE',
}

export default MfaActionTypes;
